// Source: https://github.com/magento-research/pwa-studio/blob/develop/packages/venia-concept/src/actions/user/asyncActions.js
import { makeVar } from '@apollo/client'
import Cookies from 'universal-cookie'

import loginTokenVar from '@emico/login-token'

import actions from './actions'
import { mergeCart } from '../../cart/mergeCart.mutation'
import { getCustomer } from '../../customer/getCustomer.query'
import {
    CustomerAccountManagementV1ActivatePutBody,
    CustomerAccountManagementV1CreateAccountPostBody,
    CustomerAccountManagementV1InitiatePasswordResetPutBody,
    CustomerAccountManagementV1ResetPasswordPostBody,
    CustomerDataCustomerInterface,
    IntegrationCustomerTokenServiceV1CreateCustomerAccessTokenPostBody,
    ThunkResult,
} from '../../types'
import login from '../../utils/googleTagManager/login'
import push from '../../utils/googleTagManager/push'
import { isEmpty } from '../../utils/validateRequired'
import { wishlistIdVar } from '../../wishlist/useWishlistId'
import { getCartDetails, getCartId, removeCart } from '../cart'
import { setShippingInformation, ShippingInfo } from '../checkout/asyncActions'

const HIGHSTREET_TOKEN_COOKIE_NAME = 'ns_access_token'

/**
 * @deprecated
 */
export const signIn_ = (
    credentials: IntegrationCustomerTokenServiceV1CreateCustomerAccessTokenPostBody,
    shippingInfo?: ShippingInfo,
): ThunkResult<Promise<void>> =>
    async function thunk(dispatch, getState, { request, apolloClient }) {
        dispatch(actions.signIn.request())

        try {
            const token = await request('/rest/V1/integration/customer/token', {
                method: 'POST',
                body: JSON.stringify(credentials),
                ignoreAuthFailure: true,
            })

            setToken(token)

            // query the customer from graphql, preloading it's data into the cache
            // We await here, so we have a populated cache
            const { data } = await getCustomer(apolloClient, token)

            // push to GTM
            push(login(data.customer.email))

            await dispatch(actions.signIn.receive({ token }))

            const cartId = getCartId()

            if (cartId) {
                await mergeCart(apolloClient)(cartId, token)
            }

            // If a guest user enters the checkout, enters his data, and wants to log in,
            // we want the entered data to be used in the cart and for the order.
            //
            // Because the customer is logged in, the possibility exists that he has an active cart,
            // or a default address. By logging in, the cart data is replaced with the user data.
            //
            // By providing it here, we can better control the info that is set on the cart
            if (shippingInfo) {
                await dispatch(setShippingInformation(shippingInfo))
            } else {
                await dispatch(getCartDetails({ forceRefresh: true }))
            }
        } catch (error) {
            dispatch(actions.signIn.receive(error))
            throw error
        }
    }

export const signOut = (): ThunkResult<Promise<void>> =>
    async function thunk(dispatch, getState, { apolloClient }) {
        // If we were actually signed in then our cart was a user-cart and not a
        // guest-cart and we need to purge it from Redux.
        // This could be triggered also by auth errors when the user wasn't
        // actually authenticated, and so isSignedIn may be false.
        const wasSignedIn = isSignedIn()

        // Clear wishlist id from local storage
        wishlistIdVar(undefined)

        // Sign the user out in local storage and Redux.
        await clearToken()
        await dispatch(actions.signIn.reset())

        // trigger apollo cache to remove all data, so auth-related is removed
        // Also, we dont care about refetching ACTIVE queries,
        // so we use clearStore instead of resetStore
        await apolloClient.stop()
        await apolloClient.clearStore()

        if (wasSignedIn) {
            // After signing out we must forget the old (customer) cart. This is
            // because we can no longer manipulate it (since it requires the user's
            // token) and because it has private data from the user that we do not
            // want to leak.
            await dispatch(removeCart())
        }
    }

export const createAccount = (
    accountInfo: Omit<
        CustomerAccountManagementV1CreateAccountPostBody,
        'customer'
    > & {
        customer: Pick<
            CustomerDataCustomerInterface,
            | 'email'
            | 'firstname'
            | 'lastname'
            | 'store_id'
            | 'website_id'
            | 'dob'
        >
    },
    shippingInfo?: ShippingInfo,
): ThunkResult<Promise<void>> =>
    async function thunk(dispatch, _, { request }) {
        if (isEmpty(accountInfo.password)) {
            throw new Error('password is required for creating an account.')
        }

        await request('/rest/V1/customers', {
            method: 'POST',
            body: JSON.stringify(accountInfo),
            ignoreAuthFailure: true,
        })
        await dispatch(
            signIn_(
                {
                    username: accountInfo.customer.email,
                    password: accountInfo.password ?? '',
                },
                shippingInfo,
            ),
        )
    }

export const resetPasswordRequest = (
    email: string,
    websiteId: number,
): ThunkResult<Promise<boolean>> =>
    async function thunk(dispatch, getState, { request }) {
        dispatch(actions.resetPassword.request(email))

        const body: CustomerAccountManagementV1InitiatePasswordResetPutBody = {
            email,
            websiteId,
            template: 'email_reset',
        }

        return request('/rest/V1/customers/password', {
            method: 'PUT',
            body: JSON.stringify(body),
        })
    }

export const validatePasswordResetToken = (
    customerId: string,
    resetPasswordLinkToken: string,
): ThunkResult<Promise<boolean>> =>
    async function thunk(dispatch, getState, { request }) {
        return request(
            `/rest/V1/customers/${customerId}/password/resetLinkToken/${resetPasswordLinkToken}`,
            {
                method: 'GET',
            },
        )
    }

export const setPassword = (
    email: string,
    token: string,
    password: string,
): ThunkResult<Promise<boolean>> =>
    async function thunk(dispatch, getState, { request }): Promise<boolean> {
        const body: CustomerAccountManagementV1ResetPasswordPostBody = {
            email,
            resetToken: token,
            newPassword: password,
        }

        return request('/rest/V1/customers/resetPassword', {
            method: 'POST',
            body: JSON.stringify(body),
        })
    }

export const activate = (
    token: string,
    email: string,
    password: string,
): ThunkResult<Promise<boolean>> =>
    async function thunk(dispatch, getState, { request }): Promise<boolean> {
        if (isEmpty(email)) {
            throw new Error('email is required for activating an account.')
        }
        if (isEmpty(password)) {
            throw new Error('password is required for activating an account.')
        }

        const body: CustomerAccountManagementV1ActivatePutBody & {
            password: string
        } = {
            confirmationKey: token,
            password,
        }

        return request(`/rest/V1/customers/${email}/activate-user`, {
            method: 'PUT',
            body: JSON.stringify(body),
            ignoreAuthFailure: true,
        })
    }

export const getHighStreetToken = (): string | undefined => {
    const cookies = new Cookies()
    const token = cookies.get(HIGHSTREET_TOKEN_COOKIE_NAME) as
        | string
        | undefined

    return token || undefined
}

// We use local storage for storing the user token since local storage is
// synched across tabs. This means that if one tab is logged in, all tabs will
// be logged in. More importantly if one tab logs out then all tabs log out.
// TODO: Stop using local storage; https://support.emico.nl/issues/110688. We
//  can still use local storage to sign all tabs out simultaneously by simply
//  setting an isSignedIn boolean and listening to that change.
export const getToken = (): string | null => loginTokenVar() ?? null

export const setToken = (token: string): void => {
    loginTokenVar(token)
}

// TODO: Get correct token expire time from API
export const clearToken = (): void => {
    loginTokenVar(undefined)
}

export const isSignedInVar = makeVar(false)
/**
 * @deprecated Use the useIsLoggedIn hook from @emico-hooks/login-token
 */
export const isSignedIn = (): boolean => isSignedInVar()
