import { DLVLinkClick } from './types'

export default function linkClick(
    linkName: string,
    linkCategory: string,
    linkType: 'internal' | 'external',
): DLVLinkClick {
    return {
        event: 'linkClick',
        linkName,
        linkCategory,
        linkType,
    }
}
