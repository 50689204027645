import { useIsLoggedIn } from '@emico-hooks/login-token'
import * as React from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import hashEmail from './hashEmail'
import push from './push'
import { DLVPage } from './types'
import { useGetCustomer } from '../../customer/getCustomer.query'
import { PageType } from '../../layout/PageWrapper'

export default function usePageDataLayer(pageType: PageType) {
    const isPageUnavailable = pageType === 'N/A'
    const isLoggedIn = useIsLoggedIn()
    const activeStoreConfig = useActiveStoreView()

    const { data: customer, loading: loadingCustomer } = useGetCustomer({
        skip: !isLoggedIn || isPageUnavailable,
    })

    React.useEffect(() => {
        if (loadingCustomer || !customer || isPageUnavailable) {
            return
        }

        const event: DLVPage = {
            pageType,
            language: activeStoreConfig.language.toUpperCase(),
            // TODO: country https://support.emico.nl/issues/63747#note-9
            loggedIn: isLoggedIn ? 'yes' : 'no',
            email: String(hashEmail(customer.email)),
            // TODO: has_transacted & clv https://support.emico.nl/issues/113076
        }

        push(event)
        // TODO: Refactor this to use a proper dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType, loadingCustomer, window.location.pathname])

    return { loading: loadingCustomer }
}
