import { useBreakpoints } from '@emico/ui'

import DesktopModal from './DesktopModal'
import MobileModal from './MobileModal'
import Portal from '../../Portal'

export interface Props {
    header?: React.ReactNode
    onBack?(): void
    children: React.ReactNode
    footer?: React.ReactNode
    enableScrollLock?: boolean
    small?: boolean
}

const ResponsiveModal = (props: Props) => {
    const { isDesktop } = useBreakpoints()

    const Modal = isDesktop ? DesktopModal : MobileModal

    return (
        <Portal>
            <Modal {...props} />
        </Portal>
    )
}

export default ResponsiveModal
