import { css, Global } from '@emotion/react'

import theme from '../theme'

const CookieStyles = () => (
    <Global
        styles={css`
            .lightboxBack.customOverlaybc {
                position: fixed;
                z-index: 9999999999;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
            }
            .lightboxWrapper.customOverlaybc {
                position: fixed;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                left: 50%;
                z-index: 99999999999;
                overflow-y: auto;
                max-height: 100%;
                width: calc(100% - 30vw);
                box-sizing: border-box;

                > div {
                    max-width: 870px;
                }
            }
            .firstStageWrapper,
            .secondStageWrapper {
                display: flex;
                justify-content: center;
                font-family: ${theme.fonts.primary};
                height: 100%;
                flex-direction: column;
                background-color: #fff;
                animation-name: fadeAnimation;
                animation-timing-function: ease;
                animation-fill-mode: none;
                animation-duration: 0.5s;
                padding: 50px 30px;
                color: ${theme.colors.text};
                margin: auto;

                h2 {
                    font-size: ${theme.headingSizes.h3};
                    font-family: ${theme.fonts.secondary};

                    @media only screen and (min-width: 768px) {
                        font-size: ${theme.headingSizes.h2};
                    }
                }

                p {
                    font-family: ${theme.fonts.primary};
                    font-size: ${theme.fontSizes.sm};
                    margin-bottom: 30px;
                }
            }
            .buttonWrapperConsent {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 23px;

                > button {
                    text-transform: uppercase;
                    font-family: ${theme.fonts.secondary};
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 4px 10px 0;
                    margin: 0;
                    border: none;
                    appearance: none;
                    border-radius: 2px;
                    cursor: pointer;
                    font-family: ${theme.fonts.secondary};
                    line-height: 34px;
                    opacity: 1;
                    outline: 0;
                    text-align: center;
                    text-decoration: none;
                    transition: all 0.15s ease;
                    min-width: 160px;

                    color: ${theme.colors.onPrimary};
                    background-color: ${theme.colors.primary};
                }
            }
            .cookieInformationLink {
                border-top: 1px solid ${theme.colors.grayLight};
                padding: 20px 0 0;

                p {
                    margin: 0;
                    font-size: ${theme.fontSizes.sm};
                }

                a {
                    color: ${theme.colors.grayMiddle};
                }
            }
            .secondStageWrapper {
                max-height: 0;
                transition: max-height 0.3s;
                overflow: hidden;
                background-color: #fff;
                display: none;
                justify-content: center;
                height: 100%;
                flex-direction: column;
                color: #000;
                margin: auto;
            }
            .unfold {
                max-height: unset;
            }
            .unfold.secondStageWrapper {
                display: flex;
            }
            .basicObjective {
                opacity: 0.8;
            }
            .objectivesWrapper {
                display: flex;
                flex-direction: column;
            }
            .objectiveWrapper {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-start;
                margin-bottom: 10px;
            }
            .objectiveInfoWrapper {
                display: flex;
                flex-direction: column;
                flex: 5;
            }
            .objectiveInputWrapper {
                display: flex;
                flex-direction: row-reverse;
                margin-right: 15px;
            }
            .objectiveTitle {
                margin-bottom: 0;
                font-weight: bold;
            }
            .objectiveText {
                margin-right: 20px;
            }
            .objectiveInput {
                display: inline-block;
                vertical-align: top;
                position: absolute;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                width: 1px;
                border: 0;
                overflow: hidden;
            }
            .basicObjective .objectiveInputLabel {
                cursor: not-allowed;
            }
            .objectiveInputLabel {
                font-size: 0.01px;
                position: relative;
                display: block;
                width: 25px;
                height: 25px;
                flex: 0 0 auto;
                margin-right: 10px;
                border: 1px solid ${theme.colors.grayMiddle};
                background-color: ${theme.colors.white};
                border-radius: 4px;
                transition:
                    border-color 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67),
                    background-color 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
            }
            .objectiveInputLabel:hover {
            }
            .objectiveInputLabel::before {
                display: none;
            }

            .objectiveInput:checked + .objectiveInputLabel {
                content: '';
                background-color: ${theme.colors.primary};
                border-color: ${theme.colors.primary};
                transition: background-color 0.2s
                    cubic-bezier(0.17, 0.67, 0.83, 0.67);
            }
            .objectiveInput:checked + .objectiveInputLabel::after {
                position: absolute;
                top: 50%;
                left: 55%;
                width: 8px;
                height: 12px;
                margin: -8px 0 0 -4.5px;
                border: 2px solid transparent;
                border-right-color: white;
                border-bottom-color: white;
                content: '';
                transform: rotate(40deg);
                transform-origin: center;
                transition: border-color 0.2s
                    cubic-bezier(0.17, 0.67, 0.83, 0.67);
            }

            #functional_cookies ~ .objectiveInputLabel {
                background-color: ${theme.colors.primary};
                opacity: 0.6;
            }
            @media only screen and (max-width: 800px) {
                .lightboxWrapper.customOverlaybc {
                    width: calc(100% - 10vw);
                }
                .firstStageWrapper,
                .secondStageWrapper {
                    padding: 20px;
                }
                .buttonWrapperConsent {
                    flex-direction: column;
                }
                .buttonWrapperConsent button {
                    width: 100%;
                }
                .primaryButtonConsent {
                    margin-bottom: 10px;
                }
                .buttonWrapperConsent .secondaryButtonConsent {
                    margin-right: 0;
                }
            }
        `}
    />
)

export default CookieStyles
