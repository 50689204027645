import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { maxWidth, minWidth } from '@emico/styles'

import { CurrencyEnum } from '../graphql/schema.generated'
import Button from '../input/Button'
import Price from '../presentation/Price'
import theme from '../theme'
import { useSelector } from '../types/Redux'
import Heading from '../typography/Heading'
import Text from '../typography/Text'
import useFreeGiftRules from '../useFreeGiftRules'

const Aside = styled.aside<{ hasImage: boolean }>`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
        'header ${(props) => (props.hasImage ? 'img' : 'header')}'
        'pro pro';
    border: 1px solid ${theme.colors.grayLighter};
    padding: 20px;
    margin-bottom: 30px;
    gap: 20px;
    width: 100%;
    white-space: normal;

    @media ${minWidth('md')} {
        grid-template-areas:
            'header ${(props) => (props.hasImage ? 'img' : 'header')}'
            'pro ${(props) => (props.hasImage ? 'img' : 'pro')}';
    }
`

const ProgressBar = styled.div<{ percentage: number }>`
    position: relative;
    height: 2px;
    margin: 2px 0 20px;
    background: ${theme.colors.grayLight};

    &::after {
        position: absolute;
        top: -2px;
        left: 0;
        width: ${(props) => props.percentage}%;
        height: 6px;
        background: ${theme.colors.primary};
        content: '';
    }
`

const StyledImage = styled(Image)`
    grid-area: img;
    justify-self: end;
`

const HeadingWrapper = styled.div<{ isMiniCart: boolean }>`
    grid-area: header;
    display: flex;
    flex-flow: column wrap;
    gap: 5px;
    white-space: normal;
    justify-content: center;

    @media ${minWidth('md')} {
        gap: ${(props) => (props.isMiniCart ? '0' : '20px')};
        justify-content: flex-start;
    }
`

const ProgressBarWrapper = styled.div`
    grid-area: pro;
`

const StyledIncentiveText = styled(Text)`
    color: ${theme.colors.primary};
    margin-bottom: 5px;
`

const StyledPrice = styled(Price)`
    color: inherit;
`

const StyledButton = styled(Button)`
    @media ${maxWidth('sm')} {
        display: block;
    }
`

export const getGiftRuleLabel = (label?: string) => {
    let heading = label
    let subheading

    if (label?.includes('|')) {
        const [h, s] = label.split('|')

        heading = h
        subheading = s
    }

    return [heading, subheading]
}

const NextGiftNotification = ({
    variant = 'cart',
}: {
    variant?: 'cart' | 'minicart'
}) => {
    const currencyCode = useSelector(
        (state) => state.cart?.details?.currency?.quote_currency_code,
    ) as CurrencyEnum
    const total = useSelector((state) => state.cart?.totals?.subtotal_incl_tax)
    const { nextRule } = useFreeGiftRules()

    if (!nextRule || !currencyCode) {
        return null
    }

    const { insufficient_amount, label, image } = nextRule

    const [heading, subheading] = getGiftRuleLabel(label)

    let percentage
    let remainingAmount

    if (insufficient_amount && total) {
        percentage = (100 * total) / (total + insufficient_amount)
        remainingAmount = <StyledPrice price={insufficient_amount} />
    }

    const isMiniCart = variant === 'minicart'

    return (
        <Aside hasImage={Boolean(image)}>
            <HeadingWrapper isMiniCart={isMiniCart}>
                {heading && (
                    <Heading variant="h3" element="h1">
                        {heading}
                    </Heading>
                )}

                {!isMiniCart && subheading && <Text>{subheading}</Text>}
            </HeadingWrapper>

            {image && (
                <StyledImage
                    url={image}
                    alt={subheading || heading || ''}
                    width={120}
                    height={150}
                    params={{
                        resizingType: 'fill-down',
                    }}
                    lazy
                />
            )}

            {percentage && remainingAmount && (
                <ProgressBarWrapper>
                    {percentage > 75 ? (
                        <StyledIncentiveText as="div" variant="secondary">
                            <Trans id="cart.nextGiftRule.priceTextAlmostThere">
                                You are almost there! {remainingAmount} to go!
                            </Trans>
                        </StyledIncentiveText>
                    ) : (
                        <StyledIncentiveText as="div" variant="secondary">
                            <Trans id="cart.nextGiftRule.priceText">
                                {remainingAmount} to go
                            </Trans>
                        </StyledIncentiveText>
                    )}
                    <ProgressBar percentage={percentage} />
                    {!isMiniCart && (
                        <StyledButton
                            minWidth
                            name=""
                            category=""
                            to="/shop-all"
                            variant="secondary"
                        >
                            <Trans id="cart.nextGiftRule.buttonLabel">
                                Continue shopping
                            </Trans>
                        </StyledButton>
                    )}
                </ProgressBarWrapper>
            )}
        </Aside>
    )
}

export default NextGiftNotification
