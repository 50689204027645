import { Image } from '@emico-react/image'
import * as React from 'react'
import Slider from 'react-slick'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

import BodyScrollLock from '@emico/body-scroll-lock'
import { useBreakpoints } from '@emico/ui'

import styles from './ImageSliderMobile.module.scss'
import Carousel from '../../../behaviour/Carousel'
import CloseIcon from '../../../core/CloseIcon'
import { MaveClip } from '../../../mave/MaveClip'
import Portal from '../../../Portal'
import { MediaGalleryEntry } from '../../../ProductCardFragment'
import useKeyPress from '../../../useKeyPress'
import makeMediaUrl, { MediaType } from '../../../utils/makeMediaUrl'

interface ZoomedInfo {
    file: string | null
    label: string | null
    index: number
}

interface Props {
    images: MediaGalleryEntry[]
}
const scrollToTop = () => {
    window.scrollTo({
        behavior: 'smooth',
        top: 0,
    })
}

const ImageSliderMobile = ({ images }: Props) => {
    const sliderRef = React.useRef<Slider | null>(null)
    const { breakpoints, currentBreakpoint } = useBreakpoints()
    const isLarge: boolean = breakpoints[currentBreakpoint] >= breakpoints.lg
    const [zoomed, setZoomed] = React.useState<boolean>(false)
    const [zoomedInfo, setZoomedInfo] = React.useState<ZoomedInfo>({
        file: '',
        label: '',
        index: 0,
    })
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)

    const exitZoom = React.useCallback(() => {
        setZoomed(false)
        setZoomedInfo({
            file: '',
            label: '',
            index: 0,
        })
    }, [setZoomed, setZoomedInfo])

    useKeyPress('Escape', exitZoom)

    const enterZoom = (info: ZoomedInfo) => {
        scrollToTop()
        setZoomed(true)
        setZoomedInfo({
            file: info.file,
            label: info.label,
            index: info.index,
        })
    }

    const handleClick = (image: MediaGalleryEntry, index: number) => () => {
        enterZoom({
            file: image.file,
            label: image.label,
            index,
        })
    }

    React.useEffect(() => {
        // Reset carousel to first image
        // when product changes. Fixes issue with possible incorrect
        // slider index upon viewing related products or swatches.
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images, sliderRef.current])

    return (
        <>
            {zoomed && zoomedInfo.file && (
                <Portal>
                    <BodyScrollLock lock={zoomed}>
                        <button
                            className={styles.closeZoomedContainer}
                            onClick={exitZoom}
                        >
                            <CloseIcon />
                        </button>
                        <div className={styles.zoomedContainer}>
                            <TransformWrapper
                                doubleClick={{ step: 5 }}
                                centerOnInit
                            >
                                <TransformComponent>
                                    <Image
                                        className={styles.image}
                                        url={makeMediaUrl(
                                            zoomedInfo.file,
                                            MediaType.Product,
                                        )}
                                        width={1500}
                                        alt=""
                                        lazy={false}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        </div>
                    </BodyScrollLock>
                </Portal>
            )}
            <Carousel
                settings={{
                    arrows: isLarge,
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToShowSm: 1,
                    slidesToShowMd: 1,
                    slidesToShowLg: 1,
                    speed: 300,
                    cssEase: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                }}
                beforeChange={(_, next) => {
                    setCurrentIndex(next)
                }}
                ref={sliderRef}
            >
                {images.map((image, index) => {
                    const isVideo = image.mave !== null

                    return (
                        <div
                            key={image.file}
                            onClick={
                                isVideo ? undefined : handleClick(image, index)
                            }
                        >
                            {isVideo ? (
                                <MaveClip embed={image.mave?.id ?? ''} loop />
                            ) : (
                                <Image
                                    url={makeMediaUrl(
                                        image.file,
                                        MediaType.Product,
                                    )}
                                    sizes={{
                                        '429': 752,
                                        '1': 428,
                                    }}
                                    alt={image.label ? image.label : ''}
                                    lazy={index !== 0}
                                />
                            )}
                        </div>
                    )
                })}
            </Carousel>

            <div className={styles.sliderIndicatorContainer}>
                <span
                    className={styles.sliderIndicator}
                    style={{
                        width: `${1 / (images.length / 100)}%`,
                        left: `${currentIndex / (images.length / 100)}%`,
                    }}
                />
            </div>
        </>
    )
}

export default ImageSliderMobile
