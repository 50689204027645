import { gql, useLazyQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'
import { useDebounce } from 'use-debounce'

import { useStaleDataFallback } from '@emico/apollo'

import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from './catalog/common/MinimalConfigurableProductInfo.fragment'
import { TweakwiseNavigateItem } from './catalog/common/ProductFilterPage/TweakwiseNavigate.query'
import { NoResultsMessage } from './catalog/SearchPage/NoResultsMessage'
import { useRootCategoryId } from './catalog/useRootCategory'
import SearchIcon from './core/SearchIcon'
import Button from './input/Button'
import Col from './layout/Col'
import Container from './layout/Container'
import Grid from './layout/Grid'
import GridItem from './layout/Grid/GridItem'
import Row from './layout/Row'
import Icon from './media/Icon'
import Link from './navigation/Link'
import paths from './paths'
import ProductCard from './presentation/ProductCard'
import productCardFragment from './ProductCardFragment'
import theme from './theme'
import Text from './typography/Text'
import { PrismicPage } from './usePrismicPage'
import useTweakwiseItemNo from './useTweakwiseItemNo'

type SearchDocument = PrismicPage
export interface SearchItem {
    keyword: string
    urlKey: string
}

const autoCompleteQuery = gql`
    query TweakwiseAutocomplete($query: String!, $categoryId: [ID!]) {
        tweakwiseAutocomplete(
            query: $query
            params: { categoryId: $categoryId, maxResults: 5 }
        ) {
            keywords
            items {
                product {
                    ...ProductCardFragmentJB
                    ...ConfigurableProductInfo
                }
                prismicDocument {
                    data
                }
            }
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

const Wrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    max-width: 1300px;
    margin: 0 auto;
    @media screen and (min-width: 992px) {
        flex-flow: row nowrap;
    }
`

const SearchSuggestions = styled.div`
    @media screen and (min-width: 992px) {
        width: 200px;
        margin-right: 30px;
    }
`

const ContentWrapper = styled.div`
    flex: 1;
    margin-bottom: 50px;
`

const Header = styled.div``

const SearchSuggestionLink = styled(Link)`
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: ${theme.colors.text};
    }
`

const SearchSuggestionUl = styled.ul`
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
`

const SearchSuggestionLI = styled.li`
    padding: 0;
    margin-top: 10px;
    word-break: break-word;
`

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    margin: 30px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid ${theme.colors.border};
`

const SearchInput = styled.input`
    background: transparent;
    border: none;
    outline: none;
    flex: 1;
    width: 100%;
    &::placeholder,
    &::-ms-input-placeholder {
        color: #cecece;
    }
`

export const RECENT_SEARCHES = 'recentSearches'

interface Props {
    keyword?: string
    className?: string
    onSubmit?(value: SearchItem): void
}

const getStoredSearches = (): SearchItem[] => {
    const items = JSON.parse(localStorage.getItem(RECENT_SEARCHES) || '[]')

    return Array.isArray(items) ? items : []
}

const setStoredSearches = (searchItem: SearchItem) => {
    // Store searched item
    // Prevent storing same value multiple times
    // and limit items to 5
    const items = getStoredSearches()
    const dedupedItems = [...items].filter(
        (item) => item.keyword !== searchItem.keyword,
    )

    localStorage.setItem(
        RECENT_SEARCHES,
        JSON.stringify([searchItem, ...dedupedItems].slice(-5)),
    )
}

const DocumentSearchSuggestion = ({
    item,
    onClick,
}: {
    item: SearchDocument
    onClick(
        item: SearchItem,
    ): (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}) => {
    const { seoTitle, urlKey } = item

    if (!seoTitle || !urlKey) {
        return null
    }

    return (
        <SearchSuggestionLink
            to={`/${urlKey}`}
            category="searchresult"
            name="suggestion"
            onClick={onClick({ keyword: seoTitle || '', urlKey })}
        >
            {seoTitle}
        </SearchSuggestionLink>
    )
}

const SearchMenu = ({ className, onSubmit, keyword }: Props) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const rootCategoryId = useRootCategoryId()
    const categoryId = useTweakwiseItemNo(rootCategoryId || 0)
    const [searchInputValue, setSearchInputValue] = React.useState<
        string | undefined
    >(keyword)
    const [isSubmitted, setSubmitted] = React.useState<SearchItem>()
    // Debounce searchQuery value so query is only run every X ms
    // Otherwise, search query would be run every time user presses a key
    // when input is focussed
    const [searchQueryValue] = useDebounce(searchInputValue || '_', 500)

    // eslint-disable-next-line no-restricted-syntax
    const [executeSearch, { data, loading }] = useLazyQuery<
        {
            tweakwiseAutocomplete?: {
                keywords?: string[]
                items: Array<{
                    product: TweakwiseNavigateItem | null
                    prismicDocument: { data: SearchDocument } | null
                }>
            }
        },
        {
            query: string
            categoryId: string[]
        }
    >(autoCompleteQuery, {
        variables: {
            query: searchQueryValue.trim() || '_',
            categoryId: [categoryId ?? ''],
        },
        fetchPolicy: 'cache-and-network',
        context: getCacheableContext(),
    })
    const autoCompleteData = useStaleDataFallback(data)

    const autocompleteItems =
        autoCompleteData?.tweakwiseAutocomplete?.items || []

    const products = autocompleteItems.reduce<TweakwiseNavigateItem[]>(
        (acc, item) => {
            if (!item.product) {
                return acc
            }

            // Remove possible duplicates
            if (acc.find((i) => i.id === item.product?.id)) {
                return acc
            }

            acc.push(item.product)

            return acc
        },
        [],
    )
    const hasProducts = products.length > 0

    // For now, the
    const documents = autocompleteItems.reduce<SearchDocument[]>(
        (acc, item) => {
            const prismicData = item.prismicDocument?.data

            if (!prismicData?.seoTitle) {
                return acc
            }

            acc.push(prismicData)

            return acc
        },
        [],
    )
    const hasDocuments = documents.length > 0

    const keywords = autoCompleteData?.tweakwiseAutocomplete?.keywords || []
    const hasKeywords = keywords.length > 0

    const [recentSearches, setRecentSearches] =
        React.useState<SearchItem[]>(getStoredSearches())
    const hasRecentSearches = recentSearches.length > 0

    // When searching for '_', we actually fetch the most popular terms
    const hasPopularSearches = searchQueryValue === '_'

    const handleClearRecentSearches = () => {
        localStorage.removeItem(RECENT_SEARCHES)
        setRecentSearches([])
    }

    React.useEffect(() => {
        // Focus the input field when component loads
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef])

    React.useEffect(() => {
        executeSearch()
    }, [executeSearch])

    React.useEffect(() => {
        if (isSubmitted) {
            // User navigates to searched value
            // We can safely assume the user searched for this particular value
            setStoredSearches(isSubmitted)
            setRecentSearches([...recentSearches, isSubmitted])

            if (onSubmit) {
                onSubmit(isSubmitted)
            }
        }
    }, [isSubmitted, onSubmit, searchInputValue, recentSearches])

    const handleSuggestionClick =
        (item: SearchItem) =>
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            setSearchInputValue(item.keyword)
            setSubmitted(item)

            if (onSubmit) {
                // Handle navigation in onSubmit handler
                e.preventDefault()
            }
        }

    const renderSearchSuggestions = (items: SearchItem[]) => (
        <>
            {items.map((item) => (
                <SearchSuggestionLI key={item.keyword}>
                    <SearchSuggestionLink
                        to={item.urlKey}
                        category="searchresult"
                        name="suggestion"
                        onClick={handleSuggestionClick(item)}
                    >
                        {item.keyword}
                    </SearchSuggestionLink>
                </SearchSuggestionLI>
            ))}
        </>
    )

    return (
        // eslint-disable-next-line react/no-unknown-property
        <div className={className} body-scroll-lock-ignore="true">
            <Container>
                <Row>
                    <Col md={{ offset: 2, span: 8, order: 0 }}>
                        <SearchBar>
                            <Button
                                variant="link"
                                onClick={() => {
                                    if (searchInputValue) {
                                        setSubmitted({
                                            keyword: searchInputValue,
                                            urlKey: `${
                                                paths.search
                                            }?q=${encodeURIComponent(
                                                searchInputValue,
                                            ).replace(/%20/g, '+')}`,
                                        })
                                    }
                                }}
                                name="submit"
                                category="search"
                            >
                                <Icon
                                    component={SearchIcon}
                                    title=""
                                    color="dark"
                                    css={css`
                                        font-size: 1.2em;
                                        margin-right: 20px;
                                    `}
                                />
                            </Button>
                            <SearchInput
                                ref={inputRef}
                                name="keyword"
                                onChange={(e) => {
                                    setSearchInputValue(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        if (searchInputValue) {
                                            setSubmitted({
                                                keyword: searchInputValue,
                                                urlKey: `${
                                                    paths.search
                                                }?q=${encodeURIComponent(
                                                    searchInputValue,
                                                ).replace(/%20/g, '+')}`,
                                            })
                                        }
                                    }
                                }}
                                value={searchInputValue || ''}
                                placeholder={t({
                                    id: 'core.search.inputPlaceholder',
                                    message: `What are you looking for?`,
                                })}
                            />

                            <Button
                                variant="link"
                                onClick={() => {
                                    setSearchInputValue(undefined)
                                }}
                                name="clear"
                                category="search"
                                css={css`
                                    padding: 0 10px;
                                    margin: 0 -10px;
                                `}
                            >
                                <Text color="dark">
                                    <Trans id="catalog.searchBar.closeButtonLabel">
                                        Clear
                                    </Trans>
                                </Text>
                            </Button>
                        </SearchBar>
                    </Col>
                </Row>
                <Wrapper style={{ opacity: loading ? 0.5 : 1 }}>
                    {(hasKeywords ||
                        hasPopularSearches ||
                        hasRecentSearches) && (
                        <SearchSuggestions>
                            {hasKeywords && (
                                <>
                                    <Header>
                                        <Text color="grey">
                                            {hasPopularSearches ? (
                                                <Trans id="core.search.popular">
                                                    Popular searches
                                                </Trans>
                                            ) : (
                                                <Trans id="core.search.suggestions">
                                                    Best suggestions
                                                </Trans>
                                            )}
                                        </Text>
                                    </Header>
                                    <SearchSuggestionUl>
                                        {renderSearchSuggestions(
                                            keywords.map((keyword) => ({
                                                keyword,
                                                urlKey: `${
                                                    paths.search
                                                }?q=${encodeURIComponent(
                                                    keyword,
                                                ).replace(/%20/g, '+')}`,
                                            })),
                                        )}
                                    </SearchSuggestionUl>
                                </>
                            )}

                            {hasDocuments && (
                                <>
                                    <Header>
                                        <Text color="grey">
                                            <Trans id="core.search.suggested">
                                                Suggested
                                            </Trans>
                                        </Text>
                                    </Header>
                                    <SearchSuggestionUl>
                                        {documents.map((item, index) => (
                                            <SearchSuggestionLI
                                                key={`document-${index}`}
                                            >
                                                <DocumentSearchSuggestion
                                                    item={item}
                                                    onClick={
                                                        handleSuggestionClick
                                                    }
                                                />
                                            </SearchSuggestionLI>
                                        ))}
                                    </SearchSuggestionUl>
                                </>
                            )}

                            {hasRecentSearches && (
                                <>
                                    <Header>
                                        <Text color="grey">
                                            <Trans id="core.search.recent">
                                                Recent searches
                                            </Trans>
                                        </Text>
                                    </Header>
                                    <SearchSuggestionUl>
                                        {renderSearchSuggestions(
                                            recentSearches,
                                        )}
                                        <SearchSuggestionLI key="clear">
                                            <Button
                                                onClick={
                                                    handleClearRecentSearches
                                                }
                                                name="clearRecent"
                                                category="search"
                                                variant="linkInverted"
                                            >
                                                <Trans id="core.search.eraseRecent">
                                                    Clear recent searches
                                                </Trans>
                                            </Button>
                                        </SearchSuggestionLI>
                                    </SearchSuggestionUl>
                                </>
                            )}
                        </SearchSuggestions>
                    )}
                    {hasProducts && (
                        <ContentWrapper>
                            <Grid xs={2} md={4} lg={4}>
                                {[...products]
                                    .slice(0, 4)
                                    .map((product, index) => (
                                        <GridItem key={product.id}>
                                            <ProductCard
                                                product={product}
                                                itemIndex={index}
                                                itemListId="search_menu"
                                                itemListName="Search Menu"
                                                itemListSlot="searchMenu"
                                            />
                                        </GridItem>
                                    ))}
                            </Grid>
                        </ContentWrapper>
                    )}

                    {hasDocuments && (
                        <>
                            <ContentWrapper>
                                <Grid xs={2} md={4} lg={4}>
                                    {documents.map((item, index) => (
                                        <GridItem key={`document-${index}`}>
                                            <DocumentSearchSuggestion
                                                item={item}
                                                onClick={handleSuggestionClick}
                                            />
                                        </GridItem>
                                    ))}
                                </Grid>
                            </ContentWrapper>
                        </>
                    )}

                    {!loading &&
                        !hasKeywords &&
                        !hasDocuments &&
                        !hasProducts &&
                        // With this check we can hide the message when the
                        // user changes the search term.
                        searchInputValue === searchQueryValue && (
                            <ContentWrapper>
                                <NoResultsMessage keyword={searchInputValue} />
                            </ContentWrapper>
                        )}
                </Wrapper>
            </Container>
        </div>
    )
}

export default SearchMenu
