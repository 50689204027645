/* eslint-disable react/no-unknown-property */

import { useBreakpoints } from '@emico/ui'

import styles from './ImageSlider.module.scss'
import ImageSliderDesktop from './ImageSliderDesktop'
import ImageSliderMobile from './ImageSliderMobile'
import { MediaGalleryEntry } from '../../../ProductCardFragment'
import { Product } from '../GetProduct.query'

interface Props {
    mediaGalleryEntries: MediaGalleryEntry[]
    smallImage: Product['smallImage']
    title: string
    labelType?: string
    labelText?: string
}

const sortGallery = (images: MediaGalleryEntry[]) => {
    const maveVideo = images.find((img) => Boolean(img.mave))

    if (!maveVideo) {
        return images
    }
    const [firstImage, ...rest] = images.filter((img) => img !== maveVideo)

    return [firstImage, maveVideo, ...rest]
}

const ImageSlider = ({
    mediaGalleryEntries,
    smallImage,
    title,
    labelType,
    labelText,
}: Props) => {
    const { isMobile } = useBreakpoints()

    const hasProductImages = Boolean(
        mediaGalleryEntries && mediaGalleryEntries.length > 0,
    )

    const images: MediaGalleryEntry[] = sortGallery(
        hasProductImages
            ? mediaGalleryEntries
            : smallImage?.url
              ? [
                    {
                        label: smallImage?.label || null,
                        position: 1,
                        disabled: false,
                        file: smallImage.url,
                        mave: null,
                    },
                ]
              : [],
    )

    return (
        <>
            <div className={styles.slider} body-scroll-lock-ignore="true">
                {isMobile && <ImageSliderMobile images={images} />}
                {!isMobile && (
                    <ImageSliderDesktop
                        title={title}
                        images={images}
                        labelType={labelType}
                        labelText={labelText}
                    />
                )}
            </div>
        </>
    )
}

export default ImageSlider
