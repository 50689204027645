import cx from 'classnames'
import * as React from 'react'

import styles from './Col.module.scss'
import {
    Breakpoint,
    Breakpoints,
    BreakpointsConfig,
    ColSpan,
} from '../../types'

type ColSize = boolean | ColSpan | 'auto'

// TODO: Fix this typing, can't us a number as span or offset, this will result in an type error
type Column =
    | ColSize
    | { span: number | ColSize; order: number; offset: number | ColSpan }

type BreakpointsProps = Partial<BreakpointsConfig<Column>>

interface Props extends BreakpointsProps {
    /** Content to render */
    children: React.ReactNode

    /**
     * Custom element to render this component to
     * @default 'div'
     */
    as?: React.ReactType

    /** Custom styling hook */
    className?: string

    /** The number of columns to span on extra small devices */
    xs?: BreakpointsProps['xs']

    /** The number of columns to span on small devices */
    sm?: BreakpointsProps['sm']

    /** The number of columns to span on medium devices */
    md?: BreakpointsProps['md']

    /** The number of columns to span on large devices */
    lg?: BreakpointsProps['lg']

    /** The number of columns to span on extra large devices */
    xl?: BreakpointsProps['xl']
}

// Get valid breakpoints based on global enum
const deviceSizes = Object.values(Breakpoints)

const Col = ({
    as: Component = 'div',
    className,
    children,
    ...breakpoints
}: Props) => {
    const spans: string[] = []
    const classes: string[] = []

    deviceSizes.forEach((brkPoint: Breakpoint) => {
        const propValue = breakpoints[brkPoint]

        delete breakpoints[brkPoint]

        let span
        let offset
        let order

        if (propValue != null && typeof propValue === 'object') {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;({ span = true, offset, order } = propValue)
        } else {
            span = propValue
        }

        const infix = brkPoint !== Breakpoints.xs ? `-${brkPoint}` : ''

        if (span != null) {
            spans.push(
                span === true
                    ? styles[`col${infix}`]
                    : styles[`col${infix}-${span}`],
            )
        }

        if (order != null) {
            classes.push(styles[`order${infix}-${order}`])
        }
        if (offset != null) {
            classes.push(styles[`offset${infix}-${offset}`])
        }
    })
    if (!spans.length) {
        spans.push(styles.col)
    }

    return (
        <Component
            className={cx(className, styles.gutters, ...spans, ...classes)}
            // eslint-disable-next-line react/no-children-prop
            children={children}
        />
    )
}

export default Col
