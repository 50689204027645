import { gql } from '@apollo/client'
import { Unmaybe } from '@emico-utils/graphql-data-utils'

import { BasicProduct } from '../../components/src/catalog/ProductPage/GetBasicProduct.query'
import {
    MaveData,
    Maybe,
    PriceRange,
    ProductImage,
    ProductPrice,
    ProductStockStatus,
    CategoryInterface,
    ProductInterface,
} from '../../components/src/graphql/schema.generated'

export const personalShopperBoxProductInfoFragment = gql`
    fragment PersonalShopperBoxProductInfo on ProductInterface {
        id
        sku
        name
        urlKey
        hexColor
        brand
        labeltekst
        labeltype
        smallImage {
            url
            label
            disabled
        }
        baseColorValues {
            label
        }
        categories {
            id
            name
            breadcrumbs {
                categoryName
                categoryLevel
                categoryUrlKey
                categoryId
            }
            level
            urlKey
            position
            path
        }
        mediaGalleryEntries {
            label
            position
            disabled
            file
        }
        priceRange {
            maximumPrice {
                discount {
                    amountOff
                    percentOff
                }
                finalPrice {
                    currency
                    value
                }
                regularPrice {
                    currency
                    value
                }
            }
        }
        stockStatus
        onlyXLeftInStock
    }
`

const productInfoFragment = gql`
    fragment ProductInfo on ProductInterface {
        id
        uid
        sku
        name
        urlKey
        hexColor
        brand
        labeltekst
        labeltype
        lowestPrice
        mainCategory {
            id
            name
            urlKey
            urlPath
            breadcrumbs {
                categoryName
                categoryUrlKey
                categoryId
            }
        }
        itemLabelTypeB2CValues {
            label
            value
        }
        itemLabelTextB2CValues {
            label
            value
        }
        smallImage {
            url
            label
            disabled
        }
        baseColorValues {
            label
        }

        modelImage
        mediaGalleryEntries {
            label
            position
            disabled
            file
            mave {
                id
                orientation
            }
        }
        outletAdviesprijs
        priceRange {
            maximumPrice {
                discount {
                    amountOff
                    percentOff
                }
                finalPrice {
                    currency
                    value
                }
                regularPrice {
                    currency
                    value
                }
            }
        }
        categories {
            id
            name
            breadcrumbs {
                categoryName
                categoryLevel
                categoryUrlKey
                categoryId
            }
            level
            urlKey
            position
            path
        }
        ... on SimpleProduct {
            relatedProducts {
                id
                sku
                hexColor
                smallImage {
                    url
                    label
                    disabled
                }
                modelImage
                urlKey
                stockStatus
            }
        }
        ... on ConfigurableProduct {
            deliveryMessage
            relatedProducts {
                id
                sku
                hexColor
                smallImage {
                    url
                    label
                    disabled
                }
                modelImage
                urlKey
                stockStatus
            }
        }
        stockStatus
        onlyXLeftInStock
    }
`

const productCardFragment = gql`
    fragment ProductCardFragmentJB on ProductInterface {
        ...ProductInfo
        ... on GroupedProduct {
            items {
                product {
                    ...ProductInfo
                }
            }
        }
    }
    ${productInfoFragment}
`

export default productCardFragment

export interface MediaGalleryEntry {
    label: Maybe<string>
    position: number
    disabled: boolean
    file: string
    mave?: Maybe<MaveData>
}

export interface RelatedProduct {
    id: number
    sku: string
    hexColor: Maybe<string>
    smallImage: Maybe<ProductImage & { __typename?: 'ProductImage' }>
    modelImage: Maybe<string>
    urlKey: string
    stockStatus: ProductStockStatus
}

export interface ProductCategory {
    id: number
    name: string
    breadcrumbs: Maybe<
        Array<{
            categoryName: string
            categoryLevel: number
            categoryUrlKey: string
            categoryId: number | null
            __typename?: 'Breadcrumb'
        }>
    >
    level: number
    urlKey: string
    position: number
    path: string
    __typename?: 'CategoryTree'
}

export interface ProductCardFragment {
    id: number
    uid: string
    sku: string
    name: string
    urlKey: string
    hexColor: Maybe<string>
    brand: string
    deliveryMessage?: string
    labeltekst?: string
    labeltype?: string
    lowestPrice?: number
    mainCategory?: Pick<
        CategoryInterface,
        'name' | 'id' | 'urlKey' | 'urlPath'
    > & {
        breadcrumbs: Maybe<
            Array<
                Pick<
                    Unmaybe<CategoryInterface['breadcrumbs']>,
                    'categoryName' | 'categoryUrlKey' | 'categoryId'
                >
            >
        >
    }
    itemLabelTypeB2CValues?: [
        {
            label: string
            value: string
        },
    ]
    itemLabelTextB2CValues?: [
        {
            label: string
            value: string
        },
    ]
    smallImage: Maybe<ProductImage & { __typename?: 'ProductImage' }>
    baseColorValues: ProductInterface['baseColorValues']
    mediaGalleryEntries: MediaGalleryEntry[]
    modelImage: Maybe<string>
    outletAdviesprijs?: number
    priceRange: Overwrite<
        Pick<PriceRange, 'maximumPrice'>,
        {
            maximumPrice: Pick<
                ProductPrice,
                'discount' | 'finalPrice' | 'regularPrice'
            >
        }
    >
    categories: ProductCategory[]
    stockStatus: ProductStockStatus
    onlyXLeftInStock: Maybe<number>
    __typename?:
        | 'BundleProduct'
        | 'ConfigurableProduct'
        | 'SimpleProduct'
        | 'GroupedProduct'
        | 'VirtualProduct'
    relatedProducts?: Maybe<RelatedProduct[]>
    items?: Maybe<
        Array<
            Maybe<{
                product: Maybe<BasicProduct>
            }>
        >
    >
}
