/* eslint-disable import/order */
import { gql, TypedDocumentNode } from '@apollo/client'

import { CartFragment } from './CartFragment.generated'
import { cartItemFragment } from './CartItemFragment'

export const cartFragment = gql`
    fragment CartFragment on Cart {
        id
        extensionAttributes {
            dhlparcelShippingServicepointId
            dhlparcelShippingServicepointCountryId
            shippingEstimateMin
        }
        totalQuantity
        items {
            ...CartItemFragment
        }
        freeGifts {
            claimedLabel
            description
            hasCouponCode
            id
            image
            insufficientAmount {
                value
            }
            insufficientBaseSubtotal {
                value
            }
            insufficientBaseSubtotalTotalInclTax {
                value
            }
            insufficientBaseSubtotalWithDiscount {
                value
            }
            insufficientSubtotal {
                value
            }
            insufficientSubtotalTotalInclTax {
                value
            }
            insufficientSubtotalWithDiscount {
                value
            }
            isActive
            isAvailable
            isValid
            isValidConditions
            isValidCustomerGroup
            itemIds
            label
            maxItems
            name
            products {
                id
                sku
            }
            sortOrder
            tooltip
            unclaimedLabel
        }
        prices {
            appliedTaxes {
                amount {
                    currency
                    value
                }
                label
            }
            grandTotal {
                currency
                value
            }
            subtotalIncludingTax {
                currency
                value
            }
            subtotalExcludingTax {
                currency
                value
            }
            subtotalWithDiscountExcludingTax {
                currency
                value
            }
            discounts {
                amount {
                    currency
                    value
                }
                label
                coupon {
                    code
                }
            }
        }
        appliedCoupons {
            code
        }
    }
    ${cartItemFragment}
` as TypedDocumentNode<CartFragment, never>

export type ShippingAddress = Exclude<
    CartFragment['shippingAddresses'][0],
    null
>
export type AvailableShippingMethod = Exclude<
    Exclude<ShippingAddress['availableShippingMethods'], null | undefined>[0],
    null
>
