/* eslint-disable @typescript-eslint/ban-types */
import styled from '@emotion/styled'
import { AriaListBoxOptions, useListBox, useOption } from '@react-aria/listbox'
import { ListState } from '@react-stately/list'
import { Node } from '@react-types/shared'
import { RefObject, useRef } from 'react'

import theme from '../theme'

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow: auto;
    scrollbar-width: thin;
    overscroll-behavior: contain;

    :focus {
        outline: solid thin ${theme.colors.grayDark};
    }
`

const ListItem = styled('li', {
    shouldForwardProp: (prop: string) =>
        !['isSelected', 'isFocused', 'isDisabled'].includes(prop),
})<{
    isSelected: boolean
    isFocused: boolean
    isDisabled: boolean
}>`
    background: ${({ isFocused }) =>
        isFocused ? theme.colors.grayLight : 'white'};
    color: ${({ isSelected, isDisabled }) => {
        if (isDisabled) {
            return theme.colors.grayLight
        }
        if (isSelected) {
            return theme.colors.primary
        }
        return `var(--popper-select-color, ${theme.colors.onBackground})`
    }};
    padding: 12px 24px 12px 16px;
    outline: none;
    cursor: pointer;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
    font-size: 12px;
    line-height: 16px;
    font-weight: ${({ isSelected }) =>
        isSelected ? theme.fontWeights.bold : theme.fontWeights.base};
    white-space: nowrap;
`

interface Props<T extends object> extends AriaListBoxOptions<T> {
    listBoxRef?: RefObject<HTMLUListElement>
    state: ListState<T>
}

const PopperSelectListBox = <T extends object>(props: Props<T>) => {
    const ref = useRef<HTMLUListElement>(null)
    const { listBoxRef = ref, state } = props
    const { listBoxProps } = useListBox(props, state, listBoxRef)

    return (
        <List {...listBoxProps} ref={listBoxRef}>
            {[...state.collection].map((item) => (
                <Option key={item.key} item={item} state={state} />
            ))}
        </List>
    )
}

interface OptionProps<T extends object> {
    item: Node<T>
    state: ListState<T>
}

const Option = <T extends object>({ item, state }: OptionProps<T>) => {
    const ref = useRef<HTMLLIElement>(null)

    const { optionProps, isSelected, isFocused, isDisabled } = useOption(
        { key: item.key },
        state,
        ref,
    )

    return (
        <ListItem
            {...optionProps}
            ref={ref}
            isSelected={isSelected}
            isFocused={isFocused}
            isDisabled={isDisabled}
        >
            {item.rendered}
        </ListItem>
    )
}

export default PopperSelectListBox
