import cx from 'classnames'
import * as React from 'react'

import styles from './NavSection.module.css'
import DropdownIcon from '../../../core/DropdownIcon/DropdownIcon'
import Icon from '../../../media/Icon/Icon'

const NavSection: React.FC<{
    label: string
    className: string
    children: React.ReactNode
    open: boolean
    onClick(): void
    iconClass?: string
    iconClassOpen?: string
}> = ({
    label,
    className,
    children,
    open = false,
    onClick,
    iconClass,
    iconClassOpen,
}) => (
    <>
        <button onClick={onClick} className={className}>
            {label}
            {children && (
                <Icon
                    component={DropdownIcon}
                    title="dropdown"
                    className={cx(iconClass, {
                        [`${iconClassOpen}`]: open,
                    })}
                />
            )}
        </button>
        {children && (
            <ul className={cx(styles.nav, open ? styles.open : '')}>
                <div className={styles.inner}>{children}</div>
            </ul>
        )}
    </>
)

export default NavSection
