import { gql, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { CmsPageInterface, Maybe } from '../graphql/schema.generated'

const query = gql`
    query GetAllCmsPages($storeId: Int!) {
        allCmsPages(storeId: $storeId) {
            title
            urlKey
        }
    }
`

export default query

export interface AllCmsPages {
    allCmsPages: Array<Maybe<Pick<CmsPageInterface, 'title' | 'urlKey'>>>
}

export const useAllCmsPages = (storeId: number) =>
    useQuery(query, {
        variables: { storeId },
        context: getCacheableContext(),
    })
