import { Image } from '@emico-react/image'
import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import { ConfigurableProduct } from './catalog/ProductPage/ConfigurableProduct'
import { Product } from './catalog/ProductPage/GetProduct.query'
import ProductInfoFormMinimal from './catalog/ProductPage/StickyCta/ProductInfoFormMinimal'
import Link from './navigation/Link'
import PriceBox, {
    getConfigurableProductPrices,
    getPrices,
} from './presentation/PriceBox'
import theme from './theme'
import Text from './typography/Text'

const Article = styled.article`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 30px;
`

const ImageWrapper = styled.div`
    position: relative;
    aspect-ratio: 1/1.43;
    background: ${theme.colors.grayLighter};
`

const Sku = styled(Text)`
    margin-bottom: 0.5em;
`

const CardImageLink = styled(Link)`
    flex: 0 0 35%;
    max-width: 250px;
    padding-right: 10px;

    @media screen and (min-width: 768px) {
        flex: 0 0 45%;
    }

    @media screen and (min-width: 992px) {
        padding-right: 30px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    flex: 1;

    @media screen and (min-width: 768px) {
        max-width: 250px;
    }
`

const LookImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const ShopTheLookCard = ({
    product,
}: {
    product: Product | ConfigurableProduct
}) => {
    const { name, urlKey, smallImage, outletAdviesprijs } = product
    const productUrl = `/${urlKey}`
    const isConfigurable = Boolean((product as ConfigurableProduct).variants)
    const prices = isConfigurable
        ? getConfigurableProductPrices(product as ConfigurableProduct)
        : getPrices(product)

    return (
        <ClassNames>
            {({ css }) => (
                <Article>
                    <CardImageLink
                        name={name}
                        category="lookProductCard"
                        to={productUrl}
                        title={name}
                    >
                        <ImageWrapper>
                            <LookImage
                                alt={name}
                                url={smallImage?.url ?? ''}
                                height={345}
                                width={345}
                                lazy={false}
                            />
                        </ImageWrapper>
                    </CardImageLink>
                    <Wrapper>
                        <Text as="h2" color="dark">
                            {name}
                        </Text>
                        <Sku color="grey">{product.sku}</Sku>
                        <PriceBox
                            {...prices}
                            outletAdviceprice={outletAdviesprijs}
                        />
                        <ProductInfoFormMinimal
                            product={product}
                            withBookmark={false}
                            verticalLayout
                            selectPopperPosition="bottom"
                        />
                    </Wrapper>
                </Article>
            )}
        </ClassNames>
    )
}

export default ShopTheLookCard
