import cx from 'classnames'
import * as React from 'react'

import styles from './Row.module.scss'

interface OwnProps {
    /** Contents to render */
    children: React.ReactNode

    /**
     * Custom element to render this component to
     * @default 'div'
     */
    as?: React.ReactType

    /** Custom styling hook */
    className?: string

    /**
     * When true, no gutter is used
     * @default false
     */
    noGutters?: boolean
}

type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>

const Row = ({
    children,
    as: Component = 'div',
    className,
    noGutters = false,
    ...others
}: Props) => {
    const classes = cx(styles.row, { [styles.noGutters]: noGutters }, className)

    return (
        <Component className={classes} {...others}>
            {children}
        </Component>
    )
}

export default Row
