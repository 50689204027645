import { ConfigurableAttributeOption } from '@emico-hooks/graphql-schema-types'

import { encodeAttributeValueObject } from './flattenCombinedAttributes'
import { ConfigurableAttributeValue } from '../../common/MinimalConfigurableProductInfo.fragment'
import { ConfigurableAttributesFieldValue } from '../ConfigurableAttributesField/ConfigurableAttributesField'
import {
    getVariant,
    hasLimitedStock,
    isOutOfStock,
    limitedStockLabel,
    outOfStockLabel,
} from '../ConfigurableAttributesField/helpers'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { Product } from '../GetProduct.query'

export default function defaultAttributes(
    product: Product | ConfigurableProduct,
    getAttributeOptionValue: (
        attribute: ConfigurableAttributeValue,
    ) => string | number = (attr) => attr?.valueIndex ?? '',
    labelGenerator: (props: {
        label: string
        outOfStock: boolean
        limitedStock: boolean
        onlyXLeftInStock: number | null | undefined
        product: ConfigurableProduct
    }) => string = ({ label, limitedStock, outOfStock, onlyXLeftInStock }) =>
        [
            label,
            limitedStock &&
                onlyXLeftInStock != null &&
                limitedStockLabel(onlyXLeftInStock),
            outOfStock && outOfStockLabel(),
        ]
            .filter(Boolean)
            .join(' '),
) {
    const isConfigurable = Boolean(
        (product as ConfigurableProduct).configurableOptions,
    )

    const options: Array<{
        value: string
        label: string
        sku?: string
        disabled?: boolean
    }> = []

    if (!isConfigurable) {
        return options
    }

    for (const option of (product as ConfigurableProduct).configurableOptions) {
        for (const value of option.values) {
            const variant = getVariant(
                (product as ConfigurableProduct).variants,
                [value],
            )

            const onlyXLeftInStock = (variant && variant.product)
                ?.onlyXLeftInStock
            const limitedStock = hasLimitedStock(variant)
            const outOfStock = isOutOfStock(variant)
            const variantSku = variant?.product?.sku

            options.push({
                value: encodeAttributeValueObject({
                    //[option.attributeId]: value.uid,
                    [option.attributeId]: getAttributeOptionValue(value),
                }),
                label: labelGenerator({
                    label: value.storeLabel,
                    outOfStock,
                    limitedStock: Boolean(limitedStock),
                    onlyXLeftInStock,
                    product: product as ConfigurableProduct,
                }),
                sku: variantSku,
                disabled: outOfStock,
            })
        }
    }

    return options
}
