import { useBreakpoints } from '@emico/ui'

const useGridProps = (tablet?: string, desktop?: string) => {
    const { isTablet, isDesktop } = useBreakpoints()

    const gridInfo = isDesktop
        ? desktop?.split('/').map((value) => parseInt(value.trim(), 10))
        : isTablet
          ? tablet?.split('/').map((value) => parseInt(value.trim(), 10))
          : undefined

    // Return so grid uses default
    if (!gridInfo || gridInfo.length !== 2) {
        return
    }

    const columnSpan = gridInfo[0]
    const rowSpan = gridInfo[1]

    return {
        style: {
            gridArea: `span ${rowSpan} / span ${columnSpan} / auto / auto`,
        },
    }
}

export default useGridProps
