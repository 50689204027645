import { Picture } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { useBreakpoints } from '@emico/ui'

import isBrandedStore from './isBrandedStore'
import OpeningHours from './OpeningHours'
import storeImageUrl from './storeImageUrl'
import StoreInformation from './StoreInformation'
import { SrsStore } from './useSrsStore'
import CloseIcon from '../core/CloseIcon'
import DropdownIcon from '../core/DropdownIcon'
import { Responsive } from '../core/Responsive'
import Button from '../input/Button'
import Icon from '../media/Icon'
import paths from '../paths'
import breakpoints from '../theme/breakpoints'
import useResolveStoreUrl from '../useResolveStoreUrl'

export interface Props {
    selectedStore: SrsStore | undefined
    storeView: string
    onClose(): void
}

const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    margin: 15px 0;
`

const MobileHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 50px;
    background-color: #fff;
`

const DesktopHeader = styled.div`
    min-height: 60px;
    background-color: #fff;
`

const CloseButton = styled.button`
    height: 34px;
    margin-right: 10px;
    border: none;
    background: none;
`

const StyledPicture = styled(Picture)`
    width: 100%;
    height: 200px;
    object-fit: cover;
`

const ResultDetailOverlay = ({ selectedStore, storeView, onClose }: Props) => {
    const storeUrl = useResolveStoreUrl(
        selectedStore?.shopId?.toString() ?? undefined,
    )
    const { isMobile } = useBreakpoints()

    const brandedStore = isBrandedStore(selectedStore)

    const OverlayContainer = styled.div`
        ${isMobile
            ? selectedStore
                ? 'height: auto'
                : 'height: 0'
            : 'max-height: 500px'};
        ${!isMobile
            ? selectedStore
                ? 'display: block'
                : 'display: none;'
            : 'display: block'};

        position: ${isMobile ? 'fixed' : 'relative'};
        bottom: ${isMobile ? '0' : 'unset'};
        background-color: #fff;
        width: 100%;
        max-width: ${isMobile ? '' : '450px'};
        z-index: ${isMobile ? '1000' : '1'};
        ${isMobile && 'transition: max-height .6s ease-in;'}
        overflow: hidden;

        @media (min-device-width: 768px) {
            border-top: 1px solid #f2f2f2;
        }
        @media (min-device-width: 768px) and (max-device-width: 1024px) {
            max-height: calc(54vh);
        }
        @media (max-device-width: 767px) {
            ${isMobile && 'box-shadow: 0px 15px 20px 0px #000000;'}
        }
    `

    const ScrollContainer = styled.div`
        position: relative;
        max-height: 440px;
        background-color: #fff;
        padding-bottom: 10px;
        overflow-y: auto;

        @media (max-device-width: 767px) {
            max-height: calc(100vh - 110px);
        }
        @media (min-device-width: 768px) and (max-device-width: 1024px) {
            max-height: calc(54vh - 60px);
        }
    `

    const StoreInfo = styled.div`
        padding: ${isMobile ? '5px 30px 20px 30px' : '20px 30px 20px 30px'};
    `

    return (
        <OverlayContainer>
            <Responsive sm down>
                <MobileHeader>
                    <CloseButton type="button" onClick={onClose}>
                        <Icon component={CloseIcon} title="" />
                    </CloseButton>
                </MobileHeader>
            </Responsive>
            <Responsive md up>
                <DesktopHeader>
                    <Button
                        type="button"
                        name="Back"
                        category="storelocator.storedetails.backToResults"
                        variant="link"
                        onClick={onClose}
                        css={css`
                            color: #000;
                            height: 60px;
                            padding: 0 10px;
                            transition: color 0.2s;
                            &:hover {
                                color: ${process.env.REACT_APP_PRIMARY_COLOR};
                            }
                        `}
                    >
                        <Icon
                            component={DropdownIcon}
                            title=""
                            css={css`
                                transform: rotate(90deg);
                                margin-right: 7px;
                            `}
                        />
                        {t({
                            id: 'storelocator.storedetails.backToResults',
                            message: `Back to results`,
                        })}
                    </Button>
                </DesktopHeader>
            </Responsive>

            <ScrollContainer>
                <Responsive md up>
                    {brandedStore && selectedStore && (
                        <StyledPicture
                            breakpoints={{
                                [breakpoints.xs]: {
                                    url: storeImageUrl(
                                        selectedStore,
                                        storeView,
                                        true,
                                    ),
                                    width: 375,
                                    height: 200,
                                },
                                [breakpoints.md]: {
                                    url: storeImageUrl(
                                        selectedStore,
                                        storeView,
                                    ),
                                    width: 450,
                                    height: 200,
                                },
                            }}
                            alt={
                                selectedStore?.imageTitle
                                    ? selectedStore.imageTitle
                                    : ''
                            }
                            lazy={false}
                        />
                    )}
                </Responsive>
                <StoreInfo>
                    <StoreInformation store={selectedStore} />
                    {brandedStore && <Divider />}
                    {brandedStore && <OpeningHours store={selectedStore} />}

                    <Button
                        to={`https://www.google.com/maps/dir/?api=1&destination=${selectedStore?.gLat},${selectedStore?.gLong}`}
                        name="Directions"
                        category="storelocator.storedetails.storeDirections"
                        variant="primary"
                        wide
                        css={css`
                            margin: 20px 0;
                        `}
                    >
                        {t({
                            id: 'storelocator.storedetails.directions',
                            message: `Directions`,
                        })}
                    </Button>

                    {storeUrl && brandedStore && (
                        <Button
                            name="Visit store"
                            category="storelocator.storedetails.visitStore"
                            variant="secondary"
                            wide
                            to={`${paths.storeLocator}/${storeUrl}`}
                        >
                            {t({
                                id: 'storelocator.storedetails.visitStore',
                                message: `Visit store`,
                            })}
                        </Button>
                    )}
                </StoreInfo>
            </ScrollContainer>
        </OverlayContainer>
    )
}

export default ResultDetailOverlay
