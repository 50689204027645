import { GiftRule } from './reducers/__cart'
import { useSelector } from './types'

const useFreeGiftRules = () => {
    const rules = useSelector((state) => state.cart.gifts)

    if (!rules || rules.length === 0) {
        return { rules: [] as GiftRule[], nextRule: undefined }
    }

    return { rules }
}

export default useFreeGiftRules
