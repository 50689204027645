// Source: https://github.com/magento-research/pwa-studio/blob/develop/packages/venia-concept/src/actions/checkout/actions.js
import { createAction } from 'redux-actions'

export default {
    paymentMethod: {
        submit: createAction('CHECKOUT/PAYMENT_METHOD/SUBMIT'),
        accept: createAction('CHECKOUT/PAYMENT_METHOD/ACCEPT'),
        reject: createAction('CHECKOUT/PAYMENT_METHOD/REJECT'),
    },
    order: {
        submit: createAction('CHECKOUT/ORDER/SUBMIT'),
        accept: createAction('CHECKOUT/ORDER/ACCEPT'),
        reject: createAction('CHECKOUT/ORDER/REJECT'),
    },
    getShippingMethods: {
        request: createAction('CHECKOUT/GET_SHIPPING_METHODS/REQUEST'),
        receive: createAction('CHECKOUT/GET_SHIPPING_METHODS/RECEIVE'),
    },
    reset: createAction('CHECKOUT/RESET'),
    continueAsGuest: createAction('CHECKOUT/CONTINUE_AS_GUEST'),
    setUpdating: createAction('CHECKOUT/SET_UPDATING'),
    setGatewayInformation: createAction('CHECKOUT/SET_GATEWAY_INFORMATION'),
}
