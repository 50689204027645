import cx from 'classnames'
import * as React from 'react'

import styles from './Brands.module.scss'
import Button from '../../../input/Button'
import Col from '../../../layout/Col'
import Container from '../../../layout/Container'
import Row from '../../../layout/Row'
import ImageLazy from '../../../media/ImageLazy'
import { CmsPageBrandItem } from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'

interface Props extends CmsPageBrandItem {
    isActive: boolean
    index: number
}

const BrandTab = ({
    buttonText,
    heading,
    image,
    imageMobile,
    subheading,
    url,
    isActive,
    index,
}: Props) => {
    const activeClass = isActive ? styles.active : ''

    return (
        <div className={cx(activeClass, styles.brandContentTab)} key={index}>
            {image?.url && (
                <ImageLazy
                    alt={heading ?? ''}
                    images={{
                        image: image.url,
                        image_mobile: imageMobile?.url ?? image.url,
                        regularWidth: 2000,
                        mediumWidth: 1024,
                        smallWidth: 400,
                    }}
                    className={styles.brandImage}
                />
            )}

            <Container className={styles.brandContentTabInner}>
                <Row>
                    <Col>
                        <div className={styles.brandContentBlock}>
                            {heading && (
                                <Heading
                                    variant="h2"
                                    element="h3"
                                    color="light"
                                    className={styles.brandHeading}
                                >
                                    {heading}
                                </Heading>
                            )}

                            {subheading && (
                                <Heading
                                    variant="subheading"
                                    element="h4"
                                    color="light"
                                    className={styles.brandSubHeading}
                                >
                                    {subheading}
                                </Heading>
                            )}

                            {buttonText && url && (
                                <Button
                                    to={url}
                                    variant="default"
                                    className={styles.brandButton}
                                    wide
                                    name={buttonText}
                                    category="cms.homePage.brands"
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BrandTab
