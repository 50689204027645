import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useCallback, useEffect, useRef } from 'react'

import styles from './MobileModal.module.scss'
import { Props } from './ResponsiveModal'
import CloseIcon from '../../core/CloseIcon'
import Logo from '../../core/Logo'
import Container from '../../layout/Container'
import Icon from '../../media/Icon'
import useKeyPress from '../../useKeyPress'
import FullscreenNavigationModal from '../FullscreenNavigationModal/FullscreenNavigationModal'

const MobileModal = ({
    onBack,
    header,
    children,
    footer,
    enableScrollLock = true,
}: Props) => {
    const scrollContainer = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!scrollContainer.current || !enableScrollLock) {
            return
        }

        disableBodyScroll(scrollContainer.current, {
            // allow touch move on any element within an element with the `body-scroll-lock-ignore` attribute
            allowTouchMove: (el) => {
                while (
                    el &&
                    el !== document.body &&
                    typeof el.getAttribute === 'function'
                ) {
                    if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                        return true
                    }
                    el = el.parentNode as HTMLElement
                }
                return
            },
        })

        const oldContainer = scrollContainer.current

        return () => {
            if (!oldContainer) {
                return
            }

            enableBodyScroll(oldContainer)
        }
    }, [enableScrollLock, scrollContainer])

    const handleBack = useCallback(() => onBack && onBack(), [onBack])

    useKeyPress('Escape', handleBack)

    return (
        <FullscreenNavigationModal
            back={
                <Icon
                    component={CloseIcon}
                    color="dark"
                    title={t({
                        id: 'presentation.responsiveModal.back',
                        message: `close`,
                    })}
                />
            }
            onBack={onBack}
            title={
                <div className={styles.logo}>
                    <Logo location="header-small" variant="dark" />
                </div>
            }
            className={styles.modal}
            contentClassName={styles.modalContentWrapper}
        >
            {header && (
                <Container className={styles.header}>{header}</Container>
            )}
            <Container className={styles.content} ref={scrollContainer}>
                {children}
            </Container>

            {footer && (
                <Container className={styles.footer}>{footer}</Container>
            )}
        </FullscreenNavigationModal>
    )
}

export default MobileModal
