import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useActiveStoreView } from '@emico/storeviews'
import { useBreakpoints } from '@emico/ui'

import DynamicInput from './DynamicInput'
import ResultDetailOverlay from './ResultDetailOverlay'
import SearchResults from './SearchResults'
import SearchSuggestions, { Suggestion } from './SearchSuggestions'
import { SrsStore } from './useSrsStore'
import { LocationProps } from './useSrsStores'
import Button from '../input/Button'
import Heading from '../typography/Heading'
import useLazyGoogleMapsPlaceAutocomplete, {
    AutoCompleteResult,
} from '../useLazyGoogleMapsPlaceAutocomplete'
import useLazyGoogleMapsPlaceLocation from '../useLazyGoogleMapsPlaceLocation'
import { filterNullValues } from '../utils/filterNullValues'

const SearchFormContainer = styled.div`
    position: absolute;
    height: auto;
    top: 10px;
    padding: 0;
    margin: 10px;
    background-color: #fff;
    width: calc(100% - 20px);
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;
    border-radius: 3px;
    @media (max-width: 767px) {
        position: relative;
    }
    @media (min-width: 768px) {
        top: 20px;
        left: 20px;
        max-width: 450px;
    }
    @media (min-width: 1921px) {
        left: calc(50% - 940px);
    }
`

const SearchContent = styled.div`
    position: relative;
`

const SearchHeader = styled(Heading)`
    padding: 15px 0;
    border-bottom: 1px solid #f2f2f2;
    text-transform: uppercase;
    text-align: center;
`

interface Props {
    sku?: string
    storeView: string
    searchResults: SrsStore[] | undefined
    searchResultsLoading: boolean
    selectedStore: SrsStore | undefined
    onSelect(value: SrsStore): void
    setCurrentLocation(value: LocationProps): void
    onClose(): void
    resetForm(): void
    selectedStoreLocation?: LocationProps | undefined
    setSelectedStoreLocation(value: LocationProps | undefined): void
    storeLocatorPage?: boolean
    className?: string
}

// Delay in ms after which the address will be submitted autonatically
const ON_CHANGE_SUBMIT_DELAY = 500

const SearchForm = ({
    storeView,
    searchResults,
    searchResultsLoading,
    selectedStore,
    onSelect,
    setCurrentLocation,
    onClose,
    resetForm,
    setSelectedStoreLocation,
    storeLocatorPage = true,
    className,
}: Props) => {
    const [isVisible, setVisible] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const [suggestions, setSuggestions] = useState<Suggestion[]>([])
    const [placeId, setPlaceId] = useState<string>()
    const [submitted, setsubmitted] = useState<boolean>(false)
    const { isMobile } = useBreakpoints()

    const activeStoreView = useActiveStoreView()
    const { getAutoCompleteResults, data: autoCompleteResults } =
        useLazyGoogleMapsPlaceAutocomplete()

    const { getLocation, data: googlePlaceLocation } =
        useLazyGoogleMapsPlaceLocation()

    useEffect(() => {
        if (submitted || searchValue === '') {
            return
        }

        const suggestionsData: Suggestion[] =
            filterNullValues<AutoCompleteResult>(autoCompleteResults)?.reduce<
                Suggestion[]
            >((acc, { placeId, description }) => {
                if (placeId && description && description !== null) {
                    acc.push({
                        id: placeId,
                        description,
                    })
                }
                return acc
            }, []) || []

        setSuggestions(suggestionsData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCompleteResults, submitted])

    useEffect(() => {
        if (!placeId) {
            return
        }
        getLocation({
            variables: {
                placeId,
                language: activeStoreView.language,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId])

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }

                setCurrentLocation({
                    lat: pos.lat,
                    lng: pos.lng,
                })
            })
        } else {
            // Browser doesn't support Geolocation
        }
    }

    const [debouncedGetAutoCompleteResults] = useDebouncedCallback(
        getAutoCompleteResults,
        ON_CHANGE_SUBMIT_DELAY,
    )

    useEffect(() => {
        if (searchValue) {
            // get suggestions
            debouncedGetAutoCompleteResults({
                variables: {
                    input: searchValue,
                    language: activeStoreView.language, // Used to translate search suggestions, and to suggest more relevant results.
                    location: [49.40375755621581, 11.220535028868019], // We pick neurenberg as center for our radius.
                    radius: 1200 * 1000, // in meters, from neurenberg, so it covers the whole area where we have stores, from france to hungary.
                    strictBounds: true, // True because we don't want suggestions like "new york, USA"
                    components: null,
                },
            })
        } else {
            setSuggestions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    useEffect(() => {
        if (googlePlaceLocation && googlePlaceLocation !== null) {
            setSelectedStoreLocation({
                lat: googlePlaceLocation.geometry?.location?.lat ?? 0,
                lng: googlePlaceLocation.geometry?.location?.lng ?? 0,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [googlePlaceLocation])

    const handleReset = () => {
        setVisible(false)
        setSearchValue('')
        setPlaceId(undefined)
        resetForm()
    }

    const handleSubmit = () => {
        setsubmitted(true)
        setSuggestions([])
    }

    const submitFirstSuggestion = () => {
        if (!suggestions[0]) {
            return
        }
        setSearchValue(suggestions[0].description)
        setPlaceId(suggestions[0].id)
        handleSubmit()
    }

    const submitSuggestion = (suggestion: Suggestion) => {
        if (!suggestion.description || !suggestion.description) {
            return
        }
        setSearchValue(suggestion.description)
        setPlaceId(suggestion.id)
        handleSubmit()
    }

    return (
        <SearchFormContainer className={className}>
            {isMobile && (
                <SearchHeader element="h1" color="dark" variant="h3">
                    <Trans id="storelocator.page.storelocator">
                        Storelocator
                    </Trans>
                </SearchHeader>
            )}
            <DynamicInput
                isVisible={isVisible}
                searchValue={searchValue}
                handleReset={handleReset}
                setVisible={setVisible}
                setSearchValue={setSearchValue}
                submitFirstSuggestion={submitFirstSuggestion}
                getCurrentLocation={getCurrentLocation}
                submitted={submitted}
                setsubmitted={setsubmitted}
            />
            {isMobile && (
                <Button
                    name="title"
                    wide
                    variant="primary"
                    category="categoryElement.button"
                    onClick={() => setVisible(true)}
                >
                    Zoeken
                </Button>
            )}
            <SearchContent>
                {searchResults && (isMobile || !selectedStore) && (
                    <SearchResults
                        searchResults={searchResults}
                        searchResultsLoading={searchResultsLoading}
                        onSelectStoreDetail={onSelect}
                        onSelectStoreLocation={setSelectedStoreLocation}
                        storeLocator={storeLocatorPage}
                    />
                )}

                {storeLocatorPage && (
                    <ResultDetailOverlay
                        selectedStore={selectedStore}
                        storeView={storeView}
                        onClose={onClose}
                    />
                )}

                {isVisible && suggestions.length > 0 && (
                    <SearchSuggestions
                        suggestions={suggestions}
                        onSelect={submitSuggestion}
                    />
                )}
            </SearchContent>
        </SearchFormContainer>
    )
}

export default SearchForm
