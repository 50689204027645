/* eslint-disable import/order */
import { gql, TypedDocumentNode } from '@apollo/client'

import { cartAddressFragment } from './CartAddressFragment'
import { CartFragment } from './CartFragment.generated'

export const cartDetailsFragment = gql`
    fragment CartDetailsFragment on Cart {
        id
        email
        extensionAttributes {
            dhlparcelShippingServicepointId
            dhlparcelShippingServicepointCountryId
            shippingEstimateMin
        }
        availablePaymentMethods {
            code
            title
            logo
        }
        selectedPaymentMethod {
            code
            title
        }
        billingAddress {
            ...CartAddressFragment
        }
        shippingAddresses {
            ...CartAddressFragment
            selectedShippingMethod {
                amount {
                    value
                    currency
                }
                carrierCode
                methodCode
                priceInclTax {
                    value
                }
                optionCode
            }
            availableShippingMethods {
                available
                carrierCode
                carrierTitle
                methodCode
                methodTitle
                priceInclTax {
                    value
                    currency
                }
            }
        }
    }
    ${cartAddressFragment}
` as TypedDocumentNode<CartFragment, never>
