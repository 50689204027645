import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'
import { forwardRef } from 'react'

import { minWidth } from '@emico/styles'

import { useCartItems } from './CartPage/useCartItems'
import CartItem from './MiniCartItem'
import { useMiniCartVar } from './miniCartVar'
import NextGiftNotification from './NextGiftNotification'
import CheckIcon from '../core/CheckIcon/CheckIcon'
import Button from '../input/Button'
import Icon from '../media/Icon'
import MiniMenu, {
    MiniMenuFooter,
    MiniMenuContent,
    MiniMenuHeading,
    MiniMenuActions,
} from '../MiniMenu'
import paths from '../paths'
import theme from '../theme'
import useIsCheckoutAccessible from '../useIsCheckoutAccessible'

type ContainerProps = React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement>

const StyledMiniMenu = styled(MiniMenu)`
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;

    @media ${minWidth('md')} {
        right: -18px;
        width: 425px;
    }
`

export const MiniCartUspIcon = styled(Icon)`
    width: 12px;
    height: 12px;
    margin-right: 12px;
    fill: ${theme.colors.primary};
`

// eslint-disable-next-line react/display-name
const MiniCart = forwardRef<HTMLElement, ContainerProps>(
    ({ ...others }: ContainerProps, ref) => {
        const { items: cartItems, freeGifts: rules } = useCartItems()

        const { close } = useMiniCartVar()

        const cartItem = cartItems
            ?.filter((item) => !item.extensionAttributes.isFreeGift)
            .reverse()
            .at(0)

        const giftItems = rules?.filter(
            (rule) =>
                // Check for valid rules which are added to the cart:
                rule.isValidConditions &&
                rule?.items?.some((item) => item.item),
        )

        const canCheckout = useIsCheckoutAccessible()

        if (!cartItems) {
            return null
        }

        const currencyCode =
            cartItems?.at(0)?.prices?.price?.currency ?? undefined

        return (
            <StyledMiniMenu
                ref={ref}
                heading={
                    <Trans id="cart.miniCart.headingAddedToCart">
                        Added to cart
                    </Trans>
                }
                {...others}
            >
                <MiniMenuContent>
                    {cartItem && currencyCode && (
                        <CartItem cartItem={cartItem} currency={currencyCode} />
                    )}
                    {giftItems && giftItems.length > 0 && (
                        <>
                            <MiniMenuHeading>
                                <Plural
                                    id="cart.miniCart.headingAddedGiftsToCart"
                                    value={giftItems.length}
                                    one="Gift in your cart"
                                    other="Gifts in your cart"
                                />
                            </MiniMenuHeading>
                            {giftItems.map(
                                (item) =>
                                    item &&
                                    currencyCode && (
                                        <CartItem
                                            key={item.id}
                                            variant="gift"
                                            cartItem={
                                                item.items?.find(
                                                    (itm) => itm.item,
                                                )?.item
                                            }
                                            currency={currencyCode}
                                        />
                                    ),
                            )}
                        </>
                    )}
                    <NextGiftNotification variant="minicart" />
                </MiniMenuContent>
                <MiniMenuActions>
                    <Button
                        to={paths.cart}
                        onClick={() => {
                            close()
                        }}
                        variant="secondary"
                        name="View cart"
                        category="cart.miniCart.viewCart"
                        data-testid="cart.miniCart.viewCart"
                    >
                        <Trans id="cart.miniCart.cartButtonLabel">
                            View bag
                        </Trans>
                    </Button>

                    {canCheckout && (
                        <Button
                            to={paths.checkout}
                            onClick={() => {
                                close()
                            }}
                            variant="primary"
                            name="Checkout"
                            category="cart.miniCart.checkout"
                            data-testid="cart.mini.placeOrder"
                        >
                            <Trans id="cart.miniCart.checkoutButtonLabel">
                                Checkout
                            </Trans>
                        </Button>
                    )}
                </MiniMenuActions>
                <MiniMenuFooter centerText>
                    <MiniCartUspIcon component={CheckIcon} title="" />
                    <Trans id="cart.miniCart.usp">
                        Free delivery & returns
                    </Trans>
                </MiniMenuFooter>
            </StyledMiniMenu>
        )
    },
)

export default MiniCart
