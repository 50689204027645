import styled from '@emotion/styled'
import { useState } from 'react'

import {
    PrismicImageType,
    PrismicRichText,
    RichTextBlock,
} from '@emico/prismic'

import { AccordionItem } from './Accordion'
import { Responsive } from './core/Responsive'
import FaqMenu, { FaqMenuItem } from './FaqMenu'
import FaqQuestions from './FaqQuestions'
import {
    CastironPageBodyFaq,
    JbfoPageBodyFaq,
    JustbrandsPageBodyFaq,
    MonstersalePageBodyFaq,
    PageBodyFaq,
    PageBodyFaqFields,
    PmeOutletPageBodyFaq,
    StorelocatorPageBodyFaq,
    VanguardPageBodyFaq,
} from './graphql/schema.generated'
import { getPrismicComponents } from './htmlSerializer'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import getLayoutStyleVars from './LayoutStyle'
import theme from './theme'
import { filterNullValues } from './utils/filterNullValues'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceFaqType =
    | PageBodyFaq
    | VanguardPageBodyFaq
    | CastironPageBodyFaq
    | JustbrandsPageBodyFaq
    | JbfoPageBodyFaq
    | MonstersalePageBodyFaq
    | PmeOutletPageBodyFaq
    | StorelocatorPageBodyFaq

interface Props {
    slice: PrismicSliceFaqType
}

export interface Image {
    url: string
    alt?: string
}

interface QuestionFaq {
    category: string
    content: unknown
    title: string
    icon: PrismicImageType
}

interface CategoryArrayI {
    id: string
    name: string
    iconUrl: string
}
interface CategoryState {
    initial: boolean
    id: string
    title: string
}

const Section = styled.section`
    padding: 75px 0;
    background: ${theme.colors.background};
`

const getCategory = (question: QuestionFaq) =>
    sanitizeCategory(question.category).id

const dedupedCategories = (questions: QuestionFaq[]) => {
    const categoryArray: CategoryArrayI[] = []

    questions.forEach((question) => {
        const sanitizedCategory = sanitizeCategory(question.category)

        if (!categoryArray.find((cat) => cat.id === sanitizedCategory.id)) {
            categoryArray.push({
                name: sanitizedCategory.name,
                id: sanitizedCategory.id,
                iconUrl: question.icon.url ?? null,
            })
        }
    })
    return categoryArray
}

const sanitizeCategory = (category: string) => {
    const sanitizedCategory = category.toLowerCase().replace(/ /g, '')

    return { id: sanitizedCategory, name: category }
}

const PrismicSliceFaq = ({ slice: { primary, fields } }: Props) => {
    const [activeItem, setActiveItem] = useState(0)
    const questions: QuestionFaq[] = (
        (fields as PageBodyFaqFields[]) ?? []
    ).reduce<QuestionFaq[]>((acc, field) => {
        // Dont show items with no main image or no url configured

        if (
            field?.title &&
            field?.content &&
            field?.title &&
            field?.iconimage
        ) {
            const { title, content, category, iconimage } = field

            acc.push({
                content,
                title: title ?? '',
                category: category ?? '',
                icon: iconimage,
            })
        }
        return acc
    }, [])

    const filteredQuestions = filterNullValues(questions) || []

    const category =
        filteredQuestions.length > 0
            ? sanitizeCategory(filteredQuestions[0].category)
            : undefined

    const [activeCategory, setActiveCategory] = useState<CategoryState>({
        initial: true,
        id: category?.id ?? '',
        title: category?.name ?? '',
    })

    if (!filteredQuestions.length || filteredQuestions.length === 0) {
        return null
    }
    if (!primary) {
        return null
    }

    const { emptyCategoryText, style } = primary

    if (!emptyCategoryText) {
        return null
    }

    const categories = dedupedCategories(questions)

    const items = filteredQuestions
        .filter((question) => getCategory(question) === activeCategory.id)
        .reduce<AccordionItem[]>((acc, { title, content }) => {
            if (title && content) {
                acc.push({
                    heading: title,
                    content: (
                        <PrismicRichText
                            render={content as [RichTextBlock]}
                            components={getPrismicComponents({})}
                            analyticsContext="faq.question.content"
                        />
                    ),
                })
            }
            return acc
        }, [])

    const menuItems = categories.map((c) => ({
        title: c.name,
        url: `#${sanitizeCategory(c.name).id}`,
        icon: c.iconUrl,
    }))

    const layoutStyle = getLayoutStyle(style)
    const layoutStyleVars = getLayoutStyleVars(layoutStyle)

    const handleBackClick = () => {
        setActiveCategory({
            initial: false,
            id: '',
            title: '',
        })
    }

    const handleMenuItemClick = (item: FaqMenuItem, index: number) => {
        const cat = sanitizeCategory(item.title)

        setActiveCategory({
            initial: false,
            id: cat.id,
            title: cat.name,
        })
        setActiveItem(index)
    }

    return (
        <Section css={layoutStyleVars}>
            <Container>
                {categories.length === 1 ? (
                    <Row>
                        <Col lg={{ order: 1, span: 10, offset: 1 }}>
                            <FaqQuestions
                                style={layoutStyle}
                                questions={items}
                                title={activeCategory.title}
                                onBackClick={handleBackClick}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col md={6} lg={{ order: 0, span: 3, offset: 1 }}>
                            <Responsive md up>
                                <FaqMenu
                                    activeItem={activeItem}
                                    items={menuItems}
                                    style={layoutStyle}
                                    onItemClick={handleMenuItemClick}
                                />
                            </Responsive>
                            <Responsive sm down>
                                <>
                                    {(!activeCategory.id ||
                                        activeCategory.initial) && (
                                        <FaqMenu
                                            activeItem={activeItem}
                                            items={menuItems}
                                            style={layoutStyle}
                                            onItemClick={handleMenuItemClick}
                                        />
                                    )}

                                    {activeCategory.id &&
                                        !activeCategory.initial && (
                                            <FaqQuestions
                                                style={layoutStyle}
                                                questions={items}
                                                title={activeCategory.title}
                                                onBackClick={handleBackClick}
                                            />
                                        )}
                                </>
                            </Responsive>
                        </Col>

                        <Responsive md up>
                            <Col md={6} lg={{ order: 1, span: 6, offset: 1 }}>
                                <FaqQuestions
                                    style={layoutStyle}
                                    questions={items}
                                    title={activeCategory.title}
                                    onBackClick={handleBackClick}
                                />
                            </Col>
                        </Responsive>
                    </Row>
                )}
            </Container>
        </Section>
    )
}

export default PrismicSliceFaq
