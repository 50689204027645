import { Fragment } from 'react'

import StoriesOverviewHeader from './cms-pages/StoriesOverview/StoriesOverviewHeader/StoriesOverviewHeader'
import StoriesOverviewPage from './cms-pages/StoriesOverview/StoriesOverviewPage'
import {
    CastironFaqPageBody,
    CastironPageBody,
    FaqPageBody,
    JbfoFaqPageBody,
    JbfoPageBody,
    JustbrandsFaqPageBody,
    JustbrandsPageBody,
    MonstersaleFaqPageBody,
    MonstersalePageBody,
    PageBody,
    PageBodyFaqSubject,
    PageBodyForm,
    PmeOutletFaqPageBody,
    PmeOutletPageBody,
    StorelocatorPageBody,
    VanguardFaqPageBody,
    VanguardPageBody,
} from './graphql/schema.generated'
import PrismicSliceBannerBlocksFour from './PrismicSliceBannerBlocksFour'
import PrismicSliceBannerBlocksThree from './PrismicSliceBannerBlocksThree'
import PrismicSliceBannerBlocksTwo from './PrismicSliceBannerBlocksTwo'
import PrismicSliceBrand from './PrismicSliceBrand'
import PrismicSliceCategoryList from './PrismicSliceCategoryList'
import PrismicSliceFaq from './PrismicSliceFaq'
import { PrismicSliceFaqSubject } from './PrismicSliceFaqSubject'
import { PrismicSliceForm } from './PrismicSliceForm'
import PrismicSliceHero from './PrismicSliceHero'
import PrismicSliceHtml from './PrismicSliceHtml'
import PrismicSliceImageOnly from './PrismicSliceImageOnly'
import PrismicSliceLinkList from './PrismicSliceLinkList'
import PrismicSliceMemberUsps from './PrismicSliceMemberUsps'
import PrismicSliceOverlappingImages from './PrismicSliceOverlappingImages'
import PrismicSliceProductHero from './PrismicSliceProductHero'
import PrismicSliceStore from './PrismicSliceStore'
import PrismicSliceTeam from './PrismicSliceTeam'
import PrismicSliceTextOnly from './PrismicSliceTextOnly'
import PrismicSliceTextWithMedia from './PrismicSliceTextWithMedia'
import PrismicSliceTileSlider from './PrismicSliceTileSlider'
import PrismicSliceTweakwiseSlider from './PrismicSliceTweakwiseSlider'
import PrismicSliceTwoimageswithheading from './PrismicSliceTwoimageswithheading'
import PrismicSliceVisualFilter from './PrismicSliceVisualFilter'

export type PrismicCmsSlice =
    | PageBody
    | FaqPageBody
    | PageBodyFaqSubject
    | PageBodyForm
    | VanguardPageBody
    | VanguardFaqPageBody
    | CastironPageBody
    | CastironFaqPageBody
    | JustbrandsPageBody
    | JustbrandsFaqPageBody
    | JbfoPageBody
    | JbfoFaqPageBody
    | MonstersalePageBody
    | MonstersaleFaqPageBody
    | PmeOutletPageBody
    | PmeOutletFaqPageBody
    | StorelocatorPageBody

export const renderSlice = (slice: PrismicCmsSlice, prismicType?: string) => {
    switch (slice.__typename) {
        case 'PageBodyVisualFilter':
        case 'CastironPageBodyVisualFilter':
        case 'VanguardPageBodyVisualFilter':
        case 'JustbrandsPageBodyVisualFilter':
            return <PrismicSliceVisualFilter slice={slice} />
        case 'PageBodyFaq':
        case 'VanguardPageBodyFaq':
        case 'CastironPageBodyFaq':
        case 'JustbrandsPageBodyFaq':
        case 'JbfoPageBodyFaq':
        case 'MonstersalePageBodyFaq':
        case 'PmeOutletPageBodyFaq':
        case 'StorelocatorPageBodyFaq':
            return <PrismicSliceFaq slice={slice} />
        case 'PageBodyHero':
        case 'VanguardPageBodyHero':
        case 'CastironPageBodyHero':
        case 'JustbrandsPageBodyHero':
        case 'JbfoPageBodyHero':
        case 'MonstersalePageBodyHero':
        case 'PmeOutletPageBodyHero':
        case 'FaqPageBodyHero':
        case 'VanguardFaqPageBodyHero':
        case 'CastironFaqPageBodyHero':
        case 'JustbrandsFaqPageBodyHero':
        case 'JbfoFaqPageBodyHero':
        case 'MonstersaleFaqPageBodyHero':
        case 'PmeOutletFaqPageBodyHero':
        case 'StorelocatorPageBodyHero':
            return <PrismicSliceHero slice={slice} />
        case 'PageBodyTextOnly':
        case 'VanguardPageBodyTextOnly':
        case 'CastironPageBodyTextOnly':
        case 'JustbrandsPageBodyTextOnly':
        case 'JbfoPageBodyTextOnly':
        case 'MonstersalePageBodyTextOnly':
        case 'PmeOutletPageBodyTextOnly':
        case 'StorelocatorPageBodyTextOnly':
            return <PrismicSliceTextOnly slice={slice} />
        case 'PageBodyImageOnly':
        case 'VanguardPageBodyImageOnly':
        case 'CastironPageBodyImageOnly':
        case 'JustbrandsPageBodyImageOnly':
        case 'JbfoPageBodyImageOnly':
        case 'MonstersalePageBodyImageOnly':
        case 'PmeOutletPageBodyImageOnly':
        case 'StorelocatorPageBodyImageOnly':
            return <PrismicSliceImageOnly slice={slice} />
        case 'PageBodyTextWithMedia':
        case 'VanguardPageBodyTextWithMedia':
        case 'CastironPageBodyTextWithMedia':
        case 'JustbrandsPageBodyTextWithMedia':
        case 'JbfoPageBodyTextWithMedia':
        case 'MonstersalePageBodyTextWithMedia':
        case 'PmeOutletPageBodyTextWithMedia':
        case 'StorelocatorPageBodyTextWithMedia':
            return <PrismicSliceTextWithMedia slice={slice} />
        case 'PageBodyProductHero':
        case 'VanguardPageBodyProductHero':
        case 'CastironPageBodyProductHero':
        case 'JustbrandsPageBodyProductHero':
        case 'MonstersalePageBodyProductVideoHero':
        case 'JbfoPageBodyProductVideoHero':
        case 'PmeOutletPageBodyProductHero':
            return <PrismicSliceProductHero slice={slice} />
        case 'PageBodyTweakwiseSlider':
        case 'VanguardPageBodyTweakwiseSlider':
        case 'CastironPageBodyTweakwiseSlider':
        case 'JustbrandsPageBodyTweakwiseSlider':
        case 'JbfoPageBodyTweakwiseSlider':
        case 'MonstersalePageBodyTweakwiseSlider':
        case 'PmeOutletPageBodyTweakwiseSlider':
            return <PrismicSliceTweakwiseSlider slice={slice} />
        case 'PageBodyBannerBlocks':
        case 'VanguardPageBodyBannerBlocks':
        case 'CastironPageBodyBannerBlocks':
        case 'JustbrandsPageBodyBannerBlocks':
        case 'JbfoPageBodyBannerBlocks':
        case 'MonstersalePageBodyBannerBlocks':
        case 'PmeOutletPageBodyBannerBlocks':
        case 'StorelocatorPageBodyBannerBlocks':
            return <PrismicSliceBannerBlocksFour slice={slice} />
        case 'PageBodyBannerBlocks1':
        case 'VanguardPageBodyBannerBlocks1':
        case 'CastironPageBodyBannerBlocks1':
        case 'JustbrandsPageBodyBannerBlocks1':
        case 'JbfoPageBodyBannerBlocks1':
        case 'MonstersalePageBodyBannerBlocks1':
        case 'PmeOutletPageBodyBannerBlocks1':
        case 'StorelocatorPageBodyBannerBlocks1':
            return <PrismicSliceBannerBlocksThree slice={slice} />
        case 'PageBodyBannerBlocks2':
        case 'VanguardPageBodyBannerBlocks2':
        case 'CastironPageBodyBannerBlocks2':
        case 'JustbrandsPageBodyBannerBlocks2':
        case 'JbfoPageBodyBannerBlocks2':
        case 'MonstersalePageBodyBannerBlocks2':
        case 'PmeOutletPageBodyBannerBlocks2':
        case 'StorelocatorPageBodyBannerBlocks2':
            return <PrismicSliceBannerBlocksTwo slice={slice} />
        case 'PageBodyImageSlider':
        case 'VanguardPageBodyImageSlider':
        case 'CastironPageBodyImageSlider':
        case 'JustbrandsPageBodyImageSlider':
        case 'JbfoPageBodyTileSlider':
        case 'MonstersalePageBodyTileSlider':
        case 'PmeOutletPageBodyImageSlider':
        case 'StorelocatorPageBodyImageSlider':
            return <PrismicSliceTileSlider slice={slice} />
        case 'VanguardPageBodyTwoimageswithheading':
            return <PrismicSliceTwoimageswithheading slice={slice} />
        case 'CastironPageBodyOverlappingImages':
            return <PrismicSliceOverlappingImages slice={slice} />
        case 'PageBodyHtml':
        case 'CastironPageBodyHtml':
        case 'JustbrandsPageBodyHtml':
        case 'VanguardPageBodyHtml':
        case 'MonstersalePageBodyHtml':
        case 'JbfoPageBodyHtml':
        case 'PmeOutletPageBodyHtml':
        case 'StorelocatorPageBodyHtml':
            return <PrismicSliceHtml slice={slice} />
        case 'JustbrandsPageBodyBrand':
        case 'JbfoPageBodyBrand':
            return <PrismicSliceBrand slice={slice} />
        case 'JustbrandsPageBodyStore':
        case 'JbfoPageBodyStore':
            return <PrismicSliceStore slice={slice} />
        case 'JbfoPageBodyTeam':
        case 'JustbrandsPageBodyTeam':
            return <PrismicSliceTeam slice={slice} />
        case 'PageBodyCategoryElement':
        case 'JbfoPageBodyCategoryElement':
        case 'JustbrandsPageBodyCategoryElement':
        case 'MonstersalePageBodyCategoryElement':
        case 'CastironPageBodyCategoryElement':
        case 'VanguardPageBodyCategoryElement':
        case 'PmeOutletPageBodyCategoryElement':
        case 'StorelocatorPageBodyCategoryElement':
            return <PrismicSliceCategoryList slice={slice} />
        case 'PageBodyCategoryList':
        case 'JbfoPageBodyCategoryList':
        case 'JustbrandsPageBodyCategoryList':
        case 'MonstersalePageBodyCategoryList':
        case 'CastironPageBodyCategoryList':
        case 'VanguardPageBodyCategoryList':
        case 'PmeOutletPageBodyCategoryList':
        case 'StorelocatorPageBodyCategoryList':
            return <PrismicSliceLinkList slice={slice} />
        case 'PageBodyStoriesOverviewHeader':
        case 'JustbrandsPageBodyStoriesOverviewHeader':
        case 'CastironPageBodyStoriesOverviewHeader':
        case 'VanguardPageBodyStoriesOverviewHeader':
            return <StoriesOverviewHeader slice={slice} />
        case 'PageBodyStoryOverviewPage':
        case 'JustbrandsPageBodyStoryOverviewPage':
        case 'CastironPageBodyStoryOverviewPage':
        case 'VanguardPageBodyStoryOverviewPage':
            return <StoriesOverviewPage prismicType={prismicType} />
        case 'PageBodyMemberUspS':
            return <PrismicSliceMemberUsps slice={slice} />
        case 'PageBodyFaqSubject':
            return <PrismicSliceFaqSubject slice={slice} />
        case 'PageBodyForm':
        case 'JustbrandsPageBodyForm':
            return <PrismicSliceForm slice={slice?.primary} />
        default:
            // Every option should be implemented, so slice should be a `never`
            console.warn(`Slice type "${slice.__typename}" not implemented`)
            return null
    }
}

interface Props {
    slices: PrismicCmsSlice[]
    prismicType?: string
}

const PrismicCmsSlices = ({ slices, prismicType }: Props) => (
    <>
        {slices.map((slice, index) => (
            <Fragment key={index}>{renderSlice(slice, prismicType)}</Fragment>
        ))}
    </>
)

export default PrismicCmsSlices
