/**
 * Language picker to select languages for multi-language sites
 */

import { ReactNode } from 'react'
import Cookies from 'universal-cookie'

import { ButtonUnstyled } from '@emico/ui'

import styles from './LanguagePicker.module.scss'
import {
    languages,
    supportedLanguages,
    useLanguagePicker,
} from './useLanguagePicker'
import { FlagBeIcon } from '../../flags/BE'
import Button from '../../input/Button'
import MiniMenu from '../../MiniMenu'

const flags: Record<string, ReactNode> = {
    be: <FlagBeIcon />,
}

export function LanguagePicker() {
    const {
        pickerState,
        setPickerState,
        country,
        language,
        messages,
        onSwitch,
        showSwitcher,
    } = useLanguagePicker()

    if (!showSwitcher) {
        return null
    }

    return (
        <li className={styles.listItem}>
            <ButtonUnstyled
                className={styles.languagePicker}
                analyticsName=""
                analyticsContext=""
                onClick={() =>
                    setPickerState((state) =>
                        state === 'hidden' ? 'requested' : 'hidden',
                    )
                }
            >
                {flags[country] ?? country.toUpperCase()}
                <span>|</span>
                <span>{language.toUpperCase()}</span>
            </ButtonUnstyled>
            {pickerState !== 'hidden' && (
                <MiniMenu
                    className={styles.miniMenu}
                    onClose={() => {
                        setPickerState('hidden')
                        const cookies = new Cookies()
                        cookies.set('language-picker-dismissed', 'true', {
                            path: '/',
                            maxAge: 31536000,
                        })
                    }}
                    heading={messages.selectLanguage}
                >
                    <div className={styles.content}>
                        {messages.languageQuestion[pickerState]}
                        {supportedLanguages.map((language) => (
                            <Button
                                variant="secondary"
                                key={language}
                                name=""
                                category=""
                                onClick={() => onSwitch(language)}
                            >
                                {languages[language]}
                            </Button>
                        ))}
                    </div>
                </MiniMenu>
            )}
        </li>
    )
}
