import {
    HashLink as Link,
    NavHashLink as NavLink,
    NavHashLinkProps as NavLinkProps,
} from 'react-router-hash-link'

import isRelative from '../../utils/url/isRelative'

interface OwnProps {
    /**
     * A string representation of the location to link to,
     * created by concatenating the location’s pathname, search, and hash properties
     */
    to: string

    // Link state
    // eslint-disable-next-line @typescript-eslint/ban-types
    state?: object

    /**
     * When true, clicking the link will replace the current entry in the history stack instead of adding a new one.
     */
    replace?: NavLinkProps['replace']

    /**
     * When true, the active class/style will only be applied if the location is matched exactly.
     */
    exact?: NavLinkProps['exact']

    /**
     * When true, the trailing slash on a location’s pathname will be taken into consideration when determining
     * if the location matches the current URL.
     */
    strict?: NavLinkProps['strict']

    /**
     * class that is set on the element when it is active.
     * The active state will be determined automatically based on the page url
     */
    activeClassName?: NavLinkProps['activeClassName']

    /**
     * A function to add extra logic for determining whether the link is active.
     * This should be used if you want to do more than verify that the link’s pathname matches the current URL’s pathname.
     */
    isActive?: NavLinkProps['isActive']

    /**
     * The isActive compares the current history location (usually the current browser URL).
     * To compare to a different location, a location can be passed
     */
    location?: NavLinkProps['location']

    /**
     * Custom styling hook
     */
    className?: NavLinkProps['className']

    /**
     * reflects the target HTML attribute, indicating where to display the linked resource
     */
    target?: NavLinkProps['target']

    /**
     * Contents of the link.
     * This can be a string, but also a <Heading /> component or some custom component.
     */
    children: NavLinkProps['children']
}
export type Props = OwnProps & NavLinkProps

const RouteLink = ({
    to,
    target,
    children,
    activeClassName,
    isActive,
    exact,
    strict,
    state,
    ...others
}: Props) => {
    // todo, support object and function
    if (!isRelative(to)) {
        to = `/${to}`
    }

    // NavLinks or Links should never get a target: _self, as it causes a reload.
    const linkTarget = target === '_self' ? undefined : target
    const LinkComponent = activeClassName ? NavLink : Link
    const navLinkProps = activeClassName
        ? { activeClassName, isActive, exact, strict }
        : {}

    return (
        <LinkComponent
            smooth
            to={
                state
                    ? {
                          pathname: to.startsWith('#') ? undefined : to,
                          hash: to.includes('#')
                              ? to.slice(to.indexOf('#') + 1)
                              : undefined,
                          state,
                      }
                    : to
            }
            target={linkTarget}
            {...navLinkProps}
            {...others}
        >
            {children}
        </LinkComponent>
    )
}

export default RouteLink
