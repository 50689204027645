import cx from 'classnames'
import * as React from 'react'

import Breadcrumb, { Props as BreadcrumbProps } from './Breadcrumb'
import styles from './Breadcrumbs.module.scss'

type Props = React.HTMLAttributes<HTMLOListElement> & { className?: string }

const Breadcrumbs = ({ className, children, ...otherProps }: Props) => {
    const breadcrumbs = React.Children.toArray(children)

    if (breadcrumbs.length === 0) {
        return null
    }

    return (
        <ol
            itemScope
            itemType="https://schema.org/BreadcrumbList"
            className={cx(styles.base, className)}
            {...otherProps}
        >
            {breadcrumbs.map((breadcrumb, i) => {
                if (!React.isValidElement(breadcrumb)) {
                    return null
                }

                return React.cloneElement(
                    breadcrumb as React.ReactElement<BreadcrumbProps>,
                    {
                        isActive: i === breadcrumbs.length - 1,
                        position: i + 1,
                    },
                )
            })}
        </ol>
    )
}

Breadcrumbs.Link = Breadcrumb

export default Breadcrumbs
