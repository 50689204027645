import cx from 'classnames'
import * as React from 'react'

import styles from './Team.module.scss'
import TeamProduct from './TeamProduct'
import ErrorBoundary from '../../../ErrorBoundary'
import Col from '../../../layout/Col'
import Container from '../../../layout/Container'
import Row from '../../../layout/Row'
import ImageLazy from '../../../media/ImageLazy'
import { CmsPageTeamItem } from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'
import Text from '../../../typography/Text'

interface Props extends CmsPageTeamItem {
    isActive: boolean
    index: number
}

const TeamTab = ({
    bigImage,
    bigImageMobile,
    fullName,
    heading,
    jobTitle,
    productId,
    quote,
    smallImage,
    subheading,
    isActive,
    index,
}: Props) => {
    const activeClass = isActive ? styles.active : ''

    return (
        <div className={cx(activeClass, styles.employeeContentTab)} key={index}>
            {bigImage?.url && (
                <ImageLazy
                    alt={fullName ? fullName : ''}
                    images={{
                        image: bigImage.url,
                        image_mobile: bigImageMobile?.url ?? bigImage.url,
                        regularWidth: 2000,
                        mediumWidth: 1024,
                        smallWidth: 400,
                    }}
                    className={styles.employeeImage}
                />
            )}

            <Container className={styles.employeeContentTabInner}>
                <Row>
                    <Col>
                        <div className={styles.employeeContentBlock}>
                            <Heading
                                variant="h4"
                                element="h3"
                                color="light"
                                className={styles.employeeHeading}
                            >
                                {heading}
                            </Heading>
                            <Text
                                as="div"
                                color="light"
                                className={styles.employeeSubHeading}
                            >
                                {subheading}
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className={styles.employeeQuoteWrapper}>
                {quote && (
                    <Heading
                        variant="subheading"
                        element="div"
                        color="dark"
                        className={styles.employeeQuote}
                    >
                        “{quote}”
                    </Heading>
                )}
                {fullName && (
                    <Text
                        as="div"
                        color="dark"
                        className={styles.employeeFullName}
                    >
                        {fullName}
                    </Text>
                )}
                {jobTitle && (
                    <Text as="div" color="dark" className={styles.employeeJob}>
                        {jobTitle}
                    </Text>
                )}
            </div>

            {productId && (
                <ErrorBoundary fallback={<React.Fragment />}>
                    <div className={styles.employeeBottomWrapper}>
                        <Container className={styles.employeeBottomInner}>
                            <Row>
                                <Col
                                    xs={3}
                                    lg={{ order: 0, span: 3, offset: 1 }}
                                >
                                    <TeamProduct
                                        productId={productId}
                                        className={styles.employeeBottomProduct}
                                    />
                                </Col>

                                <Col
                                    xs={9}
                                    lg={{ order: 1, span: 7, offset: 0 }}
                                >
                                    {smallImage?.url && (
                                        <ImageLazy
                                            alt={fullName ?? ''}
                                            images={{
                                                image: smallImage.url,
                                                regularWidth: 1200,
                                                mediumWidth: 800,
                                                smallWidth: 400,
                                            }}
                                            className={
                                                styles.employeeBottomImage
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ErrorBoundary>
            )}
        </div>
    )
}

export default TeamTab
