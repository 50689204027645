import { Router as ReactRouter } from 'react-router-dom'

import history from '@emico/history'

interface Props {
    children: React.ReactNode
}

const RouterProvider = ({ children }: Props) => (
    // ! added since this is never SSR'ed for now.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <ReactRouter history={history!}>{children}</ReactRouter>
)

export default RouterProvider
