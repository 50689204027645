import styled from '@emotion/styled'
import * as React from 'react'

import Container from './layout/Container'
import getLayoutStyleVars from './LayoutStyle'
import LinkList from './LinkList'
import usePrismicSliceCategoryList, {
    PrismicSliceCategoryList,
} from './mapPrismicSliceCategoryList'
import theme from './theme'

interface Props {
    slice: PrismicSliceCategoryList
}

const Section = styled.section`
    background: ${theme.colors.background};
`

const PrismicSliceLinkList = ({ slice }: Props) => {
    const { style, heading, items } = usePrismicSliceCategoryList(slice) || {}
    const layoutStyle = getLayoutStyleVars(style || 'light')

    if (!items || items.length === 0) {
        return null
    }

    return (
        <Section css={layoutStyle}>
            <Container verticalPadding>
                <LinkList heading={heading} items={items} style={style} />
            </Container>
        </Section>
    )
}

export default PrismicSliceLinkList
