import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import styles from './FilterBarMobile/FilterModal.module.scss'
import Button from '../../../../input/Button'

interface Props {
    isDirty: boolean
    clear(): void
    setActiveFilter(): void
    loading: boolean
    numResults: number
}

const FilterButtons = ({
    isDirty,
    clear,
    setActiveFilter,
    loading,
    numResults: _numResults,
}: Props) => {
    const numResults = Intl.NumberFormat(i18n.locale).format(_numResults)
    return (
        <div className={styles.results}>
            {isDirty && (
                <Button
                    variant="secondary"
                    wide
                    onClick={clear}
                    name="Clear filters"
                    category="catalog.productFilterPage.filterBar.clearFilters"
                    css={css`
                        margin-bottom: 10px;
                    `}
                >
                    <Trans id="catalog.filterListModal.clearFilters">
                        Clear All Filters
                    </Trans>
                </Button>
            )}

            <Button
                variant="primary"
                wide
                onClick={setActiveFilter}
                name="See results"
                category="catalog.productFilterPage.filterBar.seeResults"
                loading={loading}
            >
                <Trans id="catalog.filterListModal.seeResults">
                    See {numResults} results
                </Trans>
            </Button>
        </div>
    )
}

export default FilterButtons
