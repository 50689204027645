import { Reducer } from 'redux'

import { State } from './rootReducer'
import {
    CoreConfig,
    CoreConfigActionTypes,
    SetCoreConfigAction,
} from '../actions'

export type CoreConfigState = CoreConfig | null

const reducer: Reducer<CoreConfigState, SetCoreConfigAction> = (
    state: CoreConfigState = null,
    action: SetCoreConfigAction,
): CoreConfigState => {
    switch (action.type) {
        case CoreConfigActionTypes.SET_CORE_CONFIG:
            return action.payload
        default:
            return state
    }
}

export default reducer

export function getPaymentMethods(state: State): string[] | undefined {
    if (!state.coreConfig) {
        return undefined
    }
    return [
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_1'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_2'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_3'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_4'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_5'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_6'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_7'
        ],
        state.coreConfig[
            'justbrands_footer/paymentmethod_images/payment_method_8'
        ],
    ].filter((a) => a) as string[]
}
