import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

const Grid = styled.div`
    max-width: 100%;
    margin: 0 auto;

    @media ${minWidth('md')} {
        display: grid;
        // On portrait tablet, use full with of the screen
        // and use a 3 column layout
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;

        // Direct children are the grid cells
        // By default, they should span the whole row
        > * {
            grid-column: span 3;
        }
    }

    @media ${minWidth('lg')} {
        grid-template-columns: repeat(4, 1fr);

        > * {
            grid-column: span 4;
        }
    }
`

interface Props {
    children: React.ReactNode
}

const PrismicGrid = ({ children }: Props) => <Grid>{children}</Grid>

export default PrismicGrid
