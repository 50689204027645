import cx from 'classnames'
import * as React from 'react'

import styles from './Select.module.scss'
import DropdownIcon from '../../core/DropdownIcon'
import ErrorIndicator from '../ErrorIndicator'
import inputStyles from '../Input/Input.module.scss'
import InputIndicatorTransition from '../InputIndicatorTransition'

interface OwnProps {
    transparent?: boolean
    error?: string
    isValid?: boolean
    className?: string
    inputClassName?: string
    field?: string
}

type Props = OwnProps & React.InputHTMLAttributes<HTMLSelectElement>

const Select = React.forwardRef<HTMLDivElement, Props>(function Select(
    {
        field,
        transparent = false,
        error,
        isValid = false,
        className,
        inputClassName,
        ...others
    }: Props,
    ref,
) {
    return (
        <div
            className={cx(
                inputStyles.base,
                {
                    [inputStyles.error]: Boolean(error),
                    [inputStyles.valid]: isValid,
                },
                className,
            )}
            ref={ref}
        >
            <select
                {...others}
                className={cx(
                    inputStyles.input,
                    styles.input,
                    {
                        [styles.transparent]: transparent,
                    },
                    inputClassName,
                )}
            />
            <span className={styles.arrow}>
                <DropdownIcon />
            </span>

            <InputIndicatorTransition>
                {error && (
                    <ErrorIndicator field={String(field)}>
                        {error}
                    </ErrorIndicator>
                )}
            </InputIndicatorTransition>
        </div>
    )
})

export default Select
