import styled from '@emotion/styled'
import * as React from 'react'

import Container from '../../../../../layout/Container'

const ContentWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 800px;
    min-height: 350px;
    padding-top: 15px;
    padding-bottom: 15px;
`

interface OwnProps {
    children: React.ReactNode
    className?: string
}
type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>

const FilterPanel = React.forwardRef<HTMLDivElement, Props>(
    function FilterPanel({ children, ...others }: Props, ref) {
        return (
            <div {...others} ref={ref}>
                <ContentWrapper>{children}</ContentWrapper>
            </div>
        )
    },
)

export default FilterPanel
