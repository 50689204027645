import { MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'

import { getMessageDescriptor } from '@emico/i18n'

import Select from '../../../input/Select'
import { ConfigurableAttribute } from '../../common/MinimalConfigurableProductInfo.fragment'
import {
    getVariant,
    hasLimitedStock,
    isOutOfStock,
    limitedStockLabel,
    outOfStockLabel,
} from '../ConfigurableAttributesField/helpers'
import { ConfigurableProduct } from '../ConfigurableProduct'

interface OwnProps {
    attribute: ConfigurableAttribute
    product: ConfigurableProduct
    onChange(newValue?: string): void
    value: string
}

export type Props = OwnProps

// in some languages the sentence structure changes based on the option name,
// e.g. in English "a" becomes "an" for words like "arm length", in German some
// words are capitalized randomly, and who knows what other rules may be
// applicable
/**
 * @deprecated Use ProductForm/validateForm
 */
const PleaseSelectAnOption: { [key: string]: MessageDescriptor } = {
    fit: defineMessage({
        id: 'catalog.productPage.pleaseSelectAnOption.fit',
        message: `Please select a fit`,
    }),
    color: defineMessage({
        id: 'catalog.productPage.pleaseSelectAnOption.color',
        message: `Please select a color`,
    }),
    size: defineMessage({
        id: 'catalog.productPage.pleaseSelectAnOption.size',
        message: `Please select a size`,
    }),
    length: defineMessage({
        id: 'catalog.productPage.pleaseSelectAnOption.length',
        message: `Please select a length`,
    }),
}

const ConfigurableAttributeDropdown = ({
    attribute,
    product,
    onChange,
    value,
    ...others
}: Props) => {
    const placeholderMessageDescriptor = getMessageDescriptor(
        PleaseSelectAnOption,
        attribute.label,
    )

    const placeholder = placeholderMessageDescriptor
        ? placeholderMessageDescriptor
        : t({
              id: 'catalog.productPage.pleaseSelectAnOption.fallback',
              message: `Please select a ${attribute.label}`,
          })

    return (
        <Select
            onChange={(e) => {
                if (e.target.value) {
                    onChange(e.target.value)
                }
            }}
            value={value}
            {...others}
        >
            <option value="">{placeholder}</option>
            {attribute.values.map((attributeValue) => {
                const variant = getVariant(product.variants, [attributeValue])
                const stockStatus = variant && variant.product
                const outOfStock = isOutOfStock(variant)
                const limitedStock = hasLimitedStock(variant)

                // Deselect selected value when it is out of stock
                if (value === attributeValue.uid && outOfStock) {
                    onChange()
                }

                return (
                    <option
                        key={String(attributeValue.uid)}
                        value={String(attributeValue.uid)}
                        disabled={outOfStock}
                    >
                        {attributeValue.storeLabel}{' '}
                        {limitedStock &&
                            limitedStockLabel(
                                stockStatus?.onlyXLeftInStock ?? 0,
                            )}{' '}
                        {outOfStock && outOfStockLabel()}
                    </option>
                )
            })}
        </Select>
    )
}

export default ConfigurableAttributeDropdown
