import { useIsLoggedIn } from '@emico-hooks/login-token'
import { Trans } from '@lingui/macro'
import Button from 'components/src/input/Button'
import paths from 'components/src/paths'

import styles from './SuccessPage.module.scss'

interface Props {
    orderNumber: string
}

const SuccessPageButtons = ({ orderNumber }: Props) => {
    const isLoggedIn = useIsLoggedIn()
    return (
        <>
            {isLoggedIn && (
                <Button
                    minWidth
                    to={`${paths.orderOverview}/${orderNumber}`}
                    variant="primary"
                    className={styles.buttonPrimary}
                    name="Order details"
                    category="checkout.successPage.orderDetails"
                >
                    <Trans id="checkout.successPage.orderDetailsButton">
                        Order details
                    </Trans>
                </Button>
            )}
            <Button
                minWidth
                to={paths.home}
                variant="white"
                className={styles.buttonSecondary}
                name="Continue shopping"
                category="checkout.successPage.continueShopping"
            >
                <Trans id="checkout.successPage.continueShoppingButton">
                    Continue shopping
                </Trans>
            </Button>
        </>
    )
}

export default SuccessPageButtons
