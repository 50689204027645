import { i18n } from '@lingui/core'
import { plural, t } from '@lingui/macro'

import { getSentry } from '@emico/utils'

import isJson from '../utils/isJson'
import { M2ApiResponseError } from '../utils/RestApi'

// List of errors returned from magento
export const errors = {
    outOfStock: 'The requested qty is not available',
    limitReached: 'Your quote / cart is limited to %1 product(s) per item.',
    notAvailable: 'Product that you are trying to add is not available.',
    voucherLocked: 'Failed to call action getVoucherLock',
    noOptionsSelected: 'You need to choose options for your item.',
}

/**
 * Check if an error message is common and known to the frontend app, if not, report it to Sentry
 * @param error
 */
export const reportUnkownError = (error: string) => {
    if (!Object.values(errors).includes(error)) {
        getSentry()?.captureException(error)
    }
}

export const getErrorUserMessage = (error: Error) =>
    error instanceof M2ApiResponseError
        ? (error as M2ApiResponseError).userMessage
        : error.message

const parseError = (error: string, params?: { [key: string]: unknown }) => {
    let errorMessage = error
    let errorParams = params
    let translatedErrorMessage: string

    if (errorMessage.includes('\n')) {
        errorMessage = errorMessage.split('\n')[0]
    }

    if (isJson(errorMessage)) {
        const { message, params } = JSON.parse(errorMessage)

        errorParams = params
        errorMessage = message
    }

    switch (errorMessage) {
        case errors.outOfStock:
            translatedErrorMessage = t({
                id: 'cart.cartPage.outOfStock',
                message: `The requested quantity is not available.`,
            })
            break
        case errors.limitReached:
            translatedErrorMessage = plural(Number(errorParams?.[0]), {
                one: 'Your quote / cart is limited to 1 product per item.',
                other: `Your quote / cart is limited to ${errorParams?.[0]} products per item.`,
            })
            break
        case errors.notAvailable:
            translatedErrorMessage = t({
                id: 'catalog.productPage.unavailable',
                message: `This product is currently not available.`,
            })
            break
        case errors.voucherLocked:
            translatedErrorMessage = t({
                id: 'catalog.productPage.voucherLocked',
                message: `This voucher is temporarily unusable due to usage in another transaction.`,
            })
            break
        case errors.noOptionsSelected:
            translatedErrorMessage = t({
                id: 'catalog.productPage.noselection',
                message: 'You need to choose options for your item.',
            })
            break

        default:
            // perform partial match on error message
            if (
                errorMessage.includes('The requested qty of') &&
                errorMessage.includes('is not available, only')
            ) {
                if (errorParams) {
                    const { requestedQty, article, salableQty } = errorParams

                    translatedErrorMessage = t({
                        id: 'cart.cartPage.itemUnavailableWithParams',
                        message: `The requested qty of ${requestedQty} for ${article} is not available, only ${salableQty} left`,
                    })
                } else {
                    translatedErrorMessage = t({
                        id: 'cart.cartPage.itemUnavailable',
                        message: `${errorMessage}`,
                    })
                }
            } else if (errorMessage.includes('product is out of stock')) {
                translatedErrorMessage = t({
                    id: 'catalog.productPage.outOfStock',
                    message: `This product is out of stock.`,
                })
            } else {
                translatedErrorMessage = t({
                    id: 'cart.cartPage.unknownError',
                    message: `An unknown error occured.`,
                })
            }
            break
    }

    return translatedErrorMessage
}

export default parseError
