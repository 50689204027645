import { getSentry } from '@emico/utils'

/** @deprecated Use getSentry instead */
export default function withSentry(handler: (sentry: Sentry) => void) {
    const Sentry = getSentry()

    if (!Sentry) {
        return
    }
    handler(Sentry)
}
