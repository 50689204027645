import { Picture } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { useBreakpoints } from '@emico/ui'

import isBrandedStore from './isBrandedStore'
import OpeningHours from './OpeningHours'
import storeImageUrl from './storeImageUrl'
import StoreInformation from './StoreInformation'
import { SrsStore } from './useSrsStore'
import DropdownIcon from '../core/DropdownIcon'
import { Responsive } from '../core/Responsive'
import Button from '../input/Button'
import Icon from '../media/Icon'
import paths from '../paths'
import theme from '../theme'
import breakpoints from '../theme/breakpoints'
import useResolveStoreUrl from '../useResolveStoreUrl'

export interface Props {
    selectedStore: SrsStore | undefined
    storeView: string
    onClose: () => void
}

const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${theme.colors.whiteSmoke};
    margin: 15px 0;
`

const DesktopHeader = styled.div`
    min-height: 60px;
    background-color: #fff;
`

const ResultDetailOverlayModal = ({
    selectedStore,
    storeView,
    onClose,
}: Props) => {
    const storeUrl = useResolveStoreUrl(
        selectedStore?.shopId?.toString() ?? undefined,
    )
    const { isMobile } = useBreakpoints()
    const brandedStore = isBrandedStore(selectedStore)

    const OverlayContainer = styled.div`
        ${selectedStore ? 'display: flex' : 'display: none;'};
        flex-flow: column nowrap;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        width: inherit;
        z-index: ${isMobile ? '1000' : '1'};
        ${isMobile && 'transition: max-height .6s ease-in;'}
        height: 100%;
    `

    const ScrollContainer = styled.div`
        flex: 1;
        background-color: #fff;
        padding-bottom: 10px;
        overflow: hidden;
        overflow-y: auto;
    `

    const StoreInfo = styled.div`
        padding: ${isMobile ? '5px 30px 20px 30px' : '20px 30px 20px 30px'};
    `

    const StyledPicture = styled(Picture)`
        width: 100%;
        height: 200px;
        object-fit: cover;
    `

    return (
        <OverlayContainer>
            <DesktopHeader>
                <Button
                    type="button"
                    name="Back"
                    category="storelocator.storedetails.backToResults"
                    variant="link"
                    onClick={onClose}
                    css={css`
                        color: #000;
                        height: 60px;
                        padding: 0 10px;
                        transition: color 0.2s;
                        &:hover {
                            color: ${process.env.REACT_APP_PRIMARY_COLOR};
                        }
                    `}
                >
                    <Icon
                        component={DropdownIcon}
                        title=""
                        css={css`
                            transform: rotate(90deg);
                            margin-right: 7px;
                        `}
                    />
                    <Trans id="storelocator.storedetails.backToResults">
                        Back to results
                    </Trans>
                </Button>
            </DesktopHeader>

            <ScrollContainer>
                <Responsive xs up>
                    {brandedStore && selectedStore && (
                        <StyledPicture
                            breakpoints={{
                                [breakpoints.xs]: {
                                    url: storeImageUrl(
                                        selectedStore,
                                        storeView,
                                        true,
                                    ),
                                    width: 500,
                                    height: 200,
                                },
                                [breakpoints.md]: {
                                    url: storeImageUrl(
                                        selectedStore,
                                        storeView,
                                    ),
                                    width: 800,
                                    height: 200,
                                },
                            }}
                            alt={
                                selectedStore?.imageTitle
                                    ? selectedStore.imageTitle
                                    : ''
                            }
                            lazy={false}
                        />
                    )}
                </Responsive>
                <StoreInfo>
                    <StoreInformation store={selectedStore} />
                    {brandedStore && <Divider />}
                    {brandedStore && <OpeningHours store={selectedStore} />}

                    <Button
                        to={`https://www.google.com/maps/dir/?api=1&destination=${selectedStore?.gLat},${selectedStore?.gLong}`}
                        name="Directions"
                        category="storelocator.storedetails.storeDirections"
                        variant="primary"
                        wide
                        css={css`
                            margin: 20px 0;
                        `}
                    >
                        <Trans id="storelocator.storedetails.directions">
                            Directions
                        </Trans>
                    </Button>

                    {storeUrl && brandedStore && (
                        <Button
                            name="Visit store"
                            category="storelocator.storedetails.visitStore"
                            variant="secondary"
                            wide
                            to={`${paths.storeLocator}/${storeUrl}`}
                        >
                            <Trans id="storelocator.storedetails.visitStore">
                                Visit store
                            </Trans>
                        </Button>
                    )}
                </StoreInfo>
            </ScrollContainer>
        </OverlayContainer>
    )
}

export default ResultDetailOverlayModal
