import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import styles from './ImageContentCard.module.scss'
import Button from '../../input/Button'
import { Video } from '../../media/Video'
import breakpoints from '../../theme/breakpoints'
import Heading from '../../typography/Heading'
import useSelectPromotionEvent from '../../utils/ga4/useSelectPromotionEvent'
import { useViewPromotionEvent } from '../../utils/ga4/useViewPromotionEvent'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    imageUrl?: string
    imageMobileUrl?: string
    videoId?: string
    altText?: string
    header?: string
    subheader?: string
    buttonText?: string
    buttonLink?: string
    /**
     * An image is fluid when it is not displayed full width
     */
    fluid?: boolean
    isPromotion?: boolean
}

const VideoWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 105%;
        aspect-ratio: 16/9;
        transform: translate3d(-50%, -50%, 0);
    }
`

const ImageContentCard = ({
    imageUrl,
    imageMobileUrl,
    videoId,
    altText = '',
    header = '',
    subheader,
    buttonText,
    buttonLink,
    fluid = true,
    isPromotion,
    ...htmlProps
}: Props) => {
    const { pathname } = useLocation()

    const pushSelectPromotion = useSelectPromotionEvent()

    const ref = useViewPromotionEvent<HTMLElement>({
        item_id: `image with content - ${pathname}`,
        location_id: `image with content - ${pathname}`,
        promotion_name: `${header} ${subheader} ${buttonText}`,
        creative_name: videoId || `${altText} ${imageUrl} ${imageMobileUrl}`,
    })

    if (!imageUrl && !videoId) {
        return null
    }

    return (
        <section
            ref={isPromotion ? ref : undefined}
            {...htmlProps}
            className={cx(styles.card, { [styles.fluid]: fluid })}
        >
            {!videoId && imageUrl && (
                <Picture
                    breakpoints={{
                        [breakpoints.xs]: {
                            url: imageMobileUrl ?? imageUrl,
                            width: 767,
                            height: undefined,
                            params: {
                                resizingType: 'fill',
                            },
                        },
                        [breakpoints.md]: {
                            url: imageUrl,
                            width: 991,
                            height: undefined,
                            params: {
                                resizingType: 'fill',
                            },
                        },
                        [breakpoints.lg]: {
                            url: imageUrl,
                            width: 2000,
                            height: undefined,
                            params: {
                                resizingType: 'fill',
                            },
                        },
                    }}
                    lazy
                    alt={altText || header}
                    pictureProps={{ className: styles.base }}
                    className={styles.image}
                />
            )}
            {videoId && (
                <VideoWrapper>
                    <Video
                        url={`https://player.vimeo.com/video/${videoId}`}
                        responsive={false}
                        background
                        loop
                        autoplay
                        muted
                    />
                </VideoWrapper>
            )}

            {header || subheader || (buttonLink && buttonText) ? (
                <div className={styles.wrapper}>
                    {header && (
                        <Heading element="h1" variant="h1" color="light">
                            {header}
                        </Heading>
                    )}
                    {subheader && (
                        <Heading variant="h4" element="div" color="light">
                            {subheader}
                        </Heading>
                    )}
                    {buttonLink && buttonText && (
                        <Button
                            minWidth
                            variant="primary"
                            name={buttonText}
                            category="cms.flavourContentButton"
                            className={cx(styles.button)}
                            to={buttonLink}
                            size="large"
                            onClick={() => {
                                pushSelectPromotion({
                                    item_id: `image with content - ${pathname}`,
                                    location_id: `image with content - ${pathname}`,
                                    promotion_name: `${header} ${subheader} ${buttonText}`,
                                    creative_name:
                                        videoId ||
                                        `${videoId} ${altText} ${imageUrl} ${imageMobileUrl}`,
                                })
                            }}
                        >
                            {buttonText}
                        </Button>
                    )}
                </div>
            ) : null}
        </section>
    )
}

export default ImageContentCard
