import { ApolloError } from '@apollo/client'
import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { useRemoveItemFromCart } from '@emico-hooks/cart-remove-item'
import { useCallback } from 'react'

import useRemoveFromCartEvent from '../utils/ga4/useRemoveFromCartEvent'

export const useRemoveCartItemFunction = () => {
    const removeCartItem = useRemoveItemFromCart()
    const pushRemoveFromCart = useRemoveFromCartEvent()

    return useCallback(
        async (cartId: string, cartItem: CartItemFragment) => {
            try {
                await removeCartItem(cartId, cartItem.uid)
                if (cartItem.product) {
                    pushRemoveFromCart(cartItem.product, cartItem.quantity)
                }
            } catch (e) {
                if (!(e instanceof Error)) {
                    return
                }
                let message = e.message

                if (e instanceof ApolloError) {
                    message = (e as ApolloError).graphQLErrors?.[0]?.message
                }
                throw new Error(message)
            }
        },
        [pushRemoveFromCart, removeCartItem],
    )
}
