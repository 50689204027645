import * as React from 'react'

import BlueConicContent from '../BlueConicContent'
import CartStatusIcon from '../cart/CartStatusIcon'
import MiniCart from '../cart/MiniCart'
import { useMiniCartVar } from '../cart/miniCartVar'
import HeightTransition from '../layout/HeightTransition/HeightTransition'
import { Props as IconProps } from '../media/Icon'

interface Props extends Omit<IconProps, 'component' | 'title'> {
    linkClassName?: string
}

export const CLOSE_TIME_CART_BUTTON = 8000

const CartButton = ({ linkClassName, ...others }: Props) => {
    const miniCart = React.useRef<HTMLDivElement>(null)

    const { isMiniCartOpened: isOpen, close } = useMiniCartVar()

    const closeTimeOutRef = React.useRef<ReturnType<typeof setTimeout> | null>(
        null,
    )

    // The mini cart must close when the user clicks anywhere
    React.useEffect(() => {
        const handleClick = (e: Event) => {
            close()
        }

        if (isOpen) {
            document.addEventListener('click', handleClick)
        } else {
            document.removeEventListener('click', handleClick)
        }

        if (closeTimeOutRef.current) {
            clearTimeout(closeTimeOutRef.current)
            closeTimeOutRef.current = null
        }

        if (isOpen) {
            closeTimeOutRef.current = setTimeout(() => {
                close()
            }, CLOSE_TIME_CART_BUTTON)
        }

        return () => document.removeEventListener('click', handleClick)
    }, [close, isOpen])

    const linkProps = {
        className: linkClassName,
    }

    return (
        <>
            <CartStatusIcon linkProps={linkProps} {...others} />

            <HeightTransition>
                {isOpen && <MiniCart ref={miniCart} />}
            </HeightTransition>

            <BlueConicContent blueConicPosition="cartTooltip">
                <div />
            </BlueConicContent>
        </>
    )
}

export default CartButton
