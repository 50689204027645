import styled from '@emotion/styled'
import classNames from 'classnames'
import { ComponentProps, forwardRef } from 'react'

import { ArrowButton, NextArrow } from '@emico/component-slider'

import styles from './ComponentSlider.module.scss'

const NewArrowButton = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof ArrowButton.original>
>(function NewArrowButton(props, ref) {
    return (
        <ArrowButton.original
            {...props}
            ref={ref}
            className={classNames(props.className, styles.button)}
        />
    )
})

const NewNextArrow = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof NextArrow.original>
>(function NewArrowButton(props, ref) {
    return (
        <NextArrow.original
            {...props}
            ref={ref}
            className={classNames(props.className, styles.next)}
        />
    )
})

ArrowButton.override(NewArrowButton as unknown as typeof ArrowButton.original)
NextArrow.override(NewNextArrow as unknown as typeof NextArrow.original)
