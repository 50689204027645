import { MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

import { getMessageDescriptor } from '@emico/i18n'

import ProductInfo from './ProductInfo'
import styles from './ProductInfoForm.module.scss'
import {
    getConfigurableProductPrices,
    getPrices,
} from '../../../presentation/PriceBox/PriceBox'
import useProductForm from '../../ProductForm/useProductForm'
import { ConfigurableAttributesFieldValue } from '../ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { Product } from '../GetProduct.query'

interface Props {
    product: Product | ConfigurableProduct
    hideStickyCta: (value: boolean) => void
    children?: React.ReactNode
}

export interface ProductFormValues {
    configurableAttributes: ConfigurableAttributesFieldValue
}

// in some languages the sentence structure changes based on the option name,
// e.g. in English "a" becomes "an" for words like "arm length", in German some
// words are capitalized randomly, and who knows what other rules may be
// applicable
/**
 * @deprecated Use ProductForm/validateForm
 */
export const SelectAnOption: { [key: string]: MessageDescriptor } = {
    fit: defineMessage({
        id: 'catalog.productPage.selectAnOption.fit',
        message: `Select a fit`,
    }),
    color: defineMessage({
        id: 'catalog.productPage.selectAnOption.color',
        message: `Select a color`,
    }),
    size: defineMessage({
        id: 'catalog.productPage.selectAnOption.size',
        message: `Select a size`,
    }),
    length: defineMessage({
        id: 'catalog.productPage.selectAnOption.length',
        message: `Select a length`,
    }),
}

const ProductInfoForm = ({ product, children, hideStickyCta }: Props) => {
    const {
        formProps,
        submitError,
        isSubmitting,
        showSubmit,
        isInCart,
        configurableSelectProps,
        formState: { errors },
    } = useProductForm(product, {
        openMiniCartAfterAdd: true,
    })

    const isConfigurable = Boolean(
        (product as ConfigurableProduct).configurableOptions,
    )

    const prices = isConfigurable
        ? getConfigurableProductPrices(product as ConfigurableProduct)
        : getPrices(product)

    return (
        <>
            <form {...formProps} className={styles.default}>
                <ProductInfo
                    product={product}
                    prices={prices}
                    isConfigurable={isConfigurable}
                    validationError={
                        (errors.configurableAttributes
                            ?.message as unknown as string) ??
                        (errors.quantity?.message as string)
                    }
                    error={submitError ?? undefined}
                    hideStickyCta={hideStickyCta}
                    showSubmit={showSubmit}
                    isSubmitting={isSubmitting}
                    isInCart={isInCart}
                    configurableSelectProps={configurableSelectProps}
                >
                    {children}
                </ProductInfo>
            </form>
        </>
    )
}

export default ProductInfoForm
