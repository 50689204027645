import { useApolloClient } from '@apollo/client'
import { ComponentProps, ReactNode, useEffect } from 'react'

import { Page, UrlRewriteEntityTypeEnum } from '@emico/graphql-schema-types'
import { Link, storeResolvedUrl } from '@emico/link'

import { PrismicDocumentLinkType } from './responseTypes'
import usePrismicDocument from './usePrismicDocument'

interface Props extends Omit<ComponentProps<typeof Link>, 'to'> {
    children: ReactNode
    to: PrismicDocumentLinkType
}

const PrismicDocumentLink = ({ children, to, ...others }: Props) => {
    const doc = usePrismicDocument<Page | unknown>(
        {
            key: 'document.id',
            value: to.id,
        },
        false,
    )

    const page = doc.data as Page | null

    const apolloClient = useApolloClient()

    useEffect(() => {
        if (page?.__typename === 'Page' && page.urlKey) {
            storeResolvedUrl(
                apolloClient,
                `/${page.urlKey}`,
                UrlRewriteEntityTypeEnum.PRISMIC,
                0,
            )
        }
    }, [page, apolloClient])

    if (page?.__typename === 'Page' && page.urlKey) {
        return (
            <Link to={page.urlKey} {...others}>
                {children}
            </Link>
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { analyticsContext, analyticsName, ...spanProps } = others

    // TODO: Make a temp url e.g. /prismic/:id that resolves the doc and redirects lazily if the user clicks it before the above query finishes
    return <span {...spanProps}>{children}</span>
}

export default PrismicDocumentLink
