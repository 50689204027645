import { decamelize } from 'humps'

function dashes(str: string) {
    return decamelize(str, { separator: '-' })
}

export function constructCssKey(...parts: Array<string | undefined>) {
    return parts.filter(Boolean).join('-')
}

function cvar(name: string, defaultValue?: string) {
    if (defaultValue) {
        return `var(${name}, ${defaultValue})`
    }

    return `var(${name})`
}

export function getVarKeys<T extends Record<string, unknown>>(
    defaults: T,
    prefix?: string,
) {
    const varKeys: Partial<Record<keyof T, string>> = {}

    Object.entries(defaults).forEach(([key]) => {
        const cssKey = constructCssKey('-', prefix, dashes(key))

        varKeys[key as keyof T] = cssKey
    })

    return varKeys as Record<keyof T, string>
}

export function defaultsToCss<T extends Record<string, unknown>>(
    defaults: T,
    prefix?: string,
) {
    return Object.entries(defaults).map(([key, value]) => {
        const cssKey = constructCssKey('-', prefix, dashes(key))

        return `${cssKey}: ${String(value)};`
    })
}

export default function defaultsToVars<T extends Record<string, unknown>>(
    defaults: T,
    prefix?: string,
) {
    const keys = Object.entries(defaults).map(([key]) => [
        key,
        cvar(constructCssKey('-', prefix, dashes(key))),
    ])

    return Object.fromEntries(keys) as Record<keyof T, string>
}
