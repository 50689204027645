import styled from '@emotion/styled'
import * as React from 'react'

import { maxWidth } from '@emico/styles'

import FooterNavigation from './FooterNavigation'
import Container from '../../layout/Container'
import PaymentMethods from '../../presentation/AvailablePaymentMethods'
import { TrustBadge } from '../../utils/trustedShops'

const StyledContainer = styled(Container)`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @media ${maxWidth('sm')} {
        padding-bottom: 5px;
    }
`

const StyledPaymentMethods = styled(PaymentMethods)`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
`

interface Props {
    showPayment: boolean
}

const FooterBottom = ({ showPayment }: Props) => (
    <StyledContainer>
        <Wrapper>
            {!showPayment && <TrustBadge />}
            {showPayment && (
                <StyledPaymentMethods size="large">
                    <TrustBadge />
                </StyledPaymentMethods>
            )}
        </Wrapper>
        <FooterNavigation />
    </StyledContainer>
)

export default FooterBottom
