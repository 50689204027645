import { Messages, i18n } from '@lingui/core'

export const initializeI18n = async (
    locale: string,
    loadCatalog: (language: string) => Promise<Messages>,
) => {
    const { messages } = await loadCatalog(locale)

    i18n.loadAndActivate({
        locale,
        messages,
    })
}
