import { ComponentProps } from 'react'

import { useBreakpoints } from '@emico/ui'

import {
    CastironPageBodyImageSlider,
    JbfoPageBodyTileSlider,
    JustbrandsPageBodyImageSlider,
    MonstersalePageBodyTileSlider,
    PageBodyImageSlider,
    PageBodyImageSliderFields,
    PageBodyImageSliderPrimary,
    PmeOutletPageBodyImageSlider,
    StorelocatorPageBodyImageSlider,
    VanguardPageBodyImageSlider,
} from './graphql/schema.generated'
import TileSlider, { TileSliderItem } from './TileSlider'
import ButtonVariant from './types/ButtonVariant'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceTileSliderType =
    | PageBodyImageSlider
    | CastironPageBodyImageSlider
    | JustbrandsPageBodyImageSlider
    | VanguardPageBodyImageSlider
    | JbfoPageBodyTileSlider
    | MonstersalePageBodyTileSlider
    | PmeOutletPageBodyImageSlider
    | StorelocatorPageBodyImageSlider

interface Props {
    slice: PrismicSliceTileSliderType
}

const PrismicSliceTileSlider = ({ slice: { primary, fields } }: Props) => {
    const { isDesktop } = useBreakpoints()

    if (!primary) {
        return null
    }

    const {
        style,
        header,
        content,
        buttonUrl,
        buttonText,
        variant,
        buttonStyle,
    } = primary as PageBodyImageSliderPrimary & { buttonStyle?: string }

    const items: TileSliderItem[] = (
        (fields as PageBodyImageSliderFields[]) ?? []
    ).reduce<TileSliderItem[]>((acc, field) => {
        // Dont show items with no url configured
        if (field?.buttonUrl) {
            const {
                title,
                subtitle,
                buttonUrl,
                buttonText,
                image,
                mobileImage,
            } = field

            if (isDesktop && !image?.url) {
                return acc
            }
            if (!isDesktop && !mobileImage?.url) {
                return acc
            }

            const imageUrl = isDesktop ? image?.url : mobileImage?.url
            const imageAlt = isDesktop ? image?.alt : mobileImage?.alt

            if (!imageUrl) {
                return acc
            }

            acc.push({
                title: title ?? undefined,
                subtitle: subtitle ?? undefined,
                buttonUrl,
                buttonText: buttonText ?? undefined,
                image: {
                    url: imageUrl,
                    alt: imageAlt ?? undefined,
                },
                imageMobile: {
                    url: imageUrl,
                    alt: imageAlt ?? undefined,
                },
            })
        }
        return acc
    }, [])

    const componentProps: ComponentProps<typeof TileSlider> = {
        style: getLayoutStyle(style),
        header: header ?? undefined,
        variant: variant ?? undefined,
        content: content ?? undefined,
        buttonUrl: buttonUrl ?? undefined,
        buttonText: buttonText ?? undefined,
        buttonStyle:
            buttonStyle === 'highlight'
                ? ButtonVariant.highlight
                : ButtonVariant.primary,
        promotionCreative: header ?? undefined,
        items,
        tileButtonStyle:
            buttonStyle === 'highlight'
                ? ButtonVariant.highlight
                : ButtonVariant.primary,
    }

    return <TileSlider {...componentProps} />
}

export default PrismicSliceTileSlider
