import {
    useQuery,
    QueryHookOptions,
    TypedDocumentNode,
    gql,
    useApolloClient,
} from '@apollo/client'
import { cartDetailsFragment, cartFragment } from '@emico-hooks/cart-fragment'
import { useHandleCartError } from '@emico-hooks/cart-handle-error'
import { useCartId } from '@emico-hooks/cart-id'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import {
    getCacheableContext,
    stripMaybes,
} from '@emico-utils/graphql-data-utils'
import { BasicConfigurableProduct } from 'components/src/catalog/ProductPage/ConfigurableProduct'
import { useCallback, useEffect, useState } from 'react'

import { CartQuery, CartQueryVariables } from './useCart.generated'
import {
    GetBasicProductData,
    GetBasicProductVariables,
    basicProductQuerty,
} from '../../components/src/catalog/ProductPage/GetBasicProduct.query'

export const OPTIMISTIC_CART_ID = 'optimistic-cart-id'

export const cartQuery = gql`
    query Cart($id: String!) {
        cart(cart_id: $id) {
            ...CartFragment
        }
    }
    ${cartFragment}
` as TypedDocumentNode<CartQuery, CartQueryVariables>

export const cartWithDetailsQuery = gql`
    query CartDetails($id: String!) {
        cart(cart_id: $id) {
            ...CartDetailsFragment
        }
    }

    ${cartDetailsFragment}
` as TypedDocumentNode<CartQuery, CartQueryVariables>

export function useCart(
    options: Omit<
        QueryHookOptions<CartQuery, CartQueryVariables>,
        'variables'
    > = {},
    withDetails: boolean = false,
) {
    const [cartData, setCartData] = useState<CartQuery['cart']>()
    const [productsLoading, setProductsLoading] = useState(true)
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()
    const cartId = useCartId()
    const skip = !cartId || cartId === OPTIMISTIC_CART_ID
    const {
        data,
        error,
        loading: loadingCart,
        networkStatus,
        ...others
    } = useQuery<CartQuery, CartQueryVariables>(cartQuery, {
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: cartId!,
        },
        errorPolicy: 'all',
        skip,
        context: authorizationContext,
        ...options,
    })

    const { data: details, loading: loadingCartDetails } = useQuery<
        CartQuery,
        CartQueryVariables
    >(cartWithDetailsQuery, {
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: cartId!,
        },
        errorPolicy: 'all',
        skip: skip || !withDetails,
        context: authorizationContext,
        ...options,
    })

    useHandleCartError(error, networkStatus, skip)

    const fetchProducts = useCallback(
        async (cart: CartQuery['cart'], abortSignal: AbortSignal) => {
            // Fetch product data for all cart items.
            // Products are fetched seperately to avoid fetching the same product multiple times, and
            // to make use of the cache.
            //
            // These types of products are fetched:
            // - Products for the cart items
            // - Products for the free gifts

            if (!cart?.items) {
                setProductsLoading(false)

                return
            }

            const productsMap = new Map<
                number,
                ProductCardFragment | BasicConfigurableProduct
            >()

            const itemIds = cart.items.map((item) => item.product!.id) ?? []
            const giftIds =
                cart.freeGifts.flatMap((gift) =>
                    // Id is extracted from cart product which should never be null
                    gift.products.map((product) => product!.id),
                ) ?? []

            await Promise.all(
                [...itemIds, ...giftIds].map((productId) =>
                    client
                        .query<GetBasicProductData, GetBasicProductVariables>({
                            query: basicProductQuerty,
                            variables: {
                                id: productId,
                            },
                            context: getCacheableContext(),
                        })
                        .then((product) => {
                            productsMap.set(
                                product.data.product.id,
                                product.data.product,
                            )
                        }),
                ),
            )

            if (!cart?.id) {
                return
            }
            const newData: CartQuery['cart'] = {
                ...cart,
                items: cart.items.filter(stripMaybes).map((item) => ({
                    ...item,
                    // Id is extracted from cart product which should never be null
                    product: productsMap.get(item.product!.id) ?? item.product,
                })),
                freeGifts: cart.freeGifts.map((freeGift) => ({
                    ...freeGift,
                    products: freeGift.products.map(
                        (product) =>
                            // Id is extracted from cart product which should never be null
                            productsMap.get(product!.id) ?? product,
                    ),
                })),
            }

            if (abortSignal.aborted) {
                return
            }

            setProductsLoading(false)

            return setCartData(newData)
        },
        [client],
    )

    useEffect(() => {
        const abortController = new AbortController()

        setProductsLoading(true)
        fetchProducts(data?.cart, abortController.signal)

        return () => abortController.abort()
    }, [data?.cart, fetchProducts])

    return {
        ...others,
        loading: loadingCart || productsLoading || loadingCartDetails,
        data:
            withDetails && cartData
                ? { ...(details?.cart ?? {}), ...(cartData ?? {}) }
                : cartData,
        error,
    }
}
