import cx from 'classnames'
import * as React from 'react'

import { ImaginaryActions } from '@emico/ui'

import styles from './PromotionBlock.module.scss'
import ImageLazy from '../../../media/ImageLazy'
import Link from '../../../navigation/Link/Link'
import Heading from '../../../typography/Heading'

interface Props {
    title: string
    content: string
    url?: string
    image?: string
    className?: string
}

const PromotionBlock = ({ title, content, url, image, className }: Props) => {
    const imageContainer = image && (
        <div className={styles.imageContainer}>
            <ImageLazy
                images={{
                    image,
                    regularWidth: 100,
                    regularHeight: 100,
                    mediumWidth: 100,
                    mediumHeight: 100,
                    smallWidth: 100,
                    smallHeight: 100,
                    action: ImaginaryActions.SMARTCROP,
                }}
                className={styles.promotionImage}
                alt={title ?? ''}
                lazy
            />
        </div>
    )

    const contentContainer = (
        <div>
            <Heading
                variant="h4"
                element="h2"
                color="extra"
                className={styles.title}
            >
                {title}
            </Heading>
            <span className={styles.content}>{content}</span>
        </div>
    )

    return url ? (
        <Link
            to={url.trim()}
            name={title}
            category="topbar.usp"
            className={cx(styles.base, className)}
        >
            {imageContainer}
            {contentContainer}
        </Link>
    ) : (
        <div className={cx(styles.base, className)}>
            {imageContainer}
            {contentContainer}
        </div>
    )
}

export default PromotionBlock
