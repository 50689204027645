import { ApolloClient, gql } from '@apollo/client'

import { getCustomerTokenHeaderObject } from '@emico/login-token'

import {
    Maybe,
    MutationMergeWishlistsArgs,
    Scalars,
} from '../graphql/schema.generated'

const mutation = gql`
    mutation mergeWishlists(
        $sourceWishlistId: ID!
        $destinationWishlistId: ID!
    ) {
        mergeWishlists(
            sourceWishlistId: $sourceWishlistId
            destinationWishlistId: $destinationWishlistId
        ) {
            wishlist {
                id
            }
        }
    }
`

interface Result {
    mergeWishlists: Maybe<{
        wishlist: {
            id: Maybe<Scalars['ID']>
        }
    }>
}

export const mergeWishlists =
    (apollo: ApolloClient<unknown>) =>
    (
        sourceWishlistId: string,
        destinationWishlistId: string,
        customerToken: string,
    ) =>
        apollo.mutate<Result, MutationMergeWishlistsArgs>({
            mutation,
            variables: {
                sourceWishlistId,
                destinationWishlistId,
            },
            context: getCustomerTokenHeaderObject(customerToken),
        })
