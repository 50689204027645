import { useEffect } from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import { useCoreConfigValue } from '../coreConfig.query'

import './HighstreetBanner.scss'

const useHighstreetAppBanner = () => {
    const { value: bannerEnabled } = useCoreConfigValue(
        'highstreet/app_banner/enabled',
    )
    const isBannerEnabled = bannerEnabled === '1'

    let locale: string
    const activeStoreView = useActiveStoreView()

    switch (activeStoreView.language) {
        case 'nl':
        case 'be':
            locale = 'nl_NL'
            break
        case 'de':
            locale = 'de_DE'
            break
        default:
            locale = 'en_US'
            break
    }

    useEffect(() => {
        if (isBannerEnabled) {
            window.highstreetBanner = {
                config: {
                    elementId: 'highstreetBanner',
                    position: 'top',
                },
            }
            // Load script to show highstreet app banner
            // https://highstreetmobile.freshdesk.com/support/solutions/articles/48000944117-how-to-implement-our-smartbanner-
            const config = {
                store: process.env.REACT_APP_HIGHSTREET_STORE_NAME,
                host: window.location.origin,
                locale,
            }
            const hash = btoa(JSON.stringify(config))
            const baseUrl =
                process.env.NODE_ENV === 'development'
                    ? `https://${config.store}.api-dev.highstreetapp.com/smartbanner/`
                    : `https://${config.store}.api.highstreetapp.com/smartbanner/`

            const scriptSrc =
                baseUrl +
                hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '~')

            const script = document.createElement('script')

            script.defer = true
            script.src = scriptSrc

            document.body.appendChild(script)
        }
    }, [isBannerEnabled, locale])
}

export default useHighstreetAppBanner
