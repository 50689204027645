import { Sha256 } from '@aws-crypto/sha256-js'
import { globalWindow } from '@emico-utils/ssr-utils'

import { hasConsent } from '../../blueconic/getConsent'

function uint8ArrayToHexString(arr: Uint8Array): string {
    return Array.from(arr, (byte) =>
        ('0' + (byte & 0xff).toString(16)).slice(-2),
    ).join('')
}

export async function getHashedEmail(email: string) {
    if (!hasConsent('advertising_cookies')) {
        return null
    }

    const hash = new Sha256()
    hash.update(email.toLowerCase().trim())
    return await hash.digest().then(uint8ArrayToHexString)
}

export async function pushEnhancedConversionEmailHash(email: string) {
    const hash = await getHashedEmail(email)
    if (!hash) {
        return
    }
    globalWindow?.gtag?.('set', 'user_data', {
        sha256_email_address: hash,
    })
}
