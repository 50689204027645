import { ComponentProps } from 'react'

import { useBreakpoints } from '@emico/ui'

import {
    CastironPageBodyTextWithMedia,
    JbfoPageBodyTextWithMedia,
    JustbrandsPageBodyTextWithMedia,
    MonstersalePageBodyTextWithMedia,
    PageBodyTextWithMedia,
    PageBodyTextWithMediaFields,
    PmeOutletPageBodyTextWithMedia,
    StorelocatorPageBodyTextWithMedia,
    StorePageBodyTextWithMedia,
    VanguardPageBodyTextWithMedia,
} from './graphql/schema.generated'
import TextWithMedia, { Image, Alignment } from './TextWithMedia'
import ButtonVariant from './types/ButtonVariant'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceTextWithMediaType =
    | PageBodyTextWithMedia
    | VanguardPageBodyTextWithMedia
    | CastironPageBodyTextWithMedia
    | JustbrandsPageBodyTextWithMedia
    | JbfoPageBodyTextWithMedia
    | MonstersalePageBodyTextWithMedia
    | PmeOutletPageBodyTextWithMedia
    | StorePageBodyTextWithMedia
    | StorelocatorPageBodyTextWithMedia

interface Props {
    slice: PrismicSliceTextWithMediaType
}

const PrismicSliceTextWithMedia = ({ slice: { primary, fields } }: Props) => {
    const { isDesktop } = useBreakpoints()

    if (!primary) {
        return null
    }

    const {
        heading,
        headingElement,
        headingVariant,
        headingfuss,
        content,
        style,
        desktopMediaPosition,
        mobileMediaPosition,
        videoId,
        mobileVideoId,
        buttonUrl,
        buttonText,
        buttonStyle,
        button2Url,
        button2Text,
        button2Style,
        alignment,
    } = primary

    const imageFields = (fields as PageBodyTextWithMediaFields[]) ?? []
    const images = imageFields.reduce<Image[]>((acc, field) => {
        if (isDesktop && !field.image?.url) {
            return acc
        }
        if (!isDesktop && !field.mobileImage?.url) {
            return acc
        }

        const imageUrl = isDesktop ? field.image?.url : field.mobileImage?.url
        const imageAlt = isDesktop ? field.image?.alt : field.mobileImage?.alt

        if (imageUrl) {
            acc.push({
                url: imageUrl,
                mobileUrl: imageUrl,
                alt: imageAlt ?? undefined,
            })
        }
        return acc
    }, [])

    if (images.length === 0) {
        return null
    }

    const video = isDesktop ? videoId : mobileVideoId

    const componentProps: ComponentProps<typeof TextWithMedia> = {
        style: getLayoutStyle(style),
        heading: heading ?? undefined,
        headingElement: (headingElement as HeadingElement) ?? undefined,
        headingVariant: (headingVariant as HeadingVariant) ?? undefined,
        headingFuss: headingfuss ? ['castiron', 'pme', 'vanguard'] : undefined,
        content: content ?? undefined,
        images,
        videoId: video ?? undefined,
        desktopMediaPosition: desktopMediaPosition ?? undefined,
        mobileMediaPosition: mobileMediaPosition ?? undefined,
        buttonUrl: buttonUrl ?? undefined,
        buttonText: buttonText ?? undefined,
        buttonStyle: (buttonStyle as ButtonVariant) ?? undefined,
        button2Url: button2Url ?? undefined,
        button2Text: button2Text ?? undefined,
        button2Style: (button2Style as ButtonVariant) ?? undefined,
        alignment: (alignment as Alignment) ?? undefined,
    }

    return <TextWithMedia {...componentProps} />
}

export default PrismicSliceTextWithMedia
