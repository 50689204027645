import {
    DocumentNode,
    gql,
    makeVar,
    QueryHookOptions,
    useApolloClient,
    useQuery,
} from '@apollo/client'
import { useCartId } from '@emico-hooks/cart-id'
import {
    useAuthorizationContext,
    useIsLoggedIn,
} from '@emico-hooks/login-token'
import { useCallback, useEffect } from 'react'

import {
    AppliedCoupon,
    Customer as GeneratedCustomer,
    Mutation,
    MutationCreateEmptyCartArgs,
    SalesLimitsStatus,
    SrsVoucher,
} from '../graphql/schema.generated'

export { SalesLimitsStatus }

const freeGiftRuleFragment = `
freeGiftRule {
    description
    image
    name
    products {
        id
    }
    label
    claimedLabel
    unclaimedLabel
}
`
const salesRuleFragment = `
salesRule {
    description
    isActive
    name
    image
}
`

export const fragments = {
    srsVouchers: (fragment: string) => gql`
        fragment SrsVouchers on Customer {
            srsVouchers {
                barcode
                description
                ${fragment}
                id
                minPurchaseAmount {
                    currency
                    value
                }
                redeemedAt
                validTill
                valueAmount {
                    currency
                    value
                }
                valuePercentage
            }
        }
    `,
}

export const appliedCouponsQuery = gql`
    query GetAppliedCoupons($cartId: String!) {
        cart(cart_id: $cartId) {
            appliedCoupons {
                code
            }
        }
    }
`
export const srsFreeGiftRuleVouchersQuery = gql`
    query GetSrsVouchers {
        customer {
            ...SrsVouchers
        }
    }
    ${fragments.srsVouchers(freeGiftRuleFragment)}
`
export const srsSalesRuleVouchersQuery = gql`
    query GetSrsVouchers {
        customer {
            ...SrsVouchers
        }
    }
    ${fragments.srsVouchers(salesRuleFragment)}
`

export type Customer = Override<
    Pick<
        GeneratedCustomer,
        | 'firstname'
        | 'lastname'
        | 'dateOfBirth'
        | 'shoppingAllowed'
        | 'salesLimitsInfo'
        | 'email'
        | 'wishlists'
        | 'personalizedImage'
    >,
    {
        email: string
    }
>

export interface CustomerData {
    customer: Customer
}

export interface SrsVoucherData {
    customer: { srsVouchers?: SrsVoucher[] }
}

const createMaskedCartId = gql`
    mutation createEmptyCart {
        createEmptyCart
    }
`

type Result = Pick<Mutation, 'createEmptyCart'>

export const maskedCartIdVar = makeVar<string>('')

export const useGetMaskedCartId = (): { maskedCartId: string } => {
    const authorizationContext = useAuthorizationContext()
    const client = useApolloClient()
    const isLoggedIn = useIsLoggedIn()

    useEffect(() => {
        const getMaskedCartId = async () => {
            const { data } = await client.mutate<
                Result,
                MutationCreateEmptyCartArgs
            >({
                mutation: createMaskedCartId,
                context: authorizationContext,
            })
            maskedCartIdVar(data?.createEmptyCart ?? '')
        }

        if (isLoggedIn && !maskedCartIdVar()) {
            getMaskedCartId()
        }
    }, [isLoggedIn, authorizationContext, client])

    return {
        maskedCartId: maskedCartIdVar(),
    }
}

export interface AppliedCouponsResult {
    cart: { appliedCoupons?: AppliedCoupon[] }
}
export const useGetAppliedCoupons = (options?: QueryHookOptions) => {
    const { maskedCartId } = useGetMaskedCartId()

    const authorizationContext = useAuthorizationContext()

    const { data, error, ...rest } = useQuery<AppliedCouponsResult>(
        appliedCouponsQuery,
        {
            ...options,
            skip: options?.skip || !maskedCartId,
            context: authorizationContext,
            fetchPolicy: 'network-only',
            variables: {
                cartId: maskedCartId,
            },
        },
    )
    return {
        ...rest,
        data: data?.cart.appliedCoupons || [],
    }
}

export const useLazyGetAppliedCoupons = () => {
    const authorizationContext = useAuthorizationContext()
    const client = useApolloClient()
    const cartId = useCartId()

    return useCallback(async (): Promise<{
        appliedCoupons: AppliedCoupon[]
        loading: boolean
    }> => {
        const { data, loading } = await client.query<AppliedCouponsResult>({
            query: appliedCouponsQuery,
            context: authorizationContext,
            variables: { cartId },
            fetchPolicy: 'network-only',
        })
        return { appliedCoupons: data?.cart?.appliedCoupons || [], loading }
    }, [client, authorizationContext, cartId])
}

export const useSrsVouchers = (
    query: DocumentNode,
    options?: QueryHookOptions,
) => {
    const authorizationContext = useAuthorizationContext()

    const { data, error, loading } = useQuery<SrsVoucherData>(query, {
        context: authorizationContext,
        ...options,
    })
    return {
        loading,
        error,
        data: data?.customer?.srsVouchers || [],
    }
}

export const useSrsVouchersSalesRule = (options?: QueryHookOptions) => {
    const { data: vouchers, ...rest } = useSrsVouchers(
        srsSalesRuleVouchersQuery,
        options,
    )
    return {
        vouchers: vouchers?.filter((v) => v.salesRule !== null) ?? [],
        ...rest,
    }
}

export const useSrsVouchersFreeGift = (options?: QueryHookOptions) => {
    const { data: vouchers, ...rest } = useSrsVouchers(
        srsFreeGiftRuleVouchersQuery,
        options,
    )

    return {
        vouchers: vouchers?.filter((v) => v.freeGiftRule !== null) ?? [],
        ...rest,
    }
}
