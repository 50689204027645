import {
    AvailableShippingMethod,
    SelectedShippingMethod,
} from '@emico-hooks/graphql-schema-types'

import { PickUpPointFormValues } from '../checkout/PickUpPointForm/PickUpPointForm'
import { CountryCodeEnum } from '../graphql/schema.generated'

export interface Address {
    company: string
    firstName: string
    lastName: string
    street: string
    houseNumber: string
    suffix: string
    postalCode: string
    city: string
    country: CountryCodeEnum
    customerAddressId?: number
}

export interface ShippingMethod {
    carrierCode?: string | undefined
    methodCode?: string | undefined
}

// NOTE: The ouput must have the same format as Magento's serialization
export const serializeQuoteDataShippingMethodInterface = (
    shippingMethod?:
        | Pick<ShippingMethod, 'carrierCode' | 'methodCode'>
        | Pick<SelectedShippingMethod, 'carrierCode' | 'methodCode'>
        | Pick<AvailableShippingMethod, 'carrierCode' | 'methodCode'>,
) => `${shippingMethod?.carrierCode}_${shippingMethod?.methodCode}`
export const serializeShippingMethod = (
    shippingMethod?:
        | Pick<ShippingMethod, 'carrierCode' | 'methodCode'>
        | Pick<SelectedShippingMethod, 'carrierCode' | 'methodCode'>,
) => `${shippingMethod?.carrierCode}_${shippingMethod?.methodCode}`
export const deserializeShippingMethod = (
    shippingMethod: string,
): ShippingMethod => {
    const parts = shippingMethod.split('_', 2)

    return {
        carrierCode: parts[0],
        methodCode: parts[1],
    }
}

export const serializePickupPoint = (values: PickUpPointFormValues) =>
    `${values?.pickUpPointId}|${values.billingAddress?.country}`
