import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './ContactUsModal.module.scss'
import { useCoreConfigValue } from '../../coreConfig.query'
import Button from '../../input/Button'
import Icon from '../../media/Icon'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'
import CloseIcon from '../CloseIcon'
import ChatButton from '../ContactUsChatButton'
import EmailButton from '../ContactUsEmailButton'

const StyledText = styled(Text)`
    margin-bottom: 10px;
`

// TODO: Make an env var
const REACT_APP_ROBINHQ_PUBLIC_TOKEN: string | undefined =
    process.env.REACT_APP_ROBINHQ_PUBLIC_TOKEN

interface Props {
    close(): void
    className?: string
    proactiveChat?: boolean
}

const ContactUsModal = ({
    close,
    className,
    proactiveChat,
    ...others
}: Props) => {
    const { value: helpUrlKey } = useCoreConfigValue(
        'justbrands_faq/settings/url_key',
        false,
    )

    const { value: personalShopperLabel } = useCoreConfigValue(
        'justbrands_customer_service_popup/buttons/personal_shopper_label',
        false,
    )

    const { value: personalShopperUrl } = useCoreConfigValue(
        'justbrands_customer_service_popup/buttons/personal_shopper_url',
        false,
    )

    return (
        <>
            <aside className={cx(styles.default, className)} {...others}>
                <header className={styles.header}>
                    <Heading
                        variant="h4"
                        element="h1"
                        className={styles.heading}
                    >
                        <Trans id="core.contactUsModal.heading">
                            Customer service
                        </Trans>
                    </Heading>

                    <Button
                        name="Close"
                        category="core.contactUsModal.close"
                        variant="link"
                        onClick={close}
                        className={styles.close}
                    >
                        <Icon
                            component={CloseIcon}
                            title={t({
                                id: 'core.contactUsModal.close',
                                message: `Close`,
                            })}
                        />
                    </Button>
                </header>
                {!proactiveChat && (
                    <div className={cx(styles.main, 'main')}>
                        <StyledText>
                            <Trans id="core.contactUsModal.explanation">
                                How can we help?
                            </Trans>
                        </StyledText>
                        <EmailButton close={close} />
                        {REACT_APP_ROBINHQ_PUBLIC_TOKEN && (
                            <ChatButton
                                robinHqToken={REACT_APP_ROBINHQ_PUBLIC_TOKEN}
                                close={close}
                            />
                        )}
                        {personalShopperLabel && personalShopperUrl && (
                            <Button
                                name="PersonalShoppingAdvice"
                                category="core.contactUsModal.personalShoppingAdvice"
                                variant="secondary"
                                className={styles.button}
                                to={personalShopperUrl}
                            >
                                {personalShopperLabel}
                            </Button>
                        )}
                        {helpUrlKey && (
                            <Button
                                name="FAQ"
                                category="core.contactUsModal.faq"
                                variant="secondary"
                                className={styles.button}
                                to={helpUrlKey}
                            >
                                <Trans id="core.contactUsModal.faq">FAQ</Trans>
                            </Button>
                        )}
                    </div>
                )}
                {proactiveChat && (
                    <div className={cx(styles.main, 'main')}>
                        <StyledText>
                            <Trans id="core.contactUsModal.proactiveChatMessageLine1">
                                Can we help you with something?
                            </Trans>
                            <br />
                            <Trans id="core.contactUsModal.proactiveChatMessageLine2">
                                Chat with us.
                            </Trans>
                        </StyledText>
                        {REACT_APP_ROBINHQ_PUBLIC_TOKEN && (
                            <ChatButton
                                robinHqToken={REACT_APP_ROBINHQ_PUBLIC_TOKEN}
                                close={close}
                            />
                        )}
                    </div>
                )}
            </aside>
        </>
    )
}

export default ContactUsModal
