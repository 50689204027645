import styled from '@emotion/styled'

const MessageContainer = styled('div', {
    shouldForwardProp: (props) => !['type'].includes(props.toString()),
})<{ type: string }>`
    border-radius: 3px;
    margin: 0.5rem 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    font-size: 12px;
    transition: all 300ms ease-in;
    color: ${({ type }) => `var(--color-message-color-${type})`};
    background-color: ${({ type }) =>
        `var(--color-message-background-${type})`};
`

const MessageContent = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 0;
    padding: 1rem 0.5rem;
`

const MessageIconContainer = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-right: 16px;
    margin-left: 16px;
`

const MessageIcon = styled('div', {
    shouldForwardProp: (props) => !['type'].includes(props.toString()),
})<{ type: string }>`
    padding-top: 2px;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: ${({ type }) =>
        `var(--color-message-icon-background-${type})`};
    color: ${({ type }) => `var(--color-message-icon-color-${type})`};
    fill: ${({ type }) => `var(--color-message-icon-color-${type})`};
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
`

export { MessageContainer, MessageContent, MessageIcon, MessageIconContainer }
