import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'

import { getStoreLanguage, useActiveStoreView } from '@emico/storeviews'
import { maxWidth, minWidth } from '@emico/styles'

import ContactBlock from './ContactBlock'
import MenuBlock from './MenuBlock'
import LanguageSelector from '../../footer/LanguageSelector'
import Container from '../../layout/Container'
import Icon from '../../media/Icon'
import Link from '../../navigation/Link'
import theme from '../../theme'
import Heading from '../../typography/Heading'
import { getAndroidButtonByLang, getIosButtonByLang } from '../DownloadAppIcon'
import FooterSocialLinks from '../FooterSocialLinks'

interface Props {
    showSocials: boolean
    showDownloadApp: boolean
    showLanguageSelector: boolean
    urlIosApp: string
    urlAndroidApp: string
    newsletterHeading?: string
    newsletterContent?: string
}

const StyledFooter = styled.div`
    background: ${theme.colors.footerMainBackground};
    padding-top: 45px;
    padding-bottom: 55px;

    @media ${maxWidth('sm')} {
        padding-top: 25px;
        padding-bottom: 25px;
    }
`

const StyledIcon = styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'fontSize',
})<{
    fontSize: number
}>`
    fill: initial;
    font-size: ${(props) => props.fontSize}px;
`

const StyledHeading = styled(Heading, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{
    open?: boolean
}>`
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    svg {
        transform: ${(props) =>
            props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
        transition: transform 0.2s ease;
        font-size: 12px;
    }
`

const IconsContainer = styled.div`
    display: flex;
    gap: 10px;
`

const BlockContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    margin-top: 25px;

    @media ${minWidth('md')} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 45px;

        > div:nth-of-type(2) {
            grid-column: span 2;
        }
    }
`

const Separator = styled.div`
    margin-bottom: 25px;

    @media ${minWidth('md')} {
        border-bottom: 1px solid
            var(--color-footer-divider, --color-footer-top-background);
        margin-top: 45px;
        margin-bottom: 45px;
    }
`

const FooterMain = ({
    showSocials,
    showDownloadApp,
    showLanguageSelector,
    urlAndroidApp,
    urlIosApp,
    newsletterHeading,
    newsletterContent,
}: Props) => {
    const activeStoreView = useActiveStoreView()
    const lang = getStoreLanguage(activeStoreView)

    const downloadIosAppTitle = t({
        id: 'footer.download.urlIos',
        message: `Download The App On Ios`,
    })

    const downloadAndroidAppTitle = t({
        id: 'footer.download.urlAndroid',
        message: `Download The App On Android`,
    })

    return (
        <StyledFooter>
            <Container>
                <ContactBlock
                    title={newsletterHeading}
                    content={newsletterContent}
                />
            </Container>
            <Container>
                <Separator />
            </Container>
            <MenuBlock />
            <Container>
                <BlockContainer>
                    {showSocials && (
                        <div>
                            <StyledHeading element="span" variant="h3">
                                <Trans id="footer.heading.followUs">
                                    Follow us
                                </Trans>
                            </StyledHeading>
                            <FooterSocialLinks />
                        </div>
                    )}
                    {showDownloadApp && (urlIosApp || urlAndroidApp) && (
                        <div>
                            <StyledHeading element="span" variant="h3">
                                <Trans id="footer.heading.downloadApp">
                                    Download app
                                </Trans>
                            </StyledHeading>
                            <IconsContainer>
                                {urlIosApp && (
                                    <Link
                                        name="DownloadTheAppOnIos"
                                        variant="camouflaged"
                                        category="link-list"
                                        to={urlIosApp}
                                        title={downloadIosAppTitle}
                                    >
                                        <StyledIcon
                                            component={getIosButtonByLang(lang)}
                                            title={downloadIosAppTitle}
                                            fontSize={40}
                                        />
                                    </Link>
                                )}
                                {urlAndroidApp && (
                                    <Link
                                        name="DownloadTheAppOnAndroid"
                                        variant="camouflaged"
                                        category="link-list"
                                        to={urlAndroidApp}
                                        title={downloadAndroidAppTitle}
                                    >
                                        <StyledIcon
                                            component={getAndroidButtonByLang(
                                                lang,
                                            )}
                                            title={downloadAndroidAppTitle}
                                            fontSize={52}
                                        />
                                    </Link>
                                )}
                            </IconsContainer>
                        </div>
                    )}
                    {showLanguageSelector && (
                        <div>
                            <StyledHeading element="span" variant="h3">
                                <Trans id="footer.heading.languageSelector">
                                    Choose your country
                                </Trans>
                            </StyledHeading>
                            <LanguageSelector />
                        </div>
                    )}
                </BlockContainer>
            </Container>
        </StyledFooter>
    )
}

export default FooterMain
