import * as React from 'react'

import RecommendedProductsComponent, {
    RecommendedProductProps,
} from './RecommendedProductsComponent'
import useProductsBySku from '../useProductsBySku'

interface Props extends RecommendedProductProps {
    skus: string[]
}

const RecommendedProductsBlueConic = ({ skus, ...props }: Props) => {
    const { data: products, loading } = useProductsBySku(skus)

    // When loading or when there are no recommendations just hide the entire panel
    if (loading || !products || products.length === 0) {
        return null
    }

    return (
        <RecommendedProductsComponent
            {...props}
            products={products}
            loading={loading}
        />
    )
}

export default RecommendedProductsBlueConic
