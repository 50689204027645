import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import {
    CastironPageBodyStoriesOverviewHeader,
    JustbrandsPageBodyStoriesOverviewHeader,
    PageBodyStoriesOverviewHeader,
    VanguardPageBodyStoriesOverviewHeader,
} from '../../../graphql/schema.generated'
import Container from '../../../layout/Container'
import StoriesOverviewBanner from '../StoriesOverviewBanner'

const HeaderWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 20px;
    padding: 20px 0;

    & > div {
        grid-row: span 1;
        aspect-ratio: 5 / 4;
    }

    & > div:first-of-type {
        grid-row: span 2;
    }

    & > div:last-child:nth-child(2) {
        grid-row: span 2;
    }

    & > div:last-child:nth-child(4) {
        grid-row: span 2;
    }

    @media ${minWidth('md')} {
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        aspect-ratio: 16/7;
        height: auto;

        & > div {
            aspect-ratio: unset;
        }
    }
`

const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    background-color: white;
    width: 100%;

    @media ${minWidth('md')} {
        height: auto;
    }
`

export interface Props {
    slice:
        | PageBodyStoriesOverviewHeader
        | JustbrandsPageBodyStoriesOverviewHeader
        | CastironPageBodyStoriesOverviewHeader
        | VanguardPageBodyStoriesOverviewHeader
}

const StoriesOverviewHeader = ({ slice }: Props) => {
    let images = slice.fields ?? []

    images = images.slice(0, 5) // Show a max of 5 images at all times

    return (
        <Container>
            <HeaderWrapper>
                {images.map((image, i) => (
                    <ImageWrapper key={i}>
                        <StoriesOverviewBanner
                            image={image?.desktopImage?.url ?? ''}
                            image_mobile={image?.mobileImage?.url}
                            bannerLink={image.url ?? ''}
                            heading={image.title ?? ''}
                            subHeading={image.subtitle ?? ''}
                            imageAlt={image.title ?? ''}
                            buttonText={image?.buttonText ?? ''}
                        />
                    </ImageWrapper>
                ))}
            </HeaderWrapper>
        </Container>
    )
}

export default StoriesOverviewHeader
