import cx from 'classnames'
import { snakeCase } from 'snake-case'

import { useBreakpoints } from '@emico/ui'

import Carousel, { DEFAULT_SETTINGS } from './Carousel'
import styles from './RecommendedProducts.module.scss'
import { TweakwiseNavigateItem } from '../catalog/common/ProductFilterPage/TweakwiseNavigate.query'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import { LazyProductPage } from '../chunks'
import Button from '../input/Button'
import Col from '../layout/Col'
import Container, {
    Props as ContainerProps,
} from '../layout/Container/Container'
import Row from '../layout/Row'
import ProductCard, { ProductCardThemesType } from '../presentation/ProductCard'
import { ProductCardFragment } from '../ProductCardFragment'
import ButtonVariant from '../types/ButtonVariant'
import HeadingColor from '../types/HeadingColor'
import TextColor from '../types/TextColor'
import Heading from '../typography/Heading'
import Text from '../typography/Text'
import useSelectItem from '../utils/ga4/useSelectItem'

export interface RecommendedProductProps
    extends Omit<ContainerProps, 'noPadding'> {
    templateId: number
    products?: Array<Product | ProductCardFragment | ConfigurableProduct>
    header: string
    content?: string
    onClick?: () => void
    buttonText?: string
    buttonStyle?: string
    buttonUrl?: string
    className?: string
    headingClassName?: string
    contentClassName?: string
    contentInnerClassName?: string
    headingColor?: HeadingColor
    textColor?: TextColor
    withOffset?: boolean
    loading?: boolean
    itemListSlot: string
}

const RecommendedProductsComponent = ({
    products,
    templateId,
    header,
    content,
    buttonUrl,
    buttonText,
    buttonStyle,
    className,
    headingClassName,
    onClick,
    contentClassName,
    contentInnerClassName,
    loading,
    headingColor = HeadingColor.dark,
    textColor = TextColor.dark,
    withOffset = false,
    itemListSlot,
    ...containerProps
}: RecommendedProductProps) => {
    const selectItem = useSelectItem()

    const { isMobile } = useBreakpoints()

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    const handleProductClick = (product: TweakwiseNavigateItem) => {
        selectItem(product, {
            item_list_id: snakeCase(header),
            item_list_name: header,
            index: products ? products.indexOf(product) + 1 : 0,
        })
    }

    // When loading or when there are no recommendations just hide the entire panel
    if (loading || !products || products.length === 0) {
        return null
    }

    const buttonColorByTheme: ButtonVariant =
        buttonStyle === 'highlight'
            ? ButtonVariant.highlight
            : headingColor === 'light'
              ? ButtonVariant.white
              : ButtonVariant.primary

    return (
        <Container {...containerProps} noPadding>
            <Row className={cx(styles.default, className)} noGutters>
                <Col lg={3}>
                    <div className={contentClassName}>
                        <Heading
                            variant="h2"
                            element="h2"
                            color={headingColor}
                            className={headingClassName}
                            fuss={['pme']}
                        >
                            {header}
                        </Heading>

                        {content && (
                            <div className={contentInnerClassName}>
                                <Text color={textColor}>{content}</Text>
                            </div>
                        )}

                        {buttonUrl && buttonText && (
                            <Button
                                to={buttonUrl}
                                className={styles.button}
                                name={buttonText}
                                onClick={onClick}
                                variant={buttonColorByTheme}
                                category="cms.homePage.recommendedProducts"
                                minWidth
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                </Col>

                <Col lg={9}>
                    <Carousel
                        settings={{
                            ...DEFAULT_SETTINGS,
                            variableWidth: !isMobile,
                        }}
                    >
                        {products.map((product) => (
                            <div
                                className={styles.slideWrapper}
                                key={product.id}
                            >
                                <ProductCard
                                    product={product}
                                    theme={ProductCardThemesType.shaded}
                                    onClick={handleProductClick}
                                    onMouseEnter={handleMouseEnter}
                                    itemIndex={0}
                                    itemListId="recommended_products"
                                    itemListName="Recommended Products"
                                    itemListSlot={itemListSlot}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    )
}

export default RecommendedProductsComponent
