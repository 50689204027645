import cx from 'classnames'
import * as React from 'react'

import styles from './ErrorIndicator.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
    className?: string
    // Specify on which field this error indicator applies to. This is used in
    // tests but may also be used in the future to assist screen readers.
    field: string
}

/**
 * Used in form elements for (single) input validation feedback tooltips.
 */
const ErrorIndicator = ({ children, className, field, ...props }: Props) => (
    <div
        data-cy="ErrorIndicator"
        data-for={field}
        className={cx(styles.base, className)}
        {...props}
    >
        {children}
    </div>
)

export default ErrorIndicator
