import cx from 'classnames'
import * as React from 'react'

import styles from './Loader.module.scss'

interface Props {
    className?: string
}

const Loader = ({ className }: Props) => (
    <div className={cx(styles.circle, className)} />
)

export default Loader
