import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import Heading from '../../typography/Heading'
import Text from '../../typography/Text'

const CategoryHeading = styled(Heading)`
    margin: 50px 0 20px;
    text-align: center;
`

export const NoResultsMessage = ({ keyword }: { keyword: string }) => (
    <div
        // eslint-disable-next-line react/no-unknown-property
        css={css`
            margin: 0 auto 50px;
            max-width: 750px;
            text-align: center;
        `}
    >
        <CategoryHeading variant="h1" element="h1">
            <Trans id="core.search.noResultsHeading">
                Oops, we could not find anything for "{keyword}"
            </Trans>
        </CategoryHeading>
        <Text>
            <Trans id="core.search.noResultsText">
                Check if the keyword is correct or try a less specific keyword.
            </Trans>
        </Text>
    </div>
)
