import { makeVar, useApolloClient, useReactiveVar } from '@apollo/client'
import { useIsLoggedIn, useLoginTokenContext } from '@emico-hooks/login-token'
import { useEffect } from 'react'

import { syncReactiveVar } from '@emico/reactive-var-utils'

import { createGuestWishlist } from './createWishlist.mutation'
import { getWishlist } from './GetWishlist.query'
import { mergeWishlists } from './mergeWishlists.mutation'
import { getCustomer } from '../customer/getCustomer.query'

export const wishlistIdVar = syncReactiveVar(
    makeVar<string | null>(null),
    'wishlistId',
)

const DetectWishlistId = () => {
    const client = useApolloClient()
    // Monitor user token so we get notified of login status change
    const isUserSignedIn = useIsLoggedIn()
    const wishlistId = useReactiveVar(wishlistIdVar)
    const customerToken = useLoginTokenContext()()

    useEffect(() => {
        if (isUserSignedIn && customerToken) {
            // A logged in customer always has a wishlist id.
            // It could be that the token is invalid. In that case, the user gets redirected to the login page.
            // See: https://support.emico.nl/issues/135293
            getCustomer(client, customerToken)
                .then(({ data }) => {
                    if (!data?.customer) {
                        throw new Error(
                            'No customer found for wishlist. Its most likely that the session is expired',
                        )
                    }

                    const customerWishlistId = data.customer.wishlists[0]?.id

                    if (!customerWishlistId) {
                        throw new Error(
                            'Unable to get wishlist id from customer',
                        )
                    }

                    // Merge cart when there's a guest wishlist present
                    // Use the customers wishlist when the guest wishlist is invalid
                    if (wishlistId && customerWishlistId !== wishlistId) {
                        getWishlist(client)(wishlistId, customerToken)
                            .then((data) => {
                                if (data.error || data.errors) {
                                    wishlistIdVar(customerWishlistId)
                                } else {
                                    // Merge and set wishlist
                                    mergeWishlists(client)(
                                        wishlistId,
                                        customerWishlistId,
                                        customerToken,
                                    ).then(() => {
                                        wishlistIdVar(customerWishlistId)
                                    })
                                }
                            })
                            .catch((e) => {
                                wishlistIdVar(customerWishlistId)
                            })
                    } else {
                        wishlistIdVar(customerWishlistId)
                    }
                })
                .catch((e) => {
                    createGuestWishlist(client).then((data) => {
                        const guestWishlistId =
                            data.data?.createGuestWishlist?.wishlist.id

                        wishlistIdVar(guestWishlistId)
                    })
                })
        } else {
            if (!wishlistId) {
                return
            }
            // If the user isn't logged in, and there is a wishlist id,
            // we have to verify it first
            getWishlist(client)(wishlistId, false)
                .then((data) => {
                    if (data.error || data.errors) {
                        wishlistIdVar(null)
                        createGuestWishlist(client).then((data) => {
                            const guestWishlistId =
                                data.data?.createGuestWishlist?.wishlist.id

                            wishlistIdVar(guestWishlistId)
                        })
                    }
                })
                .catch((e) => {
                    wishlistIdVar(null)
                    createGuestWishlist(client).then((data) => {
                        const guestWishlistId =
                            data.data?.createGuestWishlist?.wishlist.id

                        wishlistIdVar(guestWishlistId)
                    })
                })
        }
        // TODO: Might need to add wishlistId to the dependency list if problems occur
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, isUserSignedIn])
}

export default DetectWishlistId
