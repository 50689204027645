import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import * as React from 'react'

import { maxWidth } from '@emico/styles'

import SubscribeToNewsletterForm from '../../newsletter/SubscribeToNewsletterForm'
import Heading from '../../typography/Heading'
import Paragraph from '../../typography/Text'

const ContactBlockContainer = styled.div`
    display: flex;
    align-content: stretch;
    justify-content: flex-start;
    width: 100%;

    @media ${maxWidth('sm')} {
        flex-direction: column;
        gap: 20px;
    }
`

const ContactContent = styled.div`
    min-width: 33%;
    margin-right: 20px;
`

const StyledParagraph = styled(Paragraph)`
    margin-top: 10px;
    line-height: 18px;
`

interface Props {
    title?: React.ReactNode
    content?: React.ReactNode
}
const ContactBlock = ({ title, content }: Props) => (
    <ContactBlockContainer>
        <ContactContent>
            <Heading variant="h3" element="h3">
                {title || (
                    <Trans id="footer.contactblock.heading">
                        Stay up to date
                    </Trans>
                )}
            </Heading>
            <StyledParagraph>
                {content || (
                    <Trans id="footer.contactblock.content">
                        Sign up for the latest PME Legend news and exclusive
                        offers.
                    </Trans>
                )}
            </StyledParagraph>
        </ContactContent>

        <SubscribeToNewsletterForm source="footer" />
    </ContactBlockContainer>
)

export default ContactBlock
