import { useCartItems } from './cart/CartPage/useCartItems'

const useIsCheckoutAccessible = () => {
    const { loading, items } = useCartItems()

    if (loading) {
        return true
    }

    const canCheckout = !items?.find(
        (item) =>
            !item.reservation?.isSalable ||
            item.reservation?.salableQty < item.quantity,
    )

    return canCheckout
}

export default useIsCheckoutAccessible
