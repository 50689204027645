import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './JeansFilter.module.scss'
import {
    TweakwiseNavigationAttribute,
    TweakwiseNavigationFacet,
} from '../../../../graphql/schema.generated'
import CheckButton from '../../../../input/CheckButton'

interface Props {
    filter: TweakwiseNavigationFacet
    value: string[]
    onChange(newValue: string[] | undefined): void
}

interface GridItem {
    [key: string]: { [key: string]: TweakwiseNavigationAttribute }
}

export const getJeansFilterSizes = (filter: TweakwiseNavigationFacet) => {
    const widths = [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38, 40, 42]
    const lengths = [30, 32, 34, 36, 38]

    // Map items so we can easily create a grid from them
    const gridItems: GridItem = {}

    filter.attributes.forEach((option) => {
        const [width, length] = option.title.split('-')

        if (!gridItems[width]) {
            gridItems[width] = {}
        }
        gridItems[width][length] = option
    })

    return { widths, lengths, gridItems }
}

export const isJeansFilter = (filter: TweakwiseNavigationFacet) =>
    filter.facetSettings.urlKey.includes('jeans')

const JeansFilter = ({ filter, value, onChange }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const option = e.target.value
        const checked = e.target.checked
        // Copy the array and remove any existing instances in one go
        const newValue = value ? value.filter((item) => item !== option) : []

        if (checked) {
            newValue.push(option)
        }
        onChange(newValue.length === 0 ? undefined : newValue)
    }

    const { widths, lengths, gridItems } = getJeansFilterSizes(filter)

    return (
        <div className={styles.base}>
            {widths.map((width) => {
                const hasSelection =
                    value &&
                    value.some((val) => val.split('-')[0] === width.toString())

                return (
                    <div className={styles.row} key={width}>
                        <div
                            className={cx(styles.cell, styles.widthLabel, {
                                [styles.hasSelection]: hasSelection,
                            })}
                        >
                            W{width}
                        </div>

                        {lengths.map((length) => {
                            const option =
                                (gridItems[width] &&
                                    gridItems[width][length]) ||
                                undefined

                            if (option) {
                                return (
                                    <div
                                        key={`${width}${length}`}
                                        className={[
                                            styles.cell,
                                            styles.checkbox,
                                        ].join(' ')}
                                    >
                                        <CheckButton
                                            key={option.title}
                                            className={styles.option}
                                            onChange={handleChange}
                                            value={option.title}
                                            checked={
                                                value
                                                    ? value.includes(
                                                          option.title,
                                                      )
                                                    : false
                                            }
                                            name={option.title}
                                            category="catalog.productFilterPage.filterBar.checkboxFilter"
                                        >
                                            L{length}
                                        </CheckButton>
                                    </div>
                                )
                            }

                            return (
                                <div
                                    key={`${width}-${length}`}
                                    className={[styles.cell, styles.blank].join(
                                        ' ',
                                    )}
                                    title={t({
                                        id: 'catalog.productFilterPage.filterBar.sizeUnavailable',
                                        message: `Size unavailable for current selection`,
                                    })}
                                />
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export default JeansFilter
