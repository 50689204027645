import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { PrismicDocument } from '@emico/graphql-schema-types'
import { usePrismicDocuments } from '@emico/prismic'
import { minWidth } from '@emico/styles'

import StoryCard from './StoryCard'
import { Page, Tag } from '../../graphql/schema.generated'
import Button from '../../input/Button'
import Container from '../../layout/Container'

interface Props {
    prismicType?: string
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 30px 0 50px;

    @media ${minWidth('md')} {
        grid-template-columns: repeat(4, 1fr);
    }
`

const TagsWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    gap: 10px;
    white-space: nowrap;
    overflow: auto;
    justify-content: left;
`

const StoriesOverviewPage = ({ prismicType = 'page' }: Props) => {
    const [selectedStories, setSelectedStories] = useState<PrismicDocument[]>(
        [],
    )
    const location = useLocation()
    const history = useHistory()
    const query = new URLSearchParams(location.search).get('tagId')
    let tagType = 'tag'

    if (prismicType.toLowerCase().includes('justbrands')) {
        tagType = 'justbrands_tag'
    }
    if (prismicType.toLowerCase().includes('castiron')) {
        tagType = 'castiron_tag'
    }
    if (prismicType.toLowerCase().includes('vanguard')) {
        tagType = 'vanguard_tag'
    }

    const { data: stories } = usePrismicDocuments<Page>({
        key: `my.${prismicType}.page_type`,
        values: ['story'],
    })

    const { data: tags, loading: loadingTags } = usePrismicDocuments<Tag>({
        key: 'document.type',
        values: [tagType],
    })

    const selectedTagId =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tags?.find((item) => item.data.tagName === query)?.documentId

    useEffect(() => {
        if (!stories) {
            return
        }
        if (query !== null) {
            setSelectedStories(
                stories.filter(
                    (story) =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        story.data.storyTag?.id === selectedTagId,
                ),
            )
        } else {
            setSelectedStories(stories)
        }
    }, [stories, selectedTagId, query])

    const tagIds = (stories || []).map(
        (story) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            story.data.storyTag?.id,
    )

    return (
        <Container>
            {!loadingTags && (
                <TagsWrapper>
                    <Button
                        category="storiesOverviewPage.tags.button"
                        name="storiesOverviewPage.tags"
                        onClick={() => history.replace(location.pathname)}
                        variant={query === null ? 'secondary' : 'secondaryAlt'}
                    >
                        <Trans id="storiesOverviewPage.showAll">Show all</Trans>
                    </Button>
                    {tags &&
                        tags
                            .filter((tag) =>
                                tagIds.includes(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    tag.documentId,
                                ),
                            )
                            .map((tag, i) => (
                                <Button
                                    key={tag.data.tagName}
                                    category="storiesOverviewPage.tags.buttons"
                                    name="storiesOverviewPage.tags"
                                    onClick={() =>
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        history.replace(
                                            `?tagId=${encodeURIComponent(
                                                tag.data.tagName ?? '',
                                            )}`,
                                        )
                                    }
                                    variant={
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        selectedTagId === tag.documentId
                                            ? 'secondary'
                                            : 'secondaryAlt'
                                    }
                                >
                                    {tag.data.tagName}
                                </Button>
                            ))}
                </TagsWrapper>
            )}
            <Wrapper>
                {[...selectedStories]
                    .sort(
                        (a, b) =>
                            new Date(
                                (b.data as Page).publicationField,
                            ).getTime() -
                            new Date(
                                (a.data as Page).publicationField,
                            ).getTime(),
                    )
                    .map((story, i) => (
                        <StoryCard key={i} story={story.data as Page} />
                    ))}
            </Wrapper>
        </Container>
    )
}

export default StoriesOverviewPage
