// Check if element is in viewport
const elementInViewport = (element: HTMLElement, topPos: number) => {
    const bounding = element.getBoundingClientRect()

    return (
        bounding.top <= topPos &&
        bounding.top >= (bounding.height - topPos) * -1
    )
}

export default elementInViewport
