import classNames from 'classnames'
import { ComponentProps, forwardRef, MutableRefObject, ReactNode } from 'react'

import Button from './input/Button'
import List from './List'
import styles from './MemberUsps.module.scss'
import ButtonVariant from './types/ButtonVariant'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'
import Text from './typography/Text'

export interface Props {
    heading: string | ReactNode
    headingVariant?: HeadingVariant
    usps: ComponentProps<typeof List>['items']
    content?: string
    buttonText?: string
    buttonUrl?: string
    buttonStyle?: ButtonVariant
    button2Url?: string
    button2Text?: string
    button2Style?: ButtonVariant
    containerClassname?: string
    wrapperClassname?: string
    listClassname?: string
    uspTextColor?: 'dark' | 'light'
    buttonClassname?: string
    uspIconClassname?: string
    showPriviligesLogo?: boolean
}

const MemberUsps = forwardRef(
    (
        {
            heading,
            headingVariant = HeadingVariant.h2,
            content,
            usps,
            buttonText,
            buttonUrl,
            buttonStyle = ButtonVariant.primary,
            button2Text,
            button2Url,
            button2Style = ButtonVariant.secondary,
            containerClassname,
            wrapperClassname,
            listClassname,
            uspTextColor = 'dark',
            buttonClassname,
            uspIconClassname,
        }: Props,
        ref,
    ) => {
        if (usps.length === 0) {
            return null
        }

        const hasButton = buttonUrl && buttonText
        const has2ndButton = button2Url && button2Text
        const hasButtons = hasButton || has2ndButton

        return (
            <section className={classNames(styles.section, containerClassname)}>
                <div
                    className={classNames(styles.wrapper, wrapperClassname)}
                    ref={ref as MutableRefObject<HTMLDivElement>}
                >
                    <div className={styles.content}>
                        {typeof heading === 'string' ? (
                            <Heading variant={headingVariant} element="h2">
                                {heading}
                            </Heading>
                        ) : (
                            heading
                        )}

                        {content && <Text>{content}</Text>}

                        {hasButtons && (
                            <div
                                className={classNames(
                                    styles.buttons,
                                    buttonClassname,
                                )}
                            >
                                {hasButton && (
                                    <Button
                                        minWidth
                                        to={buttonUrl}
                                        name="memberUspButton"
                                        category=""
                                        className={styles.button}
                                        variant={buttonStyle}
                                    >
                                        {buttonText}
                                    </Button>
                                )}

                                {has2ndButton && (
                                    <Button
                                        minWidth
                                        to={button2Url}
                                        name="memberUspButton2"
                                        category=""
                                        className={styles.button}
                                        variant={button2Style}
                                    >
                                        {button2Text}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.listWrapper}>
                        <div className={styles.list}>
                            <List
                                iconClassname={uspIconClassname}
                                items={usps}
                                className={listClassname}
                                color={uspTextColor}
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    },
)

MemberUsps.displayName = 'MemberUsps'
export default MemberUsps
