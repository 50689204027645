import * as React from 'react'
import Slider, { Settings } from 'react-slick'

import './slick.scss'

import LockScroll from '../LockScroll'

const mapSettings = (settings: CarouselSettings) => ({
    initialSlide: settings.initialSlide ?? 0,
    dots: settings.dots !== undefined ? settings.dots : false,
    arrows: settings.arrows !== undefined ? settings.arrows : false,
    infinite: settings.infinite !== undefined ? settings.infinite : true,
    speed: settings.speed ? settings.speed : 500,
    slidesToShow: settings.slidesToShow ? settings.slidesToShow : 5,
    fade: settings.fade !== undefined ? settings.fade : false,
    vertical: settings.vertical !== undefined ? settings.vertical : false,
    verticalSwiping:
        settings.vertical !== undefined ? settings.vertical : false,
    variableWidth:
        settings.variableWidth !== undefined ? settings.variableWidth : false,
    cssEase: settings.cssEase ? settings.cssEase : 'ease-out',
    currentIndex: settings.currentIndex ? settings.currentIndex : 0,
    responsive: !settings.variableWidth
        ? [
              {
                  breakpoint: 992,
                  settings: {
                      slidesToShow:
                          settings.slidesToShowLg !== undefined
                              ? settings.slidesToShowLg
                              : 3,
                  },
              },
              {
                  breakpoint: 767,
                  settings: {
                      slidesToShow:
                          settings.slidesToShowMd !== undefined
                              ? settings.slidesToShowMd
                              : 2,
                  },
              },
              {
                  breakpoint: 480,
                  settings: {
                      slidesToShow:
                          settings.slidesToShowSm !== undefined
                              ? settings.slidesToShowSm
                              : 1,
                  },
              },
          ]
        : undefined,
})

interface CarouselSettings {
    afterChange?(currentSlide: number): void
    arrows?: boolean
    cssEase?: string
    currentIndex?: number
    dots?: boolean
    fade?: boolean
    infinite?: boolean
    slidesToShow?: number
    slidesToShowLg?: number
    slidesToShowMd?: number
    slidesToShowSm?: number
    speed?: number
    variableWidth?: boolean
    vertical?: boolean
    initialSlide?: number
}

interface OwnProps extends Settings {
    children: React.ReactNode
    settings: CarouselSettings
    className?: string
}

type Props = OwnProps & React.RefAttributes<Slider>

const Carousel = React.forwardRef<Slider, Props>(function Carousel(
    { children, settings, className, ...others },
    ref,
) {
    const [currentIndex, setCurrentIndex] = React.useState(0)

    settings.currentIndex = currentIndex
    const mappedSettings = mapSettings(settings)

    const afterChange = (index: number): void => {
        setCurrentIndex(index)
    }

    return (
        <LockScroll data-cy="carousel" body-scroll-lock-ignore="true">
            <Slider
                {...mappedSettings}
                afterChange={afterChange}
                className={className}
                {...others}
                ref={ref}
            >
                {children}
            </Slider>
        </LockScroll>
    )
})

export default Carousel
