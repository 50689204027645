import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import compareHours from './compareHours'
import { GTime, Maybe } from '../graphql/schema.generated'
import theme from '../theme'
import Text from '../typography/Text'

const Wrapper = styled.div`
    margin-bottom: 15px;
    color: ${theme.colors.primary};
`

interface Props {
    times: Array<Maybe<GTime>>
    className?: string
}

const OpenOrClosed = ({ times, className }: Props) => {
    const now = new Date()
    const openHours = times?.[now.getDay()]

    const openTill =
        openHours?.open && openHours?.close
            ? compareHours(openHours.open, openHours.close, now)
            : undefined

    return (
        <Wrapper className={className}>
            {openTill ? (
                <Text>
                    {t({
                        id: 'storelocator.storedetails.openUntill',
                        message: `Now open until`,
                    })}{' '}
                    {openHours?.close}
                </Text>
            ) : (
                <Text>
                    {t({
                        id: 'storelocator.storedetails.closed',
                        message: `Now closed`,
                    })}{' '}
                </Text>
            )}
        </Wrapper>
    )
}

export default OpenOrClosed
