import cx from 'classnames'
import * as React from 'react'

import styles from './Team.module.scss'
import ImageLazy from '../../../media/ImageLazy'
import { CmsPageTeamItem } from '../../../types/CmsPages'
import Text from '../../../typography/Text'

interface Props extends CmsPageTeamItem {
    isActive: boolean
    index: number
    setActiveEmployee(i: number): unknown
}

const TeamPortrait = ({
    firstName,
    portraitImage,
    isActive,
    index,
    setActiveEmployee,
}: Props) => {
    const activeClass = isActive ? styles.active : ''

    return (
        <div
            className={cx(activeClass, styles.portrait)}
            onClick={() => setActiveEmployee(index)}
            key={index}
        >
            {portraitImage?.url && (
                <div className={styles.portraitImageWrapper}>
                    <ImageLazy
                        alt={firstName ?? ''}
                        images={{
                            image: portraitImage.url,
                            regularWidth: 58,
                            regularHeight: 58,
                            mediumWidth: 58,
                            mediumHeight: 58,
                            smallWidth: 60,
                            smallHeight: 60,
                        }}
                        className={styles.portraitImage}
                    />
                </div>
            )}

            {firstName && (
                <Text as="div" color="dark" className={styles.employeeName}>
                    {firstName}
                </Text>
            )}
        </div>
    )
}

export default TeamPortrait
