import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import Button from '../../../input/Button'
import Link from '../../../navigation/Link'
import breakpoints from '../../../theme/breakpoints'
import Heading from '../../../typography/Heading'

const Wrapper = styled.article`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: black;

    img {
        transition: transform 0.4s;
    }

    &:hover {
        img {
            transform: scale(1.15);
        }
    }
`

const StyledPicture = styled(Picture)`
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;

    @media ${minWidth('md')} {
        position: absolute;
    }
`

const StyledHeading = styled(Heading)`
    margin-bottom: 0.25rem;
`

const StyledSubHeading = styled(Heading)`
    margin-bottom: 0.5rem;
`

const Content = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;

    @media ${minWidth('lg')} {
        bottom: 30px;
        left: 30px;
        right: 30px;
    }
`

const StyledLink = styled(Link)`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

interface Props {
    heading?: string
    subHeading?: string
    image: string
    image_mobile?: string
    imageAlt?: string
    imageSizes?: {
        regularWidth?: number
        regularHeight?: number
        mediumWidth?: number
        mediumHeight?: number
        smallWidth?: number
        smallHeight?: number
    }
    className?: string
    bannerLink?: string
    buttonText?: string
}

const StoriesOverviewBanner = ({
    image,
    image_mobile,
    imageAlt,
    heading,
    subHeading,
    bannerLink,
    buttonText,
    className,
}: Props) => (
    <Wrapper className={className}>
        <StyledPicture
            breakpoints={{
                [breakpoints.xs]: {
                    url: image_mobile ?? image,
                    width: 767,
                    height: 500,
                },
                [breakpoints.md]: {
                    url: image ?? image_mobile,
                    width: 991,
                    height: 767,
                },
            }}
            lazy
            params={{ resizingType: 'auto', gravity: 'sm' }}
            alt={imageAlt ?? ''}
        />

        {heading && (
            <Content>
                <StyledHeading
                    element="h2"
                    variant="h1"
                    color="light"
                    fuss={['pme']}
                >
                    {heading}
                </StyledHeading>
                {subHeading && (
                    <StyledSubHeading element="div" variant="h4" color="light">
                        {subHeading}
                    </StyledSubHeading>
                )}
                {buttonText && (
                    <Button
                        minWidth
                        variant="primary"
                        category="story.banner"
                        name={buttonText}
                    >
                        {buttonText}
                    </Button>
                )}
            </Content>
        )}

        {bannerLink && (
            <StyledLink
                name="Banner link"
                category="storiesOverviewBanner"
                to={bannerLink}
            >
                <span />
            </StyledLink>
        )}
    </Wrapper>
)

export default StoriesOverviewBanner
