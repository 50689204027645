import { ComponentProps, ReactNode } from 'react'

import { Link } from '@emico/link'
import { overrideOptional } from '@emico/overrides'

import PrismicDocumentLink from './PrismicDocumentLink'
import { PrismicHyperlink } from './responseTypes'

interface Props
    extends Omit<ComponentProps<typeof Link>, 'to' | 'analyticsName'> {
    children: ReactNode
    to: PrismicHyperlink | null
    analyticsName?: ComponentProps<typeof Link>['analyticsName']
    external?: boolean
}

const PrismicLink = ({ children, to, ...others }: Props) => {
    if (to?.linkType === 'Web') {
        return (
            <Link
                to={to.url}
                target={to?.target ? '_blank' : undefined}
                analyticsName={to.url}
                {...others}
            >
                {children}
            </Link>
        )
    }

    if (to?.linkType === 'Media') {
        return (
            <Link to={to.url} analyticsName={to.url} {...others}>
                {children}
            </Link>
        )
    }

    if (to?.linkType === 'Document') {
        return (
            <PrismicDocumentLink to={to} analyticsName={to.slug} {...others}>
                {children}
            </PrismicDocumentLink>
        )
    }

    return <>{children}</>
}

export default overrideOptional(PrismicLink)
