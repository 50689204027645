import toGA4Item from './toGAItem'
import { usePushEcommerceEvent } from './usePushEvent'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../../catalog/ProductPage/GetBasicProduct.query'
import { useRootCategoryId } from '../../catalog/useRootCategory'

const useRemoveFromCartEvent = () => {
    const rootCategoryId = useRootCategoryId()

    const push = usePushEcommerceEvent([], false)

    return (
        product: BasicProduct | BasicConfigurableProduct,
        quantity: number,
    ) => {
        if (!rootCategoryId) {
            return
        }

        const item = toGA4Item(product, rootCategoryId, {
            quantity,
        })

        push?.([
            { ecommerce: null },
            {
                event: 'remove_from_cart',
                ecommerce: {
                    items: [item],
                },
            },
        ])
    }
}

export default useRemoveFromCartEvent
