import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { Trans } from '@lingui/macro'

import { resolveConfigurableOptions } from './CartItem'
import styles from './MiniCartItemSelectedOptions.module.scss'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'

interface Props {
    cartItem: CartItemFragment
}
const MiniCartItemSelectedOptions = ({ cartItem }: Props) => {
    const selectedOptions = resolveConfigurableOptions(
        cartItem.configurableOptions.map((opt) => ({
            label: opt.optionLabel,
            value: opt.configurableProductOptionValueUid,
        })),
        cartItem.product as ConfigurableProduct,
    )

    return (
        <>
            {selectedOptions &&
                selectedOptions.map(([attribute, value]) => (
                    <span
                        key={attribute.attributeId}
                        className={styles.configurableOption}
                    >
                        <strong>{attribute.label}:</strong> {value.storeLabel}
                    </span>
                ))}
            <span className={styles.amount}>
                <Trans id="cart.miniCart.amount">
                    <strong>Amount:</strong> {cartItem.quantity}
                </Trans>
            </span>
        </>
    )
}

export default MiniCartItemSelectedOptions
