import { useCallback, useEffect, useRef, useState } from 'react'

import { globalWindow } from '@emico/ssr-utils'

/**
 * Listens for user input to determine if a user is inactive.
 *
 * @param timeout How long before a user is considered inactive
 * @param once If set to true, the inactivity will not be reset after new user interaction
 * @param immediately If set to true the count down will start immediately. This could cause a loop.
 * @returns boolean Wether the user is inactive
 */
export default function useInactivity(
    timeout: number = 5 * 60 * 1000,
    once: boolean = false,
    immediately: boolean = false,
) {
    const [inactive, setInactive] = useState(false)
    const timer = useRef<NodeJS.Timeout>()

    const handleInactivity = useCallback(() => {
        setInactive(true)
    }, [])

    const handleActivity = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        if (!once) {
            setInactive(false)
        }
        timer.current = setTimeout(handleInactivity, timeout)
    }, [handleInactivity, once, timeout])

    useEffect(() => {
        globalWindow?.addEventListener('touchstart', handleActivity, {
            passive: true,
        })
        globalWindow?.addEventListener('mousemove', handleActivity, {
            passive: true,
        })
        globalWindow?.addEventListener('keypress', handleActivity, {
            passive: true,
        })
        globalWindow?.addEventListener('focus', handleActivity, {
            passive: true,
        })
        globalWindow?.addEventListener('scroll', handleActivity, {
            passive: true,
        })

        if (immediately) {
            handleActivity()
        }

        return () => {
            globalWindow?.removeEventListener('touchstart', handleActivity)
            globalWindow?.removeEventListener('mousemove', handleActivity)
            globalWindow?.removeEventListener('keypress', handleActivity)
            globalWindow?.removeEventListener('focus', handleActivity)
            globalWindow?.removeEventListener('scroll', handleActivity)
        }
    }, [handleActivity, immediately])

    return inactive
}
