import * as React from 'react'

import styles from './CheckboxFilter.module.scss'
import { TweakwiseNavigationFacet } from '../../../../graphql/schema.generated'
import CheckButton from '../../../../input/CheckButton'
import ColorButton from '../../../../input/ColorButton'

interface Props {
    filter: TweakwiseNavigationFacet
    value: string[]
    onChange(newValue: string[] | undefined): void
}

export const isColorFilter = (filter: TweakwiseNavigationFacet) =>
    filter.facetSettings.urlKey.includes('color')

const CheckboxFilter = ({ filter, value, onChange }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const option = e.target.value
        const checked = e.target.checked
        // Copy the array and remove any existing instances in one go
        const newValue = value ? value.filter((item) => item !== option) : []

        if (checked) {
            newValue.push(option)
        }
        onChange(newValue.length === 0 ? undefined : newValue)
    }

    return (
        <div className={styles.checkboxFilter}>
            {filter.attributes.map((option) => {
                if (isColorFilter(filter)) {
                    const [name, color] = option.title.split('_')

                    if (!name || !color) {
                        return null
                    }

                    return (
                        <ColorButton
                            key={option.title}
                            onChange={handleChange}
                            value={option.title}
                            checked={
                                value ? value.includes(option.title) : false
                            }
                            name={name}
                            category="catalog.productFilterPage.filterBar.colorFilter"
                            color={color}
                        >
                            {name}
                        </ColorButton>
                    )
                }

                return (
                    <CheckButton
                        key={option.title}
                        className={styles.option}
                        onChange={handleChange}
                        value={option.title}
                        checked={value ? value.includes(option.title) : false}
                        name={option.title}
                        category="catalog.productFilterPage.filterBar.checkboxFilter"
                    >
                        {option.title}
                    </CheckButton>
                )
            })}
        </div>
    )
}

export default CheckboxFilter
