import { useReactiveVar } from '@apollo/client'
import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
    PrismicRichText,
    RichTextBlock,
    usePrismicDocument,
} from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'
import { useBreakpoints } from '@emico/ui'

import InfoIcon from './core/InfoIcon'
import { Promoinfo, UrlRewriteEntityTypeEnum } from './graphql/schema.generated'
import { getPrismicComponents } from './htmlSerializer'
import Button from './input/Button'
import Container from './layout/Container'
import { headerSizeVar } from './layout/Header/HeaderContainer'
import Icon from './media/Icon'
import paths from './paths'
import ResponsiveModal from './presentation/ResponsiveModal/ResponsiveModal'
import theme from './theme'
import Heading from './typography/Heading'
import { useResolveUrl } from './utils/resolveUrl'

const Banner = styled.div<{ top?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 35px;
    max-height: 75px;
    padding: 4px 0;
    background-color: ${theme.colors.promoBannerBackground};
    ${({ top }) =>
        top ? `position: absolute; z-index: 5; top: ${top}px;` : ''}
`

const ModalContent = styled.div`
    padding: 20px 0;
`

const StyledContainer = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
`

const IconButton = styled(Button)`
    line-height: 1;
    padding: 1em;
    margin: -1em;
`

const PromoInfo = () => {
    const headerSize = useReactiveVar(headerSizeVar)
    const headerHeight = headerSize?.height ?? 0
    const { pathname } = useLocation()
    const { data: resolvedPage, loading: loadingPage } = useResolveUrl(pathname)
    const activeStoreView = useActiveStoreView()
    const { isMobile } = useBreakpoints()
    const [isOpen, setOpen] = useState<boolean>(false)

    const toggleModal = () => {
        setOpen(!isOpen)
    }

    const { data, loading } = usePrismicDocument<Promoinfo>(
        {
            key: 'my.promoinfo.storeview',
            value: activeStoreView.prismic || 'pme',
        },
        true,
    )

    if (!data || loading || loadingPage) {
        return null
    }
    const { type } = resolvedPage || {}

    // Return if page is not a home, cart or product/category page
    if (
        !(
            pathname === paths.home ||
            pathname === paths.cart ||
            (type &&
                [
                    UrlRewriteEntityTypeEnum.CATEGORY,
                    UrlRewriteEntityTypeEnum.PRODUCT,
                ].includes(type))
        )
    ) {
        return null
    }

    const { text, textMobile, modalContent, modalTitle } = data

    const isMobileProductPage =
        isMobile && type === UrlRewriteEntityTypeEnum.PRODUCT

    // Control display of component by leaving text empty
    if ((isMobile && !textMobile) || (!isMobile && !text)) {
        return null
    }

    const enableModal = modalContent && modalTitle

    return (
        <Banner top={isMobileProductPage ? headerHeight : undefined}>
            <StyledContainer>
                <Heading color="light" element="div" variant="h4">
                    {isMobile ? textMobile : text}
                </Heading>

                {enableModal && (
                    <>
                        <IconButton
                            name=""
                            category=""
                            variant="link"
                            onClick={toggleModal}
                        >
                            <Icon
                                component={InfoIcon}
                                title={t({
                                    id: 'prismic.promoinfo.moreInfoLabel',
                                    message: `More info`,
                                })}
                                css={css`
                                    font-size: 1.2em;
                                    color: ${theme.colors.textWhite};
                                `}
                            />
                        </IconButton>

                        {isOpen && (
                            <ResponsiveModal
                                onBack={toggleModal}
                                enableScrollLock
                                header={
                                    <Heading variant="h2" element="h1">
                                        {modalTitle}
                                    </Heading>
                                }
                                small
                            >
                                <ModalContent>
                                    <ClassNames>
                                        {({ css }) => (
                                            <PrismicRichText
                                                render={
                                                    modalContent as [
                                                        RichTextBlock,
                                                    ]
                                                }
                                                components={getPrismicComponents(
                                                    {
                                                        contentClassName: css`
                                                            margin-bottom: 10px;
                                                        `,
                                                    },
                                                )}
                                                analyticsContext="prismic.promoinfo.modalText"
                                            />
                                        )}
                                    </ClassNames>
                                </ModalContent>
                            </ResponsiveModal>
                        )}
                    </>
                )}
            </StyledContainer>
        </Banner>
    )
}

export default PromoInfo
