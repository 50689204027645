import { useCallback, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { useActiveStoreView, useStoreViews } from '@emico/storeviews'

import { fetchTranslatedUrl } from '../../actions/storeView/asyncActions'
import { useDispatch } from '../../types/Redux'

export type PickerState = 'requested' | 'suggested' | 'hidden'

export type Messages = Record<
    string,
    {
        selectLanguage: string
        languageQuestion: Record<'requested' | 'suggested', string>
    }
>

// Not using lingui here. It is better to use the browser language, since it is likely the user
// cannot read the language in the current locale.
export const messages: Messages = {
    nl: {
        selectLanguage: 'Kies een taal',
        languageQuestion: {
            requested: 'Wilt u in een andere taal shoppen?',
            suggested:
                'Het lijkt er op dat uw browser in het Nederlands staat, wilt u in een andere taal winkelen?',
        },
    },
    fr: {
        selectLanguage: 'Choisissez une langue',
        languageQuestion: {
            requested:
                'Souhaitez-vous faire vos achats dans une autre langue ?',
            suggested:
                'Il semble que votre navigateur soit en France, voudriez-vous aller dans un autre magasin?',
        },
    },
}

export const languages: Record<string, string> = {
    nl: 'Nederlands',
    fr: 'Français',
}

export const supportedLanguages = ['fr', 'nl']

export function getSupportedLanguages() {
    const browserLanguages = (navigator.languages ?? [navigator.language]).map(
        (locale) => locale.split('-')[0],
    )
    return browserLanguages.find((lang) => supportedLanguages.includes(lang))
}

export function useLanguagePicker() {
    const dispatch = useDispatch()
    const [state, setState] = useState<PickerState>('hidden')
    const store = useActiveStoreView()
    const storeViews = useStoreViews()
    const [language, country] = store.locale?.split('-') ?? []

    const browserLanguage = getSupportedLanguages() ?? language
    const dict = browserLanguage ? messages[browserLanguage] : messages.nl

    useEffect(() => {
        const cookies = new Cookies()
        const languagePickerDismissed = cookies.get('language-picker-dismissed')
        if (
            browserLanguage !== language &&
            languagePickerDismissed !== 'true'
        ) {
            setState('suggested')
        }
    }, [browserLanguage, language])

    const onSwitch = useCallback(
        async (language) => {
            const storeView = storeViews.find(
                (storeView) => storeView.locale === `${language}-${country}`,
            )
            if (!storeView) {
                return
            }
            const urlKey = await dispatch(
                fetchTranslatedUrl(storeView.code, location.pathname),
            )
            location.href = storeView.makeUrl(urlKey ? `/${urlKey}` : '/')
        },
        [country, dispatch, storeViews],
    )

    const showSwitcher = storeViews.some(
        (storeView) =>
            storeView.locale !== store.locale &&
            storeView.locale?.endsWith(`-${country}`),
    )

    return {
        setPickerState: setState,
        pickerState: state,
        country,
        language,
        messages: dict,
        onSwitch,
        showSwitcher,
    }
}
