import { globalWindow } from '@emico-utils/ssr-utils'

type ConsentedObjective =
    | 'personalisation_cookies'
    | 'functional_cookies'
    | 'advertising_cookies'

export function getConsent(): ConsentedObjective[] {
    const profile = globalWindow?.blueConicClient?.profile?.getProfile?.()

    if (!profile) {
        return []
    }

    return profile.getConsentedObjectives() as ConsentedObjective[]
}

export function hasConsent(consentedObjective: ConsentedObjective): boolean {
    return getConsent().includes(consentedObjective)
}
