import { useQuery, gql, TypedDocumentNode } from '@apollo/client'
import { cookie } from '@prismicio/client'
import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { getCacheableContext } from '@emico-utils/graphql-data-utils'
import { globalWindow, localStorage } from '@emico/ssr-utils'

import {
    PrismicResolvedPreviewUrlQuery,
    PrismicResolvedPreviewUrlQueryVariables,
} from './PrismicPreviewResolver.generated'

const query = gql`
    query PrismicResolvedPreviewUrl($documentId: String!) {
        prismicResolvedPreviewUrl(documentId: $documentId)
    }
` as TypedDocumentNode<
    PrismicResolvedPreviewUrlQuery,
    PrismicResolvedPreviewUrlQueryVariables
>

const PrismicPreviewResolver = (): null => {
    const { search } = useLocation()
    const params = new URLSearchParams(search)
    const documentId = params.get('documentId') ?? ''

    const history = useHistory()
    const { data } = useQuery(query, {
        skip: Boolean(!documentId),
        variables: {
            documentId,
        },
        context: getCacheableContext(),
    })

    useEffect(() => {
        if (data?.prismicResolvedPreviewUrl) {
            /*
             ** Set PrismicPreview storage item and hard reload
             ** It will get picked up by PrismicPreviewLoader
             */
            localStorage?.setItem(cookie.preview, 'true')
            if (globalWindow) {
                globalWindow.location.href = data.prismicResolvedPreviewUrl
            }
        }
    }, [data, history])

    return null
}

export default PrismicPreviewResolver
