import cx from 'classnames'
import { useEffect, useRef, useState, RefObject, useLayoutEffect } from 'react'
import { CSSTransition } from 'react-transition-group'

import styles from './toaster.module.css'
import { Toaster } from './types'
import UserDetailsIcon from '../account-dashboard-page/icons/UserDetailsIcon'
import CheckIcon from '../core/CheckIcon'
import CloseIcon from '../core/CloseIcon'

const TIMEOUT = 200

const iconMapping = {
    success: <CheckIcon />,
    warning: <UserDetailsIcon />,
    error: <CloseIcon />,
}

const useTimeout = (timeout: number | undefined, callback: () => void) => {
    const currentCallback = useRef(callback)

    useEffect(() => {
        currentCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (!timeout) {
            return
        }
        const timerId = setTimeout(() => currentCallback.current(), timeout)
        return () => clearTimeout(timerId)
    }, [timeout])
}

export const Toast = ({
    toast,
    close,
    showProgress = false,
    closeOnClick,
}: {
    toast: Toaster
    close: () => void
    showProgress: boolean
    closeOnClick: boolean
}) => {
    const { type: toastType, timeout = 0, message } = toast

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const toastNode = useRef() as RefObject<HTMLDivElement>

    const handleClose = () => {
        setIsOpen(false)
        setTimeout(() => {
            close()
        }, TIMEOUT)
    }

    useEffect(() => {
        if (toastNode.current && showProgress) {
            toastNode.current.style.setProperty(
                '--toast-timeout',
                `${timeout / 1000}s`,
            )
        }
    }, [timeout, showProgress])

    useLayoutEffect(() => {
        if (toast) {
            setIsOpen(true)
        }
    }, [toast])

    useTimeout(timeout, handleClose)

    return (
        <CSSTransition
            in={isOpen}
            classNames={{
                exit: styles.exit,
                exitActive: styles.exitActive,
                exitDone: styles.exitDone,
                enter: styles.enter,
                enterActive: styles.enterActive,
                enterDone: styles.enterDone,
            }}
            timeout={TIMEOUT}
        >
            <div
                ref={toastNode}
                onClick={() => closeOnClick && handleClose()}
                className={cx(styles.toastContainer, styles[toastType])}
            >
                <div className={styles.toastContentWrapper}>
                    <div className={styles.toastContent}>
                        <div className={styles.iconContainer}>
                            <span
                                className={cx(styles.icon, styles[toastType])}
                            >
                                {iconMapping[toastType]}
                            </span>
                        </div>
                        {message}
                    </div>
                </div>
                {showProgress && (
                    <div className={styles.progress}>
                        <span className={styles.bar} />
                    </div>
                )}
            </div>
        </CSSTransition>
    )
}
