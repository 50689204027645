import { ApolloClient, gql } from '@apollo/client'

import { Cart, MutationMergeCartsArgs } from '../graphql/schema.generated'

const mutation = gql`
    mutation mergeCarts($source_cart_id: String!) {
        mergeCarts(source_cart_id: $source_cart_id) {
            id
        }
    }
`

export const mergeCart =
    (apollo: ApolloClient<unknown>) => (cartId: string, token: string) =>
        apollo.mutate<Cart, MutationMergeCartsArgs>({
            mutation,
            variables: {
                source_cart_id: cartId,
                destination_cart_id: null,
            },
            context: {
                headers: {
                    customerToken: token,
                },
            },
        })
