import * as React from 'react'
export function FlagBeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            {...props}
        >
            <path fill="#000" d="M0 5h8v14H0z" />
            <path fill="#FDDA24" d="M8 5h8v14H8z" />
            <path fill="#EF3340" d="M16 5h8v14h-8z" />
        </svg>
    )
}
