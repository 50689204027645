import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import debounce from 'debounce'
import { Form } from 'informed'
import { useEffect, useMemo, useRef, useState } from 'react'

import NumberSelect from '../../input/NumberSelect'
import styles from '../CartItem/CartItem.module.scss'

interface QuantityFormValues {
    quantity: number
}
interface QuantityFormProps {
    quantity: number
    onSubmit(values: QuantityFormValues): void
    disabled?: boolean
    max?: number
}
const CartItemQuantityForm = ({
    disabled,
    quantity,
    max,
    onSubmit,
}: QuantityFormProps) => {
    const [rerenderKey, setRerenderKey] = useState(0)
    const internalQuantity = useRef(quantity)
    const onSubmitDebounced = useMemo(
        () =>
            debounce((values: QuantityFormValues) => {
                internalQuantity.current = Math.max(1, values.quantity ?? 1)
                return onSubmit({
                    quantity: internalQuantity.current,
                })
            }, 500),
        [onSubmit],
    )

    // Rerender when quantity changes from outside this component. This can
    // happen when the same product is in the cart twice and the second
    // instance has its size changed to match that of the first. Magento will
    // merge the cart items.
    // Another way this can occur is if the quantity was out of stock
    useEffect(() => {
        if (internalQuantity.current !== quantity) {
            setRerenderKey((key) => key + 1)
        }
    }, [quantity])

    return (
        <Form<QuantityFormValues>
            key={rerenderKey}
            className={styles.quantityForm}
            onValueChange={onSubmitDebounced}
            initialValues={{
                quantity,
            }}
        >
            <NumberSelect
                field="quantity"
                className={styles.configDropdown}
                disabled={disabled}
                data-testid="cart.item.quantity"
                from={1}
                to={10}
                max={max}
                moreLabel={t({
                    id: 'cart.item.quantityMore',
                    message: `More`,
                })}
            />
        </Form>
    )
}

export default CartItemQuantityForm
