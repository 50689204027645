import { ReactNode } from 'react'

import styles from './MiniCartItemDetails.module.scss'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import Link from '../navigation/Link'
import Text from '../typography/Text'

interface Props {
    product: Pick<Product, 'name' | 'urlKey'>
    description?: ReactNode
    details?: ReactNode
    actions?: ReactNode
    footer?: ReactNode
}

const MiniCartItemDetails = ({
    product,
    description,
    details,
    actions,
    footer,
}: Props) => (
    <div className={styles.container}>
        <div className={styles.main}>
            <div className={styles.productInfo}>
                <Text as="h1" color="dark">
                    <Link
                        name={product.name}
                        category="miniCart.cartItem"
                        to={`/${product.urlKey}`}
                        variant="dark"
                    >
                        {product.name}
                    </Link>
                </Text>
                {description && (
                    <div className={styles.description}>{description}</div>
                )}
                <div className={styles.details}>{details}</div>
            </div>
            {actions && (
                <div className={styles.actionsContainer}>{actions}</div>
            )}
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
    </div>
)

export default MiniCartItemDetails
