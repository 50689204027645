import styled from '@emotion/styled'
import * as React from 'react'

import theme from '../theme'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    variant?: 'default' | 'large'
}

const StyledSeparator = styled.div<{ variant: Props['variant'] }>`
    height: 1px;
    background-color: ${theme.colors.borderLight};

    ${(props) => (props.variant === 'large' ? 'margin: 30px 0;' : '')}
`

const Separator = ({
    variant = 'default',
    className,
    ...otherProps
}: Props) => (
    <StyledSeparator {...otherProps} variant={variant} className={className} />
)

export default Separator
