import { ApolloClient, gql } from '@apollo/client'

import { Mutation } from '../graphql/schema.generated'

const mutation = gql`
    mutation createWishlist {
        createGuestWishlist {
            wishlist {
                id
            }
        }
    }
`

type Result = Pick<Mutation, 'createGuestWishlist'>

export const createGuestWishlist = (apollo: ApolloClient<unknown>) =>
    apollo.mutate<Result>({
        mutation,
    })
