import { gql, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from './common/MinimalConfigurableProductInfo.fragment'
import { TweakwiseNavigateItem } from './common/ProductFilterPage/TweakwiseNavigate.query'
import { useCoreConfigValue } from '../coreConfig.query'
import {
    Maybe,
    QueryTweakwiseRecommendationsFeaturedArgs,
    QueryTweakwiseRecommendationsProductArgs,
    QueryTweakwiseRecommendationsProductGroupArgs,
} from '../graphql/schema.generated'
import productCardFragment from '../ProductCardFragment'
import useTweakwiseItemNo from '../useTweakwiseItemNo'
import { filterNullValues } from '../utils/filterNullValues'

export type TweakwiseRecommendationsType = 'crosssell' | 'upsell' | 'featured'

// https://support.tweakwise.com/en/cat/featured-products/85
// https://support.tweakwise.com/en/cat/up-cross-sell/87
const queryProduct = gql`
    query TweakwiseRecommendationsProduct($itemNo: ID!, $templateId: ID!) {
        tweakwiseRecommendationsProduct(
            itemNo: $itemNo
            templateId: $templateId
        ) {
            product {
                ...ProductCardFragmentJB
                ...ConfigurableProductInfo
            }
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

// https://support.tweakwise.com/en/cat/featured-products/85
// https://support.tweakwise.com/en/cat/uitgelichte-producten/86
const queryFeatured = gql`
    query TweakwiseRecommendationsFeatured($templateId: ID!) {
        tweakwiseRecommendationsFeatured(templateId: $templateId) {
            product {
                ...ProductCardFragmentJB
                ...ConfigurableProductInfo
            }
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

const queryProductGroup = gql`
    query tweakwiseRecommendationsProductGroup($itemNo: ID!, $groupKey: ID!) {
        tweakwiseRecommendationsProductGroup(
            itemNo: $itemNo
            groupKey: $groupKey
        ) {
            items {
                product {
                    ...ProductCardFragmentJB
                    ...ConfigurableProductInfo
                }
            }
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

interface TweakwiseRecommendationsProductData {
    tweakwiseRecommendationsProduct: Maybe<
        Array<{
            product: TweakwiseNavigateItem
        }>
    >
}

interface TweakwiseRecommendationsFeaturedData {
    tweakwiseRecommendationsFeatured: Maybe<
        Array<{
            product: TweakwiseNavigateItem
        }>
    >
}

interface TweakwiseRecommendationsProductGroupData {
    tweakwiseRecommendationsProductGroup: Maybe<
        Array<{
            items: {
                product: TweakwiseNavigateItem
            }
        }>
    >
}

export const useTweakwiseRecommendations = (
    type: TweakwiseRecommendationsType,
    productId?: number,
    templateId?: string,
    skip?: boolean,
): { data: TweakwiseNavigateItem[] | undefined; loading: boolean } => {
    let queryTemplateId: string | undefined
    let groupId: string | undefined

    const { value: upsell_enabled } = useCoreConfigValue(
        'tweakwise/recommendations/upsell_enabled',
    )

    const { value: featured_enabled } = useCoreConfigValue(
        'tweakwise/recommendations/featured_enabled',
    )

    const { value: crosssell_enabled } = useCoreConfigValue(
        'tweakwise/recommendations/crosssell_enabled',
    )

    const { value: upsell_template } = useCoreConfigValue(
        'tweakwise/recommendations/upsell_template',
    )

    const { value: featured_template } = useCoreConfigValue(
        'tweakwise/recommendations/featured_template',
    )

    const { value: crosssell_template } = useCoreConfigValue(
        'tweakwise/recommendations/crosssell_template',
    )

    const { value: upsell_group_code } = useCoreConfigValue(
        'tweakwise/recommendations/upsell_group_code',
    )

    const { value: crosssell_group_code } = useCoreConfigValue(
        'tweakwise/recommendations/crosssell_group_code',
    )

    const itemNo = useTweakwiseItemNo(productId)

    // For featured recommendations, the user supplied template takes
    // precedence above the site based. F.e. prismic tweakwise component can provide this.
    if (type === 'featured' && featured_enabled === '1') {
        queryTemplateId = templateId || featured_template
    }

    // For upsell and crosssell recommendations, check if there is a group defined.
    // If so, use recommendations based on the group. Otherwise use regular featured recommendations.
    if (type === 'upsell' && upsell_enabled === '1') {
        queryTemplateId = upsell_template
        groupId = upsell_group_code
    }
    if (type === 'crosssell' && crosssell_enabled === '1') {
        queryTemplateId = crosssell_template
        groupId = crosssell_group_code
    }

    // Tweakwise recommendations for when there is a group supplied.
    // Group queries are only applicable for upsell or crosssell recommendations
    // and are based on a group id and item number.
    const {
        data: groupData,
        error: groupError,
        loading: groupLoading,
    } = useQuery<
        TweakwiseRecommendationsProductGroupData,
        QueryTweakwiseRecommendationsProductGroupArgs
    >(queryProductGroup, {
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            itemNo: itemNo!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            groupKey: groupId!,
            params: null,
        },
        skip: skip || !groupId || !itemNo,
        context: getCacheableContext(),
        errorPolicy: 'all',
    })

    const variables:
        | QueryTweakwiseRecommendationsProductArgs
        | QueryTweakwiseRecommendationsFeaturedArgs = {
        // Query will be skipped when queryTemplateId is not set but type requires it.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        templateId: queryTemplateId!,
        params: null,
    }

    let query = queryFeatured

    if (itemNo) {
        query = queryProduct
        ;(variables as QueryTweakwiseRecommendationsProductArgs).itemNo = itemNo
    }

    // Tweakwise recommendations for when there is no group supplied.
    const { data, error, loading } = useQuery<
        | TweakwiseRecommendationsProductData
        | TweakwiseRecommendationsFeaturedData,
        | QueryTweakwiseRecommendationsProductArgs
        | QueryTweakwiseRecommendationsFeaturedArgs
    >(query, {
        variables,
        context: getCacheableContext(),
        errorPolicy: 'all',
        skip: skip || !queryTemplateId || groupId !== undefined,
    })

    if (error || groupError) {
        // Ignore errors for this endpoint since it's commonly misconfigured which
        // would make the app unusable during development and break e2e tests. They
        // can practically only be configuration or backend errors anyway.
        // Also, disabled products or missing products can result in errors.
    }

    // If one of the queries is still loading, return loading state
    if (loading || groupLoading) {
        return {
            data: undefined,
            loading: true,
        }
    }

    // Group data takes preference above normal data,
    // so return that if found
    if (groupData) {
        return {
            data: filterNullValues(
                groupData.tweakwiseRecommendationsProductGroup,
            )
                ?.flatMap((item) => item.items)
                .map(({ product }) => product)
                .filter((product) => product),
            loading: groupLoading,
        }
    }

    const products = filterNullValues(
        filterNullValues(
            (data as TweakwiseRecommendationsProductData)
                ?.tweakwiseRecommendationsProduct ||
                (data as TweakwiseRecommendationsFeaturedData)
                    ?.tweakwiseRecommendationsFeatured,
        )?.map((item) => item.product),
    )

    return {
        data: products,
        loading,
    }
}
