import ButtonVariant from './types/ButtonVariant'

export default (variant?: string) => {
    let buttonVariant

    switch (variant) {
        case 'dark':
            buttonVariant = ButtonVariant.dark
            break
        case 'roze':
            buttonVariant = ButtonVariant.primaryAlt
            break
        case 'ghost':
            buttonVariant = ButtonVariant.white
            break
        case 'highlight':
            buttonVariant = ButtonVariant.highlight
            break
        default:
            buttonVariant = ButtonVariant.primary
    }
    return buttonVariant
}
