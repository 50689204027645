import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import NextIcon from './core/NextIcon'
import { LayoutStyle } from './LayoutStyle'
import Icon from './media/Icon'
import Link from './navigation/Link'
import theme from './theme'

export interface FaqMenuItem {
    url: string
    title: string
    icon?: string
}

interface Props {
    items: FaqMenuItem[]
    style: LayoutStyle
    activeItem?: number
    className?: string
    onItemClick?(menuItem: FaqMenuItem, index: number): void
}

const customIcon = css`
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 15px;
`

const MenuNav = styled.nav`
    position: sticky;
`

const MenuLink = styled(Link)`
    display: flex;
    align-items: center;
    padding: 20px 15px;
    line-height: 1;
    border-bottom: 1px solid ${theme.colors.borderLight};

    &,
    &:visited,
    &:link {
        text-decoration: none;
    }

    &:hover,
    &:active {
        text-decoration: none;
    }
`

const activeMenuLink = css`
    font-weight: bold;
`

const MenuUl = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const MenuLi = styled.li`
    list-style: none;
    padding: 0;
    margin: 0;
`

const MenuIcon = styled(Icon)`
    width: 15px;
    height: 15px;
`

const FaqMenu = ({
    items,
    style = 'light',
    className,
    onItemClick,
    activeItem,
}: Props) => {
    const menuItems = items.map(({ url, title, icon }, index) => (
        <MenuLi key={index}>
            <ClassNames>
                {({ css }) => (
                    <MenuLink
                        to={url}
                        name={title}
                        category="faq"
                        css={css(`
                            ${
                                index === 0
                                    ? `border-top: 1px solid ${theme.colors.borderLight};`
                                    : ''
                            }
                            color: ${theme.colors.text} !important;
                            ${activeItem === index ? 'font-weight: bold;' : ''}
                        `)}
                        state={{ scrollRestoration: false }}
                        activeClassName={css(activeMenuLink)}
                        onClick={() => {
                            if (onItemClick) {
                                onItemClick({ url, title, icon }, index)
                            }
                        }}
                    >
                        {icon && (
                            <img
                                // eslint-disable-next-line react/no-unknown-property
                                css={css`
                                    background-size: 12px;
                                    ${customIcon}

                                    padding: 5px;
                                    background-color: ${style === 'dark'
                                        ? theme.colors.white
                                        : theme.colors.text};
                                    & path {
                                        fill: ${style === 'dark'
                                            ? theme.colors.black
                                            : theme.colors.white};
                                    }
                                `}
                                src={icon}
                                width="20px"
                                height="20px"
                                alt=""
                            />
                        )}

                        {title}

                        <MenuIcon
                            component={NextIcon}
                            title="Arrow"
                            wrapperClassName={css`
                                color: ${style === 'dark'
                                    ? theme.colors.white
                                    : theme.colors.black};
                                margin-left: auto;
                            `}
                        />
                    </MenuLink>
                )}
            </ClassNames>
        </MenuLi>
    ))

    return (
        <MenuNav className={className}>
            <MenuUl>{menuItems}</MenuUl>
        </MenuNav>
    )
}

export default FaqMenu
