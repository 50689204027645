import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import SearchFieldTrigger from './SearchFieldTrigger'
import CloseIcon from '../core/CloseIcon'
import GeoLocationIcon from '../core/GeoLocationIcon'
import SearchIcon from '../core/SearchIcon'
import Button from '../input/Button'
import Icon from '../media/Icon'

interface Props {
    isVisible: boolean
    submitted: boolean
    searchValue: string
    handleReset(): void
    setVisible(value: boolean): void
    setSearchValue(value: string): void
    submitFirstSuggestion(): void
    getCurrentLocation(): void
    setsubmitted(value: boolean): void
}

const DynamicInput = ({
    isVisible,
    searchValue,
    submitted,
    handleReset,
    setVisible,
    setSearchValue,
    submitFirstSuggestion,
    getCurrentLocation,
    setsubmitted,
}: Props) => {
    const { isMobile } = useBreakpoints()
    const InputContainer = styled.div`
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid ${isVisible ? `#000` : '#fff'};
        padding: 10px 10px 10px 15px;
        margin: ${isMobile && isVisible ? `10px 0` : '0'};
    `

    const ActiveInputRow = styled.div`
        display: flex;
        flex: auto;
        flex-wrap: nowrap;
        align-items: center;
    `

    const InputField = styled.input`
        font-size: 16px;
        border: none;
        outline: none;
        background-color: transparent;
        flex: auto;
        height: 40px;
        &:focus {
            outline: none !important;
        }
    `

    const SearchActions = styled.div`
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        flex: 0;
    `

    const Divider = styled.div`
        height: 24px;
        border-right: 1px solid #f2f2f2;
        margin: 0 4px;
    `

    return (
        <InputContainer>
            {isVisible ? (
                <ActiveInputRow>
                    <Button
                        type="button"
                        name=""
                        category=""
                        variant="link"
                        onClick={() => {
                            handleReset()
                            setsubmitted(false)
                        }}
                        css={css`
                            margin-left: -5px;
                        `}
                    >
                        <Icon
                            component={CloseIcon}
                            title=""
                            css={css`
                                transform: rotate(90deg);
                                padding: 0 5px;
                            `}
                        />
                    </Button>
                    <InputField
                        autoFocus={!submitted}
                        type="text"
                        placeholder={t({
                            id: 'storelocator.searchFieldInput.placeholder',
                            message: `Enter city, zipcode or address`,
                        })}
                        value={searchValue}
                        onChange={(event) => {
                            setSearchValue(event.target.value)
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                submitFirstSuggestion()
                            }
                        }}
                        onClick={() => setsubmitted(false)}
                    />
                </ActiveInputRow>
            ) : (
                <SearchFieldTrigger trigger={setVisible} />
            )}

            <SearchActions>
                {!isMobile && (
                    <>
                        <Button
                            type="button"
                            name="Search"
                            category="storelocator.searchBar.search"
                            variant="link"
                            css={css`
                                margin: 0;
                                padding: 0 6px;
                            `}
                            onClick={() => {
                                if (!isVisible) {
                                    setVisible(true)
                                    setsubmitted(false)
                                }
                                submitFirstSuggestion()
                            }}
                        >
                            <Icon
                                component={SearchIcon}
                                title={t({
                                    id: 'storelocator.searchBar.search',
                                    message: `Search`,
                                })}
                                css={css`
                                    height: 20px;
                                    color: ${isVisible && searchValue
                                        ? `#000`
                                        : 'gray'};
                                `}
                            />
                        </Button>
                        <Divider />
                    </>
                )}
                <Button
                    type="button"
                    name="Current location"
                    category="storelocator.searchBar.currentLocation"
                    variant="link"
                    css={css`
                        margin: 0;
                        padding: 0 4px;
                    `}
                    onClick={() => getCurrentLocation()}
                >
                    <Icon
                        component={GeoLocationIcon}
                        title="Use curront location"
                        css={css`
                            height: 24px;
                        `}
                    />
                </Button>
            </SearchActions>
        </InputContainer>
    )
}

export default DynamicInput
