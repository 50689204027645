import { ComponentType, Suspense, lazy, useMemo } from 'react'

import { PageBodyFormPrimary } from './graphql/schema.generated'
import PageLoader from './presentation/PageLoader'
import { PrismicPageBodyFormPrimary } from './PrismicSliceForm'

const lazyGiftcardBalanceForm = () =>
    lazy<ComponentType<Omit<PageBodyFormPrimary, 'formId' | 'formFooter'>>>(
        () => import('./GiftCardBalanceCheckForm'),
    )

const mapFormIdToImport = (formId: string) => {
    const form = formId.toLowerCase().replaceAll(/\s/g, '')

    switch (form) {
        case 'giftcardbalanceform': {
            return lazyGiftcardBalanceForm()
        }

        default:
            return undefined
    }
}

export const PrismicLazyForm = ({
    formId,
    formHeader,
    formSubheader,
    buttonSubmitLabel,
}: PrismicPageBodyFormPrimary) => {
    const LazyForm = useMemo(() => mapFormIdToImport(formId ?? ''), [formId])

    if (!formId) {
        return null
    }

    return (
        <Suspense fallback={<PageLoader fullScreen />}>
            {LazyForm && (
                <LazyForm
                    formHeader={formHeader ?? ''}
                    formSubheader={formSubheader ?? ''}
                    buttonSubmitLabel={buttonSubmitLabel ?? ''}
                />
            )}
        </Suspense>
    )
}
