import { ImgProxyParams, Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import Container from './layout/Container'
import { Video } from './media/Video'
import styles from './PrismicHero.module.scss'
import breakpoints from './theme/breakpoints'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'

export const imageSizes = {
    regularWidth: 2000,
    regularHeight: undefined,
    mediumWidth: 991,
    mediumHeight: undefined,
    smallWidth: 767,
    smallHeight: undefined,
}

export interface Props {
    heading?: string
    subHeading?: string
    video?: string
    mobileVideo?: string
    image?: string
    imageMobile?: string
    className?: string
    contentClassName?: string
    headingVariant?: HeadingVariant
    subheadingVariant?: HeadingVariant
    headingElement?: HeadingElement
    subheadingElement?: HeadingElement
    headingFuss?: React.ComponentProps<typeof Heading>['fuss']
    size?: 'large' | 'small'
    fullWidth?: boolean
    imageProxyParams?: ImgProxyParams
}

const StyledContainer = styled(Container)`
    position: relative;
    display: flex;
    height: 100%;
`

export const PrismicHero: React.FC<Props> = ({
    heading,
    subHeading,
    video,
    mobileVideo,
    image,
    imageMobile,
    className,
    contentClassName,
    headingVariant = HeadingVariant.sale,
    subheadingVariant = HeadingVariant.h3,
    headingElement = HeadingElement.h1,
    subheadingElement = HeadingElement.h2,
    headingFuss = ['castironLight', 'pme', 'vanguard'],
    size = 'large',
    fullWidth = true,
    imageProxyParams,
}) => {
    const { isDesktop } = useBreakpoints()

    const videoUrl = isDesktop ? video : mobileVideo
    const imageUrl = isDesktop ? image : imageMobile

    if (isDesktop && !video && !image) {
        return null
    }
    if (!isDesktop && !mobileVideo && !imageMobile) {
        return null
    }

    // Safeguard for headings being too big
    if (size === 'small') {
        if (headingVariant === HeadingVariant.sale) {
            headingVariant = HeadingVariant.h1
        }
        if (subheadingVariant === HeadingVariant.h1) {
            subheadingVariant = HeadingVariant.h2
        }
    }

    // Backwards compatibility with older content where subheading element was not selectable but based on headingElement
    const subHeadingElement =
        subheadingElement || headingElement === 'h1' ? 'h2' : 'h3'

    const content = (
        <div className={styles.heroWrapper}>
            <div
                className={cx(
                    styles.hero,
                    {
                        [styles.heroSmall]: size === 'small',
                    },
                    className,
                )}
            >
                {imageUrl && !videoUrl && (
                    <Picture
                        breakpoints={{
                            [breakpoints.xs]: {
                                url: imageUrl,
                                width: imageSizes.smallWidth,
                                height: imageSizes.smallHeight,
                            },
                            [breakpoints.md]: {
                                url: imageUrl,
                                width: imageSizes.mediumWidth,
                                height: imageSizes.mediumHeight,
                            },
                            [breakpoints.lg]: {
                                url: imageUrl,
                                width: imageSizes.regularWidth,
                                height: imageSizes.regularHeight,
                            },
                        }}
                        params={imageProxyParams}
                        lazy={false}
                        alt={heading ? heading : ''}
                        className={styles.image}
                    />
                )}

                {videoUrl && (
                    <div className={styles.video}>
                        <div className={styles.videoContainer}>
                            <Video
                                url={videoUrl}
                                responsive={false}
                                background
                                loop
                                autoplay
                                muted
                            />
                        </div>
                    </div>
                )}
                <StyledContainer>
                    <div className={cx(styles.content, contentClassName)}>
                        {heading && (
                            <Heading
                                variant={headingVariant}
                                element={headingElement}
                                color="light"
                                className={styles.heroHeading}
                                fuss={headingFuss}
                            >
                                {heading}
                            </Heading>
                        )}

                        {subHeading && (
                            <Heading
                                variant={subheadingVariant}
                                element={subHeadingElement}
                                color="light"
                                className={styles.heroSubHeading}
                            >
                                {subHeading}
                            </Heading>
                        )}
                    </div>
                </StyledContainer>
            </div>
        </div>
    )

    return fullWidth ? content : <Container>{content}</Container>
}

export default PrismicHero
