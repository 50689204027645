import { UrlRewriteEntityTypeEnum } from '@emico/graphql-schema-types'
import { UnreachableCaseError } from '@emico/utils'

import GetProductMetaQuery, {
    GetProductMetaQueryVariables,
    GetProductMetaResult,
    ProductMeta,
} from './GetProductMeta.query'
import { ThunkResult } from '../../types'
import { resolveUrl } from '../../utils/resolveUrl'

export const fetchProductMeta =
    (
        storeview: string,
        id: number,
    ): ThunkResult<Promise<ProductMeta | undefined>> =>
    async (
        dispatch,
        getState,
        { apolloClient },
    ): Promise<ProductMeta | undefined> => {
        const result = await apolloClient.query<
            GetProductMetaResult,
            GetProductMetaQueryVariables
        >({
            query: GetProductMetaQuery,
            variables: {
                id,
            },
            fetchPolicy: 'no-cache',
            // The Store header sets the storeview for which the query is run.
            // The storeview determines the locale of all content fetched.
            // By default, this is set for all queries upon initialisation of the apolloClient
            // But because meta data has to be fetched for other locales than the default,
            // we need to set it here, so we can fetch content in differnt locale than the default
            context: {
                headers: {
                    Store: storeview,
                },
            },
        })

        return result.data.product || undefined
    }

export const fetchTranslatedUrl =
    (
        storeview: string,
        url: string,
    ): ThunkResult<Promise<string | undefined>> =>
    async (
        dispatch,
        getState,
        { apolloClient },
    ): Promise<string | undefined> => {
        const result = await resolveUrl(apolloClient)(url)

        const urlData = result?.data?.urlResolver

        if (!urlData || !urlData.type) {
            // No data found for current url
            return
        }

        switch (urlData.type) {
            case UrlRewriteEntityTypeEnum.PRODUCT: {
                const productMeta = await dispatch(
                    fetchProductMeta(storeview, urlData.id ?? 0),
                )

                return productMeta ? productMeta.urlKey : undefined
            }
            case UrlRewriteEntityTypeEnum.CATEGORY:
                // For some categories (German PME) this query wont return correct results because the category id is different per storeview.
                // So to enforce consistent behaviour, we just return here so it redirects to homepage
                return
            case UrlRewriteEntityTypeEnum.CMS_PAGE:
                // Because CMS pages have an ID that is storeview specific, we can't get their translated equivalent.
                // Pages defined in code already have the correct url automatically, because they are linked to using react-router, and we set a basename
                return
            case UrlRewriteEntityTypeEnum.PRISMIC:
                // TODO: get correct prismic url
                return
            case UrlRewriteEntityTypeEnum.REDIRECT:
                // TODO: handle redirects
                return
            default:
                throw new UnreachableCaseError(urlData.type)
        }
    }
