import styled from '@emotion/styled'
import * as React from 'react'

import { SupplyStatus } from './SearchResults'
import { SrsStore } from './useSrsStore'
import Heading from '../typography/Heading'
import Text from '../typography/Text'

const StoreHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 20px;
`

const TextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

interface Props {
    store: SrsStore | undefined
}
const StoreInformation = ({ store }: Props) => {
    const postal = store?.postal
    const city = store?.city
    const street = store?.street
    const number = store?.number
    const phone = store?.gPhone

    return (
        <>
            <StoreHeading>
                <Heading variant="h3" element="h1">
                    {store?.name}
                </Heading>
                {store?.distance != null && (
                    <Text>{store?.distance.toFixed(1)} km</Text>
                )}
            </StoreHeading>
            <TextWrapper>
                <div>
                    <Text>
                        {street} {number}
                    </Text>
                    <Text>
                        {postal}, {city}
                    </Text>
                    {phone && (
                        <Text>
                            <a href={`tel:${phone}`}>
                                <Text color="dark" as="span">
                                    {phone}
                                </Text>
                            </a>
                        </Text>
                    )}
                </div>
                {store?.skuStock !== undefined && (
                    <SupplyStatus stock={store?.skuStock || 0} />
                )}
            </TextWrapper>
        </>
    )
}

export default StoreInformation
