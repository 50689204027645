import defaultsToVars, { defaultsToCss, getVarKeys } from './cssVarsUtils'

const fonts = {
    primary: "'Source Sans Pro', Helvetica, Arial, sans-serif",
    secondary: "'Source Sans Pro', Helvetica, Arial, sans-serif",
    mono: "SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
    footer: 'inherit',
}

const fontWeights = {
    light: 300,
    base: 400,
    bold: 700,
}

const fontSizes = {
    xs: '0.8rem',
    sm: '0.9rem',
    base: '1rem',
    md: '1.2rem',
    lg: '1.6rem',
    xl: '2rem',
}

const headingSizes = {
    h0: '60px',
    h1: '44px',
    h2: '35px',
    h3: '24px',
    h4: '1rem',
    footer: 'inherit',
}

export const headingSizesMd = defaultsToCss<typeof headingSizes>(
    {
        h0: '100px',
        h1: '61px',
        h2: '44px',
        h3: '28px',
        h4: '1rem',
        footer: 'inherit',
    },
    'heading-size',
)

const lineHeights = {
    base: '1.4',
}

const headingDecorationVisibility = {
    before: 'inline-block',
    after: 'inline-block',
}

export const varKeys = {
    fonts: getVarKeys(fonts, 'font'),
    fontWeights: getVarKeys(fontWeights, 'font-weight'),
    fontSizes: getVarKeys(fontSizes, 'font-size'),
    headingSizes: getVarKeys(headingSizes, 'heading-size'),
    lineHeights: getVarKeys(lineHeights, 'line-height'),
    headingDecorationVisibility: getVarKeys(
        headingDecorationVisibility,
        'heading-decoration-visibility',
    ),
}

export const typographyCss = [
    ...defaultsToCss(fonts, 'font'),
    ...defaultsToCss(fontWeights, 'font-weight'),
    ...defaultsToCss(fontSizes, 'font-size'),
    ...defaultsToCss(headingSizes, 'heading-size'),
    ...defaultsToCss(lineHeights, 'line-height'),
    ...defaultsToCss(
        headingDecorationVisibility,
        'heading-decoration-visibility',
    ),
]

export default {
    fonts: defaultsToVars(fonts, 'font'),
    fontWeights: defaultsToVars(fontWeights, 'font-weight'),
    fontSizes: defaultsToVars(fontSizes, 'font-size'),
    headingSizes: defaultsToVars(headingSizes, 'heading-size'),
    lineHeights: defaultsToVars(lineHeights, 'line-height'),
    headingDecorationVisibility: defaultsToVars(
        headingDecorationVisibility,
        'heading-decoration-visibility',
    ),
}
