import { usePushEcommerceEvent } from './usePushEvent'

export function useInternalLinkClickEvent() {
    const push = usePushEcommerceEvent([], false)

    function pushInternalLinkClickEvent(
        linkUrl?: string,
        linkPosition?: string,
        linkPositionSlot?: string,
        linkText?: string,
    ) {
        // Asuming that a user does not click faster than the required data is loaded
        push?.([
            { ecommerce: null },
            {
                event: 'internal_link_click',

                link_url: linkUrl,
                link_position: linkPosition,
                link_position_slot: linkPositionSlot,
                linkText: linkText,
            },
        ])
    }

    return pushInternalLinkClickEvent
}
