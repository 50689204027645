import { ImaginaryActions } from '@emico/ui'

import styles from './Looks.module.scss'
import Carousel from '../../../behaviour/Carousel'
import Button from '../../../input/Button'
import Col from '../../../layout/Col'
import Row from '../../../layout/Row'
import ImageLazy from '../../../media/ImageLazy'
import Link from '../../../navigation/Link'
import {
    CmsPageBlockDiscoverLooks,
    CmsPageListItem,
} from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'
import Text from '../../../typography/Text'
import { useFilterNullValues } from '../../../utils/filterNullValues'

interface Props extends CmsPageBlockDiscoverLooks {
    headingClassName?: string
    contentClassName?: string
    contentInnerClassName?: string
}

const renderLooks = (list: CmsPageListItem[]) => {
    const looks = []

    // eslint-disable-next-line no-restricted-syntax
    for (const i in list) {
        // eslint-disable-next-line no-prototype-builtins
        if (!list.hasOwnProperty(i)) {
            continue
        }
        const look = list[i]
        const { link, image, imageMobile, title } = look
        const imageUrl = image?.url

        if (link && imageUrl) {
            looks.push(
                <div key={i} className={styles.slide}>
                    <Link
                        name={title ?? ''}
                        category="homepage.looks"
                        to={link}
                    >
                        <ImageLazy
                            images={{
                                image: imageUrl,
                                image_mobile: imageMobile?.url ?? imageUrl,
                                regularWidth: 778,
                                regularHeight: 778,
                                mediumWidth: 416,
                                mediumHeight: 675,
                                smallWidth: 335,
                                smallHeight: 543,
                                action: ImaginaryActions.CROP,
                            }}
                            alt={title ?? ''}
                        />
                    </Link>
                </div>,
            )
        }
    }

    return looks
}

const Looks = ({
    header,
    content,
    buttonUrl,
    buttonText,
    list,
    headingClassName,
    contentClassName,
    contentInnerClassName,
}: Props) => {
    const listItems = useFilterNullValues(list)

    // region GTM

    if (!listItems || listItems.length === 0) {
        return null
    }

    return (
        <Row className={styles.default} noGutters>
            <Col lg={3}>
                <div className={contentClassName}>
                    {header && (
                        <Heading
                            variant="h2"
                            element="h2"
                            color="dark"
                            className={headingClassName}
                        >
                            {header}
                        </Heading>
                    )}

                    {content && (
                        <div className={contentInnerClassName}>
                            <Text>{content}</Text>
                        </div>
                    )}

                    {buttonUrl && buttonText && (
                        <Button
                            minWidth
                            to={buttonUrl}
                            variant="primary"
                            name={buttonText}
                            category="cms.homePage.looks"
                        >
                            {buttonText}
                        </Button>
                    )}
                </div>
            </Col>

            <Col lg={9} className={styles.carousel}>
                <Carousel
                    settings={{
                        arrows: true,
                        infinite: false,
                        slidesToShow: 1.1,
                        slidesToShowSm: 1.1,
                        slidesToShowMd: 1.3,
                        slidesToShowLg: 1.3,
                        speed: 300,
                        cssEase: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                    }}
                >
                    {renderLooks(listItems)}
                </Carousel>
            </Col>
        </Row>
    )
}

export default Looks
