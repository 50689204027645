// Order matters here
export enum Breakpoints {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
}

export type Breakpoint = keyof typeof Breakpoints

export type BreakpointsConfig<T> = { [key in Breakpoint]: T }
