import * as React from 'react'
import Helmet from 'react-helmet'

import { useCoreConfigValue } from '../coreConfig.query'
import push from '../utils/googleTagManager/push'

const GoogleTagManagerScript = () => {
    const { value: gtmKey, loading } = useCoreConfigValue(
        'general/google_tag_manager/key',
    )

    React.useEffect(() => {
        push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        })
    }, [])

    if (loading || !gtmKey || window?.isPercolate) {
        return null
    }

    // Allow for multiple GTM containers to be defined
    // See: https://developers.google.com/tag-manager/devguide#multiple-containers
    const gtmKeys = gtmKey.split(',').map((key) => key.trim())

    return (
        <>
            <Helmet>
                {gtmKeys.map((key) => (
                    <script
                        key={key}
                        async
                        src={`https://www.googletagmanager.com/gtm.js?id=${key}`}
                    />
                ))}
            </Helmet>

            {gtmKeys.map((key) => (
                <noscript key={key}>
                    <iframe
                        title="Google Tag Manager"
                        src={`https://www.googletagmanager.com/ns.html?id=${key}`}
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                    />
                </noscript>
            ))}
        </>
    )
}

export default GoogleTagManagerScript
