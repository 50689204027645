import cx from 'classnames'
import * as React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import ContactUsModal from './ContactUsModal'
import styles from './ContactUsModalTransition.module.scss'
import Portal from '../../Portal'

interface Props {
    open: boolean
    close(): void
    proactiveChat?: boolean
}

const ContactUsModalTransition = ({ open, close, proactiveChat }: Props) => (
    <TransitionGroup>
        {open && (
            <Transition timeout={400}>
                {(state) => (
                    <Portal>
                        <ContactUsModal
                            close={close}
                            className={cx(styles.contactUsModal, styles[state])}
                            proactiveChat={proactiveChat}
                        />
                    </Portal>
                )}
            </Transition>
        )}
    </TransitionGroup>
)

export default ContactUsModalTransition
