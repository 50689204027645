import * as React from 'react'
import {
    Breakpoint,
    BreakpointsConfig,
    withBreakpoints,
    WithBreakpointsProps,
} from 'react-breakpoints'

enum Modifiers {
    up = 'up',
    down = 'down',
    only = 'only',
}
export type Modifier = keyof typeof Modifiers
type ModifierProps = { [key in Modifier]?: boolean }

enum Breakpoints {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
}
type BreakpointsProps = { [key in Breakpoints]?: boolean }

type SharedProps = WithBreakpointsProps & ModifierProps & BreakpointsProps
interface WithRenderProps {
    children(props: WithBreakpointsProps): React.ReactElement
}
type WithoutRenderProps = AtLeastOne<BreakpointsConfig<boolean>>

type Props = SharedProps &
    (WithRenderProps | WithoutRenderProps) & { className?: string }

/**
 * Determines if a component should be rendered
 */
const shouldRender = (
    breakpoints: BreakpointsConfig<number>,
    currentBreakpoint: Breakpoint,
    componentBreakpoints: Breakpoint[],
    modifier: Modifier,
): boolean => {
    if (componentBreakpoints.length === 0) {
        throw new Error(
            `Invalid component breakpoint. Must be one of "${Object.keys(
                breakpoints,
            ).join('", "')}"`,
        )
    }

    if (componentBreakpoints.length > 1) {
        /**
         * If multiple breakpoints are defined, only show component for those breakpoints.
         * Modifiers don't matter here because we return only for breakpoints matched
         * This can be used as "between" functionality,
         * but also allows us to render components for "small" and "extra large" only.
         */
        if (componentBreakpoints.includes(currentBreakpoint)) {
            return true
        }
    } else {
        /**
         * A single breakpoint is defined.
         * Use modifiers to determine if the component should be rendered
         */
        const componentBreakpoint: Breakpoint = componentBreakpoints[0]
        const currentWidth = breakpoints[currentBreakpoint]
        const componentWidth = breakpoints[componentBreakpoint]

        // An invalid component breakpoint is defined.
        // Component breakpoint doesn't exist in breakpoints configuration
        if (!componentWidth) {
            throw new Error(
                `Unknown breakpoint "${componentBreakpoint}". Must be one of "${Object.keys(
                    breakpoints,
                ).join('", "')}"`,
            )
        }

        switch (modifier) {
            case Modifiers.only:
                if (currentBreakpoint === componentBreakpoint) {
                    return true
                }
                break
            case Modifiers.up:
                if (currentWidth >= componentWidth) {
                    return true
                }
                break
            case Modifiers.down:
                if (currentWidth <= componentWidth) {
                    return true
                }
                break
        }
    }
    return false
}

/**
 * Get component breakpoints and modifier from props
 */
const extractBreakpointsAndModifierFromProps = (props: Partial<Props>) => {
    const breakpoints: Breakpoint[] = []
    let modifier: Modifier | undefined

    Object.keys(props).forEach((prop) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (Object.values(Modifiers).includes(prop)) {
            modifier = prop as Modifier
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        } else if (Object.values(Breakpoints).includes(prop)) {
            breakpoints.push(prop as Breakpoint)
        }
    })

    if (!modifier) {
        modifier = Modifiers.only
    }

    return {
        breakpoints,
        modifier,
    }
}

// When using React.FC, we can't return children or render directly.
// See: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
// So we use a class here because when the return value is wrapped with a fragment
// It can cause issues with React.cloneElement a.o.
class Responsive extends React.Component<Props> {
    render() {
        // Because Props is a conditional type, we can't get the render variable from props here.
        // This is because WithoutRenderProps does not have "render" defined.
        // We would simply get a 'Property 'render' does not exist on type' error.
        // But...
        const { children, breakpoints, currentBreakpoint, className, ...rest } =
            this.props

        // we CAN use a typeguard to check if render exists in the props that are currently passed.
        // See https://basarat.gitbooks.io/typescript/docs/types/typeGuard.html#in

        if (typeof children === 'function') {
            return children({ breakpoints, currentBreakpoint })
        }

        const { breakpoints: componentBreakpoints, modifier } =
            extractBreakpointsAndModifierFromProps(rest)

        return shouldRender(
            breakpoints,
            currentBreakpoint,
            componentBreakpoints,
            modifier,
        ) ? (
            className ? (
                <div className={className}>{children}</div>
            ) : (
                children
            )
        ) : null
    }
}

export { Responsive }

export default withBreakpoints<Props>(Responsive)
