import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import styles from './FadeTransition.module.scss'

const CSS_TRANSITION_DURATION = 400

export enum TransitionStatus {
    OPENING = 'opening',
    OPEN = 'open',
    CLOSING = 'closing',
    CLOSED = 'closed',
}

interface OwnProps {
    isOpen?: boolean
    children: React.ReactNode
    appear?: boolean
}
type Props = React.HTMLAttributes<HTMLDivElement> & OwnProps

const FadeTransition = ({
    children,
    appear = true,
    isOpen,
    ...others
}: Props) => (
    <TransitionGroup {...others}>
        {React.Children.map(children, (child) => {
            if (!child) {
                return null
            }

            return (
                <CSSTransition
                    timeout={CSS_TRANSITION_DURATION}
                    in={isOpen}
                    appear={appear}
                    classNames={{
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                        appear: styles.appear,
                        appearActive: styles.appearActive,
                    }}
                >
                    {child}
                </CSSTransition>
            )
        })}
    </TransitionGroup>
)

export default FadeTransition
