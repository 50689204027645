import { ApolloClient, gql, useQuery } from '@apollo/client'
import {
    getCustomerTokenHeaderObject,
    useAuthorizationContext,
} from '@emico-hooks/login-token'

import { wishlistIdVar } from './useWishlistId'
import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from '../catalog/common/MinimalConfigurableProductInfo.fragment'
import { BasicConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../catalog/ProductPage/GetBasicProduct.query'
import {
    ConfigurableWishlistItem as WishlistItemInterfaceType,
    Maybe,
    QueryWishlistV2Args,
    Wishlist as WishlistType,
    WishlistItems as WishlistItemsType,
} from '../graphql/schema.generated'
import productCardFragment from '../ProductCardFragment'

export const wishlistFragment = gql`
    fragment WishlistFields on Wishlist {
        id
        itemsCount
        itemsV2(currentPage: 1, pageSize: 100) {
            items {
                id
                product {
                    ...ProductCardFragmentJB
                    ...ConfigurableProductInfo
                }
                ... on ConfigurableWishlistItem {
                    configurableOptions {
                        id
                        configurableProductOptionUid
                        configurableProductOptionValueUid
                        optionLabel
                        valueId
                        valueLabel
                    }
                }
            }
        }
    }
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
    ${productCardFragment}
`

const query = gql`
    query getWishlist($id: ID!) {
        wishlistV2(id: $id) {
            ...WishlistFields
        }
    }
    ${wishlistFragment}
`

type WishlistItemInterface = Overwrite<
    Pick<WishlistItemInterfaceType, 'id' | 'product' | 'configurableOptions'>,
    {
        product: Maybe<BasicProduct | BasicConfigurableProduct>
    }
>

type WishlistItems = Overwrite<
    Pick<WishlistItemsType, 'items'>,
    {
        items: Array<Maybe<WishlistItemInterface>>
    }
>

type Wishlist = Overwrite<
    Pick<WishlistType, 'id' | 'itemsCount' | 'itemsV2'>,
    {
        itemsV2: Maybe<WishlistItems>
    }
>

interface WishlistData {
    wishlistV2: Maybe<Wishlist>
}

export const getWishlist =
    (client: ApolloClient<unknown>) =>
    async (id: string, customerToken: string | false) =>
        client.query<WishlistData>({
            query,
            variables: {
                id,
            },
            context: customerToken
                ? getCustomerTokenHeaderObject(customerToken)
                : {},
            fetchPolicy: 'network-only',
        })

export const useGetWishlist = () => {
    const wishlistId = wishlistIdVar()
    const authorizationContext = useAuthorizationContext()

    const { data, ...others } = useQuery<
        WishlistData,
        Omit<QueryWishlistV2Args, 'store'>
    >(query, {
        variables: {
            id: wishlistId ?? '',
        },
        skip: !wishlistId,
        context: authorizationContext,
        fetchPolicy: 'cache-and-network',
    })

    return {
        ...others,
        data: data?.wishlistV2 || undefined,
    }
}
