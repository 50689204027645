// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { request } from './base'

const UNAUTHORIZED_HTTP_CODE = 401

export type RequestType = (
    resourceUrl: string,
    options?: {
        method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
        body?: string | FormData
        authorizationToken?: string
        ignoreAuthFailure?: true
        parseJSON?: false

        // I don't know if this is actually used server-side
        cache?: string
    },
) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any>

/**
 * Create a request method that calls the provided callback when the API returns an Unauthorized http code.
 * Base on https://read.reduxbook.com/markdown/part2/13-authenticated-requests.html
 */
export default (storeView: string, onAuthFailure: () => void): RequestType =>
    async (resourceUrl, options): Promise<unknown> => {
        try {
            resourceUrl = resourceUrl.replace('rest/V1', `rest/${storeView}/V1`)
            return await request(resourceUrl, options)
        } catch (err) {
            if (
                (!options || !options.ignoreAuthFailure) &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (err as any).response &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (err as any).response.status === UNAUTHORIZED_HTTP_CODE
            ) {
                onAuthFailure()
                // Send home to avoid an inconsistent state
                // window.location.href = '/'
                // Throw anyway to stop the flow from continuing
            }
            throw err
        }
    }
