import { useEffect } from 'react'
import { useStore } from 'react-redux'

import registerPercolatePreparePage from './registerPercolatePreparePage'

const AppPrerendering = () => {
    const store = useStore()

    useEffect(() => {
        registerPercolatePreparePage(store)
    }, [store])

    // Apollo cache hydration happens in createClient in @emico/magento as a part
    // of Apollo initialization. This happens automatically based on if the data
    // exists or not.

    return null
}

export default AppPrerendering
