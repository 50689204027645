import styled from '@emotion/styled'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { maxWidth, minWidth } from '@emico/styles'
import { ImaginaryActions } from '@emico/ui'

import { getPrismicComponents } from './htmlSerializer'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import getLayoutStyleVars, { LayoutStyle } from './LayoutStyle'
import ImageLazy from './media/ImageLazy'
import theme from './theme'
import Heading from './typography/Heading'

const imageSizes = {
    one: {
        regularWidth: 1237,
        regularHeight: 0,
        mediumWidth: 618,
        mediumHeight: 0,
        smallWidth: 558,
        smallHeight: 0,
    },
    two: {
        regularWidth: 921,
        regularHeight: 0,
        mediumWidth: 457,
        mediumHeight: 0,
        smallWidth: 620,
        smallHeight: 0,
    },
}

export interface Image {
    url: string
    alt?: string
    mobileUrl?: string
}

export interface Props {
    style?: LayoutStyle
    heading?: string
    content?: unknown
    images?: Image[]
}

const StyledImageLazy = styled(ImageLazy)`
    max-width: 100%;
`

const Section = styled.section`
    background: ${theme.colors.background};
    padding-top: 50px;
    padding-bottom: 50px;

    @media ${minWidth('md')} {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @media ${minWidth('lg')} {
        padding-top: 75px;
        padding-bottom: 75px;
    }
`

const StyledCol = styled(Col)`
    @media ${maxWidth('sm')} {
        margin-bottom: 25px;
    }

    @media ${minWidth('lg')} {
        padding: 0 110px;
        margin-top: 85px;
    }
`

const StyledImageCol = styled(Col)`
    margin-top: -50px;

    @media ${minWidth('md')} {
        margin-top: -45%;
        margin-bottom: 70px;
    }

    @media ${minWidth('lg')} {
        margin-top: -30%;
        margin-bottom: -10%;
    }
`

const PrismicOverLappingImages = ({
    heading,
    content,
    images,
    style = 'light',
}: Props) => {
    if (!images || images.length === 0 || !heading || !content) {
        return null
    }

    const layoutStyle = getLayoutStyleVars(style)

    const imageOne = images[0]
    const imageTwo = images[1]

    return (
        <Section css={layoutStyle}>
            <Container>
                <Row>
                    {heading && content && (
                        <StyledCol
                            xs={{ order: 0, span: 12, offset: 0 }}
                            md={{ order: 2, span: 10, offset: 1 }}
                            lg={{ order: 2, span: 6, offset: 0 }}
                        >
                            <Heading variant="h2" element="h2">
                                {heading}
                            </Heading>

                            <PrismicRichText
                                render={content as [RichTextBlock]}
                                components={getPrismicComponents({})}
                                analyticsContext="prismic.overlappingimages.content"
                            />
                        </StyledCol>
                    )}

                    {imageOne?.url && (
                        <Col
                            xs={{ order: 1, span: 9, offset: 3 }}
                            md={{ order: 0, span: 8, offset: 4 }}
                        >
                            <StyledImageLazy
                                images={{
                                    image: imageOne.url,
                                    ...imageSizes.one,
                                    action: ImaginaryActions.RESIZE,
                                }}
                                alt={heading ?? ''}
                                lazy
                            />
                        </Col>
                    )}

                    {imageTwo?.url && (
                        <StyledImageCol
                            xs={{ order: 2, span: 10, offset: 0 }}
                            md={{ order: 1, span: 7, offset: 0 }}
                            lg={{ order: 1, span: 6, offset: 0 }}
                        >
                            <StyledImageLazy
                                images={{
                                    image: imageTwo.url,
                                    ...imageSizes.two,
                                    action: ImaginaryActions.RESIZE,
                                }}
                                alt={heading ?? ''}
                                lazy
                            />
                        </StyledImageCol>
                    )}
                </Row>
            </Container>
        </Section>
    )
}

export default PrismicOverLappingImages
