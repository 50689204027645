import {
    Control,
    Controller,
    FieldValues,
    Path,
    UseControllerProps,
} from 'react-hook-form'

import PopperSelect, { Props as PropperSelectProps } from './PopperSelect'

interface Props<
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object,
    TName extends Path<T>,
> extends Omit<PropperSelectProps<T>, 'name'> {
    rules?: UseControllerProps<T, TName>['rules']
    name: TName
    control: Control<T, TName>
}

const PopperSelectControl = <
    T extends TFieldValues,
    TName extends Path<T>,
    TFieldValues extends FieldValues = FieldValues,
>({
    control,
    name,
    rules,
    ...props
}: Props<T, TName>) => (
    <Controller<T, TName>
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <PopperSelect<T>
                {...props}
                name={name}
                onSelectionChange={(key) => {
                    props.onSelectionChange?.(key)
                    onChange(key)
                }}
                onBlur={(e) => {
                    props.onBlur?.(e)
                    onBlur()
                }}
                selectedKey={value}
            />
        )}
    />
)

export default PopperSelectControl
