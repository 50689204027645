import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { Sha256 } from '@aws-crypto/sha256-js'
import { CartIdProvider } from '@emico-hooks/cart-id'
import { LoginTokenProvider, useIsLoggedIn } from '@emico-hooks/login-token'
import { ActiveStoreViewProvider } from '@emico-hooks/use-active-storeview'
import { ThemeProvider } from '@emotion/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useState, useEffect, ReactNode } from 'react'

import {
    ApolloConnector,
    LoginPageContext,
    LogoutPageContext,
} from '@emico/apollo'
import { LazyFallbackContext } from '@emico/lazy'
import { StoreView } from '@emico/storeviews'
import { BreakpointsProvider } from '@emico/ui'

import { isSignedInVar } from './actions'
import AppPrerendering from './AppPrerendering'
import theme from './appTheme'
import CookieStyles from './CookieStyles/CookieStyles'
import ReduxProvider from './core/Providers/Redux'
import RouterProvider from './core/Providers/Router'
import ResponsiveProvider from './core/Responsive/Provider'
import fragmentTypes from './graphql/fragmentTypes.generated'
import { FeedbackMessageProvider } from './input/SuccessMessage/FeedbackMessage'
import LastLocationProvider from './lastLocation/LastLocationProvider'
import PageWrapper from './layout/PageWrapper'
import paths from './paths'
import PageLoader from './presentation/PageLoader'
import RootErrorBoundary from './RootErrorBoundary'
import GlobalStyles from './theme/GlobalStyles'
import { ToasterProvider } from './toaster'
import { typePolicies } from './typePolicies'
import { useActiveStoreConfigJsonStoreView } from './useActiveStoreConfigJsonStoreView'
import dataIdFromObject from './utils/apollo/dataIdFromObject'
import ComponentOverridesContext, {
    ComponentConfig,
} from './utils/ComponentOverridesContext'
import { ProductListQueuesProvider } from './utils/ga4/ProductListQueuesContext'
import { PushEnhancedConversionEmail } from './utils/ga4/PushEnhancedConversionEmail'

function uint8ArrayToHexString(arr: Uint8Array): string {
    return Array.from(arr, (byte) =>
        ('0' + (byte & 0xff).toString(16)).slice(-2),
    ).join('')
}

function sha256(key: string): Promise<string> {
    const hash = new Sha256()

    hash.update(key)
    return hash.digest().then(uint8ArrayToHexString)
}

const links = [
    createPersistedQueryLink({
        sha256,
        useGETForHashedQueries: true,
    }),
]

const Fallback = () => {
    // Prevent multiple page wrappers being loaded when nested content chunks are loaded.
    const [usePageWrapper, setPageWrapperUsage] = useState<boolean>(true)

    useEffect(() => {
        const el = document.querySelectorAll('.pageWrapper')

        if (el.length > 1) {
            setPageWrapperUsage(false)
        }
    }, [])

    return usePageWrapper ? (
        <PageWrapper pageType="N/A" data-testid="lazy-loader">
            <PageLoader fullScreen reason="Loading chunk..." />
        </PageWrapper>
    ) : (
        <PageLoader fullScreen reason="Loading chunk..." />
    )
}

/**
 * @deprecated This is a temporary fallback for Redux code, and will be removed when the last Redux
 * actions are converted to Apollo.
 */
const LoginTokenFallback = () => {
    const isLoggedIn = useIsLoggedIn()

    isSignedInVar(isLoggedIn)
    return null
}

const AppProviders = ({
    activeStoreView,
    componentsConfig,
    children,
}: {
    storeViews: StoreView[]
    activeStoreView: StoreView
    componentsConfig: ComponentConfig
    children: ReactNode
}) => {
    const activeStoreViewConfig =
        useActiveStoreConfigJsonStoreView(activeStoreView)

    return (
        <ComponentOverridesContext.Provider value={componentsConfig}>
            <GlobalStyles />
            <CookieStyles />
            <ThemeProvider theme={theme}>
                <I18nProvider i18n={i18n}>
                    <RootErrorBoundary>
                        <RouterProvider>
                            <ActiveStoreViewProvider
                                storeView={activeStoreViewConfig}
                            >
                                <LoginTokenProvider>
                                    <LoginTokenFallback />
                                    <LoginPageContext.Provider
                                        value={paths.login}
                                    >
                                        <LogoutPageContext.Provider
                                            value={paths.logout}
                                        >
                                            <ApolloConnector
                                                possibleTypes={
                                                    fragmentTypes.possibleTypes
                                                }
                                                storeView={activeStoreView.code}
                                                dataIdFromObject={
                                                    dataIdFromObject
                                                }
                                                typePolicies={typePolicies}
                                                links={links}
                                            >
                                                <CartIdProvider>
                                                    <ReduxProvider>
                                                        <PushEnhancedConversionEmail />
                                                        <AppPrerendering />
                                                        <ResponsiveProvider>
                                                            <LastLocationProvider>
                                                                <BreakpointsProvider>
                                                                    <ToasterProvider
                                                                        closeOnClick
                                                                        position="top-center"
                                                                        autoHide={
                                                                            5000
                                                                        }
                                                                    >
                                                                        <FeedbackMessageProvider
                                                                            timeout={
                                                                                4000
                                                                            }
                                                                        >
                                                                            <ProductListQueuesProvider>
                                                                                <LazyFallbackContext.Provider
                                                                                    value={
                                                                                        <Fallback />
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        children
                                                                                    }
                                                                                </LazyFallbackContext.Provider>
                                                                            </ProductListQueuesProvider>
                                                                        </FeedbackMessageProvider>
                                                                    </ToasterProvider>
                                                                </BreakpointsProvider>
                                                            </LastLocationProvider>
                                                        </ResponsiveProvider>
                                                    </ReduxProvider>
                                                </CartIdProvider>
                                            </ApolloConnector>
                                        </LogoutPageContext.Provider>
                                    </LoginPageContext.Provider>
                                </LoginTokenProvider>
                            </ActiveStoreViewProvider>
                        </RouterProvider>
                    </RootErrorBoundary>
                </I18nProvider>
            </ThemeProvider>
        </ComponentOverridesContext.Provider>
    )
}

export default AppProviders
