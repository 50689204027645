import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useCartItems } from './cart/CartPage/useCartItems'
import { useMiniCartVar } from './cart/miniCartVar'
import EditGiftsModal from './gifts/EditGiftsModal'
import paths from './paths'

export const giftModalEnabledVar = makeVar<boolean>(false)

const FreeGiftAvailableModal = () => {
    const { pathname } = useLocation()
    const [hasChanged, setChanged] = useState<boolean>(false)

    const { freeGifts } = useCartItems()
    const availableRules = freeGifts?.filter(
        (gift) => gift.isAvailable && gift.isValid,
    )

    const isModalEnabled = useReactiveVar(giftModalEnabledVar)
    const { open } = useMiniCartVar()

    // Do not show minicart on cart page
    const disableMiniCart = pathname === paths.cart

    // Detect if there is a change in available rules.
    useEffect(() => {
        // If the only rules shown are rules that are selected already, dont show the modal.
        if (freeGifts?.find((r) => r.isAvailable)) {
            setChanged(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freeGifts?.map((r) => r.id).join()])

    if (!isModalEnabled || !hasChanged || !availableRules?.length) {
        return null
    }

    return (
        <EditGiftsModal
            rules={availableRules}
            onBack={() => {
                setChanged(false)
                giftModalEnabledVar(false)

                if (!disableMiniCart) {
                    open()
                }
            }}
        />
    )
}

export default FreeGiftAvailableModal
