import { usePushEcommerceEvent } from './usePushEvent'

export function useSortByEvent() {
    const push = usePushEcommerceEvent([], false)

    function pushSortByEvent(sortName: string) {
        // Asuming that a user does not click faster than the required data is loaded
        push?.([
            { ecommerce: null },
            {
                event: 'sort_by',

                sort_name: sortName,
            },
        ])
    }

    return pushSortByEvent
}
