import { gql, TypedDocumentNode } from '@apollo/client'

import { CartAddressFragment } from './CartAddressFragment.generated'

export const cartAddressFragment = gql`
    fragment CartAddressFragment on CartAddressInterface {
        firstname
        lastname
        company
        street
        postcode
        city
        country {
            code
            label
        }
        telephone
        region {
            code
            label
            regionId
        }
    }
` as TypedDocumentNode<CartAddressFragment, never>
