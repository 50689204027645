import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import * as React from 'react'

import makeMediaUrl, { MediaType } from '../../utils/makeMediaUrl'

interface Props {
    paymentMethodImage: string
    className?: string
}

const StyledPaymentMethod = styled.div`
    display: inline-flex;
    height: 20px;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }
`

const AvailablePaymentMethodsItem = ({
    paymentMethodImage,
    className,
}: Props) => (
    <StyledPaymentMethod className={className}>
        <Image
            url={makeMediaUrl(
                paymentMethodImage,
                MediaType.AvailablePaymentMethod,
            )}
            alt=""
            height={20}
            lazy
        />
    </StyledPaymentMethod>
)

export default AvailablePaymentMethodsItem
