import * as React from 'react'

export default (targetKey: string, callBack: () => void) => {
    // If pressed key is our target key then set to true
    const downHandler = React.useCallback(
        (event: KeyboardEvent) => {
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
            if (event.isComposing || event.keyCode === 229) {
                return
            }

            // If input element has focus, exit early so things like password managers can be closed with an escape button
            if (
                document.activeElement &&
                ['INPUT', 'TEXTAREA'].includes(
                    document.activeElement.nodeName.toUpperCase(),
                )
            ) {
                return
            }

            if (event.key === targetKey) {
                callBack()
            }
        },
        [targetKey, callBack],
    )

    React.useEffect(() => {
        window.addEventListener('keydown', downHandler)

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler)
        }
    }, [downHandler]) // Empty array ensures that effect is only run on mount and unmount
}
