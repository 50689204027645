import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled'

import Accordion, { AccordionItem } from './Accordion'
import BackIcon from './core/BackIcon'
import { Responsive } from './core/Responsive'
import { FaqPageBodyFaqSubject } from './graphql/schema.generated'
import Button from './input/Button'
import { LayoutStyle } from './LayoutStyle'
import Icon from './media/Icon'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'

export type PrismicSliceFaqSubject = FaqPageBodyFaqSubject

interface Props {
    title: string
    questions: AccordionItem[]
    onBackClick?(): void
    style: LayoutStyle
    headingElement?: HeadingElement
    headingVariant?: HeadingVariant
}

const QuestionsHeading = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.85em;
`

const BackButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: -10px;
    margin-right: 0;
    line-height: 15px;
`

const StyledIcon = styled(Icon)`
    width: 15px;
    height: 15px;
`

const FaqQuestions = ({
    title,
    questions,
    onBackClick,
    style = 'light',
    headingElement = HeadingElement.h3,
    headingVariant = HeadingVariant.h4,
}: Props) => (
    <>
        <ClassNames>
            {({ css }) => (
                <QuestionsHeading>
                    <Heading
                        variant="h2"
                        element="h2"
                        color={style === 'dark' ? 'light' : 'dark'}
                        className={css`
                            order: 2;
                        `}
                    >
                        {title}
                    </Heading>
                    {onBackClick && (
                        <Responsive sm down>
                            <BackButton
                                variant="link"
                                name=""
                                category=""
                                onClick={onBackClick}
                            >
                                <StyledIcon
                                    component={BackIcon}
                                    title="Back icon"
                                />
                            </BackButton>
                        </Responsive>
                    )}
                </QuestionsHeading>
            )}
        </ClassNames>
        <Accordion
            items={questions}
            initial={0}
            element={headingElement}
            variant={headingVariant}
        />
    </>
)

export default FaqQuestions
