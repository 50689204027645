import { Organization } from '@emico/schema-org'

import { useCoreConfigValue } from '../coreConfig.query'

interface TrustedShopReviewData {
    qualityIndicators: {
        reviewIndicator: {
            totalReviewCount: number
            overallMark: number
        }
    }
}

const OrganizationSchemaOrg = () => {
    const { value: trustedShopReviewDataRaw } = useCoreConfigValue(
        'trustedshops/data/quality',
    )

    const trustedShopReviewData: TrustedShopReviewData =
        trustedShopReviewDataRaw
            ? JSON.parse(trustedShopReviewDataRaw)
            : {
                  qualityIndicators: {
                      reviewIndicator: {
                          totalReviewCount: 0,
                          overallMark: 0,
                      },
                  },
              }

    const { totalReviewCount, overallMark } =
        trustedShopReviewData.qualityIndicators.reviewIndicator

    const profileUrl =
        'https://www.trustedshops.nl/verkopersbeoordeling/info_X5AA1F9439CEFED350F03FB687502B0C9.html?stars=5'

    const url = location.href
    const phone = useCoreConfigValue('general/store_information/phone')
    const email = useCoreConfigValue('general/store_information/email')
    const storeName = useCoreConfigValue('general/store_information/name')

    return (
        <Organization
            aggregateRating={
                (trustedShopReviewData &&
                    totalReviewCount &&
                    overallMark && {
                        '@type': 'AggregateRating',
                        bestRating: 5,
                        worstRating: 1,
                        ratingValue: overallMark,
                        reviewCount: totalReviewCount,
                        url: profileUrl,
                        itemReviewed: {
                            '@type': 'Brand',
                            name: storeName.value ? storeName.value : '',
                        },
                    }) ||
                undefined
            }
            url={url}
            contactPoint={[
                {
                    '@type': 'ContactPoint',
                    contactType: 'Customer service',
                    telephone: phone.value,
                    email: email.value,
                },
                {
                    '@type': 'ContactPoint',
                    contactType: 'Headquarters',
                    telephone: phone.value,
                },
            ]}
        />
    )
}

export default OrganizationSchemaOrg
