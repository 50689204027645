import { gql, useApolloClient } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useFieldApi, useFieldState } from 'informed'
import * as React from 'react'

import { useDebounce } from '@emico/utils'

import {
    EmailValidationResult,
    EmailValidationResultStatus,
} from '../../graphql/schema.generated'
import ErrorIndicator from '../ErrorIndicator'
import Input, { Props as InputProps } from '../Input'
import InputIndicatorTransition from '../InputIndicatorTransition'

// This regex is intended to help users detect typos, not to limit user input.
// Excessive email validation hurts UX. This regex assumes people have sane email adresses without too many weird characters,...
// ...and a domain. No, we don't want users to be able to enter `root@localhost`. Yes, we will accept `a@b.c`.
const pattern =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.([a-zA-Z0-9-]{2,}))+$/

export const validateEmail = (value: string): string | undefined => {
    if (!value || !pattern.test(value)) {
        return t({
            id: 'core.emailInput.invalidError',
            message: `Enter a valid email address`,
        })
    }
    return undefined
}

type Props = Omit<InputProps<string>, 'type' | 'validate'>

const query = gql`
    query validateEmail($email: String!) {
        validateEmail(email: $email) {
            status
            autocorrect
        }
    }
`

const EmailValidation = ({ field }: { field: string }) => {
    const apolloClient = useApolloClient()
    const fieldState = useFieldState<string>(field)
    const fieldApi = useFieldApi(field)
    const [message, setMessage] = React.useState<React.ReactNode>()
    const [autocorrectValue, setAutocorrectValue] = React.useState<string>()
    const debouncedValue = useDebounce(fieldState.value, 200)

    React.useEffect(() => {
        if (!debouncedValue || fieldState.error) {
            setMessage(undefined)
            return
        }

        apolloClient
            .query<{ validateEmail: EmailValidationResult }>({
                query,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    email: debouncedValue,
                },
            })
            .then(({ data }) => {
                const { validateEmail } = data || {}
                const { autocorrect, status } = validateEmail || {}

                if (status === EmailValidationResultStatus.INVALID) {
                    setMessage(
                        t({
                            id: 'core.emailInput.invalidError',
                            message: `Enter a valid email address`,
                        }),
                    )
                } else if (
                    status === EmailValidationResultStatus.WARNING &&
                    autocorrect
                ) {
                    setAutocorrectValue(autocorrect)
                    const autocorrectLabel = <u>{autocorrect}</u>

                    setMessage(
                        <Trans id="core.emailInput.emailSuggestion">
                            Did you mean {autocorrectLabel}?
                        </Trans>,
                    )
                } else {
                    setMessage(undefined)
                }
            })
            .catch(() => {
                // When an error occurred in the query, just act like it didn't happen
                setMessage(undefined)
            })
    }, [
        debouncedValue,
        setAutocorrectValue,
        setMessage,
        apolloClient,
        fieldState.error,
    ])

    const onErrorLabelClick = React.useCallback(() => {
        if (autocorrectValue) {
            fieldApi.setValue(autocorrectValue)
        }

        setMessage(undefined)
    }, [autocorrectValue, fieldApi])

    return (
        <InputIndicatorTransition>
            {message && (
                <ErrorIndicator field={field} onClick={onErrorLabelClick}>
                    {message}
                </ErrorIndicator>
            )}
        </InputIndicatorTransition>
    )
}

const Email = ({ children, ...props }: Props) => (
    <Input<string> {...props} type="email" validate={validateEmail}>
        {children}
        {!props.disabled && <EmailValidation field={props.field} />}
    </Input>
)

export default Email
