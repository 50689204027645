import { ClassNames, css } from '@emotion/react'
import cx from 'classnames'
import * as React from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import styles from './Heading.module.scss'
import theme from '../../theme'
import HeadingColor from '../../types/HeadingColor'
import HeadingElement from '../../types/HeadingElement'
import HeadingVariant from '../../types/HeadingVariant'

type Fuss = 'pme' | 'vanguard' | 'castiron' | 'castironLight'

export interface Props {
    /** Content to render */
    children: React.ReactNode

    /** For custom styling */
    className?: string

    /** The type of Heading */
    variant: keyof typeof HeadingVariant

    /** The HTML element of the Heading */
    element: keyof typeof HeadingElement

    /** The Heading color */
    color?: keyof typeof HeadingColor

    /** The type of fuss to be applied */
    fuss?: Fuss[]
    htmlFor?: string
}

const pmeHeading = css`
    position: relative;

    &::before {
        position: absolute;
        content: '';
        transform: translateY(-0.4em);
        width: clamp(0.5em, 30%, 1.75em);
        height: 0.12em;
        background: ${theme.colors.primary};
    }

    &::after {
        content: '';
        display: block;
    }

    &[class*='_h0_']::before {
        transform: translateY(-0.3em);
    }
`

const ciHeading = css`
    &::before {
        position: absolute;
        content: '';
        box-shadow: -0.05em -0.05em 0 0 ${theme.colors.primary};
        width: 0.5em;
        height: 0.75em;
        transform: translate3d(-0.2em, -0.2em, 0);
    }
`

const ciHeadingLight = css`
    ${ciHeading}

    &::before {
        box-shadow: -0.05em -0.05em 0 0 ${theme.colors.white};
    }
`

const vgHeading = css`
    &::before {
        position: absolute;
        content: '//';
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }

    &::after {
        position: absolute;
        content: ' .';
        display: inline-block;
    }
`

const fussStyles = {
    pme: pmeHeading,
    vanguard: vgHeading,
    castiron: ciHeading,
    castironLight: ciHeadingLight,
}

// eslint-disable-next-line react/display-name
const Heading = React.forwardRef<HTMLHeadingElement, Props>(
    (
        {
            children,
            className,
            variant,
            element,
            color,
            fuss,
            ...otherProps
        }: Props,
        ref,
    ) => {
        const activeStoreView = useActiveStoreView()
        const HeadingTag = element as
            | 'h1'
            | 'h2'
            | 'h3'
            | 'h4'
            | 'h5'
            | 'h6'
            | 'div'
            | 'span'

        const colorClassName = color ? styles[color] : ''

        const fussBrand = (fuss || []).find((f) =>
            activeStoreView.code.includes(f.replace('Light', '')),
        )

        const headingStyle = fussBrand && fussStyles[fussBrand]

        return (
            <ClassNames>
                {({ css }) => (
                    <HeadingTag
                        {...otherProps}
                        className={[
                            cx(
                                styles.default,
                                styles[variant],
                                { [colorClassName]: colorClassName },
                                className,
                            ),
                            css(headingStyle),
                        ].join(' ')}
                        ref={ref}
                    >
                        {children}
                    </HeadingTag>
                )}
            </ClassNames>
        )
    },
)

export default Heading
