import cx from 'classnames'

import styles from './ProductStockStatus.module.scss'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../../catalog/ProductPage/GetProduct.query'

interface Props {
    product: Product | ConfigurableProduct
    className?: string
}

const ProductStockStatus = ({ product, className }: Props) =>
    product.deliveryMessage ? (
        <div className={cx(styles.base, className)}>
            {product.deliveryMessage}
        </div>
    ) : null

export default ProductStockStatus
