import * as React from 'react'

interface Size {
    width: number | undefined
    height: number | undefined
}

export default function useWindowSize() {
    const [size, setSize] = React.useState<Size>({
        width: undefined,
        height: undefined,
    })

    React.useEffect(() => {
        function updateSize() {
            setSize({ width: window.innerWidth, height: window.innerHeight })
        }

        window.addEventListener('resize', updateSize)

        updateSize()

        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size
}
