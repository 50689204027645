// Put imports here that are needed by all apps to initialize.
import 'intersection-observer'

import { Buffer } from 'buffer'

import('./Sentry')

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
globalThis.Buffer = Buffer

// Localstorage can sometimes be unavailable.
// See https://support.emico.nl/issues/114874
// import './localStoragePolyfill'
