import * as Sentry from '@sentry/react'
import * as React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { ScrollRestoration } from '@emico/ui'

import FreeGiftAvailableModal from './FreeGiftAvailableModal'
import PageWrapper from './layout/PageWrapper'
import paths from './paths'
import PageLoader from './presentation/PageLoader'
import routes, { RouteType } from './routes'
import overridable from './utils/overridable'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)

// NOTE: When defining or renaming routes, please make sure the backend configuration (backend/app/etc/config.php) is
// also updated. Failure to do so will cause a 404 Status Code on your new / renamed route.
export const AppRouter = overridable('AppRouter')(
    ({ routes }: { routes: RouteType[] }) => (
        <Switch>
            {routes.map((route) => (
                <SentryRoute key={route.path} {...route} />
            ))}
        </Switch>
    ),
)

const CustomScrollRestoration = () => {
    const history = useHistory()

    React.useEffect(() => {
        history.listen((location, _) => {
            window.history.scrollRestoration = 'auto'

            if (location.pathname.startsWith(paths.account)) {
                window.history.scrollRestoration = 'manual'
            }

            if (
                location.pathname.includes(paths.login) ||
                location.pathname.includes(paths.activateAccount) ||
                location.pathname.includes(paths.register) ||
                location.pathname.includes(paths.forgotPassword)
            ) {
                window.history.scrollRestoration = 'auto'
            }
        })

        return () => {
            window.history.scrollRestoration = 'auto'
        }
    }, [history])

    return null
}

const Routing = () => (
    <React.Suspense
        fallback={
            <PageWrapper pageType="N/A">
                <PageLoader fullScreen reason="Loading module bundle..." />
            </PageWrapper>
        }
    >
        <FreeGiftAvailableModal />

        <ScrollRestoration>
            <CustomScrollRestoration />
            <AppRouter routes={routes} />
        </ScrollRestoration>
    </React.Suspense>
)

export default Routing
