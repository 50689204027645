import { ApolloError } from '@apollo/client'
import { useAddToCart } from '@emico-hooks/cart-add-to-cart'
import { CartFragment } from '@emico-hooks/cart-fragment'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { useCallback } from 'react'

import { useMiniCartVar } from '../cart/miniCartVar'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import {
    isGroupedProduct,
    Product,
} from '../catalog/ProductPage/GetProduct.query'
import { giftModalEnabledVar } from '../FreeGiftAvailableModal'
import publishBlueConicEvent from '../publishBlueConicEvent'
import { useAddToCartEvent } from '../utils/ga4/useAddToCartEvent'

async function add(
    addItemFunc: () => Promise<CartFragment | undefined>,
    pushAddToCart: () => void,
    openMiniCart: () => void,
) {
    try {
        const cart = await addItemFunc()
        pushAddToCart()
        if (cart?.items) {
            publishBlueConicEvent(
                'shoppingcart',
                cart?.items
                    .map((item) => item.product?.sku)
                    .filter(stripMaybes),
            )
            openMiniCart()
        }
    } catch (e) {
        if (!(e instanceof Error)) {
            return
        }
        let message = e.message

        if (e instanceof ApolloError) {
            message = (e as ApolloError).graphQLErrors?.[0]?.message
        }

        throw new Error(message)
    }
}

export default function useAddToCartFunction() {
    const push = useAddToCartEvent()
    const addItemToCart = useAddToCart(true)
    const { open } = useMiniCartVar()

    function addToCart(
        product: Product | ConfigurableProduct | null | undefined,
        quantity: number,
        options: ConfigurableAttributesFieldValue | undefined,
        openMiniCart?: boolean,
    ): Promise<void>

    function addToCart(
        product: Product | ConfigurableProduct | null | undefined,
    ): (
        quantity: number,
        options: ConfigurableAttributesFieldValue | undefined,
        openMiniCart?: boolean,
    ) => Promise<void>

    function addToCart(
        product: Product | ConfigurableProduct | null | undefined,
        quantity?: number,
        options?: ConfigurableAttributesFieldValue | undefined,
        openMiniCart: boolean = true,
    ) {
        if (quantity == null || options == null) {
            return (
                quantity: number,
                options: ConfigurableAttributesFieldValue | undefined,
            ) => addToCart(product, quantity, options)
        }

        if (!product || isGroupedProduct(product)) {
            return Promise.resolve()
        }
        if (openMiniCart) {
            giftModalEnabledVar(true)
        }

        const addItem = async () => {
            const itemInput = {
                sku: product.sku,
                quantity,
                selected_options: Object.values(options ?? []),
            }

            const result = await addItemToCart(itemInput)
            // Throw first error
            if (result?.addProductsToCart?.userErrors?.length) {
                throw new Error(
                    result.addProductsToCart?.userErrors[0]?.message,
                )
            }
            return result?.addProductsToCart?.cart
        }

        return add(
            addItem,
            () => {
                push(product.sku, product, quantity, options)
            },
            openMiniCart ? open : () => undefined,
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(addToCart, [push])
}
