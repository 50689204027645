import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'

import Carousel from './behaviour/Carousel'
import Button, { ButtonVariant } from './input/Button'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import { LayoutStyle } from './LayoutStyle'
import Link from './navigation/Link'
import breakpoints from './theme/breakpoints'
import styles from './TileSlider.module.scss'
import HeadingColor from './types/HeadingColor'
import TextColor from './types/TextColor'
import Heading from './typography/Heading'
import Text from './typography/Text'
import overridable from './utils/overridable'

const StyledPicture = styled(Picture)`
    width: 100%;
    height: auto;
`

export interface TileSliderItem {
    title?: string
    subtitle?: string
    buttonUrl: string
    buttonText?: string
    image: {
        url: string
        alt?: string
    }
    imageMobile?: {
        url?: string
        alt?: string
    }
}

export interface Props {
    style: LayoutStyle
    promotionCreative?: string
    variant?: string
    header?: React.ReactNode
    content?: string
    buttonUrl?: string
    buttonText?: string
    items: TileSliderItem[]
    headingColor?: HeadingColor
    textColor?: TextColor
    buttonStyle?: ButtonVariant
    tileHeadingColor?: HeadingColor
    tileButtonStyle?: ButtonVariant
}

const getTileSliderVariantStyle = (
    variant: string | undefined,
): string | undefined => {
    if (!variant) {
        return
    }

    if (variant.startsWith('variant 1')) {
        return styles.variantOne
    }

    return undefined
}

const renderItems = (
    items: TileSliderItem[],
    headingColor: HeadingColor,
    buttonStyle: ButtonVariant,
) => {
    const tiles = []

    // eslint-disable-next-line no-restricted-syntax
    for (const i in items) {
        // eslint-disable-next-line no-prototype-builtins
        if (!items.hasOwnProperty(i)) {
            continue
        }
        const tile = items[i]
        const { image, imageMobile, title, subtitle, buttonText, buttonUrl } =
            tile
        const imageUrl = image.url
        const imageAlt = image.alt

        const article = (
            <article className={styles.slide} key={`${title}${subtitle}`}>
                <StyledPicture
                    breakpoints={{
                        [breakpoints.xs]: {
                            url: imageMobile?.url ?? imageUrl,
                            width: 287,
                            height: 287,
                            params: {
                                resizingType: 'fill-down',
                            },
                        },
                        [breakpoints.md]: {
                            url: imageMobile?.url ?? imageUrl,
                            width: 416,
                            height: 416,
                            params: {
                                resizingType: 'fill-down',
                            },
                        },
                        [breakpoints.lg]: {
                            url: imageMobile?.url ?? imageUrl,
                            width: 644,
                            height: 644,
                            params: {
                                resizingType: 'fill-down',
                            },
                        },
                    }}
                    lazy
                    alt={imageAlt ?? title ?? ''}
                    className={styles.slideImage}
                />
                <span className={styles.slideContent}>
                    {title && (
                        <Heading
                            className={styles.slideTitle}
                            variant="h1"
                            element="h1"
                            color={headingColor}
                        >
                            {title}
                        </Heading>
                    )}
                    {subtitle && (
                        <Heading
                            className={styles.slideSubTitle}
                            variant="h4"
                            element="div"
                            color={headingColor}
                        >
                            {subtitle}
                        </Heading>
                    )}
                    {buttonText && (
                        <Button
                            minWidth
                            variant={buttonStyle}
                            className={styles.slideButton}
                            style={{ marginTop: '1rem' }}
                            name={buttonText}
                            category="tileslider.button"
                        >
                            {buttonText}
                        </Button>
                    )}
                </span>
            </article>
        )

        tiles.push(
            buttonUrl ? (
                <Link
                    name={title ?? imageAlt ?? ''}
                    category="tileslider.link"
                    to={buttonUrl}
                    className={styles.slideLink}
                    key={`${title}${subtitle}`}
                    preload
                >
                    {article}
                </Link>
            ) : (
                article
            ),
        )
    }

    return tiles
}

export const TileSlider = ({
    style,
    variant,
    header,
    content,
    buttonUrl,
    buttonText,
    items,
    headingColor,
    textColor,
    promotionCreative = 'Tile slider',
    buttonStyle = 'primary',
    tileHeadingColor = HeadingColor.light,
    tileButtonStyle = 'primary',
}: Props) => {
    if (!items || items.length === 0) {
        return null
    }

    const headingColorByTheme: HeadingColor =
        headingColor ||
        (style === 'light' ? HeadingColor.dark : HeadingColor.light)

    const textColorByTheme: TextColor =
        textColor || (style === 'light' ? TextColor.dark : TextColor.light)

    const variantStyle = getTileSliderVariantStyle(variant)

    return (
        <section className={cx(styles[style], variantStyle, styles.section)}>
            <Container noPadding className={styles.container}>
                <Row className={styles.row} noGutters>
                    <Col lg={3} className={styles.column}>
                        <div className={styles.content}>
                            {header && (
                                <Heading
                                    variant="h2"
                                    element="h2"
                                    color={headingColorByTheme}
                                    className={styles.heading}
                                    fuss={['pme']}
                                >
                                    {header}
                                </Heading>
                            )}

                            {content && (
                                <div className={styles.contentInner}>
                                    <Text color={textColorByTheme}>
                                        {content}
                                    </Text>
                                </div>
                            )}

                            {buttonUrl && buttonText && (
                                <Button
                                    minWidth
                                    to={buttonUrl}
                                    variant={buttonStyle}
                                    name={buttonText}
                                    category="core.homePage.stories"
                                    className={styles.contentButton}
                                    preload
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </div>
                    </Col>

                    <Col lg={9} className={styles.carousel}>
                        <Carousel
                            settings={{
                                arrows: true,
                                infinite: false,
                                slidesToShow: items.length >= 3 ? 2.3 : 2,
                                slidesToShowLg: 1.8,
                                slidesToShowMd: 1.4,
                                slidesToShowSm: 1.1,
                                speed: 300,
                                cssEase: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                            }}
                        >
                            {renderItems(
                                items,
                                tileHeadingColor,
                                tileButtonStyle,
                            )}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default overridable('TileSlider')(TileSlider)
