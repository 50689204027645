import {
    CastironPageBodyCategoryList,
    FooterPmeBodyCategoryList,
    JbfoPageBodyCategoryList,
    JustbrandsPageBodyCategoryList,
    MonstersalePageBodyCategoryList,
    PageBodyCategoryList,
    PmeOutletPageBodyCategoryList,
    SearchPageBodyCategoryList,
    StorelocatorPageBodyCategoryList,
    VanguardPageBodyCategoryList,
} from './graphql/schema.generated'
import { LayoutStyle } from './LayoutStyle'
import { ItemGroup, Item } from './LinkList'
import getLayoutStyle from './utils/getLayoutStyle'

export type PrismicSliceCategoryList =
    | PageBodyCategoryList
    | VanguardPageBodyCategoryList
    | CastironPageBodyCategoryList
    | JustbrandsPageBodyCategoryList
    | JbfoPageBodyCategoryList
    | MonstersalePageBodyCategoryList
    | PmeOutletPageBodyCategoryList
    | SearchPageBodyCategoryList
    | FooterPmeBodyCategoryList
    | StorelocatorPageBodyCategoryList

const mapPrismicSliceCategoryList = (
    slice: PrismicSliceCategoryList,
    style: LayoutStyle = 'dark',
): {
    style: LayoutStyle
    heading?: string
    items: ItemGroup[]
} => {
    const { __typename, primary, fields } = slice
    const { title1, title2, title3, title4 } = primary ?? {}
    let newStyle = style

    switch (__typename) {
        case 'PageBodyCategoryList':
        case 'VanguardPageBodyCategoryList':
        case 'CastironPageBodyCategoryList':
        case 'JustbrandsPageBodyCategoryList':
        case 'JbfoPageBodyCategoryList':
        case 'MonstersalePageBodyCategoryList':
        case 'PmeOutletPageBodyCategoryList':
            newStyle = primary?.style ? getLayoutStyle(primary.style) : style
            break
    }

    if (!fields || fields.length === 0) {
        return { items: [], style: newStyle, heading: undefined }
    }

    const items = [title1, title2, title3, title4].reduce<ItemGroup[]>(
        (acc, item, currentIndex) => {
            if (!item) {
                return acc
            }

            const listItems = [...fields].reduce<Item[]>((accFields, field) => {
                const { list, title, url } = field

                if (list === `list-${currentIndex + 1}` && title && url) {
                    accFields.push({
                        title,
                        url,
                    })
                }
                return accFields
            }, [])

            if (listItems.length === 0) {
                return acc
            }

            acc.push({
                title: item,
                items: listItems,
            })

            return acc
        },
        [],
    )

    return {
        style: newStyle,
        heading: primary?.heading ?? undefined,
        items,
    }
}

export default mapPrismicSliceCategoryList
