/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient } from '@apollo/client'
import {
    TypedUseSelectorHook,
    useDispatch as baseUseDispatch,
    useSelector as baseUseSelector,
} from 'react-redux'
import { Action as ReduxAction } from 'redux'
import {
    ThunkAction as BaseThunkAction,
    ThunkDispatch as BaseThunkDispatch,
} from 'redux-thunk'

import { State } from '../reducers/rootReducer'
import { RequestType } from '../utils/RestApi/request'

export interface Action extends ReduxAction<string> {
    payload: any
}

export interface Services {
    apolloClient: ApolloClient<unknown>
    request: RequestType
    rootCategoryId?: number
}

export type ThunkAction<R> = BaseThunkAction<R, State, Services, Action>

export type ThunkResult<R> = ThunkAction<R>

export type ThunkDispatch = BaseThunkDispatch<State, Services, Action>

/**
 * A generic type that takes a thunk action creator and returns a function
 * signature which matches how it would appear after being processed using
 * bindActionCreators(): a function that takes the arguments of the outer
 * function, and returns the return type of the inner "thunk" function.
 *
 * @template TActionCreator Thunk action creator to be wrapped
 */
export type ThunkActionDispatch<
    TActionCreator extends (...args: any[]) => ThunkAction<any>,
> = (
    ...args: Parameters<TActionCreator>
) => ReturnType<ReturnType<TActionCreator>>

// @TODO: fix depricated type
export const useSelector: TypedUseSelectorHook<State> = baseUseSelector
export const useDispatch = () => baseUseDispatch<ThunkDispatch>()
