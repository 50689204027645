import { handleActions, ReducerMap } from 'redux-actions'

import actions from '../actions/checkout'

export interface CheckoutState {
    continueAsGuest: boolean
    updating: boolean
    email?: string
    firstName?: string
    lastName?: string
    gatewayInformation?: {
        redirectUrl: string
        redirectForm?: {
            action: string
            fields: Array<{ field: string; value: string }>
        }
    }
}

const initialState: CheckoutState = {
    continueAsGuest: false,
    updating: false,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    gatewayInformation: undefined,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducerMap: ReducerMap<CheckoutState, any> = {
    [actions.reset.toString()]: (): CheckoutState => initialState,
    [actions.continueAsGuest.toString()]: (
        state,
        {
            payload,
        }: { payload: Pick<CheckoutState, 'email' | 'firstName' | 'lastName'> },
    ): CheckoutState => ({
        ...state,
        continueAsGuest: true,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
    }),
    [actions.setGatewayInformation.toString()]: (
        state,
        { payload }: { payload: CheckoutState['gatewayInformation'] },
    ): CheckoutState => ({
        ...state,
        gatewayInformation: payload,
    }),
    [actions.setUpdating.toString()]: (
        state,
        { payload }: { payload: CheckoutState['updating'] },
    ): CheckoutState => ({
        ...state,
        updating: payload,
    }),
}

export default handleActions(reducerMap, initialState)
