import cx from 'classnames'

import { ImaginaryActions } from '@emico/ui'

import styles from './MiniCartItemImage.module.scss'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import { LazyProductPage } from '../chunks'
import { Maybe, ProductImage } from '../graphql/schema.generated'
import ImageLazy from '../media/ImageLazy'
import Link from '../navigation/Link'

interface Props {
    product: Overwrite<
        Pick<Product, 'urlKey' | 'name' | 'smallImage' | 'modelImage'>,
        {
            smallImage: Maybe<Pick<ProductImage, 'url'>>
        }
    >
    variant?: 'standard' | 'gift'
}

const MiniCartItemImage = ({ product, variant = 'standard' }: Props) => {
    const modelImage = product.modelImage === '1' ? true : false

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    return (
        <figure
            className={cx(styles.imageContainer, {
                [styles.gift]: variant === 'gift',
            })}
        >
            <Link
                to={`/${product.urlKey}`}
                name={product.name}
                category="cart.miniCart.cartItem"
                onMouseEnter={handleMouseEnter}
            >
                <ImageLazy
                    alt=""
                    images={{
                        image: product.smallImage?.url ?? '',
                        regularWidth: 164,
                        mediumWidth: 300,
                        smallWidth: 150,
                        action: ImaginaryActions.RESIZE,
                    }}
                    className={cx(styles.image, {
                        [styles.modelImage]: modelImage,
                    })}
                />
            </Link>
        </figure>
    )
}

export default MiniCartItemImage
