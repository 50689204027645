import { ComponentProps } from 'react'

import {
    CastironPageBodyOverlappingImages,
    CastironPageBodyOverlappingImagesFields,
} from './graphql/schema.generated'
import PrismicOverlappingImages, { Image } from './PrismicOverlappingImages'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceOverlappingImagesType = CastironPageBodyOverlappingImages

interface Props {
    slice: PrismicSliceOverlappingImagesType
}

const PrismicSliceOverlappingImages = ({
    slice: { primary, fields },
}: Props) => {
    if (!primary) {
        return null
    }

    const { heading, content, style } = primary

    const imageFields =
        (fields as CastironPageBodyOverlappingImagesFields[]) ?? []

    const images = imageFields.reduce<Image[]>((acc, field) => {
        if (field?.backgroundImage?.url && field?.frontImage?.url) {
            acc.push({
                url: field.backgroundImage.url,
                alt: field.backgroundImage.alt ?? undefined,
            })
            acc.push({
                url: field.frontImage.url,
                alt: field.frontImage.alt ?? undefined,
            })
        }
        return acc
    }, [])

    const componentProps: ComponentProps<typeof PrismicOverlappingImages> = {
        style: getLayoutStyle(style),
        heading: heading ?? undefined,
        content,
        images,
    }

    return <PrismicOverlappingImages {...componentProps} />
}

export default PrismicSliceOverlappingImages
