import cx from 'classnames'
import * as React from 'react'

import styles from './Card.module.scss'
import { LazyProductPage } from '../../../chunks'
import ImageLazy from '../../../media/ImageLazy'
import { PlaybackEvent } from '../../../media/Video/Video'
import Link from '../../../navigation/Link'
import { ProductCardFragment } from '../../../ProductCardFragment'
import hasHoverSupport from '../../../utils/hasHoverSupport'
import isPortraitMode from '../../../utils/isPortraitMode'
import overridable from '../../../utils/overridable'
import useHover from '../../../utils/useHover'

interface OwnProps {
    product: ProductCardFragment
    selectedCard: string | undefined
    activeCard: string | undefined
    carouselEnabled: boolean
    isVideoReady: boolean
    progress: PlaybackEvent
    onClick?(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        product: ProductCardFragment,
    ): void
    onHover?(product: ProductCardFragment, isHovered: boolean): void
    onVisibilityChange?(isVisible: boolean): void
}

type Props = OwnProps

const HeroVideoCard = ({
    product,
    activeCard,
    selectedCard,
    onClick,
    onHover,
    carouselEnabled,
    isVideoReady,
}: Props) => {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>()
    const hasHover = hasHoverSupport()
    const isPortrait = isPortraitMode()
    const isActive = activeCard === product.sku
    const isSelected =
        !hasHover && !isPortrait ? selectedCard === product.sku : undefined

    const images = {
        image: product.smallImage?.url ?? '',
        smallWidth: 170,
        mediumWidth: 170,
        regularWidth: 170,
    }

    const handleClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        if (onClick) {
            onClick(event, product)
        }
    }

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    React.useEffect(() => {
        if (onHover) {
            onHover(product, isHovered)
        }
    }, [isHovered, product, onHover])

    return (
        <div
            ref={hoverRef}
            className={cx(styles.base, {
                [styles.productList]: !carouselEnabled,
                [styles.active]: isVideoReady && !isHovered && isActive,
                [styles.hovered]: isVideoReady && (isHovered || isSelected),
            })}
        >
            <Link
                name={product.name}
                category="productCard"
                to={`/${product.urlKey}`}
                title={product.name}
                className={styles.responsiveWrapper}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
            >
                <article
                    className={cx(styles.card, {
                        [styles.animateCard]: !carouselEnabled && isVideoReady,
                    })}
                >
                    <h1 className={styles.title}>{product.name}</h1>

                    <div className={styles.imageWrapper}>
                        <ImageLazy
                            alt={product.smallImage?.label || product.name}
                            images={images}
                            className={styles.image}
                            lazy={false}
                        />
                    </div>
                </article>
            </Link>
        </div>
    )
}

export default overridable('HeroVideoCard')(HeroVideoCard)
