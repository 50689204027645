import { ConfigurableProduct } from 'components/src/catalog/ProductPage/ConfigurableProduct'
import { Product } from 'components/src/catalog/ProductPage/GetProduct.query'
import Heading from 'components/src/typography/Heading/Heading'
import { BrandMapper } from 'components/src/utils/BrandMapper'

import styles from './ProductInfo.module.scss'

interface Props {
    product: Product | ConfigurableProduct
}

const ProductInfoHeader = ({ product }: Props) => (
    <>
        <header className={styles.header}>
            <div className={styles.nameContainer}>
                <Heading
                    element="h1"
                    color="dark"
                    variant="h2"
                    className={styles.name}
                >
                    {product.name}
                </Heading>
            </div>
            <div className={styles.brandContainer}>
                <img
                    src={BrandMapper(product.brand)?.logo}
                    alt={BrandMapper(product.brand)?.prettyName}
                    className={styles.brand}
                />
            </div>
        </header>
    </>
)

export default ProductInfoHeader
