import { useBreakpoints } from '@emico/ui'

import { renderHeroContent } from './cms-pages/HomePage/HomePageLayout/HomePageLayout'
import { PageBodyProductHeroPrimary } from './graphql/schema.generated'
import Container from './layout/Container'
import styles from './PrismicProductHero.module.scss'

const PrismicProductHero = (props: PageBodyProductHeroPrimary) => {
    const { isDesktop } = useBreakpoints()

    const { videoId, mobileImage, mobileVideoId, image } = props

    if (isDesktop && !videoId && !image?.url) {
        return null
    }
    if (!isDesktop && !mobileVideoId && !mobileImage?.url) {
        return null
    }

    return (
        <Container noPadding fluid className={styles.container}>
            {renderHeroContent({
                ...props,
                image: isDesktop ? image : mobileImage,
                videoId: isDesktop ? videoId : mobileVideoId,
            })}
        </Container>
    )
}

export default PrismicProductHero
