import { ComponentProps } from 'react'

import {
    CastironPageBodyProductHero,
    JbfoPageBodyProductVideoHero,
    JustbrandsPageBodyProductHero,
    MonstersalePageBodyProductVideoHero,
    PageBodyProductHero,
    PmeOutletPageBodyProductHero,
    VanguardPageBodyProductHero,
} from './graphql/schema.generated'
import PrismicProductHero from './PrismicProductHero'

type PrismicSliceProductHeroType =
    | PageBodyProductHero
    | CastironPageBodyProductHero
    | VanguardPageBodyProductHero
    | JustbrandsPageBodyProductHero
    | JbfoPageBodyProductVideoHero
    | MonstersalePageBodyProductVideoHero
    | PmeOutletPageBodyProductHero

interface Props {
    slice: PrismicSliceProductHeroType
}

const PrismicSliceProductHero = ({ slice: { primary } }: Props) => {
    if (!primary) {
        return null
    }

    const {
        image,
        mobileImage,
        mobileVideoId,
        content,
        buttonUrl,
        button2Url,
        buttonText,
        button2Text,
        subheading,
        buttonStyle,
        videoId,
        textStyle,
        productSkus,
        productCueTimes,
        subheadingStyle,
    } = primary

    const componentProps: ComponentProps<typeof PrismicProductHero> = {
        image,
        mobileImage,
        content,
        buttonUrl,
        button2Url,
        buttonText,
        button2Text,
        subheading,
        subheadingStyle,
        buttonStyle,
        textStyle,
        videoId: videoId ? `https://player.vimeo.com/video/${videoId}` : null,
        mobileVideoId: mobileVideoId
            ? `https://player.vimeo.com/video/${mobileVideoId}`
            : null,
        productSkus,
        productCueTimes,
    }

    return <PrismicProductHero {...componentProps} />
}

export default PrismicSliceProductHero
