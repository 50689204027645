import timeObjectFromString from '../timeObjectFromString'

function compareHours(open: string, close: string, now: Date) {
    const storeOpens = timeObjectFromString(open)
    const storeCloses = timeObjectFromString(close)

    // Convert the values to timestamps using the Date.prototype.getTime() method
    const nowTimestamp = now.getTime()
    const storeOpensTimestamp = storeOpens.getTime()
    const storeClosesTimestamp = storeCloses.getTime()

    // Compare the timestamps using the ? : operator
    return (
        nowTimestamp >= storeOpensTimestamp &&
        nowTimestamp < storeClosesTimestamp
    )
}

export default compareHours
