import {
    Children,
    isValidElement,
    Fragment,
    cloneElement,
    ReactNode,
} from 'react'

import BlueConicComponent, {
    Props as BlueConicComponentProps,
} from './BlueConicComponent'
import styles from './BlueConicContent.module.scss'
import ErrorBoundary from './ErrorBoundary'

type Props = {
    children?: React.ReactElement
    extraContent?: ReactNode
} & Partial<Pick<BlueConicComponentProps, 'blueConicPosition'>>

const BlueConicContent = ({
    blueConicPosition,
    children,
    extraContent,
}: Props) => {
    const childrenWithProps = Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        // Check for a blueconic position. If it is set enable blueconic takeover.
        if (isValidElement(child) && blueConicPosition) {
            return (
                <ErrorBoundary fallback={<Fragment />}>
                    <BlueConicComponent
                        blueConicPosition={blueConicPosition}
                        fallBack={child}
                        // Pass down all props of the child to the blueconic component
                        {...(child.props ?? {})}
                        // including data attribute
                    />
                </ErrorBoundary>
            )
        }
        return child
    })
    return (
        <div className={styles.target} data-bc-position={blueConicPosition}>
            {extraContent}
            {childrenWithProps}
        </div>
    )
}

export default BlueConicContent
