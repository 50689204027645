import ciDefault from './images/ciDefault.png'
import jbDefault from './images/jbDefault.png'
import pmeDefault from './images/pmeDefault.png'
import { SrsStore } from './useSrsStore'
import absolutify from '../utils/url/absolutify'

const defaultImageUrl = (store: string) => {
    if (store === 'pme') {
        return absolutify(pmeDefault)
    }
    if (store === 'castiron') {
        return absolutify(ciDefault)
    }
    if (store === 'justbrands') {
        return absolutify(jbDefault)
    }
    return ''
}

const storeImageUrl = (
    store: SrsStore,
    storeView: string,
    mobile: boolean = false,
) => {
    const storeImageUrl = mobile ? store.imageUrlMobile : store.imageUrl

    return storeImageUrl
        ? `https://stores.justbrands.eu/${storeImageUrl}`
        : store.shopId
          ? `https://stores.justbrands.eu/assets/images/${store.shopId}-desktop.jpg`
          : defaultImageUrl(storeView)
}

export default storeImageUrl
