import * as React from 'react'

import DesktopBackgroundImage from './NotFoundBackgroundDesktop.jpg'
import MobileBackgroundImage from './NotFoundBackgroundMobile.jpg'
import TabletBackgroundImage from './NotFoundBackgroundTablet.jpg'
import HeroImage from '../cms-pages/HomePage/HeroImage'
import { Responsive } from '../core/Responsive'
import overridable from '../utils/overridable'
import absolutify from '../utils/url/absolutify'

const NotFoundBackgroundImages = () => (
    <>
        <Responsive lg up>
            <HeroImage url={absolutify(DesktopBackgroundImage)} />
        </Responsive>
        <Responsive md only>
            <HeroImage url={absolutify(TabletBackgroundImage)} />
        </Responsive>
        <Responsive sm xs>
            <HeroImage url={absolutify(MobileBackgroundImage)} />
        </Responsive>
    </>
)

export default overridable('NotFoundBackgroundImages')(NotFoundBackgroundImages)
