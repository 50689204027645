import styled from '@emotion/styled'
import * as React from 'react'

import styles from './LayoutTop.module.scss'
import BlueConicContent from '../../../BlueConicContent'
import { COLORSWATCH_PDP_ENABLED } from '../../../constants'
import getProductLabelInfo from '../../../getProductLabelInfo'
import Col from '../../../layout/Col'
import Row from '../../../layout/Row'
import { RelatedProduct } from '../../../ProductCardFragment'
import ShopTheLookCard from '../../../ShopTheLookCard'
import Heading from '../../../typography/Heading'
import { filterNullValues } from '../../../utils/filterNullValues'
import getProductStockInfo from '../../../utils/getProductStockInfo'
import ColorSwatches from '../ColorSwatches'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { GroupedProduct, isGroupedProduct, Product } from '../GetProduct.query'
import ImageSlider from '../ImageSlider'
import ProductInfoForm from '../ProductInfo'
import OutOfStockInfo from '../ProductInfo/OutOfStockInfo'

const ShopTheLookHeading = styled(Heading)`
    text-align: center;
    margin-bottom: 28px;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
`

const ShopTheLookWrapper = styled.div`
    padding-top: 32px;

    @media screen and (min-width: 768px) {
        padding-top: 0;
    }
`

export interface Props {
    product?: Product | GroupedProduct
    hideStickyCta: (value: boolean) => void
}

export const getInStockProductsForGroupedProduct = (product: GroupedProduct) =>
    // Check for valid / out of stock products
    (filterNullValues(product.items) || []).reduce<
        Array<Product | ConfigurableProduct>
    >((acc, item) => {
        if (item.product) {
            const { isOutOfStock } = getProductStockInfo(item.product)

            if (!isOutOfStock) {
                acc.push(item.product)
            }
        }
        return acc
    }, [])

const ShopTheLookProducts = ({ product }: { product: GroupedProduct }) => {
    const products = getInStockProductsForGroupedProduct(product)

    // No products or all out of stock
    if (products.length === 0) {
        return <OutOfStockInfo product={product} />
    }

    return (
        <>
            {products.map((product) => (
                <ShopTheLookCard key={product.id} product={product} />
            ))}
        </>
    )
}

const LayoutTop = ({ product, hideStickyCta }: Props) => {
    let products: RelatedProduct[] = []
    let labelInfo

    if (product) {
        const {
            id,
            sku,
            hexColor,
            smallImage,
            modelImage,
            urlKey,
            stockStatus,
        } = product

        products = [
            {
                id,
                sku,
                hexColor,
                smallImage,
                modelImage,
                urlKey,
                stockStatus,
            },
            ...(product.relatedProducts ?? []),
        ]
        labelInfo = getProductLabelInfo(product)
    }

    return (
        <Row className={styles.default}>
            <Col className={styles.colWrapper}>
                <div className={styles.leftCol}>
                    <div className={styles.fixedWrapper}>
                        {product && (
                            <ImageSlider
                                title={product.name}
                                smallImage={product.smallImage}
                                mediaGalleryEntries={
                                    product.mediaGalleryEntries
                                }
                                labelText={labelInfo?.text}
                                labelType={labelInfo?.type}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.rightColWrapper}>
                    <div className={styles.rightCol}>
                        {product && !isGroupedProduct(product) && (
                            <ProductInfoForm
                                product={product}
                                hideStickyCta={hideStickyCta}
                            >
                                {COLORSWATCH_PDP_ENABLED && (
                                    <ColorSwatches
                                        productId={product.id}
                                        products={products}
                                    />
                                )}
                                <BlueConicContent blueConicPosition="pdpSizeText">
                                    <div />
                                </BlueConicContent>
                            </ProductInfoForm>
                        )}
                        {product && isGroupedProduct(product) && (
                            <ShopTheLookWrapper>
                                <ShopTheLookHeading
                                    element="h1"
                                    color="dark"
                                    variant="h2"
                                >
                                    {product.name}
                                </ShopTheLookHeading>

                                <ShopTheLookProducts product={product} />
                            </ShopTheLookWrapper>
                        )}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default LayoutTop
