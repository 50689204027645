import { ConfigurableAttributeOption } from '../common/MinimalConfigurableProductInfo.fragment'

type AttributesWithCode = Pick<ConfigurableAttributeOption, 'code'>

export default function getAttributeIndex(
    attributes: AttributesWithCode[],
    code: string,
) {
    for (let i = 0; i < attributes.length; i += 1) {
        if (attributes[i].code === code) {
            return i
        }
    }

    return -1
}
