import { Component, ReactNode } from 'react'
import { connect } from 'react-redux'

import { fetchCoreConfig } from './actions'
import { State } from './reducers'

interface DispatchProps {
    fetchCoreConfig(): void
}
type Props = DispatchProps & {
    children: ReactNode
}

class AutoLoader extends Component<Props> {
    componentDidMount(): void {
        this.load()
    }

    load() {
        const { fetchCoreConfig } = this.props

        Promise.resolve(fetchCoreConfig())
    }

    render() {
        return this.props.children
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, DispatchProps, {}, State>(null, {
    fetchCoreConfig,
})(AutoLoader)
