import cx from 'classnames'
import * as React from 'react'

import styles from './Nav.module.scss'

enum JustifyClasses {
    left = 'left',
    right = 'right',
    center = 'center',
    spaceBetween = 'spaceBetween',
}

export interface Props {
    /**
     * Justify the contents within the container
     * @default "left"
     */
    justify?: keyof typeof JustifyClasses
    className?: string

    /**
     * NavLink items
     */
    children: React.ReactNode
}

const Nav = (props: Props) => {
    const { justify = JustifyClasses.left, children, className } = props

    return (
        <nav>
            <ul className={cx(styles.base, styles[justify], className)}>
                {React.Children.map(children, (child, index) => (
                    <li key={`nav-item-${index}`}>{child}</li>
                ))}
            </ul>
        </nav>
    )
}

export default Nav
