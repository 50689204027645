import { ReactNode } from 'react'

import { ComponentMapping } from '@emico/prismic'

import TextColor from './types/TextColor'
import Text from './typography/Text'

export const getPrismicComponents = ({
    style,
    contentClassName,
}: {
    style?: TextColor
    contentClassName?: string
}): ComponentMapping => ({
    paragraph: ({ children, key }) => (
        <Text color={style} className={contentClassName} key={key}>
            {children}
        </Text>
    ),
})

const htmlSerializer =
    ({
        style = TextColor.dark,
        contentClassName,
    }: {
        style?: TextColor
        contentClassName?: string
        // This is not a react component, but a prismic html serializer
        // eslint-disable-next-line react/display-name
    }) =>
    // eslint-disable-next-line react/display-name
    (
        type: unknown,
        element: ReactNode,
        content: string,
        children: ReactNode[],
        key: string,
    ) => {
        switch (type) {
            // case 'hyperlink':
            //     return linkResolver({ analyticsContext: 'prismicLink' })(
            //         type,
            //         element,
            //         content,
            //         children,
            //         key,
            //     )
            case 'paragraph':
                return (
                    <Text color={style} className={contentClassName} key={key}>
                        {children}
                    </Text>
                )
        }

        return null
    }

export default htmlSerializer
