import { usePushEcommerceEvent } from './usePushEvent'

export function useSocialButtonClickEvent() {
    const push = usePushEcommerceEvent([], false)

    function pushButtonClickEvent(
        position: string,
        type: 'whatsapp' | 'bel' | 'mail' | 'chat',
    ) {
        // Asuming that a user does not click faster than the required data is loaded
        push?.([
            { ecommerce: null },
            {
                event: 'button_click',
                button_category: 'contact opties',
                button_position: position,
                button_text: type,
            },
        ])
    }

    return pushButtonClickEvent
}
