import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
    TweakwiseNavigationFacet,
    TweakwiseNavigationParams,
    TweakwiseNavigationProperties,
    TweakwiseNavigationRedirect,
} from '@emico/graphql-schema-types'

import { ConfigurableAttributesFieldValue } from './catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { useRootCategoryId } from './catalog/useRootCategory'
import { PrismicDocument } from './graphql/schema.generated'
import productCardFragment, { ProductCardFragment } from './ProductCardFragment'
import useTweakwiseItemNo from './useTweakwiseItemNo'

const query = gql`
    query (
        $query: String
        $categoryId: [ID!]
        $page: Int!
        $pageSize: Int!
        $facets: [TweakwiseNavigationFacetFilter!]
        $sortKey: String
        $filterTemplateId: Int
        $sortTemplateId: Int
    ) {
        tweakwiseNavigation(
            params: {
                query: $query
                categoryId: $categoryId
                page: $page
                pageSize: $pageSize
                facets: $facets
                sortKey: $sortKey
                filterTemplateId: $filterTemplateId
                sortTemplateId: $sortTemplateId
            }
        ) {
            items {
                itemNo
                product {
                    ...ProductCardFragmentJB
                }
                prismicDocument {
                    data
                }
            }
            redirects {
                url
            }
            properties {
                nrOfItems
                pageSize
                nrOfPages
                currentPage
                sortFields {
                    title
                    displayTitle
                    order
                    isSelected
                    url
                }
            }
            facets {
                attributes {
                    url
                    title
                    isSelected
                    nrOfResults
                }
                facetSettings {
                    title
                    urlKey
                    source
                    attributeName
                    isCollapsed
                    isCollapsible
                    selectionType
                    nrOfShownAttributes
                    isNrOfResultsVisible
                }
            }
        }
    }
    ${productCardFragment}
`

export interface CustomTweakwiseNavigationProperties {
    currentPage: number
    nrOfItems: number
    nrOfPages: number
    pageSize: number
}

export interface CustomTweakwiseNavigationItem {
    itemNo: string
    product?: ProductCardFragment
    prismicDocument?: PrismicDocument
    initialValue?: ConfigurableAttributesFieldValue
}

export interface CustomTweakwiseNavigationResult {
    items: CustomTweakwiseNavigationItem[]
    facets: TweakwiseNavigationFacet[]
    properties: TweakwiseNavigationProperties
    redirects: TweakwiseNavigationRedirect[]
}

export interface TweakwiseNavigationQueryResult {
    tweakwiseNavigation: CustomTweakwiseNavigationResult
}

export interface TweakwiseNavigationArgs {
    query?: string
    categoryId?: number | string
    page: number
    pageSize: number
    facets: TweakwiseNavigationParams['facets']
    sortKey?: string
    filterTemplateId?: number
    sortTemplateId?: number
}

interface TweakwiseNavigationByCategory extends TweakwiseNavigationArgs {
    categoryId: number | string
}

interface TweakwiseNavigationByQuery extends TweakwiseNavigationArgs {
    query: string
}

type TweakwiseNavigationVariables =
    | TweakwiseNavigationByCategory
    | TweakwiseNavigationByQuery

const useTweakwiseNavigation = (
    variables: TweakwiseNavigationVariables,
    options: QueryHookOptions<TweakwiseNavigationQueryResult> = {},
) => {
    const rootCategoryId = useRootCategoryId()
    // The root category always needs to be provided.
    // If not duplicate products from other storeviews will be returned.
    const categoryId = useTweakwiseItemNo(
        variables.categoryId ?? rootCategoryId,
    )

    const { data, error, ...others } = useQuery<TweakwiseNavigationQueryResult>(
        query,
        {
            ...options,
            variables: {
                ...variables,
                categoryId,
            },
            context: getCacheableContext(),
            errorPolicy: 'ignore',
        },
    )

    if (error) {
        throw error
    }

    return {
        data: data?.tweakwiseNavigation,
        error,
        ...others,
    }
}

export default useTweakwiseNavigation
