import { usePrismicDocument } from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'

import {
    FooterCastiron,
    FooterJbfo,
    FooterJustbrands,
    FooterMonstersale,
    FooterPme,
    FooterPmeoutlet,
    FooterVanguard,
} from '../../../graphql/schema.generated'

export type PrismicFooter =
    | FooterPme
    | FooterJustbrands
    | FooterCastiron
    | FooterVanguard
    | FooterJbfo
    | FooterPmeoutlet
    | FooterMonstersale

const usePrismicFooter = () => {
    // Determine page template based on the current store view
    // The value of ID in `my.<ID>.<URL IDENTIFIER>` has to match with
    // a page type in prismic.
    // The value of URL IDENTIFIER has to match with a field, defined on the page type.
    const storeview = useActiveStoreView()
    const identifier = 'url_key'
    let pageTemplate = ''

    switch (storeview.prismic) {
        case 'pme':
            pageTemplate = 'footer_pme'
            break
        case 'justbrands':
            pageTemplate = 'footer_justbrands'
            break
        case 'castiron':
            pageTemplate = 'footer_castiron'
            break
        case 'vanguard':
            pageTemplate = 'footer_vanguard'
            break
        case 'jbfo':
            pageTemplate = 'footer_jbfo'
            break
        case 'pme_outlet':
            pageTemplate = 'footer_pmeoutlet'
            break
        case 'monstersale':
            pageTemplate = 'footer_monstersale'
            break
        default:
            pageTemplate = ''
    }
    // if (!pageTemplate) {
    //     return { data: null }
    // }
    return usePrismicDocument<PrismicFooter>(
        {
            key: `my.${pageTemplate}.${identifier}`,
            value: `${storeview.prismic}-footer`,
        },
        false,
        {
            skip: !pageTemplate,
        },
    )
}

export default usePrismicFooter
