import { FormValues as InformedFormValues } from 'informed'
import * as React from 'react'

import Select from './Select'
import { Props as InputProps, useInformedInput } from '../Input'

interface OwnProps {
    transparent?: boolean
}

export type Props<FormValue, FormValues extends InformedFormValues> = OwnProps &
    InputProps<FormValue, FormValues>

const InformedSelect = <
    FormValue = string | number,
    // eslint-disable-next-line @typescript-eslint/ban-types
    FormValues extends InformedFormValues = {},
>({
    className,
    inputClassName,
    transparent = false,
    validateOnChange = true,
    field,
    ...otherProps
}: Props<FormValue, FormValues>) => {
    const { render, hasError, isValid, inputProps, isTouched, errorMessage } =
        useInformedInput<FormValue, FormValues>({
            ...otherProps,
            validateOnChange,
            field,
        })

    return render(
        <Select
            error={hasError && isTouched && errorMessage}
            isValid={isValid}
            className={className}
            inputClassName={inputClassName}
            transparent={transparent}
            // TODO: ref={ref}
            {...inputProps}
        />,
    )
}

export default InformedSelect
