import { FullWidthMedia } from '@emico-react/full-width-media'
import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { minWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'

import CloseIcon from '../../../core/CloseIcon'
import { MaveClip } from '../../../mave/MaveClip'
import Icon from '../../../media/Icon'
import FullscreenNavigationModal from '../../../presentation/FullscreenNavigationModal/FullscreenNavigationModal'
import ProductLabel from '../../../presentation/ProductLabel'
import { MediaGalleryEntry } from '../../../ProductCardFragment'

interface Props {
    images: MediaGalleryEntry[]
    title: string
    labelType?: string
    labelText?: string
}

const Grid = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @media ${minWidth('lg')} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const LookImage = styled(Image)`
    aspect-ratio: 1;
    object-fit: cover;
`

const StyledButton = styled(ButtonUnstyled)`
    position: relative;
`

const StyledProductLabel = styled(ProductLabel)`
    position: absolute;
    bottom: 0;
    left: 0;
`

const ImageSliderDesktop = ({ images, title, labelType, labelText }: Props) => {
    const imageCount = images.length
    const desktopSize = imageCount === 1 ? 1092 : 536
    const [zoomImage, setZoomImage] = useState<false | number>(false)

    const imageList = images.map((image, index) => {
        const isVideo = image.mave !== null

        return (
            <StyledButton
                key={`image-${image.file.replace(/^.*(\\|\/|:)/, '')}`}
                analyticsContext="productPage"
                analyticsName="imageZoom"
                onClick={isVideo ? undefined : () => setZoomImage(index)}
            >
                {index === 0 && labelType && labelText && (
                    <StyledProductLabel variant={labelType} text={labelText} />
                )}
                {isVideo ? (
                    <MaveClip embed={image.mave?.id ?? ''} loop />
                ) : (
                    <LookImage
                        url={image.file}
                        alt={image.label ?? ''}
                        lazy
                        sizes={{
                            992: desktopSize,
                            768: 520,
                            1: 767,
                        }}
                        width={520}
                        height={520}
                    />
                )}
            </StyledButton>
        )
    })

    const handleBack = useCallback(() => {
        setZoomImage(false)
    }, [setZoomImage])

    return (
        <>
            {zoomImage !== false && (
                <FullscreenNavigationModal
                    back={
                        <Icon
                            component={CloseIcon}
                            color="dark"
                            title={t({
                                id: 'presentation.responsiveModal.back',
                                message: `close`,
                            })}
                        />
                    }
                    onBack={handleBack}
                    title={title}
                >
                    <FullWidthMedia
                        media={images
                            .filter((img) => !img.mave)
                            .map((item) => ({
                                alt: item.label ?? '',
                                width: 1600,
                                height: 1600,
                                url: item.file,
                            }))}
                        currentItem={zoomImage}
                        containScrolling
                    />
                </FullscreenNavigationModal>
            )}
            {images.length > 1 ? <Grid>{imageList}</Grid> : <>{imageList}</>}
        </>
    )
}

export default ImageSliderDesktop
