import * as React from 'react'
import { Helmet } from 'react-helmet'

import { usePreloadLazyComponent } from '@emico/lazy'

import styles from './HomePageLayout.module.scss'
import BlueConicContent from '../../../BlueConicContent'
import { LazyCategoryPage } from '../../../chunks'
import ErrorBoundary from '../../../ErrorBoundary'
import FatalErrorPage from '../../../FatalErrorPage'
import { PageBodyProductHeroPrimary } from '../../../graphql/schema.generated'
import Container from '../../../layout/Container'
import RecommendedProducts from '../../../RecommendedProducts'
import RecommendedProductsTweakwise from '../../../RecommendedProducts/RecommendedProductsTweakwise'
import { CmsPageHomepage } from '../../../types/CmsPages'
import Categories from '../Categories'
import Hero from '../Hero'
import HeroVideo from '../HeroVideo'
import { HeroShopableVideoProduct } from '../HeroVideo/HeroVideo'
import Looks from '../Looks'
import Stories from '../Stories'

export const renderHeroContent = (hero: PageBodyProductHeroPrimary) => {
    const {
        content,
        buttonUrl,
        buttonText,
        button2Url,
        button2Text,
        buttonStyle,
        textStyle,
        subheading,
        subheadingStyle,
        videoId,
        image,
        productCueTimes,
        productSkus,
    } = hero

    if (videoId) {
        let products: HeroShopableVideoProduct[] | undefined

        if (productSkus && productCueTimes) {
            const skus = productSkus.split(',')
            const cuePoints = productCueTimes.split(',')

            // Only use products when a cuepoint is entered for each product
            if (skus.length > 0 && skus.length === cuePoints.length) {
                products = skus.map((sku, index) => ({
                    sku: sku.trim(),
                    cuePoint: parseInt(cuePoints[index].trim(), 10),
                }))
            }
        }

        return (
            <ErrorBoundary fallback={<React.Fragment />}>
                <HeroVideo
                    buttonStyle={buttonStyle}
                    subheading={subheading}
                    videoId={videoId}
                    content={content}
                    buttonUrl={buttonUrl}
                    button2Url={button2Url}
                    textStyle={textStyle}
                    buttonText={buttonText}
                    button2Text={button2Text}
                    subheadingStyle={subheadingStyle}
                    products={products}
                />
            </ErrorBoundary>
        )
    }

    if (image?.url) {
        return <Hero {...hero} className={styles.hero} />
    }

    return null
}

const HomePageLayout = ({
    hero,
    sale,
    categories,
    discoverLooks,
    latestArrivals,
    stories,
}: Omit<CmsPageHomepage, 'title' | 'urlKey'>) => {
    // Prefetch chunks that will likely be viewed next
    // This prevents the white flashes that appear between page navigations
    // because the browser has to load the chunk first
    usePreloadLazyComponent(LazyCategoryPage)

    return (
        <div data-cy="HomePageLayout" className={styles.default}>
            <Helmet>
                {process.env.REACT_APP_HIGHSTREET_STORE_NAME && (
                    <meta
                        property="al:ios:url"
                        content={`${process.env.REACT_APP_HIGHSTREET_STORE_NAME}://home`}
                    />
                )}
            </Helmet>
            {hero && (
                <Container noPadding fluid>
                    {renderHeroContent({
                        ...hero,
                        button2Url: null,
                        button2Text: null,
                        videoId: hero.video,
                        image: {
                            url: hero.image?.url || '',
                            dimensions: { width: 0, height: 0 },
                            alt: null,
                        },
                        mobileImage: {
                            url: hero.image?.url || '',
                            dimensions: { width: 0, height: 0 },
                            alt: null,
                        },
                        mobileVideoId: hero.video,
                    })}
                </Container>
            )}

            <div id="featured">
                {sale && sale.header && sale.recommendationsIdentifier && (
                    <ErrorBoundary
                        fallback={<FatalErrorPage type="component" />}
                    >
                        <BlueConicContent blueConicPosition="homePageSlider">
                            <RecommendedProductsTweakwise
                                header={sale.header}
                                content={sale.content || undefined}
                                buttonText={sale.buttonText || undefined}
                                buttonUrl={sale.buttonUrl || undefined}
                                templateId={sale.recommendationsIdentifier}
                                className={styles.sale}
                                headingClassName={styles.heading}
                                contentClassName={styles.content}
                                contentInnerClassName={styles.contentInner}
                                itemListSlot="homePageSlider"
                            />
                        </BlueConicContent>
                    </ErrorBoundary>
                )}

                {categories?.list?.length && (
                    <div className={styles.categoriesWrapper}>
                        <Container noPadding>
                            <Categories
                                {...categories}
                                headingClassName={styles.heading}
                                contentClassName={styles.content}
                                contentInnerClassName={styles.contentInner}
                            />
                        </Container>
                    </div>
                )}

                <Container noPadding>
                    {discoverLooks?.list?.length && (
                        <Container noPadding>
                            <Looks
                                {...discoverLooks}
                                headingClassName={styles.heading}
                                contentClassName={styles.content}
                                contentInnerClassName={styles.contentInner}
                            />
                        </Container>
                    )}
                </Container>

                {latestArrivals &&
                    latestArrivals.header &&
                    latestArrivals.recommendationsIdentifier && (
                        <ErrorBoundary
                            fallback={<FatalErrorPage type="component" />}
                        >
                            <div className={styles.arrivalsWrapper}>
                                <RecommendedProducts
                                    header={latestArrivals.header}
                                    content={
                                        latestArrivals.content || undefined
                                    }
                                    buttonText={
                                        latestArrivals.buttonText || undefined
                                    }
                                    buttonUrl={
                                        latestArrivals.buttonUrl || undefined
                                    }
                                    templateId={
                                        latestArrivals.recommendationsIdentifier
                                    }
                                    className={styles.latestArrivals}
                                    headingClassName={styles.heading}
                                    contentClassName={styles.content}
                                    contentInnerClassName={styles.contentInner}
                                    itemListSlot="homePageArrivals"
                                />
                            </div>
                        </ErrorBoundary>
                    )}

                {stories && stories.list && stories.list.length && (
                    <div className={styles.storiesWrapper}>
                        <Container noPadding>
                            <Stories
                                {...stories}
                                headingClassName={styles.heading}
                                contentClassName={styles.content}
                                contentInnerClassName={styles.contentInner}
                            />
                        </Container>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HomePageLayout
