/* eslint-disable no-console */
/* eslint-disable import/order */
import ReactDOM from 'react-dom/client'

import {
    registerPrelaunchJob,
    waitForPrelaunchJobs,
} from '@emico/prelaunch-jobs'
// import { serviceWorkerRegistration } from '@emico/service-worker-webapp'
import {
    getActiveStoreView,
    getStoreLanguage,
    StoreView,
} from '@emico/storeviews'

import BaseApp from './App'
import { ComponentConfig } from './utils/ComponentOverridesContext'

// We need to import chunks here so eagerLazy is initialized and starts
// preloading chunks that are needed for the initial load as determined by prerendering.
import './chunks'
import { initializeI18n } from './utils/i18n'

import { Messages } from '@lingui/core'

export const ROOT_ELEMENT_ID = 'root'

/**
 * Use this method to initialize and start the SPA for a store.
 *
 * This could probably use more documentation, but I can't think of anything
 * that needs explaining.
 */
const launchStore = async ({
    storeViews,
    loadCatalog,
    preloadFonts,
    componentsConfig,
    App = BaseApp,
}: {
    storeViews: StoreView[]

    loadCatalog: (language: string) => Promise<Messages>
    preloadFonts: string[]
    componentsConfig: ComponentConfig
    App?: typeof BaseApp
}) => {
    const activeStoreView = getActiveStoreView(storeViews, window.location)

    // See the i18n.md for @emico/ui why delaying the App mounting makes sense.
    registerPrelaunchJob(
        'i18n',
        initializeI18n(getStoreLanguage(activeStoreView), loadCatalog),
    )

    // Wait for the application to be ready. This will await all running
    // Promises that are needed during application start-up. This is necessary
    // for server-side rendered snapshots to work without loading indicators
    // or other white flashes.
    await waitForPrelaunchJobs()

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = document.getElementById(ROOT_ELEMENT_ID)!

    if (process.env.REACT_APP_DEBUG_PRERENDERING) {
        // TODO: Add prerendering Cypress tests from New Retail Project to test this automatically instead
        const appHtmlLength = root.innerHTML.length

        console.log(
            'DEBUG_SSR',
            'React app HTML length before initial render:',
            appHtmlLength,
        )
    }

    root.removeAttribute('data-prerender')

    // Never use hydrate here, this will break the product modal when opening a
    // category page via a snapshot
    ReactDOM.createRoot(root).render(
        <App
            storeViews={storeViews}
            activeStoreView={activeStoreView}
            preloadFonts={preloadFonts}
            componentsConfig={componentsConfig}
        />,
    )

    // if (process.env.NODE_ENV === 'production') {
    // serviceWorkerRegistration.register()
    // }

    if (process.env.REACT_APP_DEBUG_PRERENDERING) {
        const appHtmlLength = root.innerHTML.length

        console.log(
            'DEBUG_SSR',
            'React app HTML length after initial render:',
            appHtmlLength,
            "This should be similar to the previous number (ideally it's equal).",
        )
        if (appHtmlLength === 0) {
            console.error(
                'DEBUG_SSR',
                'The React app HTML disappeared! This will trigger a white flash during SSR and should be fixed. This is likely caused by a provider returning `null` while loading.',
            )
        }
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    // serviceWorker.unregister()
}

export default launchStore
