import cx from 'classnames'
import { ReactNode } from 'react'

import styles from './Badge.module.scss'
import overridable from '../../utils/overridable'

export const Badge = ({
    badgeClassName,
    wrapperClassName,
    children,
}: {
    wrapperClassName: string
    badgeClassName: string
    children: ReactNode
}) => (
    <div className={wrapperClassName}>
        <span className={cx(styles.badge, badgeClassName)}>{children}</span>
    </div>
)

export default overridable('Badge')(Badge)
