import { useGoogleMap } from '@react-google-maps/api'
import { ReactNode, useEffect } from 'react'

import { SrsStore } from './useSrsStore'

interface Props {
    selectedStore: SrsStore | undefined
    children: ReactNode
}

const StoreMarker = ({ selectedStore, children }: Props) => {
    const map = useGoogleMap()

    const lat: number = selectedStore?.gLat ? selectedStore.gLat : 0
    const lng: number = selectedStore?.gLong ? selectedStore.gLong : 0

    useEffect(() => {
        if (map && lat > 0) {
            map.panTo({ lat, lng })
        }
    }, [map, lat, lng])

    return <div>{children}</div>
}

export default StoreMarker
