import { default as useReactCollapsed } from 'react-collapsed'

interface Options {
    /**
     * If true, the Collapse is expanded
     * @default undefined
     */
    isOpen?: boolean

    /**
     * If true, the Collapse will be expanded when mounted
     * @default false
     */
    defaultOpen?: boolean

    /**
     * Style object applied to the collapse panel when it expands
     * @default {transitionDuration: '250ms' transitionTimingFunction: 'cubic-bezier(0.17, 0.67, 0.83, 0.67)'}
     */
    expandStyles?: React.CSSProperties

    /**
     * Style object applied to the collapse panel when it collapses
     * @default {transitionDuration: '250ms' transitionTimingFunction: 'cubic-bezier(0.17, 0.67, 0.83, 0.67)'}
     */
    collapseStyles?: React.CSSProperties
}

const useCollapse = (options: Options) => {
    const {
        getCollapseProps,
        getToggleProps,
        isOpen,
        toggleOpen,
        mountChildren,
    } = useReactCollapsed({
        expandStyles: {
            transitionDuration: '150ms',
            transitionTimingFunction: 'cubic-bezier(0.17, 0.67, 0.83, 0.67)',
        },
        collapseStyles: {
            transitionDuration: '150ms',
            transitionTimingFunction: 'cubic-bezier(0.17, 0.67, 0.83, 0.67)',
        },
        defaultOpen: false,
        isOpen: undefined,
        ...options,
    })

    return [getCollapseProps, getToggleProps, isOpen, toggleOpen, mountChildren]
}

export default useCollapse
