import { Picture } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import cx from 'classnames'

import { minWidth } from '@emico/styles'

import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import { LayoutStyle } from './LayoutStyle'
import theme from './theme'
import breakpoints from './theme/breakpoints'
import { varKeys as colorVarKeys } from './theme/colors'
import styles from './Twoimageswithheading.module.scss'
import Heading from './typography/Heading'

const defaultImageSizes = {
    regularWidth: 1366,
    regularHeight: undefined,
    mediumWidth: 768,
    mediumHeight: undefined,
    smallWidth: 375,
    smallHeight: undefined,
}

export interface Props {
    style: LayoutStyle
    firstimage?: string
    firstmobileimage?: string
    secondimage?: string
    secondmobileimage?: string
    heading?: string
    imageSizes?: {
        regularWidth?: number
        regularHeight?: number
        mediumWidth?: number
        mediumHeight?: number
        smallWidth?: number
        smallHeight?: number
    }
}

const light = css`
    ${colorVarKeys.headings}: ${theme.colors.darkHeadings};
    ${colorVarKeys.text}: ${theme.colors.darkText};
    ${colorVarKeys.background}: ${theme.colors.lightBackground};
`

const dark = css`
    ${colorVarKeys.headings}: ${theme.colors.darkHeadings};
    ${colorVarKeys.text}: ${theme.colors.darkText};
    ${colorVarKeys.background}: ${theme.colors.darkBackground};
`

const StyledContainer = styled(Container)`
    background: ${theme.colors.background};
    position: relative;

    @media ${minWidth('md')} {
        margin-top: 90px;
        margin-bottom: 130px;
    }
`

const BackDrop = styled.div<{ layoutStyle: LayoutStyle }>`
    display: none;
    background: ${(props) =>
        props.layoutStyle === 'light'
            ? `var(${colorVarKeys.secondaryExtra}, ${colorVarKeys.primary})`
            : 'none'};

    @media ${minWidth('md')} {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 70%;
        height: 100%;
    }

    @media ${minWidth('lg')} {
        width: 65%;
    }
`

const ColorBackDropInner = styled.div<{ layoutStyle: LayoutStyle }>`
    position: relative;
    padding: 45px 20px 65px;
    background: ${(props) =>
        props.layoutStyle === 'light'
            ? `var(${colorVarKeys.secondaryExtra}, ${colorVarKeys.primary})`
            : 'none'};

    @media ${minWidth('md')} {
        padding: 0;
        background: none;
    }
`

const Twoimageswithheading = ({
    style,
    firstimage,
    firstmobileimage,
    secondimage,
    secondmobileimage,
    imageSizes = defaultImageSizes,
    heading,
}: Props) => {
    const layoutStyle = style === 'light' ? light : dark

    if (!heading || !firstimage || !secondimage) {
        return null
    }

    return (
        <StyledContainer css={layoutStyle}>
            <Row>
                <BackDrop layoutStyle={style} />

                {firstimage && (
                    <Col
                        xs={{ order: 1, span: 10, offset: 2 }}
                        md={{ order: 0, span: 6, offset: 0 }}
                        lg={{ order: 0, span: 6, offset: 0 }}
                        className={cx(styles.image, styles.imageOne)}
                    >
                        <Picture
                            breakpoints={{
                                [breakpoints.xs]: {
                                    url: firstmobileimage ?? firstimage,
                                    width: imageSizes.smallWidth ?? 0,
                                    height: imageSizes.smallHeight,
                                },
                                [breakpoints.md]: {
                                    url: firstimage ?? firstmobileimage,
                                    width: imageSizes.mediumWidth ?? 0,
                                    height: imageSizes.mediumHeight,
                                },
                                [breakpoints.lg]: {
                                    url: firstimage ?? firstmobileimage,
                                    width: imageSizes.regularWidth ?? 0,
                                    height: imageSizes.regularHeight,
                                },
                            }}
                            lazy
                            alt={heading ?? ''}
                        />
                    </Col>
                )}

                {secondimage && (
                    <Col
                        xs={{ order: 0, span: 12, offset: 0 }}
                        md={{ order: 1, span: 6, offset: 0 }}
                        lg={{ order: 1, span: 6, offset: 0 }}
                        className={cx(styles.image, styles.imageTwo)}
                    >
                        <ColorBackDropInner layoutStyle={style}>
                            <Picture
                                breakpoints={{
                                    [breakpoints.xs]: {
                                        url: secondmobileimage ?? secondimage,
                                        width: imageSizes.smallWidth ?? 0,
                                        height: imageSizes.smallHeight,
                                    },
                                    [breakpoints.md]: {
                                        url: secondimage ?? secondmobileimage,
                                        width: imageSizes.mediumWidth ?? 0,
                                        height: imageSizes.mediumHeight,
                                    },
                                    [breakpoints.lg]: {
                                        url: secondimage ?? secondmobileimage,
                                        width: imageSizes.regularWidth ?? 0,
                                        height: imageSizes.regularHeight,
                                    },
                                }}
                                lazy
                                alt={heading ?? ''}
                            />

                            {heading && (
                                <Heading
                                    variant="h2"
                                    element="h2"
                                    className={styles.heading}
                                    fuss={['vanguard']}
                                >
                                    {heading}
                                </Heading>
                            )}
                        </ColorBackDropInner>
                    </Col>
                )}
            </Row>
        </StyledContainer>
    )
}

export default Twoimageswithheading
