import {
    gql,
    QueryHookOptions,
    useApolloClient,
    useQuery,
} from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { useState } from 'react'

import {
    CustomerCoupon as GeneratedCustomerCoupon,
    Query,
} from '../graphql/schema.generated'
import { useFilterNullValues } from '../utils/filterNullValues'

export type CustomerCoupon = GeneratedCustomerCoupon & {
    image?: string
    value?: string | number | undefined
    __typename?: 'CustomerCoupon'
}

export const query = gql`
    query GetCustomerCoupons {
        customerCoupon {
            items {
                couponId
                amount {
                    currency
                    value
                }
                applied
                isActive
                code
                description
                type {
                    description
                    image
                    title
                }
                minimalOrderAmount {
                    currency
                    value
                }
                validUntil
            }
        }
    }
`

type QueryResult = Pick<Query, 'customerCoupon'>

export const useGetCustomerCoupons = (
    options?: QueryHookOptions<QueryResult>,
) => {
    const authorizationContext = useAuthorizationContext()
    const { data, error, loading, refetch } = useQuery<QueryResult>(query, {
        fetchPolicy: 'cache-and-network',
        ...options,
        context: authorizationContext,
    })

    return {
        data: useFilterNullValues(data?.customerCoupon?.items),
        loading,
        refetch,
        error,
    }
}

export const useLazyGetCustomerCoupons = () => {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()
    const [loading, setLoading] = useState(false)
    return {
        loading,
        getCustomerCoupons: async () => {
            setLoading(true)
            const { data } = await client.query({
                query,
                context: authorizationContext,
            })
            setLoading(false)
            return stripMaybes(data?.customerCoupon?.items)
        },
    }
}

export const useUnclaimedVouchersCount = (): number | undefined => {
    const { data: vouchers } = useGetCustomerCoupons()
    return vouchers?.reduce((sum, item) => sum + (item?.applied ? 0 : 1), 0)
}
