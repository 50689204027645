import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

// NOTE: This file is also parsed by an extract script. This script will generate a list of paths
// for Magento to correctly return 404 status codes.
// Please do keep this file simple, the extract script only parses some very basic JavaScript funtionallity
// in order to extract self references and generate translated routes.
const paths = {
    customer: '/customer',
    account: `/customer/account`,
    accountDetails: `/customer/account/details`,
    orderOverview: `/customer/account/my-orders`,
    orderOverviewDetails: () => `${paths.orderOverview}/:id`,
    login: '/customer/account/login',
    logout: '/customer/account/logout',
    register: '/customer/account/create',
    registerAddress: '/customer/account/create/address',
    forgotPassword: '/customer/account/forgotpassword',
    setPassword: '/customer/account/set-password',
    activateAccount: '/customer/account/activate',
    addressBook: '/customer/account/address-book',
    addressBookEdit: () => `${paths.addressBook}/edit/:id`,
    addressBookCreate: () => `${paths.addressBook}/create`,
    personalShopper: '/customer/account/personal-shopper',
    personalShopperOutfits: () => `${paths.personalShopper}/outfits`,
    justRewards: () =>
        `${paths.account}/${t({
            id: 'core.paths.justRewards',
            message: `just-rewards`,
        })}`,
    checkout: '/checkout',
    checkoutSuccess: '/checkout-success',
    checkoutDelivery: '/checkout-delivery',
    checkoutDetails: '/checkout-contact-details',
    checkoutPayment: '/checkout-payment',
    checkoutDeliveryWithOptionalCartId: '/checkout-delivery/:cartId?',
    checkoutDetailsWithOptionalCartId: '/checkout-contact-details/:cartId?',
    checkoutPaymentWithOptionalCartId: '/checkout-payment/:cartId?',
    checkoutPaymentPending: '/checkout-payment-pending',
    checkoutSuccessWithToken: '/checkout-success/:token',
    checkoutResetPassword: '/checkout-reset-password',
    home: '/',
    search: '/search',
    cart: '/cart',
    cartWithOptionalId: '/cart/:cartId?',
    shopAll: '/shop-all',
    stories: '/stories',
    storeLocator: '/storelocator',
    storeLocatorDetails: '/storelocator/:urlPath',
    wishlist: '/wishlist',
    prismicPreview: '/preview',
    privileges: '/pme-legend-privileges',
}

export default paths
