import Team from './cms-pages/JustBrandsHomePage/Team'
import {
    JbfoPageBodyTeam,
    JbfoPageBodyTeamFields,
    JustbrandsPageBodyTeam,
} from './graphql/schema.generated'
import styles from './PrismicSliceTeam.module.scss'
import { CmsPageTeamItem } from './types/CmsPages'

type PrismicSliceTeamType = JbfoPageBodyTeam | JustbrandsPageBodyTeam

interface Props {
    slice: PrismicSliceTeamType
}

const PrismicSliceTeam = ({ slice: { primary, fields } }: Props) => {
    if (!primary) {
        return null
    }

    const { subheading, heading } = primary

    const teamMembers: CmsPageTeamItem[] = (
        (fields as JbfoPageBodyTeamFields[]) ?? []
    ).reduce<CmsPageTeamItem[]>((acc, field) => {
        // Dont show items with no main image or no url configured
        if (
            field?.bigimage?.url &&
            field?.bigmobileimage?.url &&
            field?.portraitimage?.url &&
            field?.smallimage?.url
        ) {
            const {
                bigimage,
                bigmobileimage,
                firstname,
                fullname,
                heading,
                jobtitle,
                portraitimage,
                productid,
                quote,
                smallimage,
                subheading,
            } = field

            acc.push({
                bigImageMobile: {
                    url: bigmobileimage.url,
                },
                bigImage: {
                    url: bigimage.url,
                },
                portraitImage: {
                    url: portraitimage.url,
                },
                smallImage: {
                    url: smallimage.url,
                },
                firstName: firstname ?? '',
                fullName: fullname ?? '',
                heading: heading ?? '',
                subheading: subheading ?? '',
                jobTitle: jobtitle ?? '',
                productId: productid ? parseInt(productid, 10) : null,
                quote: quote ?? '',
            })
        }
        return acc
    }, [])

    return (
        <div className={styles.default}>
            <Team
                subheading={subheading}
                heading={heading}
                employees={teamMembers}
            />
        </div>
    )
}

export default PrismicSliceTeam
