import cx from 'classnames'
import * as React from 'react'

import styles from './FullscreenNavigationModal.module.scss'
import Button from '../../input/Button'
import Container from '../../layout/Container'
import useKeyPress from '../../useKeyPress'
import FullscreenModal from '../FullscreenModal/FullscreenModal'

export interface Props {
    back: React.ReactNode
    onBack?(): void
    title: React.ReactNode
    children: React.ReactNode
    centerTitle?: boolean
    className?: string
    contentClassName?: string
    footer?: React.ReactNode
}

const FullscreenNavigationModal = ({
    back,
    onBack,
    title,
    children,
    className,
    contentClassName,
    centerTitle,
    footer,
}: Props) => {
    const handleBack = React.useCallback(() => onBack && onBack(), [onBack])

    useKeyPress('Escape', handleBack)

    return (
        <FullscreenModal className={cx(styles.modal, className)}>
            <Container
                as="header"
                className={cx(styles.header, {
                    [styles.centerTitle]: centerTitle,
                })}
            >
                <div className={styles.title}>{title}</div>
                {back && onBack && (
                    <Button
                        variant="link"
                        className={styles.backButton}
                        onClick={onBack}
                        name="Back"
                        category="core.fullscreenNavigationModal.back"
                    >
                        {back}
                    </Button>
                )}
            </Container>
            <div className={cx(styles.content, contentClassName)}>
                {children}
            </div>
            {footer && (
                <Container as="footer" className={styles.footer}>
                    {footer}
                </Container>
            )}
        </FullscreenModal>
    )
}

export default FullscreenNavigationModal
