import AndroidIconDE from './android/DE'
import AndroidIconEN from './android/EN'
import AndroidIconNL from './android/NL'
import IosIconDE from './ios/DE'
import IosIconEN from './ios/EN'
import IosIconNL from './ios/NL'

export const getIosButtonByLang = (lang: string) => {
    switch (lang) {
        case 'nl':
            return IosIconNL
        case 'de':
            return IosIconDE
        case 'en':
            return IosIconEN
        default:
            return IosIconEN
    }
}
export const getAndroidButtonByLang = (lang: string) => {
    switch (lang) {
        case 'nl':
            return AndroidIconNL
        case 'de':
            return AndroidIconDE
        case 'en':
            return AndroidIconEN
        default:
            return AndroidIconEN
    }
}
