import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'
import Helmet from 'react-helmet'

import styles from './FatalErrorPage.module.scss'
import Button from '../input/Button'
import ExternalLink from '../navigation/ExternalLink'
import Heading from '../typography/Heading'
import Text from '../typography/Text'

const CONTACT_PAGE_URL = '/contact'

// This page can't use any of the fancy libraries such as react-router and
// Apollo since we want to use it as high up as possible to be able to catch
// all sorts of errors, in particular the most likely errors: request errors
// during Apollo calls.

// So we also can't use PageWrapper since that uses several Apollo queries.

// This is alright though, since we don't want the fatal error page to look too
// similar with any other page on the site. This should make it more obvious to
// users that they ran into a fatal error and need to refresh, which wasn't the
// case when we showed the header and footer like normal. It's also more similar
// to how server-rendered websites and browsers would show crashes or network
// issues, which hopefully makes the fatal error more recognizable too.

// It does slightly deviate from the app-like feeling this site might give,
// especially on mobiles. This again should be fine, as this page should be
// exclusive for real unexpected, fatal errors (hard crashes). This isn't
// entirely the case at the time of writing this as network errors are also
// caught here.
// TODO: Handle network errors nicely in a separate component.

interface Props {
    type?: string | undefined
}

const FatalErrorPage = ({ type }: Props) => {
    const fullLocation = window.location.pathname
    const [originalPathname] = React.useState<string>(fullLocation)

    // If the user navigates to another page we should force a refresh
    React.useEffect(() => {
        if (fullLocation !== originalPathname) {
            console.warn(
                'Navigation occured, forcing an app refresh to clear any potentially erroneous state',
            )
            window.location.reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullLocation])

    return (
        <div className={styles.container}>
            <div
                className={
                    type === 'component' ? styles.basecomponent : styles.base
                }
            >
                <Helmet>
                    <title>
                        {t({
                            id: 'core.fatalErrorPage.documentTitle',
                            message: `An error occured`,
                        })}
                    </title>
                </Helmet>

                <div className={styles.bar} />

                <Heading variant="h2" element="h1" className={styles.heading}>
                    <Trans id="core.fatalErrorPage.heading">
                        An error occured.
                    </Trans>
                </Heading>
                <Text className={styles.explanation}>
                    {/* Use an external link to force a page refresh. */}
                    <Trans id="core.fatalErrorPage.text">
                        An error occured while trying to process your request.
                        Please try again.
                        <br />
                        <br />
                        If the problem persists please{' '}
                        <ExternalLink
                            name="Contact us"
                            category="fatalErrorPage"
                            to={CONTACT_PAGE_URL}
                            className={styles.link}
                        >
                            contact us
                        </ExternalLink>
                        .
                    </Trans>
                </Text>
                <Button
                    minWidth
                    variant="primary"
                    className={styles.button}
                    onClick={() => window.location.reload()}
                    name="Try again"
                    category="core.fatalErrorPage.refresh"
                >
                    <Trans id="core.fatalErrorPage.refreshButton">
                        Try again
                    </Trans>
                </Button>
            </div>
        </div>
    )
}

export default FatalErrorPage
