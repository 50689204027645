import cx from 'classnames'
import * as React from 'react'

import { useAddProductsToWishlist } from './addProductsToWishlist.mutation'
import { useGetWishlist } from './GetWishlist.query'
import { useRemoveProductsFromWishlist } from './removeProductsFromWishlist.mutation'
import { wishlistIdVar } from './useWishlistId'
import { addToWishlist, removeFromWishlist } from './wishlistGtmData'
import { ConfigurableAttributeOption } from '../catalog/common/MinimalConfigurableProductInfo.fragment'
import {
    ConfigurableAttributesFieldValue,
    useSelectedConfigurableValue,
} from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import { getProductInitialValue } from '../catalog/ProductPage/StickyCta/ProductInfoFormMinimal'
import { WishlistItemInput } from '../graphql/schema.generated'
import Button from '../input/Button'
import useAddToWishlist from '../utils/ga4/useAddToWishlist'
import push from '../utils/googleTagManager/push'
import { wishlistVar } from '../WishlistActionButton'

export interface Props {
    product: Product | ConfigurableProduct
    render(isActive: boolean): React.ReactNode
    className?: string
    activeClassName?: string
    value?: ConfigurableAttributesFieldValue
    onAddToWishlist?(): Promise<void>
    optimistic?: boolean
    showWishlistModal?: boolean
    position?: 'pdp-addtowishlist' | 'pop-addtowishlist'
}

const WishlistButton = ({
    product,
    className,
    activeClassName,
    value,
    onAddToWishlist,
    render,
    optimistic = true,
    showWishlistModal = false,
    position,
}: Props) => {
    const ga4AddToWishlist = useAddToWishlist()
    const isConfigurable = Boolean((product as ConfigurableProduct).variants)
    const wishlistId = wishlistIdVar()
    const [removeItem, { loading: removing }] = useRemoveProductsFromWishlist()
    const { data: wishlist, loading: loadinWishlist } = useGetWishlist()
    const [addProductsToWishlist, { loading: adding }] =
        useAddProductsToWishlist()

    const selectedValueVar = useSelectedConfigurableValue(product)

    const wishlistItem = wishlist?.itemsV2?.items.find(
        (item) => item?.product?.id === product.id,
    )

    const handleClick = async (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        const configurableOptionValue = getProductInitialValue(product, value)

        if (!wishlistItem) {
            push(addToWishlist(product, configurableOptionValue))
            ga4AddToWishlist(product, configurableOptionValue ?? {})

            if (onAddToWishlist) {
                await onAddToWishlist()
            }

            let childSku: string | undefined

            if (isConfigurable && value) {
                const values: number[] = Object.values(value)

                childSku = (product as ConfigurableProduct).variants.find(
                    (variant) => {
                        const filter = (
                            attribute: ConfigurableAttributeOption,
                        ) =>
                            values.some(
                                (value) => value === attribute.valueIndex,
                            )

                        return variant.attributes.every(filter)
                    },
                )?.product?.sku
            }

            // When a configurable product with a selected size is added
            // the sku has to be set to the child product sku (simple product)
            // and the parent sku needs to be that of the configurable product
            const options: WishlistItemInput = {
                sku: childSku || product.sku,
                parent_sku: childSku && product.sku,
                quantity: 1,
                selected_options:
                    selectedValueVar && Object.keys(selectedValueVar)?.length
                        ? Object.values(selectedValueVar)
                        : undefined,
            }

            addProductsToWishlist([options])

            if (showWishlistModal) {
                wishlistVar(product.id)
            }
        } else {
            push(removeFromWishlist(product, configurableOptionValue))

            if (!wishlistId) {
                return
            }

            await removeItem({
                variables: {
                    wishlistId,
                    wishlistItemsIds: [wishlistItem.id],
                },
            })
        }
    }

    const isActive = optimistic
        ? (wishlistItem || adding) && !removing
        : Boolean(wishlistItem)

    const classes = cx(className, {
        [`${activeClassName}`]: isActive,
    })

    return (
        <Button
            variant="link"
            name={
                wishlistItem
                    ? 'Remove from wishlist (product page)'
                    : 'Add to wishlist (product page)'
            }
            category={
                wishlistItem
                    ? 'wishlist.productPage.remove'
                    : 'wishlist.productPage.add'
            }
            onClick={handleClick}
            className={classes}
            disabled={loadinWishlist || removing || adding}
            data-bc-position={position}
        >
            {render(isActive)}
        </Button>
    )
}

export default WishlistButton
