import * as React from 'react'

import styles from './Grid.module.scss'

interface OwnProps {
    position?: string
    productIndex?: number
    children: React.ReactNode
}

type Props = OwnProps &
    React.HTMLAttributes<HTMLDivElement> &
    React.RefAttributes<HTMLDivElement>

const GridItem = React.forwardRef<HTMLDivElement, Props>(function GridItem(
    { position, productIndex, children, ...other }: Props,
    ref,
) {
    return (
        <div
            data-prod-count={productIndex}
            data-position={position}
            className={styles.cell}
            ref={ref}
            {...other}
        >
            {children}
        </div>
    )
})

export default GridItem
