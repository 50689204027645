import { usePrismicDocument } from '@emico/prismic'

import VisualFilter from './catalog/CategoryPage/VisualFilter'
import {
    CastironPageBodyVisualFilter,
    JustbrandsPageBodyVisualFilter,
    PageBodyVisualFilter,
    VanguardPageBodyVisualFilter,
    VisualFilter as VisualFilterType,
} from './graphql/schema.generated'
import Container from './layout/Container'

type PrismicSliceVisualFilterType =
    | PageBodyVisualFilter
    | VanguardPageBodyVisualFilter
    | CastironPageBodyVisualFilter
    | JustbrandsPageBodyVisualFilter

interface Props {
    slice: PrismicSliceVisualFilterType
}

const PrismicSliceVisualFilter = ({ slice }: Props) => {
    const { primary } = slice
    const filterId =
        primary?.visualFilter?.linkType === 'Document'
            ? primary?.visualFilter?.id
            : undefined

    const { data, loading } = usePrismicDocument<VisualFilterType | undefined>(
        {
            key: 'document.id',
            value: filterId ?? '',
        },
        true,
        {
            skip: !filterId,
        },
    )

    // Only return something when there are cards to show
    const cards = data?.cards ?? []
    const items = cards.filter(
        ({ image, relativeUrl, title }) =>
            image && image.url && relativeUrl && title,
    )

    if (loading || items.length === 0) {
        return null
    }

    return (
        <Container verticalPadding>
            <VisualFilter items={items} />
        </Container>
    )
}

export default PrismicSliceVisualFilter
