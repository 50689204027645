import { FreeGiftRule } from '@emico-hooks/cart-fragment'
import { useState } from 'react'

import EditGiftsModal from './EditGiftsModal'
import styles from './FreeGiftProductSelection.module.scss'
import Button from '../input/Button'

export const FreeGiftProductSelection = ({
    rule,
    ruleCompleted,
}: {
    rule: FreeGiftRule
    ruleCompleted: boolean
}) => {
    const hasProductSelection = rule?.products.length > 1

    const [isOpen, setOpen] = useState<boolean>()

    return (
        <>
            {hasProductSelection && ruleCompleted ? (
                <Button
                    variant="linkInverted"
                    className={styles.changeGift}
                    name=""
                    category=""
                    onClick={() => setOpen(true)}
                >
                    Change gift
                </Button>
            ) : null}

            {isOpen && (
                <EditGiftsModal rules={[rule]} onBack={() => setOpen(false)} />
            )}
        </>
    )
}
