import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import LastLocationContext from './LastLocationContext'

type Props = RouteComponentProps & {
    children: React.ReactNode
}

// Since location is global state anyway, we can get away with using a global
// variable. Based on https://github.com/hinok/react-router-last-location/blob/6e63d8196e09fc133673be4120c40dd9a09373e5/src/LastLocationProvider.tsx#L8
let lastLocation: RouteComponentProps['location'] | undefined
let currentLocation: RouteComponentProps['location'] | undefined

const LastLocationProvider = ({ location, children }: Props) => {
    // The location changed, so current is now old.
    const previousLocation = currentLocation

    lastLocation = previousLocation
    currentLocation = location

    return (
        <LastLocationContext.Provider value={lastLocation}>
            {children}
        </LastLocationContext.Provider>
    )
}

export default withRouter(LastLocationProvider)
