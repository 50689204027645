import { createContext, ReactNode, useContext, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'

import { Toast } from './Toast'
import { ToasterContainer } from './ToasterContainer'
import { ToastPosition, Toaster, ToasterContextType } from './types'

const context = createContext<ToasterContextType>({
    toast: {
        warning: () => undefined,
        success: () => undefined,
        error: () => undefined,
    },
})

interface Props {
    /** Timeout in milliseconds */
    autoHide?: number
    /** The children */
    children: ReactNode
    /** Allow multiple toasts */
    allowMultiple?: boolean
    /** The position of the toast */
    position?: ToastPosition
    /** Visual feedback of the remaining time before toast gets dismissed */
    showProgress?: boolean
    /** Close toast on click */
    closeOnClick?: boolean
}

let toasterId = 0
const ToasterProvider = ({
    children,
    autoHide: messageTimeout = 5000,
    allowMultiple = false,
    position = 'top-center',
    showProgress = false,
    closeOnClick = true,
}: Props) => {
    const Provider = context.Provider
    const [currentToasts, setCurrentToasts] = useState<Toaster[]>([])

    const addToast = (toaster: Toaster) => {
        toasterId = toasterId + 1
        const newToast = {
            ...toaster,
            timeout: toaster.timeout || messageTimeout,
            id: toasterId,
        }

        setCurrentToasts((prev) => {
            if (allowMultiple) {
                return [...prev, newToast]
            }
            return [newToast]
        })
    }

    const closeToast = (toastId: number) => {
        setCurrentToasts((prev) =>
            prev?.filter((toast) => toast.id !== toastId),
        )
    }

    const toast = useMemo(
        () => ({
            success: (message: string, timeout?: number) =>
                addToast({
                    type: 'success',
                    message,
                    timeout,
                }),
            warning: (message: string, timeout?: number) =>
                addToast({
                    type: 'warning',
                    message,
                    timeout,
                }),
            error: (message: string, timeout?: number) =>
                addToast({ type: 'error', message, timeout }),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <Provider
            value={{
                toast,
            }}
        >
            {children}
            {createPortal(
                <ToasterContainer position={position}>
                    {currentToasts.map((currToast) => {
                        const mergeProps = {
                            showProgress,
                            toast: currToast,
                            closeOnClick,
                            close: () => closeToast(currToast?.id ?? 0),
                        }

                        return <Toast key={currToast.id} {...mergeProps} />
                    })}
                </ToasterContainer>,
                document.body,
            )}
        </Provider>
    )
}

const useToaster = () => useContext(context)

export { useToaster, ToasterProvider }
