import { FreeGiftRule } from '@emico-hooks/cart-fragment'
import { Plural, Trans } from '@lingui/macro'
import classNames from 'classnames'
import {
    Fragment,
    useState,
    useCallback,
    Dispatch,
    SetStateAction,
} from 'react'

import { stripMaybes } from '@emico/utils'

import styles from './EditGiftsModal.module.scss'
import { useUpdateCart } from './useUpdateCart'
import CartGiftItem from '../cart/CartGiftItem'
import { useCartItems } from '../cart/CartPage/useCartItems'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import Button from '../input/Button'
import Loader from '../presentation/Loader'
import ResponsiveModal, {
    Props as ResponsiveModalProps,
} from '../presentation/ResponsiveModal/ResponsiveModal'
import Heading from '../typography/Heading'

interface OwnProps {
    rules: FreeGiftRule[]
}

type Props = OwnProps & Pick<ResponsiveModalProps, 'onBack'>

const isEmptyOptionValue = (option: Record<number | string, number>) =>
    !Object.keys(option).length

type SelectedProductsMap = {
    [key: number | string]: {
        options: ConfigurableAttributesFieldValue | undefined
        product: ConfigurableProduct | Product
    }
}

const EditGiftsModalRule = ({
    rule,
    setSelectedProducts,
    maxItems,
}: {
    rule: FreeGiftRule
    setSelectedProducts: Dispatch<SetStateAction<SelectedProductsMap>>
    maxItems: number
}) => {
    const { products } = rule

    const { items } = useCartItems()

    const [selectedProductsMap, setSelectedProductsMap] = useState<{
        map: Map<number, Record<number | string, number> | undefined>
    }>({ map: new Map() })

    const toggleSelection = (
        product: Product,
        option: Record<number | string, number>,
        confProduct: ConfigurableProduct | Product,
    ) => {
        const productsMap = selectedProductsMap.map

        productsMap.set(
            product.id,
            isEmptyOptionValue(option) ? undefined : option,
        )
        setSelectedProductsMap({ map: productsMap })
        setSelectedProducts((selected) => ({
            ...selected,
            [product.id]: {
                options: option as ConfigurableAttributesFieldValue,
                product: { ...confProduct },
            },
        }))
    }

    const isProductSelected = (product: Product) => {
        const option = selectedProductsMap.map.get(product.id)
        return !option ? false : !isEmptyOptionValue(option)
    }

    const selectedCount = Array.from(selectedProductsMap.map).filter(
        ([, value]) => !isEmptyOptionValue(value ?? {}),
    ).length

    return (
        <div className={styles.group} key={`${rule.id}`}>
            <div className={classNames(styles.subheader, styles.sticky)}>
                <span>
                    <Plural
                        id="cart.gifts.modalCongratsHeader"
                        value={maxItems}
                        one="Congrats! Select your exclusive gift"
                        other="Congrats! Select your {maxItems} exclusive gifts"
                    />
                </span>
            </div>
            {products.filter(stripMaybes).map((p) => {
                const isSelectedProduct = isProductSelected(p)

                const cartItem = items?.find((itm) => itm.product?.id === p?.id)

                return (
                    <Fragment key={`${p.id}-${rule.id}`}>
                        <div
                            className={classNames(
                                styles.gift,
                                isSelectedProduct ? styles.selected : '',
                            )}
                        >
                            <CartGiftItem
                                editMode
                                hideAmount
                                hideFreeGiftLabel
                                isSelectionDisabled={
                                    selectedCount >= maxItems &&
                                    !isSelectedProduct
                                }
                                className={styles.padding}
                                onChange={(option, product) => {
                                    toggleSelection(
                                        p,
                                        option as Record<number, number>,
                                        product,
                                    )
                                }}
                                productId={p.id}
                                cartItem={cartItem}
                                rule={{ ...rule, items: [] }}
                            />
                        </div>
                    </Fragment>
                )
            })}
        </div>
    )
}

const EditGiftsModal = ({ rules, onBack }: Props) => {
    const { loading, updateCart } = useUpdateCart()

    const [selectedProducts, setSelectedProducts] =
        useState<SelectedProductsMap>({})

    const { freeGifts, items } = useCartItems()

    const onBackCallback = useCallback(async () => {
        const productIds = Object.keys(selectedProducts)
        let selectedOptions: Array<{
            product: ConfigurableProduct | Product
            options: ConfigurableAttributesFieldValue | undefined
        }> = []
        for (const prodId of productIds) {
            const { options, product } = selectedProducts[prodId]
            selectedOptions = [
                ...selectedOptions,
                {
                    product,
                    options,
                },
            ]
        }
        await updateCart(selectedOptions)
        onBack?.()
    }, [onBack, updateCart, selectedProducts])

    // Get list of gift rule id's that are visible in the modal
    const giftRuleIds = rules.map((r) => r.id)

    // Filter on visible rules and calculate some values
    const { maxItems } = (freeGifts || []).reduce(
        (acc, gift) => {
            if (giftRuleIds.includes(gift.id)) {
                acc.selectedCount =
                    acc.selectedCount + (gift.itemIds ? gift.itemIds.length : 0)
                acc.maxItems = acc.maxItems + (gift.maxItems || 0)
            }
            return acc
        },
        { selectedCount: 0, maxItems: 0 },
    )

    const selectedCount = Object.keys(selectedProducts || {}).reduce(
        (counter, key) => {
            const option = selectedProducts[key].options as Record<
                number | string,
                number
            >
            if (!isEmptyOptionValue(option)) {
                return counter + 1
            }
            return counter
        },
        0,
    )

    return (
        <ResponsiveModal
            onBack={onBack}
            header={
                <Heading variant="h2" element="h1">
                    <Plural
                        id="cart.gifts.modalSelectGiftHeading"
                        value={maxItems}
                        one="Select your gift"
                        other="Select your gifts"
                    />
                </Heading>
            }
            footer={
                <>
                    <div
                        className={classNames(styles.subheader, styles.bottom)}
                    >
                        <span>
                            <Trans id="cart.gifts.modalSelectedItemsCount">
                                {selectedCount} of {maxItems} gifts selected
                            </Trans>
                        </span>
                    </div>
                    <Button
                        name="closeFreeGiftsModal"
                        category="cart.gifts.modal"
                        onClick={onBackCallback}
                        wide
                        disabled={loading || selectedCount < maxItems}
                        variant="primary"
                    >
                        {loading && <Loader className={styles.loader} />}
                        <Trans id="cart.gifts.modal.submitLabel">
                            Save changes
                        </Trans>
                    </Button>
                </>
            }
        >
            {rules?.map((rule) => (
                <EditGiftsModalRule
                    rule={rule}
                    key={rule.id}
                    setSelectedProducts={setSelectedProducts}
                    maxItems={maxItems}
                />
            ))}
            {loading && (
                <div className={styles.saveGifts}>
                    <Loader className={styles.loader} />
                </div>
            )}
        </ResponsiveModal>
    )
}

export default EditGiftsModal
