import { cookie } from '@prismicio/client'
import { Helmet } from 'react-helmet'

const PrismicPreviewLoader = () =>
    process.env.REACT_APP_PRISMIC_REPOSITORY !== undefined &&
    // eslint-disable-next-line no-restricted-globals
    localStorage?.getItem(cookie.preview) ? (
        <Helmet>
            <script
                async
                defer
                src={`https://static.cdn.prismic.io/prismic.js?repo=${process.env.REACT_APP_PRISMIC_REPOSITORY}&new=true`}
            />
        </Helmet>
    ) : null

export default PrismicPreviewLoader
