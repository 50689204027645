import './overrides'
import './theme/reboot.scss'

import { PrismicPreviewLoader } from '@emico/prismic'
import { StoreView } from '@emico/storeviews'

import AppBehavior from './AppBehavior'
import AppProviders from './AppProviders'
import { ComponentConfig } from './utils/ComponentOverridesContext'

const App = ({
    storeViews,
    activeStoreView,
    preloadFonts,
    componentsConfig,
}: {
    storeViews: StoreView[]
    activeStoreView: StoreView
    preloadFonts: string[]
    componentsConfig: ComponentConfig
}) => (
    <>
        <PrismicPreviewLoader />
        <AppProviders
            storeViews={storeViews}
            activeStoreView={activeStoreView}
            componentsConfig={componentsConfig}
        >
            <AppBehavior preloadFonts={preloadFonts} />
        </AppProviders>
    </>
)

export default App
