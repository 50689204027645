import { DLVButtonClick } from './types'

export default function buttonClick(
    buttonName: string,
    buttonCategory: string,
): DLVButtonClick {
    return {
        event: 'buttonClick',
        buttonName,
        buttonCategory,
    }
}
