import { useApolloClient } from '@apollo/client'
import { Provider as ReactReduxProvider } from 'react-redux'
import { Store } from 'redux'
import { persistStore } from 'redux-persist'

import { useActiveStoreView } from '@emico/storeviews'

import { signOut } from '../../actions/user'
import { useRootCategoryId } from '../../catalog/useRootCategory'
import { ThunkDispatch } from '../../types'
import configureStore from '../../utils/configureStore'
import { request } from '../../utils/RestApi'

interface Props {
    children: React.ReactNode
}

let store: Store

const ReduxProvider = ({ children }: Props) => {
    const { code } = useActiveStoreView()
    const apolloClient = useApolloClient()
    const rootCategoryId = useRootCategoryId()

    const handleRequest = request(code, () => {
        const dispatch = store.dispatch as ThunkDispatch

        dispatch(signOut())
    })

    if (!store) {
        store = configureStore({
            apolloClient,
            request: handleRequest,
            rootCategoryId,
        })
        persistStore(store)
    }

    // PersistGate has been removed due to a white-flash happening with SSR enabled.
    // The PersistGate does nothing except for blocking app render until state has been loaded
    return <ReactReduxProvider store={store}>{children}</ReactReduxProvider>
}

export default ReduxProvider
