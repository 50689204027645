import classNames from 'classnames'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'

import FaqQuestions from './FaqQuestions'
import { PageBodyFaqSubject } from './graphql/schema.generated'
import { getPrismicComponents } from './htmlSerializer'
import styles from './PrismicSliceFaqSubject.module.scss'
import HeadingVariant from './types/HeadingVariant'
import TextColor from './types/TextColor'

export const PrismicSliceFaqSubject = ({
    slice,
    className,
}: {
    slice: PageBodyFaqSubject
    className?: string
}) => {
    const { primary, fields } = slice

    if (!primary || !fields || !fields.length) {
        return null
    }

    const { heading } = primary

    return (
        <div className={classNames(styles.faqContainer, className)}>
            <FaqQuestions
                headingVariant={HeadingVariant.h3}
                style="light"
                questions={fields.map((faqItem) => ({
                    ...faqItem,
                    content: (
                        <PrismicRichText
                            render={faqItem.content as [RichTextBlock]}
                            components={getPrismicComponents({
                                style: TextColor.dark,
                            })}
                            analyticsContext="privileges.faq.question.content"
                        />
                    ),
                }))}
                title={heading ?? ''}
            />
        </div>
    )
}
