import { ConfigurableAttributesFieldValue } from './ConfigurableAttributesField'
import {
    CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    shouldCombineWidthAndLength,
} from './helpers'
import { ConfigurableAttributeValue } from '../../common/MinimalConfigurableProductInfo.fragment'
import { ConfigurableProduct } from '../ConfigurableProduct'

export const getConfigurableAttributeLabel = (
    product: ConfigurableProduct,
    value: ConfigurableAttributesFieldValue,
) => {
    const attributes = Object.entries(value)

    if (shouldCombineWidthAndLength(product) && attributes.length > 0) {
        const widthAttributeValue = attributes[0][1]

        const lengthAttribute = product.configurableOptions.find(
            (attribute) =>
                attribute.attributeCode ===
                CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
        )

        const widthAttribute = product.configurableOptions.find(
            (attribute) =>
                attribute.attributeCode ===
                CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
        )

        const widthSize = widthAttribute?.values.find(
            (attr) => attr.valueIndex === widthAttributeValue,
        )

        const lengthSize = lengthAttribute?.values.find(
            (attr) =>
                attr.valueIndex === value[Number(lengthAttribute.attributeId)],
        )

        return `W${widthSize ? widthSize.label : ``} ${
            lengthSize ? `L${lengthSize.label}` : ``
        }`
    } else {
        const allAttributeValues = product.configurableOptions.reduce<
            ConfigurableAttributeValue[]
        >((acc, attribute) => {
            acc = [...acc, ...attribute.values]
            return acc
        }, [])

        const sizeValue = allAttributeValues.find(
            (attr) => attr.valueIndex === Object.values(value)[0],
        )

        return sizeValue?.label
    }
}
