import { useReactiveVar } from '@apollo/client'
import { useCart } from '@emico-hooks/cart'
import {
    useSetShippingAddressesOnCart,
    useSetBillingAddressOnCart,
} from '@emico-hooks/cart-addresses'
import { CartAddressFragment, CartFragment } from '@emico-hooks/cart-fragment'
import { useSetGuestEmailOnCart } from '@emico-hooks/cart-guest'
import { useCartId } from '@emico-hooks/cart-id'
import { useSetShippingMethodsOnCart } from '@emico-hooks/cart-shipping-methods'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { useCallback } from 'react'

import { CountryCodeEnum } from '@emico/graphql-schema-types'

import { pickupPointZipCodeVar } from '../../checkout/PickUpPointForm/useDHLParcelShippingServicePoints'
import {
    Address,
    useGetAddresses,
} from '../../customerAddress/getAddresses.query'
import { AddressFormValues } from '../../utils/address/types'
import { isSameAddress } from '../../utils/address/utils'

const parseFreeGifts = (cart: CartFragment) => {
    const freeGifts = cart?.freeGifts ?? []
    const cartItems = cart?.items ?? []

    return freeGifts?.map((gift) => {
        const products = gift.products ?? []
        return {
            ...gift,
            items: products.filter(stripMaybes).map((prod) => ({
                productUid: prod.uid,
                productId: prod.id,
                item: cartItems?.find((item) => item?.product?.id === prod?.id),
            })),
        }
    })
}

const parseTotals = (cart: CartFragment) => ({
    deliveryPrice: 0,
    prices: cart?.prices,
    discounts: cart?.prices?.discounts,
    totalItems: cart?.totalQuantity ?? 0,
    totalItemsExclGifts:
        (cart?.totalQuantity ?? 0) -
        (cart?.items?.filter((item) => item?.extensionAttributes?.isFreeGift)
            ?.length ?? 0),
})

const fromCartAddresstoAddressFormValues = (
    addressValues: CartAddressFragment,
): AddressFormValues => ({
    ...fromAddressBookAddressToAddressFormValues(
        addressValues,
        addressValues.country.code as CountryCodeEnum,
    ),
})

const fromAddressBookAddressToAddressFormValues = (
    addressValues?: Address | CartAddressFragment,
    countryCodeEnum?: CountryCodeEnum,
): AddressFormValues => ({
    city: addressValues?.city ?? '',
    postalCode: addressValues?.postcode ?? '',
    firstName: addressValues?.firstname ?? '',
    lastName: addressValues?.lastname ?? '',
    houseNumber: addressValues?.street?.[1] ?? '',
    country:
        addressValues && 'countryCode' in addressValues
            ? (addressValues.countryCode as CountryCodeEnum)
            : countryCodeEnum,
    suffix: addressValues?.street?.[2] ?? '',
    company: addressValues?.company ?? '',
    street: addressValues?.street?.[0] ?? '',
    phone: addressValues?.telephone ?? '',
    isAddressBookAddress:
        addressValues && 'id' in addressValues
            ? Boolean(addressValues?.id)
            : false,
    region:
        addressValues && 'region' in addressValues
            ? addressValues?.region
            : undefined,
})

export const useCartItems = (withDetails: boolean = false) => {
    const cart = useCart({}, withDetails)

    const hasItems = Boolean(cart.data?.totalQuantity ?? 0)
    const freeGifts = cart?.data && parseFreeGifts(cart.data)
    const totals = cart?.data ? parseTotals(cart.data) : undefined

    return {
        id: cart?.data?.id,
        items: hasItems ? cart?.data?.items : undefined,
        hasItems,
        quantity: hasItems ? cart.data?.totalQuantity ?? 0 : 0,
        email: cart?.data?.email,
        ...totals,
        ...cart,
        ...cart?.data,
        freeGifts,
    }
}

const useCartItemsWithAddresses = (withDetails: boolean = false) => {
    const cart = useCartItems(withDetails)

    return {
        ...cart,
        cartBillingAddress: cart?.billingAddress ?? undefined,
        cartShippingAddress: cart?.shippingAddresses?.[0] ?? undefined,
        cartShippingMethod:
            cart?.shippingAddresses?.[0]?.selectedShippingMethod ?? undefined,
        shippingMethods:
            cart?.shippingAddresses?.[0]?.availableShippingMethods ?? [],
        dhlServicePointId:
            cart?.extensionAttributes?.dhlparcelShippingServicepointId ??
            undefined,
        dhlServicePointCountryId:
            cart?.extensionAttributes?.dhlparcelShippingServicepointCountryId ??
            undefined,
        paymentMethods: cart?.availablePaymentMethods ?? [],
    }
}

export const useCheckoutDelivery = (withDetails: boolean = false) => {
    const {
        cartBillingAddress,
        cartShippingMethod: defaultShippingMethod,
        cartShippingAddress,
        loading,
        shippingMethods,
        email: cartEmail,
        dhlServicePointCountryId,
        dhlServicePointId,
        paymentMethods,
        ...cart
    } = useCartItemsWithAddresses(withDetails)

    const { data: addresses = [], loading: loadingAddresses } =
        useGetAddresses()

    const addressBookDefaultShippingAddress = addresses?.find(
        (address) => address.defaultShipping,
    )
    const addressBookDefaultBillingAddress = addresses?.find(
        (address) => address.defaultBilling,
    )

    const hasDefaultAddressBookShippingAddress = Boolean(
        addressBookDefaultShippingAddress,
    )
    const hasDefaultAddressBookBillingAddress = Boolean(
        addressBookDefaultBillingAddress,
    )

    const hasCartShippingAddress = Boolean(cartShippingAddress)
    const hasCartBillingAddress = Boolean(cartBillingAddress)

    const hasAddressBookAddresses = Boolean(addresses?.length)

    const addressFormValuesShippingAddress = addressBookDefaultShippingAddress
        ? fromAddressBookAddressToAddressFormValues(
              addressBookDefaultShippingAddress,
          )
        : cartShippingAddress
          ? fromCartAddresstoAddressFormValues(cartShippingAddress)
          : undefined

    const addressFormValuesBillingAddress = cartBillingAddress
        ? fromCartAddresstoAddressFormValues(cartBillingAddress)
        : addressBookDefaultBillingAddress
          ? fromAddressBookAddressToAddressFormValues(
                addressBookDefaultBillingAddress,
            )
          : undefined

    // Check if shipping & billing addresses are known addresses:
    const isShippingAddressInAddressBook =
        cartShippingAddress &&
        addresses.find((address) =>
            isSameAddress(
                fromAddressBookAddressToAddressFormValues(address),
                fromCartAddresstoAddressFormValues(cartShippingAddress),
            ),
        )
    const isBillingAddressInAddressBook =
        cartBillingAddress &&
        addresses.find((address) =>
            isSameAddress(
                fromAddressBookAddressToAddressFormValues(address),
                fromCartAddresstoAddressFormValues(cartBillingAddress),
            ),
        )

    const searchQuery = useReactiveVar(pickupPointZipCodeVar)

    const isBillingSameAsShipping =
        cartBillingAddress && cartShippingAddress
            ? isSameAddress(
                  fromCartAddresstoAddressFormValues(cartBillingAddress),
                  fromCartAddresstoAddressFormValues(cartShippingAddress),
              )
            : addressBookDefaultBillingAddress &&
                addressBookDefaultShippingAddress
              ? isSameAddress(
                    fromAddressBookAddressToAddressFormValues(
                        addressBookDefaultBillingAddress,
                    ),
                    fromAddressBookAddressToAddressFormValues(
                        addressBookDefaultShippingAddress,
                    ),
                )
              : true

    return {
        isAddressBookAddress: (address: AddressFormValues) => {
            const foundAddress = addresses?.find(
                (addressBookAddress: Address) =>
                    isSameAddress(
                        fromAddressBookAddressToAddressFormValues(
                            addressBookAddress,
                        ),
                        address,
                    ),
            )
            return Boolean(foundAddress)
        },
        hasAddressBookAddresses,
        hasCartBillingAddress,
        hasCartShippingAddress,
        hasDefaultAddressBookShippingAddress,
        hasDefaultAddressBookBillingAddress,
        addressBookShippingAddress: hasDefaultAddressBookShippingAddress
            ? fromAddressBookAddressToAddressFormValues(
                  addressBookDefaultShippingAddress,
              )
            : undefined,
        addressBookBillingAddress: hasDefaultAddressBookBillingAddress
            ? fromAddressBookAddressToAddressFormValues(
                  addressBookDefaultBillingAddress,
              )
            : undefined,
        cartShippingAddress: cartShippingAddress
            ? {
                  ...fromCartAddresstoAddressFormValues(cartShippingAddress),
                  isAddressBookAddress: Boolean(isShippingAddressInAddressBook),
              }
            : undefined,
        cartBillingAddress: cartBillingAddress
            ? {
                  ...fromCartAddresstoAddressFormValues(cartBillingAddress),
                  isAddressBookAddress: Boolean(isBillingAddressInAddressBook),
              }
            : undefined,
        billingAddressSameAsShippingAddress: isBillingSameAsShipping,
        defaultShippingMethod,
        shippingMethods: shippingMethods?.filter(stripMaybes),
        isShippingAddressInAddressBook,
        isBillingAddressInAddressBook,
        cartEmail,
        loading: loadingAddresses || loading,
        dhlServicePointId,
        dhlServicePointCountryId,
        paymentMethods,
        phone:
            cartShippingAddress?.telephone ||
            cartBillingAddress?.telephone ||
            addressBookDefaultShippingAddress?.telephone ||
            addressBookDefaultBillingAddress?.telephone,
        searchQuery:
            searchQuery || addressFormValuesBillingAddress?.postalCode || '',
        ...cart,
    }
}

export const useSetShippingAddress = () => {
    const setAddress = useSetShippingAddressesOnCart()
    const cartId = useCartId()

    return useCallback(
        async (address: AddressFormValues) =>
            await setAddress(cartId ?? '', [
                {
                    address: {
                        city: address.city ?? '',
                        country_code: address.country ?? CountryCodeEnum.NL,
                        firstname: address.firstName ?? '',
                        lastname: address?.lastName ?? '',
                        postcode: address?.postalCode,
                        street: [
                            address.street ?? '',
                            `${address.houseNumber}`,
                            address?.suffix ?? '',
                        ],
                        telephone: address?.phone ?? '',
                        region_id: address?.region?.regionId,
                        region: address.region?.code,
                        save_in_address_book: !address?.isAddressBookAddress,
                    },
                },
            ]).then(
                (res) =>
                    res?.setShippingAddressesOnCart?.cart
                        ?.shippingAddresses?.[0]?.availableShippingMethods,
            ),
        [cartId, setAddress],
    )
}

export const useSetBillingAddress = () => {
    const setAddress = useSetBillingAddressOnCart()
    const cartId = useCartId()

    return useCallback(
        async (address?: AddressFormValues) =>
            address
                ? await setAddress(cartId ?? '', {
                      address: {
                          city: address.city ?? '',
                          country_code: address.country ?? CountryCodeEnum.NL,
                          firstname: address?.firstName ?? '',
                          lastname: address?.lastName ?? '',
                          postcode: address?.postalCode,
                          street: [
                              address.street ?? '',
                              `${address.houseNumber}`,
                              address?.suffix ?? '',
                          ],
                          telephone: address?.phone ?? '',
                          region_id: address?.region?.regionId,
                          region: address.region?.code,
                          save_in_address_book: !address?.isAddressBookAddress,
                      },
                  })
                : Promise.resolve(),
        [cartId, setAddress],
    )
}

export const useSetGuestEmailAddress = () => {
    const setGuestEmail = useSetGuestEmailOnCart()
    const cartId = useCartId()

    return useCallback(
        async (email: string) => await setGuestEmail(cartId ?? '', email),
        [cartId, setGuestEmail],
    )
}

export const useSetShippingMethod = () => {
    const setShippingMethod = useSetShippingMethodsOnCart()
    const cartId = useCartId()

    return useCallback(
        async (
            carrierCode: string,
            methodCode: string,
            dhlServicePointCountryId?: string,
            dhlServicePointId?: string,
        ) =>
            await setShippingMethod(cartId ?? '', [
                {
                    carrier_code: carrierCode,
                    method_code: methodCode,
                    ...(dhlServicePointCountryId &&
                        dhlServicePointId && {
                            dhlparcel_shipping_servicepoint_country_id:
                                dhlServicePointCountryId,
                            dhlparcel_shipping_servicepoint_id:
                                dhlServicePointId,
                        }),
                },
            ]),
        [cartId, setShippingMethod],
    )
}
