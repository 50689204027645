import { Trans } from '@lingui/macro'

import { STORES_DISABLED } from '../../constants'
import CheckIcon from '../../core/CheckIcon'
import Submit from '../../input/Submit'
import Icon from '../../media/Icon'
import Link from '../../navigation/Link'
import paths from '../../paths'

interface Props {
    showSubmit: boolean
    isInCart: boolean
    isSubmitting: boolean
    className?: string
}

const ProductFormButton = ({
    showSubmit,
    isInCart,
    isSubmitting,
    className,
}: Props) => {
    if (!showSubmit && !STORES_DISABLED) {
        return (
            <Link name="Stores" category="header" to={paths.storeLocator}>
                <Trans id="core.productInfo.viewInStore">View in store</Trans>
            </Link>
        )
    }

    const check = <Icon component={CheckIcon} title="" />

    return (
        <Submit
            variant="highlight"
            wide
            name="Add to cart"
            category="productPage.addToCart"
            loading={isSubmitting}
            disabled={isSubmitting}
            data-testid="cart.addToCartForm.Addtocart"
            className={className}
            data-bc-position="pdp-submit-addtocart"
        >
            {isInCart ? (
                <Trans id="cart.addToCartForm.added">
                    {check} Added to cart
                </Trans>
            ) : (
                <Trans id="cart.addToCartForm.submit">Add to cart</Trans>
            )}
        </Submit>
    )
}

export default ProductFormButton
