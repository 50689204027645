import { getSentry } from '@emico/utils'

import { BLUECONIC_EVENTS_ENABLED } from './constants'
import { BlueConicEventName } from './types'

/**
 * Generate an event that is processed by BlueConic
 *
 * @param {BlueConicEventName} eventName
 * @param {string[]} productIds
 */
const publishBlueConicEvent = (
    eventName: BlueConicEventName,
    productIds: string[],
) => {
    const storeId = process.env.REACT_APP_BLUECONIC_STORE_ID

    if (!BLUECONIC_EVENTS_ENABLED || !storeId || !window?.blueConicClient) {
        return
    }

    try {
        window.blueConicClient.event.publish(eventName, {
            productIds,
            storeId,
        })
    } catch (e) {
        const Sentry = getSentry()

        Sentry?.withScope((scope) => {
            scope.setExtras({
                eventName,
                storeId,
                productIds,
            })

            Sentry.captureMessage(
                'Error when trying to fire a BlueConic event using the window.blueConicClient.event.publish API',
                'error',
            )
        })
    }
}

export default publishBlueConicEvent
