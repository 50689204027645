// Scroll to referring element and calculate the offset position minus the fixed topbar
const scrollToElement = (
    element: HTMLElement,
    includeHeader: boolean = true,
) => {
    let offset = element.offsetTop

    if (includeHeader) {
        const fixedElement = document.querySelector('[data-topbar]')
        const fixedOffset = fixedElement?.getBoundingClientRect().height

        offset = offset - (fixedOffset ?? 0)
    }

    window.scrollTo({
        behavior: 'smooth',
        top: offset,
    })
}

export default scrollToElement
