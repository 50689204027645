import { useActiveStoreView } from '@emico/storeviews'

const useTweakwiseItemNo = (id: number | string | undefined) => {
    const { storeId } = useActiveStoreView()

    if (id === undefined) {
        return
    }

    return `1${storeId.toString().padStart(4, '0')}${id}`
}

export default useTweakwiseItemNo
