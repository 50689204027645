import { useApolloClient } from '@apollo/client'
import * as React from 'react'

import useAddToCartFunction from './checkout/useAddToCartFunction'
import { getProductsBySku } from './useProductsBySku'
import { filterNullValues } from './utils/filterNullValues'

const Api = () => {
    const addToCart = useAddToCartFunction()
    const apolloClient = useApolloClient()

    React.useEffect(() => {
        const eventLoaded = new Event('onJustbrandsLoaded')
        const eventCleanup = new Event('onJustbrandsCleanup')

        window.justbrands = {
            getProducts: async (skus: string[]) => {
                const { data, error } = await getProductsBySku(
                    apolloClient,
                    skus,
                )

                if (error) {
                    return Promise.reject(error.message)
                }
                const items = filterNullValues(data.products.items) || []

                return Promise.resolve(items)
            },
            addToCart: async (sku, quantity, options) => {
                const { data, error } = await getProductsBySku(apolloClient, [
                    sku,
                ])

                if (error) {
                    return Promise.reject(error.message)
                }
                if (
                    !data?.products?.items ||
                    data.products.items.length === 0
                ) {
                    return Promise.reject(`Product with sku ${sku} not found.`)
                }
                const product = data.products.items[0]

                return addToCart(product, quantity, options)
            },
        }

        document.dispatchEvent(eventLoaded)

        return () => {
            document.dispatchEvent(eventCleanup)
            delete window?.justbrands
        }
    }, [addToCart, apolloClient])

    return null
}

export default Api
