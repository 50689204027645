import useResizeObserver from '@react-hook/resize-observer'
import * as React from 'react'

const useSize = (target: React.MutableRefObject<HTMLElement | null>) => {
    const [size, setSize] = React.useState<DOMRect>()

    React.useLayoutEffect(() => {
        if (target.current) {
            setSize(target.current.getBoundingClientRect())
        }
    }, [target])

    useResizeObserver(target, (entry) => {
        setSize(entry.contentRect)
    })

    return size
}

export default useSize
