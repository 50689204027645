import { Marker } from '@react-google-maps/api'
import * as React from 'react'

import ci_icon from './markerIcons/ci_icon.svg'
import ciMarker from './markerIcons/ci_marker.svg'
import jb_icon from './markerIcons/jb_icon.svg'
import marker from './markerIcons/marker.svg'
import pme_icon from './markerIcons/pme_icon.svg'
import pmeMarker from './markerIcons/pme_marker.svg'
import vgMarker from './markerIcons/vg_marker.svg'
import { SrsStore } from './useSrsStore'

interface Location {
    lat: number
    lng: number
}

interface Props {
    store: SrsStore
    selectedStore: SrsStore | undefined
    position: Location
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clusterer?: any
    onSelect(value: SrsStore): void
    storeView: string
}

const StoreMarker = ({
    store,
    selectedStore,
    position,
    onSelect,
    clusterer,
    storeView,
}: Props) => {
    let url = marker
    let zIndex = -2

    switch (storeView) {
        case 'castiron':
            url = ciMarker
            break
        case 'pme':
            url = pmeMarker
            break
        case 'vanguard':
            url = vgMarker
            break
    }

    const isSelected = store.storeId === selectedStore?.storeId
    const iconOptions: google.maps.Icon = {
        url,
        anchor: isSelected
            ? new google.maps.Point(43, 64)
            : new google.maps.Point(32, 47),
        scaledSize: isSelected
            ? new google.maps.Size(43, 64)
            : new google.maps.Size(32, 47),
    }

    let icon: typeof iconOptions = iconOptions

    if (store.pmeStore) {
        icon = { ...iconOptions, url: pme_icon }
        zIndex = 3
    } else if (store.ciStore) {
        icon = { ...iconOptions, url: ci_icon }
        zIndex = 2
    } else if (store.jbStore) {
        icon = { ...iconOptions, url: jb_icon }
        zIndex = 1
    }

    return (
        <Marker
            icon={icon}
            key={store.storeId}
            clickable
            onClick={() => onSelect(store)}
            position={position}
            clusterer={clusterer}
            zIndex={zIndex}
        />
    )
}

export default StoreMarker
