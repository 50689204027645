import { DLVFormInteraction } from './types'

interface Props {
    formName: string
    formID: string
    formStep?: string
    formStepName?: string
}

export default function formInteraction({
    formName,
    formID,
    formStep,
    formStepName,
}: Props): DLVFormInteraction {
    return {
        event: 'formInteraction',
        formName,
        formID,
        formStep,
        formStepName,
    }
}
