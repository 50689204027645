import { globalWindow } from '@emico/ssr-utils'

const makeUrl = (language?: string) => (relativePath: string) => {
    const origin = globalWindow?.location.origin.replace('http://', 'https://')
    const languagePrefix = language ? `/${language}` : ''

    return `${origin}${languagePrefix}${relativePath}`
}

export default makeUrl
