import { CartItemFragmentConfigurableCartItem } from '@emico-hooks/cart-fragment'

import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { decodeAttributeValueObject } from '../catalog/ProductPage/StickyCta/flattenCombinedAttributes'

export type CartItemWithOptions = {
    __typename?: 'ConfigurableCartItem'
    configurableOptions: Array<{
        configurableProductOptionUid: string
        configurableProductOptionValueUid: string
        optionLabel: string
        valueLabel: string
    }>
} & CartItemFragmentConfigurableCartItem

export const getCartItemBySelectedAttributes = (
    cartItems: CartItemWithOptions[],
    attributes: string | ConfigurableAttributesFieldValue,
): boolean => {
    const selectedUids = Object.values(
        typeof attributes === 'string'
            ? decodeAttributeValueObject<Record<string, string>>(attributes)
            : attributes ?? {},
    )

    const configurableProductOptionValueUids = cartItems.map((item) =>
        item.configurableOptions.map(
            (conf) => conf.configurableProductOptionValueUid,
        ),
    )
    let allMatch = true
    for (const valueUids of configurableProductOptionValueUids) {
        allMatch = valueUids.every((value) => selectedUids.includes(value))
        if (allMatch) {
            break
        }
    }
    return allMatch
}
