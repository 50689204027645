import { BaseCategoryTree, useCategoryTree } from './CategoryTree.query'
import { useCoreConfigValue } from '../coreConfig.query'

const CORE_CONFIG_ROOT_CATEGORY_ID_KEY =
    'general_settings/store/root_category_id'

export const useRootCategoryId = (): number | undefined => {
    const { value, loading } = useCoreConfigValue(
        CORE_CONFIG_ROOT_CATEGORY_ID_KEY,
    )

    if (!value && !loading) {
        throw new Error(`${CORE_CONFIG_ROOT_CATEGORY_ID_KEY} value is not set`)
    }
    return value ? Number(value) : undefined
}

const useRootCategory = (): {
    data?: BaseCategoryTree | undefined
    loading: boolean
} => {
    const rootCategoryId = useRootCategoryId()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error, loading } = useCategoryTree(rootCategoryId!, {
        skip: rootCategoryId === undefined,
    })

    if (error) {
        throw error
    }

    return {
        loading: loading || rootCategoryId === undefined,
        data: data && data.category ? data.category : undefined,
    }
}

export default useRootCategory
