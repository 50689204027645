import * as React from 'react'

import { LayoutStyle } from './LayoutStyle'
import { PrismicCmsSlice, renderSlice } from './PrismicCmsSlices'
import PrismicSliceFaqSubjects, {
    PrismicSliceFaqSubject,
} from './PrismicSliceFaqSubjects'

interface Props {
    urlKey: string
    slices: PrismicCmsSlice[]
    style: LayoutStyle
}

const PrismicCmsFaqSlices = ({ slices, urlKey, style = 'light' }: Props) => {
    let heroSlice: PrismicCmsSlice | undefined
    const subjectSlices: PrismicSliceFaqSubject[] = []
    const otherSlices = slices.reduce<PrismicCmsSlice[]>((acc, slice) => {
        if (slice.__typename?.endsWith('PageBodyHero')) {
            heroSlice = slice
        } else if (slice.__typename?.endsWith('PageBodyFaqSubject')) {
            subjectSlices.push(slice as PrismicSliceFaqSubject)
        } else {
            acc.push()
        }
        return acc
    }, [])

    return (
        <>
            {heroSlice && (
                <React.Fragment key="hero">
                    {renderSlice(heroSlice)}
                </React.Fragment>
            )}

            <PrismicSliceFaqSubjects
                style={style}
                slices={subjectSlices}
                urlKey={urlKey}
            />

            {otherSlices.map((slice, index) => (
                <React.Fragment key={index}>
                    {renderSlice(slice)}
                </React.Fragment>
            ))}
        </>
    )
}

export default PrismicCmsFaqSlices
