import { StoreConfigJsonStoreView } from '@emico-utils/storeview-manager'
import { useMemo } from 'react'

import { StoreView } from '@emico/storeviews'

/**
 * Converts @emico/storeviews StoreView to @emico-utils/storeview-manager StoreView
 * @param activeStoreView
 * @returns
 */
export function useActiveStoreConfigJsonStoreView(
    activeStoreView: StoreView,
): StoreConfigJsonStoreView {
    return useMemo(
        () => ({
            code: activeStoreView.code,
            websiteId: activeStoreView.websiteId,
            storeId: activeStoreView.storeId,
            basename: activeStoreView.basename,
            language: activeStoreView.language,
            locale: activeStoreView.locale ?? `nl-NL`,
            defaultDisplayCurrencyCode:
                activeStoreView.defaultDisplayCurrencyCode ?? 'EUR',
            // This is not used in JB
            rewriteInfo: {
                destination: '',
                source: '',
                locale: true,
            },
        }),
        [
            activeStoreView.basename,
            activeStoreView.code,
            activeStoreView.defaultDisplayCurrencyCode,
            activeStoreView.language,
            activeStoreView.locale,
            activeStoreView.storeId,
            activeStoreView.websiteId,
        ],
    )
}
