import { ClassNames } from '@emotion/react'
import * as React from 'react'

import LogoStandard from './logo.svg?react'
import overridable from '../../utils/overridable'

export interface Props extends React.HTMLAttributes<SVGElement> {
    logo?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    smallLogo?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    footerLogo?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    location?: 'header' | 'header-small' | 'footer'
    variant?: 'light' | 'dark'
}

// Some designs might require showing a different logo in different locations
export const Logo = ({
    logo,
    smallLogo,
    footerLogo,
    location = 'header',
    variant = 'light',
    ...others
}: Props) => {
    let LogoComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>> =
        logo || LogoStandard

    if (smallLogo && location === 'header-small') {
        LogoComponent = smallLogo
    }
    if (footerLogo && location === 'footer') {
        LogoComponent = footerLogo
    }

    return (
        <ClassNames>
            {({ theme }) => {
                const fill =
                    variant === 'dark' ? theme.logoDark : theme.logoLight

                return <LogoComponent {...others} fill={fill} />
            }}
        </ClassNames>
    )
}

export default overridable('Logo')(Logo)
