import { useReactiveVar } from '@apollo/client'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './FloatingContactUsButton.module.scss'
import { isFloatingContactUsButtonVisible } from './useHideFloatingContactUsButton'
import { useCoreConfigValue } from '../../coreConfig.query'
import Icon from '../../media/Icon'
import Link from '../../navigation/Link'
import ChatIcon from '../ChatIcon'
import ContactUsModalTransition from '../ContactUsModal/ContactUsModalTransition'

const FloatingContactUsButton = () => {
    const isVisible = useReactiveVar(isFloatingContactUsButtonVisible)
    const {
        value: floatingContactEnabled,
        loading: loadingFloatingChatEnabled,
    } = useCoreConfigValue('robinhq/chat/enabled')

    const { value: helpUrlKey } = useCoreConfigValue(
        'justbrands_faq/settings/url_key',
        false,
    )

    const isFloatingContactEnabled = floatingContactEnabled === '1'

    const [contactUs, setContactUs] = React.useState<boolean>(false)

    if (
        loadingFloatingChatEnabled ||
        !isFloatingContactEnabled ||
        !helpUrlKey
    ) {
        return null
    }

    return (
        <>
            <Link
                to={helpUrlKey}
                name="Help"
                category="core.floatingContactUs.helpText"
                onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    setContactUs(true)
                }}
                className={cx(styles.default, { [styles.hidden]: !isVisible })}
            >
                <Icon
                    component={ChatIcon}
                    title={t({
                        id: 'core.floatingContactUs.helpText',
                        message: `Questions?`,
                    })}
                    className={styles.chatIcon}
                />
                <div className={styles.divider} />
                <div className={styles.text}>
                    <Trans id="core.floatingContactUs.helpText">
                        Questions?
                    </Trans>
                </div>
            </Link>
            <ContactUsModalTransition
                open={contactUs}
                close={() => setContactUs(false)}
            />
        </>
    )
}

export default FloatingContactUsButton
