import { Picture } from '@emico-react/image'
import cx from 'classnames'
import * as React from 'react'

import styles from './NewsletterContentCard.module.scss'
import SubscribeToNewsletterForm from '../../newsletter/SubscribeToNewsletterForm'
import breakpoints from '../../theme/breakpoints'
import Heading from '../../typography/Heading'
import makeMediaUrl from '../../utils/makeMediaUrl'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    imageUrl: string
    mobileImageUrl?: string
    header?: string
    subheader?: string
    thankYouHeader?: string
    thankYouSubheader?: string
    fluid?: boolean
    source?: string
}

const NewsletterContentCard = ({
    imageUrl,
    mobileImageUrl,
    header,
    subheader,
    fluid = true,
    source,
    thankYouHeader,
    thankYouSubheader,
    ...htmlProps
}: Props) => {
    const [isSubmitted, setSubmitted] = React.useState(false)

    return (
        <section
            {...htmlProps}
            className={cx(styles.card, { [styles.fluid]: fluid })}
        >
            <Picture
                breakpoints={{
                    [breakpoints.xs]: {
                        url: mobileImageUrl
                            ? makeMediaUrl(mobileImageUrl)
                            : makeMediaUrl(imageUrl),
                        width: 350,
                        height: undefined,
                    },
                    [breakpoints.md]: {
                        url: makeMediaUrl(imageUrl),
                        width: 750,
                        height: undefined,
                    },
                    [breakpoints.lg]: {
                        url: makeMediaUrl(imageUrl),
                        width: 900,
                        height: undefined,
                    },
                }}
                lazy
                alt={header || ''}
                className={styles.base}
            />
            <div className={styles.wrapper}>
                {header && (
                    <Heading
                        element="h1"
                        variant="h1"
                        color="light"
                        className={styles.heading}
                    >
                        {isSubmitted && thankYouHeader
                            ? thankYouHeader
                            : header}
                    </Heading>
                )}

                {subheader && (
                    <Heading
                        variant="h4"
                        element="span"
                        color="light"
                        className={styles.subheading}
                    >
                        {isSubmitted && thankYouSubheader
                            ? thankYouSubheader
                            : subheader}
                    </Heading>
                )}

                <SubscribeToNewsletterForm
                    source={source}
                    theme="opaque"
                    onSubmitClick={() => {
                        setSubmitted(true)
                    }}
                />
            </div>
        </section>
    )
}

export default NewsletterContentCard
