import styled from '@emotion/styled'
import * as React from 'react'

import { useCoreConfigObject } from '../../coreConfig.query'
import Link from '../../navigation/Link'
import { Nav } from '../../navigation/Nav'

const MAX_CORE_CONFIG_FOOTER_MENU_ITEMS = 10

const StyledNav = styled(Nav)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`

export const FooterNavigation = () => {
    const { data: coreConfig } = useCoreConfigObject()

    if (!coreConfig) {
        return null
    }

    const menuItems: Array<[string, string]> = []

    for (let i = 1; i <= MAX_CORE_CONFIG_FOOTER_MENU_ITEMS; i += 1) {
        // 1-indexed
        // TODO: Rename `justbrands_footer` to `footer`
        const link = coreConfig[`justbrands_footer/default/link_${i}`]
        const title = coreConfig[`justbrands_footer/default/title_${i}`]

        if (link && title) {
            menuItems.push([link, title])
        }
    }

    if (menuItems.length === 0) {
        return null
    }

    return (
        <StyledNav>
            {menuItems.map(([link, title]) => (
                <Link
                    name={title}
                    category="footerNavigation"
                    key={title}
                    to={link}
                    variant="dark"
                >
                    {title}
                </Link>
            ))}
        </StyledNav>
    )
}

export default FooterNavigation
