import { ComponentProps } from 'react'

import BannerBlocks, { BannerBlocksItem } from './BannerBlocks'
import {
    CastironPageBodyBannerBlocks2,
    JbfoPageBodyBannerBlocks2,
    JustbrandsPageBodyBannerBlocks2,
    MonstersalePageBodyBannerBlocks2,
    PageBodyBannerBlocks2,
    PageBodyBannerBlocks2Fields,
    PageBodyBannerBlocks2Primary,
    PmeOutletPageBodyBannerBlocks2,
    StorelocatorPageBodyBannerBlocks2,
    StorePageBodyBannerBlocks2,
    VanguardPageBodyBannerBlocks2,
} from './graphql/schema.generated'
import ButtonVariant from './types/ButtonVariant'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceBannerBlocksType =
    | PageBodyBannerBlocks2
    | CastironPageBodyBannerBlocks2
    | JustbrandsPageBodyBannerBlocks2
    | VanguardPageBodyBannerBlocks2
    | JbfoPageBodyBannerBlocks2
    | MonstersalePageBodyBannerBlocks2
    | PmeOutletPageBodyBannerBlocks2
    | StorelocatorPageBodyBannerBlocks2
    | StorePageBodyBannerBlocks2

interface Props {
    slice: PrismicSliceBannerBlocksType
}

const PrismicSliceBannerBlocks = ({ slice: { primary, fields } }: Props) => {
    if (!primary) {
        return null
    }

    const { title, style, buttonStyle } =
        primary as unknown as PageBodyBannerBlocks2Primary & {
            buttonStyle?: string
        }

    const bannerBlocks: BannerBlocksItem[] = (
        (fields as PageBodyBannerBlocks2Fields[]) ?? []
    ).reduce<BannerBlocksItem[]>((acc, field) => {
        const { title, image, url, subtitle, buttonText, mobileImage } = field

        acc.push({
            title: title ?? undefined,
            url: url ?? undefined,
            subtitle: subtitle ?? undefined,
            buttonText: buttonText ?? undefined,
            image: image?.url
                ? {
                      url: image.url,
                      alt: image.alt ?? title ?? '',
                  }
                : undefined,
            mobileImage: mobileImage?.url
                ? {
                      url: mobileImage.url,
                      alt: mobileImage.alt ?? title ?? '',
                  }
                : undefined,
        })
        return acc
    }, [])

    const componentProps: ComponentProps<typeof BannerBlocks> = {
        style: getLayoutStyle(style),
        title: title ?? '',
        bannerBlocks,
        buttonVariant:
            buttonStyle === 'highlight'
                ? ButtonVariant.highlight
                : ButtonVariant.primary,
        amount: 2,
    }

    return <BannerBlocks {...componentProps} />
}

export default PrismicSliceBannerBlocks
