import { ApolloClient, FetchResult, gql, useApolloClient } from '@apollo/client'

import withSentry from '../utils/withSentry'

const query = gql`
    mutation subscribeEmailToNewsletter($email: String!, $source: String) {
        subscribeEmailToNewsletter(email: $email, source: $source) {
            status
        }
    }
`

export interface NewsletterEmailSubscribeData {
    SubscribeEmailToNewsletterOutput: {
        status: 'NOT_ACTIVE' | 'SUBSCRIBED' | 'UNSUBSCRIBED' | 'UNCONFIRMED'
    }
}

export const newsletterEmailSubscribe =
    (client: ApolloClient<unknown>) =>
    async (
        email: string,
        source: string,
    ): Promise<FetchResult<NewsletterEmailSubscribeData>> =>
        client.mutate<NewsletterEmailSubscribeData>({
            mutation: query,
            variables: {
                email,
                source,
            },
        })

export const useNewsletterSubscribe = () => {
    const client = useApolloClient()

    return async (email: string, source: string) => {
        try {
            await newsletterEmailSubscribe(client)(email, source)
        } catch (err) {
            // When an error occurs log it and then continue anyway. We
            // don't want to block the process because of the
            // newsletter.
            withSentry((Sentry) => {
                Sentry.captureException(err)
            })
        }
    }
}
