import * as React from 'react'

import ComponentOverridesContext, {
    ComponentConfig,
} from './ComponentOverridesContext'

const overridable =
    (name: keyof ComponentConfig) =>
    <
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TProps extends React.ComponentProps<any>,
    >(
        component: React.ComponentType<TProps>,
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const OverridableComponent = React.forwardRef<any, TProps>(
            (props, ref) => (
                <ComponentOverridesContext.Consumer>
                    {(componentOverridesConfig: ComponentConfig) => {
                        const Component =
                            componentOverridesConfig[name] || component

                        return <Component {...props} ref={ref} />
                    }}
                </ComponentOverridesContext.Consumer>
            ),
        )

        OverridableComponent.displayName = `OverridableComponent(${name})`
        return OverridableComponent
    }

export default overridable
