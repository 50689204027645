import cx from 'classnames'
import { ComponentProps } from 'react'

import CartIconImage from './CartIcon/CartIcon'
import useCartItemsAmount from './useCartItemsAmount'
import Icon from '../media/Icon'
import Link from '../navigation/Link'
import paths from '../paths'

interface Props {
    linkClassName?: string
    className?: string
    linkProps?: OptionalProp<
        Omit<ComponentProps<typeof Link>, 'to' | 'children'>,
        'name' | 'category'
    >
}

const CartStatusIcon = ({ className, linkProps = {}, ...others }: Props) => {
    const numItems = useCartItemsAmount()

    return (
        <Link
            name="Cart"
            category="cartButton"
            {...linkProps}
            to={paths.cart}
            className={cx(linkProps.className)}
            data-testid="header.Cart"
            data-bc-position="shoppingcart"
        >
            <Icon
                component={CartIconImage}
                title="Cart"
                className={className}
                content={
                    numItems > 0 ? (
                        <span
                            data-testid="numCartItems"
                            data-bc-position="carted-items"
                        >
                            {numItems}
                        </span>
                    ) : null
                }
                {...others}
            />
        </Link>
    )
}

export default CartStatusIcon
