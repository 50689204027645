import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

export function isEmpty<T>(value: T) {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value === '') ||
        (typeof value === 'boolean' && value === false)
    )
}

export function validateIfPasswordNeedsToBeChecked<
    T,
    P extends Record<string, unknown>,
>(value: T, values: P) {
    return !values.password ? undefined : checkIfChecked(value)
}

function checkIfChecked<T>(value: T) {
    return value ? undefined : false
}

const checkIfPhonenumber = (value: string) =>
    value.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]{8,14}$/g)

export function validatePhoneNumber<T>(value: T) {
    const isEmptyMessage = t({
        id: 'checkout.contactDetailsPage.phoneEmpty',
        message: `e.g. +447911123456`,
    })

    const isInvalidMessage = t({
        id: 'checkout.contactDetailsPage.phoneRequired',
        message: `Please provide a valid mobile number`,
    })

    return isEmpty(value)
        ? isEmptyMessage
        : typeof value === 'string' && checkIfPhonenumber(value)
          ? undefined
          : isInvalidMessage
}

const validateRequired =
    (message: string) =>
    <T>(value: T) =>
        isEmpty(value) ? message : undefined

export default validateRequired
