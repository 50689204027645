import { RefObject, useEffect, useRef, useState } from 'react'

const useHover = <T extends HTMLElement>(): [RefObject<T>, boolean] => {
    const ref = useRef<T>(null)
    const initialState = Boolean(ref.current && ref.current.matches(':hover'))
    const [value, setValue] = useState(initialState)

    const handleMouseEnter = () => setValue(true)
    const handleMouseLeave = () => setValue(false)

    useEffect(() => {
        const node = ref.current

        if (!node) {
            return
        }

        node.addEventListener('mouseenter', handleMouseEnter)
        node.addEventListener('mouseleave', handleMouseLeave)

        return () => {
            node.removeEventListener('mouseenter', handleMouseEnter)
            node.removeEventListener('mouseleave', handleMouseLeave)
        }
        // TODO: Investigate if this is needed, and if so, if we can fix it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current])

    return [ref, value]
}

export default useHover
