import { defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'justbrands_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.justbrands_nl',
            message: `Netherlands`,
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 9,
        websiteId: 4,
        prismic: 'justbrands',
        prismicLocale: 'nl-nl',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
