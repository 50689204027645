import { useIsLoggedIn } from '@emico-hooks/login-token'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../input/Button'
import { getShopIdFromCookie } from '../layout/Header/TopBar/ShopIdTopBarBanner'
import paths from '../paths'
import theme from '../theme'
import Heading from '../typography/Heading'

export const Warning = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: ${theme.colors.grayDark};
    color: ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
`

export const Spacer = styled.div`
    display: inline-block;
    width: 20px;
    height: 20px;
`

const SRSCartCleanup = () => {
    const shopId = getShopIdFromCookie()
    const history = useHistory()
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const isUserSignedIn = useIsLoggedIn()

    useEffect(() => {
        if (shopId === undefined) {
            return
        }

        const unblock = history.block((location) => {
            if (location.pathname.startsWith('/checkout') || showWarning) {
                return
            }
            if (!isUserSignedIn && shopId) {
                return
            }
            setShowWarning(true)
            return false
        })

        return () => unblock()
    }, [history, isUserSignedIn, shopId, showWarning])

    const handleLeave = useCallback(async () => {
        // Use window.location.href instead of history.push to limit the chance of cached data being present
        window.location.href = paths.logout
    }, [])

    const handleCancel = useCallback(() => {
        setShowWarning(false)
    }, [])

    if (shopId === undefined || !showWarning) {
        return null
    }

    return (
        <Warning>
            <Heading variant="h1" element="h2" color="light">
                <Trans id="srs.cartCleanup.title">Warning</Trans>
            </Heading>
            <p>
                <Trans id="srs.cartCleanup.explanation">
                    Are you sure you want to exit the checkout? Your shopping
                    cart will be emptied to protect your privacy.
                </Trans>
            </p>
            <div>
                <Button
                    minWidth
                    category="srs.cart-cleanup"
                    name="leave"
                    size="large"
                    onClick={handleLeave}
                >
                    <Trans id="srs.cartCleanup.leave">Yes, leave</Trans>
                </Button>
                <Spacer />
                <Button
                    minWidth
                    category="srs.cart-cleanup"
                    name="cancel"
                    variant="white"
                    size="large"
                    onClick={handleCancel}
                >
                    <Trans id="srs.cartCleanup.cancel">Cancel</Trans>
                </Button>
            </div>
        </Warning>
    )
}

export default SRSCartCleanup
