import { HTMLAttributes } from 'react'

import styles from './cart/MiniCartItem.module.scss'
import MiniCartItemContainer from './cart/MiniCartItemContainer'
import MiniCartItemDetails from './cart/MiniCartItemDetails'
import MiniCartItemImage from './cart/MiniCartItemImage'
import MiniCartItemRowTotal from './cart/MiniCartItemRowTotal'
import { useGetBasicProduct } from './catalog/ProductPage/GetBasicProduct.query'
import PageLoader from './presentation/PageLoader'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    className?: string
    productId: number
}

const MiniWishlistItem = ({ className, productId, ...others }: Props) => {
    const { data: product } = useGetBasicProduct(productId)

    if (!product) {
        return <PageLoader className={styles.loader} />
    }

    return (
        <MiniCartItemContainer className={className} {...others}>
            <MiniCartItemImage product={product} />

            <MiniCartItemDetails
                product={product}
                description={product.sku}
                footer={
                    <MiniCartItemRowTotal
                        product={product}
                        totals={{
                            quantity: 1,
                            rowTotalIncludingTax:
                                product.priceRange.maximumPrice.finalPrice
                                    .value ?? 0,
                        }}
                        currency={
                            product.priceRange.maximumPrice.regularPrice
                                .currency
                        }
                    />
                }
                {...others}
            />
        </MiniCartItemContainer>
    )
}

export default MiniWishlistItem
