import styled from '@emotion/styled'
import { ComponentProps } from 'react'

// Import from html-content instead of ui
// The version from ui has a lot of default mappings to other ui components that we dont use.
import { HTMLContent as HTMLContentBase } from '@emico/ui'

import Link from './navigation/Link'
import Heading from './typography/Heading'
import Text from './typography/Text'

const List = styled.ul`
    list-style: initial;
    margin: 0 0 1em 0;
    padding: 0 0 0 1em;
`

const ListItem = styled.li`
    margin: initial;
    padding: initial;
`

const HTMLContent = ({
    allowStyles = false,
    html,
    removeSingleParagraphWrapper,
    ...rest
}: Omit<ComponentProps<typeof HTMLContentBase>, 'componentMapping'>) =>
    html ? (
        <HTMLContentBase
            html={html}
            componentMapping={{
                H1: (node, index, children) => (
                    <Heading variant="h1" element="h1" key={index}>
                        {children}
                    </Heading>
                ),
                H2: (node, index, children) => (
                    <Heading variant="h2" element="h2" key={index}>
                        {children}
                    </Heading>
                ),
                H3: (node, index, children) => (
                    <Heading variant="h3" element="h3" key={index}>
                        {children}
                    </Heading>
                ),
                H4: (node, index, children) => (
                    <Heading variant="h4" element="h4" key={index}>
                        {children}
                    </Heading>
                ),
                H5: (node, index, children) => (
                    <Heading variant="h4" element="h4" key={index}>
                        {children}
                    </Heading>
                ),
                H6: (node, index, children) => (
                    <Heading variant="h4" element="h4" key={index}>
                        {children}
                    </Heading>
                ),
                UL: (node, index, children) => (
                    <List key={index}>{children}</List>
                ),
                OL: (node, index, children) => (
                    <List key={index}>{children}</List>
                ),
                LI: (node, index, children) => (
                    <ListItem key={index}>{children}</ListItem>
                ),
                P: (node, index, children) => (
                    <Text key={index}>{children}</Text>
                ),
                SPAN: (node, index, children) => (
                    <Text key={index}>{children}</Text>
                ),
                A: (node, index, children) => {
                    const a = node as HTMLAnchorElement

                    return (
                        <Link
                            target={a.getAttribute('target') ?? undefined}
                            key={`${index}%%${a.href}`}
                            name=""
                            category=""
                            to={a.getAttribute('href') || ''}
                        >
                            {children}
                        </Link>
                    )
                },
                // The nodes below arent allowed and therefor return empty nodes
                TABLE: () => <></>,
                TR: () => <></>,
                TH: () => <></>,
                TD: () => <></>,
                IMG: () => <></>,
            }}
            removeSingleParagraphWrapper={removeSingleParagraphWrapper}
            allowStyles={allowStyles}
            {...rest}
        />
    ) : (
        <></>
    )

export default HTMLContent
