import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'

export const convertToTagManagerVariants = (
    selectedOptions: ConfigurableAttributesFieldValue,
    product: ConfigurableProduct,
) =>
    Object.entries(selectedOptions).reduce<string[]>(
        (values, [optionId, valueUid]) => {
            const configurableProduct = product as ConfigurableProduct
            // These should always find a result otherwise it makes no sense this is a selected option
            const configurableOption =
                configurableProduct.configurableOptions.find(
                    (configurableOption) =>
                        configurableOption.attributeId === optionId,
                )

            const valueLabel = configurableOption?.values.find(
                (availableValue) => availableValue.uid === valueUid,
            )

            values.push(
                `${configurableOption?.label}:${valueLabel?.storeLabel}`,
            )
            return values
        },
        [],
    )
