import { gql, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { SrsStore as Store } from '../graphql/schema.generated'

export type SrsStore = Pick<
    Store,
    | 'distance'
    | 'storeId'
    | 'shopId'
    | 'skuStock'
    | 'name'
    | 'imageUrl'
    | 'imageUrlMobile'
    | 'imageTitle'
    | 'street'
    | 'number'
    | 'postal'
    | 'city'
    | 'country'
    | 'countryCode'
    | 'jbStore'
    | 'pmeStore'
    | 'ciStore'
    | 'gPhone'
    | 'gLat'
    | 'gLong'
    | 'gTimes'
>

export const srsStoreFragment = gql`
    fragment SrsStoreFragment on SrsStore {
        storeId
        shopId
        distance
        name
        imageUrl
        imageUrlMobile
        imageTitle
        street
        number
        postal
        city
        country
        countryCode
        jbStore
        pmeStore
        ciStore
        gPhone
        gLat
        gLong
        gTimes {
            open
            close
        }
    }
`

const query = gql`
    query SrsStore($id: Int!) {
        srsStoreByShopId(shop_id: $id) {
            ...SrsStoreFragment
        }
    }
    ${srsStoreFragment}
`

export const useSrsStore = (id?: number) => {
    const { data, error, loading, ...rest } = useQuery<{
        srsStoreByShopId: SrsStore
    }>(query, {
        variables: {
            id,
        },
        errorPolicy: 'all',
        context: getCacheableContext(),
        skip: !id,
    })

    return {
        data: data?.srsStoreByShopId,
        loading,
        ...rest,
    }
}
