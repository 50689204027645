import cx from 'classnames'
import * as React from 'react'

import styles from './PageOverlay.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    withBackground?: boolean
    withTransparentBackground?: boolean
}

const PageOverlay = React.forwardRef<HTMLDivElement, Props>(
    function PageOverlay(
        {
            className,
            withBackground,
            withTransparentBackground = true,
            ...props
        }: Props,
        ref,
    ) {
        return (
            <div
                className={cx(
                    styles.backdrop,
                    {
                        [styles.withTransparentBackground]:
                            withTransparentBackground,
                        [styles.withBackground]: withBackground,
                    },
                    className,
                )}
                {...props}
                ref={ref}
            />
        )
    },
)

export default PageOverlay
