import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import cx from 'classnames'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import styles from './AlertModal.module.scss'
import Button from '../../input/Button'
import PageOverlay from '../../layout/PageOverlay'
import Portal from '../../Portal'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'

export interface Props extends React.HTMLAttributes<HTMLElement> {
    title: string
    onCancel(): void
    onConfirm(): void
    onOverlayClick?(): void
    intro?: string
    open?: boolean
    children?: React.ReactNode
    loading?: boolean
    disabled?: boolean
    cancelLabel?: string
    confirmLabel?: string
}

export const AlertModal = forwardRef<HTMLElement, Props>(function AlertModal(
    {
        title,
        intro,
        onCancel,
        onConfirm,
        className,
        children,
        disabled,
        loading,
        cancelLabel = t({
            id: 'core.alertModal.cancel',
            message: `Cancel`,
        }),
        confirmLabel = t({
            id: 'core.alertModal.confirm',
            message: `Confirm`,
        }),
        ...others
    }: Props,
    ref,
) {
    const mainRef = useRef<HTMLDivElement>(null)
    const [isOverflowed, setOverflow] = useState<boolean>(false)

    useEffect(() => {
        if (mainRef.current) {
            const elementHeight = mainRef.current.clientHeight
            const scrollHeight = mainRef.current.scrollHeight

            if (scrollHeight > elementHeight) {
                setOverflow(true)
            }
        }
    }, [title, intro, children])

    const downHandler = useCallback(
        (event: KeyboardEvent) => {
            // See https://developer.mozilla.org/en-US/docs/Web/API/Document/keydown_event
            if (event.isComposing || event.keyCode === 229) {
                return
            }

            if (event.key.startsWith('Esc')) {
                onCancel()
            }

            if (event.key === 'Enter') {
                onConfirm()
            }
        },
        [onCancel, onConfirm],
    )

    useEffect(() => {
        window.addEventListener('keydown', downHandler)

        return () => {
            window.removeEventListener('keydown', downHandler)
        }
    }, [downHandler])

    return (
        <aside className={cx(styles.default, className)} {...others} ref={ref}>
            <div
                className={cx(styles.main, {
                    [styles.overflow]: isOverflowed,
                })}
                ref={mainRef}
            >
                <Heading element="h1" variant="h4" className={styles.heading}>
                    {title}
                </Heading>

                {intro && <Text>{intro}</Text>}

                {children}
            </div>

            <footer className={styles.footer}>
                <Button
                    name="Cancel"
                    category="core.alertModal.cancel"
                    variant="secondary"
                    onClick={onCancel}
                    className={styles.button}
                    wide
                >
                    {cancelLabel}
                </Button>

                <Button
                    name="Close"
                    category="core.alertModal.close"
                    variant="primary"
                    onClick={onConfirm}
                    className={styles.button}
                    wide
                    disabled={disabled || loading}
                >
                    {confirmLabel}
                </Button>
            </footer>
        </aside>
    )
})
const AlertModalTransition = ({ open, onOverlayClick, ...props }: Props) =>
    open ? (
        <Portal>
            <div className={cx(styles.overlay)}>
                <AlertModal {...props} />
                <PageOverlay
                    onClick={onOverlayClick}
                    className={styles.pageOverlay}
                />
            </div>
        </Portal>
    ) : null

export default AlertModalTransition
