import {
    useQuery,
    gql,
    TypedDocumentNode,
    QueryHookOptions,
} from '@apollo/client'
import { cookie } from '@prismicio/client'

import { getCacheableContext } from '@emico-utils/graphql-data-utils'
import { useActiveStoreView } from '@emico/storeviews'
import { getCookie } from '@emico/utils'

import { PrismicDocument } from './usePrismicDocument'
import {
    PrismicDocumentsQuery,
    PrismicDocumentsQueryVariables,
} from './usePrismicDocuments.generated'

const query = gql`
    query PrismicDocuments(
        $storeView: String
        $key: String!
        $values: [String!]!
        $lang: String!
        $ref: String
    ) {
        prismicDocuments(
            storeView: $storeView
            predicate: { key: $key, values: $values }
            lang: $lang
            ref: $ref
        ) {
            data
        }
    }
` as TypedDocumentNode<PrismicDocumentsQuery, PrismicDocumentsQueryVariables>

const usePrismicDocuments = <T,>(
    predicate: {
        key: string
        values: string[]
    },
    storeSpecific = true,
    options?: Omit<
        QueryHookOptions<PrismicDocumentsQuery, PrismicDocumentsQueryVariables>,
        'variables'
    >,
) => {
    const activeStoreView = useActiveStoreView()
    const ref = getCookie(cookie.preview) || undefined

    const { data, loading } = useQuery(query, {
        ...options,
        fetchPolicy: ref ? 'no-cache' : options?.fetchPolicy,
        errorPolicy: 'all',
        variables: {
            ref: ref ?? null,
            key: predicate.key,
            values: predicate.values,
            lang:
                activeStoreView.prismicLocale ??
                activeStoreView.locale ??
                'en-US',
            storeView:
                storeSpecific && activeStoreView.prismic
                    ? activeStoreView.prismic
                    : null,
        },
        context: getCacheableContext(),
    })

    return {
        data: data?.prismicDocuments as Array<PrismicDocument<T>>,
        loading,
    }
}

export default usePrismicDocuments
