import { useEffect } from 'react'

import { pushEnhancedConversionEmailHash } from './usePushEnhancedConversionEmailHash'
import { useGetCustomer } from '../../customer/getCustomer.query'

export function PushEnhancedConversionEmail() {
    const { data: customer } = useGetCustomer()

    useEffect(() => {
        if (!customer?.email) {
            return
        }
        pushEnhancedConversionEmailHash(customer.email)
    }, [customer?.email])

    return null
}
