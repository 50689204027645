const colors = {
    white: '#fff',
    grayLight: '#f8f8f8',
    border: '#ededed',
    text: '#141414',
    colorSale: '#c0392b',
    muted: '#9d9d9d',
    logoDark: '#141414',
    logoLight: '#ffffff',
}

type Flexible<T> = {
    [Prop in keyof T]: T[Prop] extends string ? string : T[Prop]
}

export type Colors = Flexible<typeof colors>

export type Theme = Colors

const defaultTheme: Theme = {
    ...colors,
}

// Expose a variable that can be used in object asign below
// This variable will be changed dynamically by the overrideTheme function
const theme = {
    ...defaultTheme,
}

/**
 * This function overrides theme values in the theme. Very basic theme values
 * are defined in this theme like primary color. This function just replaces
 * the values defined in theme, with the properties and values in newTheme.
 *
 * Styling might change in the future, so even while this function is very
 * simple, it allows us to change it in the future but with type checking
 *
 * @param newTheme Theme variables to override in the theme
 */
export function overrideTheme(newTheme: Partial<Theme>) {
    Object.assign(theme, newTheme)
}

// Expose the default theme
export { defaultTheme }

// Expose the theme with possible overrides
export default theme
