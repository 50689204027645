import { makeVar } from '@apollo/client'
import { useEffect } from 'react'

import { syncReactiveVar } from '@emico/reactive-var-utils'

import { useGetCustomer } from './getCustomer.query'

const customerGroupVar = syncReactiveVar(makeVar(0), 'customer/group')

export function useCustomerGroupSync() {
    const { data } = useGetCustomer()

    useEffect(() => {
        const customerGroupCss = document.getElementById('customer-group-css')

        if (customerGroupCss && data?.groupId != null) {
            const customerGroup = Number(data?.groupId)
            const newStyle = `[data-hide-customer-group~="${customerGroup}"]{--display: none;}[data-display-customer-group]{--display: none;}[data-display-customer-group~="${customerGroup}"]{--display: initial;}`
            if (customerGroupCss.innerHTML.trim() !== newStyle.trim()) {
                customerGroupCss.innerHTML = newStyle
            }
        }
    }, [data?.groupId])

    customerGroupVar(data?.groupId ?? 0)
}
