import styles from './maveclip.module.css'
import { MaveLoader } from './MaveLoader'
import { MaveClipProps } from '../types/mave-io'

export const MaveClip = (props: MaveClipProps) => (
    <MaveLoader>
        <mave-clip
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            class={styles.clip}
            {...props}
            autoplay="always"
            muted
            onloadedmetadata="this.muted = true"
        ></mave-clip>
    </MaveLoader>
)
