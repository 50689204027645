import { useCart } from '@emico-hooks/cart'
import { useMemo } from 'react'

import {
    CartItemWithOptions,
    getCartItemBySelectedAttributes,
} from '../../checkout/getCartItemBySelectedAttributes'
import { ConfigurableAttributesFieldValue } from '../ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../ProductPage/ConfigurableProduct'
import { Product } from '../ProductPage/GetProduct.query'

/**
 * Check if a product is in cart
 * @param product A Product (configurable or not)
 * @param selectedOptions Selected product options
 */
export default function useIsProductInCart(
    product: Product | ConfigurableProduct,
    selectedOptions: ConfigurableAttributesFieldValue,
) {
    const { data } = useCart()

    const cartItems = (data?.items as CartItemWithOptions[]) ?? []

    // Is product in cart?
    const cartItemProducts = cartItems.filter(
        (item) => item?.product?.id === product.id,
    )

    return useMemo(
        () =>
            !cartItemProducts.length
                ? false
                : getCartItemBySelectedAttributes(
                      cartItemProducts,
                      selectedOptions,
                  ),
        [cartItemProducts, selectedOptions],
    )
}
