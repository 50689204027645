import { Trans } from '@lingui/macro'

import Button, { ButtonProps } from '../input/Button'

export const EditGiftDeselectButton = (
    props: Omit<ButtonProps, 'name' | 'category'>,
) => (
    <Button variant="linkInverted" {...props} name="" category="">
        <Trans id="cart.gifts.modal.deselect">Deselect this gift</Trans>
    </Button>
)
