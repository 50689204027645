import { Trans } from '@lingui/macro'

import styles from './EditGiftsModal.module.scss'
import CheckIcon from '../core/CheckIcon'
import Button, { ButtonProps } from '../input/Button'

export const EditGiftAppliedButton = (
    props: Omit<ButtonProps, 'name' | 'category'>,
) => (
    <Button name="" category="" minWidth disabled {...props}>
        <CheckIcon className={styles.icon} />
        <Trans id="cart.gifts.modal.applied">Toegevoegd</Trans>
    </Button>
)
