import { gql, makeVar, useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'

import { useDebounce } from '@emico/utils'

import { DhlParcelShippingServicePointsResult } from '../../graphql/schema.generated'

export const pickupPointZipCodeVar = makeVar<string | undefined>(undefined)

const query = gql`
    query DHLParcelShippingServicePoints(
        $country: String!
        $postcode: String!
        $limit: Int
    ) {
        dhlParcelShippingServicePoints(
            country: $country
            postcode: $postcode
            limit: $limit
        ) {
            items {
                id
                name
                keyword
                address {
                    countryCode
                    postalCode
                    city
                    street
                    number
                    isBusiness
                    addition
                }
                geoLocation {
                    latitude
                    longitude
                }
                distance
                openingTimes {
                    timeFrom
                    timeTo
                    weekDay
                }
                shopType
                country
            }
        }
    }
`

export function useDHLParcelShippingServicePoints(
    country: string,
    postcode: string,
) {
    const client = useApolloClient()
    const [result, setResult] =
        useState<DhlParcelShippingServicePointsResult | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const debouncedPostcode = useDebounce(postcode, 500)

    useEffect(() => {
        if (!debouncedPostcode || debouncedPostcode.length < 4) {
            return
        }
        pickupPointZipCodeVar(debouncedPostcode)

        async function runQuery() {
            setIsLoading(true)
            const result = await client.query<{
                dhlParcelShippingServicePoints: DhlParcelShippingServicePointsResult
            }>({
                query,
                variables: {
                    country,
                    postcode: debouncedPostcode,
                },
            })

            setResult(result.data?.dhlParcelShippingServicePoints ?? null)
            setIsLoading(false)
        }
        runQuery()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, debouncedPostcode])

    return { result: result?.items, isLoading }
}
