import { PureComponent, ReactNode } from 'react'
import ReactDOM from 'react-dom'

import PortalTarget from './PortalTarget'

interface Props {
    children: ReactNode
}
interface State {
    elem?: HTMLElement
}

class Portal extends PureComponent<Props, State> {
    state = {
        elem: undefined,
    }
    componentDidMount() {
        this.setState({
            elem: PortalTarget.createElement(),
        })
    }
    componentWillUnmount() {
        if (this.state.elem) {
            PortalTarget.remove(this.state.elem)
        }
    }

    render() {
        if (!this.state.elem) {
            return null
        }
        return ReactDOM.createPortal(this.props.children, this.state.elem)
    }
}

export default Portal
