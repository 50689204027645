import Cookies from 'universal-cookie'

const HIGHSTREET_TOKEN_COOKIE_NAME = 'ns_access_token'

export const getHighStreetToken = (): string | undefined => {
    const cookies = new Cookies()
    const token = cookies.get(HIGHSTREET_TOKEN_COOKIE_NAME) as
        | string
        | undefined

    return token || undefined
}
