import { globalWindow } from '@emico/ssr-utils'

const detectTouchscreen = () => {
    let result = false

    if (
        navigator &&
        globalWindow &&
        globalWindow.PointerEvent &&
        'maxTouchPoints' in navigator
    ) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            result = true
        }
    } else {
        // no Pointer Events...
        if (
            globalWindow?.matchMedia &&
            globalWindow?.matchMedia('(any-pointer:coarse)').matches
        ) {
            // check for any-pointer:coarse which mostly means touchscreen
            result = true
        } else if (
            globalWindow &&
            (globalWindow.TouchEvent || 'ontouchstart' in globalWindow)
        ) {
            // last resort - check for exposed touch events API / event handler
            result = true
        }
    }
    return result
}

export default detectTouchscreen
