import { ApolloClient, gql } from '@apollo/client'

import { getCustomerTokenHeaderObject } from '@emico/login-token'

import { Address, fragments } from './getAddresses.query'
import {
    Maybe,
    MutationUpdateCustomerAddressArgs,
} from '../graphql/schema.generated'

const UPDATE_ADDRESS = gql`
    mutation UpdateCustomerAddress($id: Int!, $input: CustomerAddressInput!) {
        customerAddress: updateCustomerAddress(id: $id, input: $input) {
            ...Address
        }
    }
    ${fragments.address}
`

export interface UpdateAddressData {
    customerAddress: Maybe<Address>
}

export type UpdateAddressVariables = MutationUpdateCustomerAddressArgs

export const updateAddressTelephone = async (
    client: ApolloClient<unknown>,
    token: string | null,
    id: number,
    phone: string,
) =>
    client.mutate({
        mutation: UPDATE_ADDRESS,
        variables: {
            id,
            input: {
                telephone: phone,
            },
        },
        context: getCustomerTokenHeaderObject(token),
    })

export default UPDATE_ADDRESS
