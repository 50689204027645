import { ComponentProps } from 'react'

import {
    CastironPageBodyTextOnly,
    JbfoPageBodyTextOnly,
    JustbrandsPageBodyTextOnly,
    MonstersalePageBodyTextOnly,
    PageBodyTextOnly,
    PmeOutletPageBodyTextOnly,
    StorelocatorPageBodyTextOnly,
    StorePageBodyTextOnly,
    VanguardPageBodyTextOnly,
} from './graphql/schema.generated'
import PrismicTextOnly from './PrismicTextOnly'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceTextOnlyType =
    | PageBodyTextOnly
    | VanguardPageBodyTextOnly
    | CastironPageBodyTextOnly
    | JustbrandsPageBodyTextOnly
    | JbfoPageBodyTextOnly
    | MonstersalePageBodyTextOnly
    | PmeOutletPageBodyTextOnly
    | StorePageBodyTextOnly
    | StorelocatorPageBodyTextOnly

interface Props {
    slice: PrismicSliceTextOnlyType
}

const PrismicSliceTextOnly = ({ slice: { primary } }: Props) => {
    if (!primary || !primary?.content) {
        return null
    }

    const {
        style,
        heading,
        content,
        tablet,
        desktop,
        buttonUrl,
        buttonText,
        visibility,
    } = primary

    const componentProps: ComponentProps<typeof PrismicTextOnly> = {
        style: getLayoutStyle(style),
        heading: heading ?? undefined,
        content: content ?? undefined,
        tablet: tablet ?? undefined,
        desktop: desktop ?? undefined,
        buttonUrl: buttonUrl ?? undefined,
        buttonText: buttonText ?? undefined,
        visibility: visibility ?? undefined,
    }

    return <PrismicTextOnly {...componentProps} />
}

export default PrismicSliceTextOnly
