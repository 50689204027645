import { useApolloClient } from '@apollo/client'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ImaginaryActions, useBreakpoints } from '@emico/ui'

import styles from './Colorswatches.module.scss'
import { getProduct } from './GetProduct.query'
import {
    ProductStockStatus,
    UrlRewriteEntityTypeEnum,
} from '../../graphql/schema.generated'
import ImageLazy from '../../media/ImageLazy/ImageLazy'
import Link from '../../navigation/Link'
import { RelatedProduct } from '../../ProductCardFragment'

interface Props {
    productId: RelatedProduct['id']
    products?: RelatedProduct[]
    onSwatchMouseOver?: (smallImage: RelatedProduct['smallImage']) => void
    onSwatchMouseOut?: () => void
}

const ColorSwatches = ({
    productId,
    products,
    onSwatchMouseOver,
    onSwatchMouseOut,
}: Props) => {
    const history = useHistory()
    const location = useLocation()
    const apolloClient = useApolloClient()
    const [loading, setLoading] = React.useState<boolean>(false)
    const { isDesktop } = useBreakpoints()
    const swatches = products?.filter((swatch) =>
        Boolean(swatch.smallImage?.url),
    )

    const [activeSwatchId, setActiveSwatchId] =
        React.useState<number>(productId)

    React.useEffect(() => {
        setActiveSwatchId(productId)
    }, [productId])

    if (!swatches || swatches.length === 0) {
        return null
    }

    const handleMouseOut = (id: RelatedProduct['id']) => () => {
        if (onSwatchMouseOut) {
            onSwatchMouseOut()
        }
    }

    const handleMouseOver =
        (id: RelatedProduct['id'], smallImage: RelatedProduct['smallImage']) =>
        () => {
            if (activeSwatchId !== id && !loading) {
                if (onSwatchMouseOver) {
                    onSwatchMouseOver(smallImage)
                }
                getProduct(apolloClient, id)
            }
        }

    const handleClick =
        (id: RelatedProduct['id'], to: string) =>
        async (e: React.MouseEvent) => {
            e.preventDefault()
            setLoading(true)

            setActiveSwatchId(id)

            // Preload the data so we wont get a full page loader
            // Instead, manage loading state locally
            await getProduct(apolloClient, id)
            setLoading(false)

            window.scrollTo(0, 0)

            history.push(to, {
                backUrl: location.pathname,
            })
        }

    return (
        <React.Fragment>
            <div className={styles.colorSwatches}>
                {[...swatches]
                    .sort((a, b) => a.id - b.id)
                    .map(
                        ({ id, smallImage, urlKey, stockStatus }) =>
                            smallImage?.url && (
                                <Link
                                    key={id}
                                    to={`/${urlKey}`}
                                    name=""
                                    category="catalog.colorSwatches"
                                    state={{ scrollRestoration: false }}
                                    onClick={handleClick(id, `/${urlKey}`)}
                                    resolver={{
                                        type: UrlRewriteEntityTypeEnum.PRODUCT,
                                        id,
                                        redirectCode: 0,
                                    }}
                                    title={
                                        stockStatus ===
                                        ProductStockStatus.OUT_OF_STOCK
                                            ? t({
                                                  id: 'catalog.productCard.colorOutOfStock',
                                                  message: `This color is out of stock`,
                                              })
                                            : undefined
                                    }
                                    onMouseOver={
                                        isDesktop
                                            ? handleMouseOver(id, smallImage)
                                            : undefined
                                    }
                                    onMouseOut={
                                        isDesktop
                                            ? handleMouseOut(id)
                                            : undefined
                                    }
                                >
                                    <div
                                        className={cx(styles.swatch, {
                                            [styles.active]:
                                                activeSwatchId === id,
                                            [styles.loading]:
                                                loading &&
                                                stockStatus !==
                                                    ProductStockStatus.OUT_OF_STOCK,
                                            [styles.colorOutOfStock]:
                                                stockStatus ===
                                                ProductStockStatus.OUT_OF_STOCK,
                                        })}
                                        data-bc-position="pdp-colorswatches"
                                    >
                                        <ImageLazy
                                            className={styles.imageWrapper}
                                            imageClassName={styles.image}
                                            images={{
                                                image: smallImage?.url,
                                                action: ImaginaryActions.RESIZE,
                                                regularWidth: 46,
                                                regularHeight: 46,
                                                mediumWidth: 37,
                                                mediumHeight: 37,
                                                smallWidth: 37,
                                                smallHeight: 37,
                                            }}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                            ),
                    )}
            </div>
        </React.Fragment>
    )
}

export default ColorSwatches
