import styled from '@emotion/styled'
import * as React from 'react'

import UserDetailsIcon from '../../account-dashboard-page/icons/UserDetailsIcon'

export interface Props {
    children: React.ReactNode
    className?: string
    variant?: 'default' | 'noBackground'
    autoHide?: boolean
}

const ERROR_VISIBILITY_DURATION = 5000

const Wrapper = styled.div<{ variant: Props['variant'] }>`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    ${(props) =>
        props.variant === 'default'
            ? 'background: #fcecde; padding: 22px 16px; margin-top: -5px;'
            : ''}
    width: 100%;
    color: #935015;
    font-size: 13px;
    line-height: 18px;
`

const IconContainer = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-right: 14px;
`

const Message = styled.div`
    flex: 1 1 auto;
`

const Icon = styled(UserDetailsIcon)`
    color: #fff;
    transform: rotate(180deg);
    height: 22px;
    width: 22px;
    fill: #eb9447;
`

/**
 * Used in forms for form-wide errors.
 */
const FormError = ({
    className,
    children,
    variant = 'default',
    autoHide = true,
}: Props) => {
    const [isVisible, setVisibility] = React.useState(true)

    React.useEffect(() => {
        if (!autoHide) {
            return
        }

        const timer = setTimeout(
            () => setVisibility(false),
            ERROR_VISIBILITY_DURATION,
        )

        return () => clearTimeout(timer)
    }, [isVisible, autoHide])

    React.useEffect(() => {
        setVisibility(true)
    }, [children])

    return isVisible ? (
        <Wrapper data-cy="FormError" className={className} variant={variant}>
            <IconContainer>
                <Icon />
            </IconContainer>
            <Message data-cy="FormError/content">{children}</Message>
        </Wrapper>
    ) : null
}

export default FormError
