import { useWhenVisible } from '@emico-utils/when-visible'

import { usePushEcommerceEvent } from './usePushEvent'
import { GA4PromotionItem } from './useSelectPromotionEvent'

export const useViewPromotionEvent = <T extends HTMLElement>(
    item: GA4PromotionItem,
) => {
    const push = usePushEcommerceEvent([], false)

    return useWhenVisible<T>(
        () => {
            push?.([
                { ecommerce: null },
                {
                    event: 'view_promotion',
                    ecommerce: {
                        ...item,
                        items: [],
                    },
                },
            ])
        },
        ({ isIntersecting }) => isIntersecting,
        { runOnce: true, threshold: 0.8 },
    )
}
