import { makeVar } from '@apollo/client'
import { css } from '@emotion/react'
import cx from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'

import { Icon as EmicoIcon } from '@emico/ui'

import styles from './TopBarUspBanner.module.scss'
import CloseIcon from '../../../core/CloseIcon'
import Button from '../../../input/Button'
import Link from '../../../navigation/Link/Link'
import Text from '../../../typography/Text'
import useUsps, { Usp } from '../../../useUsps'
import push from '../../../utils/googleTagManager/push'

const HIDE_USP_BANNER_KEY = 'hide-usp-banner'

interface TopBannersUsp {
    value?: number | undefined
}

interface UspItemProps {
    item: Usp
    active: boolean
}

// Css animation duration in milliseconds
// This needs to match the usp item animation duration
const USP_CSS_ANIMATION_DURATION = 6000

export const topBannersUsp = makeVar<TopBannersUsp | undefined>(undefined)

const UspItem = ({ item, active }: UspItemProps) => {
    const { label, url } = item

    if (!label) {
        return null
    }

    return (
        <div className={cx(styles.uspItem, { [styles.active]: active })}>
            {url ? (
                <Link
                    to={url.trim()}
                    name={label}
                    category="topbar.usp"
                    className={styles.link}
                >
                    <Text as="span" className={styles.label}>
                        {label}
                    </Text>
                </Link>
            ) : (
                <Text as="span" className={styles.label}>
                    {label}
                </Text>
            )}
        </div>
    )
}

const cookies = new Cookies()

const setHidden = (value: boolean) => {
    if (value) {
        const date = new Date()
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000)
        cookies.set(HIDE_USP_BANNER_KEY, '1', {
            path: '/',
            expires: date,
        })
        document.documentElement.style.setProperty('--display-usp', 'none')
    } else {
        cookies.remove(HIDE_USP_BANNER_KEY)
        document.documentElement.style.removeProperty('--display-usp')
    }
}

const TopBarUspBanner = () => {
    const { data } = useUsps('top')
    const usps = [...data].slice(0, 3) // to make sure there are only 3 usps
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const countRef = useRef(activeIndex)

    countRef.current = activeIndex
    const isHiddenByUser = cookies.get(HIDE_USP_BANNER_KEY) === '1'

    const enableAnimation = usps.length > 1

    useEffect(() => {
        if (usps.length > 0) {
            topBannersUsp({
                value: 1,
            })
        }

        // only animate when there are multiple items
        const timer = enableAnimation
            ? setInterval(() => {
                  setActiveIndex(
                      countRef.current === usps.length - 1
                          ? 0
                          : countRef.current + 1,
                  )
              }, USP_CSS_ANIMATION_DURATION)
            : undefined

        if (!isHiddenByUser) {
            setHidden(false)
        }

        return () => timer && clearInterval(timer)
    }, [enableAnimation, isHiddenByUser, usps.length])

    const handleCloseClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.stopPropagation()

        push({ event: 'closeUSPbar' })

        // Set a cookie that expires 30 days from now
        setHidden(true)
    }

    useEffect(() => {
        topBannersUsp({
            value: isHiddenByUser ? 0 : 1,
        })
    }, [isHiddenByUser])

    if (!usps || usps.length === 0) {
        return null
    }

    return (
        <div className={styles.base} data-id="usptopBanner">
            {usps.map((item, i) => (
                <UspItem item={item} key={i} active={activeIndex === i} />
            ))}
            <Button
                className={styles.close}
                variant="default"
                onClick={handleCloseClick}
                name="Close"
                category="core.topbarbanner.close"
            >
                <EmicoIcon
                    component={CloseIcon}
                    css={css`
                        width: 10px;
                        height: 10px;
                    `}
                />
            </Button>
        </div>
    )
}

export default TopBarUspBanner
