import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { CheckIcon } from '@emico/icons'

import optionStyles from './Option.module.scss'
import { MAX_COLS, OptionsType } from './Options'
import styles from './Options.module.scss'
import Heading from '../../typography/Heading'
import { getContrastColor } from '../../utils/getContrastColor'

export const ColorOptions = ({ answers, isMultiSelect }: OptionsType) => {
    const { register } = useFormContext()
    const gridRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // desktop grid cols depends on the number of answers:
        // mobile: 2 cols always
        // desktop:
        const divider = Math.min(
            answers?.length ?? 0,
            MAX_COLS,
            answers?.length ?? 0 % MAX_COLS,
        )

        gridRef.current?.style.setProperty(
            '--num-cols',
            `${Math.max(divider, MAX_COLS / (divider || 1))}`,
        )
    }, [answers?.length])

    if (!answers) {
        return null
    }
    return (
        <div className={styles.grid} ref={gridRef}>
            {answers?.map((answer) => {
                // Example: Beige_#cdbd90
                const { answer: answerString } = answer
                const [label, value] = (answerString || '').split('_', 2)

                let contrastColor: 'dark' | 'light' = 'light'

                try {
                    contrastColor = getContrastColor(value)
                } catch {
                    // no-op
                }

                return (
                    <label
                        key={answer.answerId}
                        className={classNames(
                            optionStyles.option,
                            optionStyles.minHeight,
                        )}
                        style={{
                            backgroundColor: value,
                        }}
                    >
                        <span className={optionStyles.selectedIndicator}>
                            <CheckIcon />
                        </span>
                        <input
                            {...register?.('question')}
                            value={answer.answerId}
                            type={isMultiSelect ? 'checkbox' : 'radio'}
                        />
                        <Heading
                            color={contrastColor}
                            variant="h3"
                            element="h3"
                            className={classNames(optionStyles.centered)}
                        >
                            {label}
                        </Heading>
                    </label>
                )
            })}
        </div>
    )
}
