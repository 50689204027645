export default function parseMagentoDateTime(date: string): Date {
    const parts = date.split(/[^0-9]/) as unknown as number[]

    return new Date(
        parts[0],
        parts[1] - 1,
        parts[2],
        parts[3],
        parts[4],
        parts[5],
    )
}
