import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { BasicConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'

/**
 * Get the product variant that matches the selected options
 * @param product The product to check
 * @param selectedOptions The selected options
 * @returns Returns the product variant that matches the selected options
 */
export const getProductVariantBySelectedOptions = (
    product: BasicConfigurableProduct,
    selectedOptions: ConfigurableAttributesFieldValue,
) => {
    const selectedUids = Object.values(selectedOptions)

    return product.variants.find((variant) => {
        const attributeUids = variant?.attributes?.map((attr) => attr.uid)
        if (selectedUids.every((uid) => attributeUids.includes(uid))) {
            return variant
        }
        return null
    })
}
