import { usePrismicDocument } from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'

import { PsRegistrationBox } from './graphql/schema.generated'

const usePrismicPersonalShopper = () => {
    const activeStoreView = useActiveStoreView()

    return usePrismicDocument<PsRegistrationBox>(
        {
            key: 'my.ps_registration_box.storeview',
            value: activeStoreView.prismic || 'pme',
        },
        true,
    )
}

export default usePrismicPersonalShopper
