import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

import theme from './theme'
import { varKeys as colorVarKeys } from './theme/colors'

export type LayoutStyle = 'light' | 'dark'

const light = css`
    ${colorVarKeys.headings}: ${theme.colors.lightHeadings};
    ${colorVarKeys.text}: ${theme.colors.lightText};
    ${colorVarKeys.background}: ${theme.colors.lightBackground};
`

const dark = css`
    ${colorVarKeys.headings}: ${theme.colors.darkHeadings};
    ${colorVarKeys.text}: ${theme.colors.darkText};
    ${colorVarKeys.background}: ${theme.colors.darkBackground};
`

export const DarkMode = styled.div`
    display: contents;
    ${dark}
`

export const LightMode = styled.div`
    display: contents;
    ${light}
`

const getLayoutStyleVars = (
    style: LayoutStyle,
): SerializedStyles | undefined => (style === 'dark' ? dark : light)

export default getLayoutStyleVars
