import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { getConfigurableAttributeLabel } from '../catalog/ProductPage/ConfigurableAttributesField/getConfigurableAttributeLabel'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import {
    getConfigurableProductPrices,
    getPrices,
} from '../presentation/PriceBox'

const getWishlistGtmData = (
    product: Product | ConfigurableProduct,
    value?: ConfigurableAttributesFieldValue,
) => {
    const isConfigurable = Boolean((product as ConfigurableProduct).variants)
    const prices = isConfigurable
        ? getConfigurableProductPrices(product as ConfigurableProduct)
        : getPrices(product)

    const variantLabel = isConfigurable
        ? value
            ? getConfigurableAttributeLabel(
                  product as ConfigurableProduct,
                  value,
              )
            : 'no size selected'
        : 'one size'

    return {
        currencyCode: product.priceRange?.maximumPrice?.finalPrice?.currency,
        productID: product.sku,
        productName: product.name,
        productPrice: prices
            ? prices.specialPrice?.value
                ? String(prices.oldPrice?.value)
                : String(prices.actualPrice?.value)
            : undefined,
        productSaleprice:
            prices && prices.specialPrice?.value
                ? String(prices.specialPrice.value)
                : undefined,
        brand: product.brand,
        variant: `size:${variantLabel}`,
        stockStatus: product.stockStatus,
    }
}

export const addToWishlist = (
    product: Product | ConfigurableProduct,
    value?: ConfigurableAttributesFieldValue,
): { [key: string]: string | undefined } => ({
    event: 'addToWishlist',
    ...getWishlistGtmData(product, value),
})

export const removeFromWishlist = (
    product: Product | ConfigurableProduct,
    value?: ConfigurableAttributesFieldValue,
): { [key: string]: string | undefined } => ({
    event: 'removeFromWishlist',
    ...getWishlistGtmData(product, value),
})
