import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { plural, Plural, t } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import styles from './ProductCard.module.scss'
import ProductStockStatus from './ProductStockStatus'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { isGroupedProduct } from '../../catalog/ProductPage/GetProduct.query'
import { getInStockProductsForGroupedProduct } from '../../catalog/ProductPage/LayoutTop/LayoutTop'
import { WISHLIST_DISABLED } from '../../constants'
import getProductLabelInfo from '../../getProductLabelInfo'
import {
    ProductStockStatus as ProductStockStatusEnum,
    UrlRewriteEntityTypeEnum,
} from '../../graphql/schema.generated'
import Link from '../../navigation/Link'
import { ProductCardFragment, RelatedProduct } from '../../ProductCardFragment'
import Text from '../../typography/Text'
import { BrandMapper } from '../../utils/BrandMapper'
import { useViewItemListEvent } from '../../utils/ga4/useViewItemEvent'
import WishlistIconButton from '../../wishlist/WishlistIconButton'
import PriceBox, {
    getConfigurableProductPrices,
    getPrices,
    Prices,
} from '../PriceBox'
import ProductLabel from '../ProductLabel'

const SHOW_PRODUCT_BRAND = process.env.REACT_APP_SHOW_PRODUCT_BRAND === 'true'

const InfoWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
`

const StyledProductLabel = styled(ProductLabel)`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
`

export enum ProductCardThemesType {
    default = 'default',
    shaded = 'shaded',
}
export type OwnProps = {
    product: ProductCardFragment
    position?: 'pdp' | 'pop'
    image_label?: string
    theme?: ProductCardThemesType
    className?: string
    withStockStatus?: boolean
    withColorSwatch?: boolean
    withBookmark?: boolean
    initialValue?: ConfigurableAttributesFieldValue
    children?: React.ReactNode
    onClick?(product: ProductCardFragment): void
    onMouseEnter?(product: ProductCardFragment): void

    // GA4 props
    itemListId: string
    itemListName: string
    itemListSlot: string
    itemIndex: number
} & React.RefAttributes<HTMLDivElement>

export type Props = OwnProps

const renderPriceBox = (
    prices: Prices | undefined,
    product: ProductCardFragment,
) => {
    if (!prices) {
        return null
    }
    return (
        <>
            <PriceBox
                showPercentOff
                showLowestPriceInfo
                product={product}
                {...prices}
                className={styles.priceBox}
                priceClassName={styles.prices}
                outletAdviceprice={product?.outletAdviesprijs ?? null}
            />
        </>
    )
}

const ColorSwatch = ({
    product,
    onSwatchMouseEnter,
    onSwatchMouseLeave,
}: {
    product: ProductCardFragment
    onSwatchMouseEnter: (
        smallImage: ProductCardFragment['smallImage'],
    ) => () => void
    onSwatchMouseLeave: () => void
}) => {
    const visibleSwatchCount = 5
    const relatedProducts = product.relatedProducts ?? []
    const swatches = relatedProducts.reduce<
        Array<
            Pick<RelatedProduct, 'id' | 'smallImage' | 'stockStatus' | 'urlKey'>
        >
    >(
        (acc, product) => {
            if (!product.smallImage?.url) {
                return acc
            }

            const { id, smallImage, stockStatus, urlKey } = product

            acc.push({
                id,
                smallImage,
                stockStatus,
                urlKey,
            })

            return acc
        },
        [
            {
                id: product.id,
                smallImage: product.smallImage,
                stockStatus: product.stockStatus,
                urlKey: product.urlKey,
            },
        ],
    )

    if (swatches.length < 2) {
        return null
    }

    const remainingSwatchesCount = swatches.slice(visibleSwatchCount).length

    return (
        <div
            className={styles.colorSwatchWrapper}
            data-bc-position="colorswatches"
        >
            {swatches
                .slice(
                    0,
                    visibleSwatchCount + (remainingSwatchesCount === 1 ? 1 : 0),
                )
                .map(
                    ({ id, smallImage, stockStatus, urlKey }) =>
                        smallImage?.url && (
                            <Link
                                key={id}
                                className={styles.colorSwatch}
                                name={`${id}`}
                                category="productCardColorSwatch"
                                to={`/${urlKey}`}
                                title={product.name}
                                onMouseEnter={onSwatchMouseEnter(smallImage)}
                                onMouseLeave={onSwatchMouseLeave}
                                resolver={{
                                    type: UrlRewriteEntityTypeEnum.PRODUCT,
                                    id: id,
                                }}
                            >
                                <Image
                                    url={smallImage.url}
                                    width={36}
                                    height={36}
                                    params={{
                                        resizingType: 'fill-down',
                                    }}
                                    alt={product.name ?? ''}
                                    lazy
                                    className={cx(styles.colorSwatchImage, {
                                        [styles.colorOutOfStock]:
                                            stockStatus ===
                                            ProductStockStatusEnum.OUT_OF_STOCK,
                                    })}
                                    title={
                                        stockStatus ===
                                        ProductStockStatusEnum.OUT_OF_STOCK
                                            ? t({
                                                  id: 'catalog.productCard.colorOutOfStock',
                                                  message: `This color is out of stock`,
                                              })
                                            : product.name
                                    }
                                />
                            </Link>
                        ),
                )}
            {remainingSwatchesCount > 1 && (
                <Link
                    name="more colors"
                    category="productCard"
                    to={`/${product.urlKey}`}
                    title={plural(remainingSwatchesCount, {
                        one: '# more color',
                        other: '# more colors',
                    })}
                    className={cx(styles.colorSwatch, styles.remaining)}
                    resolver={{
                        type: UrlRewriteEntityTypeEnum.PRODUCT,
                        id: product.id,
                    }}
                >
                    +
                </Link>
            )}
        </div>
    )
}

// eslint-disable-next-line react/display-name
export const ProductCard = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            product,
            image_label,
            theme = ProductCardThemesType.default,
            className,
            children,
            withColorSwatch = true,
            withStockStatus = false,
            withBookmark = true,
            onClick,
            onMouseEnter,
            position,

            itemIndex,
            itemListId,
            itemListName,
            itemListSlot,
        },
        ref,
    ) => {
        const productCardClass = cx(
            {
                [styles[theme]]: true,
            },
            className,
        )

        const hoverImage = product.mediaGalleryEntries
            ?.filter((mediaEntry) => !mediaEntry.mave)
            .filter((i) => !i.disabled)
            .sort((a, b) => a.position - b.position)[1]

        const { isDesktop } = useBreakpoints()
        const brandName = product.brand ? product.brand : ''
        const name = product.name ? product.name : 'Name not available'
        const urlKey = product.urlKey ? product.urlKey : '#'
        const imageUrl = product.smallImage?.url || ''
        const [productImageUrl, setProductImageUrl] = React.useState(imageUrl)

        const eventProducts = React.useMemo(
            () => [
                {
                    product,
                    meta: {
                        index: itemIndex,
                    },
                },
            ],
            [itemIndex, product],
        )

        const viewItemEventRef = useViewItemListEvent<HTMLDivElement>(
            itemListId ?? 'N/A',
            itemListName ?? 'N/A',
            itemListSlot ?? 'N/A',
            eventProducts,
        )

        const handleSwatchMouseEnter =
            (swatchImage: ProductCardFragment['smallImage']) => () => {
                setProductImageUrl(swatchImage?.url || '')
            }

        const handleSwatchMouseLeave = () => {
            setProductImageUrl(hoverImage ? hoverImage.file : imageUrl)
        }
        const handleClick = onClick
            ? () => setTimeout(() => onClick(product), 16)
            : undefined
        const handleMouseEnter = () => {
            if (hoverImage) {
                handleSwatchMouseEnter({
                    label: hoverImage.label,
                    url: hoverImage.file,
                    position: 0,
                    disabled: false,
                })()
            }
        }

        const handleMouseLeave = () => {
            setProductImageUrl(imageUrl)
        }

        const isConfigurable = Boolean(
            (product as ConfigurableProduct).variants,
        )

        const prices = isConfigurable
            ? getConfigurableProductPrices(product as ConfigurableProduct)
            : getPrices(product)

        let cardPosition
        let whislistPosition: 'pop-addtowishlist' | undefined

        switch (position) {
            case 'pdp':
                cardPosition = 'pdp-productcard'
                break
            case 'pop':
                whislistPosition = 'pop-addtowishlist'
                cardPosition = 'pop-productcard'
                break
        }

        const labelInfo = getProductLabelInfo(product)

        return (
            <article
                className={productCardClass}
                ref={ref}
                data-testid="categoryPage.ProductCard"
                data-bc-position={cardPosition}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={styles.inner} ref={viewItemEventRef}>
                    <Link
                        name={name}
                        category="productCard"
                        data-testid="productCard.link"
                        preload
                        to={`/${urlKey}`}
                        title={name}
                        className={styles.clickArea}
                        onClick={handleClick}
                        onMouseEnter={
                            onMouseEnter
                                ? () => onMouseEnter(product)
                                : undefined
                        }
                        // eslint-disable-next-line react/no-children-prop
                        children={null}
                        resolver={{
                            type: UrlRewriteEntityTypeEnum.PRODUCT,
                            id: product.id,
                        }}
                    />
                    <div
                        className={styles.imageWrapper}
                        data-bc-position="recommendation-image"
                    >
                        <Image
                            alt={image_label ? image_label : name}
                            url={productImageUrl}
                            width={641}
                            className={styles.imageLazy}
                            lazy={false}
                            /**
                             * Breakpoints are based on most used resolutions in GA.
                             * We don't want too much breakpoints cuz then the image caching needs to work harder.
                             */
                            sizes={{
                                '1370': 641,
                                '1': 458,
                            }}
                        />
                        {!WISHLIST_DISABLED && withBookmark && (
                            <WishlistIconButton
                                product={product}
                                className={styles.wishlistButton}
                                position={whislistPosition}
                            />
                        )}
                        <InfoWrapper>
                            {labelInfo.type && !isGroupedProduct(product) && (
                                <StyledProductLabel
                                    variant={labelInfo.type}
                                    text={labelInfo.text}
                                />
                            )}
                            {withStockStatus && !isGroupedProduct(product) && (
                                <ProductStockStatus product={product} />
                            )}
                        </InfoWrapper>
                    </div>
                    <div className={styles.titleBox}>
                        {SHOW_PRODUCT_BRAND &&
                            !isGroupedProduct(product) &&
                            brandName && (
                                <span className={styles.brand}>
                                    {BrandMapper(brandName)?.prettyName}
                                </span>
                            )}
                        {!isGroupedProduct(product) && (
                            <Text
                                as="h2"
                                color="dark"
                                className={styles.productTitle}
                            >
                                {name}
                            </Text>
                        )}
                        {!isGroupedProduct(product) &&
                            renderPriceBox(prices, product)}
                        {isGroupedProduct(product) && (
                            <Text
                                as="h2"
                                color="dark"
                                className={cx(
                                    styles.productTitle,
                                    styles.groupedProductTitle,
                                )}
                            >
                                {name}
                            </Text>
                        )}
                        {isGroupedProduct(product) && product.items && (
                            <Text>
                                <Plural
                                    id="catalog.productCard.groupedProductCount"
                                    value={
                                        getInStockProductsForGroupedProduct(
                                            product,
                                        ).length
                                    }
                                    one="# item"
                                    other="# items"
                                />
                            </Text>
                        )}
                    </div>
                    {isDesktop &&
                        !isGroupedProduct(product) &&
                        withColorSwatch && (
                            <ColorSwatch
                                product={product}
                                onSwatchMouseEnter={handleSwatchMouseEnter}
                                onSwatchMouseLeave={handleSwatchMouseLeave}
                            />
                        )}
                </div>
                {children}
            </article>
        )
    },
)

export default ProductCard
