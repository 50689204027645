enum ButtonVariant {
    default = 'default',
    primary = 'primary',
    primaryAlt = 'primaryAlt',
    secondary = 'secondary',
    secondaryAlt = 'secondaryAlt',
    white = 'white',
    link = 'link',
    dark = 'dark',
    highlight = 'highlight',
}

export default ButtonVariant
