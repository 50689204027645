import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useHistory } from 'react-router-dom'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { useBreakpoints } from '@emico/ui'

import { Responsive } from './core/Responsive'
import FaqMenu from './FaqMenu'
import FaqQuestions from './FaqQuestions'
import { FaqPageBodyFaqSubject } from './graphql/schema.generated'
import { getPrismicComponents } from './htmlSerializer'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import { LayoutStyle } from './LayoutStyle'
import TextColor from './types/TextColor'

export type PrismicSliceFaqSubject = FaqPageBodyFaqSubject

interface Props {
    slices: PrismicSliceFaqSubject[]
    urlKey: string
    style: LayoutStyle
}

const PrismicSliceFaqSubjects = ({
    slices,
    urlKey,
    style = 'light',
}: Props) => {
    const { isMobile } = useBreakpoints()
    const { location, push } = useHistory()

    const subjects = [...slices].reduce<PrismicSliceFaqSubject[]>(
        (acc, subject) => {
            if (
                subject &&
                subject.primary?.urlKey &&
                subject.primary?.heading &&
                subject.fields &&
                subject.fields.length > 0
            ) {
                // Filter out incomplete questions
                // We are only interested in subjects that have valid questions
                const subjectQuestions = [...subject.fields].filter(
                    (question) =>
                        question && question.heading && question.content,
                )

                if (subjectQuestions.length > 0) {
                    acc.push(subject)
                }
            }

            return acc
        },
        [],
    )

    const activeSubject: PrismicSliceFaqSubject | undefined = subjects.find(
        (subject) =>
            location.pathname.endsWith(subject.primary?.urlKey ?? '_N/A_'),
    )

    // Redirect to first item when not on mobile to prevent showing a page without selected content
    if (!isMobile && !activeSubject && subjects.length > 0) {
        return <Redirect to={`${urlKey}/${subjects[0].primary?.urlKey}`} />
    }

    const handleBackClick = () => {
        if (!activeSubject) {
            return
        }

        push(`/${urlKey}`)
    }

    const activeQuestions = activeSubject && activeSubject.fields

    const activeQuestionsList = activeQuestions &&
        activeSubject &&
        activeSubject?.primary?.heading && (
            <>
                <Helmet>
                    {activeSubject?.primary?.seoTitle && (
                        <title>{activeSubject.primary.seoTitle}</title>
                    )}
                    {activeSubject?.primary?.seoDescription && (
                        <meta
                            name="description"
                            content={activeSubject.primary.seoDescription}
                        />
                    )}
                    {activeSubject?.primary?.seoKeywords && (
                        <meta
                            name="keywords"
                            content={activeSubject.primary.seoKeywords}
                        />
                    )}
                </Helmet>

                <FaqQuestions
                    title={activeSubject.primary.heading}
                    questions={activeQuestions.map(({ heading, content }) => ({
                        heading,
                        content: (
                            <PrismicRichText
                                render={content as [RichTextBlock]}
                                components={getPrismicComponents({
                                    style:
                                        style === 'dark'
                                            ? TextColor.light
                                            : TextColor.dark,
                                })}
                                analyticsContext="faq.question.content"
                            />
                        ),
                    }))}
                    onBackClick={handleBackClick}
                    style={style}
                />
            </>
        )

    const items = subjects
        .filter(({ primary }) => primary && primary.urlKey && primary.heading)
        .map(({ primary }) => ({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            title: primary!.heading!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            url: `/${urlKey}/${primary!.urlKey}`,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            icon: primary!.icon?.url,
        }))

    return (
        <Container verticalPadding>
            <Row>
                <Col md={6} lg={{ order: 0, span: 3, offset: 1 }}>
                    <Responsive md up>
                        <FaqMenu items={items} style={style} />
                    </Responsive>

                    <Responsive sm down>
                        <>
                            {!activeSubject && (
                                <FaqMenu items={items} style={style} />
                            )}

                            {activeQuestionsList}
                        </>
                    </Responsive>
                </Col>

                <Responsive md up>
                    <Col md={6} lg={{ order: 1, span: 6, offset: 1 }}>
                        {activeQuestionsList}
                    </Col>
                </Responsive>
            </Row>
        </Container>
    )
}

export default PrismicSliceFaqSubjects
