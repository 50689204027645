import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { cartDetailsFragment, cartFragment } from '@emico-hooks/cart-fragment'
import { handleCartError } from '@emico-hooks/cart-handle-error'
import { useSetCartId } from '@emico-hooks/cart-id'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    SetShippingMethodsOnCartMutation,
    SetShippingMethodsOnCartMutationVariables,
} from './useSetShippingMethodsOnCart.generated'

interface ShippingMethodInput {
    carrier_code: string
    method_code: string
    dhlparcel_shipping_servicepoint_country_id?: string
    dhlparcel_shipping_servicepoint_id?: string
}

const setShippingMethodsOnCartMutation = gql`
    mutation setShippingMethodsOnCart(
        $cartId: String!
        $shippingMethods: [ShippingMethodInput!]!
    ) {
        setShippingMethodsOnCart(
            input: { cart_id: $cartId, shipping_methods: $shippingMethods }
        ) {
            cart {
                ...CartFragment
                ...CartDetailsFragment
            }
        }
    }

    ${cartFragment}
    ${cartDetailsFragment}
` as TypedDocumentNode<
    SetShippingMethodsOnCartMutation,
    SetShippingMethodsOnCartMutationVariables
>

/**
 * Mutation to set shipping methods on the cart
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const cartId = useCartId()
 *  const setShippingMethodsOnCart = useSetShippingMethodsOnCart()
 *
 *  return useCallback(
 *    async (values: ExampleFormValues) => {
 *      try {
 *         await setShippingMethodsOnCart(
 *          cartId,
 *          [
 *            {
 *              carrier_code: carrierCode,
 *              method_code: values.shippingMethod,
 *            }
 *          ]
 *        )
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [cartId, setShippingMethodsOnCart],
 *  )
 * }
 * ```
 */

export function useSetShippingMethodsOnCart() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()

    const setCartId = useSetCartId()

    return useCallback(
        /**
         *
         * @param cartId Cart id where the shipping methods should be added to
         * @param shippingMethods Array of shipping methods that should be added to the cart
         */
        async (cartId: string, shippingMethods: ShippingMethodInput[]) => {
            const result = await client.mutate({
                mutation: setShippingMethodsOnCartMutation,
                variables: {
                    cartId,
                    shippingMethods,
                },
                context: authorizationContext,
            })

            result.errors?.some((error) => handleCartError(error, setCartId))

            return result
        },
        [authorizationContext, client, setCartId],
    )
}
