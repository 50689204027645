import { i18n, MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './ProductLabel.module.scss'

export interface ProductLabelType {
    text?: string
    // new | sale | sale2 | pme333 | webonly | giveaway
    variant: string
    className?: string
}

const labelTextFallback: { [s: string]: MessageDescriptor } = {
    new: defineMessage({
        id: 'product.card.newLabel',
        message: `New`,
    }),
    sale: defineMessage({
        id: 'product.card.saleLabel',
        message: `Sale`,
    }),
    sale2: defineMessage({
        id: 'product.card.sale2Label',
        message: `Sale`,
    }),
    pme333: defineMessage({
        id: 'product.card.pme333Label',
        message: `PME-333`,
    }),
    webonly: defineMessage({
        id: 'product.card.webonlyLabel',
        message: `Web only`,
    }),
    giveaway: defineMessage({
        id: 'product.card.giveawayLabel',
        message: `Give-away`,
    }),
    actie: defineMessage({
        id: 'product.card.actionLabel',
        message: `Action`,
    }),
}

const ProductLabel: React.FC<ProductLabelType> = ({
    text,
    variant = 'sale',
    className,
}) => {
    const variantLower = variant.toLowerCase()
    const fallbackLabel = i18n._(labelTextFallback[variantLower])
    const label = text || fallbackLabel

    if (!label) {
        return null
    }

    return (
        <div className={cx(styles.default, styles[variantLower], className)}>
            {label}
        </div>
    )
}

export default ProductLabel
