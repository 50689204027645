import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'

import NotFoundBackgroundImages from './NotFoundBackgroundImages'
import styles from './NotFoundPage.module.scss'
import { useCoreConfigValue } from '../coreConfig.query'
import Button from '../input/Button'
import useLastLocation from '../lastLocation/useLastLocation'
import Col from '../layout/Col'
import Container from '../layout/Container'
import Header from '../layout/Header/Header'
import HeaderContainer from '../layout/Header/HeaderContainer'
import PageWrapper from '../layout/PageWrapper'
import Row from '../layout/Row'
import Link from '../navigation/Link/Link'
import Heading from '../typography/Heading'
import parseQueryString from '../utils/url/parseQueryString'
import withSentry from '../utils/withSentry'

export const locationToString = (location: RouteComponentProps['location']) =>
    `${location.pathname}${location.search}${location.hash}`

const NotFoundPage = () => {
    const location = useLocation()
    const history = useHistory()
    const lastLocation = useLastLocation()
    const link = useCoreConfigValue('justbrands_content/404_page/contact_link')

    const queryString = parseQueryString(location.search)
    const fallbackUrl = queryString.fallbackUrl

    if (fallbackUrl) {
        history.replace(decodeURIComponent(fallbackUrl))
        return null
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        withSentry((Sentry) => {
            Sentry.withScope((scope: SentryScope) => {
                const message = `Page not found: ${locationToString(location)}`

                scope.setExtra('lastLocation', lastLocation)
                Sentry.captureMessage(message, 'log')

                // eslint-disable-next-line no-console
                console.log(message, 'lastLocation', lastLocation)
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // if (staticContext) {
    //     staticContext.statusCode = 404
    // }

    return (
        <PageWrapper
            pageType="cms"
            header={
                <HeaderContainer>
                    <Header />
                </HeaderContainer>
            }
            className={styles.base}
        >
            <Helmet>
                <title>
                    {t({
                        id: 'core.notFoundPage.documentTitle',
                        message: `Page not found`,
                    })}
                </title>
            </Helmet>

            <Container>
                <NotFoundBackgroundImages />

                <Row>
                    <Col
                        lg={{
                            offset: 1,
                            span: 6,
                            order: 0,
                        }}
                        md={{
                            offset: 1,
                            span: 7,
                            order: 0,
                        }}
                        sm={{
                            offset: 1,
                            span: 7,
                            order: 0,
                        }}
                        xs={12}
                    >
                        <Heading
                            variant="h1"
                            element="h1"
                            color="light"
                            className={styles.heading}
                        >
                            <Trans id="core.notFoundPage.heading">
                                Our cargo pilot is lost.
                            </Trans>
                        </Heading>
                        <div className={styles.explanation}>
                            <Trans id="core.notFoundPage.text">
                                The page you're trying to find is no longer
                                available. But don't worry, let us guide you
                                back or just{' '}
                                <Link
                                    name="Contact us"
                                    category="notFoundPage"
                                    to={link ? String(link.value) : '/contact'}
                                    variant="light"
                                    className={styles.link}
                                >
                                    contact us
                                </Link>
                                .
                            </Trans>
                        </div>

                        <Button
                            minWidth
                            variant="primary"
                            className={styles.button}
                            to={
                                lastLocation
                                    ? locationToString(lastLocation)
                                    : '/'
                            }
                            name="Go back"
                            category="core.jbfoHomePage.back"
                        >
                            {lastLocation ? (
                                <Trans id="core.notFoundPage.goBackButton">
                                    Go back
                                </Trans>
                            ) : (
                                <Trans id="core.notFoundPage.goHomeButton">
                                    Go to home
                                </Trans>
                            )}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}

export default NotFoundPage
