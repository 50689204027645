import RecommendedProductsComponent, {
    RecommendedProductProps,
} from './RecommendedProductsComponent'
import { useTweakwiseRecommendations } from '../catalog/TweakwiseRecommendations.query'

interface Props extends RecommendedProductProps {
    productId?: number
    templateId: number
}

const RecommendedProductsTweakwise = ({
    templateId,
    productId,
    ...props
}: Props) => {
    const { data: products, loading } = useTweakwiseRecommendations(
        'featured',
        productId,
        templateId.toString(),
    )

    // When loading or when there are no recommendations just hide the entire panel
    if (loading || !products || products.length === 0) {
        return null
    }

    return (
        <RecommendedProductsComponent
            {...props}
            products={products}
            templateId={templateId}
            loading={loading}
        />
    )
}

export default RecommendedProductsTweakwise
