import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ConfigurableProduct } from './catalog/ProductPage/ConfigurableProduct'
import { Product } from './catalog/ProductPage/GetProduct.query'

interface Props {
    product: Product | ConfigurableProduct
}

const Wrapper = styled.div`
    margin-bottom: 10px;
`

const ProductColor: React.FC<Props> = ({ product }: Props) => {
    const { baseColorValues } = product
    const color = baseColorValues?.[0]?.label

    if (color) {
        return (
            <Wrapper>
                <Trans id="product.color">Color: {color}</Trans>
            </Wrapper>
        )
    }

    return null
}

export default ProductColor
