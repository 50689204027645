import { Picture } from '@emico-react/image'
import cx from 'classnames'

import { LayoutStyle } from './LayoutStyle'
import styles from './PrismicImageOnly.module.scss'
import breakpoints from './theme/breakpoints'
import useGridProps from './useGridProps'

export interface Props {
    style: LayoutStyle
    image: string
    imageAlt?: string
    imageMobile?: string
    tablet?: string
    desktop?: string
}

const PrismicImageOnly = ({
    style,
    image,
    imageMobile,
    imageAlt,
    tablet,
    desktop,
}: Props) => {
    const gridProps = useGridProps(tablet, desktop)

    if (!image) {
        return null
    }

    return (
        <div className={cx(styles[style], styles.container)} {...gridProps}>
            <Picture
                pictureProps={{ className: styles.scaledImage }}
                // Image sizes are based on
                // the maximum viewport widths at their respective breakpoints
                // F.e. the breakpoint for small to medium = 768,
                // so the max small image width is 767
                breakpoints={{
                    [breakpoints.xs]: {
                        url: imageMobile ?? image,
                        width: 767,
                        height: undefined,
                        params: {
                            resizingType: 'fill',
                        },
                    },
                    [breakpoints.md]: {
                        url: image ?? imageMobile,
                        width: 991,
                        height: undefined,
                        params: {
                            resizingType: 'fill',
                        },
                    },
                    [breakpoints.lg]: {
                        url: image ?? imageMobile,
                        width: 2000,
                        height: undefined,
                        params: {
                            resizingType: 'fill',
                        },
                    },
                }}
                lazy
                alt={imageAlt ?? ''}
                className={styles.scaledImage}
            />
        </div>
    )
}

export default PrismicImageOnly
