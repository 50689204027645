import Vimeo, {
    CuePointEvent as VimeoCuePointEvent,
    PlayEvent as VimeoPlaybackEvent,
    VimeoProps,
} from '@u-wave/react-vimeo'
import Player from '@vimeo/player'
import * as React from 'react'

import ErrorBoundary from '../../ErrorBoundary'

interface OwnProps {
    /**
     * Vimeo url, optionally including parameters
     */
    url: string
}

type Props = OwnProps &
    Omit<
        VimeoProps,
        'video' | 'showByline' | 'showPortrait' | 'showTitle' | 'controls'
    >

export type CuePointEvent = VimeoCuePointEvent
export type PlaybackEvent = VimeoPlaybackEvent

const Video = ({ url, ...vimeoProps }: Props) => (
    <ErrorBoundary fallback={<React.Fragment />}>
        <Vimeo
            // Default props first, so they can be overridden by url props and component props
            controls={false}
            autopause={false}
            responsive
            // Spread url props first so they can be overridden by component props
            {...vimeoProps}
            // Props below should not be allowed to change
            video={url}
            // On some video's the lines below have no effect...
            showByline={false}
            showPortrait={false}
            showTitle={false}
            onError={() => {
                // no-op
            }}
        />
    </ErrorBoundary>
)

export { Player }

export default Video
