import { ConfigurableVariant } from '../catalog/common/MinimalConfigurableProductInfo.fragment'
import { isConfigurable } from '../catalog/common/ProductFilterPage/TweakwiseNavigate.query'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import { ProductStockStatus } from '../graphql/schema.generated'

interface StockInfo {
    isOutOfStock: boolean
    type?: 'some' | 'specific'
    sizeCount: number
    inStockItems: string[]
}

const getCombinedVariantLabels = (variant: ConfigurableVariant) =>
    variant.attributes.map((attr) => attr.label).join(', ')

export default function getProductStockInfo(
    product: Product | ConfigurableProduct,
): StockInfo {
    const productOutOfStock =
        product.stockStatus === ProductStockStatus.OUT_OF_STOCK
    const configurableProduct = isConfigurable(product) ? product : undefined

    if (productOutOfStock || !configurableProduct) {
        return {
            isOutOfStock: productOutOfStock,
            type: undefined,
            sizeCount: 0,
            inStockItems: [],
        }
    }

    // If a configurable product is in stock, check variations for stock status.
    // We only care about sizes, so if a product has only one size-length
    // combination available, that's fine.

    const variantsWithStock = configurableProduct.variants?.filter(
        (variant) =>
            variant.product?.stockStatus !== ProductStockStatus.OUT_OF_STOCK &&
            // onlyXLeftInStock could be disabled, but there could be stock. So if it is null
            // assume there is some stock.
            (variant.product?.onlyXLeftInStock ?? 1) > 0,
    )

    if (!variantsWithStock || variantsWithStock.length === 0) {
        return {
            inStockItems: [],
            isOutOfStock: true,
            sizeCount: 0,
        }
    }

    if (variantsWithStock.length === 1) {
        return {
            inStockItems: variantsWithStock.map(getCombinedVariantLabels),
            isOutOfStock: false,
            sizeCount: 1,
            type: 'specific',
        }
    }

    return {
        inStockItems: variantsWithStock.map(getCombinedVariantLabels),
        isOutOfStock: false,
        sizeCount: variantsWithStock.length,
        type: 'some',
    }
}
