import { ComponentProps } from 'react'

import {
    CastironFaqPageBodyHero,
    CastironPageBodyHero,
    FaqPageBodyHero,
    JbfoFaqPageBodyHero,
    JbfoPageBodyHero,
    JustbrandsFaqPageBodyHero,
    JustbrandsPageBodyHero,
    MonstersaleFaqPageBodyHero,
    MonstersalePageBodyHero,
    PageBodyHero,
    PmeOutletFaqPageBodyHero,
    PmeOutletPageBodyHero,
    StorelocatorPageBodyHero,
    StorePageBodyHero,
    VanguardFaqPageBodyHero,
    VanguardPageBodyHero,
} from './graphql/schema.generated'
import { mapElement } from './mapHeadingElement'
import { mapVariant } from './mapHeadingVariant'
import PrismicHero from './PrismicHero'

type PrismicSliceHeroType =
    | PageBodyHero
    | FaqPageBodyHero
    | CastironPageBodyHero
    | CastironFaqPageBodyHero
    | VanguardPageBodyHero
    | VanguardFaqPageBodyHero
    | JustbrandsPageBodyHero
    | JustbrandsFaqPageBodyHero
    | JbfoPageBodyHero
    | JbfoFaqPageBodyHero
    | MonstersalePageBodyHero
    | MonstersaleFaqPageBodyHero
    | PmeOutletPageBodyHero
    | PmeOutletFaqPageBodyHero
    | StorePageBodyHero
    | StorelocatorPageBodyHero

interface Props {
    slice: PrismicSliceHeroType
}

const PrismicSliceHero = ({ slice: { primary } }: Props) => {
    if (!primary) {
        return null
    }

    const {
        image,
        mobileImage,
        header,
        subheading,
        videoId,
        mobileVideoId,
        headingVariant,
        subheadingVariant,
        headingElement,
        subheadingElement,
        headingfuss,
        size,
        fullWidth,
    } = primary

    // Map heading variants and elements

    const componentProps: ComponentProps<typeof PrismicHero> = {
        heading: header ?? undefined,
        subHeading: subheading ?? undefined,
        image: image?.url ?? undefined,
        imageMobile: mobileImage?.url ?? undefined,
        fullWidth: fullWidth ?? undefined,
        headingVariant: mapVariant(headingVariant),
        subheadingVariant: mapVariant(subheadingVariant),
        headingElement: mapElement(headingElement),
        subheadingElement: mapElement(subheadingElement),
        size:
            size === 'large' ? 'large' : size === 'small' ? 'small' : undefined,
        headingFuss: headingfuss ? ['castironLight', 'pme', 'vanguard'] : [],
        video: videoId
            ? `https://player.vimeo.com/video/${videoId}`
            : undefined,
        mobileVideo: mobileVideoId
            ? `https://player.vimeo.com/video/${mobileVideoId}`
            : undefined,
    }

    return <PrismicHero {...componentProps} />
}

export default PrismicSliceHero
