import { gql, useMutation } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'

import { wishlistFragment } from './GetWishlist.query'
import {
    Mutation,
    MutationRemoveProductsFromWishlistArgs,
} from '../graphql/schema.generated'

const mutation = gql`
    mutation removeProductsFromWishlist(
        $wishlistId: ID!
        $wishlistItemsIds: [ID!]!
    ) {
        removeProductsFromWishlist(
            wishlistId: $wishlistId
            wishlistItemsIds: $wishlistItemsIds
        ) {
            wishlist {
                ...WishlistFields
            }
        }
    }
    ${wishlistFragment}
`

type Result = Pick<Mutation, 'removeProductsFromWishlist'>

export const useRemoveProductsFromWishlist = () => {
    const authorizationContext = useAuthorizationContext()

    // eslint-disable-next-line no-restricted-syntax
    return useMutation<Result, MutationRemoveProductsFromWishlistArgs>(
        mutation,
        {
            context: authorizationContext,
        },
    )
}
