import * as React from 'react'

import BrandSchemaOrg from './BrandSchemaOrg'
import OrganizationSchemaOrg from './OrganizationSchemaOrg'
import Searchbox from './Searchbox'

const RichSnippets = () => (
    <>
        <OrganizationSchemaOrg />
        <BrandSchemaOrg />
        <Searchbox />
    </>
)

export default RichSnippets
