import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { OptionsType } from './Options'
import styles from './SizeCombinedOptions.module.scss'
import Container from '../../layout/Container'
import Heading from '../../typography/Heading'

const MAX_SIZE_COLS = 6

export const SizesCombinedOptions = ({
    answers,
    isMultiSelect,
    onSelectionChange,
}: OptionsType) => {
    const { watch, setValue } = useForm<{
        width: string
        length: string
    }>()

    const [widthValues, setWidthValues] = useState<string[] | undefined>()
    const [lengthValues, setLengthValues] = useState<string[] | undefined>()

    const [disabledWidthValues, setDisabledWidthValues] = useState<
        string[] | undefined
    >([])
    const [disabledLengthValues, setDisabledLengthValues] = useState<
        string[] | undefined
    >([])

    const selectedAnswer = answers?.find((a) => a.isSelected)
    useEffect(() => {
        if (selectedAnswer) {
            setValue('width', selectedAnswer?.answer?.split('-')?.[0] ?? '')
            setValue('length', selectedAnswer?.answer?.split('-')?.[1] ?? '')
        }
    }, [selectedAnswer, setValue])

    const gridRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // desktop grid cols depends on the number of answers:
        // mobile: 2 cols always
        // desktop:
        const divider = Math.min(
            answers?.length ?? 0,
            MAX_SIZE_COLS,
            answers?.length ?? 0 % MAX_SIZE_COLS,
        )

        gridRef.current?.style.setProperty(
            '--num-cols',
            `${Math.max(divider, MAX_SIZE_COLS / (divider || 1))}`,
        )
        setDisabledLengthValues([])
        setDisabledWidthValues([])

        const sizes = answers?.map((a) => a.answer?.split('-'))
        setWidthValues([...new Set(sizes?.map((size) => size?.[0] ?? ''))])
        setLengthValues([...new Set(sizes?.map((size) => size?.[1] ?? ''))])
    }, [answers])

    const { width, length } = watch()

    useEffect(() => {
        if (width) {
            const availableSizes = answers
                ?.filter((a) => a.answer?.startsWith(width))
                .map((a) => a.answer?.split('-')?.[1])
            setDisabledLengthValues(
                availableSizes
                    ? lengthValues?.filter(
                          (val) => !availableSizes.includes(val),
                      )
                    : [],
            )
        } else {
            setDisabledLengthValues([])
        }
    }, [width, answers, lengthValues])

    useEffect(() => {
        if (length) {
            const availableSizes = answers
                ?.filter((a) => a.answer?.endsWith(length))
                .map((a) => a.answer?.split('-')?.[0])

            setDisabledWidthValues(
                availableSizes
                    ? widthValues?.filter(
                          (val) => !availableSizes.includes(val),
                      )
                    : [],
            )
        } else {
            setDisabledWidthValues([])
        }
    }, [length, answers, widthValues])

    useEffect(() => {
        if (width && length) {
            const combinedValue = `${width}-${length}`
            const selectedAnswerId = answers?.find(
                (a) => a.answer === combinedValue,
            )
            if (selectedAnswerId) {
                onSelectionChange?.(selectedAnswerId?.answerId ?? '')
            }
        } else {
            onSelectionChange?.([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, length])

    if (!answers) {
        return null
    }

    return (
        <Container className={styles.container}>
            <Heading
                color="light"
                variant="h3"
                element="label"
                htmlFor="width"
                className={styles.sectionTitle}
            >
                <Trans id="guidedSelling.waist">Waist</Trans>
            </Heading>
            <div className={styles.grid}>
                {widthValues?.map((value) => (
                    <label
                        key={value}
                        className={classNames(
                            styles.option,
                            disabledWidthValues?.includes(value)
                                ? styles.disabled
                                : '',
                        )}
                    >
                        <input
                            className={styles.input}
                            disabled={disabledWidthValues?.includes(value)}
                            checked={width === value}
                            onClick={() => {
                                setValue('width', '')
                            }}
                            onChange={(e) => {
                                setValue('width', e.target.checked ? value : '')
                            }}
                            value={value}
                            type={isMultiSelect ? 'checkbox' : 'radio'}
                        />
                        {value}
                    </label>
                ))}
            </div>

            <Heading
                color="light"
                variant="h3"
                element="label"
                htmlFor="length"
                className={styles.sectionTitle}
            >
                <Trans id="guidedSelling.length">Length</Trans>
            </Heading>
            <div className={styles.grid}>
                {lengthValues?.map((value) => (
                    <label
                        key={value}
                        className={classNames(
                            styles.option,
                            disabledLengthValues?.includes(value)
                                ? styles.disabled
                                : '',
                        )}
                    >
                        <input
                            className={styles.input}
                            disabled={disabledLengthValues?.includes(value)}
                            checked={length === value}
                            onClick={() => {
                                setValue('length', '')
                            }}
                            onChange={(e) => {
                                setValue(
                                    'length',
                                    e.target.checked ? value : '',
                                )
                            }}
                            value={value}
                            type={isMultiSelect ? 'checkbox' : 'radio'}
                        />
                        {value}
                    </label>
                ))}
            </div>
        </Container>
    )
}
