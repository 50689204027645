import cx from 'classnames'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import styles from './Breadcrumb.module.scss'
import BreadcrumbIcon from '../../core/BreadcrumbIcon'
import Link, { Props as LinkProps } from '../Link'

interface OwnProps {
    position: number
    isActive?: boolean
}

export type Props = OwnProps &
    Pick<LinkProps, 'to' | 'className' | 'children' | 'location'> &
    RouteComponentProps

const Breadcrumb = ({ to, className, children, isActive, position }: Props) => (
    <li
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
    >
        <Link
            name={to}
            category="breadcrumb"
            preload
            to={to}
            className={cx(styles.base, className, {
                [styles.active]: isActive,
            })}
            itemType="https://schema.org/Thing"
            itemProp="item"
        >
            <span itemProp="name">{children}</span>
        </Link>
        {!isActive && <BreadcrumbIcon className={styles.icon} />}
        <meta itemProp="position" content={String(position)} />
    </li>
)

export default withRouter(Breadcrumb)
