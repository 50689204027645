import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'

import styles from './TopBar.module.scss'
import Link from '../../../navigation/Link'
import paths from '../../../paths'
import theme from '../../../theme'
import Container from '../../Container'

interface OwnProps {
    left?: React.ReactNode
    right?: React.ReactNode
    logo?: React.ReactNode
    children?: React.ReactNode
    className?: string
}

export type Props = OwnProps &
    React.HTMLAttributes<HTMLDivElement> &
    React.RefAttributes<HTMLDivElement>

const Logo = styled.div`
    width: ${theme.utils.topbarMenuOffset};
`

const TopBar = React.forwardRef<HTMLDivElement, Props>(function TopBar(
    { left, right, logo, children, className, ...otherProps }: Props,
    ref,
) {
    return (
        <div {...otherProps} className={cx(styles.base, className)} ref={ref}>
            <div className={styles.relative}>
                <Container className={styles.container}>
                    {logo && (
                        <Logo className={cx(styles.logo)}>
                            <Link
                                name="Logo"
                                category="topBar"
                                to={paths.home}
                                className={styles.logoWrapper}
                                preload
                            >
                                {logo}
                            </Link>
                        </Logo>
                    )}
                    {left && <div className={styles.left}>{left}</div>}
                    {right && <div className={styles.right}>{right}</div>}
                </Container>
            </div>
        </div>
    )
})

export default TopBar
