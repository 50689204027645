import { t } from '@lingui/macro'
import { useGetCustomer } from 'components/src/customer/getCustomer.query'
import absolutify from 'components/src/utils/url/absolutify'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import styles from './AccountHero.module.scss'
import paths from './paths'
import PrismicHero from './PrismicHero'
import desktopImage from './static/account-hero/desktop.jpg'
import mobileImage from './static/account-hero/mobile.jpg'
import HeadingVariant from './types/HeadingVariant'
import overridable from './utils/overridable'

type Props = Pick<
    React.ComponentProps<typeof PrismicHero>,
    'image' | 'imageMobile' | 'heading' | 'className' | 'imageProxyParams'
>

export const AccountHero = ({
    heading,
    image = desktopImage,
    imageMobile = mobileImage,
    className,
    imageProxyParams,
}: Props) => (
    <PrismicHero
        imageProxyParams={imageProxyParams}
        className={className}
        heading={
            heading ??
            t({
                id: 'account.dashboard.title',
                message: `My account`,
            })
        }
        image={absolutify(image)}
        imageMobile={absolutify(imageMobile)}
        size="small"
        headingVariant={HeadingVariant.h1}
        subheadingVariant={HeadingVariant.h3}
    />
)

export default overridable('AccountHero')(AccountHero)

export const PersonalizedAccountHero = () => {
    const { data: customer } = useGetCustomer()
    const { pathname } = useLocation()

    const heading =
        pathname === paths.account
            ? ''
            : t({
                  id: 'user.dashboard.header.titlePersonalised',
                  message: `Welcome ${customer?.firstname}`,
              })

    return (
        <AccountHero
            className={styles.customerImage}
            heading={heading}
            imageProxyParams={
                customer?.personalizedImage
                    ? {
                          resizingType: 'fit',
                      }
                    : undefined
            }
            image={customer?.personalizedImage ?? ''}
            imageMobile={customer?.personalizedImage ?? ''}
        />
    )
}
