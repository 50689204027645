import cx from 'classnames'
import * as React from 'react'

import styles from './Text.module.scss'
import TextAlignment from '../../types/TextAlignment'
import TextColor from '../../types/TextColor'
import TextSize from '../../types/TextSize'
import TextVariant from '../../types/TextVariant'

interface Props extends React.HTMLAttributes<HTMLElement> {
    /**
     * Component to render as
     * @default 'p'
     */
    as?: React.ElementType

    /** Content to render */
    children?: React.ReactNode

    /** For custom styling */
    className?: string

    /** The variant */
    variant?: keyof typeof TextVariant

    /** The text color */
    color?: keyof typeof TextColor

    /** The text alignment */
    alignment?: keyof typeof TextAlignment

    /** The text size */
    size?: keyof typeof TextSize

    italic?: boolean
}

const Text = ({
    as: Component = 'p',
    className,
    variant,
    color,
    alignment,
    italic = false,
    size,
    ...otherProps
}: Props) => {
    const classes = []

    if (alignment) {
        classes.push(styles[alignment])
    }

    if (color) {
        classes.push(styles[color])
    }

    if (variant) {
        classes.push(styles[variant])
    }

    if (italic) {
        classes.push(styles.italic)
    }

    if (size) {
        classes.push(styles[size])
    }

    return (
        <Component
            {...otherProps}
            className={cx(className, styles.text, ...classes)}
        />
    )
}

export default Text
