import cx from 'classnames'
import * as React from 'react'

import styles from './NavLink.module.scss'
import elementInViewport from '../../utils/elementInViewport'
import scrollToElement from '../../utils/scrollToElement'
import Link, { Props as LinkProps } from '../Link'

interface OwnProps {
    /**
     * Hook to style active indicator
     */
    indicatorClassName?: string

    /**
     * When true, apply active class
     * Used for controlled link state
     * @default: false
     */
    active: boolean
}

export type Props = OwnProps & Omit<LinkProps, 'theme'>

const getElementIdFromUrl = (to: LinkProps['to'] | { hash?: string }) => {
    if (typeof to === 'string') {
        return to.split('#').slice(1).shift()
    } else if (typeof to === 'object' && typeof to.hash === 'string') {
        return to.hash.replace('#', '')
    }
    return
}

const NavLink = ({
    to,
    className,
    active,
    activeClassName,
    indicatorClassName,
    ...otherProps
}: Props) => {
    const [isNavLinkActive, setNavlinkActive] = React.useState<boolean>(false)
    const [isClicked, setClicked] = React.useState<boolean>(
        window.location.hash ? to.includes(window.location.hash) : false,
    )

    const handleScroll = React.useCallback(() => {
        const elementId = getElementIdFromUrl(to)

        if (elementId) {
            const element = document.getElementById(elementId)

            if (element !== null) {
                const isElementInViewport = elementInViewport(element, 0)

                setNavlinkActive(isElementInViewport)

                if (isElementInViewport) {
                    setClicked(false)
                }
            } else {
                setNavlinkActive(false)
            }
        }
    }, [to])

    const handleScrollToElement = React.useCallback((el: HTMLElement) => {
        setClicked(true)
        scrollToElement(el, false)
    }, [])

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, false)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [handleScroll])

    return (
        <Link
            to={to}
            className={cx(
                styles.base,
                {
                    [styles.indicator]: !indicatorClassName,
                    [styles.active]: isClicked || isNavLinkActive || active,
                },
                indicatorClassName,
                className,
            )}
            scroll={handleScrollToElement}
            // Do not apply active class state on anchored links with no actual anchor
            activeClassName={
                to === '/#' || to === '#'
                    ? undefined
                    : cx(styles.active, activeClassName)
            }
            data-testid="navLink"
            {...otherProps}
        />
    )
}

export default NavLink
