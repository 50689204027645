import { ComponentProps } from 'react'

import BlueConicContent from './BlueConicContent'
import ErrorBoundary from './ErrorBoundary'
import { LayoutStyle } from './LayoutStyle'
import RecommendedProducts from './RecommendedProducts'
import RecommendedProductsTweakwise from './RecommendedProducts/RecommendedProductsTweakwise'
import styles from './TweakwiseSlider.module.scss'
import { BlueConicDynamicPosition } from './types/BlueConic'
import HeadingColor from './types/HeadingColor'
import TextColor from './types/TextColor'

type Props = Pick<
    ComponentProps<typeof RecommendedProducts>,
    | 'header'
    | 'content'
    | 'buttonText'
    | 'buttonStyle'
    | 'buttonUrl'
    | 'templateId'
    | 'headingColor'
    | 'textColor'
> & {
    style: LayoutStyle
    positionBC?: BlueConicDynamicPosition
}

const TweakwiseSlider = ({
    style,
    header,
    content,
    buttonText,
    buttonStyle,
    buttonUrl,
    templateId,
    headingColor,
    textColor,
    positionBC,
}: Props) => {
    const headingColorByTheme: HeadingColor =
        headingColor ||
        (style === 'light' ? HeadingColor.dark : HeadingColor.light)

    const textColorByTheme: TextColor =
        textColor || (style === 'light' ? TextColor.dark : TextColor.light)

    return (
        <ErrorBoundary fallback={<></>}>
            <section className={styles[style]}>
                <BlueConicContent blueConicPosition={positionBC}>
                    <RecommendedProductsTweakwise
                        className={styles.wrapper}
                        header={header}
                        content={content}
                        buttonText={buttonText}
                        buttonUrl={buttonUrl}
                        buttonStyle={buttonStyle}
                        templateId={templateId}
                        headingClassName={styles.heading}
                        contentClassName={styles.content}
                        contentInnerClassName={styles.contentInner}
                        headingColor={headingColorByTheme}
                        textColor={textColorByTheme}
                        withOffset
                        itemListSlot={
                            positionBC ?? 'recommendedProductsTweakwise'
                        }
                    />
                </BlueConicContent>
            </section>
        </ErrorBoundary>
    )
}

export default TweakwiseSlider
