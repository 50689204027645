import { usePushEcommerceEvent } from './usePushEvent'

export function useFilterRemovedEvent() {
    const push = usePushEcommerceEvent([], false)

    function pushFilterddRemovedEvent(filterName: string, filterValue: string) {
        // Asuming that a user does not click faster than the required data is loaded
        push?.([
            { ecommerce: null },
            {
                event: 'filter_removed',

                filter_name: filterName,
                filter_value: filterValue,
            },
        ])
    }

    return pushFilterddRemovedEvent
}
