import { css, Global } from '@emotion/react'

import { colorsCss } from './colors'
import ThemeOverrides from './ThemeOverrides'
// import { spacingCss, spacingDefaulsMd } from './spacing'
// import { sizesCss, sizesLg } from './sizes'
// import { transitionCss } from './transitions'
import { typographyCss } from './typography'
import { utilsCss } from './utils'
// import { shadowCss } from './shadow'
// import { bordersCss } from './borders'

const GlobalStyles = () => {
    const allVars = [
        ...utilsCss,
        // ...bordersCss,
        ...colorsCss,
        // ...spacingCss,
        // ...sizesCss,
        ...typographyCss,
        // ...transitionCss,
        // ...shadowCss,
    ].join('')

    return (
        <>
            <Global
                styles={css`
                    #tsBadge {
                        * {
                            border: none !important;
                            background: transparent !important;
                        }

                        [id^='minimized-trustbadge'] > p {
                            background-color: red !important;
                            color: red !important;
                            border: 1px solid red !important;
                            display: none !important;
                        }
                    }

                    :root {
                        ${allVars}
                        scroll-timeline: --squareTimeline y;
                        scroll-timeline: --squareTimeline vertical;
                    }

                    @media (prefers-reduced-motion) {
                        --var-transition-ease-in: none;
                    }
                `}
            />
            <ThemeOverrides />
        </>
    )
}

export default GlobalStyles
