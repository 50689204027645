import { makeVar } from '@apollo/client'
import { css, Global } from '@emotion/react'
import * as React from 'react'

import styles from './HeaderContainer.module.scss'
import ShopIdTopBarBanner from './TopBar/ShopIdTopBarBanner'
import TopBarBanner from './TopBar/TopBarBanner'
import TopBarUspBanner from './TopBar/TopBarUspBanner'
import ErrorBoundary from '../../ErrorBoundary'
import PromoInfo from '../../PromoInfo'
import useSize from '../../useSize'

interface Props {
    children?: React.ReactElement | React.ReactElement[]
}

export const headerSizeVar = makeVar<
    { width: number; height: number } | undefined
>(undefined)

const HeaderContainer = ({ children }: Props) => {
    const headerRef = React.useRef(null)
    const size = useSize(headerRef)

    React.useEffect(() => {
        if (size?.width && size?.height) {
            headerSizeVar({ width: size.width, height: size.height })
        }
    }, [size?.width, size?.height])

    return (
        <>
            <div
                className={styles.headerContainer}
                data-id="header-container"
                ref={headerRef}
            >
                <Global
                    styles={css`
                        :root {
                            --header-height: ${size?.height || 60}px;
                        }
                    `}
                />
                <ErrorBoundary fallback={<React.Fragment />}>
                    <ShopIdTopBarBanner />
                    <TopBarBanner key="topbanner" />
                    <TopBarUspBanner key="topbannerusp" />
                </ErrorBoundary>

                {children}
            </div>

            <ErrorBoundary fallback={<React.Fragment />}>
                <PromoInfo />
            </ErrorBoundary>
        </>
    )
}

export default React.memo(HeaderContainer)
