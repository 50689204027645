import { Marker } from '@react-google-maps/api'

import userLocationIcon from './markerIcons/userLocationIcon.svg'

interface Location {
    lat: number
    lng: number
}

interface Props {
    position: Location
}

const CurrentUserMarker = ({ position }: Props) => {
    const icon = {
        url: userLocationIcon,
        anchor: new google.maps.Point(20, 20),
        scaledSize: new google.maps.Size(28, 28),
    }

    return <Marker icon={icon} position={position} />
}

export default CurrentUserMarker
