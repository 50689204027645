import * as React from 'react'
import { Helmet } from 'react-helmet'

import { RichTextBlock } from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'

import { StorePage } from './graphql/schema.generated'
import { PrismicPage } from './usePrismicPage'

interface Props {
    page: PrismicPage | StorePage
}

const PrismicHelmet = ({ page }: Props) => {
    const activeStoreView = useActiveStoreView()
    let highstreetContentExtension

    if (
        process.env.REACT_APP_HIGHSTREET_STORE_NAME &&
        page?.urlKey &&
        page?.seoTitle
    ) {
        const url = activeStoreView.makeUrl(`/${page.urlKey}`)
        const contentUrl =
            page.urlKey === 'homepage'
                ? `${process.env.REACT_APP_HIGHSTREET_STORE_NAME}://home`
                : `${
                      process.env.REACT_APP_HIGHSTREET_STORE_NAME
                  }://contentextension?url=${btoa(url)}&title=${encodeURI(
                      page.seoTitle,
                  )}`

        highstreetContentExtension = (
            <meta property="al:ios:url" content={contentUrl} />
        )
    }

    let head: string | undefined

    /**
     * page.head is a prisic Rich Text field
     * where users can enter links to JS
     * and CSS scripts
     */
    const pageHead = page?.head as RichTextBlock[] | undefined

    if (
        pageHead &&
        pageHead.length > 0 &&
        'text' in pageHead[0] &&
        pageHead[0].text
    ) {
        head = pageHead[0].text
    }

    return (
        <Helmet>
            {page?.seoTitle && <title>{page?.seoTitle}</title>}
            {page?.seoDescription && (
                <meta name="description" content={page?.seoDescription} />
            )}
            {page?.seoKeywords && (
                <meta name="keywords" content={page?.seoKeywords} />
            )}
            {highstreetContentExtension}
            {(page?.noindex || page?.nofollow) && (
                <meta
                    name="robots"
                    content={`${page?.noindex ? 'noindex' : 'index'},${
                        page?.nofollow ? 'nofollow' : 'follow'
                    }`}
                />
            )}
            {head &&
                head.split('\n').map((src, index) => {
                    if (!src || !src.startsWith('https://')) {
                        return null
                    }

                    if (src.endsWith('.js')) {
                        return (
                            <script
                                key={`${index}-js`}
                                src={src + '#' + Date.now()}
                                crossOrigin="true"
                                type="module"
                                defer
                            />
                        )
                    }
                    if (src.endsWith('.css')) {
                        return (
                            <link
                                key={`${index}-css`}
                                rel="stylesheet"
                                href={src}
                            />
                        )
                    }
                    return null
                })}
        </Helmet>
    )
}

export default PrismicHelmet
