import { ConfigurableProduct } from 'components/src/catalog/ProductPage/ConfigurableProduct'
import { Product } from 'components/src/catalog/ProductPage/GetProduct.query'
import Heading from 'components/src/typography/Heading/Heading'
import overridable from 'components/src/utils/overridable'

import styles from './ProductInfo.module.scss'

interface Props {
    product: Product | ConfigurableProduct
}

const ProductInfoHeader = ({ product }: Props) => (
    <Heading element="h1" color="dark" variant="h2" className={styles.name}>
        {product.name}
    </Heading>
)

export default overridable('ProductInfoHeader')(ProductInfoHeader)
