import * as React from 'react'

interface OwnProps {
    children: React.ReactNode
}
type Props = React.HTMLAttributes<HTMLDivElement> & OwnProps

/**
 * Lock scrolling the page vertically when swiping horizontally.
 */
const LockScroll = ({ children, ...others }: Props) => {
    const containerRef = React.createRef<HTMLDivElement>()

    const MIN_MOVEMENT_THRESHOLD_PX = 5
    // Track the X location of the touch start to see the touch direction
    let firstClientX = 0
    const touchStart = (e: TouchEvent) => {
        firstClientX = e.touches[0].clientX
    }

    const preventTouch = (e: TouchEvent) => {
        const clientX = e.touches[0].clientX - firstClientX

        // Block vertical scrolling when you start swiping horizontally.
        if (Math.abs(clientX) > MIN_MOVEMENT_THRESHOLD_PX) {
            e.preventDefault()

            return false
        }
        return
    }

    React.useEffect(() => {
        if (!containerRef.current) {
            return
        }
        containerRef.current.addEventListener('touchstart', touchStart, {
            passive: true,
        })
        containerRef.current.addEventListener('touchmove', preventTouch, {
            passive: false,
        })

        const container = containerRef.current

        return () => {
            if (!container) {
                return
            }
            container.removeEventListener('touchstart', touchStart)
            container.removeEventListener('touchmove', preventTouch)
        }
    })

    return (
        <div ref={containerRef} {...others}>
            {children}
        </div>
    )
}

export default LockScroll
