import { usePushEcommerceEvent } from './usePushEvent'

export function useFilterAppliedEvent() {
    const push = usePushEcommerceEvent([], false)

    function pushFilterAppliedEvent(filterName: string, filterValue: string) {
        // Asuming that a user does not click faster than the required data is loaded
        push?.([
            { ecommerce: null },
            {
                event: 'filter_applied',

                filter_name: filterName,
                filter_value: filterValue,
            },
        ])
    }

    return pushFilterAppliedEvent
}
