import styled from '@emotion/styled'

import Text from '../typography/Text'

export interface Suggestion {
    description: string
    id: string
}
interface Props {
    suggestions: Suggestion[]
    onSelect(value: Suggestion): void
}

const Suggestions = styled.ul`
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;
    z-index: 2;
`

const SuggestionItem = styled.li`
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    padding-left: 40px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f2f2f2;
    }
`

const SearchSuggestions = ({ suggestions, onSelect }: Props) => {
    const suggestionsList = suggestions.map((suggestion, i) => (
        <SuggestionItem
            key={i}
            onClick={() => suggestion.description && onSelect(suggestion)}
        >
            <Text as="span">{suggestion.description}</Text>
        </SuggestionItem>
    ))

    return <Suggestions>{suggestionsList}</Suggestions>
}

export default SearchSuggestions
