import { makeVar } from '@apollo/client'
import { useIsLoggedIn } from '@emico-hooks/login-token'
import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { minWidth } from '@emico/styles'
import { useBreakpoints } from '@emico/ui'

import styles from './Header.module.scss'
import HeaderLink from './HeaderLink'
import { LanguagePicker } from './LanguagePicker'
import MobileNavigation from './MobileNavigation'
import Navigation from './Navigation'
import TopBar from './TopBar'
import { useIsJustRewardsEnabled } from '../../account-dashboard-page/AccountDashboardPageLayout/justRewards/isJustRewardsEnabled'
import AddressBookIcon from '../../account-dashboard-page/icons/AddressBookIcon'
import DashboardIcon from '../../account-dashboard-page/icons/DashboardIcon'
import OrdersIcon from '../../account-dashboard-page/icons/OrdersIcon'
import PersonalShopperIcon from '../../account-dashboard-page/icons/PersonalShopperIcon'
import SignOutIcon from '../../account-dashboard-page/icons/SignOutIcon'
import UserDetailsIcon from '../../account-dashboard-page/icons/UserDetailsIcon'
import BlueConicContent from '../../BlueConicContent'
import getSearchQuery from '../../catalog/SearchPage/getSearchQuery'
import {
    JUST_REWARDS_ENABLED,
    STORES_DISABLED,
    WISHLIST_DISABLED,
} from '../../constants'
import AccountIcon from '../../core/AccountIcon'
import CheckIcon from '../../core/CheckIcon'
import CloseIcon from '../../core/CloseIcon'
import Logo from '../../core/Logo'
import NextIcon from '../../core/NextIcon'
import RewardsIcon from '../../core/RewardsIcon'
import SearchIcon from '../../core/SearchIcon'
import StoresIcon from '../../core/StoresIcon'
import { useGetCustomer } from '../../customer/getCustomer.query'
import Button from '../../input/Button'
import Icon from '../../media/Icon'
import MiniMenu from '../../MiniMenu'
import Link from '../../navigation/Link'
import paths from '../../paths'
import { usePersonalShopperEnabled } from '../../PersonalShopperEnabled'
import Portal from '../../Portal'
import CartButton from '../../presentation/CartButton'
import FullscreenNavigationModal from '../../presentation/FullscreenNavigationModal/FullscreenNavigationModal'
import MobileModal from '../../presentation/ResponsiveModal/MobileModal'
import SearchMenu, { SearchItem } from '../../SearchMenu'
import theme from '../../theme'
import Heading from '../../typography/Heading'
import useWindowSize from '../../utils/useWindowSize'
import { VouchersBadge } from '../../VouchersBadge'
import WishlistActionButton from '../../WishlistActionButton'
import FadeTransition from '../FadeTransition'
import PageOverlay from '../PageOverlay'

const MIN_DESKTOP_HEADER_WIDTH = 1300

interface Props {
    className?: string
    showNavigation?: boolean
}

export const mobileMenuOpen = makeVar<boolean>(false)
export const mobileMenuVisibleVar = makeVar<boolean>(false)

const desktopSearchMenu = css`
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    min-height: 320px;
    background: #fff;
`

const StyledMiniMenu = styled(MiniMenu)`
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    @media ${minWidth('md')} {
        right: -19px;
        width: 375px;
    }
`

const AccountButton = styled(Button)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    > span {
        display: inline-block;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

const StyledCheckIcon = styled(CheckIcon)`
    width: 10px;
    fill: ${theme.colors.badgeFill};
`

const StyledCrossIcon = styled(CloseIcon)`
    width: 8px;
    fill: ${theme.colors.badgeFill};
`

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const Li = styled.li`
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid ${theme.colors.borderLight};
    @media ${minWidth('md')} {
        border-top: 1px solid ${theme.colors.borderLight};
        border-bottom: none;
    }
`

const IconWrapper = styled.span`
    color: ${theme.colors.white};
    position: relative;
`

const AccountLink = styled(Link)`
    display: flex;
    gap: 10px;
    padding: 10px 20px 10px 10px;
    text-decoration: none !important;
    align-items: center;
    line-height: 1;
    color: ${theme.colors.text} !important;
    &:hover,
    &:focus,
    &:active {
        color: ${theme.colors.text};
        background-color: ${theme.colors.borderLight};
    }
    @media ${minWidth('md')} {
        padding: 10px 30px 10px 28px;
    }
`

const useMyAccountLinks = () => {
    const isPersonalShopperEnabled = usePersonalShopperEnabled()
    const isRewardsEnabled = useIsJustRewardsEnabled()

    const links: Array<{
        className?: string
        url: string
        icon: React.ReactNode
        label: string
    }> = [
        {
            url: paths.account,
            icon: <DashboardIcon />,
            label: t({
                id: 'user.menu.dasboard',
                message: `Dashboard`,
            }),
        },
        {
            url: paths.accountDetails,
            icon: <UserDetailsIcon />,
            label: t({
                id: 'user.menu.accountDetails',
                message: `Account details`,
            }),
        },
        {
            url: paths.orderOverview,
            icon: <OrdersIcon />,
            label: t({
                id: 'user.menu.myOrders',
                message: `My purchases`,
            }),
        },
        {
            url: paths.addressBook,
            icon: <AddressBookIcon />,
            label: t({
                id: 'user.menu.addressBook',
                message: `Address book`,
            }),
        },
    ]

    if (isPersonalShopperEnabled) {
        links.push({
            url: paths.personalShopper,
            icon: <PersonalShopperIcon />,
            label: t({
                id: 'user.menu.personalShopper',
                message: `Personal shopper`,
            }),
        })
    }
    if (JUST_REWARDS_ENABLED) {
        links.push({
            url: paths.justRewards(),
            className: styles.priviliges,
            icon: <RewardsIcon />,
            label: t({
                id: 'user.menu.justRewards',
                message: `Just Rewards`,
            }),
        })
    }
    links.push({
        url: paths.logout,
        icon: <SignOutIcon />,
        label: t({
            id: 'user.menu.signOut',
            message: `Sign out`,
        }),
    })

    return links
}

const Header = ({ showNavigation = true, ...props }: Props) => {
    const { push } = useHistory()
    const size = useWindowSize()
    const { isMobile } = useBreakpoints()
    const location = useLocation()
    const { data: customer } = useGetCustomer()
    const [isSearchMenuVisible, setSearchMenuVisibility] =
        React.useState<boolean>()

    const [isAccountMenuVisible, setAccountMenuVisibility] =
        React.useState<boolean>()
    const searchQuery = getSearchQuery(location)
    const [searchTerm, setSearchTerm] = React.useState(searchQuery)
    const [isMobileMenu, setMobileMenu] = React.useState(false)
    const topBar = React.useRef<HTMLDivElement>(null)
    const miniMenuRef = React.useRef<HTMLDivElement>(null)
    const isLoggedIn = useIsLoggedIn()

    const [showMobileMenu, setMobileMenuVisibility] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        if (size?.width) {
            setMobileMenuVisibility(size.width < MIN_DESKTOP_HEADER_WIDTH)
            mobileMenuVisibleVar(size.width < MIN_DESKTOP_HEADER_WIDTH)
        }
    }, [size])

    const handleSearchSubmit = (item: SearchItem) => {
        setSearchMenuVisibility(false)
        setSearchTerm(item.keyword)
        push(item.urlKey)
    }

    const handleSearchMenuVisibility = React.useCallback(() => {
        setSearchMenuVisibility(!isSearchMenuVisible)
    }, [isSearchMenuVisible, setSearchMenuVisibility])

    const handleAccountMenuVisibility = React.useCallback(() => {
        if (!isLoggedIn) {
            push(paths.account)
        } else {
            setAccountMenuVisibility(!isAccountMenuVisible)
        }
    }, [isLoggedIn, push, isAccountMenuVisible])

    const myAccountLinks = useMyAccountLinks()

    const handleToggleMenu =
        (toggleFn: React.Dispatch<React.SetStateAction<boolean | undefined>>) =>
        (e: React.MouseEvent<HTMLElement>) => {
            toggleFn((visible) => !visible)
            // Prevent bubble up to doc click handler:
            e.stopPropagation()
        }

    React.useEffect(() => {
        const handleDocumentClicks = (e: Event) => {
            if (
                isAccountMenuVisible &&
                !miniMenuRef.current?.contains(e.target as Node)
            ) {
                setAccountMenuVisibility(false)
            }
        }

        document.addEventListener('click', handleDocumentClicks)
        return () => document.removeEventListener('click', handleDocumentClicks)
    }, [isAccountMenuVisible])

    const search = (
        <BlueConicContent
            blueConicPosition="searchTooltip"
            extraContent={
                <Button
                    variant="link"
                    className={cx(styles.link, styles.default)}
                    onClick={handleToggleMenu(setSearchMenuVisibility)}
                    name="Search"
                    category="searchButton"
                >
                    <Icon
                        data-cy="Header/searchButton"
                        component={isSearchMenuVisible ? CloseIcon : SearchIcon}
                        title={t({
                            id: 'core.topBar.searchButtonLabel',
                            message: `Search`,
                        })}
                        className={styles.icon}
                    />
                </Button>
            }
        >
            <div />
        </BlueConicContent>
    )

    const searchMenu = isSearchMenuVisible ? (
        showMobileMenu ? (
            <Portal>
                <MobileModal onBack={handleSearchMenuVisibility}>
                    <SearchMenu
                        onSubmit={handleSearchSubmit}
                        keyword={searchTerm}
                    />
                </MobileModal>
            </Portal>
        ) : (
            <>
                <FadeTransition>
                    <ClassNames>
                        {({ css }) => (
                            <SearchMenu
                                css={css`
                                    ${desktopSearchMenu}
                                `}
                                keyword={searchTerm}
                                onSubmit={handleSearchSubmit}
                            />
                        )}
                    </ClassNames>
                </FadeTransition>
                <Portal>
                    <FadeTransition>
                        <PageOverlay
                            onClick={() => setSearchMenuVisibility(false)}
                            css={css`
                                z-index: 109;
                            `}
                        />
                    </FadeTransition>
                </Portal>
            </>
        )
    ) : null

    const accountMenu = isAccountMenuVisible ? (
        isMobile ? (
            <FullscreenNavigationModal
                back={
                    <Icon
                        component={CloseIcon}
                        wrapperClassName={styles.closeIconWrapper}
                        title={t({
                            id: 'catalog.mobileFilter.modalClose',
                            message: `Close`,
                        })}
                    />
                }
                onBack={() => setAccountMenuVisibility(false)}
                title={
                    <Heading element="span" variant="h3">
                        {t({
                            id: 'core.topBar.accountMenuHeading',
                            message: `Hi`,
                        })}{' '}
                        {customer?.firstname}
                    </Heading>
                }
            >
                <Ul>
                    {myAccountLinks.map(
                        ({ url, label, icon, className }, index) => (
                            <Li key={`account-menu-item-${index}`}>
                                <AccountLink
                                    to={url}
                                    name=""
                                    category=""
                                    variant="none"
                                    onClick={() =>
                                        setAccountMenuVisibility(false)
                                    }
                                >
                                    <IconWrapper>
                                        {icon}
                                        {url === paths.justRewards() && (
                                            <VouchersBadge />
                                        )}
                                    </IconWrapper>
                                    <span className={className}>{label}</span>
                                    <ClassNames>
                                        {({ css }) => (
                                            <Icon
                                                component={NextIcon}
                                                title=""
                                                wrapperClassName={css`
                                                    margin-left: auto;
                                                `}
                                            />
                                        )}
                                    </ClassNames>
                                </AccountLink>
                            </Li>
                        ),
                    )}
                </Ul>
            </FullscreenNavigationModal>
        ) : (
            <StyledMiniMenu
                ref={miniMenuRef}
                onClose={() => setAccountMenuVisibility(false)}
                heading={
                    <>
                        {t({
                            id: 'core.topBar.accountMenuHeading',
                            message: `Hi`,
                        })}{' '}
                        {customer?.firstname}
                    </>
                }
            >
                <Ul>
                    {myAccountLinks.map(
                        ({ url, label, icon, className }, index) => (
                            <Li key={`account-menu-item-${index}`}>
                                <AccountLink
                                    to={url}
                                    name=""
                                    category=""
                                    variant="none"
                                    onClick={() =>
                                        setAccountMenuVisibility(false)
                                    }
                                >
                                    <IconWrapper>
                                        {icon}
                                        {url === paths.justRewards() && (
                                            <VouchersBadge />
                                        )}
                                    </IconWrapper>
                                    <span className={className}>{label}</span>
                                    <ClassNames>
                                        {({ css }) => (
                                            <Icon
                                                component={NextIcon}
                                                title=""
                                                wrapperClassName={css`
                                                    margin-left: auto;
                                                `}
                                            />
                                        )}
                                    </ClassNames>
                                </AccountLink>
                            </Li>
                        ),
                    )}
                </Ul>
            </StyledMiniMenu>
        )
    ) : null

    const account = (
        <BlueConicContent
            blueConicPosition="accountTooltip"
            extraContent={
                <AccountButton
                    variant="link"
                    className={cx(styles.link, styles.default)}
                    onClick={handleToggleMenu(handleAccountMenuVisibility)}
                    name="Account"
                    category="accountButton"
                >
                    <Icon
                        data-cy="Header/accountButton"
                        component={AccountIcon}
                        title={t({
                            id: 'core.topBar.accountButtonLabel',
                            message: `Account`,
                        })}
                        className={styles.icon}
                        badgeClassName={customer ? styles.badgeSuccess : ''}
                        content={
                            customer ? <StyledCheckIcon /> : <StyledCrossIcon />
                        }
                    />
                </AccountButton>
            }
        >
            <div />
        </BlueConicContent>
    )

    const stores = !STORES_DISABLED && (
        <BlueConicContent
            blueConicPosition="storesTooltip"
            extraContent={
                <HeaderLink
                    name="Stores"
                    to={paths.storeLocator}
                    icon={StoresIcon}
                    title={t({
                        id: 'core.topBar.storesButtonLabel',
                        message: `Stores`,
                    })}
                />
            }
        >
            <div />
        </BlueConicContent>
    )

    function toggleMobileMenu() {
        mobileMenuOpen(!isMobileMenu)
        setMobileMenu(!isMobileMenu)
    }

    const mobileMenu = (
        <>
            <MobileNavigation close={toggleMobileMenu} open={isMobileMenu} />

            <Button
                variant="link"
                className={cx(
                    styles.link,
                    styles.default,
                    styles.mobileIcon,
                    isMobileMenu ? styles.inActive : styles.active,
                )}
                name="MobileMenu"
                onClick={() => toggleMobileMenu()}
                category="mobileMenu"
            >
                <span />
                <span />
                <span />
            </Button>
        </>
    )
    const wishlist = !WISHLIST_DISABLED && <WishlistActionButton />

    const cart = (
        <CartButton
            linkClassName={cx(styles.link, styles.default)}
            className={styles.icon}
        />
    )

    const navigation = showNavigation ? (
        <Navigation className={styles.navigation} />
    ) : null

    if (showMobileMenu) {
        return (
            <>
                <TopBar
                    ref={topBar}
                    data-cy="Header"
                    logo={<Logo location="header-small" />}
                    right={
                        showNavigation && (
                            <ul className={cx(styles.actions, styles.right)}>
                                <li className={styles.withModal}>
                                    {account}
                                    {accountMenu}
                                </li>
                                <li className={styles.withModal}>{search}</li>
                                {!WISHLIST_DISABLED && (
                                    <li className={styles.withModal}>
                                        {wishlist}
                                    </li>
                                )}
                                <li className={styles.withModal}>{cart}</li>
                                <li>{mobileMenu}</li>
                            </ul>
                        )
                    }
                    {...props}
                >
                    {navigation}
                </TopBar>
                {searchMenu}
            </>
        )
    }

    return (
        <>
            <TopBar
                data-cy="Header"
                ref={topBar}
                logo={<Logo location="header" />}
                left={!isSearchMenuVisible && navigation}
                right={
                    showNavigation && (
                        <ul className={cx(styles.actions, styles.right)}>
                            <LanguagePicker />
                            <li
                                style={
                                    isSearchMenuVisible
                                        ? { visibility: 'hidden' }
                                        : undefined
                                }
                                className={styles.withModal}
                            >
                                {account}
                                {accountMenu}
                            </li>
                            <li className={styles.search}>{search}</li>
                            {stores && (
                                <li
                                    style={
                                        isSearchMenuVisible
                                            ? { visibility: 'hidden' }
                                            : undefined
                                    }
                                    className={styles.withModal}
                                >
                                    {stores}
                                </li>
                            )}
                            {!WISHLIST_DISABLED && (
                                <li
                                    style={
                                        isSearchMenuVisible
                                            ? { visibility: 'hidden' }
                                            : undefined
                                    }
                                    className={styles.withModal}
                                >
                                    {wishlist}
                                </li>
                            )}
                            <li className={styles.withModal}>{cart}</li>
                        </ul>
                    )
                }
                {...props}
            />
            {searchMenu}
        </>
    )
}

export default Header
