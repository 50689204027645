import { makeVar, useReactiveVar } from '@apollo/client'

const miniCartVar = makeVar<boolean | undefined>(undefined)

export const openMiniCart = () => miniCartVar(true)
export const closeMiniCart = () => miniCartVar(false)

export const useMiniCartVar = () => {
    const isMiniCartOpened = useReactiveVar(miniCartVar)

    return {
        open: () => {
            miniCartVar(true)
        },
        close: () => {
            miniCartVar(false)
        },
        isMiniCartOpened,
    }
}
