import cx from 'classnames'
import * as React from 'react'

import styles from './HeroImage.module.scss'
import ImageLazy from '../../../media/ImageLazy'

interface Props {
    url: string
    mobile_url?: string
    className?: string
}

const HeroImage = ({ url, mobile_url, className }: Props) => (
    <ImageLazy
        images={{
            image: url,
            image_mobile: mobile_url ? mobile_url : url,
            regularWidth: 2000,
            mediumWidth: 991,
            smallWidth: 767,
        }}
        alt=""
        className={cx(styles.imageWrapper, className)}
        imageClassName={styles.image}
    />
)

export default HeroImage
