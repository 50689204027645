import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import * as React from 'react'

import OpenOrClosed from './OpenOrClosed'
import { SrsStore } from './useSrsStore'
import Text from '../typography/Text'

interface Props {
    store: SrsStore | undefined
}

const HourRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`

const StyledClosed = styled.div`
    min-width: 160px;
    text-align: center;
`

const StyledOpenClosed = styled(OpenOrClosed)`
    p {
        font-size: 18px;
        font-weight: bold;
    }
`

interface HourRowInformationProps {
    open: string | null | undefined
    closed: string | null | undefined
    text: string
}
interface ClosedProps {
    open: string | null | undefined
    closed: string | null | undefined
}

const Closed = ({ open, closed }: ClosedProps) => {
    const openText = open?.toLowerCase()
    const closedText = closed?.toLowerCase()

    if (openText === 'close' || (!open && closedText === 'close') || !closed) {
        return (
            <StyledClosed>
                {t({
                    id: 'storelocator.storedetails.closedText',
                    message: `Closed`,
                })}
            </StyledClosed>
        )
    }

    return <StyledClosed>{`${open}u - ${closed}u`}</StyledClosed>
}

const HourRowInformation = ({
    open,
    closed,
    text,
}: HourRowInformationProps) => {
    if (!open && !closed) {
        return null
    }
    return (
        <HourRow>
            <Text color="dark" as="span">
                {text}
            </Text>
            <Text color="dark" as="span">
                <Closed open={open} closed={closed} />
            </Text>
        </HourRow>
    )
}

const OpeningHours = ({ store }: Props) => (
    <>
        {store && store.gTimes && <StyledOpenClosed times={store?.gTimes} />}
        <HourRowInformation
            open={store?.gTimes?.[1]?.open}
            text={t({
                id: 'storelocator.storedetails.monday',
                message: `Monday`,
            })}
            closed={store?.gTimes?.[1]?.close}
        />
        <HourRowInformation
            open={store?.gTimes?.[2]?.open}
            text={t({
                id: 'storelocator.storedetails.tuesday',
                message: `Tuesday`,
            })}
            closed={store?.gTimes?.[2]?.close}
        />
        <HourRowInformation
            open={store?.gTimes?.[3]?.open}
            text={t({
                id: 'storelocator.storedetails.wednesday',
                message: `Wednesday`,
            })}
            closed={store?.gTimes?.[3]?.close}
        />
        <HourRowInformation
            open={store?.gTimes?.[4]?.open}
            text={t({
                id: 'storelocator.storedetails.thursday',
                message: `Thursday`,
            })}
            closed={store?.gTimes?.[4]?.close}
        />
        <HourRowInformation
            open={store?.gTimes?.[5]?.open}
            text={t({
                id: 'storelocator.storedetails.friday',
                message: `Friday`,
            })}
            closed={store?.gTimes?.[5]?.close}
        />
        <HourRowInformation
            open={store?.gTimes?.[6]?.open}
            text={t({
                id: 'storelocator.storedetails.saturday',
                message: `Saturday`,
            })}
            closed={store?.gTimes?.[6]?.close}
        />
        <HourRowInformation
            open={store?.gTimes?.[0]?.open}
            text={t({
                id: 'storelocator.storedetails.sunday',
                message: `Sunday`,
            })}
            closed={store?.gTimes?.[0]?.close}
        />
    </>
)

export default OpeningHours
