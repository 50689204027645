import { useCart } from '@emico-hooks/cart'

import { useFetchDefaultCurrencyCode } from '@emico/currency-code'

import Api from './Api'
import AutoLoader from './AutoLoader'
import SRSSessionCleanup from './cart/SRSSessionCleanup'
import GoogleTagManagerScript from './core/GoogleTagManagerScript'
import { useCustomerGroupSync } from './customer/useCustomerGroupSync'
import useMenuItems from './layout/Header/useMenuItems'
import PortalTarget from './PortalTarget'
import PreloadFonts from './PreloadFonts'
import RichSnippets from './richSnippets'
import RobinHqProactiveChatDetection from './RobinHqProactiveChatDetection'
import AppRouter from './Routing'
import useHighstreetToken from './useHighstreetToken'
import { TrustedShopsScript } from './utils/trustedShops'
import useHighstreetAppBanner from './utils/useHighstreetAppBanner'
import useWishlistId from './wishlist/useWishlistId'

const Hooks = () => {
    useHighstreetToken()
    useHighstreetAppBanner()
    useFetchDefaultCurrencyCode()
    useCustomerGroupSync()

    useWishlistId()

    // Preload menu items so they are in cache
    useMenuItems()

    return null
}

const AppBehavior = ({ preloadFonts }: { preloadFonts: string[] }) => (
    <>
        <Hooks />
        <AutoLoader>
            {/* Load before AppRouter so the dataLayer is initialized before any components try to use it */}
            <GoogleTagManagerScript />
            <RichSnippets />
            <PreloadFonts fonts={preloadFonts} />
            <AppRouter />
            <PortalTarget />
            <SRSSessionCleanup />
            <RobinHqProactiveChatDetection />
            <Api />
            <TrustedShopsScript
                variant="custom_reviews"
                customElementId="tsBadge"
                customBadgeHeight="40"
                customBadgeWidth="100"
                disableResponsive="true"
            />
        </AutoLoader>
    </>
)

export default AppBehavior
