import cx from 'classnames'
import * as React from 'react'

import styles from './ValidIndicator.module.scss'
import CheckIcon from '../../core/CheckIcon'

interface Props {
    className?: string
}

const ValidIndicator = ({ className }: Props) => (
    <span className={cx(styles.base, className)}>
        <CheckIcon />
    </span>
)

export default ValidIndicator
