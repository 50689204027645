import styled from '@emotion/styled'
import * as React from 'react'

import { minWidth } from '@emico/styles'

import { useCoreConfigValue } from '../coreConfig.query'
import MediaIcon from '../media/Icon'
import Link from '../navigation/Link'
import FacebookIcon from '../static/social/facebook.svg?react'
import InstagramIcon from '../static/social/instagram.svg?react'
import TwitterIcon from '../static/social/twitter.svg?react'
import YoutubeIconLight from '../static/social/youtube.svg?react'
import theme from '../theme'

const Wrapper = styled.div`
    display: inline-flex;
    gap: 10px;
    margin-top: 10px;
`

const Icon = styled(MediaIcon)`
    width: 23px;
    height: 23px;
    fill: ${theme.colors.text};
    color: ${theme.colors.background};

    @media ${minWidth('lg')} {
        width: 36px;
        height: 36px;
    }
`

const FooterSocialLinks = () => {
    const { value: facebookLink } = useCoreConfigValue(
        'justbrands_social/facebook/profile_link',
    )

    const { value: facebookTitle } = useCoreConfigValue(
        'justbrands_social/facebook/profile_link_title',
    )

    const { value: instagramLink } = useCoreConfigValue(
        'justbrands_social/instagram/profile_link',
    )

    const { value: instagramTitle } = useCoreConfigValue(
        'justbrands_social/instagram/profile_link_title',
    )

    const { value: twitterLink } = useCoreConfigValue(
        'justbrands_social/twitter/profile_link',
    )

    const { value: twitterTitle } = useCoreConfigValue(
        'justbrands_social/twitter/profile_link_title',
    )

    const { value: youtubeLink } = useCoreConfigValue(
        'justbrands_social/youtube/profile_link',
    )

    const { value: youtubeTitle } = useCoreConfigValue(
        'justbrands_social/youtube/profile_link_title',
    )

    return (
        <Wrapper>
            {facebookLink && (
                <Link
                    name="Facebook"
                    category="socialLinks"
                    to={facebookLink}
                    title={facebookTitle}
                >
                    <Icon component={FacebookIcon} title="Facebook" />
                </Link>
            )}
            {instagramLink && (
                <Link
                    name="Instagram"
                    category="socialLinks"
                    to={instagramLink}
                    title={instagramTitle}
                >
                    <Icon component={InstagramIcon} title="Instagram" />
                </Link>
            )}
            {twitterLink && (
                <Link
                    name="Twitter"
                    category="socialLinks"
                    to={twitterLink}
                    title={twitterTitle}
                >
                    <Icon component={TwitterIcon} title="Twitter" />
                </Link>
            )}
            {youtubeLink && (
                <Link
                    name="Youtube"
                    category="socialLinks"
                    to={youtubeLink}
                    title={youtubeTitle}
                >
                    <Icon component={YoutubeIconLight} title="YouTube" />
                </Link>
            )}
        </Wrapper>
    )
}

export default FooterSocialLinks
