import { useApolloClient } from '@apollo/client'
import { useLoginTokenContext } from '@emico-hooks/login-token'
import cx from 'classnames'
import merge from 'deepmerge'
import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'

import { useActiveStoreView } from '@emico/storeviews'

import styles from './Link.module.scss'
import { DEBUG_PREFETCH_URL, REQUIRE_LOGIN_FOR_PRELOAD } from '../../constants'
import isHighstreet from '../../isHighstreet'
import { useInternalLinkClickEvent } from '../../utils/ga4/useInternalLinkClickEvent'
import linkClick from '../../utils/googleTagManager/linkClick'
import push from '../../utils/googleTagManager/push'
import { isAbsolute, isMailTo, isPhone } from '../../utils/url'
import ExternalLink from '../ExternalLink'
import PreloadResolverInternalLink, {
    Props as PreloadResolverInternalLinkProps,
} from '../PreloadResolverInternalLink'
import { Props as RouteLinkProps } from '../RouteLink'

type LinkVariant =
    | 'default'
    | 'light'
    | 'secondary'
    | 'none'
    | 'camouflaged'
    | 'dark'
    | 'inverted'

interface OwnProps {
    variant?: LinkVariant
    // GTM props
    name: string
    category: string
    resolver?: PreloadResolverInternalLinkProps['resolver'] | false | true // pass false for hard-coded links, true to be explicit that the resolver should determine the page type
    // Load a prerendered apollo state
    preload?: boolean | 'hover'
}

export type Props = OwnProps & RouteLinkProps

const preloadCache = new Map()

function fastArrayMerge<T>(arrayA: T[], arrayB: T[]) {
    const arrayALength = arrayA.length

    if (arrayALength === 0) {
        return arrayB
    }

    const arrayC = [...arrayA]

    for (let i = 0; i < arrayB.length; i += 1) {
        const bItem = arrayB[i]
        const cItem = arrayC[i]
        if (bItem != null && cItem == null) {
            arrayC[i] = bItem
        } else if (bItem == null && cItem == null && i > arrayC.length) {
            arrayC[i] = bItem
        }
        // In the other cases, cItem is still the correct version
    }

    return arrayC
}

const Link = ({
    to,
    resolver,
    target,
    children,
    className,
    activeClassName,
    onClick,
    onMouseOver,
    name,
    category,
    variant = 'default',
    preload,
    ...others
}: Props) => {
    const storeView = useActiveStoreView()
    const loginTokenContext = useLoginTokenContext()
    const client = useApolloClient()
    const history = useHistory()
    const [clicked, setClicked] = useState(false)
    const [ref, inView] = useInView({
        triggerOnce: true,
    })
    to = to.trim()

    const pushClickEvent = useInternalLinkClickEvent()

    const classNames = cx(styles[variant], className)
    const isExternal = isAbsolute(to) || isPhone(to) || isMailTo(to)

    const triggerPrefetch = useCallback(() => {
        if (
            (process.env.NODE_ENV === 'development' && !DEBUG_PREFETCH_URL) ||
            !preload
        ) {
            return
        }

        if (preloadCache.has(to)) {
            return
        }

        if (REQUIRE_LOGIN_FOR_PRELOAD && !loginTokenContext()) {
            return
        }

        const headers = new Headers()
        headers.append('X-Apollo-State', 'true')
        headers.append('Accept', 'application/json')
        try {
            const baseUrl = storeView.makeUrl(to)
            const url = new URL(baseUrl, 'http://a/')
            if (DEBUG_PREFETCH_URL) {
                const debugUrl = new URL(DEBUG_PREFETCH_URL)
                url.hostname = debugUrl.hostname
                url.port = debugUrl.port
            }
            url.searchParams.set('x-apollo-state', '1')
            const path = `${url.pathname.replace(/\/$/, '')}${url.search}`

            const request = fetch(`${DEBUG_PREFETCH_URL ?? ''}${path}`, {
                headers,
                priority: 'low',
            })
                .then((r) => r.json())
                .catch(() => {
                    preloadCache.delete(to)
                })
            preloadCache.set(to, request)
        } catch {
            // no-op
        }
    }, [loginTokenContext, preload, storeView, to])

    useEffect(() => {
        if (!inView || preload === 'hover') {
            return
        }
        triggerPrefetch()
    }, [inView, preload, triggerPrefetch])

    if (isExternal) {
        return (
            <ExternalLink
                to={to}
                target={
                    target ||
                    (isAbsolute(to) && !isHighstreet() ? '_blank' : '_self')
                }
                className={classNames}
                name={name}
                category={category}
                {...others}
            >
                {children}
            </ExternalLink>
        )
    }

    if (
        process.env.REACT_APP_PRELOAD_RESOLVER_DATA !== 'false' &&
        resolver !== false &&
        !preload
    ) {
        return (
            <PreloadResolverInternalLink
                {...{
                    to,
                    target,
                    activeClassName,
                    children,
                    className: classNames,
                }}
                onClick={(e) => {
                    if (onClick) {
                        onClick(e)
                    }
                    if (e.defaultPrevented) {
                        return
                    }
                    const target = e.currentTarget
                    // Timeout because the push event should not be run on the main thread
                    setTimeout(() => {
                        push(linkClick(name, category, 'internal'))
                        pushClickEvent(
                            to,
                            category,
                            name,
                            target.textContent ?? '',
                        )
                    }, 16)
                }}
                resolver={resolver !== true ? resolver : undefined}
                {...others}
            />
        )
    }

    return (
        <div
            ref={ref}
            style={{ display: 'inline', opacity: clicked ? 0.8 : 1 }}
        >
            <PreloadResolverInternalLink
                to={to}
                target={target}
                activeClassName={activeClassName}
                className={classNames}
                onMouseOver={
                    preload === 'hover'
                        ? (e) => {
                              if (onMouseOver) {
                                  onMouseOver(e)
                              }
                              if (e.defaultPrevented) {
                                  return
                              }
                              triggerPrefetch()
                          }
                        : onMouseOver
                }
                onClick={(e) => {
                    if (onClick) {
                        onClick(e)
                    }
                    if (e.defaultPrevented) {
                        return
                    }
                    const target = e.currentTarget
                    e.preventDefault()
                    setClicked(true)
                    setTimeout(() => {
                        if (preloadCache.get(to)) {
                            preloadCache
                                .get(to)
                                .then((initialState: object) => {
                                    const existingCache = client.extract()
                                    const data = merge<object>(
                                        initialState,
                                        existingCache,
                                        {
                                            // combine arrays using object equality (like in sets)
                                            arrayMerge: fastArrayMerge,
                                        },
                                    )

                                    client.cache.restore(data)
                                })
                                .catch(() => {
                                    // no-op
                                })
                                .finally(() => {
                                    setClicked(false)
                                    history.push(to)
                                })
                        } else {
                            setClicked(false)
                            history.push(to)
                        }

                        push(linkClick(name, category, 'internal'))
                        pushClickEvent(
                            to,
                            category,
                            name,
                            target.textContent ?? '',
                        )
                    }, 16)
                }}
                {...others}
            >
                {children}
            </PreloadResolverInternalLink>
        </div>
    )
}

export default Link
