import cx from 'classnames'
import * as React from 'react'

import styles from './Grid.module.scss'
import { Breakpoint, Breakpoints } from '../../types'

type ColumnCount = 1 | 2 | 3 | 4

export interface Props {
    /**
     * Custom element to render this component to
     * @default 'div'
     */
    as?: React.ElementType

    /** Custom styling hook */
    className?: string

    /**
     * No. of columns on extra small devices
     * @default 1
     */
    xs?: ColumnCount

    /**
     * No. of columns on small devices
     * @default 2
     */
    sm?: ColumnCount

    /**
     * No. of columns on medium devices
     * @default 3
     */
    md?: ColumnCount

    /**
     * No. of columns on large devices
     * @default 3
     */
    lg?: ColumnCount

    /**
     * No. of columns on extra large devices
     * @default 4
     */
    xl?: ColumnCount

    children: React.ReactNode
}

// Get valid breakpoints based on global enum
const deviceSizes = Object.values(Breakpoints)

const Grid: React.FC<Props> = ({
    as = 'div',
    className,
    children,
    xs = 1,
    sm = 2,
    md = 3,
    lg = 4,
    xl = 4,
}) => {
    const Component = as
    const gridColumnWidths: string[] = []
    const columnCount = { xs, sm, md, lg, xl }

    deviceSizes.forEach((brkPoint: Breakpoint) => {
        const colSpan = columnCount[brkPoint]

        delete columnCount[brkPoint]

        if (colSpan != null) {
            gridColumnWidths.push(styles[`grid-${brkPoint}-${colSpan}`])
        }
    })

    return (
        <Component className={cx(className, styles.grid, ...gridColumnWidths)}>
            {children}
        </Component>
    )
}

export default Grid
