import { Trans, t } from '@lingui/macro'

import styles from './Header.module.scss'
import BackIcon from '../core/BackIcon'
import PageLoader from '../presentation/PageLoader'
import Heading from '../typography/Heading'
import Text from '../typography/Text'

interface Props {
    onBack?: () => void
    title: string
    subTitle: string
    loading?: boolean
}

export const Header = ({ title, subTitle, onBack, loading }: Props) => (
    <div className={styles.container}>
        <div className={styles.header}>
            {loading ? (
                <PageLoader
                    reason={t({
                        id: 'guidedSelling.loading',
                        message: 'Loading results',
                    })}
                />
            ) : (
                <>
                    {onBack && (
                        <div className={styles.nav} onClick={onBack}>
                            <BackIcon />
                            <Trans id="guidedSelling.back">Previous step</Trans>
                        </div>
                    )}
                    <Heading variant="h1" color="dark" element="h1">
                        {title}
                    </Heading>

                    <Text
                        variant="primary"
                        color="dark"
                        className={styles.subHeader}
                    >
                        {subTitle}
                    </Text>
                </>
            )}
        </div>
    </div>
)
