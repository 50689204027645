import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './OutOfStock.module.scss'
import { WISHLIST_DISABLED } from '../../../constants'
import Button from '../../../input/Button'
import { getBreadcrumbs } from '../../../navigation/ProductBreadcrumbs'
import Text from '../../../typography/Text'
import WishlistIconButton from '../../../wishlist/WishlistIconButton'
import { useRootCategoryId } from '../../useRootCategory'
import { ConfigurableAttributesFieldValue } from '../ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { isGroupedProduct, Product } from '../GetProduct.query'

interface Props extends React.HTMLAttributes<HTMLElement> {
    product: Product | ConfigurableProduct
    value?: ConfigurableAttributesFieldValue
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
`

const OutOfStockInfo = ({
    product,
    className,
    value,
    ...otherProps
}: Props) => {
    const rootCategoryId = useRootCategoryId()

    if (!rootCategoryId) {
        return null
    }

    const breadcrumbs = getBreadcrumbs(
        product.categories,
        rootCategoryId,
        product.name,
        product.urlKey,
    )

    if (breadcrumbs.length === 0 && product.mainCategory == null) {
        return null
    }

    const mainCategoryLink =
        product.mainCategory?.urlPath ?? breadcrumbs[0].label
    const mainCategoryLabel =
        product.mainCategory?.name?.toLowerCase() ??
        breadcrumbs[0].label.toLowerCase()

    return (
        <section className={cx(styles.base, className)} {...otherProps}>
            <Text as="h2" className={styles.heading}>
                <Trans id="core.outOfStockInfo.heading">Out of stock</Trans>
            </Text>

            <Text
                css={css`
                    margin-bottom: 1em;
                `}
            >
                {isGroupedProduct(product) ? (
                    <Trans id="core.outOfStockInfo.textGroupedProduct">
                        Sorry, unfortunately this look is out of stock. But we
                        still have some other amazing looks that you may like.
                    </Trans>
                ) : (
                    <Trans id="core.outOfStockInfo.text">
                        Sorry, unfortunately this product is out of stock. But
                        we still have some other amazing {mainCategoryLabel}{' '}
                        that you may like.
                    </Trans>
                )}
            </Text>

            <Wrapper>
                <Button
                    variant="primary"
                    wide
                    to={mainCategoryLink}
                    name="Go to main product category"
                    category="productPage.outOfStock"
                >
                    <Trans id="core.outOfStockInfo.buttonLabel">
                        Go to {mainCategoryLabel}
                    </Trans>
                </Button>

                {!WISHLIST_DISABLED && (
                    <WishlistIconButton
                        product={product}
                        css={css`
                            width: 40px;
                            flex: 0 0 40px;
                        `}
                        value={value}
                        showWishlistModal
                    />
                )}
            </Wrapper>
        </section>
    )
}

export default OutOfStockInfo
