import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { ReactNode } from 'react'

import styles from './Footer.module.scss'
import Text from '../typography/Text'
// import Text from '../../typography/Text'

interface Props {
    button: ReactNode
    numberOfResults: number
    className?: string
    dark?: boolean
}

export const Footer = ({ button, className, numberOfResults, dark }: Props) => (
    <div className={styles.footerMain}>
        <div
            className={classNames(styles.container, className, {
                [styles.footerDark]: dark,
            })}
        >
            <div className={styles.contents}>
                {button}
                <Text variant="primary" color="dark">
                    <Trans id="guidedSelling.resultCount">
                        {numberOfResults} results found
                    </Trans>
                </Text>
            </div>
        </div>
    </div>
)
