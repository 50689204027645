import {
    TweakwiseFunnelAnswer,
    TweakwiseFunnelQuestion,
} from '@emico-hooks/graphql-schema-types'
import { ReactElement } from 'react'
import { UseFormRegister } from 'react-hook-form'

import { QuestionDefinition, QuestionType } from '../TweakwiseConfigurator'

export const MAX_COLS = 4

export type OptionsType = {
    isMultiSelect?: boolean
    isSkippable?: boolean
    skipLabel?: string
    answers?: TweakwiseFunnelAnswer[]
    onSelectionChange?: (answerIds: string[] | string) => void
}

interface Props {
    children: Array<ReactElement<OptionsType>>
    question?: TweakwiseFunnelQuestion & QuestionDefinition
    onSelectionChange: (answerIds: string[] | string) => void
}
