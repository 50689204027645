import { css } from '@emotion/react'
import * as React from 'react'

import { ConfigurableAttributesFieldValue } from './catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { isGroupedProduct } from './catalog/ProductPage/GetProduct.query'
import ProductInfoFormMinimal from './catalog/ProductPage/StickyCta/ProductInfoFormMinimal'
import { ProductCard, ProductCardThemesType } from './presentation/ProductCard'

type Props = Pick<
    React.ComponentProps<typeof ProductCard>,
    | 'product'
    | 'children'
    | 'initialValue'
    | 'withBookmark'
    | 'withColorSwatch'
    | 'position'
    | 'itemIndex'
    | 'itemListId'
    | 'itemListName'
    | 'itemListSlot'
> & {
    onValueChange?(value: ConfigurableAttributesFieldValue): void
}

const ProductCardWithAddToCart = React.forwardRef<HTMLDivElement, Props>(
    function ProductCardWithAddToCart(
        {
            product,
            withBookmark,
            withColorSwatch,
            initialValue,
            onValueChange,
            children,
            position,
            itemIndex,
            itemListId,
            itemListName,
            itemListSlot,
        },
        ref,
    ) {
        return (
            <ProductCard
                product={product}
                withBookmark={withBookmark}
                initialValue={initialValue}
                withColorSwatch={withColorSwatch}
                theme={ProductCardThemesType.default}
                position={position}
                ref={ref}
                itemIndex={itemIndex}
                itemListId={itemListId}
                itemListName={itemListName}
                itemListSlot={itemListSlot}
            >
                {children}
                {!isGroupedProduct(product) && (
                    <ProductInfoFormMinimal
                        product={product}
                        withBookmark={withBookmark}
                        initialValue={initialValue}
                        onValueChange={onValueChange}
                        verticalLayout
                        css={css`
                            padding: 5px 0 0 0 !important;
                            margin-top: auto;
                        `}
                    />
                )}
            </ProductCard>
        )
    },
)

export default ProductCardWithAddToCart
