import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import styles from './HeightTransition.module.scss'

const CSS_TRANSITION_DURATION = 200

interface Props {
    children: React.ReactNode
}

const HeightTransition = ({ children }: Props) => (
    <TransitionGroup>
        {React.Children.map(children, (child) => {
            if (!child) {
                return null
            }

            return (
                <CSSTransition
                    timeout={CSS_TRANSITION_DURATION}
                    appear
                    classNames={{
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                        appear: styles.appear,
                        appearActive: styles.appearActive,
                    }}
                >
                    {child}
                </CSSTransition>
            )
        })}
    </TransitionGroup>
)

export default HeightTransition
