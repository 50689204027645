import { gql, useQuery } from '@apollo/client'
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { TweakwiseFunnel } from '@emico-hooks/graphql-schema-types'

export const TweakwiseFunnelItemFragment = gql`
    fragment TweakwiseFunnelItemFragment on TweakwiseFunnelItem {
        itemNo
        title
        brand
        price
        url
        imageUrl
        labels
    }
`

const TweakwiseFunnelFragment = gql`
    fragment TweakwiseFunnelFragment on TweakwiseFunnel {
        code
        name
        navigationUrl
        filterTemplateId
        count
        questions {
            id
            question
            isMultiSelect
            image
            explanation
            current
            skippable
            skipLabel

            answers {
                answerId
                answer
                caption
                summary
                imageUrl
                count
                isSelected
                nextQuestionId
            }
        }
    }
`

const query = gql`
    query TweakwiseFunnel(
        $code: String!
        $answers: [TweakwiseFunnelAnswerInput]
        $skippedQuestions: [Int]
    ) {
        tweakwiseFunnel(
            params: {
                code: $code
                answers: $answers
                skippedQuestions: $skippedQuestions
            }
        ) {
            ...TweakwiseFunnelFragment

            items {
                ...TweakwiseFunnelItemFragment
            }
        }
    }
    ${TweakwiseFunnelFragment}
    ${TweakwiseFunnelItemFragment}
`

export interface Answer {
    questionId?: string
    skipQuestion?: boolean
    answerIds?: string[]
}

export function useTweakwiseFunnel(code: string, answers: Answer[]) {
    const { data, networkStatus } = useQuery<{
        tweakwiseFunnel: TweakwiseFunnel
    }>(query, {
        fetchPolicy: 'network-only',
        variables: {
            code,
            answers: answers.map((a) => ({
                questionId: a.questionId,
                answerIds: a.answerIds,
            })),
            skippedQuestions: answers
                .filter((a) => a.skipQuestion)
                .map((a) => parseInt(a?.questionId ?? ''))
                .pop(),
        },
    })

    return {
        data: data?.tweakwiseFunnel,
        loading: isNetworkRequestInFlight(networkStatus),
    }
}
