import { Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './LoadMore.module.scss'
import Button from '../../input/Button'

interface Props {
    numItemsSeen: number
    numTotalResults: number
    onClick(): void
    loading: boolean
    className?: string
    label?: React.ReactNode
}

const LoadMore = ({
    numItemsSeen,
    numTotalResults,
    onClick,
    loading,
    className,
    label = (
        <Trans id="catalog.loadMore.progress">
            You've viewed {numItemsSeen} of {numTotalResults} products
        </Trans>
    ),
}: Props) => (
    <div className={cx(styles.container, className)}>
        {label}
        <div className={styles.bar}>
            <div
                className={styles.progress}
                style={{
                    width: `${(
                        Math.min(1, numItemsSeen / numTotalResults) * 100
                    ).toFixed(2)}%`,
                }}
            />
        </div>
        <Button
            minWidth
            onClick={onClick}
            variant="primary"
            size="hero"
            loading={loading}
            disabled={loading}
            name="Load more"
            category="catalog.loadMore"
        >
            <Trans id="catalog.loadMore.button">Load more</Trans>
        </Button>
    </div>
)

export default LoadMore
