import cx from 'classnames'
import { HTMLAttributes, ReactNode } from 'react'

import styles from './MiniCartItemContainer.module.scss'

interface Props extends HTMLAttributes<HTMLElement> {
    children: ReactNode
    className?: string
}

const MiniCartItemContainer = ({ children, className, ...others }: Props) => (
    <article className={cx(styles.container, className)} {...others}>
        {children}
    </article>
)

export default MiniCartItemContainer
