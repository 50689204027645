import dataLayer from './dataLayer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function push(object: Record<string, any>) {
    // Clear the ecommerce object first
    // See: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clearing_the_ecommerce_object
    dataLayer.push({ ecommerce: null })

    dataLayer.push(object)
}
