import * as React from 'react'
import Helmet from 'react-helmet'

const Searchbox = () => {
    const url = window !== undefined ? window.location.origin : null

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'WebSite',
                    url,
                    potentialAction: {
                        '@type': 'SearchAction',
                        target: `${url}/search?q={search_term_string}`,
                        'query-input': 'required name=search_term_string',
                    },
                })}
            </script>
        </Helmet>
    )
}

export default Searchbox
