import { ComponentProps } from 'react'

import { UnknownJSON } from '@emico/graphql-schema-types'
import { overrideOptional } from '@emico/overrides'
import { Image } from '@emico/ui'

import { PrismicImageType } from './responseTypes'

interface Props extends Omit<ComponentProps<typeof Image>, 'url' | 'alt'> {
    image: PrismicImageType | UnknownJSON
    alt?: string
}
const PrismicImage = ({
    image: _image,
    imaginaryConfig,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sizes,
    ...props
}: Props) => {
    const image = _image as PrismicImageType | undefined

    if (!image?.url) {
        return null
    }

    if (image.url.endsWith('.svg')) {
        return <img src={image.url} alt={image.alt ?? ''} {...props} />
    }

    return (
        <Image
            {...props}
            url={image.url}
            alt={image.alt ?? ''}
            originalHeight={image.dimensions?.height}
            originalWidth={image.dimensions?.width}
            imaginaryConfig={imaginaryConfig}
        />
    )
}

export default overrideOptional(PrismicImage)
