import { useMemo } from 'react'

import { Maybe } from '../graphql/schema.generated'

export const filterNullValues = <T>(items?: Maybe<Array<Maybe<T>>>) =>
    items?.reduce<T[]>((acc, item) => {
        if (item) {
            acc.push(item)
        }
        return acc
    }, [])

// The use of useMemo makes sure that returnvalue only changes when items change
// Because filterNullValues mutates the array and returns a new array on each
// call it will trigger useEffect hooks on each render.
export const useFilterNullValues = <T>(items?: Maybe<Array<Maybe<T>>>) =>
    useMemo(() => filterNullValues(items), [items])
