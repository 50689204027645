import { handleActions, ReducerMap } from 'redux-actions'

import actions, { getToken } from '../actions/user'

export const name = 'user'

export interface UserState {
    token?: string
}

const initialState: UserState = {
    token: getToken() ?? undefined,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducerMap: ReducerMap<UserState, any> = {
    [actions.signIn.receive.toString()]: (
        state,
        {
            payload,
            error,
        }: {
            payload: Partial<UserState>
            error?: boolean
        },
    ): UserState => {
        if (error) {
            return {
                ...initialState,
            }
        }

        return {
            ...state,
            ...payload,
        }
    },
    // TODO: handle the reset password response from the API.
    [actions.resetPassword.receive.toString()]: (state) => state,
    [actions.signIn.reset.toString()]: (): UserState => ({
        ...initialState,
    }),
}

export default handleActions(reducerMap, initialState)
