import styled from '@emotion/styled'
import * as React from 'react'

import theme from './theme'
import Text from './typography/Text'

const StyledText = styled(Text)`
    margin: 0 0 30px 0;
    padding: 1em;
    background: ${theme.colors.grayLighter};
`

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
    content: string
}

const SizeInformationBlueConic = ({ content, color, ...htmlProps }: Props) => (
    <StyledText {...htmlProps} as="p">
        {content}
    </StyledText>
)

export default SizeInformationBlueConic
