export default function parseQueryString(queryString: string): {
    [key: string]: string
} {
    if (queryString === '') {
        return {}
    }
    const values = queryString.substr(1).split('&')

    return values.reduce<{ [key: string]: string }>((obj, value: string) => {
        const pair = value.split('=', 2)

        obj[pair[0]] = pair[1]
        return obj
    }, {})
}
