import cx from 'classnames'
import * as React from 'react'

import styles from './Button.module.scss'
import Link, { Props as LinkProps } from '../../navigation/Link'
import Loader from '../../presentation/Loader'
import buttonClick from '../../utils/googleTagManager/buttonClick'
import push from '../../utils/googleTagManager/push'

export type ButtonVariant =
    | 'default'
    | 'primary'
    | 'primaryAlt'
    | 'secondary'
    | 'secondaryAlt'
    | 'white'
    | 'link'
    | 'dark'
    | 'linkInverted'
    | 'activeFilter'
    | 'highlight'

export type ButtonSize = 'small' | 'medium' | 'large' | 'hero'

export interface OwnProps {
    /**
     * The style of the button
     * @default 'default'
     */
    variant?: ButtonVariant
    size?: ButtonSize
    minWidth?: boolean
    /** Custom styling hook */
    className?: string

    /**
     * When true, make button full width
     * @default false
     */
    wide?: boolean
    disabled?: boolean
    loading?: boolean
    loadingText?: string

    // GTM props
    name: string
    category: string
}

export type ButtonProps = OwnProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>
type ButtonLinkProps = OwnProps & Omit<LinkProps, 'variant'>
export type EitherButtonOrLinkProps = ButtonProps | ButtonLinkProps

const Button = ({
    children,
    variant = 'default',
    size = 'large',
    className,
    wide = false,
    minWidth = false,
    disabled = false,
    loading = false,
    loadingText,
    name,
    category,
    ...otherProps
}: EitherButtonOrLinkProps) => {
    const classNames = cx(
        styles[`button-${variant}`],
        styles[`button-${size}`],
        { [styles.buttonWide]: wide },
        {
            [styles.minWidth]:
                minWidth &&
                variant !== 'link' &&
                variant !== 'linkInverted' &&
                !wide,
        },
        className,
    )

    if ('to' in otherProps) {
        return (
            <Link
                variant="none"
                {...otherProps}
                onClick={(e) => {
                    if (disabled) {
                        e.preventDefault()
                    }
                    if (otherProps.onClick) {
                        otherProps.onClick(e)
                    }
                }}
                className={cx(classNames, { [styles.disabled]: disabled })}
                name={name}
                category={category}
            >
                {children}
            </Link>
        )
    } else {
        const button = (
            <button
                type="button"
                {...otherProps}
                onClick={(e) => {
                    if (name !== '' && category !== '') {
                        push(buttonClick(name, category))
                    }
                    if (otherProps.onClick) {
                        otherProps.onClick(e)
                    }
                }}
                disabled={disabled}
                className={classNames}
            >
                {children}
            </button>
        )

        if (loading) {
            return (
                <div
                    className={cx(styles.loadingContainer, {
                        [styles.disabled]: disabled,
                        [styles.buttonWide]: wide,
                    })}
                >
                    <div className={styles.loading}>{button}</div>
                    <div
                        className={cx(
                            loadingText
                                ? styles.loaderPositionerWithText
                                : styles.loaderPositioner,
                        )}
                    >
                        {loadingText ? (
                            <>
                                <span className={styles.loadingText}>
                                    {loadingText}
                                </span>
                                <Loader className={styles.loader} />
                            </>
                        ) : (
                            <Loader className={styles.loader} />
                        )}
                    </div>
                </div>
            )
        }

        return button
    }
}

export default Button
