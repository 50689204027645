import { ComponentProps } from 'react'

import { useBreakpoints } from '@emico/ui'

import {
    CastironPageBodyImageOnly,
    JbfoPageBodyImageOnly,
    JustbrandsPageBodyImageOnly,
    MonstersalePageBodyImageOnly,
    PageBodyImageOnly,
    PmeOutletPageBodyImageOnly,
    StorelocatorPageBodyImageOnly,
    StorePageBodyImageOnly,
    VanguardPageBodyImageOnly,
} from './graphql/schema.generated'
import PrismicImageOnly from './PrismicImageOnly'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceImageOnlyType =
    | PageBodyImageOnly
    | VanguardPageBodyImageOnly
    | CastironPageBodyImageOnly
    | JustbrandsPageBodyImageOnly
    | JbfoPageBodyImageOnly
    | MonstersalePageBodyImageOnly
    | PmeOutletPageBodyImageOnly
    | StorePageBodyImageOnly
    | StorelocatorPageBodyImageOnly

interface Props {
    slice: PrismicSliceImageOnlyType
}

const PrismicSliceImageOnly = ({ slice: { primary } }: Props) => {
    const { isDesktop } = useBreakpoints()

    if (!primary) {
        return null
    }

    const { image, mobileImage, style, tablet, desktop } = primary

    if (isDesktop && !image?.url) {
        return null
    }
    if (!isDesktop && !mobileImage?.url) {
        return null
    }

    const imageUrl = image?.url ?? mobileImage?.url
    const imageAlt = isDesktop ? image?.alt : mobileImage?.alt

    if (!imageUrl) {
        return null
    }

    const componentProps: ComponentProps<typeof PrismicImageOnly> = {
        style: getLayoutStyle(style),
        image: imageUrl,
        imageMobile: mobileImage?.url ?? undefined,
        tablet: tablet ?? undefined,
        desktop: desktop ?? undefined,
        imageAlt: imageAlt ?? undefined,
    }

    return <PrismicImageOnly {...componentProps} />
}

export default PrismicSliceImageOnly
