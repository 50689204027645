import * as React from 'react'

import Component, { Props } from './Component'
import { getPaymentMethods } from '../../reducers'
import { useSelector } from '../../types'

const FooterPaymentMethodsContainer = (
    props: Omit<Props, 'paymentMethods'> & { size?: 'large' },
) => {
    const paymentMethods = useSelector(getPaymentMethods)

    if (!paymentMethods) {
        return null
    }

    return <Component paymentMethods={paymentMethods} {...props} />
}

export default FooterPaymentMethodsContainer
