import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { Maybe } from '../graphql/schema.generated'

export interface CategoryTree {
    id: number
    name: string
    level: Maybe<number>
    urlPath: Maybe<string>
    position: Maybe<number>
    includeInMenu: Maybe<number>
    highlighted: Maybe<number>
    children: CategoryTree[]
}

export type BaseCategoryTree = CategoryTree & {
    promotionBackgroundImage: Maybe<string>
    promotionButtonText: Maybe<string>
    promotionText: Maybe<string>
    promotionLink: Maybe<string>
}

const query = gql`
    query CategoryTree($categoryId: Int = 2) {
        category(id: $categoryId) {
            promotionBackgroundImage
            promotionButtonText
            promotionText
            promotionLink
            id
            name
            level
            urlPath
            position
            includeInMenu
            highlighted
            children {
                id
                name
                level
                urlPath
                position
                includeInMenu
                highlighted
                children {
                    id
                    name
                    level
                    urlPath
                    position
                    includeInMenu
                    highlighted
                    children {
                        id
                        name
                        level
                        urlPath
                        position
                        includeInMenu
                        highlighted
                    }
                }
            }
        }
    }
`

interface CategoryTreeVariables {
    categoryId?: number
}

export interface CategoryTreeData {
    category: BaseCategoryTree
}

export const useCategoryTree = (
    categoryId: number,
    options: Omit<
        QueryHookOptions<CategoryTreeData, CategoryTreeVariables>,
        'variables'
    > = {},
) =>
    useQuery<CategoryTreeData, CategoryTreeVariables>(query, {
        ...options,
        variables: {
            categoryId,
        },
        context: getCacheableContext(),
    })
