import { useReactiveVar } from '@apollo/client'
import cx from 'classnames'
import * as React from 'react'
import Cookies from 'universal-cookie'

import { currencyCode } from '@emico/currency-code'
import { Price as EmicoPrice } from '@emico/price'
import { useActiveStoreView } from '@emico/storeviews'

import styles from './Price.module.scss'
import { CurrencyEnum } from '../../graphql/schema.generated'

export interface Props {
    old?: boolean
    special?: boolean
    advice?: boolean
    price: number
    currency?: CurrencyEnum
    decimalDash?: boolean
    variantBig?: boolean
    className?: string
}

const overrideCurrencyLocales: Record<string, string> = {
    CHF: 'de-CH',
    CZK: 'cs-CZ',
}

const Price = ({
    old,
    special,
    price,
    currency,
    decimalDash = true,
    variantBig,
    className,
    advice = false,
}: Props) => {
    const activeStoreView = useActiveStoreView()
    const defaultCurrency = useReactiveVar(currencyCode)
    const currencyForRenderer = currency || defaultCurrency

    const classes = cx(
        {
            [styles.regular]: !old && !special,
            [styles.old]: old,
            [styles.special]: special,
            [styles.advice]: advice,
            [styles.variantBig]: variantBig,
        },
        className,
    )

    const cookies = new Cookies()
    const visitorCountry = cookies.get('geo_country')

    if (
        visitorCountry &&
        visitorCountry === process.env.REACT_APP_HIDE_PRICE_FOR &&
        activeStoreView.code !== process.env.REACT_APP_FORCE_SHOW_PRICE_FOR
    ) {
        return <></>
    }

    const currencyRenderLanguage =
        currencyForRenderer && currencyForRenderer in overrideCurrencyLocales
            ? overrideCurrencyLocales[currencyForRenderer]
            : activeStoreView.language

    return (
        <EmicoPrice
            value={price}
            language={currencyRenderLanguage}
            currency={currency}
            currencyDisplay="symbol"
            decimalDash={decimalDash}
            className={classes}
            useGrouping
        />
    )
}

export default Price
