const timeObjectFromString = (string: string) => {
    const hours = parseInt(string.substring(0, 2), 0)
    const minutes = parseInt(string.substring(3, 5), 0)
    const date = new Date()

    date.setHours(hours, minutes, 0)
    return date
}

export default timeObjectFromString
