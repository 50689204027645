import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'
import { snakeCase } from 'snake-case'

import styles from './ProductGrid.module.scss'
import Grid, { Props as GridProps } from '../../layout/Grid'
import GridItem from '../../layout/Grid/GridItem'
import Link from '../../navigation/Link'
import ProductCard from '../../presentation/ProductCard/ProductCard'
import { ProductCardFragment } from '../../ProductCardFragment'
import ProductCardWithAddToCart from '../../ProductCardWithAddToCart'
import theme from '../../theme'
import { PrismicPage } from '../../usePrismicPage'
import { CustomTweakwiseNavigationItem } from '../../useTweakwiseNavigation'
import useSelectItem from '../../utils/ga4/useSelectItem'

interface OwnProps {
    className?: string
    children: CustomTweakwiseNavigationItem[]
    listName: string
    content?: React.ReactNode[]
    withSizeSelect?: boolean
    withColorSwatch?: boolean
}

type Props = OwnProps & GridProps

const SearchSuggestionLink = styled(Link)`
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: ${theme.colors.text};
    }
`

const ProductGrid = ({
    className,
    children: tweakwiseItems,
    listName,
    content = [],
    withSizeSelect,
    withColorSwatch,
    ...gridProps
}: Props) => {
    const Card = withSizeSelect ? ProductCardWithAddToCart : ProductCard
    const selectItem = useSelectItem()

    const handleProductClick = (product: ProductCardFragment) => {
        selectItem(product, {
            item_list_id: snakeCase(listName),
            item_list_name: listName,
            index: tweakwiseItems
                ? tweakwiseItems.findIndex(
                      (value) => value.product?.id === product.id,
                  ) + 1
                : 0,
        })
    }

    const productElems = tweakwiseItems.map(
        ({ product, initialValue }, index) =>
            product && (
                <GridItem
                    key={`${index}${product.id}`}
                    productIndex={index + 1}
                >
                    <Card
                        product={product}
                        onClick={handleProductClick}
                        withStockStatus
                        withColorSwatch={withColorSwatch}
                        initialValue={initialValue}
                        position="pop"
                        itemListName={listName}
                        itemListId={listName}
                        itemListSlot="N/A"
                        itemIndex={index}
                    />
                </GridItem>
            ),
    )

    const pages = tweakwiseItems
        .filter((item) => item.prismicDocument?.data)
        .map((item, index) => {
            const data = item.prismicDocument?.data as PrismicPage

            if (!data.seoTitle && !data.urlKey) {
                return <></>
            }

            return (
                <GridItem
                    key={`${index}${data.seoTitle}`}
                    productIndex={index + 1}
                >
                    <SearchSuggestionLink
                        to={`/${data.urlKey}`}
                        category="searchresult"
                        name="suggestion"
                    >
                        {data.seoTitle}
                    </SearchSuggestionLink>
                </GridItem>
            )
        })

    const itemsForGrid = [...productElems, ...content]

    // Order of props matter here,
    // because gridProps contains defaultProps inherited from Grid
    return (
        <Grid {...gridProps} xs={2} className={cx(styles.grid, className)}>
            {pages}
            {itemsForGrid}
        </Grid>
    )
}

export default ProductGrid
