import styled from '@emotion/styled'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import Button from '../../../../../input/Button'
import theme from '../../../../../theme'

const MobileFilterButton = styled(Button)`
    margin-bottom: 20px;
    background: ${theme.colors.grayLighter};
    border: 1px solid ${theme.colors.border} !important;
    padding: 9px 10px 5px;

    &:active,
    &:hover {
        background: ${theme.colors.background};
    }
`

export default MobileFilterButton
