import styled from '@emotion/styled'
// Use components from react-google-maps instead of emico/ui so clustering works
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api'
import { useEffect, useState } from 'react'

import { useActiveStoreView } from '@emico/storeviews'
import { GoogleMapLoadScript } from '@emico/ui'

import CurrentUserMarker from './CurrentUserMarker'
import PanMap from './PanMap'
import StoreMarker from './StoreMarker'
import { SrsStore } from './useSrsStore'
import Container from '../layout/Container'
import castiron_cluster from '../static/icons/castiron_cluster.svg'
import justbrands_cluster from '../static/icons/justbrands_cluster.svg'
import pme_cluster from '../static/icons/pme_cluster.svg'
import vanguard_cluster from '../static/icons/vanguard_cluster.svg'

export interface Location {
    lat: number
    lng: number
}

interface Props {
    stores: SrsStore[]
    amountResults: number
    location: Location | undefined
    selectedStoreLocation: Location | undefined
    selectedStore: SrsStore | undefined
    onSelect(value: SrsStore): void
    storeView: string
    showSingleMarker?: boolean
    zoomLevel?: {
        location?: number
        selectedStoreLocation?: number
        noResults?: number
        default?: number
    }
}

const GoogleMapsContainer = styled.div`
    display: block;
    width: 100%;
    height: 100%;
`

const StyledContainer = styled(Container)`
    height: 100%;
`

const extraSmallCluster = 30
const smallCluster = 40
const mediumCluster = 50
const largeCluster = 55
const extraLargeCluster = 65

// See https://developers.google.com/maps/documentation/javascript/marker-clustering
const textColor = '#fff'

function getCluserOptions(storeView: string) {
    let clusterUrl: string
    const clusterStyles = [
        extraSmallCluster,
        smallCluster,
        mediumCluster,
        largeCluster,
        extraLargeCluster,
    ]

    switch (storeView) {
        case 'castiron':
            clusterUrl = castiron_cluster
            break
        case 'pme':
            clusterUrl = pme_cluster
            break
        case 'justbrands':
            clusterUrl = justbrands_cluster
            break
        case 'vanguard':
            clusterUrl = vanguard_cluster
            break
        default:
            clusterUrl = pme_cluster
            break
    }

    const clusterStylesObject = clusterStyles.map((clusterStyle) => ({
        url: clusterUrl,
        height: clusterStyle,
        width: clusterStyle,
        textColor,
    }))

    return {
        minimumClusterSize: 4,
        imagePath: '/static/icons/',
        styles: clusterStylesObject,
    }
}

const getInitialLocation = (storeCode: string): Location => {
    if (storeCode.toLowerCase().includes('_be')) {
        return {
            lat: 50.71530051321676,
            lng: 4.605237420712023,
        }
    } else if (storeCode.toLowerCase().includes('_de')) {
        return {
            lat: 50.7473199139518,
            lng: 10.058232394713842,
        }
    }

    return {
        lat: 52.3925399,
        lng: 5.7313817,
    }
}

const StoreMap = ({
    stores,
    amountResults,
    location,
    selectedStoreLocation,
    selectedStore,
    onSelect,
    storeView,
    showSingleMarker = false,
    zoomLevel: userProvidedZoomLevel,
}: Props) => {
    // Set sensible zoom defaults that can be overridden
    const zoomLevel = {
        ...{
            location: 12,
            selectedStoreLocation: 10,
            noResults: 8,
            default: 9,
        },
        ...userProvidedZoomLevel,
    }
    const { code: storeCode } = useActiveStoreView()
    const [centerLocation, setCenterLocation] = useState<Location>(
        getInitialLocation(storeCode),
    )

    useEffect(() => {
        if (selectedStoreLocation) {
            setCenterLocation(selectedStoreLocation)
        }
    }, [selectedStoreLocation])

    useEffect(() => {
        if (location) {
            setCenterLocation(location)
        }
    }, [location])

    function getZoomLevel() {
        if (selectedStore || location) {
            return zoomLevel.location
        } else if (selectedStoreLocation) {
            return zoomLevel.selectedStoreLocation
        } else if (amountResults === 0) {
            return zoomLevel.noResults
        }
        return zoomLevel.default
    }

    const filteredStores = showSingleMarker
        ? [...stores].filter((store) => store.shopId === selectedStore?.shopId)
        : stores

    const [brandedStores, nonBrandedStores] = filteredStores.reduce<
        [SrsStore[], SrsStore[]]
    >(
        (acc, curr) => {
            if (!curr.gLat || !curr.gLong) {
                return acc
            }

            if (curr.jbStore || curr.pmeStore || curr.ciStore) {
                acc[0].push(curr)
            } else {
                acc[1].push(curr)
            }
            return acc
        },
        [[], []],
    )

    return (
        <StyledContainer noPadding>
            <GoogleMapsContainer>
                {!window.isPercolate && (
                    <GoogleMapLoadScript
                        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                        loadingElement={null}
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                height: '100%',
                            }}
                            zoom={getZoomLevel()}
                            center={centerLocation && centerLocation}
                            options={{
                                mapTypeControl: false,
                                clickableIcons: true,
                                fullscreenControl: false,
                                styles: [
                                    {
                                        featureType: 'road',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#fff',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.arterial',
                                        elementType: 'labels.text.fill',
                                        stylers: [
                                            {
                                                color: '#757575',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.highway',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#dadada',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.highway',
                                        elementType: 'labels.text.fill',
                                        stylers: [
                                            {
                                                color: '#616161',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.local',
                                        elementType: 'labels.text.fill',
                                        stylers: [
                                            {
                                                color: '#9e9e9e',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'transit.line',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#e5e5e5',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'transit.station',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#eeeeee',
                                            },
                                        ],
                                    },
                                    {
                                        elementType: 'labels.icon',
                                        stylers: [
                                            {
                                                visibility: 'off',
                                            },
                                        ],
                                    },
                                    {
                                        stylers: [
                                            {
                                                saturation: -100,
                                            },
                                        ],
                                    },
                                ],
                            }}
                        >
                            <PanMap selectedStore={selectedStore}>
                                {brandedStores.map((store) => (
                                    <StoreMarker
                                        storeView={storeView}
                                        key={store.storeId}
                                        store={store}
                                        selectedStore={selectedStore}
                                        onSelect={onSelect}
                                        position={{
                                            lat: store.gLat ?? 0,
                                            lng: store.gLong ?? 0,
                                        }}
                                    />
                                ))}

                                {location && (
                                    <CurrentUserMarker position={location} />
                                )}

                                <MarkerClusterer
                                    options={getCluserOptions(storeView)}
                                >
                                    {(clusterer) =>
                                        nonBrandedStores.map((store) => (
                                            <StoreMarker
                                                storeView={storeView}
                                                key={store.storeId}
                                                store={store}
                                                selectedStore={selectedStore}
                                                onSelect={onSelect}
                                                position={{
                                                    lat: store.gLat ?? 0,
                                                    lng: store.gLong ?? 0,
                                                }}
                                                clusterer={clusterer}
                                            />
                                        ))
                                    }
                                </MarkerClusterer>
                            </PanMap>
                        </GoogleMap>
                    </GoogleMapLoadScript>
                )}
            </GoogleMapsContainer>
        </StyledContainer>
    )
}

export default StoreMap
