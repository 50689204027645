import { AddressFormValues } from './types'
import { isEmpty } from '../validateRequired'

export const isSameAddress = (
    source?: AddressFormValues,
    target?: AddressFormValues,
) => {
    if (!target || !source) {
        return false
    }

    const sourcePostalCode =
        source?.postalCode?.toLowerCase().replace(/\s+/g, '') ?? ''
    const targetPostalCode =
        target?.postalCode?.toLowerCase().replace(/\s+/g, '') ?? ''
    if (sourcePostalCode !== targetPostalCode) {
        return false
    }

    const srcStreetArray = [source?.street, source?.houseNumber, source?.suffix]
    const sourceStreet =
        srcStreetArray?.join('_').toLowerCase().replace(/\s+/g, '') ?? ''

    const targetStreetArray = [
        target?.street,
        target?.houseNumber,
        target?.suffix,
    ]
    const targetStreet =
        targetStreetArray?.join('_').toLowerCase().replace(/\s+/g, '') ?? ''

    if (sourceStreet !== targetStreet) {
        return false
    }

    return true
}

export const isAddressComplete = (address?: AddressFormValues): boolean => {
    if (!address) {
        return false
    }
    // suffix is optional
    const isComplete =
        !isEmpty(address.firstName) &&
        !isEmpty(address.lastName) &&
        !isEmpty(address.country) &&
        !isEmpty(address.street) &&
        !isEmpty(address.houseNumber) &&
        !isEmpty(address.postalCode) &&
        !isEmpty(address.city)

    return isComplete
}
