/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

export interface ComponentConfig {
    // Core
    AccountHero?: React.ComponentType<any>
    AccountLoginPageLayout?: React.ComponentType<any>
    AppRouter?: React.ComponentType<any>
    Banner?: React.ComponentType<any>
    FooterContactInfo?: React.ComponentType<any>
    FooterNavigation?: React.ComponentType<any>
    HeroVideoCard?: React.ComponentType<any>
    Logo?: React.ComponentType<any>
    LoginForm?: React.ComponentType<any>
    NotFoundBackgroundImages?: React.ComponentType<any>
    StandardHeader?: React.ComponentType<any>
    SuccessPageBackgroundImages?: React.ComponentType<any>
    SuccessPageButtons?: React.ComponentType<any>
    TextContentCard?: React.ComponentType<any>
    FooterTop?: React.ComponentType<any>
    Badge?: React.ComponentType<any>

    // Icons

    InfoIcon?: React.ComponentType<any>
    AccountIcon?: React.ComponentType<any>
    AddressBookIcon?: React.ComponentType<any>
    BackIcon?: React.ComponentType<any>
    BackToTopIcon?: React.ComponentType<any>
    BinIcon?: React.ComponentType<any>
    BookmarkFilledIcon?: React.ComponentType<any>
    BookmarkIcon?: React.ComponentType<any>
    BreadcrumbIcon?: React.ComponentType<any>
    CartEmptyIcon?: React.ComponentType<any>
    CartIcon?: React.ComponentType<any>
    CheckIcon?: React.ComponentType<any>
    CloseIcon?: React.ComponentType<any>
    DashboardIcon?: React.ComponentType<any>
    DropdownIcon?: React.ComponentType<any>
    GeoLocationIcon?: React.ComponentType<any>
    LoyaltyIcon?: React.ComponentType<any>
    NextIcon?: React.ComponentType<any>
    OrdersIcon?: React.ComponentType<any>
    PersonalShopperIcon?: React.ComponentType<any>
    PickupPointIcon?: React.ComponentType<any>
    PlusIcon?: React.ComponentType<any>
    PostalAddressIcon?: React.ComponentType<any>
    RewardsIcon?: React.ComponentType<any>
    SearchIcon?: React.ComponentType<any>
    SignOutIcon?: React.ComponentType<any>
    StoresIcon?: React.ComponentType<any>
    UserDetailsIcon?: React.ComponentType<any>
    UspCheckIcon?: React.ComponentType<any>
    OnlineChatIcon?: React.ComponentType<any>
    PersonalChatIcon?: React.ComponentType<any>
    PiggyIcon?: React.ComponentType<any>
    DiscountIcon?: React.ComponentType<any>
    GiftIcon?: React.ComponentType<any>
    JeansIcon?: React.ComponentType<any>
    VoucherEuroIcon?: React.ComponentType<any>
    VoucherPercentageIcon?: React.ComponentType<any>

    // Catalog
    ProductInfo?: React.ComponentType<any>
    ProductInfoHeader?: React.ComponentType<any>

    // Cart
    CartItemContainer?: React.ComponentType<any>
    CartItemImage?: React.ComponentType<any>
    CartItemInfoContainer?: React.ComponentType<any>
    CartPage?: React.ComponentType<any>
    CartPageOrderSummary?: React.ComponentType<any>
    CartTotals?: React.ComponentType<any>
    CartTotalsBody?: React.ComponentType<any>
    CartTotalsFooter?: React.ComponentType<any>
    CartTotalsFooterPrice?: React.ComponentType<any>
    CouponForm?: React.ComponentType<any>
    CouponFormNote?: React.ComponentType<any>
    UndoIcon?: React.ComponentType<any>
    PriceBox?: React.ComponentType<any>

    // Checkout
    CheckoutCart?: React.ComponentType<any>
    CheckoutCartItem?: React.ComponentType<any>
    CheckoutContinueWithoutAccountForm?: React.ComponentType<any>
    CheckoutCoupon?: React.ComponentType<any>
    CheckoutForgotPasswordForm?: React.ComponentType<any>
    CheckoutForgotPasswordPage?: React.ComponentType<any>
    CheckoutLoginForm?: React.ComponentType<any>
    CheckoutLoginPage?: React.ComponentType<any>
    CheckoutSidebar?: React.ComponentType<any>
    PageLayout?: React.ComponentType<any>
    StickyElement?: React.ComponentType<any>
    SuccesPageDeliveryMessage?: React.ComponentType<any>
    TileSlider?: React.ComponentType<any>
    WizardProgressBar?: React.ComponentType<any>

    // Prismic content
    PrismicCategoryTextOnly?: React.ComponentType<any>

    // Button variants
    CtaSelectSizeButton?: React.ComponentType<any>
    NewsletterSubmit?: React.ComponentType<any>

    // Priviliges program:
    PriviligesLogoIcon?: React.ComponentType<any>
    ProgressIndicator?: React.ComponentType<any>

    // Theming
    ThemeOverrides?: React.ComponentType<any>
}

const ComponentOverridesContext = React.createContext<ComponentConfig>({})

const Consumer = ComponentOverridesContext.Consumer

/**
 * The provider also acts as a consumer so that components implementing this
 * provider can be wrapped in another provider to create multiple levels of
 * overriding.
 * The default value is an empty object, so this will work fine if there's no
 * parent Provider.
 */
const Provider = ({
    // eslint-disable-next-line react/prop-types
    value: myComponentsConfig,
    children,
}: React.ComponentProps<typeof ComponentOverridesContext.Provider>) => (
    <Consumer>
        {(parentComponentsConfig: ComponentConfig) => (
            <ComponentOverridesContext.Provider
                value={{
                    ...myComponentsConfig,
                    ...parentComponentsConfig,
                }}
            >
                {children}
            </ComponentOverridesContext.Provider>
        )}
    </Consumer>
)

export default {
    Consumer,
    Provider,
}
