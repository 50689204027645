import { makeVar } from '@apollo/client'
import { useEffect } from 'react'

export const isFloatingContactUsButtonVisible = makeVar(true)

export function useHideFloatingContactUsButton() {
    return useEffect(() => {
        isFloatingContactUsButtonVisible(false)
        return () => {
            isFloatingContactUsButtonVisible(true)
        }
    }, [])
}
