import { useReactiveVar } from '@apollo/client'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'
import * as React from 'react'

import MobileFilterButton from './MobileFilterButton'
import { FilterModal } from '../../../../../chunks'
import {
    TweakwiseNavigationFacet,
    TweakwiseNavigationPropertiesSortField,
} from '../../../../../graphql/schema.generated'
import Container from '../../../../../layout/Container'
import { headerSizeVar } from '../../../../../layout/Header/HeaderContainer'
import Portal from '../../../../../Portal'
import Text from '../../../../../typography/Text'
import { FilterValue, FilterValues } from '../../ProductFilterPage'

interface Props extends React.RefAttributes<HTMLDivElement> {
    sortFields: TweakwiseNavigationPropertiesSortField[]
    options: TweakwiseNavigationFacet[]
    values: FilterValues
    setValue(key: string, value: FilterValue): void
    clear(): void
    sortKey: string | undefined
    setSortKey(sortKey: string | undefined): void
    numResults: number
    loading: boolean
}

const StickyContainer = styled(Container)`
    position: sticky;
    top: 0;
    z-index: 2;
`

const StickyContainerWrapper = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const headerSize = useReactiveVar(headerSizeVar)

    return (
        <StickyContainer
            style={{
                top: headerSize?.height ?? 0,
            }}
        >
            {children}
        </StickyContainer>
    )
}

const FilterBarMobile = ({
    options,
    values,
    setValue,
    sortKey,
    setSortKey,
    clear,
    numResults,
    loading,
    sortFields,
}: Props) => {
    const [isOpen, setOpen] = React.useState<boolean>(false)

    // Filter out the search key as it should not count as a filter
    const activeFilterCount = Object.entries(values).reduce(
        (acc, entry) => {
            const [filter, filterValues] = entry

            if (filter !== 'q' && typeof acc === 'number') {
                acc += filterValues.length
            }
            return acc
        },
        sortKey !== undefined ? 1 : 0,
    )

    const handleClose = React.useCallback(() => setOpen(false), [setOpen])

    const isDirty = activeFilterCount > 0
    const activeFilterCountLabel =
        activeFilterCount > 0 ? `(${activeFilterCount})` : ''

    if (!options.length) {
        return null
    }

    return (
        <>
            <StickyContainerWrapper>
                <MobileFilterButton
                    wide
                    onClick={() => setOpen(true)}
                    name="Filters"
                    category="catalog.productFilterPage.filterBarMobile.button"
                >
                    <Trans id="catalog.productFilterPage.filterBarMobile.buttonLabel">
                        Filter & sort {activeFilterCountLabel}
                    </Trans>
                </MobileFilterButton>

                <Portal>
                    {isOpen && (
                        <FilterModal
                            isDirty={isDirty}
                            close={handleClose}
                            clear={clear}
                            options={options}
                            sortFields={sortFields}
                            values={values}
                            numResults={numResults}
                            setValue={setValue}
                            sortKey={sortKey}
                            setSortKey={setSortKey}
                            loading={loading}
                        />
                    )}
                </Portal>
            </StickyContainerWrapper>

            <Container
                css={css`
                    text-align: center;
                    margin-bottom: 15px;
                `}
            >
                <Text
                    color="grey"
                    css={css`
                        margin: 0;
                    `}
                >
                    <Plural
                        id="catalog.productFilterPage.filterBar.resultCount"
                        value={numResults}
                        one="# result"
                        other="# results"
                    />
                </Text>
            </Container>
        </>
    )
}

export default FilterBarMobile
