import { TweakwiseFunnelQuestion } from '@emico-hooks/graphql-schema-types'
import { Image } from '@emico-react/image'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ColorOptions } from './options/ColorOptions'
import { DefaultOptions } from './options/DefaultOptions'
import { SizesCombinedOptions } from './options/SizesCombinedOptions'
import styles from './Question.module.scss'
import { QuestionDefinition } from './TweakwiseConfigurator'
import { useHideFloatingContactUsButton } from '../core/FloatingContactUsButton/useHideFloatingContactUsButton'

export type TweakwiseQuestion = TweakwiseFunnelQuestion & QuestionDefinition
interface Props {
    question?: TweakwiseQuestion
    onSelectionChange: (questionid: string, answerIds: string[]) => void
}

export function Question({ question, onSelectionChange }: Props) {
    const { image } = question || {}
    const prevQuestionId = useRef<string>()
    useHideFloatingContactUsButton()

    const form = useForm<{ question: string | string[] }>()

    const { watch, setValue, reset } = form

    const values = watch('question')

    useEffect(() => {
        onSelectionChange(
            question?.id ?? '',
            Array.isArray(values) ? values : [values],
        )
    }, [onSelectionChange, question?.id, values])

    useEffect(() => {
        if (!question || prevQuestionId.current === question?.id) {
            return
        }
        prevQuestionId.current = question?.id
        reset({
            question: question.isMultiSelect
                ? question.answers
                      ?.filter(stripMaybes)
                      ?.filter((answer) => answer.isSelected)
                      ?.map((a) => a.answerId)
                : question.answers
                      ?.filter(stripMaybes)
                      ?.filter((answer) => answer.isSelected)
                      ?.map((a) => a.answerId)?.[0],
        })
    }, [question, reset])

    let optionsComponent = <></>
    switch (question?.questionType) {
        case 'jeans_size_combined':
            optionsComponent = (
                <SizesCombinedOptions
                    key={question?.id}
                    answers={question?.answers?.filter(stripMaybes)}
                    isMultiSelect={false}
                    onSelectionChange={(answers: string | string[]) =>
                        setValue('question', answers)
                    }
                />
            )
            break
        case 'color':
            optionsComponent = (
                <ColorOptions
                    key={question?.id}
                    isMultiSelect={question.isMultiSelect ?? false}
                    answers={question?.answers?.filter(stripMaybes)}
                />
            )
            break
        default:
            optionsComponent = (
                <DefaultOptions
                    key={question?.id}
                    answers={question?.answers?.filter(stripMaybes)}
                    isMultiSelect={question?.isMultiSelect ?? false}
                />
            )
            break
    }

    return (
        <FormProvider {...form}>
            <div className={styles.container}>
                {image && (
                    <div>
                        <Image
                            url={image}
                            className={styles.imageWrapper}
                            width={1800}
                            alt=""
                            lazy={false}
                        />
                    </div>
                )}

                {optionsComponent}
            </div>
        </FormProvider>
    )
}
