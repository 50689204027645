import { useCartId } from '@emico-hooks/cart-id'
import { useRemoveItemFromCart } from '@emico-hooks/cart-remove-item'
import {
    ConfigurableOptionInput,
    useUpdateCartItems,
} from '@emico-hooks/cart-update-cart'
import { useCallback, useState } from 'react'

import { useCartItems } from '../cart/CartPage/useCartItems'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import useAddToCartFunction from '../checkout/useAddToCartFunction'
import { useRemoveCartItemFunction } from '../checkout/useRemoveItemFromCart'

type UpdateCartArgs = {
    product: Product | ConfigurableProduct
    options: ConfigurableAttributesFieldValue | undefined
}

type UpdateCart = (value: UpdateCartArgs[]) => Promise<void>

type CartAction = () => Promise<any>

export const useUpdateCart = (): {
    loading: boolean
    updateCart: UpdateCart
} => {
    const addToCart = useAddToCartFunction()
    const updateCartItems = useUpdateCartItems()
    const removeCartItem = useRemoveCartItemFunction()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const cartId = useCartId()
    const { items: cartItems } = useCartItems()

    const updateCart = useCallback(
        async (value: UpdateCartArgs[]) => {
            let cartActions: Array<{
                isDelete: boolean
                action: CartAction
            }> = []

            for (const { product, options } of value) {
                const isSingleSizeProduct =
                    product?.__typename === 'SimpleProduct' ||
                    (product as ConfigurableProduct).variants?.length === 1

                const prodId = product.id
                const hasOptions = !options
                    ? false
                    : Object.keys(options).length > 0
                const isCartItem = cartItems?.find(
                    (item) => item.product?.id === prodId,
                )

                if (isCartItem && !hasOptions) {
                    // delete existing item
                    cartActions = [
                        ...cartActions,
                        {
                            action: async () =>
                                await removeCartItem(cartId ?? '', isCartItem),
                            isDelete: true,
                        },
                    ]
                } else if (isCartItem && hasOptions && !isSingleSizeProduct) {
                    // update item
                    const selectedOptions: ConfigurableOptionInput[] = []
                    if ('variants' in product) {
                        isCartItem.configurableOptions?.forEach((opt) => {
                            const label = opt.optionLabel
                            const confOption = product.configurableOptions.find(
                                (opt) => opt.label === label,
                            )
                            if (confOption) {
                                selectedOptions.push({
                                    configurable_product_option_uid:
                                        opt.configurableProductOptionUid,
                                    configurable_product_option_value_uid:
                                        String(
                                            options?.[
                                                Number(confOption.attributeId)
                                            ],
                                        ),
                                })
                            }
                        })
                    }
                    cartActions = [
                        ...cartActions,
                        {
                            isDelete: false,
                            action: async () =>
                                await updateCartItems([
                                    {
                                        cart_item_uid: isCartItem.uid,
                                        configurable_options: selectedOptions,
                                        quantity: 1,
                                    },
                                ]),
                        },
                    ]
                } else if (!isCartItem && hasOptions) {
                    // add item:
                    cartActions = [
                        ...cartActions,
                        {
                            isDelete: false,
                            action: async () =>
                                await addToCart(product, 1, options, false),
                        },
                    ]
                }
            }
            cartActions = cartActions.sort((a, _) => (a.isDelete ? -1 : 0))

            const runActions = async (actions: CartAction[]) => {
                setIsLoading(true)
                for (const action of actions) {
                    await action()
                }
                setIsLoading(false)
            }
            if (cartActions.length) {
                return await runActions(
                    cartActions.map((action) => action.action),
                )
            }
            return Promise.resolve()
        },
        [cartItems, removeCartItem, cartId, updateCartItems, addToCart],
    )

    return {
        updateCart,
        loading: isLoading,
    }
}
