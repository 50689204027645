import * as React from 'react'

import { useGetBasicProduct } from '../../../catalog/ProductPage/GetBasicProduct.query'
import { ProductCard } from '../../../presentation/ProductCard/ProductCard'

interface Props {
    productId: number
    className?: string
}

const TeamProduct = ({ productId, className }: Props) => {
    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { data: product } = useGetBasicProduct(productId)

        if (!product) {
            return null
        }
        return (
            <ProductCard
                product={product}
                className={className}
                itemListSlot="teamProduct"
                itemIndex={0}
                itemListId="team_product"
                itemListName="Team Product"
            />
        )
    } catch (e) {
        // Most likely an incorrect product id has been entered.
        // Do not show the component in that case
        return null
    }
}

export default TeamProduct
