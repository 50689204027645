import * as React from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { StoreViewWithState, useActiveStoreView } from '@emico/storeviews'

interface Props {
    storeView: StoreViewWithState
}

const HomepageCanonical = ({ storeView }: Props) => {
    const activeStoreConfig = useActiveStoreView()
    const location = useLocation()

    // origin doesn't live in react-router location
    const { origin } = window.location

    // Remove trailing slash from url
    // See https://support.emico.nl/issues/124173
    const regex = /\/$/
    const href = `${origin}${storeView.basename}${location.pathname}`
        .replace(regex, '')
        .replace('http://', 'https://')

    return (
        <Helmet>
            <link rel="alternate" href={href} hrefLang={storeView.hrefLang} />
            {storeView.hrefLang === 'x-default' && (
                <link
                    rel="alternate"
                    href={href}
                    hrefLang={storeView.locale || storeView.language}
                />
            )}
            {activeStoreConfig === storeView && (
                <link rel="canonical" href={href} />
            )}
        </Helmet>
    )
}

export default HomepageCanonical
