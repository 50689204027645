// Source: https://stackoverflow.com/a/8831937/684353
// The goal is to have a unique hash for each emailaddress without consuming
// significant resources, either as bytes in the bundle or as computing power
// on each dataLayer push.
// Obviously any hash is reversible considering all emails are in our database
// and we could just run the hash on those emails, so what is the point really?
export default function hashEmail(email: string): number {
    let hash = 0

    if (email.length === 0) {
        return hash
    }
    for (let i = 0; i < email.length; i += 1) {
        const char = email.charCodeAt(i)

        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return hash
}
