import { ComponentProps } from 'react'

import CategoryElement, { CategoryElementItem } from './CategoryElement'
import {
    CastironPageBodyCategoryElement,
    JbfoPageBodyCategoryElement,
    JustbrandsPageBodyCategoryElement,
    MonstersalePageBodyCategoryElement,
    PageBodyCategoryElement,
    PageBodyCategoryElementFields,
    PmeOutletPageBodyCategoryElement,
    StorelocatorPageBodyCategoryElement,
    VanguardPageBodyCategoryElement,
} from './graphql/schema.generated'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceCategoryElementType =
    | PageBodyCategoryElement
    | CastironPageBodyCategoryElement
    | JustbrandsPageBodyCategoryElement
    | VanguardPageBodyCategoryElement
    | JbfoPageBodyCategoryElement
    | MonstersalePageBodyCategoryElement
    | PmeOutletPageBodyCategoryElement
    | StorelocatorPageBodyCategoryElement

interface Props {
    slice: PrismicSliceCategoryElementType
}

const PrismicSliceCategoryElement = ({ slice: { primary, fields } }: Props) => {
    if (!primary) {
        return null
    }

    const { heading, style } = primary

    const items: CategoryElementItem[] = (
        (fields as PageBodyCategoryElementFields[]) ?? []
    ).reduce<CategoryElementItem[]>((acc, field) => {
        // Dont show items with no main image or no url configured
        if (field?.title && field?.url) {
            const { title, url } = field

            acc.push({
                title: title ?? undefined,
                url: url ?? undefined,
            })
        }
        return acc
    }, [])

    const componentProps: ComponentProps<typeof CategoryElement> = {
        style: getLayoutStyle(style),
        heading: heading ?? '',
        items,
    }

    return <CategoryElement {...componentProps} />
}

export default PrismicSliceCategoryElement
