import cx from 'classnames'
import { LiHTMLAttributes } from 'react'

import DiscountIcon from './core/DiscountIcon'
import GiftIcon from './core/GiftIcon'
import JeansIcon from './core/JeansIcon'
import UspIcon from './core/UspCheckIcon'
import styles from './List.module.scss'
import Icon from './media/Icon'
import Text from './typography/Text'

interface ListItem {
    icon?: string | React.ReactNode
    label: string | React.ReactNode
    props?: LiHTMLAttributes<HTMLLIElement>
}

export interface Props {
    items: ListItem[]
    size?: 'small' | 'large'
    className?: string
    color?: 'light' | 'dark'
    iconClassname?: string
}

const mapIcons = (icon: string) => {
    switch (icon) {
        case 'jeans':
            return JeansIcon
        case 'present':
            return GiftIcon
        case 'discount':
            return DiscountIcon
        default:
            return UspIcon
    }
}

const List = ({
    items,
    size = 'large',
    className,
    color = 'dark',
    iconClassname,
}: Props) => (
    <ul
        className={cx(
            styles.list,
            { [styles.small]: size === 'small' },
            className,
        )}
    >
        {items.map(({ label, icon, props }, index) => (
            <li className={styles.item} key={`listitem-${index}`} {...props}>
                {icon && typeof icon === 'string' && (
                    <Icon
                        name={icon}
                        component={mapIcons(icon)}
                        title={icon}
                        className={cx(styles.icon, iconClassname)}
                    />
                )}
                {icon && typeof icon !== 'string' && icon}

                {label && typeof label === 'string' && (
                    <Text as="span" color={color}>
                        {label}
                    </Text>
                )}
                {label && typeof label !== 'string' && label}
            </li>
        ))}
    </ul>
)

export default List
