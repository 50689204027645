import { ConfigurableOptions } from './ConfigurableProduct'

export default function getAttributeIdByCode(
    configurableOptions: ConfigurableOptions[],
    code: string,
) {
    return configurableOptions.find((option) => option.attributeCode === code)
}

export function getAttributeIdLookupMap(
    configurableOptions: ConfigurableOptions[],
) {
    const map = new Map<string, string>()

    for (const option of configurableOptions) {
        map.set(option.attributeCode, option.attributeId)
    }
    return map
}
