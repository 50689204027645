import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
    CategoryTree,
    CmsPage,
    Maybe,
    Navigation,
    NavigationItem,
    ProductInterface,
    QueryNavigationArgs,
} from '../graphql/schema.generated'
import { useFilterNullValues } from '../utils/filterNullValues'

const navigationItemFragment = gql`
    fragment NavigationItemFragment on NavigationItem {
        id
        label
        href
        sortOrder
        target
        promotionBackgroundImage
        promotionButtonLink
        promotionButtonText
        promotionLink
        promotionText
        promotionBackgroundImage2
        promotionButtonLink2
        promotionButtonText2
        promotionLink2
        promotionText2
        category {
            id
            urlPath
        }
        product {
            id
            urlKey
        }
        cmsPage {
            urlKey
        }
    }
`

const query = gql`
    query Navigation($code: String) {
        navigation(code: $code) {
            items {
                ...NavigationItemFragment
                children {
                    ...NavigationItemFragment
                    children {
                        ...NavigationItemFragment
                    }
                }
            }
        }
    }
    ${navigationItemFragment}
`

type NavigationItemsVariables = Pick<QueryNavigationArgs, 'code'>

export type CustomNavigationItem = Overwrite<
    Pick<
        NavigationItem,
        | 'id'
        | 'label'
        | 'href'
        | 'sortOrder'
        | 'target'
        | 'category'
        | 'cmsPage'
        | 'product'
        | 'children'
        | 'promotionBackgroundImage'
        | 'promotionButtonLink'
        | 'promotionButtonText'
        | 'promotionLink'
        | 'promotionText'
        | 'promotionBackgroundImage2'
        | 'promotionButtonLink2'
        | 'promotionButtonText2'
        | 'promotionLink2'
        | 'promotionText2'
    >,
    {
        children: CustomNavigationItem[]
        category: Maybe<Pick<CategoryTree, 'urlPath'>>
        product: Maybe<Pick<ProductInterface, 'urlKey'>>
        cmsPage: Maybe<Pick<CmsPage, 'urlKey'>>
    }
>

interface NavigationItemsData {
    navigation: Overwrite<
        Pick<Navigation, 'items'>,
        {
            items: Maybe<Array<Maybe<CustomNavigationItem>>>
        }
    >
}

const useNavigationItems = (
    code: string,
    options: Omit<
        QueryHookOptions<NavigationItemsData, NavigationItemsVariables>,
        'variables'
    > = {},
): CustomNavigationItem[] | undefined => {
    const { data, error, loading } = useQuery<
        NavigationItemsData,
        NavigationItemsVariables
    >(query, {
        ...options,
        variables: {
            code,
        },
        context: getCacheableContext(),
    })

    if (error) {
        throw error
    }

    const menuItems = useFilterNullValues(data?.navigation?.items)

    if (!loading && !menuItems) {
        throw new Error('Main menu configuration is missing !!!')
    }

    return menuItems
}

export default useNavigationItems
