import { useActiveStoreView } from '@emico/storeviews'

import {
    JUST_REWARDS_ENABLED,
    JUST_REWARDS_STORE_BLACKLIST,
} from '../../../constants'

export function isJustRewardsEnabled(storeCode: string): boolean {
    return (
        JUST_REWARDS_ENABLED &&
        !JUST_REWARDS_STORE_BLACKLIST?.includes(storeCode)
    )
}

export function useIsJustRewardsEnabled() {
    const storeView = useActiveStoreView()

    return isJustRewardsEnabled(storeView.code)
}
