import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'

import { Page } from '../../graphql/schema.generated'
import Link from '../../navigation/Link'
import theme from '../../theme'
import breakpoints from '../../theme/breakpoints'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'

const StoryCardWrapper = styled(Link)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.colors.graylightest};
    width: 100%;

    img {
        transition: transform 0.4s;
        aspect-ratio: 2 / 1;
    }

    &:hover {
        text-decoration: none;
        img {
            transform: scale(1.15);
        }
    }
`

const ImageWrapper = styled.div`
    overflow: hidden;
`

const StyledPicture = styled(Picture)`
    height: 100%;
    width: 100%;
    object-fit: cover;
`

const SubHeading = styled(Heading)`
    padding-top: 10px;
`

const CardContent = styled.div`
    padding: 15px;
`

const StyledHeading = styled(Heading)`
    color: ${theme.colors.grayMiddle};
`

const Span = styled.span`
    font-weight: 400;
    color: ${theme.colors.text};
`

const StyledText = styled(Text)`
    margin: 10px 0;
`

const WrapperText = styled.div`
    display: flex;
    justify-content: space-between;
`

interface Props {
    story: Page
}

const StoryCard = ({ story }: Props) => {
    const publication = new Date(story.publicationField)
    const date = new Intl.DateTimeFormat('nl-NL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(publication)

    return (
        <StoryCardWrapper
            to={story.urlKey ?? ''}
            name="Story link"
            category="storyOverviewStoryCard"
        >
            {story.thumbnailImage?.url && (
                <ImageWrapper>
                    <StyledPicture
                        breakpoints={{
                            [breakpoints.xs]: {
                                url: story.thumbnailImage?.url.replace(
                                    '&w=349&h=232',
                                    '',
                                ),
                                width: 768,
                                height: 384,
                                params: {
                                    crop: [768, 384],
                                    gravity: 'sm',
                                },
                            },
                            [breakpoints.md]: {
                                url: story.thumbnailImage?.url.replace(
                                    '&w=349&h=232',
                                    '',
                                ),
                                width: 992,
                                height: 496,
                                params: {
                                    crop: [992, 496],
                                    gravity: 'sm',
                                },
                            },
                        }}
                        alt=""
                        lazy={false}
                    />
                </ImageWrapper>
            )}
            <CardContent>
                <WrapperText>
                    <StyledHeading variant="h4" element="div">
                        {story.storyTag?.linkType === 'Document'
                            ? story.storyTag.slug
                            : null}
                    </StyledHeading>
                    <Span>{date}</Span>
                </WrapperText>
                {story.storyTitle && (
                    <SubHeading variant="h3" element="h4">
                        {story.storyTitle}
                    </SubHeading>
                )}
                <StyledText>{story.storyDescription}</StyledText>
            </CardContent>
        </StoryCardWrapper>
    )
}

export default StoryCard
