import { ReactNode, useEffect } from 'react'

const maveCdnUrl = 'https://cdn.mave.io/npm/@maveio/components/+esm'
const fallbackUrl =
    'https://ga.jspm.io/npm:@maveio/components@0.0.110/dist/index.js'

type MaveModule = {
    Clip: unknown
}

const loadMaveWithFallback = async (
    maveCdnUrl: string,
    fallbackUrl: string,
) => {
    const maveModule: MaveModule = await import(
        /* @vite-ignore */ maveCdnUrl
    ).catch(() => import(/* @vite-ignore */ fallbackUrl))

    if (!maveModule || !maveModule.Clip) {
        throw new Error('Cannot load Mave module.')
    }
}

export const MaveLoader = ({ children }: { children: ReactNode }) => {
    useEffect(() => {
        loadMaveWithFallback(maveCdnUrl, fallbackUrl)
    }, [])

    return <>{children}</>
}
