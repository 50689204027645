import { useCallback } from 'react'

import parseItemVariant from './parseItemVariant'
import toGA4Item from './toGAItem'
import { usePushEcommerceEvent } from './usePushEvent'
import { convertToTagManagerVariants } from './utils'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../../catalog/ProductPage/GetBasicProduct.query'
import { useRootCategoryId } from '../../catalog/useRootCategory'
import { getProductVariantBySelectedOptions } from '../getProductVariantBySelectedOptions'

export function useAddToCartEvent() {
    const rootCategoryId = useRootCategoryId()
    const push = usePushEcommerceEvent([], false, false)

    function pushAddToCartEvent(
        itemId: string,
        product: BasicProduct | BasicConfigurableProduct,
        quantity: number,
        selectedOptions?: ConfigurableAttributesFieldValue,
    ) {
        if (!rootCategoryId) {
            return
        }

        const variant: string[] = selectedOptions
            ? convertToTagManagerVariants(
                  selectedOptions,
                  product as BasicConfigurableProduct,
              )
            : []

        const selectedProductVariantSku = getProductVariantBySelectedOptions(
            product as BasicConfigurableProduct,
            selectedOptions ?? {},
        )?.product?.sku

        const item = toGA4Item(
            {
                ...product,
                sku: selectedProductVariantSku ?? itemId,
            },
            rootCategoryId,
            {
                quantity,
                item_variant: parseItemVariant(variant.join(',')),
                item_id: itemId,
            },
        )

        // Asuming that a user does not click faster than the required data is loaded
        push?.([
            { ecommerce: null },
            {
                event: 'add_to_cart',
                ecommerce: {
                    currency: String(item.currency ?? 'EUR'),
                    value:
                        Math.round(
                            ((item.price || 0) * quantity + Number.EPSILON) *
                                100,
                        ) / 100,
                    items: [item],
                },
            },
        ])
    }
    return useCallback(pushAddToCartEvent, [push, rootCategoryId])
}
