import HeroImage from 'components/src/cms-pages/HomePage/HeroImage'
import absolutify from 'components/src/utils/url/absolutify'
import * as React from 'react'

import DesktopBackgroundImage from './desktop.jpg'
import MobileBackgroundImage from './mobile.jpg'

const NotFoundBackgroundImages = () => (
    <HeroImage
        url={absolutify(DesktopBackgroundImage)}
        mobile_url={absolutify(MobileBackgroundImage)}
    />
)

export default NotFoundBackgroundImages
