interface BrandsInfo {
    prettyName: string
    logo: string
}

interface BrandsInfoType {
    [index: string]: BrandsInfo
}

const brandsInfo: BrandsInfoType = {
    'CAST IRON': {
        prettyName: 'Cast Iron',
        logo: '/cast-iron.svg',
    },
    VANGUARD: {
        prettyName: 'Vanguard',
        logo: '/vanguard.svg',
    },
    'PME JEANS': {
        prettyName: 'PME Legend',
        logo: '/pme-legend.svg',
    },
    'PME LEGEND': {
        prettyName: 'PME Legend',
        logo: '/pme-legend.svg',
    },
    'PALL MALL': {
        prettyName: 'PME Legend',
        logo: '/pme-legend.svg',
    },
}

// Maps brand to pretty name and image
const BrandMapper = (brand: string): BrandsInfo | undefined => brandsInfo[brand]

export { BrandMapper }
