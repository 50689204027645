import { getCoreConfig } from '../coreConfig.query'
import { Action, ThunkResult } from '../types/Redux'

export enum CoreConfigActionTypes {
    SET_CORE_CONFIG = 'SET_CORE_CONFIG',
}
export interface CoreConfig {
    [key: string]: string | null
}
export interface SetCoreConfigAction extends Action {
    type: CoreConfigActionTypes.SET_CORE_CONFIG
    payload: CoreConfig
}

export const setCoreConfig = (data: CoreConfig): SetCoreConfigAction => ({
    type: CoreConfigActionTypes.SET_CORE_CONFIG,
    payload: data,
})

export const fetchCoreConfig =
    (): ThunkResult<void> =>
    async (
        dispatch: (action: Action) => unknown,
        getState,
        { apolloClient },
    ) => {
        const response = await getCoreConfig(apolloClient)()

        const data = response.data.coreConfig?.data ?? []
        // Turn the array into a keyvalue dictionary to simplify the structure, making usage easier.
        const config = data.reduce<CoreConfig>((obj, item) => {
            if (item?.code && item?.value) {
                obj[item.code] = item.value as string | null
            }
            return obj
        }, {})

        dispatch(setCoreConfig(config))
    }
