import cx from 'classnames'

import Brands from './cms-pages/JBFOHomePage/Brands'
import {
    JbfoPageBodyBrand,
    JbfoPageBodyBrandFields,
    JustbrandsPageBodyBrand,
} from './graphql/schema.generated'
import styles from './PrismicSliceBrand.module.scss'
import { CmsPageBrandItem } from './types/CmsPages'

type PrismicSliceBrandType = JbfoPageBodyBrand | JustbrandsPageBodyBrand

interface Props {
    slice: PrismicSliceBrandType
}

const PrismicSliceBrand = ({ slice: { primary, fields } }: Props) => {
    if (!primary) {
        return null
    }

    const { content, heading, style } = primary

    const brands: CmsPageBrandItem[] = (
        (fields as JbfoPageBodyBrandFields[]) ?? []
    ).reduce<CmsPageBrandItem[]>((acc, field) => {
        // Dont show items with no main image or no url configured
        if (
            field?.image?.url &&
            field.desktopLogoImage?.url &&
            field?.buttonUrl
        ) {
            const {
                title,
                content,
                desktopLogoImage,
                buttonUrl,
                buttonText,
                image,
                mobileImage,
            } = field

            acc.push({
                buttonText: buttonText ?? '',
                heading: title ?? '',
                image: {
                    url: image.url,
                },
                imageMobile: {
                    url: mobileImage?.url ?? image.url,
                },
                logo: {
                    url: desktopLogoImage?.url,
                    file: desktopLogoImage?.url,
                },
                subheading: content ?? '',
                title: title ?? '',
                url: buttonUrl ?? undefined,
            })
        }
        return acc
    }, [])

    return (
        <div className={cx(style ? styles[style] : '', styles.default)}>
            <div className={styles.brandsWrapper}>
                <Brands
                    style={style ?? 'light'}
                    heading={heading}
                    subheading={content}
                    brands={brands}
                />
            </div>
        </div>
    )
}

export default PrismicSliceBrand
