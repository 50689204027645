import { gql, useQuery } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'

import {
    Maybe,
    QueryUspsArgs,
    Usp as GraphqlUsp,
} from './graphql/schema.generated'
import { filterNullValues } from './utils/filterNullValues'

const queryUsps = gql`
    query getUsps {
        usps(pageSize: 50, filter: false) {
            url
            label
            isPromotion
            promotionImage
            promotionText
            position
            customerGroupIds
        }
    }
`

export type Usp = Pick<
    GraphqlUsp,
    | 'label'
    | 'url'
    | 'isPromotion'
    | 'promotionImage'
    | 'promotionText'
    | 'position'
    | 'customerGroupIds'
>

interface UspData {
    usps: Array<Maybe<Usp>>
}

const useUsps = (position: 'top' | 'pdp' | 'promotion') => {
    const authorizationContext = useAuthorizationContext()
    const { data, ...others } = useQuery<UspData, QueryUspsArgs>(queryUsps, {
        context: authorizationContext,
    })
    const usps = filterNullValues(data?.usps) ?? []

    switch (position) {
        case 'top':
            return {
                ...others,
                data: usps.filter(
                    (usp) =>
                        !usp.isPromotion &&
                        usp.position?.includes('top') &&
                        usp.label,
                ),
            }
        case 'pdp':
            return {
                ...others,
                data: usps.filter(
                    (usp) =>
                        !usp.isPromotion &&
                        usp.position?.includes('pdp') &&
                        usp.label,
                ),
            }
        case 'promotion':
            return {
                ...others,
                data: usps.filter((usp) => usp.isPromotion && usp.label),
            }
    }
}

export const usePromotionBlock = () => {
    const { data: usps, ...others } = useUsps('promotion')

    return {
        ...others,
        data: usps?.slice(0, 2),
    }
}

export default useUsps
