import { Location } from 'history'

import parseQueryString from '../../utils/url/parseQueryString'

const getSearchQuery = (location: Location): string | undefined => {
    const queryString = parseQueryString(location.search)

    return queryString.q
        ? decodeURIComponent(queryString.q.replace(/\+/g, '%20'))
        : undefined
}

export default getSearchQuery
