// import borders from './borders'
import colors from './colors'
import typography from './typography'
import utils from './utils'
// import spacing from './spacing'
// import sizes from './sizes'
// import transitions from './transitions'
// import zIndices from './zIndices'
// import shadow from './shadow'

const theme = {
    utils,
    //   borders,
    colors,
    //   ...spacing,
    //   ...sizes,
    ...typography,
    //   zIndices,
    //   transition: transitions,
    //   shadow,
}

export default theme
