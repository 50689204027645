import { createAction } from 'redux-actions'

export default {
    signIn: {
        request: createAction('USER_SIGN_IN_REQUEST'),
        receive: createAction('USER_SIGN_IN_RECEIVE'),
        reset: createAction('USER_SIGN_IN_RESET'),
    },
    getDetails: {
        request: createAction('USER_GET_DETAILS_REQUEST'),
        receive: createAction('USER_GET_DETAILS_RECEIVE'),
    },
    resetPassword: {
        request: createAction('USER_RESET_PASSWORD_REQUEST'),
        receive: createAction('USER_RESET_PASSWORD_RECEIVE'),
    },
    completePasswordReset: createAction('USER_COMPLETE_PASSWORD_RESET'),
}
