import { usePrismicDocument } from '@emico/prismic'

import {
    CastironFaqPage,
    CastironPage,
    FaqPage,
    JustbrandsFaqPage,
    JustbrandsPage,
    Page,
    PmeOutletFaqPage,
    PmeOutletPage,
    VanguardFaqPage,
    VanguardPage,
} from './graphql/schema.generated'

export type PrismicPage =
    | Pick<
          Page,
          | 'tweakwiseFunnelId'
          | 'pageType'
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          FaqPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          VanguardPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          VanguardFaqPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          JustbrandsPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          JustbrandsFaqPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          CastironPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          CastironFaqPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          PmeOutletPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >
    | Pick<
          PmeOutletFaqPage,
          | 'body'
          | 'head'
          | 'nofollow'
          | 'noindex'
          | 'seoDescription'
          | 'seoKeywords'
          | 'seoTitle'
          | 'style'
          | 'urlKey'
          | '__typename'
      >

const usePrismicPage = <T = PrismicPage>(
    urlKey: string,
    prismicType: string,
    skip?: boolean,
) =>
    // The value of ID in `my.<ID>.url_key` has to match with
    // a page type in prismic.
    usePrismicDocument<T>(
        {
            key: `my.${prismicType}.url_key`,
            value: urlKey,
        },
        true,
        { skip },
    )

export default usePrismicPage
