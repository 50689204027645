import { Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './Stores.module.scss'
import { Responsive } from '../../../core/Responsive'
import Button from '../../../input/Button'
import Col from '../../../layout/Col'
import Container from '../../../layout/Container'
import Row from '../../../layout/Row'
import ImageLazy from '../../../media/ImageLazy'
import { CmsPageStoresItem } from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'

interface Props extends CmsPageStoresItem {
    isActive: boolean
    index: number
}

const StoreTab = ({
    address,
    image,
    imageMobile,
    name,
    url,
    isActive,
    index,
}: Props) => {
    const activeClass = isActive ? styles.active : ''

    return (
        <div className={cx(activeClass, styles.storeContentTab)} key={index}>
            {image?.url && (
                <ImageLazy
                    alt={name ?? ''}
                    images={{
                        image: image.url,
                        image_mobile: imageMobile?.url ?? image.url,
                        regularWidth: 2000,
                        mediumWidth: 1024,
                        smallWidth: 400,
                    }}
                    className={styles.storeImage}
                />
            )}

            <Container className={styles.storeContentTabInner}>
                <Row>
                    <Col>
                        <div className={styles.storeContentBlock}>
                            {name && (
                                <Heading
                                    variant="h2"
                                    element="h3"
                                    color="light"
                                    className={styles.storeHeading}
                                >
                                    {name}
                                </Heading>
                            )}

                            {address && (
                                <Heading
                                    variant="subheading"
                                    element="div"
                                    color="light"
                                    className={styles.storeAddress}
                                >
                                    {address}
                                </Heading>
                            )}

                            <Responsive lg up>
                                {url && (
                                    <Button
                                        minWidth
                                        to={url}
                                        variant="default"
                                        className={styles.storeDiscoverButton}
                                        name={`discoverButton.${name}`}
                                        category="cms.homePage.stores"
                                    >
                                        <Trans id="cms.homePage.stores.discoverButton">
                                            Discover
                                        </Trans>
                                    </Button>
                                )}
                            </Responsive>
                        </div>

                        <Responsive md down>
                            {url && (
                                <Button
                                    minWidth
                                    to={url}
                                    variant="default"
                                    className={styles.storeDiscoverButton}
                                    name={`discoverButton.${name}`}
                                    category="cms.homePage.stores"
                                >
                                    <Trans id="cms.homePage.stores.discoverButton">
                                        Discover
                                    </Trans>
                                </Button>
                            )}
                        </Responsive>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StoreTab
