import {
    createContext,
    HTMLAttributes,
    default as React,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'

import {
    MessageContainer,
    MessageContent,
    MessageIcon,
    MessageIconContainer,
} from './FeedbackStyledComponents'
import CheckIcon from '../../core/CheckIcon'

type MessageType = {
    type: 'success' | 'warning'
    message: string
}

interface ContextType {
    message?: MessageType
    setSuccess(value: string): void
    setWarning(value: string): void
    reset(): void
}

const context = createContext<ContextType>({
    message: undefined,
    setSuccess: (_: string) => undefined,
    setWarning: (_: string) => undefined,
    reset: () => undefined,
})

interface Props {
    timeout?: number
    children: ReactNode
}

const FeedbackMessageProvider = ({
    children,
    timeout: messageTimeout = 3000,
}: Props) => {
    const Provider = context.Provider
    const [message, setMessage] = useState<MessageType | undefined>(undefined)

    let timeout: NodeJS.Timeout | undefined

    useEffect(() => {
        let timeout =
            message !== undefined
                ? setTimeout(() => {
                      setMessage(undefined)
                      timeout = undefined
                  }, messageTimeout)
                : undefined

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [message, timeout, messageTimeout])

    const setSuccessMessage = (message: string) => {
        setMessage({ message, type: 'success' })
    }

    const setWarningMessage = (message: string) => {
        setMessage({ message, type: 'warning' })
    }

    const reset = () => setMessage(undefined)

    return (
        <Provider
            value={{
                message,
                setSuccess: setSuccessMessage,
                setWarning: setWarningMessage,
                reset,
            }}
        >
            {children}
        </Provider>
    )
}

const useFeedbackMessage = () => useContext(context)

const iconMapping = {
    success: <CheckIcon />,
    warning: <>!</>,
}

const FeedbackMessage = ({
    className,
}: {
    className?: HTMLAttributes<HTMLElement>
}) => {
    const { message, reset } = useFeedbackMessage()

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => () => reset(), [])
    if (message === undefined) {
        return null
    }

    const { type, message: mesg } = message

    return (
        <MessageContainer type={type} onClick={reset}>
            <MessageContent>
                <MessageIconContainer>
                    {type && type in iconMapping && (
                        <MessageIcon type={type}>
                            {iconMapping[type]}
                        </MessageIcon>
                    )}
                </MessageIconContainer>
                {mesg}
            </MessageContent>
        </MessageContainer>
    )
}

export default FeedbackMessage

export { useFeedbackMessage, FeedbackMessageProvider }
