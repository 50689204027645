import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { Item } from '@react-stately/collections'
import { useEffect } from 'react'

import { minWidth } from '@emico/styles'

import defaultAttributes from './defaultAttributes'
import flattenCombinedAttributes, {
    decodeAttributeValueObject,
    encodeAttributeValueObject,
} from './flattenCombinedAttributes'
import Button, { ButtonProps } from '../../../input/Button'
import FormError from '../../../input/FormError'
import PopperSelect from '../../../input/PopperSelect'
import PopperSelectControl from '../../../input/PopperSelectControl'
import { getBreadcrumbs } from '../../../navigation/ProductBreadcrumbs'
import Text from '../../../typography/Text'
import getProductStockInfo from '../../../utils/getProductStockInfo'
import overridable from '../../../utils/overridable'
import ProductFormButton from '../../ProductForm/ProductFormButton'
import useProductForm from '../../ProductForm/useProductForm'
import { useRootCategoryId } from '../../useRootCategory'
import {
    ConfigurableAttributesFieldValue,
    useSelectedConfigurableValue,
} from '../ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { Product } from '../GetProduct.query'

const StyledFormError = styled(FormError)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -60px;
    z-index: 1;
`

const StyledForm = styled.form`
    display: contents;
`

const ActionWrapper = styled.div<{ verticalLayout: boolean }>`
    display: flex;
    gap: 10px;
    ${({ verticalLayout }) => (verticalLayout ? '' : 'flex-grow: 1;')}
    position: relative;
    flex-direction: ${({ verticalLayout }) =>
        verticalLayout ? 'column' : 'row'};
    padding: 10px 0;

    @media ${minWidth('lg')} {
        padding: 20px 0;
    }
`

const StyledText = styled(Text)`
    margin-bottom: 0;
    font-size: 1em;
    text-align: center;

    @media ${minWidth('lg')} {
        font-size: 1.2em;
    }
`

const ConfigurableOptionsContainer = styled.div<{ verticalLayout: boolean }>`
    align-items: center;
    justify-content: center;
    min-height: 40px;
    width: ${({ verticalLayout }) => (verticalLayout ? '100%' : '50%')};

    @media ${minWidth('lg')} {
        display: flex;
        flex-basis: 50%;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
`

const SubmitButtonContainer = styled.div`
    flex-basis: 50%;
`

const StyledPopperSelect = styled(PopperSelectControl)`
    width: 100%;
    max-width: 100%;
` as typeof PopperSelectControl

interface Props {
    product: Product | ConfigurableProduct
    onAddToCartSuccess?(): void
    initialValue?: ConfigurableAttributesFieldValue
    onValueChange?(value: ConfigurableAttributesFieldValue): void
    withProductInfo?: boolean
    withBookmark?: boolean
    className?: string
    children?: React.ReactNode
    verticalLayout?: boolean
    selectPopperPosition?: 'top' | 'bottom'
}

export interface ProductFormValues {
    configurableAttributes?: string
}

// Make CtaButton overridable so we can change button variant
export const CtaSelectSizeButton = overridable('CtaSelectSizeButton')(
    (props: ButtonProps) => <Button {...props} />,
)

export const getProductInitialValue = (
    product: Product | ConfigurableProduct,
    initialValue?: ConfigurableAttributesFieldValue,
) => {
    let standardInitialValue: Record<string, string> | undefined

    const isConfigurable = Boolean(
        (product as ConfigurableProduct).configurableOptions,
    )

    if ('configurableOptions' in product) {
        product.configurableOptions.forEach((option) => {
            if (option.values.length > 1) {
                return
            }
            if (!standardInitialValue) {
                standardInitialValue = {}
            }
            standardInitialValue[option.attributeId] = option.values[0]?.uid
        })
    }

    const returnValue = initialValue || standardInitialValue

    if (returnValue && isConfigurable) {
        // Check if the initial value exists within variants so we know if it is available or not
        const isAvailable = (product as ConfigurableProduct).variants
            .flatMap((v) => v.attributes)
            .flatMap((a) => a.uid)
            .includes(Object.values(returnValue)[0])

        return isAvailable ? returnValue : undefined
    } else {
        return returnValue
    }
}

const ProductInfoFormMinimal = ({
    product,
    initialValue,
    onValueChange,
    verticalLayout = false,
    className,
    selectPopperPosition = 'top',
}: Props) => {
    const selectedValueVar = useSelectedConfigurableValue(product)

    const rootCategoryId = useRootCategoryId()

    const productInitialValue = getProductInitialValue(product, initialValue)

    const {
        formProps,
        configurableSelectProps,
        isSubmitting,
        isInCart,
        formState: { errors },
        showSubmit,
        setValue: setFormValue,
        submitError,
    } = useProductForm(product, {
        defaultValues: {
            configurableAttributes: productInitialValue
                ? encodeAttributeValueObject(
                      getProductInitialValue(product, initialValue),
                  )
                : undefined,
        },
    })

    useEffect(() => {
        if (selectedValueVar) {
            setFormValue(
                'configurableAttributes',
                encodeAttributeValueObject(selectedValueVar),
            )
        }
    }, [selectedValueVar, setFormValue])

    if (!rootCategoryId) {
        return null
    }

    const breadcrumbs = getBreadcrumbs(
        product.categories,
        rootCategoryId,
        product.name,
        product.urlKey,
    )

    if (breadcrumbs.length === 0) {
        return null
    }

    const { to: mainCategoryLink, label: mainCategoryLabel } = breadcrumbs[0]

    const { isOutOfStock } = getProductStockInfo(product)

    const flattenedOptions = flattenCombinedAttributes(
        product,
        (attr) => attr.uid,
    )

    const defaultOptions = defaultAttributes(product, (attr) => attr.uid)
    const options =
        (flattenedOptions ?? defaultOptions)?.map((o) => ({
            key: o.value,
            label: o.label,
            isDisabled: o.disabled,
        })) ?? []

    const cartError =
        errors.configurableAttributes?.message ||
        submitError ||
        errors.quantity?.message

    return (
        <StyledForm {...formProps}>
            {cartError && !verticalLayout && (
                <StyledFormError>{cartError}</StyledFormError>
            )}
            <ActionWrapper
                className={className}
                verticalLayout={verticalLayout}
            >
                {cartError && verticalLayout && (
                    <StyledFormError>{cartError}</StyledFormError>
                )}
                <ConfigurableOptionsContainer verticalLayout={verticalLayout}>
                    {isOutOfStock && (
                        <StyledText as="h2">
                            <Trans id="cart.addToCartForm.outOfStockShort">
                                Out of stock
                            </Trans>
                        </StyledText>
                    )}

                    {!isOutOfStock && options.length === 1 && (
                        <Trans id="cart.addToCartForm.oneSize">One size</Trans>
                    )}
                    {!isOutOfStock && options.length > 1 && (
                        <StyledPopperSelect<
                            {
                                key: string
                                label: string
                                isDisabled: boolean | undefined
                            },
                            'key'
                        >
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            verticalLayout={verticalLayout}
                            as={PopperSelect}
                            label={t({
                                id: 'cart.addToCartForm.selectSize',
                                message: `Select a size`,
                            })}
                            items={options}
                            disabledKeys={options
                                ?.filter((o) => o.isDisabled)
                                ?.map((o) => o.key)}
                            onSelectionChange={(key) => {
                                const value = decodeAttributeValueObject<
                                    Record<string, string>
                                >(String(key))

                                if (onValueChange) {
                                    onValueChange(value)
                                }
                            }}
                            defaultPopperPosition={selectPopperPosition}
                            labelIsVisuallyHidden
                            rules={{
                                required: true,
                            }}
                            {...configurableSelectProps}
                        >
                            {(item) => <Item key={item.key}>{item.label}</Item>}
                        </StyledPopperSelect>
                    )}
                </ConfigurableOptionsContainer>

                <SubmitButtonContainer>
                    {isOutOfStock ? (
                        <Button
                            variant="primary"
                            wide
                            to={mainCategoryLink}
                            name="Go to main product category"
                            category="productPage.outOfStock"
                        >
                            <Trans id="core.outOfStockInfo.buttonLabel">
                                Go to {mainCategoryLabel}
                            </Trans>
                        </Button>
                    ) : (
                        <ButtonWrapper>
                            <ProductFormButton
                                isInCart={isInCart}
                                isSubmitting={isSubmitting}
                                showSubmit={showSubmit}
                            />
                        </ButtonWrapper>
                    )}
                </SubmitButtonContainer>
            </ActionWrapper>
        </StyledForm>
    )
}

export default ProductInfoFormMinimal
