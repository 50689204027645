import { ComponentProps } from 'react'

import {
    CastironPageBodyTweakwiseSlider,
    JbfoPageBodyTweakwiseSlider,
    JustbrandsPageBodyTweakwiseSlider,
    Maybe,
    MonstersalePageBodyTweakwiseSlider,
    PageBodyTweakwiseSlider,
    PmeOutletPageBodyTweakwiseSlider,
    VanguardPageBodyTweakwiseSlider,
} from './graphql/schema.generated'
import Container from './layout/Container'
import TweakwiseSlider from './TweakwiseSlider'
import { BlueConicDynamicPosition } from './types/BlueConic'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceTweakwiseSliderType =
    | PageBodyTweakwiseSlider
    | CastironPageBodyTweakwiseSlider
    | JustbrandsPageBodyTweakwiseSlider
    | VanguardPageBodyTweakwiseSlider
    | JbfoPageBodyTweakwiseSlider
    | MonstersalePageBodyTweakwiseSlider
    | PmeOutletPageBodyTweakwiseSlider

interface Props {
    slice: PrismicSliceTweakwiseSliderType
}

const PrismicSliceTweakwiseSlider = ({ slice: { primary } }: Props) => {
    if (!primary) {
        return null
    }

    let buttonStyle

    const {
        style,
        header,
        content,
        buttonUrl,
        buttonText,
        recommendationsIdentifier,
        blueconicPosition,
    } = primary

    // Not every '...PageBodyPrimary' has a buttonStyle in Prismic:
    if ('buttonStyle' in primary) {
        buttonStyle = primary.buttonStyle ?? ''
    }

    const templateId = parseInt(
        recommendationsIdentifier?.split(' ')[0] || '',
        10,
    )

    if (!header || !templateId || templateId === 0 || isNaN(templateId)) {
        return null
    }

    const componentProps: ComponentProps<typeof TweakwiseSlider> = {
        style: getLayoutStyle(style),
        header,
        content: content ?? undefined,
        buttonUrl: buttonUrl ?? undefined,
        buttonText: buttonText ?? undefined,
        buttonStyle: buttonStyle ?? undefined,
        templateId,
        positionBC:
            (blueconicPosition as Maybe<BlueConicDynamicPosition>) ?? undefined,
    }

    return (
        <Container noPadding fluid>
            <TweakwiseSlider {...componentProps} />
        </Container>
    )
}

export default PrismicSliceTweakwiseSlider
