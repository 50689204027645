import cx from 'classnames'
import * as React from 'react'

import styles from './Container.module.scss'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    as?: React.ElementType

    /**
     * If true, container will have vertical padding for content
     */
    verticalPadding?: boolean

    /**
     * If true, container wont have any padding
     * @default false
     */
    noPadding?: boolean

    /**
     * When true, allows the container to fill all horizontal space
     * @default false
     */
    fluid?: boolean
}

const Container = React.forwardRef<HTMLDivElement, Props>(function Container(
    {
        as = 'div',
        className,
        fluid = false,
        noPadding = false,
        verticalPadding = false,
        children,
        ...otherProps
    }: Props,
    ref,
) {
    const Component = as

    const classes = cx(
        styles.container,
        { [styles.maxWidth]: !fluid },
        { [styles.noPadding]: noPadding },
        { [styles.verticalPadding]: verticalPadding },
        className,
    )

    return (
        <Component {...otherProps} className={classes} ref={ref}>
            {children}
        </Component>
    )
})

export default Container
