import HeadingVariant from './types/HeadingVariant'

export const mapVariant = (variant: string | null) => {
    switch (variant) {
        case 'sale':
            return HeadingVariant.sale
        case 'h1':
            return HeadingVariant.h1
        case 'h2':
            return HeadingVariant.h2
        case 'h3':
            return HeadingVariant.h3
        default:
            return undefined
    }
}
