import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { ComponentProps, memo } from 'react'

import { stripMaybes } from '@emico/utils'

import Accordion from './Accordion'
import { ConfigurableProduct } from './catalog/ProductPage/ConfigurableProduct'
import { Product } from './catalog/ProductPage/GetProduct.query'
import { AttributeOption, Maybe } from './graphql/schema.generated'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'
import Text from './typography/Text'

interface IconType {
    label?: string
    iconFileName?: string
    title?: string
}

const StyledAccordion = styled(Accordion)`
    margin-top: 25px;
`

const StyledUl = styled.ul`
    margin-bottom: 0;
    padding: 0 0 0 25px;
`

const StyledIconUl = styled.ul`
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    margin: 0;
    padding: 0;
`

const StyledIconLi = styled.li`
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 10px;
`

const Icon = styled.img`
    display: inline-block;
    width: 30px;
    height: 30px;
`

const CONTENT_SEPARATORS = /[;/,|]+/

const combineValues = (
    values: Maybe<Array<Maybe<AttributeOption>>> | undefined,
): string =>
    values
        ?.map((item) => item?.label)
        ?.filter(stripMaybes)
        .join(', ') ?? ''

const parseIcons = (values: string[] | undefined): IconType[] | undefined =>
    values &&
    values.map((value) => {
        const [iconFileName, label] = value.split(CONTENT_SEPARATORS)

        return {
            iconFileName,
            label,
        }
    })

const addHighlightsItems = (
    items: ComponentProps<typeof Accordion>['items'],
    product: Product | ConfigurableProduct,
): void => {
    const { highlightsValues } = product // old values
    const {
        productClosureValues,
        productClosureDetailsValues,
        productHoodValues,
        productHoodDetailsValues,
        productCollarValues,
        productCollarDetailsValues,
        productSleeveDetailsValues,
        productCuffsValues,
        productSleeveCuffDetailsValues,
        productLogoLocationValues,
        productInnerPocketsValues,
        productOuterPocketsValues,
        productNumberOfPockets,
        productJacketDetailsValues,
        productJacketlayersValues,
        productJacketTypeValues,
        productInnerJacketDetailsValues,
        productDimensions,
        productSustainableValues,
        productLengthDescriptionValues,
        productSleeveLengthValues,
        productPocketTypeValues,
    } = product

    const content: string[] = []

    content.push(combineValues(productClosureValues))
    content.push(combineValues(productClosureDetailsValues))
    content.push(combineValues(productHoodValues))
    content.push(combineValues(productHoodDetailsValues))
    content.push(combineValues(productCollarValues))
    content.push(combineValues(productCollarDetailsValues))
    content.push(combineValues(productSleeveDetailsValues))
    content.push(combineValues(productCuffsValues))
    content.push(combineValues(productSleeveCuffDetailsValues))
    content.push(combineValues(productLogoLocationValues))
    content.push(combineValues(productInnerPocketsValues))
    content.push(combineValues(productOuterPocketsValues))
    if (productNumberOfPockets) {
        content.push(
            t({
                id: 'core.productinfo.numberOfPockets',
                message: `Pockets: ${productNumberOfPockets}`,
            }),
        )
    }
    content.push(combineValues(productJacketDetailsValues))
    content.push(combineValues(productJacketlayersValues))
    content.push(combineValues(productJacketTypeValues))
    content.push(combineValues(productInnerJacketDetailsValues))
    if (productDimensions) {
        content.push(
            t({
                id: 'core.productinfo.dimensions',
                message: `Dimensions: ${productDimensions}`,
            }),
        )
    }
    content.push(combineValues(productSustainableValues))
    content.push(combineValues(productLengthDescriptionValues))
    content.push(combineValues(productSleeveLengthValues))
    content.push(combineValues(productPocketTypeValues))

    const newItems = content.filter((item) => Boolean(item))

    // Preserve fallback for products that don't have inriver data
    if (newItems.length === 0) {
        const highlights = (highlightsValues || []).reduce<string[]>(
            (acc, curr) => {
                if (curr?.label) {
                    acc.push(curr.label)
                }
                return acc
            },
            [],
        )

        content.push(...highlights)
    }

    const contentItems = content.map((h) => h.trim()).filter((h) => h)

    if (contentItems.length > 0) {
        items.push({
            heading: (
                <Trans id="core.productinfo.specifications">
                    Specifications
                </Trans>
            ),
            content: (
                <StyledUl style={{ marginBottom: 0 }}>
                    {contentItems.map((item, index) => (
                        <li key={`highlight-${index}`}>{item}</li>
                    ))}
                </StyledUl>
            ),
        })
    }
}

const addAboutItems = (
    items: ComponentProps<typeof Accordion>['items'],
    product: Product | ConfigurableProduct,
): void => {
    const { description } = product

    if (description?.html) {
        items.push({
            heading: (
                <Trans id="core.productinfo.about">About this product</Trans>
            ),
            content: <Text>{description.html}</Text>,
        })
    }
}

const addFeatureItems = (
    items: ComponentProps<typeof Accordion>['items'],
    product: Product | ConfigurableProduct,
): boolean => {
    const { featureIconsValues } = product // old values
    const { featureMedia, featureName, featureDescription } = product // new values

    let icons: IconType[] | undefined

    // Use new way of fetching icons
    icons = featureName?.split(',').reduce<IconType[]>((acc, title, index) => {
        const iconFileName = featureMedia?.split(',')?.[index]
        const label = featureDescription?.split(',')?.[index] || title

        if (iconFileName) {
            acc.push({
                label,
                iconFileName,
                title,
            })
        }
        return acc
    }, [])

    // Use old way of fetching icons
    if (icons?.length === 0) {
        const featureIcons = (featureIconsValues || []).reduce<string[]>(
            (acc, curr) => {
                if (curr?.label) {
                    acc.push(curr.label)
                }
                return acc
            },
            [],
        )

        if (!featureIcons || featureIcons.length === 0) {
            return false
        }

        icons = parseIcons(featureIcons)?.filter((icon) => icon.iconFileName)
    }
    if (!icons || icons.length === 0) {
        return false
    }

    items.push({
        heading: <Trans id="core.productinfo.features">Features</Trans>,
        content: (
            <StyledIconUl>
                {icons.map(({ label, iconFileName, title }) => (
                    <StyledIconLi key={`feature-${iconFileName}`}>
                        <Icon
                            src={`/media/icons/${iconFileName}`}
                            alt={label || ''}
                            title={title || label || ''}
                            width={30}
                            height={30}
                        />{' '}
                        {label}
                    </StyledIconLi>
                ))}
            </StyledIconUl>
        ),
    })
    return true
}

const addFitItems = (
    items: ComponentProps<typeof Accordion>['items'],
    product: Product | ConfigurableProduct,
): void => {
    const { modelInfo, fitValues } = product // old values
    const {
        productFitValues,
        productWaistRiseValues,
        productShapeValues,
        itemSizeAdviceValues,
        modelAdvice,
    } = product // new values

    const content: string[] = []

    content.push(combineValues(itemSizeAdviceValues))
    content.push(combineValues(productFitValues))
    content.push(combineValues(productWaistRiseValues))
    content.push(combineValues(productShapeValues))
    content.push(modelAdvice ?? '')
    const newItems = content.filter((item) => Boolean(item))
    const hasNewItems = newItems.length > 0

    // Preserve fallback for products that don't have inriver data
    if (!hasNewItems) {
        const fit = (fitValues || []).reduce<string[]>((acc, curr) => {
            if (curr?.label) {
                acc.push(curr.label)
            }
            return acc
        }, [])

        content.push(...fit)
    }

    const contentItems = content.map((h) => h.trim()).filter((h) => h)

    if (contentItems.length > 0 || (!hasNewItems && modelInfo)) {
        items.push({
            heading: <Trans id="core.productinfo.fit">Fit</Trans>,
            content: (
                <StyledUl>
                    {!hasNewItems && modelInfo && (
                        <li key="fit-model">{modelInfo}</li>
                    )}
                    {contentItems.map((fit, index) => (
                        <li key={`fit-${index}`}>{fit}</li>
                    ))}
                </StyledUl>
            ),
        })
    }
}

const addMaterialsItems = (
    items: ComponentProps<typeof Accordion>['items'],
    product: Product | ConfigurableProduct,
): void => {
    const { materialInside, materialOutside } = product // old values
    const { productFabricValues, productPatternValues } = product // new values
    const productFabric = combineValues(productFabricValues)
    const productPattern = combineValues(productPatternValues)

    const values = [
        productFabric,
        productPattern ? (
            <Trans id="core.productinfo.productPattern">
                Pattern: {productPattern}
            </Trans>
        ) : (
            false
        ),
        materialInside ? (
            <Trans id="core.productinfo.materialInside">
                Lining: {materialInside}
            </Trans>
        ) : (
            false
        ),
        materialOutside ? (
            <Trans id="core.productinfo.materialOutside">
                Outside: {materialOutside}
            </Trans>
        ) : (
            false
        ),
    ].filter(Boolean)

    // Don't show materials if they are not set
    if (values.length === 0) {
        return
    }

    // Check for new values and display old values only when no new values are set
    items.push({
        heading: <Trans id="core.productinfo.materials">Materials</Trans>,
        content: values.map((value, i) => <div key={i}>{value}</div>),
    })
}

const addCareItems = (
    items: ComponentProps<typeof Accordion>['items'],
    product: Product | ConfigurableProduct,
): void => {
    const { washingInstructions, washingSymbolsValues } = product // old values
    const { washingSymbolMedia, washingSymbolDescription } = product // new values

    let icons: IconType[] | undefined

    // Use new way of fetching icons
    icons = washingSymbolDescription
        ?.split(',')
        .reduce<IconType[]>((acc, label, index) => {
            const iconFileName = washingSymbolMedia?.split(',')?.[index]

            if (iconFileName) {
                acc.push({
                    label,
                    iconFileName,
                })
            }
            return acc
        }, [])

    // Use old way of fetching icons
    if (icons?.length === 0) {
        const washingIcons = (washingSymbolsValues || []).reduce<string[]>(
            (acc, curr) => {
                if (curr?.label) {
                    acc.push(curr.label)
                }
                return acc
            },
            [],
        )

        if (!washingIcons || washingIcons.length === 0) {
            return
        }
        icons = parseIcons(washingIcons)?.filter((icon) => icon.iconFileName)
    }

    if (!icons || icons.length === 0) {
        return
    }

    items.push({
        heading: <Trans id="core.productinfo.care">Wash & Care</Trans>,
        content: (
            <StyledIconUl>
                {washingInstructions && (
                    <StyledIconLi key="instructions">
                        {washingInstructions}
                    </StyledIconLi>
                )}
                {icons.map(({ label, iconFileName }) => (
                    <StyledIconLi key={`care-${iconFileName}`}>
                        <Icon
                            src={`/media/icons/${iconFileName}`}
                            alt={label || ''}
                            title={label || ''}
                            width={30}
                            height={30}
                        />{' '}
                        {label}
                    </StyledIconLi>
                ))}
            </StyledIconUl>
        ),
    })
}

const ProductInfoAccordion = memo(function ProductInfoAccordion({
    product,
}: {
    product: Product | ConfigurableProduct
}) {
    const items: ComponentProps<typeof Accordion>['items'] = []

    const hasFeatureItems = addFeatureItems(items, product)

    addHighlightsItems(items, product)
    addAboutItems(items, product)
    addFitItems(items, product)
    addMaterialsItems(items, product)
    addCareItems(items, product)

    if (items.length === 0) {
        return null
    }

    return (
        <StyledAccordion
            initial={hasFeatureItems ? 0 : undefined}
            items={items}
            variant={HeadingVariant.h4}
            element={HeadingElement.h2}
        />
    )
})

export default ProductInfoAccordion
