import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './WishlistIconButton.module.scss'
import FadeTransition from '../../layout/FadeTransition'
import Icon from '../../media/Icon'
import BookmarkIcon, { BookmarkFilledIcon } from '../BookmarkIcon'
import WishlistButton, { Props as WishlistButtonProps } from '../WishlistButton'

type Props = Pick<
    WishlistButtonProps,
    | 'product'
    | 'value'
    | 'className'
    | 'onAddToWishlist'
    | 'optimistic'
    | 'showWishlistModal'
    | 'position'
>

export const renderIcon = (
    isActive: boolean,
    className?: string,
    activeClassName?: string,
) => (
    <>
        <Icon
            component={BookmarkIcon}
            title={t({
                id: 'core.productPageTopBar.wishlistAdd',
                message: `Add to wishlist`,
            })}
            wrapperClassName={cx(styles.icon, className)}
        />
        <FadeTransition appear={false}>
            {isActive && (
                <Icon
                    component={BookmarkFilledIcon}
                    title={t({
                        id: 'core.productPageTopBar.wishlistRemove',
                        message: `Remove from wishlist`,
                    })}
                    wrapperClassName={cx(
                        styles.icon,
                        styles.iconFilled,
                        activeClassName,
                    )}
                />
            )}
        </FadeTransition>
    </>
)

const WishlistIconButton = ({ className, ...rest }: Props) => (
    <WishlistButton
        {...rest}
        className={cx(styles.default, className)}
        activeClassName={styles.active}
        render={renderIcon}
    />
)

export default WishlistIconButton
