import { ReactNode, useEffect, useState } from 'react'

import { Option, useNonSpammyFieldState } from '@emico/ui'

import Input from '../Input'
import InformedSelect from '../Select/Informed'

enum InputState {
    SELECT,
    NUMBER,
}

type RenderLabelFunction = (index: number) => ReactNode

function getOptions(
    from: number,
    to: number,
    renderLabel?: RenderLabelFunction,
) {
    const options = []

    for (let i = from; i <= to; i += 1) {
        options.push(
            <option value={i} key={i}>
                {renderLabel?.(i) ?? i}
            </option>,
        )
    }
    return options
}

export interface Props
    extends Omit<
        React.ComponentProps<typeof InformedSelect>,
        'value' | 'mask' | 'format' | 'parse'
    > {
    from?: number
    to?: number
    max?: number
    emptyLabel?: string
    renderLabel?: RenderLabelFunction
    moreLabel?: string
}

const NumberSelect = ({
    field,
    from = 0,
    to = 10,
    emptyLabel,
    renderLabel,
    moreLabel,
    max,
    ...props
}: Props) => {
    const [state, setState] = useState<InputState>(InputState.SELECT)
    const fieldState = useNonSpammyFieldState(field)

    useEffect(() => {
        if (Number(fieldState.value) > to) {
            setState(InputState.NUMBER)
        }
    }, [fieldState.value, to])

    if (state === InputState.NUMBER) {
        return (
            <Input
                field={field}
                {...props}
                min={from}
                max={max}
                initialValue={fieldState.value}
                type="number"
                withValidationIndicators={false}
            />
        )
    }

    return (
        <InformedSelect field={field} max={max} {...props}>
            {emptyLabel && <Option value="">{emptyLabel}</Option>}
            {getOptions(from, Math.min(to, max || to), renderLabel)}
            {moreLabel && (!max || max > to) && (
                <Option value={to + 1}>{moreLabel}</Option>
            )}
        </InformedSelect>
    )
}

export default NumberSelect
