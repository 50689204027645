import {
    useQuery,
    gql,
    QueryHookOptions,
    TypedDocumentNode,
} from '@apollo/client'
import { cookie } from '@prismicio/client'

import { getCacheableContext } from '@emico-utils/graphql-data-utils'
import { PrismicDocument as BasePrismicDocument } from '@emico/graphql-schema-types'
import { useActiveStoreView } from '@emico/storeviews'
import { getCookie } from '@emico/utils'

import {
    PrismicDocumentQuery,
    PrismicDocumentQueryVariables,
} from './usePrismicDocument.generated'

// Allow typing the prismicDocument since we usually know the return type format
export interface PrismicDocument<T> extends BasePrismicDocument {
    data: T
}

const query = gql`
    query PrismicDocument(
        $storeView: String
        $key: String!
        $value: String!
        $lang: String!
        $ref: String
    ) {
        prismicDocument(
            storeView: $storeView
            predicate: { key: $key, value: $value }
            lang: $lang
            ref: $ref
        ) {
            data
        }
    }
` as TypedDocumentNode<PrismicDocumentQuery, PrismicDocumentQueryVariables>

const usePrismicDocument = <T,>(
    predicate: {
        key: string
        value: string
    },
    storeSpecific = true,
    options?: Omit<
        QueryHookOptions<PrismicDocumentQuery, PrismicDocumentQueryVariables>,
        'variables'
    >,
) => {
    const activeStoreView = useActiveStoreView()
    const ref = getCookie(cookie.preview) || undefined

    const { data, loading } = useQuery(query, {
        ...options,
        fetchPolicy: ref ? 'no-cache' : options?.fetchPolicy,
        errorPolicy: 'all',
        variables: {
            ref: ref ?? null,
            key: predicate.key,
            value: predicate.value,
            lang:
                activeStoreView.prismicLocale ??
                activeStoreView.locale ??
                'en-US',
            storeView:
                storeSpecific && activeStoreView.prismic
                    ? activeStoreView.prismic
                    : null,
        },
        context: getCacheableContext(),
    })

    return {
        data: data?.prismicDocument?.data as T,
        loading,
    }
}

export default usePrismicDocument
