import { ComponentProps } from 'react'

import BannerBlocks, { BannerBlocksItem } from './BannerBlocks'
import {
    CastironPageBodyBannerBlocks1,
    JbfoPageBodyBannerBlocks1,
    JustbrandsPageBodyBannerBlocks1,
    MonstersalePageBodyBannerBlocks1,
    PageBodyBannerBlocks1,
    PageBodyBannerBlocks1Fields,
    PageBodyBannerBlocks1Primary,
    PmeOutletPageBodyBannerBlocks1,
    StorelocatorPageBodyBannerBlocks1,
    StorePageBodyBannerBlocks1,
    VanguardPageBodyBannerBlocks1,
} from './graphql/schema.generated'
import ButtonVariant from './types/ButtonVariant'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceBannerBlocks =
    | PageBodyBannerBlocks1
    | CastironPageBodyBannerBlocks1
    | JustbrandsPageBodyBannerBlocks1
    | VanguardPageBodyBannerBlocks1
    | JbfoPageBodyBannerBlocks1
    | MonstersalePageBodyBannerBlocks1
    | PmeOutletPageBodyBannerBlocks1
    | StorelocatorPageBodyBannerBlocks1
    | StorePageBodyBannerBlocks1

interface Props {
    slice: PrismicSliceBannerBlocks
}

const PrismicSliceBannerBlocksThree = ({
    slice: { primary, fields },
}: Props) => {
    if (!primary) {
        return null
    }

    const { title, style, buttonStyle } =
        primary as unknown as PageBodyBannerBlocks1Primary & {
            buttonStyle?: string
        }

    const bannerBlocks: BannerBlocksItem[] = (
        (fields as PageBodyBannerBlocks1Fields[]) ?? []
    ).reduce<BannerBlocksItem[]>((acc, field) => {
        const { title, image, url, subtitle, buttonText, mobileImage } = field

        acc.push({
            title: title ?? undefined,
            url: url ?? undefined,
            subtitle: subtitle ?? undefined,
            buttonText: buttonText ?? undefined,
            image: image?.url
                ? {
                      url: image.url,
                      alt: image.alt ?? title ?? '',
                  }
                : undefined,
            mobileImage: mobileImage?.url
                ? {
                      url: mobileImage.url,
                      alt: mobileImage.alt ?? title ?? '',
                  }
                : undefined,
        })
        return acc
    }, [])

    const componentProps: ComponentProps<typeof BannerBlocks> = {
        style: getLayoutStyle(style),
        title: title ?? '',
        bannerBlocks,
        buttonVariant:
            buttonStyle === 'highlight'
                ? ButtonVariant.highlight
                : ButtonVariant.primary,
        amount: 3,
    }

    return <BannerBlocks {...componentProps} />
}

export default PrismicSliceBannerBlocksThree
