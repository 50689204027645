import { RichTextField, RTNode } from '@prismicio/client'
import { LinkProps, PrismicRichText as RichText } from '@prismicio/react'
import { decamelizeKeys } from 'humps'
import { ComponentProps } from 'react'

import { overrideOptional } from '@emico/utils'

import PrismicLink from './PrismicLink'

type Props = Omit<ComponentProps<typeof RichText>, 'field'> & {
    render: RichTextField
    analyticsContext: ComponentProps<typeof PrismicLink>['analyticsContext']
}

const LinkComponent = (props: LinkProps) => (
    <PrismicLink
        href={props.href}
        to={{
            linkType: 'Web',
            url: props.href,
            target: props.target,
        }}
        analyticsContext=""
        analyticsName=""
        target={props.target}
        rel={props.rel}
    >
        {props.children}
    </PrismicLink>
)

function fixCase(node: RTNode): RTNode {
    if (!('spans' in node)) {
        return node
    }

    return {
        ...node,
        spans: node.spans.map((span) => {
            if ('data' in span) {
                // The typing is fixed in this function, it was incorrect and is now correct.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return {
                    ...span,
                    data: decamelizeKeys(span.data),
                } as typeof span
            }

            return span
        }),
    }
}

/**
 * Renders rich content from Prismic. This function assumes the data passed to
 * it might be incomplete.
 */
const PrismicRichText = ({ render, ...props }: Props) => {
    const fixedCase = render.map(fixCase)

    return (
        <RichText
            // RTNode[] is not exactly the same, but hard to reproduce.
            field={fixedCase as [RTNode, ...RTNode[]]}
            externalLinkComponent={LinkComponent}
            internalLinkComponent={LinkComponent}
            {...props}
        />
    )
}

export default overrideOptional(PrismicRichText)
