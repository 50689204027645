import { usePrismicDocument } from '@emico/prismic'

import { Maybe, StorePage } from './graphql/schema.generated'

const useResolveStoreUrl = (
    srsId?: string,
): StorePage['urlKey'] | undefined => {
    const { data } = usePrismicDocument<Maybe<StorePage>>(
        {
            key: `my.store_page.srs_id`,
            value: srsId || '',
        },
        false,
        {
            skip: !srsId,
        },
    )

    if (!data || !data?.urlKey || !srsId) {
        return undefined
    }

    return data.urlKey
}

export default useResolveStoreUrl
