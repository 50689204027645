import HeadingColor from './types/HeadingColor'

export default (color?: string) => {
    let headingColor

    switch (color) {
        case 'dark':
            headingColor = HeadingColor.dark
            break
        case 'roze':
            headingColor = HeadingColor.contrast
            break
        default:
            headingColor = HeadingColor.light
    }

    return headingColor
}
