import { DataLayerProduct } from '@emico-utils/datalayer-templates'

import { usePushEcommerceEvent } from './usePushEvent'

export interface GA4PromotionItem extends Partial<DataLayerProduct> {
    item_id: string
    location_id: string // Location on the page / in the app
    promotion_name?: string // The name of the promotion associated with the item.
    creative_name?: string // The name of the promotional creative.
}

const useSelectPromotionEvent = () => {
    const push = usePushEcommerceEvent([], false)

    return (item: GA4PromotionItem) => {
        push?.([
            { ecommerce: null },
            {
                event: 'select_promotion',
                ecommerce: { ...item, items: [] },
            },
        ])
    }
}

export default useSelectPromotionEvent
