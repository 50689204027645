import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { BasicConfigurableProduct } from './ConfigurableProduct'
import productCardFragment, {
    ProductCardFragment,
} from '../../ProductCardFragment'
import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from '../common/MinimalConfigurableProductInfo.fragment'

export const basicProductQuerty = gql`
    query GetBasicProduct($id: Int!) {
        product(id: $id) {
            ...ProductCardFragmentJB
            ...ConfigurableProductInfo
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

export interface GetBasicProductVariables {
    id: number
}

export type BasicProduct = ProductCardFragment

export interface GetBasicProductData {
    product: BasicProduct | BasicConfigurableProduct
}

export const useGetBasicProduct = (
    id: number,
    options: Omit<
        QueryHookOptions<GetBasicProductData, GetBasicProductVariables>,
        'variables'
    > = {},
) => {
    const { data, error, ...others } = useQuery<
        GetBasicProductData,
        GetBasicProductVariables
    >(basicProductQuerty, {
        ...options,
        variables: {
            id,
        },
        fetchPolicy: 'cache-and-network',
        context: getCacheableContext(),
    })

    if (error) {
        throw error
    }

    return {
        ...others,
        data: data && data.product ? data.product : undefined,
    }
}
