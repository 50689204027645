import defaultsToVars, { defaultsToCss, getVarKeys } from './cssVarsUtils'

const utilsPrefix = 'utils'

const defaults = {
    topbarMenuOffset: '118px',
}

export const varKeys = getVarKeys(
    {
        ...defaults,
    },
    utilsPrefix,
)

export const utilsCss = [...defaultsToCss(defaults, utilsPrefix)]

export default defaultsToVars(defaults, utilsPrefix)
