import cx from 'classnames'
import { ReactNode } from 'react'

import styles from './CartItemContainer.module.scss'
import overridable from '../utils/overridable'

interface Props {
    children: ReactNode
    className?: string
}

const CartItemContainer = ({ children, className }: Props) => (
    <article className={cx(styles.container, className)}>{children}</article>
)

export default overridable('CartItemContainer')(CartItemContainer)
