import * as React from 'react'

import styles from './Team.module.scss'
import TeamPortrait from './TeamPortrait'
import TeamTab from './TeamTab'
import Col from '../../../layout/Col'
import Row from '../../../layout/Row'
import { CmsPageTeam, CmsPageTeamItem } from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'
import { filterNullValues } from '../../../utils/filterNullValues'
import scrollToElement from '../../../utils/scrollToElement'

const getEmployeePortrait = (
    employees: CmsPageTeamItem[],
    activeEmployee: number,
    setActiveEmployee: (i: number) => void,
) =>
    employees.map((employee, index) => (
        <TeamPortrait
            {...employee}
            isActive={activeEmployee === index}
            index={index}
            key={index}
            setActiveEmployee={setActiveEmployee}
        />
    ))

const getEmployeeContent = (
    employees: CmsPageTeamItem[],
    activeEmployee: number,
) =>
    employees.map((employee, index) => (
        <TeamTab
            {...employee}
            isActive={activeEmployee === index}
            index={index}
            key={index}
        />
    ))

const Team = ({ heading, subheading, employees }: CmsPageTeam) => {
    const [activeEmployee, setActiveEmployee] = React.useState<number>(0)
    const teamRef = React.createRef<HTMLDivElement>()
    const scrollToTeam = () => {
        if (!teamRef.current) {
            return
        }
        scrollToElement(teamRef.current)
    }

    const filteredEmployees = filterNullValues(employees) || []

    return (
        <Row noGutters className={styles.default}>
            <Col>
                <div className={styles.teamWrapper}>
                    <div className={styles.headingWrapper}>
                        <Heading
                            variant="h1"
                            element="h2"
                            color="dark"
                            className={styles.heading}
                        >
                            {heading}
                        </Heading>

                        <Heading
                            variant="subheadingAlt"
                            element="h3"
                            color="dark"
                            className={styles.subHeading}
                        >
                            {subheading}
                        </Heading>
                    </div>

                    {employees && (
                        <div ref={teamRef} className={styles.employeeWrapper}>
                            <div
                                onClick={scrollToTeam}
                                className={styles.employeePortraits}
                            >
                                {getEmployeePortrait(
                                    filteredEmployees,
                                    activeEmployee,
                                    setActiveEmployee,
                                )}
                            </div>
                            <div className={styles.employeeContent}>
                                {getEmployeeContent(
                                    filteredEmployees,
                                    activeEmployee,
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default Team
