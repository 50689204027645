import { useApolloClient } from '@apollo/client'
import * as React from 'react'

import { UrlRewriteEntityTypeEnum } from '../../graphql/schema.generated'
import { resolveUrl, storeResolvedUrl } from '../../utils/resolveUrl'
import InternalLink, {
    Props as InternalLinkProps,
} from '../RouteLink/RouteLink'

export type UrlResolverHint = {
    type: UrlRewriteEntityTypeEnum
    id?: number
    redirectCode?: number
    prismicType?: string
    authRequired?: boolean
    productTypeName?: string
    productId?: number
} & (
    | {
          type: UrlRewriteEntityTypeEnum.CATEGORY
          id: number // id is required for categories as they can't be fetched by urlKey
      }
    | {
          type: UrlRewriteEntityTypeEnum.CMS_PAGE
          id: undefined
      }
    | {
          type: UrlRewriteEntityTypeEnum.PRODUCT
          id: number
      }
    | {
          type: UrlRewriteEntityTypeEnum.PRISMIC
          prismicType: string // prismic type should always be present for prismic pages
      }
)
export interface Props extends InternalLinkProps {
    resolver?: UrlResolverHint
}

const PreloadResolverRouteLink = ({ to, resolver, ...others }: Props) => {
    const client = useApolloClient()

    // Preload the resolver data for this URL to improve page load performance
    // since Apollo will be able to fetch this from cache. See:
    // https://support.emico.nl/issues/110967
    React.useEffect(() => {
        if (resolver) {
            // We're being told how this link could be resolved so store that
            // so we can skip the query
            storeResolvedUrl(client)(
                to,
                resolver.type,
                resolver.redirectCode ?? 0,
                resolver.prismicType,
                resolver.authRequired,
                resolver.id ?? undefined,
                resolver.productTypeName ?? undefined,
                resolver.productId ?? undefined,
            )
        } else if (resolver !== false) {
            // Allow passing a string to resolver to change the URL to resolve. Useful for URLs with query strings or hashtags
            const urlToResolve = resolver ? resolver : to

            // Don't use a hook or component here to avoid a bunch of overhead
            // such as query state (loading, error, etc)
            resolveUrl(client)(urlToResolve)
        }
    }, [client, to, resolver])

    return <InternalLink to={to} {...others} />
}

export default PreloadResolverRouteLink
