import { DataLayerProduct } from '@emico-utils/datalayer-templates'

import toGA4Item from './toGAItem'
import { usePushEcommerceEvent } from './usePushEvent'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../../catalog/ProductPage/GetBasicProduct.query'
import { useRootCategoryId } from '../../catalog/useRootCategory'

const useSelectItem = () => {
    const rootCategoryId = useRootCategoryId()

    const push = usePushEcommerceEvent([], false)

    return (
        product: BasicProduct | BasicConfigurableProduct,
        additionalItemData?: DataLayerProduct,
    ) => {
        if (!rootCategoryId) {
            return
        }
        push?.([
            {
                ecommerce: null,
            },
            {
                event: 'select_item',
                ecommerce: {
                    items: [
                        toGA4Item(product, rootCategoryId, additionalItemData),
                    ],
                },
            },
        ])
    }
}

export default useSelectItem
