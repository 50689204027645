import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

const query = gql`
    query GetProductMeta($id: Int!) {
        product(id: $id) {
            id
            urlKey
        }
    }
`

export default query

export interface GetProductMetaQueryVariables {
    id: number
}

export interface ProductMeta {
    id: number
    urlKey: string
}

export interface GetProductMetaResult {
    product: ProductMeta
}

export const useProductMeta = (
    id: number,
    options: QueryHookOptions<
        GetProductMetaResult,
        GetProductMetaQueryVariables
    > = {},
) =>
    useQuery<GetProductMetaResult, GetProductMetaQueryVariables>(query, {
        ...options,
        variables: {
            id,
        },
        context: getCacheableContext(),
    })
