import * as React from 'react'

import Stores from './cms-pages/JustBrandsHomePage/Stores/Stores'
import {
    JbfoPageBodyStore,
    JbfoPageBodyStoreFields,
    JustbrandsPageBodyStore,
} from './graphql/schema.generated'
import styles from './PrismicSliceStore.module.scss'
import { CmsPageStoresItem } from './types/CmsPages'

type PrismicSliceStoreType = JbfoPageBodyStore | JustbrandsPageBodyStore

interface Props {
    slice: PrismicSliceStoreType
}

const PrismicSliceStore = ({ slice: { primary, fields } }: Props) => {
    if (!primary) {
        return null
    }

    const { buttontext, buttonurl, heading, subheading } = primary

    const stores: CmsPageStoresItem[] = (
        (fields as JbfoPageBodyStoreFields[]) ?? []
    ).reduce<CmsPageStoresItem[]>((acc, field) => {
        // Dont show items with no main image or no url configured
        if (field?.image?.url && field.address && field.name && field.url) {
            const { address, name, url, image, mobileimage } = field

            acc.push({
                address,
                name,
                image: {
                    url: image.url,
                },
                imageMobile: {
                    url: mobileimage?.url ?? image.url,
                },
                url: url ?? '',
            })
        }
        return acc
    }, [])

    return (
        <div className={styles.default}>
            <div className={styles.brandsWrapper}>
                <Stores
                    buttonText={buttontext}
                    buttonUrl={buttonurl}
                    heading={heading}
                    subheading={subheading}
                    store={stores}
                />
            </div>
        </div>
    )
}

export default PrismicSliceStore
