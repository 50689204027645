import { css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import { minWidth } from '@emico/styles'

import AvailablePaymentMethodsItem from './AvailablePaymentMethodsItem'
import { getShopIdFromCookie } from '../../layout/Header/TopBar/ShopIdTopBarBanner'

interface OwnProps {
    paymentMethods: string[]
    size?: 'large'
}
export type Props = React.HTMLAttributes<HTMLDivElement> & OwnProps

const Wrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'size',
})<Pick<OwnProps, 'size'>>`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 15px;

    @media ${minWidth('md')} {
        gap: ${(props) => (props.size === 'large' ? '30px' : '15px')};
    }

    @media ${minWidth('lg')} {
        gap: ${(props) => (props.size === 'large' ? '50px' : '15px')};
    }
`

export const FooterPaymentMethodsComponent = ({
    paymentMethods,
    children,
    ...others
}: Props) => {
    const shopId = getShopIdFromCookie()

    return (
        <Wrapper {...others}>
            {children}
            {paymentMethods
                .filter(
                    (item) =>
                        !shopId ||
                        (shopId && !item.toLowerCase().includes('klarna')),
                )
                .map((paymentMethodImage) => (
                    <AvailablePaymentMethodsItem
                        key={paymentMethodImage}
                        paymentMethodImage={paymentMethodImage}
                        css={css`
                            height: ${others.size === 'large'
                                ? '25px'
                                : '20px'};
                        `}
                    />
                ))}
        </Wrapper>
    )
}

export default FooterPaymentMethodsComponent
