import { ComponentProps } from 'react'

import BannerBlocks, { BannerBlocksItem } from './BannerBlocks'
import {
    CastironPageBodyBannerBlocks,
    JbfoPageBodyBannerBlocks,
    JustbrandsPageBodyBannerBlocks,
    MonstersalePageBodyBannerBlocks,
    PageBodyBannerBlocks,
    PageBodyBannerBlocksFields,
    PageBodyBannerBlocksPrimary,
    PmeOutletPageBodyBannerBlocks,
    StorelocatorPageBodyBannerBlocks,
    StorePageBodyBannerBlocks,
    VanguardPageBodyBannerBlocks,
} from './graphql/schema.generated'
import ButtonVariant from './types/ButtonVariant'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceBannerBlocks =
    | PageBodyBannerBlocks
    | CastironPageBodyBannerBlocks
    | JustbrandsPageBodyBannerBlocks
    | VanguardPageBodyBannerBlocks
    | JbfoPageBodyBannerBlocks
    | MonstersalePageBodyBannerBlocks
    | PmeOutletPageBodyBannerBlocks
    | StorelocatorPageBodyBannerBlocks
    | StorePageBodyBannerBlocks

interface Props {
    slice: PrismicSliceBannerBlocks
}

const PrismicSliceBannerBlocksFour = ({
    slice: { primary, fields },
}: Props) => {
    if (!primary) {
        return null
    }

    const { title, style, buttonStyle } =
        primary as unknown as PageBodyBannerBlocksPrimary & {
            buttonStyle?: string
        }

    const bannerBlocks: BannerBlocksItem[] = (
        (fields as PageBodyBannerBlocksFields[]) ?? []
    ).reduce<BannerBlocksItem[]>((acc, field) => {
        const { title, image, url, mobileImage } = field

        acc.push({
            title: title ?? undefined,
            url: url ?? undefined,
            image: image?.url
                ? {
                      url: image.url,
                      alt: image.alt ?? title ?? '',
                  }
                : undefined,
            mobileImage: mobileImage?.url
                ? {
                      url: mobileImage.url,
                      alt: mobileImage.alt ?? title ?? '',
                  }
                : undefined,
        })
        return acc
    }, [])

    const componentProps: ComponentProps<typeof BannerBlocks> = {
        style: getLayoutStyle(style),
        title: title ?? '',
        bannerBlocks,
        buttonVariant:
            buttonStyle === 'highlight'
                ? ButtonVariant.highlight
                : ButtonVariant.primary,
        amount: 4,
    }

    return <BannerBlocks {...componentProps} />
}

export default PrismicSliceBannerBlocksFour
