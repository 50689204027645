import { useLoginTokenContext } from '@emico-hooks/login-token'

import { getHighStreetToken } from './actions/user/utils'

const useHighstreetToken = (): void => {
    const highStreetToken = getHighStreetToken()
    const tokenContext = useLoginTokenContext()

    if (highStreetToken) {
        tokenContext(highStreetToken)
    }
}

export default useHighstreetToken
