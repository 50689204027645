import cx from 'classnames'
import * as React from 'react'

import styles from './Label.module.scss'

interface OwnProps {
    field?: string
    disabled?: boolean
    className?: string
}

type Props = OwnProps & React.HTMLAttributes<Omit<HTMLLabelElement, 'htmlFor'>>

const Label = ({ className, disabled, field, ...otherProps }: Props) => (
    <label
        {...otherProps}
        className={cx(styles.base, { [styles.disabled]: disabled }, className)}
        htmlFor={field}
    />
)

export default Label
