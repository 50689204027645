import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
    GooglePlace,
    Maybe,
    Query,
    QueryGooglePlaceDetailsArgs,
} from './graphql/schema.generated'

// location defaults to a place in west germany, with a radius of 2500 km
// This allows us to narrow down the search results
const query = gql`
    query GooglePlace($placeId: ID!, $language: String!) {
        googlePlaceDetails(placeId: $placeId, language: $language) {
            name
            formattedAddress
            geometry {
                location {
                    lat
                    lng
                }
            }
        }
    }
`

type CustomGooglePlace = Pick<
    GooglePlace,
    'name' | 'formattedAddress' | 'geometry'
>

export type GooglePlaceLocation = Override<
    Pick<Query, 'googlePlaceDetails'>,
    {
        googlePlaceDetails: Maybe<CustomGooglePlace>
    }
>

const useLazyGoogleMapsPlaceLocation = (
    options?: LazyQueryHookOptions<
        GooglePlaceLocation,
        QueryGooglePlaceDetailsArgs
    >,
) => {
    // eslint-disable-next-line no-restricted-syntax
    const [getLocation, { data, error, loading }] = useLazyQuery<
        GooglePlaceLocation,
        QueryGooglePlaceDetailsArgs
    >(query, {
        fetchPolicy: 'network-only',
        ...options,
        context: getCacheableContext(),
    })

    if (error) {
        throw error
    }

    return {
        data: data?.googlePlaceDetails,
        loading,
        getLocation,
    }
}

export default useLazyGoogleMapsPlaceLocation
