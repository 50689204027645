import cx from 'classnames'
import * as React from 'react'

import styles from './Carousel.module.scss'
import Carousel from '../behaviour/Carousel'

interface CarouselSettings {
    adaptiveHeight?: boolean
    afterChange?(currentSlide: number): void
    arrows?: boolean
    cssEase?: string
    currentIndex?: number
    dots?: boolean
    fade?: boolean
    infinite?: boolean
    slidesToShow?: number
    slidesToShowLg?: number
    slidesToShowMd?: number
    slidesToShowSm?: number
    speed?: number
    variableWidth?: boolean
    vertical?: boolean
}

interface Props {
    children: React.ReactNode
    settings?: CarouselSettings
    className?: string
}

export const DEFAULT_SETTINGS = {
    arrows: true,
    infinite: true,
    adaptiveHeight: true,
    variableWidth: true,
    speed: 300,
    cssEase: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
    slidesToShow: 2.3,
    slidesToShowSm: 2.3,
    slidesToShowMd: 2.3,
    slidesToShowLg: 1.1,
}

const TweakwiseRecommendationsCarousel = ({
    children,
    settings,
    className,
}: Props) => (
    <Carousel
        settings={settings ? settings : DEFAULT_SETTINGS}
        className={cx(styles.carousel, className)}
    >
        {children}
    </Carousel>
)

export default TweakwiseRecommendationsCarousel
