import HeadingElement from './types/HeadingElement'

export const mapElement = (element: string | null) => {
    switch (element) {
        case 'h1':
            return HeadingElement.h1
        case 'h2':
            return HeadingElement.h2
        case 'h3':
            return HeadingElement.h3
        default:
            return undefined
    }
}
