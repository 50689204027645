import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxThunk from 'redux-thunk'

import RootReducer from '../reducers'
import { Services } from '../types'

export default function configureStore(services: Services) {
    const store = createStore(
        RootReducer,
        window.preloadedReduxState,
        composeWithDevTools(
            applyMiddleware(reduxThunk.withExtraArgument(services)),
        ),
    )

    // Cleanup prerendered redux state
    delete window.preloadedReduxState

    return store
}
