import { MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

import { getMessageDescriptor } from '@emico/i18n'

import { ConfigurableAttributesFieldValue } from '../ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../ProductPage/ConfigurableProduct'
import { Product } from '../ProductPage/GetProduct.query'
import { decodeAttributeValueObject } from '../ProductPage/StickyCta/flattenCombinedAttributes'

export const selectAnOption: { [key: string]: MessageDescriptor } = {
    fit: defineMessage({
        id: 'catalog.productPage.selectAnOption.fit',
        message: `Select a fit`,
    }),
    color: defineMessage({
        id: 'catalog.productPage.selectAnOption.color',
        message: `Select a color`,
    }),
    size: defineMessage({
        id: 'catalog.productPage.selectAnOption.size',
        message: `Select a size`,
    }),
    length: defineMessage({
        id: 'catalog.productPage.selectAnOption.length',
        message: `Select a length`,
    }),
}
export const getMissingConfigurableOptions = (
    configurableOptions: ConfigurableProduct['configurableOptions'],
    values: ConfigurableAttributesFieldValue,
) =>
    configurableOptions.filter(
        (configurableOption) =>
            !Object.keys(values).some(
                (optionId: string) =>
                    String(optionId) === configurableOption.attributeId &&
                    values[Number(optionId)] !== undefined,
            ),
    )

export const validateForm =
    (product: Product | ConfigurableProduct) =>
    (values: string): string | undefined => {
        const configurableOptions = (product as ConfigurableProduct)
            .configurableOptions

        if (!configurableOptions) {
            return undefined
        }

        const missingOptions = getMissingConfigurableOptions(
            configurableOptions,
            typeof values === 'string'
                ? decodeAttributeValueObject(values ?? '')
                : values ?? {},
        )

        const firstMissingOption = missingOptions[0]

        if (firstMissingOption) {
            const messageDescriptor = getMessageDescriptor(
                selectAnOption,
                firstMissingOption.label,
            )
            const missingOptionLabel = firstMissingOption.label
            const fallback = defineMessage({
                id: 'catalog.productPage.selectAnOptionFallback',
                message: `Select a ${missingOptionLabel}`,
            })

            return i18n._(messageDescriptor ? messageDescriptor : fallback)
        }
        return undefined
    }
