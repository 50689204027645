import { useReactiveVar } from '@apollo/client'
import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Plural, Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import { currencyCode } from '@emico/currency-code'
import { CurrencyEnum } from '@emico/graphql-schema-types'
import { minWidth } from '@emico/styles'

import styles from './FilterBarDesktop.module.scss'
import FilterButton from './FilterButton'
import Panel from './Panel'
import { TweakwiseFilterInput } from '../../../../../chunks'
import CloseIcon from '../../../../../core/CloseIcon'
import {
    TweakwiseNavigationAttribute,
    TweakwiseNavigationFacet,
    TweakwiseNavigationPropertiesSortField,
} from '../../../../../graphql/schema.generated'
import Button from '../../../../../input/Button'
import Select from '../../../../../input/Select/Select'
import Container from '../../../../../layout/Container'
import { headerSizeVar } from '../../../../../layout/Header/HeaderContainer'
import HeightTransition from '../../../../../layout/HeightTransition/HeightTransition'
import Icon from '../../../../../media/Icon'
import Text from '../../../../../typography/Text'
import { FilterValue, FilterValues } from '../../ProductFilterPage'
import { isColorFilter } from '../CheckboxFilter'
import FilterButtons from '../FilterButtons'
import { isJeansFilter } from '../JeansFilter'

interface Props extends React.RefAttributes<HTMLDivElement> {
    sortFields: TweakwiseNavigationPropertiesSortField[]
    options: TweakwiseNavigationFacet[]
    values: FilterValues
    setValue(key: string, value: FilterValue): void
    clear(): void
    sortKey: string | undefined
    setSortKey(sortKey: string | undefined): void
    numResults: number
    loading: boolean
}

const SelectedFiltersBar = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-flow: row wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    max
`

const SelectedFilterIcon = styled(Icon)`
    margin-left: 10px;
    width: 0.8em;
    height: 0.8em;
`

const ButtonWrapper = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-flow: row wrap;

    @media ${minWidth('md')} {
        justify-content: center;
    }
`

const SortSelect = styled(Select)`
    width: auto;
`

const sortDropDown = css`
    border: none !important;
    background: transparent;
    font-size: 1em;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: lowercase;
`

const SortContainer = styled(Container)`
    display: flex;
    flex-flow: row no-wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
`

const FilterBar = ({
    filters,
    values,
    activeFilter,
    setActiveFilter,
    onToggle,
    isToggled,
}: {
    filters: TweakwiseNavigationFacet[]
    values: FilterValues
    activeFilter: string | undefined
    setActiveFilter(activeFilter: string | undefined): void
    onToggle?(): void
    isToggled?: boolean
}) => (
    <Container>
        <ButtonWrapper>
            {filters.map((filter) => {
                const isActive = activeFilter === filter.facetSettings.urlKey

                const hasValue =
                    values[filter.facetSettings.urlKey] !== undefined

                return (
                    <FilterButton
                        variant="link"
                        key={filter.facetSettings.urlKey}
                        isActive={isActive}
                        hasValue={hasValue}
                        onClick={() =>
                            setActiveFilter(
                                !isActive
                                    ? filter.facetSettings.urlKey
                                    : undefined,
                            )
                        }
                        className={cx(styles.button, {
                            [styles.buttonInactive]: activeFilter && !isActive,
                            [styles.buttonHasValue]: hasValue,
                        })}
                        name={filter.facetSettings.urlKey}
                        category="catalog.productFilterPage.filterBar.filterToggle"
                    >
                        {filter.facetSettings.title ||
                            filter.facetSettings.urlKey}
                    </FilterButton>
                )
            })}
            {onToggle && (
                <Button
                    className={cx(styles.button, styles.toggle)}
                    onClick={onToggle}
                    name="show non-essential filters"
                    category="catalog.productFilterPage.filterBar"
                    variant="link"
                >
                    {isToggled ? (
                        <Trans id="catalog.filterBarDesktop.showLessFilters">
                            Less filters
                        </Trans>
                    ) : (
                        <Trans id="catalog.filterBarDesktop.showMoreFilters">
                            More filters
                        </Trans>
                    )}
                </Button>
            )}
        </ButtonWrapper>
    </Container>
)

export function formatFilterPrice(
    value: number,
    currency: CurrencyEnum | undefined,
) {
    return new Intl.NumberFormat(i18n.locale, {
        style: 'currency',
        currency: currency ?? 'EUR',
    })
        .formatToParts(Number(value) ?? 0)
        .filter((item) => item.type !== 'decimal' && item.type !== 'fraction')
        .map((item) => item.value)
        .join('')
}

const SelectedFilter = ({
    children,
    onClick,
}: {
    children: string
    onClick(): void
}) => (
    <Button
        onClick={onClick}
        variant="activeFilter"
        name={children}
        category="catalog.filterBarDesktop.selectedFilter"
        size="medium"
        state={{ scrollRestoration: false }}
    >
        {children}
        <SelectedFilterIcon component={CloseIcon} title="Remove filter" />
    </Button>
)

const SelectedFilters = ({
    filters,
    values,
    setValue,
    children,
}: {
    filters: TweakwiseNavigationFacet[]
    values: FilterValues
    setValue(key: string, value: FilterValue): void
    children?: React.ReactNode
}) => {
    const filtersWithSelectedValues = filters.reduce<
        TweakwiseNavigationFacet[]
    >((acc, filter) => {
        const selectedValues = filter.attributes.filter(
            (attr) => attr.isSelected,
        )

        if (selectedValues.length > 0) {
            acc.push({ ...filter, attributes: selectedValues })
        }

        return acc
    }, [])

    const currency = useReactiveVar(currencyCode)

    if (filtersWithSelectedValues.length === 0) {
        return null
    }

    return (
        <SelectedFiltersBar>
            {filtersWithSelectedValues.map((filter) => {
                const selectedValues = filter.attributes

                const handleClick =
                    (selectedValue: TweakwiseNavigationAttribute) => () => {
                        const filterValue =
                            values[filter.facetSettings.urlKey] ?? []

                        const newValues = filterValue.filter(
                            (value) => value !== selectedValue.title,
                        )

                        setValue(filter.facetSettings.urlKey, newValues)
                    }

                switch (filter.facetSettings.selectionType) {
                    case 'checkbox':
                        if (isJeansFilter(filter)) {
                            return selectedValues.map(
                                (selectedValue, index) => {
                                    const [width, length] =
                                        selectedValue.title.split('-')

                                    return (
                                        <SelectedFilter
                                            key={`jeans${index}`}
                                            onClick={handleClick(selectedValue)}
                                        >
                                            {`W${width}-L${length}`}
                                        </SelectedFilter>
                                    )
                                },
                            )
                        }
                        if (isColorFilter(filter)) {
                            return selectedValues.map(
                                (selectedValue, index) => {
                                    const [
                                        name,
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        hexValue,
                                    ] = selectedValue.title.split('_')

                                    return (
                                        <SelectedFilter
                                            key={`color${index}`}
                                            onClick={handleClick(selectedValue)}
                                        >
                                            {name}
                                        </SelectedFilter>
                                    )
                                },
                            )
                        }
                        return selectedValues.map((selectedValue, index) => (
                            <SelectedFilter
                                key={`cb${index}`}
                                onClick={handleClick(selectedValue)}
                            >
                                {selectedValue.title}
                            </SelectedFilter>
                        ))
                    case 'slider': {
                        const priceFilterValues = filter.attributes.filter(
                            (attribute) => attribute.isSelected,
                        )
                        const min = priceFilterValues?.[0].title
                        const max = priceFilterValues?.[1].title

                        const minPrice = formatFilterPrice(
                            Number(min ?? 0),
                            currency as CurrencyEnum,
                        )

                        const maxPrice = formatFilterPrice(
                            Number(max ?? 0),
                            currency as CurrencyEnum,
                        )

                        return (
                            <SelectedFilter
                                key="pricefilter"
                                onClick={() =>
                                    setValue(filter.facetSettings.urlKey, [])
                                }
                            >
                                {`${minPrice} - ${maxPrice}`}
                            </SelectedFilter>
                        )
                    }
                    default:
                        return null
                }
            })}
            {children}
        </SelectedFiltersBar>
    )
}

const FilterBarDesktop = React.forwardRef<HTMLDivElement, Props>(
    function FilterBarDes(
        {
            options,
            values,
            setValue,
            sortKey,
            setSortKey,
            clear,
            sortFields,
            numResults,
            loading,
        }: Props,
        ref,
    ) {
        const [showNonEssential, setNonEssentialFiltersVisibility] =
            React.useState<boolean>(false)
        const headerSize = useReactiveVar(headerSizeVar)
        const [activeFilter, setActiveFilter] = React.useState<
            string | undefined
        >(undefined)

        if (!options.length) {
            return null
        }

        const isDirty = Object.keys(values).length > 0

        // Sometimes, when a user activates a filter and quickly
        // tries to activate another filter, that 2nd filter could
        // become unavailable because of the previous selection.
        const filter = options.find(
            ({ facetSettings: { urlKey } }) => urlKey === activeFilter,
        )

        const essentialFilters = [...options].slice(0, 5)
        const nonEssentialFilters = [...options].slice(5)
        const hasSort = sortFields.length > 0

        return (
            <>
                <div ref={ref} />
                <div
                    className={styles.container}
                    data-id="filterBar"
                    onMouseLeave={() => setActiveFilter(undefined)}
                    style={{
                        top: headerSize?.height ?? 0,
                    }}
                >
                    <FilterBar
                        filters={essentialFilters}
                        values={values}
                        setActiveFilter={setActiveFilter}
                        activeFilter={activeFilter}
                        onToggle={
                            nonEssentialFilters.length > 0
                                ? () =>
                                      setNonEssentialFiltersVisibility(
                                          !showNonEssential,
                                      )
                                : undefined
                        }
                        isToggled={showNonEssential}
                    />
                    {showNonEssential && (
                        <FilterBar
                            filters={nonEssentialFilters}
                            values={values}
                            setActiveFilter={setActiveFilter}
                            activeFilter={activeFilter}
                        />
                    )}
                    <SelectedFilters
                        filters={options}
                        setValue={setValue}
                        values={values}
                    />

                    <HeightTransition>
                        {activeFilter !== undefined && filter && (
                            <Panel className={styles.panel}>
                                <TweakwiseFilterInput
                                    filter={filter}
                                    value={values[activeFilter]}
                                    onChange={(value) =>
                                        setValue(activeFilter, value)
                                    }
                                />

                                <FilterButtons
                                    isDirty={isDirty}
                                    clear={clear}
                                    setActiveFilter={() =>
                                        setActiveFilter(undefined)
                                    }
                                    loading={loading}
                                    numResults={numResults}
                                />
                            </Panel>
                        )}
                    </HeightTransition>
                </div>

                {activeFilter !== undefined && (
                    <div
                        className={styles.overlay}
                        onClick={() => setActiveFilter(undefined)}
                    />
                )}

                {hasSort && (
                    <SortContainer
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text
                            color="grey"
                            css={css`
                                margin-right: auto;
                            `}
                        >
                            <Plural
                                id="catalog.productFilterPage.filterBar.resultCount"
                                value={numResults}
                                one="# result"
                                other="# results"
                            />
                        </Text>
                        <ClassNames>
                            {({ css }) => (
                                <>
                                    <Text
                                        color="grey"
                                        as="span"
                                        css={css`
                                            margin: 0;
                                        `}
                                    >
                                        <Trans id="catalog.productFilterPage.filterBar.sortLabel">
                                            Sort by:
                                        </Trans>
                                    </Text>
                                    <SortSelect
                                        inputClassName={css`
                                            ${sortDropDown}
                                        `}
                                        onChange={(event) =>
                                            setSortKey(event.target.value)
                                        }
                                        value={sortKey || sortFields[0].title}
                                    >
                                        {sortFields.map(
                                            ({ title, displayTitle }) => (
                                                <option
                                                    key={title}
                                                    value={title}
                                                >
                                                    {displayTitle}
                                                </option>
                                            ),
                                        )}
                                    </SortSelect>
                                </>
                            )}
                        </ClassNames>
                    </SortContainer>
                )}
            </>
        )
    },
)

export default FilterBarDesktop
