import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useButton } from '@react-aria/button'
import { AriaSelectOptions, HiddenSelect, useSelect } from '@react-aria/select'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useSelectState } from '@react-stately/select'
import { SelectProps } from '@react-types/select'
import { ComponentProps, useEffect, useRef, useState } from 'react'

import { ChevronDownIcon } from '@emico/icons'

import ErrorIndicator from './ErrorIndicator'
import InputIndicatorTransition from './InputIndicatorTransition'
import PopperPopover from './PopperPopover'
import PopperSelectListBox from './PopperSelectListBox'
import theme from '../theme'

const Wrapper = styled('div', {
    shouldForwardProp: (prop) => isPropValid(prop),
})<{ horizontalLayout?: boolean }>`
    position: relative;
    display: ${(props) =>
        props.horizontalLayout ? 'inline-flex' : 'inline-block'};
    gap: 10px;
    align-items: center;
`

const ErrorMessageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
`

const TriggerButton = styled.button`
    width: 100%;
    background: ${theme.colors.white};
    color: ${theme.colors.onBackground};
    font-family: var(--popper-select-font, ${theme.fonts.secondary});
    font-weight: ${theme.fontWeights.bold};
    padding: 12px 16px 10px 16px;
    text-align: left;
    line-height: 16px;
    text-transform: uppercase;
    border: solid thin ${theme.colors.border};
    border-radius: 3px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 10px;

    :focus {
        outline: solid 2px ${theme.colors.grayDark};
        outline-offset: -1px;
    }
`

const ValueLabel = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Arrow = styled(ChevronDownIcon, {
    shouldForwardProp: (p) => p !== 'open',
})<{ open: boolean }>`
    transition: transform 0.2s ease;
    transform: rotate(${(p) => (p.open ? '180deg' : '0deg')});

    @media (prefers-reduced-motion) {
        transition: none;
    }
`

// eslint-disable-next-line @typescript-eslint/ban-types
export type Props<T extends object> = SelectProps<T> &
    AriaSelectOptions<T> & {
        className?: string
        defaultPopperPosition?: ComponentProps<
            typeof PopperPopover
        >['defaultPopperPosition']
        labelIsVisuallyHidden?: boolean
        horizontalLayout?: boolean
        selectAnOptionLabel?: React.ReactNode
    }

// eslint-disable-next-line @typescript-eslint/ban-types
function PopperSelect<T extends object>(props: Props<T>) {
    const state = useSelectState<T>(props)
    const [isOpenDelayed, setIsOpenDelayed] = useState(false)

    const ref = useRef<HTMLButtonElement>(null)
    const {
        labelProps,
        triggerProps,
        errorMessageProps,
        valueProps,
        menuProps,
    } = useSelect<T>(props, state, ref)

    // Get props for the button based on the trigger props from useSelect
    const { buttonProps } = useButton(triggerProps, ref)

    const items = menuProps.items as Iterable<T> | undefined

    useEffect(() => {
        const delay = setTimeout(() => setIsOpenDelayed(state.isOpen), 50)

        return () => clearTimeout(delay)
    }, [state.isOpen])

    return (
        <Wrapper
            className={props.className}
            horizontalLayout={props.horizontalLayout}
        >
            {props.labelIsVisuallyHidden ? (
                <VisuallyHidden>
                    <div {...labelProps}>{props.label}</div>
                </VisuallyHidden>
            ) : (
                <div {...labelProps}>{props.label}</div>
            )}
            <HiddenSelect
                state={state}
                triggerRef={ref}
                label={props.label}
                name={props.name}
            />
            <TriggerButton {...buttonProps} ref={ref}>
                <ValueLabel {...valueProps}>
                    {state.selectedItem
                        ? state.selectedItem.rendered
                        : props.selectAnOptionLabel
                          ? props.selectAnOptionLabel
                          : t({
                                id: 'popperSelect.selectAnOption',
                                message: `Select an option`,
                            })}
                </ValueLabel>
                <Arrow aria-hidden="true" open={isOpenDelayed} />
            </TriggerButton>
            {isOpenDelayed && (
                <PopperPopover
                    isOpen={isOpenDelayed}
                    onClose={state.close}
                    triggerRef={ref}
                    defaultPopperPosition={props.defaultPopperPosition}
                >
                    <PopperSelectListBox
                        {...menuProps}
                        items={items}
                        state={state}
                    />
                </PopperPopover>
            )}
            <ErrorMessageWrapper>
                {props.errorMessage && (
                    <InputIndicatorTransition>
                        <ErrorIndicator
                            field={props.name ?? ''}
                            {...errorMessageProps}
                        >
                            {props.errorMessage}
                        </ErrorIndicator>
                    </InputIndicatorTransition>
                )}
            </ErrorMessageWrapper>
        </Wrapper>
    )
}

export default PopperSelect
