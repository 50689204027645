import * as React from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import { PERSONAL_SHOPPER_ENABLED } from './constants'
import usePrismicPersonalShopper from './usePrismicPersonalShopper'

const PersonalShopperEnabled = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const isPersonalShopperEnabled = usePersonalShopperEnabled()

    if (!isPersonalShopperEnabled) {
        return null
    }

    return <>{children}</>
}

export const usePersonalShopperEnabled = (): boolean | undefined => {
    const { data: prismicData, loading } = usePrismicPersonalShopper()
    const { code } = useActiveStoreView()
    const { mobileOrderImage, orderImage, orderButtonLink } = prismicData || {}

    if (loading) {
        return undefined
    }

    if (
        PERSONAL_SHOPPER_ENABLED &&
        [
            'pme_en',
            'pme_nl',
            'pme_be',
            'pme_be_fr',
            'pme_de',
            'justbrands_nl',
        ].includes(code) &&
        (mobileOrderImage?.url || orderImage?.url) &&
        orderButtonLink
    ) {
        return true
    }

    return false
}

export default PersonalShopperEnabled
