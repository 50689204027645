import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { t } from '@lingui/macro'
import {
    ReactNode,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Control, useForm } from 'react-hook-form'

import styles from './gift.module.scss'
import { SelectionHandle } from './types'
import { useUpdateCart } from './useUpdateCart'
import {
    CartItemConfigurableConfiguration,
    CartItemConfigurableFormValue,
} from '../cart/CartItemConfiguration/CartItemConfigurableConfiguration'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import {
    decodeAttributeValueObject,
    encodeAttributeValueObject,
} from '../catalog/ProductPage/StickyCta/flattenCombinedAttributes'
import Loader from '../presentation/Loader'

interface Props {
    cartItem?: CartItemFragment
    product: Product | ConfigurableProduct
    maxItemsCount?: number
    selectedCount?: number
    disabled?: boolean
    onChange?: (option: ConfigurableAttributesFieldValue) => void
    editMode?: boolean
}

// eslint-disable-next-line react/display-name
export const FreeGiftSizeSelector = forwardRef<SelectionHandle, Props>(
    ({ cartItem, product, disabled, onChange, editMode }: Props, ref) => {
        const [updatingCart, setUpdatingCart] = useState<boolean>(false)
        const [errorMessage, setErrorMessage] = useState<ReactNode>(null)

        const { updateCart } = useUpdateCart()

        const optionsMap = new Map()
        const selectedAttributeObject: Record<string, string> = useMemo(
            () => ({}),
            [],
        )
        if ('variants' in product) {
            product.configurableOptions.forEach((item) =>
                optionsMap.set(item.attributeCode, item.attributeId),
            )
            const variant = product.variants.find(
                (item) => item.product?.id === cartItem?.product?.id,
            )

            for (const attribute of variant?.attributes ?? []) {
                selectedAttributeObject[
                    String(optionsMap.get(attribute.code))
                ] = attribute.uid
            }
        }

        const getAttributeIdByCode = (code: string) => {
            if ('variants' in product) {
                return product.configurableOptions.find(
                    (opt) => opt.attributeCode === code,
                )?.attributeId
            }
            return undefined
        }

        const attributeValue = encodeAttributeValueObject(
            cartItem?.configurableOptions?.reduce(
                (memo, curr) => ({
                    ...memo,
                    [getAttributeIdByCode(curr.optionLabel) ?? '']:
                        curr.configurableProductOptionValueUid,
                }),
                {},
            ) ?? {},
        )

        const { handleSubmit, control, setValue, watch } = useForm<
            CartItemConfigurableFormValue | { giftSelected: boolean }
        >({
            defaultValues: {
                value: attributeValue,
            },
        })

        const optionValue = watch('value')

        const onChangeRef = useRef(onChange)

        useEffect(() => {
            if (onChangeRef.current) {
                onChangeRef.current(decodeAttributeValueObject(optionValue))
            }
        }, [optionValue])

        useEffect(() => {
            setErrorMessage('')
        }, [optionValue, disabled])

        useEffect(() => {
            setValue('value', attributeValue)
        }, [attributeValue, setValue])

        const updateGiftItem = async (
            options?: ConfigurableAttributesFieldValue,
        ) => {
            await updateCart([
                {
                    product,
                    options,
                },
            ])
        }

        useImperativeHandle(ref, () => ({
            deselect() {
                setValue('value', '')
            },
            select() {
                setErrorMessage(t({ id: 'cart.gifts.selectSizeErrorMessage' }))
            },
        }))

        return (
            <>
                <form
                    className={styles.sizeSelector}
                    onSubmit={handleSubmit(() => {
                        // no-op, updates are handled on-change
                    })}
                >
                    {updatingCart ? (
                        <div>
                            <Loader className={styles.loader} />
                        </div>
                    ) : (
                        <CartItemConfigurableConfiguration
                            horizontalLayout={editMode}
                            product={product}
                            control={
                                control as Control<
                                    CartItemConfigurableFormValue,
                                    'value'
                                >
                            }
                            onChange={async (value) => {
                                if (!editMode) {
                                    setUpdatingCart(true)
                                    await updateGiftItem(value)
                                    setUpdatingCart(false)
                                }
                            }}
                            isDisabled={updatingCart || disabled}
                            className={
                                !editMode && !cartItem ? styles.hide : ''
                            }
                        />
                    )}
                    <div className={styles.error}>{errorMessage}</div>
                </form>
            </>
        )
    },
)
