import { Image } from '@emico-react/image'
import cx from 'classnames'
import * as React from 'react'

import { ImaginaryActions } from '@emico/ui'

import styles from './ImageLazy.module.scss'
import { ImagesType } from '../../types'

export interface Props {
    /**
     * When true the image will be loaded only after it becomes visible in the viewport. Only use on pages with a lot of images for usability reasons.
     */
    lazy?: boolean
    alt: string
    /**
     * @deprecated Use url, width and height attributes. For responsive images use the sizes attribute
     * and for art direction use the Picture component from emico-react/image.
     */
    images?: ImagesType
    className?: string
    /**
     * @deprecated Use className instead, both will be used on the same element.
     */
    imageClassName?: string
    sizes?: React.ComponentProps<typeof Image>['sizes']
    params?: React.ComponentProps<typeof Image>['params']
    url?: string
    width?: number
    height?: number
    originalWidth?: number
    originalHeight?: number
    /**
     * @deprecated Use params instead to pass params to ImgProxy. E.g.
     * `{ crop: [100, 100], gravity: 'ne:10:10' }` or `{ resizingType: 'fill' }`
     */
    action?: ImaginaryActions
}

const ImageLazy = ({
    lazy = true,
    alt,
    images,
    className,
    imageClassName,
    width = images?.regularWidth,
    height = images?.regularHeight,
    url = images?.image,
    sizes,
    action,
    params: _p = {},
    originalHeight,
    originalWidth,
}: Props) => {
    const classes = cx(styles.imageLazy, className, imageClassName)
    const params: React.ComponentProps<typeof Image>['params'] = { ..._p }

    if (action === ImaginaryActions.CROP && width && height) {
        params.crop = [width, height]
        params.gravity = 'ce:0:0'
    }

    if (action === ImaginaryActions.SMARTCROP && width && height) {
        params.crop = [width, height]
        params.gravity = 'sm'
    }

    return (
        <Image
            alt={alt}
            lazy={lazy}
            url={url ?? ''}
            width={width}
            height={height}
            className={classes}
            sizes={sizes}
            params={params}
            originalHeight={originalHeight}
            originalWidth={originalWidth}
        />
    )
}

export default ImageLazy
