import { ApolloClient, ApolloError } from '@apollo/client'

import {
    resolveUrl as genericResolveUrl,
    storeResolvedUrl as genericStoreResolvedUrl,
    useResolveUrl as genericUseResolveUrl,
} from '@emico/link'

import withSentry from './withSentry'
import { UrlRewriteEntityTypeEnum } from '../graphql/schema.generated'

export const normalizeUrl = (url: string): string => {
    url = url.replace('.html', '')
    return url === '/' ? '/homepage' : url
}

export const useResolveUrl = (urlKey: string) => {
    const { error, data, loading } = genericUseResolveUrl(normalizeUrl(urlKey))

    if (
        error &&
        error?.graphQLErrors?.[0]?.extensions?.category !==
            'graphql-no-such-entity'
    ) {
        // Log errors that are not known errors
        withSentry((Sentry) => {
            Sentry.captureException(error)
        })
    }

    return {
        error,
        loading,
        data,
    }
}

export const resolveUrl =
    (client: ApolloClient<unknown>) => async (url: string) => {
        try {
            const result = await genericResolveUrl(client)(normalizeUrl(url))

            // Await the function so we can check for errors with try - catch

            return result
        } catch (err) {
            // Mosty likely, the url wasn't found
            if (
                'graphQLErrors' in (err as ApolloError) &&
                (err as ApolloError).graphQLErrors?.[0]?.extensions
                    ?.category === 'graphql-no-such-entity'
            ) {
                // Don't log urls that are not found to Sentry
                return
            }
            // All other errors are unexpected and should be logged to Sentry
            withSentry((Sentry) => {
                Sentry.captureException(err)
            })

            return
        }
    }

export const storeResolvedUrl =
    (client: ApolloClient<unknown>) =>
    async (
        url: string,
        type: UrlRewriteEntityTypeEnum,
        redirectCode: number,
        prismicType?: string,
        authRequired?: boolean,
        id?: number,
        productTypeName?: string,
        productId?: number,
    ) =>
        genericStoreResolvedUrl(
            client,
            normalizeUrl(url),
            type,
            redirectCode,
            prismicType,
            authRequired,
            id,
            productTypeName,
            productId,
        )
