import { PRIVILEGES_ENABLED } from './constants'
import CheckIcon from './core/CheckIcon'
import { useSrsVouchersSalesRule } from './customer/srsVouchers.query'
import { useUnclaimedVouchersCount } from './vouchers/customerVouchers.query'
import styles from './VouchersBadge.module.scss'

export const VouchersBadge = () => {
    const count = useUnclaimedVouchersCount()
    const { vouchers } = useSrsVouchersSalesRule()
    const hasVouchers = vouchers.length + (count || 0) > 0

    if (!PRIVILEGES_ENABLED || !hasVouchers) {
        return null
    }

    return (
        <span className={styles.badge}>
            <CheckIcon className={styles.icon} />
        </span>
    )
}
