import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'

import { globalWindow } from '@emico/ssr-utils'
import { theme } from '@emico/styles'

import ErrorBoundary from './ErrorBoundary'
import FatalErrorPage from './FatalErrorPage'

interface HandledError {
    message: string
    stack?: string
    filename?: string
}

/**
 * Some errors are triggered by third party scripts, such as browser
 * plug-ins. These errors should generally not affect the
 * application, so we can safely ignore them for our error handling.
 *
 * NOTE: This only works if they did not set CORS headers. If they did
 * set CORS headers, the message will be more informative :(
 * If a browser plug-in like Google Translate messes with the DOM
 * and that breaks the app, that triggers a different error so
 * those third party issues are still handled.
 */
const isTriggeredByExternalScript = (error: HandledError) => {
    if (!error || error.message === 'Script error.') {
        return true
    }

    // Go through the stack trace, if the last call was triggered by an
    // external script it shouldn't affect the rest of the app.
    if (!error.stack) {
        // tslint:disable-next-line:no-console
        console.error('Error has no stack trace!')
        return true
    }
    const lines = error.stack
        .split('\n')
        // Ignore the first line because it usually contains info not
        // actually a part of the stack trace and it may include a link to
        // the page on which the error occured, rather than the script that
        // caused it. e.g. Safari on iOS/OS X
        .splice(1)

    if (lines.length === 0) {
        // tslint:disable-next-line:no-console
        console.error('Error has an empty stack trace!')
        return true
    }

    const sources = lines
        .map((line) => line.match(/(https?:\/\/[^/]+)\//))
        .filter((line) => line)
    const firstSource = sources[0]

    if (!firstSource) {
        return true
    }
    if (firstSource[1] !== globalWindow?.location.origin) {
        return true
    }

    const isGtm = error.stack.includes('www.googletagmanager.com/gtm.js?id=')

    return isGtm
}

const ToasterAppearAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(100%) scaleX(0);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0%) scaleX(1);
  }
`

const ErrorToaster = styled.div`
    position: fixed;
    bottom: 15%;
    left: 50%;
    margin-right: -50%; // this fixes auto-width on mobile: otherwise the container would consume no more than 50% of the width (due to left: 50%)
    transform: translateX(-50%);
    max-width: calc(100% - ${theme.spacing.x4}px);
    z-index: ${theme.zIndex.errorToaster};
    border-radius: 3px;
    background: ${theme.dangerColorDark};
    color: #fff;
    padding: ${theme.spacing.x2}px;
    font-size: ${theme.fontSize};
    animation: ${ToasterAppearAnimation} 200ms ease-out forwards;
`

const StyledA = styled.a`
    &,
    &:visited {
        color: inherit;
        text-decoration: underline;
    }
`

interface Props {
    children: ReactNode
    fallback?: React.ReactElement
    disableErrorEvents?: boolean
}

const HIDE_TOASTER_TIMEOUT = 8000 // ms

const RootErrorBoundary = ({
    children,
    fallback,
    disableErrorEvents = false,
}: Props) => {
    const [error, setError] = useState<Error | undefined>(undefined)
    const resetError = useCallback(() => setError(undefined), [setError])

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>
        const handleError = (error: Error) => {
            // The error is automatically caught by Sentry and the CRA error overlay
            // since we don't stop it from propagating, so no need to manually send it
            // to Sentry.

            if (isTriggeredByExternalScript(error)) {
                // This doesn't affect the main application, so don't bother the user.
                // TODO: We may want to make it possible to also ignore these errors in
                //  Sentry.
                // eslint-disable-next-line no-console
                console.debug(
                    'Not showing error toaster for error since it was triggered by an external script.',
                    error,
                )
                return
            }

            console.warn('Unhandled application error:', error)
            // Delay the state update by one frame to avoid the React error: "Cannot update a component while rendering a different component"
            setTimeout(() => {
                setError(error)
                // Auto close the toaster after a short duration
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(resetError, HIDE_TOASTER_TIMEOUT)
            }, 0)
        }

        const handleErrorEvent = (error: ErrorEvent) => {
            handleError(error.error)
        }

        if (disableErrorEvents === false) {
            globalWindow?.addEventListener('error', handleErrorEvent)
        }

        const handleUnhandledRejectionEvent = (
            error: PromiseRejectionEvent,
        ) => {
            handleError(error.reason)
        }

        globalWindow?.addEventListener(
            'unhandledrejection',
            handleUnhandledRejectionEvent,
        )
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
            if (disableErrorEvents === false) {
                globalWindow?.removeEventListener('error', handleErrorEvent)
            }

            globalWindow?.removeEventListener(
                'unhandledrejection',
                handleUnhandledRejectionEvent,
            )
        }
    }, [resetError, disableErrorEvents])

    // TODO: Reload page on navigation https://emico-commerce.atlassian.net/browse/NRC-1692

    const handleRefresh = (e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        globalWindow?.location.reload()
    }

    return (
        <>
            <ErrorBoundary fallback={fallback ?? <FatalErrorPage />}>
                {children}
            </ErrorBoundary>

            {error && (
                <ErrorToaster onClick={resetError} onContextMenu={resetError}>
                    <Trans id="unhandledAppError">
                        Something went wrong. Try again or{' '}
                        <StyledA
                            href={globalWindow?.location.href}
                            onClick={handleRefresh}
                        >
                            reload the app
                        </StyledA>
                        .
                    </Trans>
                </ErrorToaster>
            )}
        </>
    )
}

export default RootErrorBoundary
