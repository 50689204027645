import { useReactiveVar } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useRef, useState } from 'react'

import Button from './input/Button'
import MiniMenu, { MiniMenuContent, MiniMenuActions } from './MiniMenu'
import MiniWishlistItem from './MiniWishlistItem'
import paths from './paths'
import { CLOSE_TIME_CART_BUTTON } from './presentation/CartButton'
import { wishlistVar } from './WishlistActionButton'

interface Props {
    className?: string
}

const MiniWishlist = ({ className }: Props) => {
    const productId = wishlistVar()
    const ref = useRef<HTMLElement | null>(null)
    const wishlist = useReactiveVar(wishlistVar)
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>()

    /* eslint-disable react-hooks/exhaustive-deps */
    // We disable exhaustive-deps here because the code below
    // should not update when timer is changed.
    // A new timer should only be set when isActive becomes true (again)
    useEffect(() => {
        const stopTimer = () => {
            if (!timer) {
                return
            }
            clearTimeout(timer)
            setTimer(undefined)
        }

        if (wishlist) {
            setTimer(
                setTimeout(() => {
                    wishlistVar(false)
                }, CLOSE_TIME_CART_BUTTON),
            )
        } else {
            stopTimer()
        }
        return stopTimer
    }, [wishlist])

    const handleOutsideClick = useCallback(
        (e: Event) => {
            if (!ref.current?.contains(e.target as Node)) {
                wishlistVar(false)
            }
        },
        [ref.current, wishlistVar],
    )

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick)
        return () => document.removeEventListener('click', handleOutsideClick)
    })

    return (
        <MiniMenu
            ref={ref}
            className={className}
            heading={
                <Trans id="cart.miniWishlist.headingWishlist">
                    Added to wishlist
                </Trans>
            }
            onClose={() => {
                wishlistVar(false)
            }}
        >
            {productId && (
                <MiniMenuContent>
                    <MiniWishlistItem productId={productId} />
                </MiniMenuContent>
            )}
            <MiniMenuActions>
                <Button
                    to={paths.wishlist}
                    variant="secondary"
                    name="Wishlist"
                    category="cart.miniWishlist.viewWishlist"
                    data-testid="cart.miniWishlist.viewWishlist"
                    onClick={() => wishlistVar(false)}
                >
                    <Trans id="cart.miniWishlist.viewWishlistButton">
                        View wishlist
                    </Trans>
                </Button>
            </MiniMenuActions>
        </MiniMenu>
    )
}

export default MiniWishlist
