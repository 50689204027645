import styled from '@emotion/styled'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import Button from './input/Button'
import Container from './layout/Container'
import getLayoutStyleVars, { LayoutStyle } from './LayoutStyle'
import theme from './theme'
import ButtonVariant from './types/ButtonVariant'
import Heading from './typography/Heading'

export interface CategoryElementItem {
    title?: string
    url?: string
}

export interface Props {
    style: LayoutStyle
    heading: string
    items?: CategoryElementItem[]
}

const Section = styled.section`
    background: ${theme.colors.background};
    padding: 50px 0;
`

const HeadingWrapper = styled.div`
    text-align: center;
    margin-bottom: 20px;
`

const ButtonContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
`

export const CategoryElement = ({ style, heading, items }: Props) => {
    const layoutStyle = getLayoutStyleVars(style)
    const { isMobile } = useBreakpoints()

    if (!items || items.length === 0 || !isMobile) {
        return null
    }

    const visibleItems = items.filter(
        ({ title, url }) => title && url,
    ) as Array<Required<CategoryElementItem>>

    if (visibleItems.length === 0) {
        return null
    }

    const buttonColorByTheme: ButtonVariant =
        style === 'light' ? ButtonVariant.secondaryAlt : ButtonVariant.white

    return (
        <Section css={layoutStyle}>
            <Container>
                {heading && (
                    <HeadingWrapper>
                        <Heading variant="h2" element="h2">
                            {heading}
                        </Heading>
                    </HeadingWrapper>
                )}

                <ButtonContainer>
                    {visibleItems.map((item, index) => {
                        const { title, url } = item

                        return (
                            <Button
                                key={index}
                                to={url}
                                name={title}
                                category="categoryElement.button"
                                variant={buttonColorByTheme}
                            >
                                {title}
                            </Button>
                        )
                    })}
                </ButtonContainer>
            </Container>
        </Section>
    )
}

export default CategoryElement
