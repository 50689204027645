import { gql, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { ProductGroup } from '../../catalog/ProductPage/GetProduct.query'
import {
    Maybe,
    SizeGuide as GeneratedSizeGuide,
    SizeGuideRow as GeneratedSizeGuideRow,
    SizeGuideSizeType as GeneratedSizeGuideSizeType,
} from '../../graphql/schema.generated'

const query = gql`
    query GetSizeGuide($productType: String!) {
        sizeGuide(product_type: $productType) {
            guide {
                image
                note
                description
                sizeTypeOne {
                    title
                    titleShort
                    description
                }
                sizeTypeTwo {
                    title
                    titleShort
                    description
                }
                sizeTypeThree {
                    title
                    titleShort
                    description
                }
                sizeTable {
                    header
                    sizeOne
                    sizeTwo
                    sizeThree
                }
            }
        }
    }
`

export type SizeGuideRow = Pick<
    GeneratedSizeGuideRow,
    'header' | 'sizeOne' | 'sizeTwo' | 'sizeThree'
>

type SizeGuideSizeType = Pick<
    GeneratedSizeGuideSizeType,
    'title' | 'titleShort' | 'description'
>

type SizeGuide = Override<
    Pick<
        GeneratedSizeGuide,
        | 'description'
        | 'image'
        | 'note'
        | 'productType'
        | 'sizeTable'
        | 'sizeTypeTwo'
        | 'sizeTypeOne'
        | 'sizeTypeThree'
    >,
    {
        sizeTable: Maybe<Array<Maybe<SizeGuideRow>>>
        sizeTypeOne: SizeGuideSizeType
        sizeTypeTwo: Maybe<SizeGuideSizeType>
        sizeTypeThree: Maybe<SizeGuideSizeType>
    }
>

interface SizeGuideResult {
    guide: Maybe<SizeGuide>
}

interface GetSizeGuideData {
    sizeGuide: Maybe<SizeGuideResult>
}

interface GetSizeGuideVariables {
    productType: ProductGroup
}

export const useSizeGuide = (productGroup: ProductGroup) => {
    const { data, error, ...others } = useQuery<
        GetSizeGuideData,
        GetSizeGuideVariables
    >(query, {
        variables: {
            productType: productGroup,
        },
        context: getCacheableContext(),
    })

    if (error) {
        throw error
    }

    return {
        ...others,
        data: data?.sizeGuide?.guide,
    }
}
