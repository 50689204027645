import { ApolloClient, gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { ConfigurableProduct } from './ConfigurableProduct'
import { Maybe, ProductInterface } from '../../graphql/schema.generated'
import productCardFragment, {
    ProductCardFragment,
} from '../../ProductCardFragment'
import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from '../common/MinimalConfigurableProductInfo.fragment'

const query = gql`
    query GetProduct($id: Int!) {
        product(id: $id) {
            ...ProductCardFragmentJB
            ...ConfigurableProductInfo
            metaTitle
            metaDescription
            metaRobots
            recommendations
            mediaGalleryEntries {
                label
                position
                disabled
                file
            }
            ... on SimpleProduct {
                image {
                    url
                    label
                }
                shortDescription {
                    html
                }
                description {
                    html
                }
                productGroup
                contentMiddleTitle
                contentMiddleContent
                contentMiddleImageTwo
                contentBottomTitle
                contentBottomContent
                contentBottomImageTwo
                contentSizeGuideImageAbove
                # oude velden product tabs
                modelInfo
                materialOutside
                materialInside
                washingInstructions
                featureIconsValues {
                    label
                }
                highlightsValues {
                    label
                }
                washingSymbolsValues {
                    label
                }
                fitValues {
                    label
                }
                # nieuwe velden
                # specifications
                productClosureValues {
                    label
                }
                productClosureDetailsValues {
                    label
                }
                productHoodValues {
                    label
                }
                productHoodDetailsValues {
                    label
                }
                productCollarValues {
                    label
                }
                productCollarDetailsValues {
                    label
                }
                productSleeveDetailsValues {
                    label
                }
                productCuffsValues {
                    label
                }
                productSleeveCuffDetailsValues {
                    label
                }
                productSleeveLengthValues {
                    label
                }
                productLogoLocationValues {
                    label
                }
                productInnerPocketsValues {
                    label
                }
                productOuterPocketsValues {
                    label
                }
                productJacketDetailsValues {
                    label
                }
                productJacketlayersValues {
                    label
                }
                productInnerJacketDetailsValues {
                    label
                }
                productSustainableValues {
                    label
                }
                productLengthDescriptionValues {
                    label
                }
                productPocketTypeValues {
                    label
                }
                productJacketTypeValues {
                    label
                }
                productNumberOfPockets
                productDimensions
                # fit
                productFitValues {
                    label
                }
                productWaistRiseValues {
                    label
                }
                productShapeValues {
                    label
                }
                itemSizeAdviceValues {
                    label
                }
                # features
                featureMedia
                featureName
                featureDescription
                modelAdvice
                # material
                itemMaterialBody
                itemMaterialBodyLining
                productFabricValues {
                    label
                }
                productPatternValues {
                    label
                }
                productMaterialFilling
                # wasvoorschriften
                washingInstructions
                washingSymbolMedia
                washingSymbolDescription
            }

            ... on ConfigurableProduct {
                image {
                    url
                    label
                }
                shortDescription {
                    html
                }
                description {
                    html
                }
                productGroup
                contentMiddleTitle
                contentMiddleContent
                contentMiddleImageTwo
                contentBottomTitle
                contentBottomContent
                contentBottomImageTwo
                contentSizeGuideImageAbove
                # oude velden product tabs
                modelInfo
                materialOutside
                materialInside
                washingInstructions
                featureIconsValues {
                    label
                }
                highlightsValues {
                    label
                }
                washingSymbolsValues {
                    label
                }
                fitValues {
                    label
                }
                baseColorValues {
                    label
                }
                # nieuwe velden
                # specifications
                productClosureValues {
                    label
                }
                productClosureDetailsValues {
                    label
                }
                productHoodValues {
                    label
                }
                productHoodDetailsValues {
                    label
                }
                productCollarValues {
                    label
                }
                productCollarDetailsValues {
                    label
                }
                productSleeveDetailsValues {
                    label
                }
                productCuffsValues {
                    label
                }
                productSleeveCuffDetailsValues {
                    label
                }
                productSleeveLengthValues {
                    label
                }
                productLogoLocationValues {
                    label
                }
                productInnerPocketsValues {
                    label
                }
                productOuterPocketsValues {
                    label
                }
                productJacketDetailsValues {
                    label
                }
                productJacketlayersValues {
                    label
                }
                productInnerJacketDetailsValues {
                    label
                }
                productSustainableValues {
                    label
                }
                productLengthDescriptionValues {
                    label
                }
                productPocketTypeValues {
                    label
                }
                productJacketTypeValues {
                    label
                }
                productNumberOfPockets
                productDimensions
                # fit
                productFitValues {
                    label
                }
                productWaistRiseValues {
                    label
                }
                productShapeValues {
                    label
                }
                itemSizeAdviceValues {
                    label
                }
                # features
                featureMedia
                featureName
                featureDescription
                modelAdvice
                # material
                itemMaterialBody
                itemMaterialBodyLining
                productFabricValues {
                    label
                }
                productPatternValues {
                    label
                }
                productMaterialFilling
                # wasvoorschriften
                washingInstructions
                washingSymbolMedia
                washingSymbolDescription
            }

            ... on GroupedProduct {
                id
                items {
                    product {
                        ...ProductCardFragmentJB
                        ...ConfigurableProductInfo
                    }
                }
            }
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

export interface GetProductVariables {
    id: number
}

export enum ProductGroup {
    jeans = 'jeans',
}

export type AdditionalProductInfoInterface = Pick<
    ProductInterface,
    | 'baseColorValues'
    // oude waarden
    | 'fitValues'
    | 'featureIconsValues'
    | 'highlightsValues'
    | 'washingSymbolsValues'
    | 'modelInfo'
    // nieuwe waarden
    // specifications
    | 'productClosureValues'
    | 'productClosureDetailsValues'
    | 'productHoodValues'
    | 'productHoodDetailsValues'
    | 'productCollarValues'
    | 'productCollarDetailsValues'
    | 'productSleeveDetailsValues'
    | 'productCuffsValues'
    | 'productSleeveCuffDetailsValues'
    | 'productSleeveLengthValues'
    | 'productLogoLocationValues'
    | 'productInnerPocketsValues'
    | 'productOuterPocketsValues'
    | 'productJacketDetailsValues'
    | 'productJacketlayersValues'
    | 'productInnerJacketDetailsValues'
    | 'productSustainableValues'
    | 'productLengthDescriptionValues'
    | 'productPocketTypeValues'
    | 'productJacketTypeValues'
    | 'productNumberOfPockets'
    | 'productDimensions'
    // fit
    | 'productFitValues'
    | 'productWaistRiseValues'
    | 'productShapeValues'
    | 'itemSizeAdviceValues'
    // features
    | 'featureMedia'
    | 'featureName'
    | 'featureDescription'
    | 'modelAdvice'
    // material
    | 'itemMaterialBody'
    | 'itemMaterialBodyLining'
    | 'productFabricValues'
    | 'productPatternValues'
    | 'productMaterialFilling'
    // wasvoorschriften
    | 'washingInstructions'
    | 'washingSymbolMedia'
    | 'washingSymbolDescription'
> & {
    image: {
        url: string
        label: string
    }
    shortDescription: {
        html: string
    }
    description: {
        html: string
    }
    metaTitle: string
    metaDescription: string
    metaRobots?: string
    contentMiddleTitle: Maybe<string>
    contentMiddleContent: Maybe<string>
    contentMiddleImageTwo: string | 'no_selection'
    contentBottomTitle: Maybe<string>
    contentBottomContent: Maybe<string>
    contentBottomImageTwo: string | 'no_selection'
    contentSizeGuideImageAbove: string | 'no_selection'
    recommendations: Maybe<string>
    productGroup: ProductGroup
    warehouse: string
    // oude waarden
    modelInfo?: string
    materialOutside?: string
    materialInside?: string
    washingInstructions?: string
}

export type Product = ProductCardFragment &
    Partial<AdditionalProductInfoInterface>

export type GroupedProduct = Omit<ProductCardFragment, 'items'> &
    Pick<
        Product,
        'recommendations' | 'metaTitle' | 'metaRobots' | 'metaDescription'
    > & {
        items: Maybe<
            Array<{
                product: Maybe<Product | ConfigurableProduct>
            }>
        >
    }

export interface GetProductData {
    product: Product | ConfigurableProduct
}

export const isGroupedProduct = (
    product: Product | ConfigurableProduct | GroupedProduct,
): product is GroupedProduct =>
    (product as GroupedProduct).__typename === 'GroupedProduct'

export const useGetProduct = (
    id?: number,
    options: Omit<
        QueryHookOptions<GetProductData, GetProductVariables>,
        'variables'
    > = {},
) => {
    const { data, error, ...others } = useQuery<
        GetProductData,
        GetProductVariables
    >(query, {
        ...options,
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: id!,
        },
        errorPolicy: 'all',
        context: getCacheableContext(),
        skip: !id,
    })

    return {
        ...others,
        data: data?.product,
    }
}

export const getProduct = (
    apolloClient: ApolloClient<unknown>,
    id: GetProductVariables['id'],
) =>
    apolloClient.query<GetProductData>({
        query,
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        variables: { id },
    })
