import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { cartFragment } from '@emico-hooks/cart-fragment'
import { useCartId } from '@emico-hooks/cart-id'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    UpdateCartItemsMutation,
    UpdateCartItemsMutationVariables,
} from './useUpdateCartItems.generated'

interface GiftMessageInput {
    from: string
    to: string
    message: string
}

interface CustomizableOptionInput {
    id?: number
    value_string: string
}

export interface ConfigurableOptionInput {
    configurable_product_option_uid: string
    configurable_product_option_value_uid: string
}

interface CartItemUpdateInput {
    cart_item_uid: string
    configurable_options?: ConfigurableOptionInput[]
    customizable_options?: CustomizableOptionInput[]
    gift_message?: GiftMessageInput
    quantity?: number
}

const updateCartItemsMutation = gql`
    mutation updateCartItems(
        $cartId: String!
        $cartItems: [CartItemUpdateInput!]!
    ) {
        updateCartItems(input: { cart_id: $cartId, cart_items: $cartItems }) {
            cart {
                ...CartFragment
            }
        }
    }

    ${cartFragment}
` as TypedDocumentNode<
    UpdateCartItemsMutation,
    UpdateCartItemsMutationVariables
>

/**
 * Mutation to update cart items
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const cartId = useCartId()
 *  const updateCartItems = useUpdateCartItems()
 *
 *  return useCallback(
 *    async (values: ExampleFormValues) => {
 *      try {
 *        await updateCartItems([
 *          {
 *            cart_item_id: cartItem.id,
 *            quantity: quantity
 *          }
 *        ])
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [cartId, updateCartItems],
 *  )
 * }
 * ```
 */

export function useUpdateCartItems() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()
    const cartId = useCartId()

    return useCallback(
        /**
         *
         * @param cartItems Array of CartItemUpdateInput objects: cart items to update
         */
        (cartItems: CartItemUpdateInput[]) => {
            if (!cartId) {
                return
            }

            return client.mutate({
                mutation: updateCartItemsMutation,
                variables: {
                    cartId,
                    cartItems,
                },
                context: authorizationContext,
            })
        },
        [authorizationContext, cartId, client],
    )
}
