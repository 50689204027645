import { makeVar, useReactiveVar } from '@apollo/client'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { minWidth } from '@emico/styles'

import BlueConicContent from './BlueConicContent'
import { mobileMenuVisibleVar } from './layout/Header'
import HeaderLink from './layout/Header/HeaderLink'
import HeightTransition from './layout/HeightTransition/HeightTransition'
import MiniWishlist from './MiniWishlist'
import paths from './paths'
import BookmarkIcon from './wishlist/BookmarkIcon'
import { useGetWishlist } from './wishlist/GetWishlist.query'

export const wishlistVar = makeVar<false | number>(false)

const StyledMiniWishlist = styled(MiniWishlist)`
    position: absolute;
    top: 100%;
    padding-bottom: 0;
    right: 0;
    width: 100%;

    @media ${minWidth('md')} {
        right: -18px;
        width: 375px;
    }
`

const WishlistActionButton = () => {
    const isMobileMenuVisible = useReactiveVar(mobileMenuVisibleVar)
    const { data: wishlistData } = useGetWishlist()
    const wishlistItemCount: number = wishlistData?.itemsCount ?? 0
    const wishlist = useReactiveVar(wishlistVar)

    return (
        <>
            <HeaderLink
                name="Wishlist"
                to={paths.wishlist}
                icon={BookmarkIcon}
                title={t({
                    id: 'core.topBar.wishlistButtonLabel',
                    message: `Wishlist`,
                })}
                counter={wishlistItemCount}
                position="wishlist"
            />

            <HeightTransition>
                {wishlist !== false && !isMobileMenuVisible && (
                    <StyledMiniWishlist />
                )}
            </HeightTransition>

            <BlueConicContent blueConicPosition="wishlistTooltip">
                <div />
            </BlueConicContent>
        </>
    )
}

export default WishlistActionButton
