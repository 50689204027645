import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import Button from '../../input/Button'
import { useSocialButtonClickEvent } from '../../utils/ga4/useSocialButtonClickEvent'
import styles from '../ContactUsModal/ContactUsModal.module.scss'

interface RobinUser {
    name: string
    firstName: string
    lastName: string
    function: string
    email: string
    avatar: string
    avatar20: string
    avatar36: string
    avatar128: string
    presence: string
    actualPresence: string
}

const global = window as {
    robin_settings?: {
        hide_tab?: boolean
        tab_position?: 'bottom-right' // enum
        callback(
            event: 'load' | 'user' | 'init',
            data?: Record<string, unknown>,
        ): void
        features?: {
            shareCookieAcrossDomains?: boolean
            chatInDivOnMobile?: boolean
        }
    }
    __robin?: {
        show(type: string): void
        getWebStoreUsers(): RobinUser[]
    }
}

interface Props {
    robinHqToken: string
    close(): void
}

const ContactUsChatButton = ({ robinHqToken, close }: Props) => {
    const [robinLoaded, setRobinLoaded] = useState<boolean>(
        Boolean(global.__robin),
    )
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [isChatOpen, setChatOpen] = useState<boolean>(false)

    const pushEvent = useSocialButtonClickEvent()

    const show = useCallback(() => {
        // When showing the RobinHQ panel, the contact us modal must
        // automatically close so that it looks like it is replaced
        close()
        global.__robin?.show('chat')
    }, [close])

    useEffect(() => {
        global.robin_settings = {
            hide_tab: false,
            tab_position: 'bottom-right',
            callback(event, data) {
                if (event === 'init') {
                    setRobinLoaded(true)
                }
            },
            features: {
                // if this is set to true it doesn't work.
                shareCookieAcrossDomains: false,
                // force the chat to not be in a popup on mobile
                chatInDivOnMobile: true,
            },
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (robinLoaded && isChatOpen) {
            show()
        }
    }, [robinLoaded, isChatOpen, show])

    useEffect(() => {
        if (robinLoaded && global.__robin) {
            const onlineUser = global.__robin
                .getWebStoreUsers()
                .find((item) => item.actualPresence === 'online')

            setIsOnline(onlineUser !== undefined)
        }
    }, [robinLoaded])
    const handleOpenChat = () => {
        pushEvent('contact modal', 'chat')
        setChatOpen(true)
        if (global.__robin) {
            // if robin isn't available yet, opening will be done in the init event
            show()
        }
    }

    return (
        <>
            {isOnline && (
                <Button
                    name="Chat"
                    category="core.contactUsModal.chat"
                    variant="secondary"
                    className={styles.button}
                    onClick={handleOpenChat}
                    loading={!robinLoaded}
                    disabled={isChatOpen || !robinLoaded || !isOnline}
                >
                    <Trans id="core.contactUsModal.chat">Chat</Trans>
                </Button>
            )}
            {!global.__robin && (
                <Helmet>
                    <script
                        src={`https://selfservice.robinhq.com/external/robin/${robinHqToken}.js`}
                        async
                    />
                </Helmet>
            )}
        </>
    )
}

export default ContactUsChatButton
