import {
    gql,
    MutationUpdaterFn,
    QueryHookOptions,
    useQuery,
} from '@apollo/client'
import { DataProxy } from '@apollo/client/cache'
import {
    useAuthorizationContext,
    useIsLoggedIn,
} from '@emico-hooks/login-token'

import { DeleteAddressData } from './deleteAddress.query'
import {
    Customer as GeneratedCustomer,
    CustomerAddress as GeneratedCustomerAddress,
    Maybe,
    Query,
} from '../graphql/schema.generated'
import {
    filterNullValues,
    useFilterNullValues,
} from '../utils/filterNullValues'

export const fragments = {
    address: gql`
        fragment Address on CustomerAddress {
            id
            company
            firstname
            lastname
            countryCode
            street
            postcode
            city
            telephone
            defaultBilling
            defaultShipping
        }
    `,
}

const query = gql`
    query GetCustomerAddresses {
        customer {
            email
            addresses {
                ...Address
            }
        }
    }
    ${fragments.address}
`

export type Address = Pick<
    GeneratedCustomerAddress,
    | 'id'
    | 'company'
    | 'firstname'
    | 'lastname'
    | 'countryCode'
    | 'street'
    | 'postcode'
    | 'city'
    | 'telephone'
    | 'defaultBilling'
    | 'defaultShipping'
>

type Customer = Override<
    Pick<GeneratedCustomer, 'email' | 'addresses'>,
    {
        addresses: Maybe<Array<Maybe<Address>>>
    }
>

export type GetAddressesData = Override<
    Pick<Query, 'customer'>,
    {
        customer: Maybe<Customer>
    }
>

const writeCache = (
    cache: DataProxy,
    mapper: (addresses: Address[]) => Address[],
) => {
    let cachedQuery

    try {
        cachedQuery = cache.readQuery<GetAddressesData>({
            query,
        })
        if (!cachedQuery) {
            throw new Error('There is no GET_ADDRESSES cache!')
        }
    } catch (err) {
        // there is no cache yet, just do nothing
        return
    }
    const { customer } = cachedQuery
    const addresses = filterNullValues(customer?.addresses) ?? []

    // write the new addresses to the cache
    cache.writeQuery({
        query,
        data: {
            customer: {
                ...customer,
                addresses: mapper(addresses),
            },
        },
    })
}

export const removeFromCache =
    (id: number): MutationUpdaterFn<DeleteAddressData> =>
    (cache, { data }) => {
        if (!data) {
            return data
        }

        const mapper = (addresses: Address[]) =>
            addresses.filter((address) => address.id !== id)

        writeCache(cache, mapper)
        return data
    }

interface UpdateCacheData {
    customerAddress: Maybe<Address>
}
export const updateCache =
    <T extends UpdateCacheData>(
        type: 'create' | 'update',
    ): MutationUpdaterFn<T> =>
    (cache, { data }) => {
        if (!data || !data.customerAddress) {
            return
        }

        // setup some business logic
        const mappers: Array<(address: Address) => Address> = []

        // if default billing is set, we need to unset the previous one
        if (data.customerAddress.defaultBilling) {
            mappers.push(
                (address: Address): Address => ({
                    ...address,
                    defaultBilling: false,
                }),
            )
        }
        // if default shipping is set, we need to unset the previous one
        if (data.customerAddress.defaultShipping) {
            mappers.push(
                (address: Address): Address => ({
                    ...address,
                    defaultShipping: false,
                }),
            )
        }

        // mutate the current address, if update is 'update'
        // since we are using an updater, this doesn't happen automaticly anymore
        if (type === 'update') {
            mappers.push((address: Address): Address => {
                if (
                    data?.customerAddress?.id &&
                    address.id &&
                    address.id === data.customerAddress.id
                ) {
                    return data.customerAddress
                }
                return address
            })
        }

        const mapper = (addresses: Address[]) =>
            // call all created functions on the current addresses
            mappers
                .reduce((acc, fn) => acc.map(fn), addresses)
                // add the new address, if update is 'add'
                .concat(type === 'create' ? data?.customerAddress ?? [] : [])
                .filter(Boolean)

        writeCache(cache, mapper)
    }

export const useGetAddresses = (
    options?: QueryHookOptions<GetAddressesData>,
) => {
    const authorizationContext = useAuthorizationContext()
    const isLoggedIn = useIsLoggedIn()
    const { data, error, loading, refetch } = useQuery<GetAddressesData>(
        query,
        {
            ...options,
            context: authorizationContext,
            skip: !isLoggedIn,
        },
    )

    if (error) {
        throw error
    }

    return {
        refetch,
        data: useFilterNullValues(data?.customer?.addresses),
        loading,
    }
}

export const useFindAddress = (
    query: (address: Address) => boolean,
    options?: QueryHookOptions<GetAddressesData>,
) => {
    const { data, loading } = useGetAddresses(options)

    return {
        data: data?.find(query) || undefined,
        loading,
    }
}
export const useGetDefaultShippingAddress = (options?: QueryHookOptions) =>
    useFindAddress(({ defaultShipping }) => Boolean(defaultShipping), options)
export const useGetDefaultBillingAddress = (options?: QueryHookOptions) =>
    useFindAddress(({ defaultBilling }) => Boolean(defaultBilling), options)
