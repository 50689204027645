import { useCartId } from '@emico-hooks/cart-id'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { useStoreViews } from '@emico/storeviews'
import { useBreakpoints } from '@emico/ui'

import styles from './LanguageSelector.module.scss'
import { fetchTranslatedUrl } from '../../actions/storeView'
import AlertModal from '../../core/AlertModal'
import { useCoreConfigValue } from '../../coreConfig.query'
import Select from '../../input/Select/Select'
import { useDispatch } from '../../types'
import Paragraph from '../../typography/Text'

const DropdownContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>`
    ${(props) => (props.isMobile ? 'margin-top: 10px' : '')};
`

const LanguageSelectorContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{
    isMobile: boolean
}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${(props) =>
        props.isMobile ? 'flex-direction: column;align-items: flex-start;' : ''}
    ${DropdownContainer} {
        ${(props) => (!props.isMobile ? 'margin-left: 10px' : '')};
    }
`

export const MANUALLY_SELECTED_STOREVIEW_KEY = 'manually-selected-storeview'

const LanguageSelector = () => {
    const dispatch = useDispatch()
    const [isOpen, setOpen] = React.useState<boolean>(false)
    const { isMobile } = useBreakpoints()

    const cartId = useCartId()
    const hasCart = Boolean(cartId)

    const location = useLocation()
    // Do not show the language selector when there's just one language available
    const storeViews = useStoreViews()
    const { value: storeSelectorConfig } = useCoreConfigValue(
        'justbrands_content/stores/alternatives',
    )
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentStoreView = storeViews.find((sf) => sf.active)!.code
    const [selectedStoreView, setSelectedStoreView] =
        React.useState<string>(currentStoreView)

    const availableStores = React.useMemo(
        () =>
            storeSelectorConfig &&
            Object.values<{ code: string; label: string; url: string }>(
                JSON.parse(storeSelectorConfig),
            ).filter(
                (store) =>
                    // Label configured in the backend can be used to show or hide store
                    store.label &&
                    store.label !== '' &&
                    // Only show stores that are configured in the frontend
                    storeViews.findIndex(
                        (storeView) => storeView.code === store.code,
                    ) !== -1,
            ),
        [storeSelectorConfig, storeViews],
    )

    if (!availableStores || availableStores.length === 1) {
        return null
    }

    const redirect = async (storeView: string) => {
        // Remember selection
        const cookies = new Cookies()

        cookies.set(MANUALLY_SELECTED_STOREVIEW_KEY, storeView, {
            path: '/',
        })

        // Redirect
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { makeUrl } = storeViews.find((sf) => sf.code === storeView)!
        const urlToTranslate = location.pathname

        const urlKey = await dispatch(
            fetchTranslatedUrl(storeView, urlToTranslate),
        )

        window.location.href = makeUrl(
            urlKey
                ? // An translated version is found.
                  // Prefix the url with the new basename, and use the new url
                  `/${urlKey}`
                : // If no translated version is found, redirect to the homepage.
                  '/',
        )
    }

    const handleChangeLanguage: React.ChangeEventHandler<HTMLSelectElement> = (
        event,
    ) => {
        const storeView = event.currentTarget.value

        setSelectedStoreView(storeView)

        // If there is a cart, trigger modal
        // Otherwise we can continue as normal
        if (hasCart) {
            setOpen(true)
        } else {
            redirect(storeView)
        }
    }

    const handleCancelClick = () => {
        setSelectedStoreView(currentStoreView)
        setOpen(false)
    }

    const handleConfirmClick = async () => {
        if (selectedStoreView) {
            setOpen(false)
            redirect(selectedStoreView)
        }
    }

    const dropdown = (
        <Select
            id="language-select"
            field="language-select"
            onChange={handleChangeLanguage}
            value={selectedStoreView}
            className={styles.selectWrapper}
            inputClassName={styles.select}
            transparent
        >
            {availableStores.map((storeView) => (
                <option key={storeView.code} value={storeView.code}>
                    {storeView.label}
                </option>
            ))}
        </Select>
    )

    return (
        <LanguageSelectorContainer isMobile={isMobile}>
            <Paragraph>
                <Trans id="core.languageSelector.label">
                    You are shopping in
                </Trans>
            </Paragraph>
            <DropdownContainer isMobile={isMobile}>
                {dropdown}
            </DropdownContainer>

            <AlertModal
                open={isOpen}
                onConfirm={handleConfirmClick}
                onCancel={handleCancelClick}
                title={t({
                    id: 'core.languageSelector.modalTitle',
                    message: `Confirm switching store`,
                })}
                confirmLabel={t({
                    id: 'core.languageSelector.modalConfirmLabel',
                    message: `Continue`,
                })}
            >
                <Trans id="core.languageSelector.modalContent">
                    <p>
                        You are about to switch stores. By doing so, your cart
                        will be removed.
                    </p>
                    <p>Do you want to continue?</p>
                </Trans>
            </AlertModal>
        </LanguageSelectorContainer>
    )
}

export default LanguageSelector
