import { css, Global } from '@emotion/react'
import * as React from 'react'

import ContactUsModalTransition from './core/ContactUsModal/ContactUsModalTransition'

const ROBIN_HQ_PROACTIVE_CHAT_ROOT_ID = 'robin_pac_invite'

const RobinHqProactiveChatDetection = () => {
    const [showChat, setChatVisibility] = React.useState<boolean>(false)

    const callback: MutationCallback = (mutationsList) => {
        if (showChat) {
            return
        }

        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach((addedNode) => {
                    if (
                        addedNode.nodeType === addedNode.ELEMENT_NODE &&
                        (addedNode as HTMLElement).id ===
                            ROBIN_HQ_PROACTIVE_CHAT_ROOT_ID
                    ) {
                        setChatVisibility(true)
                    }
                })
            }
        }
    }

    React.useEffect(() => {
        if (!process.env.REACT_APP_ROBINHQ_PUBLIC_TOKEN) {
            return
        }

        const observer = new MutationObserver(callback)
        const node = document.body

        if (node) {
            observer.observe(node, {
                attributes: false,
                childList: true,
                subtree: false,
            })
        }

        return () => {
            observer.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!process.env.REACT_APP_ROBINHQ_PUBLIC_TOKEN) {
        return null
    }

    return (
        <>
            <Global
                styles={css`
                    /* stylelint-disable selector-id-pattern */
                    #${ROBIN_HQ_PROACTIVE_CHAT_ROOT_ID} {
                        display: none;
                    }
                `}
            />

            <ContactUsModalTransition
                open={showChat}
                close={() => setChatVisibility(false)}
                proactiveChat
            />
        </>
    )
}

export default RobinHqProactiveChatDetection
