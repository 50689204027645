import { Trans } from '@lingui/macro'

import Button, { ButtonProps } from '../input/Button'

export const EditGiftSelectButton = (
    props: Omit<ButtonProps, 'name' | 'category'>,
) => (
    <Button variant="secondary" {...props} name="" category="">
        <Trans id="cart.gifts.modal.select">I want this gift</Trans>
    </Button>
)
