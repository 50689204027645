import cx from 'classnames'
import * as React from 'react'

import Badge from './Badge'
import styles from './Icon.module.scss'

// Interface for wrapper components that use this base component
export interface OwnProps {
    // component could be a SVG or it can be a overridable() component,
    // which is _not_ a SVG type anymore, sad panda
    component:
        | React.FC<React.SVGProps<SVGSVGElement>>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | React.ComponentType<any>
    title: string
    className?: string
    wrapperClassName?: string
    badgeClassName?: string
    rounded?: boolean
    content?: React.ReactNode
}

export type Props = OwnProps & React.SVGProps<SVGSVGElement>

const Icon = ({
    component: Component,
    title,
    // eslint-disable-next-line react/prop-types
    color,
    className,
    wrapperClassName,
    badgeClassName,
    rounded,
    content,
    ...others
}: Props) => (
    <span
        aria-label={title}
        className={cx(styles.wrapper, wrapperClassName, {
            [styles.rounded]: rounded,
        })}
    >
        <Component
            className={cx(styles.default, className)}
            focusable="false"
            aria-hidden="true"
            {...others}
        />
        {content && (
            <Badge
                wrapperClassName={styles.badgeWrapper}
                badgeClassName={badgeClassName ?? ''}
            >
                {content}
            </Badge>
        )}
    </span>
)

export default Icon
