import cx from 'classnames'
import * as React from 'react'

import styles from './ProgressBar.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    progress?: number
}

const ProgressBar = ({ className, progress = 0, ...otherProps }: Props) => (
    <div className={cx(styles.progressBar, className)} {...otherProps}>
        <div
            className={styles.progress}
            style={{ transform: `translate3d(-${100 - progress}%, 0, 0)` }}
        />
    </div>
)

export default ProgressBar
