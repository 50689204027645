import cx from 'classnames'
import * as React from 'react'

import styles from './RadioButton.module.scss'
import Button from '../Button'
import { Label } from '../Label'

interface Props {
    Component?: React.ElementType
    variant?: 'primary' | 'minimal' | 'filled'
    className?: string
    value: string
    children?: React.ReactNode
    name?: string
    category?: string
    disabled?: boolean
    title?: string
    testId?: string
}

const DefaultRadioInput = (
    props: React.InputHTMLAttributes<HTMLInputElement>,
) => <input type="radio" {...props} />

const RadioButton = <
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object = React.InputHTMLAttributes<HTMLInputElement>,
>({
    Component = DefaultRadioInput,
    className,
    variant = 'primary',
    value,
    children,
    name = '',
    category = '',
    disabled,
    title,
    testId,
    ...others
}: Props & T) => (
    <Label
        className={cx(
            styles.radioButton,
            styles[variant],
            {
                [styles.disabled]: disabled,
            },
            className,
        )}
        title={title}
        data-testid={testId}
    >
        <Component
            className={styles.input}
            value={value}
            disabled={disabled}
            {...others}
        />
        <Button
            className={styles.button}
            tabIndex={-1}
            name={name}
            category={category}
            disabled={disabled}
        >
            {children}
        </Button>
    </Label>
)

export default RadioButton
