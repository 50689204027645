import { TweakwiseConfigurator } from './guided-selling/TweakwiseConfigurator'
import Header from './layout/Header'
import HeaderContainer from './layout/Header/HeaderContainer'
import PageWrapper from './layout/PageWrapper'
import NotFoundPage from './NotFoundPage'
import PageLoader from './presentation/PageLoader'
import PrismicCmsFaqSlices from './PrismicCmsFaqSlices'
import PrismicCmsSlices from './PrismicCmsSlices'
import PrismicGrid from './PrismicGrid'
import PrismicHelmet from './PrismicHelmet'
import styles from './PrismicPage.module.scss'
import usePrismicPage from './usePrismicPage'
import getLayoutStyle from './utils/getLayoutStyle'

interface Props {
    urlKey: string
    prismicType: string
}

const PrismicPage = ({ urlKey, prismicType }: Props) => {
    let loading = false
    let prismicPage

    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { data, loading: prismicLoading } = usePrismicPage(
            urlKey,
            prismicType,
        )

        prismicPage = data
        loading = prismicLoading
    } catch {
        // Catch issues with incorrect prismic configuration
        return <NotFoundPage />
    }

    if (loading) {
        return (
            <PageWrapper pageType="cms">
                <PageLoader fullScreen reason="Loading prismic data..." />
            </PageWrapper>
        )
    }

    if (!prismicPage?.body) {
        return <NotFoundPage />
    }

    const style = getLayoutStyle(prismicPage?.style)
    const hideLayout = 'hideLayout' in prismicPage && prismicPage.hideLayout

    return (
        <PageWrapper
            pageType="cms"
            header={
                hideLayout ? null : (
                    <HeaderContainer>
                        <Header />
                    </HeaderContainer>
                )
            }
            footer={hideLayout ? null : undefined}
            className={styles[style]}
        >
            <PrismicHelmet page={prismicPage} />

            {prismicPage.__typename === 'Page' &&
            prismicPage.tweakwiseFunnelId ? (
                <TweakwiseConfigurator
                    tweakwiseFunnelId={prismicPage.tweakwiseFunnelId}
                />
            ) : (
                <PrismicGrid>
                    {prismicType.endsWith('faq_page') ? (
                        <PrismicCmsFaqSlices
                            slices={prismicPage.body}
                            urlKey={prismicPage.urlKey || ''}
                            style={style}
                        />
                    ) : (
                        <PrismicCmsSlices
                            slices={prismicPage.body}
                            prismicType={prismicType}
                        />
                    )}
                </PrismicGrid>
            )}
        </PageWrapper>
    )
}

export default PrismicPage
