import { css } from '@emotion/react'
import * as React from 'react'

import styles from './Navigation.module.scss'
import detectTouchscreen from '../../../detectTouchscreen'
import { Nav } from '../../../navigation/Nav'
import { NavLink } from '../../../navigation/NavLink'
import Portal from '../../../Portal'
import FadeTransition from '../../FadeTransition'
import PageOverlay from '../../PageOverlay'
import SubNav, { MenuItem } from '../SubNav/SubNav'
import useMenuItems from '../useMenuItems'

/**
 * Hover duration before menu becomes visible.
 */
const MENU_HOVER_DURATION = 300

interface Props {
    onOpenSubNavigation?(): void
    onCloseSubNavigation?(eventTrigger: React.MouseEvent): void
    className?: string
}

const Navigation = ({
    onOpenSubNavigation,
    onCloseSubNavigation,
    className,
}: Props) => {
    const isTouchDevice = detectTouchscreen()
    const menuItems = useMenuItems()
    const timer = React.useRef<NodeJS.Timeout>()

    // Contains the url of the currently active menu
    const [activeMenu, setActiveMenu] = React.useState<MenuItem | undefined>(
        undefined,
    )

    const openMenu = (menu: MenuItem) => {
        setActiveMenu(menu)

        if (onOpenSubNavigation) {
            onOpenSubNavigation()
        }
    }

    const closeMenu = (eventTrigger: React.MouseEvent) => {
        setActiveMenu(undefined)

        if (onCloseSubNavigation) {
            onCloseSubNavigation(eventTrigger)
        }
    }

    const handleNavLinkOnMouseEnter =
        (item: MenuItem): React.MouseEventHandler<HTMLAnchorElement> =>
        (e) => {
            if (isTouchDevice) {
                return
            }

            if (activeMenu) {
                openMenu(item)
            } else {
                timer.current = setTimeout(() => {
                    openMenu(item)
                }, MENU_HOVER_DURATION)
            }
        }

    React.useEffect(
        () => () => {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        },
        [],
    )

    const handleNavLinkOnClick =
        (item: MenuItem): React.MouseEventHandler<HTMLAnchorElement> =>
        (event) => {
            // Prevent menu items with a submenu from navigating to a page.
            if (isTouchDevice && item.children && item.children.length > 0) {
                if (activeMenu?.id === item.id) {
                    closeMenu(event)
                } else {
                    event.preventDefault()
                    openMenu(item)
                }
            }
        }

    const handleSubNavOnMouseLeave: React.MouseEventHandler<HTMLDivElement> = (
        event,
    ) => !isTouchDevice && closeMenu(event)

    const handleSubNavBackDropClick: React.MouseEventHandler<HTMLDivElement> = (
        event,
    ) => closeMenu(event)

    const handleNavLinkOnMouseLeave = () => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
    }

    const activeMenuItem = menuItems?.find(
        (menuItem) => menuItem.id === activeMenu?.id,
    )

    if (!menuItems || menuItems.length === 0) {
        return null
    }

    return (
        <>
            <Nav className={className}>
                {menuItems.map((item, index) => (
                    <React.Fragment key={item.label}>
                        <NavLink
                            key={index}
                            name={item.label}
                            category="header.navigation"
                            to={item.to}
                            resolver={item.resolver}
                            onMouseEnter={handleNavLinkOnMouseEnter(item)}
                            onMouseLeave={handleNavLinkOnMouseLeave}
                            isActive={item.isActive}
                            active={false}
                            onClick={handleNavLinkOnClick(item)}
                            preload
                        >
                            {item.label}
                        </NavLink>
                        {item.children && (
                            <SubNav
                                banner={item.banner}
                                banner2={item.banner2}
                                items={item.children}
                                onItemClick={closeMenu}
                                className={styles.hidden}
                            />
                        )}
                    </React.Fragment>
                ))}
            </Nav>

            <FadeTransition>
                {activeMenuItem &&
                    activeMenuItem.children &&
                    activeMenuItem.children.length > 0 && (
                        <SubNav
                            banner={activeMenuItem.banner}
                            banner2={activeMenuItem.banner2}
                            items={activeMenuItem.children}
                            onItemClick={closeMenu}
                            className={styles.subNav}
                            onMouseLeave={handleSubNavOnMouseLeave}
                        />
                    )}
            </FadeTransition>
            <Portal>
                <FadeTransition>
                    {activeMenuItem &&
                        activeMenuItem.children &&
                        activeMenuItem.children.length > 0 && (
                            <PageOverlay
                                onClick={handleSubNavBackDropClick}
                                css={css`
                                    z-index: 109;
                                `}
                            />
                        )}
                </FadeTransition>
            </Portal>
        </>
    )
}

export default Navigation
