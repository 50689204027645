import { ConfigurableAttributeOption } from '@emico-hooks/graphql-schema-types'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import {
    CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    hasLimitedStock,
    isOutOfStock,
    limitedStockLabel,
    outOfStockLabel,
} from '../ConfigurableAttributesField/helpers'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { getAttributeIdLookupMap } from '../getAttributeIdByCode'
import getAttributeIndex from '../getAttributeIndex'
import { Product } from '../GetProduct.query'

export const lengthLabel = (value: string) =>
    t({
        id: 'core.configurableOptionSelect.valueLabel',
        message: `L${value}`,
    })
export const widthLabel = (value: string) =>
    t({
        id: 'core.configurableOptionSelect.prefix',
        message: `W${value}`,
    })

export function encodeAttributeValueObject<T>(valueObject: T): string {
    return encodeURIComponent(JSON.stringify(valueObject))
}

export function decodeAttributeValueObject<T>(value: string | undefined): T {
    if (!value) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return {} as T
    }
    return JSON.parse(decodeURIComponent(value))
}

const cache = new WeakMap<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    Array<{
        value: string
        label: string
        sku?: string
        disabled: boolean
    }>
>()

export default function flattenCombinedAttributes(
    product: Product | ConfigurableProduct,
    getAttributeOptionValue: (
        attribute: ConfigurableAttributeOption,
    ) => string | number = (attr) => attr?.valueIndex ?? '',
    attributeCodeA: string = CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    attributeCodeB: string = CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    labelGenerator: (props: {
        a: string
        b: string
        outOfStock: boolean
        limitedStock: boolean
        onlyXLeftInStock: number | null | undefined
        product: ConfigurableProduct
    }) => string = ({ a, b, limitedStock, outOfStock, onlyXLeftInStock }) =>
        [
            widthLabel(a),
            lengthLabel(b),
            limitedStock &&
                onlyXLeftInStock != null &&
                limitedStockLabel(onlyXLeftInStock),
            outOfStock && outOfStockLabel(),
        ]
            .filter(Boolean)
            .join(' '),
) {
    const cachedResult = cache.get(product)

    if (cachedResult) {
        return cachedResult
    }

    const configurableProduct =
        'configurableOptions' in product ? product : undefined

    if (!configurableProduct) {
        return null
    }

    const attributeIdMap = getAttributeIdLookupMap(
        configurableProduct.configurableOptions,
    )

    const optionsA = configurableProduct?.configurableOptions.find(
        (item) => item.attributeCode === attributeCodeA,
    )

    const optionsB = configurableProduct?.configurableOptions.find(
        (item) => item.attributeCode === attributeCodeB,
    )

    if (!optionsA || !optionsB) {
        return null
    }

    const result = [...configurableProduct.variants]
        .map((variant, i) => {
            const attributeAIndex = getAttributeIndex(
                variant.attributes,
                attributeCodeA,
            )

            const attributeBIndex = getAttributeIndex(
                variant.attributes,
                attributeCodeB,
            )

            const options = variant.attributes.map((attribute) => [
                attributeIdMap.get(attribute.code),
                getAttributeOptionValue(attribute),
            ])

            const stockStatus = variant && variant.product
            const outOfStock = isOutOfStock(variant)
            const limitedStock = Boolean(hasLimitedStock(variant))
            const variantSku = variant.product?.sku

            return {
                value: encodeAttributeValueObject(Object.fromEntries(options)),
                label: labelGenerator({
                    a: variant.attributes[attributeAIndex].label,
                    b: variant.attributes[attributeBIndex].label,
                    outOfStock,
                    limitedStock,
                    onlyXLeftInStock: stockStatus?.onlyXLeftInStock,
                    product: configurableProduct,
                }),
                sku: variantSku,
                disabled: Boolean(outOfStock),
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

    cache.set(product, result)

    return result
}
