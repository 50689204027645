import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { ProductCardFragment } from '@emico-hooks/product-card-fragment'

import styles from './MiniCartItemRowTotal.module.scss'
import { CurrencyEnum } from '../graphql/schema.generated'
import PriceBox from '../presentation/PriceBox'

interface Props {
    product?: ProductCardFragment
    totals?: { quantity: number; rowTotalIncludingTax: number }
    cartItem?: CartItemFragment
    currency: CurrencyEnum
}

const MiniCartItemRowTotal = ({
    cartItem,
    currency,
    product,
    totals,
}: Props) => {
    const { regularPrice, discount } = product
        ? product.priceRange.maximumPrice
        : cartItem?.product?.priceRange?.maximumPrice || {}

    const actualPrice = totals
        ? totals.rowTotalIncludingTax
        : cartItem?.prices?.rowTotalIncludingTax.value ?? 0

    const originalPrice = totals
        ? totals.quantity * (regularPrice?.value ?? 0)
        : (cartItem?.quantity ?? 0) * (regularPrice?.value ?? 0)
    const hasDiscount = discount?.percentOff ? discount.percentOff > 0 : false

    return (
        <div className={styles.totals}>
            <PriceBox
                oldPrice={
                    hasDiscount
                        ? {
                              value: originalPrice,
                              currency,
                          }
                        : undefined
                }
                regularPrice={
                    !hasDiscount
                        ? {
                              value: actualPrice,
                              currency,
                          }
                        : undefined
                }
                specialPrice={
                    hasDiscount
                        ? {
                              value: actualPrice,
                              currency,
                          }
                        : undefined
                }
                className={styles.priceContainer}
                priceClassName={styles.price}
                outletAdviceprice={cartItem?.product?.outletAdviesprijs ?? null}
            />
        </div>
    )
}

export default MiniCartItemRowTotal
