import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
    GooglePlaceAutocompleteResult,
    Maybe,
    Query,
    QueryGooglePlaceAutocompleteArgs,
} from './graphql/schema.generated'

// location defaults to a place in west germany, with a radius of 2500 km
// This allows us to narrow down the search results
const query = gql`
    query GoogleMapsPlaceAutocomplete(
        $input: String!
        $language: String!
        $location: [Float!]
        $radius: Float
        $strictBounds: Boolean
    ) {
        googlePlaceAutocomplete(
            input: $input
            language: $language
            location: $location
            radius: $radius
            strictBounds: $strictBounds
        ) {
            placeId
            description
            reference
        }
    }
`

export type AutoCompleteResult = Pick<
    GooglePlaceAutocompleteResult,
    'placeId' | 'description' | 'reference'
>

export type GooglePlaceAutocompleteResultData = Override<
    Pick<Query, 'googlePlaceAutocomplete'>,
    {
        googlePlaceAutocomplete: Maybe<AutoCompleteResult[]>
    }
>

const useLazyGoogleMapsPlaceAutocomplete = (
    options?: LazyQueryHookOptions<
        GooglePlaceAutocompleteResultData,
        QueryGooglePlaceAutocompleteArgs
    >,
) => {
    // eslint-disable-next-line no-restricted-syntax
    const [getAutoCompleteResults, { data, error, loading }] = useLazyQuery<
        GooglePlaceAutocompleteResultData,
        QueryGooglePlaceAutocompleteArgs
    >(query, {
        fetchPolicy: 'cache-first',
        ...options,
        context: getCacheableContext(),
    })

    if (error) {
        throw error
    }

    return {
        data: data?.googlePlaceAutocomplete,
        loading,
        getAutoCompleteResults,
    }
}

export default useLazyGoogleMapsPlaceAutocomplete
