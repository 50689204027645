import { isAbsolute } from './url'

if (process.env.REACT_APP_MAGENTO_BACKEND_MEDIA_BASE === undefined) {
    throw new Error(
        'Missing the environment variable "REACT_APP_MAGENTO_BACKEND_MEDIA_BASE".' +
            ' A value is required, but an empty string is considered a valid value.',
    )
}

export enum MediaType {
    Product,
    Category,
    AvailablePaymentMethod,
    CheckoutPaymentMethod,
    Bank,
}

// You can override these paths in environment variables, but these should
// generally be standard Magento paths
const basePathByMediaType = {
    [MediaType.Product]:
        process.env.REACT_APP_MAGENTO_BACKEND_MEDIA_PATH_PRODUCT ||
        '/media/catalog/product',
    [MediaType.Category]:
        process.env.REACT_APP_MAGENTO_BACKEND_MEDIA_PATH_CATEGORY ||
        '/media/catalog/category',
    [MediaType.AvailablePaymentMethod]:
        process.env
            .REACT_APP_MAGENTO_BACKEND_MEDIA_PATH_FOOTER_PAYMENT_METHODS ||
        '/media/payment_images',
    [MediaType.CheckoutPaymentMethod]:
        process.env.REACT_APP_MAGENTO_BACKEND_MEDIA_PATH_PAYMENT_METHODS ||
        '/media/payment_images',
    // TODO: Move bank images out of Magento into the repo, like 404 error
    //  images
    [MediaType.Bank]:
        process.env.REACT_APP_MAGENTO_BACKEND_MEDIA_PATH_BANK || '/media/banks',
}

/**
 * Turns a relative URL from the API into an absolute URL that can be used by
 * our CDN.
 * @param type The type of the media. This depends on what endpoint the URL was
 * received from.
 * @param path The relative path of the media as received by the API.
 */
const makeMediaUrl = (path: string, type?: MediaType) => {
    if (isAbsolute(path)) {
        return path
    }

    const basePath = type !== undefined ? basePathByMediaType[type] : ''

    if (path.startsWith('/')) {
        path = path.substr(1)
    }

    return `${process.env.REACT_APP_MAGENTO_BACKEND_MEDIA_BASE}${basePath}/${path}`
}

export default makeMediaUrl
