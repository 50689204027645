import { gql, TypedDocumentNode } from '@apollo/client'

import { CartItemFragment } from './CartItemFragment.generated'

export const cartItemFragment = gql`
    fragment CartItemFragment on CartItemInterface {
        id
        uid
        errors {
            code
            message
        }
        ... on ConfigurableCartItem {
            configurableOptions {
                configurableProductOptionUid
                configurableProductOptionValueUid
                optionLabel
                valueLabel
            }
            configuredVariant {
                uid
                id
                sku
            }
        }
        extensionAttributes {
            isPersonalShopperBoxItem
            isFreeGift
            personalShopperBoxItemId
        }

        product {
            id
        }

        prices {
            price {
                currency
                value
            }
            rowTotal {
                currency
                value
            }
            rowTotalIncludingTax {
                currency
                value
            }
            totalItemDiscount {
                currency
                value
            }
            discounts {
                label
                amount {
                    currency
                    value
                }
            }
        }
        quantity
        reservation {
            salableQty
            reservationQty
            isSalable
        }
    }
` as TypedDocumentNode<CartItemFragment, never>
