import * as React from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useActiveStoreView, useStoreViews } from '@emico/storeviews'

import HomepageCanonical from './HomepageCanonical'
import { PageType } from '../layout/PageWrapper/PageWrapper'
import paths from '../paths'

interface OwnProps {
    type: PageType
}

type Props = OwnProps

// Default implementation of the alternate and canonical links
// changes on every pathname

// more info:
// https://yoast.com/rel-canonical/
// https://yoast.com/hreflang-ultimate-guide/
// https://support.google.com/webmasters/answer/189077?hl=nl

const Canonicals = ({ type }: Props) => {
    const { makeUrl, hrefLang, locale, language } = useActiveStoreView()
    const storeViews = useStoreViews()
    const location = useLocation()

    // Category pages and product pages have their own canonical implementation
    if (type === 'category' || type === 'product') {
        return null
    }

    // Check if we are on the homepage, and if so, use homepage canonicals
    // Tried moving this logic to CmsPage component but then the product dialog helmet code
    // does not correctly override the homepage helmet code, resulting in homepage helmet code being present
    if (location.pathname === paths.home) {
        return (
            <>
                {storeViews.map((storeView) => (
                    <HomepageCanonical
                        key={storeView.code}
                        storeView={storeView}
                    />
                ))}
            </>
        )
    }

    const href = makeUrl(location.pathname)

    return (
        <Helmet>
            <link rel="alternate" href={href} hrefLang={hrefLang} />

            <link
                rel="alternate"
                href={href}
                hrefLang={
                    hrefLang === 'x-default' ? locale || language : 'x-default'
                }
            />

            <link rel="canonical" href={href} />
        </Helmet>
    )
}

export default Canonicals
