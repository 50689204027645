import cx from 'classnames'
import * as React from 'react'

import styles from './Stores.module.scss'
import StoreTab from './StoreTab'
import { Responsive } from '../../../core/Responsive'
import Button from '../../../input/Button'
import Col from '../../../layout/Col'
import Row from '../../../layout/Row'
import { CmsPageStores, CmsPageStoresItem } from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'
import { filterNullValues } from '../../../utils/filterNullValues'

const getStoreButtons = (
    stores: CmsPageStoresItem[],
    activeStore: number,
    setActiveStore: (i: number) => void,
) =>
    stores.map((store, index) => {
        const activeClass = activeStore === index ? styles.active : ''

        return (
            <div
                className={cx(activeClass, styles.storeButton)}
                onClick={() => setActiveStore(index)}
                key={index}
            >
                {store.name && store.name}
            </div>
        )
    })

const getStoreContent = (stores: CmsPageStoresItem[], activeStore: number) =>
    stores.map((store, index) => (
        <StoreTab
            {...store}
            isActive={activeStore === index}
            index={index}
            key={index}
        />
    ))

const Stores = ({
    heading,
    subheading,
    buttonUrl,
    buttonText,
    store,
}: CmsPageStores) => {
    const [activeStore, setActiveStore] = React.useState<number>(0)
    const filteredStore = filterNullValues(store) || []

    return (
        <Row noGutters className={styles.default}>
            <Col>
                <div className={styles.storesWrapper}>
                    {heading && (
                        <Heading
                            variant="h2"
                            element="h2"
                            color="dark"
                            className={styles.heading}
                        >
                            {heading}
                        </Heading>
                    )}

                    {subheading && (
                        <Heading
                            variant="subheading"
                            element="h3"
                            color="dark"
                            className={styles.subHeading}
                        >
                            {subheading}
                        </Heading>
                    )}

                    {filteredStore && filteredStore.length > 0 && (
                        <div className={styles.storeWrapper}>
                            <div className={styles.storeButtonsWrapper}>
                                <div className={styles.storeButtons}>
                                    {getStoreButtons(
                                        filteredStore,
                                        activeStore,
                                        setActiveStore,
                                    )}
                                    <Responsive lg up>
                                        {buttonUrl && buttonText && (
                                            <Button
                                                minWidth
                                                to={buttonUrl}
                                                variant="primary"
                                                name="findStoreButton"
                                                category="cms.homePage.stores"
                                                className={styles.button}
                                            >
                                                {buttonText}
                                            </Button>
                                        )}
                                    </Responsive>
                                </div>
                            </div>
                            <div className={styles.storeContent}>
                                {getStoreContent(filteredStore, activeStore)}
                            </div>
                            <Responsive md down>
                                {buttonUrl && buttonText && (
                                    <Button
                                        minWidth
                                        to={buttonUrl}
                                        variant="primary"
                                        name="findStoreButton"
                                        category="cms.homePage.stores"
                                        className={styles.button}
                                    >
                                        {buttonText}
                                    </Button>
                                )}
                            </Responsive>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default Stores
