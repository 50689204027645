// Source: https://github.com/magento-research/pwa-studio/blob/develop/packages/venia-concept/src/actions/cart/actions.js
import { createAction } from 'redux-actions'

export default {
    getDetails: {
        receive: createAction('CART_GET_DETAILS_RECEIVE'),
    },
    removeItem: {
        request: createAction('CART_REMOVE_ITEM_REQUEST'),
        receive: createAction('CART_REMOVE_ITEM_RECEIVE'),
    },
    updateItem: {
        request: createAction('CART_UPDATE_ITEM_REQUEST'),
        receive: createAction('CART_UPDATE_ITEM_RECEIVE'),
    },
    miniCart: {
        open: createAction('CART/MINI_CART/OPEN'),
        close: createAction('CART/MINI_CART/CLOSE'),
    },
    updateGift: {
        request: createAction('CART_UPDATE_GIFT_REQUEST'),
        receive: createAction('CART_UPDATE_GIFT_RECEIVE'),
    },
    reset: createAction('CART_RESET'),
}
