import { useIsLoggedIn } from '@emico-hooks/login-token'
import { omitAuthRoutes } from 'components/src/utils/omitAuthRoutes'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { UrlRewriteEntityTypeEnum } from '@emico/graphql-schema-types'
import { isAbsolute } from '@emico/url'
import { UnreachableCaseError } from '@emico/utils'

import { CmsPage, LazyCategoryPage, LazyProductPage } from '../chunks'
import PageWrapper from '../layout/PageWrapper'
import NotFoundPage from '../NotFoundPage'
import paths from '../paths'
import PageLoader from '../presentation/PageLoader'
import PrismicPage from '../PrismicPage'
import { useResolveUrl } from '../utils/resolveUrl'
import withSentry from '../utils/withSentry'

/**
 * A "dynamic route" in our application means a server-side configured route.
 * We have a bunch of static routes such as /cart, /checkout and user routes
 * and all other routes are dynamically configured server-side. This allows
 * admins to make CMS pages, categories or products at any route they desire,
 * except for pre-existing static routes.
 *
 * This component resolves the received URL into a page type and id.
 */
const DynamicRouteResolver = ({ location }: RouteComponentProps) => {
    const { pathname } = location
    const { data: resolvedPage, loading, error } = useResolveUrl(pathname)
    const isLoggedIn = useIsLoggedIn() || omitAuthRoutes()

    if (error) {
        return <NotFoundPage />
    }

    if (!resolvedPage && loading) {
        return (
            <PageWrapper pageType="N/A">
                <PageLoader fullScreen reason="Resolving url..." />
            </PageWrapper>
        )
    }
    if (!resolvedPage) {
        return <NotFoundPage />
    }

    const { type, id, redirectCode, relativeUrl, prismicType, authRequired } =
        resolvedPage

    if (!type) {
        return <NotFoundPage />
    }

    if (authRequired && !isLoggedIn) {
        return <Redirect to={paths.login} />
    }

    // Handle possible redirects
    // A redirect code and url can also be supplied when type is something other than REDIRECT
    // So we need to handle it here, instead of in the REDIRECT case below.
    if (redirectCode && relativeUrl) {
        // External URL redirects don't work in Magento 2.4.0, this might change in
        // the future since the documentation explains how to do it.
        // TODO: Add link to mentioned documentation
        if (isAbsolute(relativeUrl)) {
            window.location.href = relativeUrl
            return null
        }
        return <Redirect to={relativeUrl} />
    }

    switch (type) {
        case UrlRewriteEntityTypeEnum.REDIRECT:
            // Redirect does not have the correct data.
            // Log an error and redirect to homepage
            withSentry((Sentry) => {
                Sentry.withScope((scope: SentryScope) => {
                    scope.setExtras({
                        resolvedPage,
                        pathname,
                    })
                    Sentry.captureMessage(
                        'Error when trying to redirect user',
                        'error',
                    )
                })
            })

            return <Redirect to={paths.home} />
        case UrlRewriteEntityTypeEnum.CATEGORY:
            if (!id) {
                return <NotFoundPage />
            }

            return <LazyCategoryPage id={id} />
        case UrlRewriteEntityTypeEnum.PRODUCT:
            if (!id) {
                return <NotFoundPage />
            }
            return <LazyProductPage id={id} />
        case UrlRewriteEntityTypeEnum.CMS_PAGE:
            if (!id) {
                return <NotFoundPage />
            }

            // TODO: Fetch by url_key
            return <CmsPage id={id} />
        case UrlRewriteEntityTypeEnum.PRISMIC:
            if (!prismicType) {
                // Every prismic page should have a type

                console.warn(
                    `A prismic page is being served at ${
                        relativeUrl || pathname
                    } but it has no prismic type.`,
                )
                return <NotFoundPage />
            }

            return (
                <PrismicPage
                    urlKey={relativeUrl || pathname}
                    prismicType={prismicType}
                />
            )
        default:
            throw new UnreachableCaseError(type)
    }
}

export default DynamicRouteResolver
