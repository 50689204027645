import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { HTMLAttributes } from 'react'

import styles from './MiniCartItem.module.scss'
import MiniCartItemContainer from './MiniCartItemContainer'
import MiniCartItemDetails from './MiniCartItemDetails'
import MiniCartItemImage from './MiniCartItemImage'
import MiniCartItemRowTotal from './MiniCartItemRowTotal'
import MiniCartItemSelectedOptions from './MiniCartItemSelectedOptions'
import { getGiftRuleLabel } from './NextGiftNotification'
import { CurrencyEnum } from '../graphql/schema.generated'
import PageLoader from '../presentation/PageLoader'
import Text from '../typography/Text'
import useFreeGiftRules from '../useFreeGiftRules'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    cartItem?: CartItemFragment
    currency: CurrencyEnum
    className?: string
    variant?: 'gift' | 'item'
}

const MiniCartItem = ({
    cartItem,
    currency,
    className,
    variant = 'item',
    ...others
}: Props) => {
    const { rules } = useFreeGiftRules()
    const { product } = cartItem || {}
    const isGift = variant === 'gift'

    if (!product) {
        return <PageLoader className={styles.loader} />
    }

    if (!cartItem) {
        return null
    }

    const description = isGift ? (
        <Text variant="secondary">
            {
                getGiftRuleLabel(
                    rules.find((r) =>
                        r.products.find((p) => p.id === product.id),
                    )?.label,
                )[0]
            }
        </Text>
    ) : (
        product.sku
    )

    return (
        <MiniCartItemContainer className={className} {...others}>
            <MiniCartItemImage product={product} />

            <MiniCartItemDetails
                product={product}
                description={description}
                details={<MiniCartItemSelectedOptions cartItem={cartItem} />}
                footer={
                    !isGift && (
                        <MiniCartItemRowTotal
                            cartItem={cartItem}
                            currency={currency}
                        />
                    )
                }
                {...others}
            />
        </MiniCartItemContainer>
    )
}

export default MiniCartItem
