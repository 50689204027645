import cx from 'classnames'
import * as React from 'react'

import styles from './FullscreenModal.module.scss'

interface Props {
    children: React.ReactNode
    className?: string
}

const FullscreenModal = ({ children, className }: Props) => (
    <div className={cx(styles.fullscreenModal, className)}>{children}</div>
)

export default FullscreenModal
