import cx from 'classnames'
import * as React from 'react'

import styles from './FilterButton.module.scss'
import DropdownIcon from '../../../../../../core/DropdownIcon'
import Button from '../../../../../../input/Button'
import Icon from '../../../../../../media/Icon'

interface OwnProps {
    children: React.ReactNode
    isActive?: boolean
    hasValue?: boolean
    className?: string
}
type Props = OwnProps & React.ComponentProps<typeof Button>

const FilterButton = ({
    children,
    isActive,
    hasValue,
    className,
    ...others
}: Props) => (
    <Button
        className={cx(
            styles.default,
            {
                [styles.active]: isActive,
                [styles.hasValue]: hasValue,
            },
            className,
        )}
        data-testid="filterButton"
        {...others}
    >
        {children}
        <Icon component={DropdownIcon} title="" className={styles.chevron} />
    </Button>
)

export default FilterButton
