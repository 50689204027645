import { ApolloClient, gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { Maybe } from '@emico/graphql-schema-types'
import { stripMaybes } from '@emico/utils'

import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from './catalog/common/MinimalConfigurableProductInfo.fragment'
import { BasicConfigurableProduct } from './catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from './catalog/ProductPage/GetBasicProduct.query'
import { ProductAttributeFilterInput } from './graphql/schema.generated'
import productCardFragment from './ProductCardFragment'

const QueryProduct = gql`
    query ProductsBySku($filter: ProductAttributeFilterInput!) {
        products(filter: $filter) {
            items {
                ...ProductCardFragmentJB
                ...ConfigurableProductInfo
            }
        }
    }
    ${productCardFragment}
    ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
`

interface Products {
    products: {
        items: Maybe<Array<BasicProduct | BasicConfigurableProduct>>
    }
}

const useProductsBySku = (
    arrSku: string[],
    options?: Pick<QueryHookOptions, 'skip'>,
) => {
    const { data, ...others } = useQuery<Products>(QueryProduct, {
        variables: {
            filter: {
                sku: {
                    in: arrSku,
                },
            },
        },
        ...options,
        context: getCacheableContext(),
        errorPolicy: 'all',
    })

    return {
        ...others,
        data: data?.products?.items?.filter(stripMaybes),
    }
}

export const getProductsBySku = (
    apolloClient: ApolloClient<unknown>,
    skus: string[],
) =>
    apolloClient.query<Products, { filter: ProductAttributeFilterInput }>({
        query: QueryProduct,
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        variables: { filter: { sku: { in: skus } } },
    })

export default useProductsBySku
