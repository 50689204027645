import {
    ComponentProps,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react'

import styles from './BlueConicComponent.module.scss'
import RecommendedProducts from './catalog/RecommendedProducts'
import { CLOSE_TIME_CART_BUTTON } from './presentation/CartButton'
import { ImageContentCard } from './presentation/ImageContentCard'
import { NewsletterContentCard } from './presentation/NewsletterContentCard'
import RecommendedProductsBlueConic from './RecommendedProducts/RecommendedProductsBlueConic'
import SizeInformationBlueConic from './SizeInformationBlueConic'
import TooltipBlueConic from './TooltipBlueConic'
import {
    BlueConicPosition,
    BlueConicDataItem,
    BlueConicFlavorBlockInteractionData,
    BlueConicPdpSliderInteractionData,
    BlueConicProductSliderInteractionData,
    BlueConicTooltipInteractionData,
    BlueConicSizeInformationInteractionData,
} from './types'

type BlueConicComponentProps =
    | ComponentProps<typeof ImageContentCard>
    | ComponentProps<typeof RecommendedProducts>
    | ComponentProps<typeof NewsletterContentCard>
    | ComponentProps<typeof RecommendedProductsBlueConic>
    | ComponentProps<typeof SizeInformationBlueConic>

export type Props = {
    blueConicPosition: BlueConicPosition
    fallBack: ReactElement
} & BlueConicComponentProps

// Helper function to find blueconic data based on component type and position
const getBlueConicData = (position: string) =>
    (document?.blueConicData || []).find((block) => block.position === position)

const BlueConicComponent = ({
    blueConicPosition,
    fallBack,
    ...props
}: Props) => {
    // Get the data that is already defined in blueconic, or else get it from prismic.
    const [componentData, setComponentData] = useState<
        BlueConicDataItem | undefined
    >(getBlueConicData(blueConicPosition))

    const handleBlueConicDataModified = useCallback(() => {
        // Find most recent data for this block.
        const block = getBlueConicData(blueConicPosition)

        // Check if theres data and if the uuid's are different.
        // If uuid's are the same, block already contains correct data
        // so no need to update then.
        if (block && block.interactionId !== componentData?.interactionId) {
            setComponentData(block)

            if (block.type === 'tooltip') {
                // Tooltips should only be shown once.
                // Remove it from the blueconic data array,
                // otherwise it will trigger again when component is remounted.
                document.blueConicData =
                    document?.blueConicData?.filter(
                        (item) => item.interactionId !== block.interactionId,
                    ) || []
            }
        }
    }, [componentData?.interactionId, blueConicPosition])

    // Measure interaction with BlueConic component
    // This registers all clicks within the node tree
    const handleBlueConicClick = useCallback(() => {
        if (componentData?.interactionId && window.blueConicClient) {
            window.blueConicClient.createEvent(
                'CLICK',
                componentData.interactionId,
            )
        }
    }, [componentData?.interactionId])

    // Monitor blueconic for data changes and update data when applicable
    useEffect(() => {
        document.addEventListener(
            'blueConicDataModified',
            handleBlueConicDataModified,
        )

        return () => {
            document.removeEventListener(
                'blueConicDataModified',
                handleBlueConicDataModified,
            )
        }
    }, [handleBlueConicDataModified])

    useEffect(() => {
        if (componentData?.interactionId) {
            window.blueConicClient?.createEvent(
                'VIEW',
                componentData.interactionId,
            )
        }
    }, [componentData?.interactionId])

    if (!componentData) {
        return fallBack
    }

    if (componentData.type === 'flavorblock') {
        const {
            withEmailSubscription,
            imageUrl,
            mobileImageUrl,
            title,
            subtitle,
            imageAlt,
            buttonText,
            buttonLink,
            source,
            thankYouTitle,
            thankYouSubTitle,
        } = componentData.data as BlueConicFlavorBlockInteractionData

        if (withEmailSubscription === 'true' && imageUrl) {
            return (
                <div className={styles.target} onClick={handleBlueConicClick}>
                    <NewsletterContentCard
                        {...props}
                        source={source}
                        imageUrl={imageUrl}
                        mobileImageUrl={mobileImageUrl}
                        header={title}
                        thankYouHeader={thankYouTitle}
                        thankYouSubheader={thankYouSubTitle}
                        subheader={subtitle}
                    />
                </div>
            )
        }

        return (
            <div className={styles.target} onClick={handleBlueConicClick}>
                <ImageContentCard
                    {...props}
                    imageUrl={imageUrl}
                    imageMobileUrl={mobileImageUrl}
                    altText={imageAlt}
                    header={title}
                    subheader={subtitle}
                    buttonText={buttonText}
                    buttonLink={buttonLink}
                />
            </div>
        )
    }

    if (componentData.type === 'homePageSlider') {
        const { skus, title, content, buttonLink, buttonText } =
            componentData.data as BlueConicProductSliderInteractionData

        return (
            <div className={styles.target} onClick={handleBlueConicClick}>
                <RecommendedProductsBlueConic
                    {...props}
                    skus={skus}
                    header={title ?? ''}
                    buttonText={buttonText}
                    buttonUrl={buttonLink}
                    content={content}
                    templateId={0}
                    itemListSlot={componentData.type}
                    onClick={() => {
                        window.blueConicClient?.createEvent(
                            'CLICK',
                            componentData.interactionId,
                        )
                    }}
                />
            </div>
        )
    }

    if (componentData.type === 'pdpSlider') {
        const { skus, title } =
            componentData.data as BlueConicPdpSliderInteractionData

        return (
            <div className={styles.target} onClick={handleBlueConicClick}>
                <RecommendedProducts
                    {...(props as ComponentProps<typeof RecommendedProducts>)}
                    header={title ?? ''}
                    blueConicSkus={skus}
                    itemListSlot={componentData.type}
                />
            </div>
        )
    }

    if (componentData.type === 'cartSlider') {
        const { skus, title } =
            componentData.data as BlueConicPdpSliderInteractionData

        return (
            <div className={styles.target} onClick={handleBlueConicClick}>
                <RecommendedProducts
                    {...(props as ComponentProps<typeof RecommendedProducts>)}
                    header={title ?? ''}
                    blueConicSkus={skus}
                    itemListSlot={componentData.type}
                />
            </div>
        )
    }

    if (componentData.type === 'tooltip') {
        const {
            title,
            content,
            buttonText,
            buttonUrl,
            button2Text,
            button2Url,
            imageUrl,
            mobileImageUrl,
            uspText,
            displayDuration,
        } = componentData.data as BlueConicTooltipInteractionData

        return (
            <div className={styles.target}>
                <TooltipBlueConic
                    {...props}
                    title={title}
                    content={content}
                    buttonText={buttonText}
                    buttonUrl={buttonUrl}
                    button2Text={button2Text}
                    button2Url={button2Url}
                    uspText={uspText}
                    imageUrl={imageUrl}
                    mobileImageUrl={mobileImageUrl}
                    displayDuration={
                        displayDuration
                            ? displayDuration * 1000
                            : CLOSE_TIME_CART_BUTTON
                    }
                    interactionId={componentData.interactionId}
                />
            </div>
        )
    }

    if (componentData.type === 'sizeInformation') {
        const { content } =
            componentData.data as BlueConicSizeInformationInteractionData

        return content ? (
            <SizeInformationBlueConic {...props} content={content} />
        ) : null
    }
    // ... other implementations can go here

    return fallBack
}

export default BlueConicComponent
