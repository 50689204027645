import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { ProductStockStatus } from '../../../graphql/schema.generated'
import {
    ConfigurableAttributeOption,
    ConfigurableAttributeValue,
    ConfigurableVariant,
} from '../../common/MinimalConfigurableProductInfo.fragment'
import { ConfigurableProduct } from '../ConfigurableProduct'

export const limitedStockLabel = (quantity: number) =>
    t({
        id: 'core.configurableOptionSelect.limitedAvailabilityLabel',
        message: `(only ${quantity} left)`,
    })
export const outOfStockLabel = () =>
    t({
        id: 'core.configurableOptionSelect.outOfStockLabel',
        message: `(sold out)`,
    })

export const isOutOfStock = (variant: ConfigurableVariant | undefined) =>
    !variant?.product ||
    variant.product.stockStatus === ProductStockStatus.OUT_OF_STOCK ||
    variant.product.onlyXLeftInStock === 0

export const hasLimitedStock = (variant: ConfigurableVariant | undefined) =>
    variant?.product?.onlyXLeftInStock && variant.product.onlyXLeftInStock <= 5
/**
 * @deprecated You no longer need to look up the variant, you already have variants with the info
 * you need. Do not try to match configurable options to variants.
 */
export const getVariant = (
    variants: ConfigurableVariant[],
    values: ConfigurableAttributeValue[],
    lazy: boolean = false,
) =>
    variants.find((variant) => {
        const filter = (attribute: ConfigurableAttributeOption) =>
            values.some((value) => value.valueIndex === attribute.valueIndex)

        return lazy
            ? variant.attributes.some(filter)
            : variant.attributes.every(filter)
    })

export const CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE = 'size'
export const CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE = 'length'

export const shouldCombineWidthAndLength = (
    configurableProduct: ConfigurableProduct,
) => {
    const hasWidth = configurableProduct.configurableOptions.some(
        (item) =>
            item.attributeCode === CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    )

    const hasLength = configurableProduct.configurableOptions.some(
        (item) =>
            item.attributeCode === CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    )

    return hasWidth && hasLength
}
