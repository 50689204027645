import * as React from 'react'

import linkClick from '../utils/googleTagManager/linkClick'
import push from '../utils/googleTagManager/push'

interface Props
    extends React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    > {
    // https://html.spec.whatwg.org/#the-a-element "If the a element has no href attribute, then the element represents a placeholder for where a link might otherwise have been placed, if it had been relevant, consisting of just the element's contents."
    to?: string
    children: React.ReactNode
    name: string
    category: string
}

const ExternalLink = ({
    to,
    children,
    onClick,
    name,
    category,
    target,
    ...others
}: Props) => (
    <a
        href={to}
        target={target}
        onClick={
            to !== undefined
                ? (e) => {
                      push(linkClick(name, category, 'external'))
                      if (onClick) {
                          onClick(e)
                      }
                  }
                : undefined
        }
        {...others}
    >
        {children}
    </a>
)

export default ExternalLink
