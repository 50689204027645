import cx from 'classnames'
import { ReactNode } from 'react'

import styles from './toaster-container.module.scss'
import { ToastPosition } from './types'

interface Props {
    children: ReactNode
    position: ToastPosition
}

export const ToasterContainer = ({ children, position }: Props) => {
    const [vPos, hPos] = position?.split('-') ?? []

    return (
        <div
            role="alert"
            className={cx(
                styles.container,
                vPos && styles[vPos],
                hPos && styles[hPos],
            )}
        >
            {children}
        </div>
    )
}
