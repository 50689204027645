import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { useBreakpoints } from '@emico/ui'

import Text from '../typography/Text'

interface Props {
    trigger(value: boolean): void
}

const SearchFieldTrigger = ({ trigger }: Props) => {
    const { isMobile } = useBreakpoints()

    return (
        <Text
            as="span"
            color="dark"
            css={css`
                display: flex;
                align-items: center;
                flex: auto;
                height: 40px;
                cursor: pointer;
                margin: 0;
                ${isMobile ? 'color: #dcdcdc;' : ''}
            `}
            onClick={() => trigger(true)}
        >
            {!isMobile &&
                t({
                    id: 'storelocator.searchFieldTrigger.findAStore',
                    message: `Find a store`,
                })}
            {isMobile &&
                t({
                    id: 'storelocator.searchFieldTrigger.findAStoreMobile',
                    message: `City/postalcode`,
                })}
        </Text>
    )
}

export default SearchFieldTrigger
