import { ComponentType } from 'react'

import styles from './Header.module.scss'
import Icon from '../../media/Icon'
import Link from '../../navigation/Link'

interface Props {
    icon: ComponentType
    title: string
    counter?: number
    name: string
    to: string
    content?: string
    position?: 'wishlist' | 'storelocator' | 'account'
}

const HeaderLink = ({
    icon,
    counter,
    title,
    name,
    to,
    position,
    content,
}: Props) => {
    let badgePosition

    switch (position) {
        case 'wishlist':
            badgePosition = 'items-wishlist'
            break
    }

    return (
        <Link
            name={name}
            category="header"
            to={to}
            className={styles.link}
            data-bc-position={position}
        >
            {content && (
                <span className={styles.linkText} title={content}>
                    {content}
                </span>
            )}
            <Icon
                component={icon}
                title={title}
                className={styles.icon}
                content={
                    (counter ?? 0) > 0 && (
                        <span data-bc-position={badgePosition}>{counter}</span>
                    )
                }
            />
        </Link>
    )
}

export default HeaderLink
