import ReactBreakpoints from 'react-breakpoints'

import breakpoints from '../../theme/breakpoints'

interface Props {
    children: React.ReactNode
}

const ResponsiveProvider = ({ children }: Props) => (
    <ReactBreakpoints breakpoints={breakpoints}>{children}</ReactBreakpoints>
)

export default ResponsiveProvider
