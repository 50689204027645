import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import styles from './Hero.module.scss'
import getPrismicButtonVariant from '../../../getPrismicButtonVariant'
import getPrismicHeadingColor from '../../../getPrismicHeadingColor'
import { PageBodyProductHeroPrimary } from '../../../graphql/schema.generated'
import Button from '../../../input/Button'
import Col from '../../../layout/Col'
import Row from '../../../layout/Row'
import Heading from '../../../typography/Heading'
import useSelectPromotionEvent from '../../../utils/ga4/useSelectPromotionEvent'
import { useViewPromotionEvent } from '../../../utils/ga4/useViewPromotionEvent'
import HeroImage from '../HeroImage'

interface Props extends PageBodyProductHeroPrimary {
    className?: string
}

const ButtonWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 2em;
    width: 100%;

    & > * {
        flex: 0 0 auto;
    }

    @media screen and (min-width: 768px) {
        flex-flow: row nowrap;
    }
`

interface RenderContentProps {
    content: string
    locationId: string
    textStyle?: string
    buttonStyle?: string
    buttonText?: string
    buttonUrl?: string
    button2Text?: string
    button2Url?: string
    subheading?: string
    subheadingStyle?: string
}

export const RenderContent = ({
    content,
    locationId,
    textStyle,
    buttonStyle,
    buttonText,
    buttonUrl,
    button2Text,
    button2Url,
    subheading,
    subheadingStyle,
}: RenderContentProps) => {
    // Map text / button style to valid properties
    // Also set a sensible default
    const headingColor = getPrismicHeadingColor(textStyle)
    const subheadingColor = getPrismicHeadingColor(subheadingStyle)
    const buttonVariant = getPrismicButtonVariant(buttonStyle)

    const pushSelectPromotion = useSelectPromotionEvent()

    let headingText = content
    let subheadingText = subheading

    // TODO: remove this logic in favor of the subheading field.
    // Now it is in prismic, it should be OK, but check nonetheless
    if (content.includes(',')) {
        const contentParts: string[] = content.split(',')

        headingText = contentParts[0]
        subheadingText = contentParts[1]
    }

    const handleButtonClick = (position: 1 | 2) =>
        pushSelectPromotion({
            item_id: locationId,
            location_id: locationId,
            promotion_name: `${content} ${subheading}`,
            creative_name:
                position === 1
                    ? `${buttonText}:${buttonUrl}`
                    : `${button2Text}:${button2Url}`,
        })

    return (
        <>
            <Heading
                element="h1"
                variant="sale"
                color={headingColor}
                fuss={['pme', 'vanguard', 'castironLight']}
            >
                {headingText}
            </Heading>
            {subheadingText && (
                <Heading element="h2" variant="h2" color={subheadingColor}>
                    {subheadingText}
                </Heading>
            )}
            <ButtonWrapper>
                {buttonUrl && buttonText && (
                    <Button
                        minWidth
                        to={buttonUrl}
                        variant={buttonVariant}
                        name={buttonText}
                        category="cms.homePage.hero"
                        onClick={() => handleButtonClick(1)}
                        size="hero"
                        preload
                    >
                        {buttonText}
                    </Button>
                )}
                {button2Url && button2Text && (
                    <Button
                        minWidth
                        to={button2Url}
                        variant={buttonVariant}
                        name={button2Text}
                        category="cms.homePage.hero"
                        onClick={() => handleButtonClick(2)}
                        size="hero"
                        preload
                    >
                        {button2Text}
                    </Button>
                )}
            </ButtonWrapper>
        </>
    )
}

const Hero = ({
    content,
    image,
    mobileImage,
    buttonText,
    buttonUrl,
    button2Text,
    button2Url,
    buttonStyle,
    textStyle,
    subheadingStyle,
    subheading,
    className,
}: Props) => {
    const { pathname } = useLocation()

    const ref = useViewPromotionEvent<HTMLDivElement>({
        item_id: `hero - ${pathname}`,
        location_id: `hero - ${pathname}`,
        promotion_name: `${content} ${subheading} ${buttonText} ${button2Text}`,
        creative_name: `${image?.alt} ${image?.url} - ${mobileImage?.alt} ${mobileImage?.url}`,
    })

    return (
        <Row noGutters className={cx(styles.default, className)}>
            <Col>
                {image && image.url && (
                    <div ref={ref}>
                        <HeroImage
                            url={image.url}
                            mobile_url={mobileImage?.url || image.url}
                        />
                    </div>
                )}

                <div className={styles.content}>
                    {content && (
                        <RenderContent
                            content={content}
                            textStyle={textStyle ?? undefined}
                            locationId={`hero - ${pathname}`}
                            buttonStyle={buttonStyle ?? undefined}
                            buttonText={buttonText ?? undefined}
                            buttonUrl={buttonUrl ?? undefined}
                            button2Text={button2Text ?? undefined}
                            button2Url={button2Url ?? undefined}
                            subheading={subheading ?? undefined}
                            subheadingStyle={subheadingStyle ?? undefined}
                        />
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default Hero
