import { ComponentProps } from 'react'

import { RichTextBlock } from '@emico/prismic'

import {
    CastironPageBodyHtml,
    JbfoPageBodyHtml,
    JustbrandsPageBodyHtml,
    MonstersalePageBodyHtml,
    PageBodyHtml,
    PmeOutletPageBodyHtml,
    StorelocatorPageBodyHtml,
    StorePageBodyHtml,
    VanguardPageBodyHtml,
} from './graphql/schema.generated'
import PrismicHtml from './PrismicHtml'
import getLayoutStyle from './utils/getLayoutStyle'

type PrismicSliceHtmlType =
    | PageBodyHtml
    | VanguardPageBodyHtml
    | CastironPageBodyHtml
    | JustbrandsPageBodyHtml
    | JbfoPageBodyHtml
    | MonstersalePageBodyHtml
    | PmeOutletPageBodyHtml
    | StorePageBodyHtml
    | StorelocatorPageBodyHtml

interface Props {
    slice: PrismicSliceHtmlType
}

const PrismicSliceHtml = ({ slice: { primary } }: Props) => {
    if (!primary || !primary?.html) {
        return null
    }

    const { html, style } = primary
    const richTextBlock = html as RichTextBlock[]

    const htmlContent =
        richTextBlock?.[0] &&
        'text' in richTextBlock[0] &&
        richTextBlock[0].text

    if (!htmlContent) {
        return null
    }

    const componentProps: ComponentProps<typeof PrismicHtml> = {
        style: getLayoutStyle(style),
        html: htmlContent ?? undefined,
    }

    return <PrismicHtml {...componentProps} />
}

export default PrismicSliceHtml
