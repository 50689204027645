import cx from 'classnames'
import * as React from 'react'

import styles from './Brands.module.scss'
import BrandTab from './BrandTab'
import ImageLazy from '../../../media/ImageLazy'
import { CmsPageBrand, CmsPageBrandItem } from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'
import { filterNullValues } from '../../../utils/filterNullValues'

interface StyleProps {
    style?: string
}

const getBrandLogos = (
    brands: CmsPageBrandItem[],
    activeBrand: number,
    setActiveBrand: (i: number) => void,
) =>
    brands.map(({ logo, heading }, index) => {
        const activeClass = activeBrand === index ? styles.active : ''

        return (
            <div
                className={cx(activeClass, styles.brandLogo)}
                onClick={() => setActiveBrand(index)}
                key={index}
            >
                {logo?.url && heading && (
                    <ImageLazy
                        alt={heading}
                        images={{
                            image: logo.url,
                            regularWidth: 113,
                            regularHeight: 56,
                            mediumWidth: 113,
                            mediumHeight: 56,
                            smallWidth: 84,
                            smallHeight: 42,
                        }}
                    />
                )}
            </div>
        )
    })

const getBrandContent = (brands: CmsPageBrandItem[], activeBrand: number) =>
    brands.map((brand, index) => (
        <BrandTab
            key={index}
            {...brand}
            isActive={activeBrand === index}
            index={index}
        />
    ))

const Brands = ({
    heading,
    subheading,
    brands,
    style,
}: CmsPageBrand & StyleProps) => {
    const [activeBrand, setActiveBrand] = React.useState<number>(0)

    // Only show brands that have a logo set
    const filteredBrands = filterNullValues(brands)?.filter(
        (brand) => brand.logo?.url,
    )

    if (!filteredBrands || filteredBrands.length === 0) {
        return null
    }

    return (
        <div className={cx(style ? styles[style] : '', styles.default)}>
            <div className={styles.brandsWrapper}>
                {heading && (
                    <Heading
                        variant="h2"
                        element="h2"
                        color="dark"
                        className={styles.heading}
                    >
                        {heading}
                    </Heading>
                )}

                {subheading && (
                    <Heading
                        variant="subheadingAlt"
                        element="h3"
                        color="dark"
                        className={styles.subHeading}
                    >
                        {subheading}
                    </Heading>
                )}

                <div className={styles.brandWrapper}>
                    <div
                        className={cx(
                            style ? styles[style] : '',
                            styles.brandLogos,
                        )}
                    >
                        {getBrandLogos(
                            filteredBrands,
                            activeBrand,
                            setActiveBrand,
                        )}
                    </div>
                    <div className={styles.brandContent}>
                        {getBrandContent(filteredBrands, activeBrand)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brands
