import { DLVFilterInteraction } from './types'

export default function filterInteraction({
    name,
    category,
    value = '',
}: {
    name: string
    category: string
    value?: string
}): DLVFilterInteraction {
    return {
        event: 'filterInteraction',
        filterName: name,
        filterCategory: category,
        filterValue: value,
    }
}
