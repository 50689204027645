import { ComponentProps } from 'react'

import { useBreakpoints } from '@emico/ui'

import { VanguardPageBodyTwoimageswithheading } from './graphql/schema.generated'
import Twoimageswithheading from './Twoimageswithheading'

interface Props {
    slice: VanguardPageBodyTwoimageswithheading
}

const PrismicSliceTwoimageswithheading = ({ slice: { primary } }: Props) => {
    const { isDesktop } = useBreakpoints()

    if (!primary) {
        return null
    }

    const {
        firstimage,
        firstmobileimage,
        secondimage,
        secondmobileimage,
        heading,
    } = primary

    if (isDesktop && (!firstimage?.url || !secondimage?.url)) {
        return null
    }
    if (!isDesktop && (!firstmobileimage?.url || !secondmobileimage?.url)) {
        return null
    }

    if (!heading) {
        return null
    }

    const componentProps: ComponentProps<typeof Twoimageswithheading> = {
        style: 'light', // getLayoutStyle(style),
        heading: heading ?? undefined,
        firstimage: firstimage?.url ?? undefined,
        firstmobileimage: firstmobileimage?.url ?? undefined,
        secondimage: secondimage?.url ?? undefined,
        secondmobileimage: secondmobileimage?.url ?? undefined,
    }

    return <Twoimageswithheading {...componentProps} />
}

export default PrismicSliceTwoimageswithheading
