import { useReactiveVar } from '@apollo/client'
import { useMemo } from 'react'

import { currencyCode as currencyCodeVar } from '@emico/currency-code'

import { usePushWhenInView } from './ProductListQueuesContext'
import { GA4ProductWithMeta } from './toGAItem'

export function useViewItemListEvent<T extends HTMLElement>(
    itemListId: string,
    itemListName: string,
    itemListSlot: string | null,
    products: GA4ProductWithMeta[],
) {
    const currencyCode = useReactiveVar(currencyCodeVar)
    const eventTemplate = useMemo(
        () => [
            { ecommerce: null },
            {
                event: 'view_item_list',
                item_list_id: itemListId,
                item_list_name: itemListName,
                ecommerce: {
                    currency: currencyCode ?? 'EUR',
                    item_list_id: itemListId,
                    item_list_name: itemListName,
                    item_list_slot: itemListSlot,
                    items: [],
                },
            },
        ],
        [currencyCode, itemListId, itemListName, itemListSlot],
    )

    return usePushWhenInView<T>(eventTemplate, products)
}
