import { ApolloClient, ApolloError, useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import { Form } from 'informed'
import * as React from 'react'

import { newsletterEmailSubscribe } from './NewsletterEmailSubscribe.query'
import styles from './SubscribeToNewsletterForm.module.scss'
import SubscribeToNewsletterFormValues from './SubscribeToNewsletterFormValues'
import Button, { ButtonProps } from '../input/Button'
import { Email } from '../input/Email'
import formInteraction from '../utils/googleTagManager/formInteraction'
import push from '../utils/googleTagManager/push'
import overridable from '../utils/overridable'

interface Props {
    theme?: 'transparent' | 'opaque'
    onSubmitClick?(): void
    className?: string
    source?: string
}

const handleSubmit =
    (client: ApolloClient<unknown>) =>
    async (
        { subscribeToEmail }: SubscribeToNewsletterFormValues,
        source?: string,
    ) => {
        try {
            await newsletterEmailSubscribe(client)(
                subscribeToEmail,
                source ? `website_${source}` : 'website',
            )
        } catch (err) {
            if (!(err instanceof ApolloError)) {
                return
            }

            const graphQLError = err.graphQLErrors && err.graphQLErrors[0]

            if (graphQLError) {
                switch (graphQLError.extensions?.category) {
                    case 'newsletter-already-subscribed':
                        // do nothing and act like we were successful
                        return
                    default:
                        throw err
                }
            }
        }
        push(
            formInteraction({
                formName: 'Newsletter',
                formID: 'newsletter',
            }),
        )
    }

const SubscribeToNewsletterForm = ({
    theme,
    onSubmitClick,
    className,
    source,
}: Props) => {
    const client = useApolloClient()
    const [isSuccessful, setSuccessful] = React.useState<boolean>(false)

    return (
        <Form<SubscribeToNewsletterFormValues>
            onSubmit={async (values: SubscribeToNewsletterFormValues) => {
                await handleSubmit(client)(values, source)
                setSuccessful(true)
            }}
            css={css`
                display: flex;
                flex-flow: column nowrap;
                justify-content: end;
                width: 100%;
            `}
        >
            <div className={cx(styles.default, className)}>
                <Email
                    field="subscribeToEmail"
                    placeholder={t({
                        id: 'core.newsletter.placeholder',
                        message: `Subscribe to Newsletter`,
                    })}
                    inputClassName={cx(styles.input, {
                        [styles.opaque]: theme === 'opaque',
                    })}
                    errorIndicatorClassName={styles.errorIndicator}
                    validIndicatorClassName={styles.validIndicator}
                />
                <NewsletterSubmit
                    minWidth
                    className={cx(styles.submit)}
                    name="Subscribe"
                    category="newsletter.subscribe"
                    theme={theme}
                    onClick={onSubmitClick}
                    data-bc-position="newsletter-submit"
                >
                    {isSuccessful ? (
                        <Trans id="core.newsletter.submitSuccessful">
                            Subscribed!
                        </Trans>
                    ) : (
                        <Trans id="core.newsletter.submit">Subscribe</Trans>
                    )}
                </NewsletterSubmit>
            </div>
        </Form>
    )
}

export const NewsletterSubmit = overridable('NewsletterSubmit')(
    (props: ButtonProps & Pick<Props, 'theme'>) => (
        <Button {...props} type="submit" />
    ),
)

export default SubscribeToNewsletterForm
