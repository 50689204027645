import { Store } from 'redux'

import { dumpApolloState } from '@emico/apollo'
import { writeLoadedChunksToDocument } from '@emico/lazy'
import { embedStyles, removeUnnecessaryElements } from '@emico/utils'

import { ROOT_ELEMENT_ID } from './launchStore'

declare global {
    interface Window {
        percolate?: {
            preparePage: () => void
            isReady?: boolean
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        preloadedReduxState: any
    }
}

function dumpReduxState(store: Store) {
    const reduxState = store.getState()
    const initialState = `window.preloadedReduxState=${JSON.stringify(
        reduxState,
    ).replace(/</g, '\\u003c')}`
    const script = document.createElement('script')

    script.id = 'preloaded-redux-state'
    script.textContent = initialState
    const head = document?.getElementsByTagName('head')[0]

    head.appendChild(script)
}

const registerPercolatePreparePage = (store: Store) => {
    if (!window) {
        return
    }

    window.percolate = {
        isReady: true,
        preparePage: async () => {
            // Make it possible to detect if the DOM is a prerender result in tests
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
                .getElementById(ROOT_ELEMENT_ID)!
                .setAttribute('data-prerender', '')

            // Don't dump the GTM data layer here because then we would trigger two
            // page events. In addition the page event must include user information
            // which a version from the cache can not have. It's better and easier
            // for the app to take care of this.

            // Polling mechanism to check if the page is actually ready and contains data.
            let intervalId: NodeJS.Timeout

            const pollReadyFlag = (
                resolve: (value: void | PromiseLike<void>) => void,
            ) => {
                if (!intervalId) {
                    intervalId = setInterval(() => checkForReady(resolve), 1000)
                }
            }

            const checkForReady = (
                resolve: (value: void | PromiseLike<void>) => void,
            ) => {
                if (window.percolate?.isReady) {
                    clearInterval(intervalId)
                    // Give some time to render the page
                    setTimeout(() => {
                        dumpApolloState()

                        dumpReduxState(store)

                        embedStyles()

                        removeUnnecessaryElements()

                        writeLoadedChunksToDocument()

                        resolve()
                    }, 2500)
                }
            }

            return new Promise((resolve) => {
                pollReadyFlag(resolve)
            })
        },
    }
}

export default registerPercolatePreparePage
