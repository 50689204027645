import styled from '@emotion/styled'
import * as React from 'react'
import { InView } from 'react-intersection-observer'

import FooterBottom from './FooterBottom'
import FooterMain from './FooterMain'
import FooterTop from './FooterTop'
import usePrismicFooter from './prismicFooterData'
import ErrorBoundary from '../../ErrorBoundary'
import { WhereFooterPme } from '../../graphql/schema.generated'
import { DarkMode } from '../../LayoutStyle'
import theme from '../../theme'

interface Props {
    hideStickyCtaFooter?: (value: boolean) => void
}

const StyledFooter = styled.footer`
    --heading-font-family: ${theme.fonts.footer};
    --heading-font-size: ${theme.headingSizes.footer};
`

const Footer = ({ hideStickyCtaFooter }: Props) => {
    const { data } = usePrismicFooter()

    if (!data) {
        return null
    }
    const {
        showDownloadApp,
        showLanguageSelector,
        showPayment,
        showSocials,
        urlIosApp,
        urlAndroidApp,
        newsletterHeading,
        newsletterContent,
    } = data as WhereFooterPme

    return (
        <InView
            onChange={(inView) => {
                hideStickyCtaFooter?.(inView)
            }}
        >
            <StyledFooter data-cy="Footer">
                <ErrorBoundary fallback={<React.Fragment />}>
                    <DarkMode>
                        <FooterTop />
                        <FooterMain
                            showDownloadApp={showDownloadApp ?? true}
                            showLanguageSelector={showLanguageSelector ?? true}
                            showSocials={showSocials ?? true}
                            urlIosApp={urlIosApp ?? ''}
                            urlAndroidApp={urlAndroidApp ?? ''}
                            newsletterHeading={newsletterHeading ?? undefined}
                            newsletterContent={newsletterContent ?? undefined}
                        />
                    </DarkMode>
                    <FooterBottom showPayment={showPayment ?? true} />
                </ErrorBoundary>
            </StyledFooter>
        </InView>
    )
}

export default Footer
