import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useStaleDataFallback } from '@emico/apollo'
import { stripMaybes } from '@emico/utils'

import { usePreviousAnswers } from './usePreviousAnswers'
import { Answer, useTweakwiseFunnel } from './useTweakwiseFunnel'
import { useTweakwiseFunnelProducts } from './useTweakwiseFunnelProducts'

function encodeAnswers(answers: Answer[]) {
    const state = new URLSearchParams()
    state.append(
        'an',
        answers
            .map((answer) =>
                [answer.questionId, ...(answer.answerIds ?? [])].join('|'),
            )
            .join('~'),
    )
    return state.toString()
}

const searchToAnswers = (search: string) => {
    const params = new URLSearchParams(search)
    return params.get('an')
        ? params
              .get('an')
              ?.split('~')
              .map((question) => question.split('|'))
              .map((question) => ({
                  questionId: question[0],
                  skipQuestion: question.length <= 1,
                  answerIds: question.slice(1),
              })) ?? []
        : []
}

export function useTweakwiseFunnelState(code: string) {
    const history = useHistory()
    const { search } = useLocation()
    const [lastAnswers, setLastAnswers] = useState<Answer | null>(null)

    const answers = useMemo(() => searchToAnswers(search), [search])

    const { data, loading } = useTweakwiseFunnel(code, answers)
    const showResults = data?.questions
        ? !data?.questions?.some((question) => question?.current)
        : false
    const {
        data: products,
        navigationUrl,
        cid,
    } = useTweakwiseFunnelProducts(code, answers, {
        skip: !showResults,
    })

    const nextQuestion = data?.questions?.find((question) => question?.current)

    const addAnswer = (questionId: string, answerIds: string[]) => {
        setLastAnswers(null)
        history.push({
            search: encodeAnswers([
                ...answers,
                {
                    questionId,
                    answerIds,
                },
            ]),
        })
    }

    const back = () => {
        const newAnswers = answers.slice(0, -1)
        setLastAnswers(answers[answers.length - 1] ?? null)
        history.push({
            search: encodeAnswers(newAnswers),
        })
    }

    const isFirstQuestion = data?.questions?.[0]?.current === true

    // Save previous given answers
    const prevSearch = usePreviousAnswers(search)

    // Set the isSelected prop on nextQuestion, so when navigating back, we can show the previous selected option(s).
    const nextQuestionWithSelection = useMemo(() => {
        if (!nextQuestion) {
            return undefined
        }
        const prevQuestions = searchToAnswers(prevSearch ?? '')
        const prevQuestionWithAnswers = prevQuestions.find(
            (prev) => prev.questionId === nextQuestion?.id,
        )

        return {
            ...nextQuestion,
            answers: nextQuestion?.answers
                ?.filter(stripMaybes)
                ?.map((answer) => ({
                    ...answer,
                    isSelected: prevQuestionWithAnswers?.answerIds.includes(
                        answer?.answerId ?? '',
                    ),
                })),
        }
    }, [nextQuestion, prevSearch])

    return {
        loading,
        nextQuestion: useStaleDataFallback(nextQuestionWithSelection),
        lastAnswers,
        addAnswer,
        back,
        items: data?.items,
        products: products,
        count: data?.count ?? 0,
        isFirstQuestion,
        navigationUrl,
        cid,
        showResults,
        name: data?.name,
    }
}
