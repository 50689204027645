import {
    JustbrandsPageBodyForm,
    JustbrandsPageBodyFormPrimary,
    PageBodyForm,
    PageBodyFormPrimary,
} from './graphql/schema.generated'
import { PrismicLazyForm } from './PrismicLazyForm'

export type PrismicPageBodyFormPrimary =
    | PageBodyFormPrimary
    | JustbrandsPageBodyFormPrimary

export const PrismicSliceForm = ({
    slice,
}: {
    slice: PrismicPageBodyFormPrimary | null
}) => (slice ? <PrismicLazyForm {...slice} /> : null)
