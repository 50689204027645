import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useState } from 'react'

import Button from '../../input/Button'
import { useSocialButtonClickEvent } from '../../utils/ga4/useSocialButtonClickEvent'
import styles from '../ContactUsModal/ContactUsModal.module.scss'

const global = window as {
    robin_settings?: {
        hide_tab?: boolean
        tab_position?: 'bottom-right' // enum
        callback(event: 'load' | 'init', data?: unknown): void
        features?: {
            shareCookieAcrossDomains?: boolean
            chatInDivOnMobile?: boolean
        }
    }
    __robin?: {
        show(type: string): void
    }
}

interface Props {
    close(): void
}

const ContactUsEmailButton = ({ close }: Props) => {
    const [robinLoaded] = useState<boolean>(Boolean(global.__robin))
    const [isEmailOpen, setEmailOpen] = useState<boolean>(false)

    const pushEvent = useSocialButtonClickEvent()

    const show = useCallback(() => {
        // When showing the RobinHQ panel, the contact us modal must
        // automatically close so that it looks like it is replaced
        close()
        global.__robin?.show('contact_form')
    }, [close])

    useEffect(() => {
        if (robinLoaded && isEmailOpen) {
            show()
        }
    }, [robinLoaded, isEmailOpen, show])

    const handleOpenEmail = () => {
        pushEvent('contact modal', 'mail')
        setEmailOpen(true)
        if (global.__robin) {
            // if robin isn't available yet, opening will be done in the init event
            show()
        }
    }

    return (
        <Button
            name="Chat"
            category="core.contactUsModal.email"
            variant="secondary"
            className={styles.button}
            onClick={handleOpenEmail}
            disabled={isEmailOpen}
        >
            <Trans id="core.contactUsModal.email">Email</Trans>
        </Button>
    )
}

export default ContactUsEmailButton
