import loginTokenVar from '@emico/login-token'

import actions from './actions'
import { CartState } from '../../reducers'
import storage from '../../storage'
import {
    QuoteDataCartInterface,
    QuoteDataPaymentMethodInterface,
    QuoteDataTotalsInterface,
} from '../../types/MagentoRestApi'
import { Action, ThunkDispatch, ThunkResult } from '../../types/Redux'
import { M2ApiResponseError } from '../../utils/RestApi'
import { RequestType } from '../../utils/RestApi/request'
import checkoutActions from '../checkout'
import { isSignedIn } from '../user'
/**
 * @deprecated Use @emico-hooks instead
 */
export const createCart = (): ThunkResult<Promise<string>> =>
    async function thunk(dispatch, getState, { request }): Promise<string> {
        // reset the checkout workflow
        dispatch(checkoutActions.reset())

        {
            // if a cart exists in storage, act like we just received it
            const cartId = await getCartId()

            if (cartId && !isSignedIn()) {
                return cartId
            }
        }

        const guestCartEndpoint = '/rest/V1/guest-carts'
        const signedInCartEndpoint = '/rest/V1/carts/mine'
        const cartEndpoint = isSignedIn()
            ? signedInCartEndpoint
            : guestCartEndpoint

        const cartId: string = await request(cartEndpoint, {
            method: 'POST',
            authorizationToken: loginTokenVar(),
        })

        // write to storage in the background
        saveCartId(cartId)

        return cartId
    }

/**
 * @deprecated Use @emico-hooks instead
 */
export interface MagentoRestError {
    json: {
        message: string
        parameters: Array<string | number>
    }
}

/**
 * @deprecated Use @emico-hooks instead
 */
export const getCartDetails = (
    payload: {
        forceRefresh?: boolean
        withPaymentDetails?: boolean
        cartId?: string
        isSignedIn?: boolean
    } = {},
): ThunkResult<Promise<void>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        services,
    ): Promise<void> {
        const { request } = services
        const { forceRefresh, withPaymentDetails } = payload
        const cartId = payload.cartId ?? getCartId()
        const signedIn = payload.isSignedIn ?? isSignedIn()

        // If there isn't a cart and the user isn't signed in, abort.
        // Don't automatically create a cart since this would make carts for
        // every single (bot) visit which would cost too many server-side
        // resources.
        // If the user is signed in we must ask the server if it has a cart for
        // us.
        if (!cartId && !signedIn) {
            return
        }

        try {
            let paymentMethods: QuoteDataPaymentMethodInterface[] | undefined
            const [details, totals] = await fetchCart(
                request,
                cartId,
                signedIn,
                forceRefresh === true,
            )

            if (signedIn) {
                saveCartId(String(details.id))
            }

            if (withPaymentDetails) {
                paymentMethods = await fetchCartPart({
                    request,
                    cartId,
                    forceRefresh,
                    isSignedIn: signedIn,
                    subResource: 'payment-methods',
                })
            }

            const receive: (payload: Partial<CartState>) => Action =
                actions.getDetails.receive

            await dispatch(
                receive({
                    details,
                    totals,
                    ...(withPaymentDetails ? { paymentMethods } : {}),
                }),
            )
        } catch (error) {
            const response =
                error instanceof M2ApiResponseError ? error.response : undefined

            // check if the cart has expired
            if (response && [401, 404].includes(response.status)) {
                // if so, then delete the cached ID from local storage.
                // In contrast to the save, make sure storage deletion is
                // complete before dispatching the error--you don't want an
                // upstream action to try and reuse the known-bad ID.
                await dispatch(removeCart())
            } else {
                throw error
            }
        }
    }
/**
 * @deprecated Use @emico-hooks instead
 */
export const removeCart = (): ThunkResult<Promise<void>> =>
    async function thunk(dispatch) {
        // Clear the cartId from local storage.
        clearCartId()

        // Clear the cart info from the redux store.
        dispatch(actions.reset())
    }

/* helpers */
/**
 * @deprecated Use @emico-hooks instead
 */
async function fetchCart(
    request: RequestType,
    cartId: string | null,
    isSignedIn: boolean,
    forceRefresh: boolean,
) {
    // Never fetch the parts separately! The codebase relies on all parts being
    // loaded in state.
    // TODO: Convert to GraphQL and load the extra product info we need in one go (e.g. product description & image)
    return Promise.all([
        fetchCartDetails(request, cartId, isSignedIn, forceRefresh),
        fetchCartTotals(request, cartId, isSignedIn, forceRefresh),
    ])
}
/**
 * @deprecated Use @emico-hooks instead
 */
async function fetchCartPart(cartPart: {
    request: RequestType
    cartId: string | null
    forceRefresh?: boolean
    isSignedIn: boolean
    subResource?:
        | 'items'
        | 'totals'
        | 'totals-information'
        | 'coupons'
        | 'shipping-methods'
        | 'shipping-information'
        | 'payment-methods'
        | 'selected-payment-method'
        | 'payment-information'
        | 'billing-address'

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
    const {
        request,
        cartId,
        forceRefresh,
        isSignedIn,
        subResource = '',
    } = cartPart
    const signedInEndpoint = `/rest/V1/carts/mine/${subResource}`
    const guestEndpoint = `/rest/V1/guest-carts/${cartId}/${subResource}`
    const endpoint = isSignedIn ? signedInEndpoint : guestEndpoint

    const cache = forceRefresh ? 'reload' : 'default'

    return request(endpoint, { cache })
}

// Extra wrappers to get correct return types
/**
 * @deprecated Use @emico-hooks instead
 */
async function fetchCartDetails(
    request: RequestType,
    cartId: string | null,
    isSignedIn: boolean,
    forceRefresh?: boolean,
): Promise<QuoteDataCartInterface> {
    return fetchCartPart({
        request,
        cartId,
        forceRefresh,
        isSignedIn,
    })
}
/**
 * @deprecated Use @emico-hooks instead
 */
async function fetchCartTotals(
    request: RequestType,
    cartId: string | null,
    isSignedIn: boolean,
    forceRefresh?: boolean,
): Promise<QuoteDataTotalsInterface> {
    return fetchCartPart({
        request,
        cartId,
        forceRefresh,
        isSignedIn,
        subResource: 'totals',
    })
}

/**
 * @deprecated Use @emico-hooks instead
 */
export function getCartId(): string | null {
    return storage.getItem('cartId')
}
/**
 * @deprecated Use @emico-hooks instead
 */
export function saveCartId(id: string): void {
    return storage.setItem('cartId', id)
}
/**
 * @deprecated Use @emico-hooks instead
 */
export function clearCartId(): void {
    return storage.removeItem('cartId')
}

/**
 * @deprecated Use @emico-hooks instead
 */
export const restoreCart = (): ThunkResult<Promise<boolean>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<boolean> {
        const guestRestoreCartEndpoint = '/rest/V1/guest-carts/restore'
        const authedRestoreCartEndpoint = '/rest/V1/carts/restore'
        const restoreCartEndpoint = isSignedIn()
            ? authedRestoreCartEndpoint
            : guestRestoreCartEndpoint

        const isCartRestored = await request(restoreCartEndpoint, {
            method: 'POST',
        })

        if (!isCartRestored) {
            // Cart could not be restored.
            // Clear the entire cart because it has become invalid
            await dispatch(removeCart())
        }

        return isCartRestored
    }
