import { GtmEvent } from '@emico-utils/datalayer-templates'
import { useWhenVisible } from '@emico-utils/when-visible'
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from 'react'

import { hashCode } from '@emico/utils'

import toGA4Item, { GA4ProductWithMeta } from './toGAItem'
import { usePushEcommerceEvent } from './usePushEvent'
import { useRootCategoryId } from '../../catalog/useRootCategory'

interface ProductListQueue {
    eventTemplate: GtmEvent[]
    products: GA4ProductWithMeta[]
}

const DispatchContext = createContext<
    (template: GtmEvent[], products: GA4ProductWithMeta[]) => void
>(() => void 0)

function getQueueKey(eventTemplate: GtmEvent[]) {
    return hashCode(JSON.stringify(eventTemplate))
}

function enrichTemplates(
    templates: GtmEvent[],
    products: GA4ProductWithMeta[],
    rootId: number,
) {
    const newTemplates: GtmEvent[] = []

    for (const template of templates) {
        // If it is not a ecommerce event, a reset trigger, of it has no items, push the
        // original template
        if (!template.ecommerce?.items) {
            newTemplates.push(template)
            continue
        }

        newTemplates.push({
            ...template,
            ecommerce: {
                ...template.ecommerce,
                items: products.map((item) => toGA4Item(item, rootId)),
            },
        })
    }

    return newTemplates
}

export const ProductListQueuesProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const queuesRef = useRef<Record<string, ProductListQueue>>({})
    // const [, setQueues] = useState<Record<string, ProductListQueue>>({})
    const push = usePushEcommerceEvent([], false, false)
    const rootId = useRootCategoryId()

    const setQueues = useCallback(
        (
            callback: (
                queues: Record<string, ProductListQueue>,
            ) => Record<string, ProductListQueue>,
        ) => {
            queuesRef.current = callback(queuesRef.current)
        },
        [],
    )

    // When the push function changes props (like the current page) have changed. This will
    // trigger this effect, en push the items in the queue with the old push event
    useEffect(() => {
        if (!push || rootId == null) {
            return () => {
                // When push is not available, we cannot push items from the queue yet
            }
        }
        // no-op
        return () => {
            setQueues((queues) => {
                for (const queue of Object.values(queues)) {
                    const events = enrichTemplates(
                        queue.eventTemplate,
                        queue.products,
                        rootId,
                    )

                    // Be careful when moving this code, this code depends on the old push event
                    // to push events with previously available data.
                    push(events)
                }

                return {}
            })
            if (timerRef.current) {
                clearTimeout(timerRef.current)
                timerRef.current = null
            }
        }
    }, [push, rootId, setQueues])

    const process = useCallback(() => {
        setQueues((queues) => {
            if (!push || rootId == null) {
                return queues
            }

            for (const queue of Object.values(queues)) {
                if (queue.products.length === 0) {
                    continue
                }
                const events = enrichTemplates(
                    queue.eventTemplate,
                    queue.products,
                    rootId,
                )

                push(events)
            }

            return {}
        })
    }, [setQueues, push, rootId])

    const pushToQueue = useCallback(
        (template: GtmEvent[], products: GA4ProductWithMeta[]) => {
            setQueues((queues) => {
                const key = getQueueKey(template)

                return {
                    ...queues,
                    [key]: {
                        eventTemplate: template,
                        products: [
                            ...(queues[key]?.products ?? []),
                            ...products,
                        ],
                    },
                }
            })
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(process, 200)
        },
        [process, setQueues],
    )

    return (
        <DispatchContext.Provider value={pushToQueue}>
            {children}
        </DispatchContext.Provider>
    )
}

export function usePushToQueue() {
    const pushToQueue = useContext(DispatchContext)

    return pushToQueue
}

export function usePushWhenInView<T extends HTMLElement>(
    template: GtmEvent[],
    products: GA4ProductWithMeta[],
) {
    const pushToQueue = usePushToQueue()

    return useWhenVisible<T>(
        () => {
            pushToQueue(template, products)
        },
        ({ isIntersecting }) => isIntersecting,
        {
            threshold: 0.8,
            runOnce: true,
        },
    )
}
