import cx from 'classnames'
import * as React from 'react'

import styles from './CheckButton.module.scss'
import Button from '../Button'
import { Label } from '../Label'

interface Props {
    Component?: React.ElementType
    className?: string
    disabled?: boolean
    children: React.ReactNode
    name?: string
    category?: string
}

const DefaultCheckboxInput = (
    props: React.InputHTMLAttributes<HTMLInputElement>,
) => <input type="checkbox" {...props} />

const CheckButton = <T = React.InputHTMLAttributes<HTMLInputElement>,>({
    Component = DefaultCheckboxInput,
    className,
    disabled,
    children,
    name = '',
    category = '',
    ...others
}: Props & T) => (
    <Label className={styles.checkButton} disabled={disabled}>
        <Component className={styles.input} disabled={disabled} {...others} />
        <Button
            className={cx(styles.button, className)}
            tabIndex={-1}
            name={name}
            category={category}
        >
            {children}
        </Button>
    </Label>
)

export default CheckButton
